/* eslint-disable react-hooks/rules-of-hooks */ // This can be removed when the children prop is sunset
import React, {
	cloneElement,
	forwardRef,
	Fragment,
	MouseEvent,
	RefObject,
	useEffect,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getAnchorEl } from '@fabric/utils';
import { Fade, Popper } from '@mui/material';
import clsx from 'classnames';
import { capitalize, isArray, isBoolean } from 'lodash';
import { useStyles } from './tooltip.styles';
import { TooltipProps, childrenPropsType } from './types';

const TRANSITION_DURATION = 200;

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
	(
		{
			anchorEl: anchorElProp,
			anchorRef,
			children,
			className,
			classes = {},
			content,
			enterDelay = 0,
			id,
			open,
			leaveDelay = 0,
			onClose,
			onOpen,
			placement: placementProp = 'top',
			title,
		},
		ref
	) => {
		useEffect(() => {
			if (children) {
				console.warn(
					'The `children` prop is deprecated and will be removed in future versions. Please use the `anchorRef` prop instead.'
				);
			}
		}, [children]);

		// If children is an array type, we should fallback to rendering the children with no Tooltip. This can be removed when we sunset the children prop.
		if (children && isArray(children)) {
			console.error('Invalid prop `children` of type `array` supplied to Tooltip. Expected a single ReactElement.');

			return children;
		}

		const isControlled = isBoolean(open);
		const [openState, setOpenState] = useState<boolean | undefined>(undefined);

		const previousZIndex = useRef<number>(0);

		const zIndex = useMemo(() => {
			if (openState) {
				const newZIndex = getMaxZIndex() + 10;
				previousZIndex.current = newZIndex;
				return newZIndex;
			}
			return previousZIndex.current;
		}, [openState]);

		const styles = useStyles({ zIndex });

		const [arrowRef, setArrowRef] = useState(null);

		// Start Cleanup when children prop is sunset
		const childrenProps: childrenPropsType = (children ? { ...children.props } : {}) as childrenPropsType;
		const internalChildRef = React.useRef<RefObject<HTMLElement>>();
		const childNodeRef = childrenProps.ref || internalChildRef;

		if (childrenProps) {
			childrenProps.ref = childNodeRef;
		}
		// End Cleanup when children prop is sunset

		const enterTimer = React.useRef<ReturnType<typeof setTimeout>>();
		const leaveTimer = React.useRef<ReturnType<typeof setTimeout>>();

		const clearTimers = () => {
			if (enterTimer.current) {
				clearTimeout(enterTimer.current);
			}

			if (leaveTimer.current) {
				clearTimeout(leaveTimer.current);
			}
		};

		const handleOpen = useCallback(
			(event?) => {
				setOpenState(true);
				if (onOpen) {
					onOpen(event);
				}
			},
			[onOpen]
		);

		const handleClose = useCallback(
			(event?) => {
				setOpenState(false);
				if (onClose) {
					onClose(event);
				}
			},
			[onClose]
		);

		const handleEnter = useCallback(
			(event?: MouseEvent<HTMLElement>) => {
				if (event?.persist) {
					event.persist();
				}

				clearTimers();
				enterTimer.current = setTimeout(() => {
					handleOpen(event);
				}, enterDelay);
			},
			[enterDelay, handleOpen]
		);

		const handleLeave = useCallback(
			(event?: MouseEvent<HTMLElement>) => {
				if (event?.persist) {
					event.persist();
				}

				clearTimers();
				leaveTimer.current = setTimeout(() => {
					handleClose(event);
				}, leaveDelay);
			},
			[handleClose, leaveDelay]
		);

		const handleEscape = useCallback(
			(event?: KeyboardEvent) => {
				if (event?.key === 'Escape') {
					clearTimers();
					leaveTimer.current = setTimeout(() => {
						handleClose(event);
					}, leaveDelay);
				}
			},
			[handleClose, leaveDelay]
		);

		useEffect(() => {
			return () => {
				clearTimers();
			};
		}, []);

		useEffect(() => {
			// Handle event listeners
			const anchor = anchorRef?.current || childNodeRef?.current || (getAnchorEl(anchorElProp) as Element);
			if (anchor && !(anchor instanceof Element)) {
				console.error('Invalid `anchorEl` or `children` prop supplied to Tooltip. Must be a ReactElement.');
			} else if (anchor && !isControlled) {
				// @ts-ignore
				anchor.addEventListener('mouseenter', handleEnter);
				// @ts-ignore
				anchor.addEventListener('focusin', handleEnter);
				// @ts-ignore
				anchor.addEventListener('mouseleave', handleLeave);
				// @ts-ignore
				anchor.addEventListener('focusout', handleLeave);
				// SVG elements don't support keyboard events
				// @ts-ignore
				anchor.addEventListener('keydown', handleEscape);
			}

			return () => {
				if (anchor instanceof Element && anchor.removeEventListener) {
					// @ts-ignore
					anchor.removeEventListener('mouseenter', handleEnter);
					// @ts-ignore
					anchor.removeEventListener('focusin', handleEnter);
					// @ts-ignore
					anchor.removeEventListener('mouseleave', handleLeave);
					// @ts-ignore
					anchor.removeEventListener('focusout', handleLeave);
					// SVG elements don't support keyboard events
					// @ts-ignore
					anchor.removeEventListener('keydown', handleEscape);
				}
			};
		}, [anchorElProp, anchorRef, childNodeRef, isControlled, handleEnter, handleLeave, handleEscape]);

		useEffect(() => {
			// Controlled logic
			if (isControlled) {
				if (open) {
					handleEnter();
				} else {
					handleLeave();
				}
			}
		}, [open, isControlled, handleEnter, handleLeave]);

		return (
			<Fragment>
				{children && cloneElement(children, childrenProps)}
				{(anchorRef?.current || childNodeRef?.current || anchorElProp) && (
					<Popper
						anchorEl={anchorRef?.current || (childNodeRef?.current as TooltipProps['anchorEl']) || anchorElProp}
						className={clsx(className, styles.root, classes.root)}
						id={id}
						modifiers={[
							{
								name: 'arrow',
								enabled: true,
								options: {
									element: arrowRef,
								},
							},
						]}
						open={!!openState}
						placement={placementProp}
						role="tooltip"
						transition={true}
					>
						{({ placement, TransitionProps }) => (
							<Fade {...TransitionProps} timeout={TRANSITION_DURATION}>
								<div
									className={clsx(
										styles.tooltip,
										styles[`tooltipPlacement${capitalize(placement.split('-')[0])}`],
										classes.tooltip
									)}
									ref={ref}
								>
									<div aria-live="polite" className={clsx(styles.content, classes.content)}>
										{title && <span className={clsx(styles.title, classes.title)}>{title}</span>}
										{content}
									</div>
									{ifFeature(
										'encore',
										undefined,
										<span
											className={clsx(styles.arrow, classes.arrow)}
											ref={setArrowRef as unknown as RefObject<HTMLSpanElement>}
										/>
									)}
								</div>
							</Fade>
						)}
					</Popper>
				)}
			</Fragment>
		);
	}
);
