import { ListItem, Subcategory, TransferListItemProps } from '../../types';
import { getDisplayOrderIndexRight } from './get-display-order-index-right';

export const getSubcategorizedData = (listItems: ListItem[]): Subcategory[] => {
	const newItems: Subcategory[] = [];
	const indexes: { [key: string]: number } = {};
	let renderIndex = 0;
	listItems.forEach((entry: TransferListItemProps, index) => {
		entry.listIndex = index;
		if (entry.subcategory) {
			if (indexes[entry.subcategory] === undefined) {
				newItems.push({
					items: [entry],
					name: entry.subcategory,
					grouped: entry.grouped,
					index: renderIndex,
				});
				indexes[entry.subcategory] = renderIndex;
				renderIndex += 1;
			} else {
				newItems[indexes[entry.subcategory]].items.push(entry);
			}
		} else {
			newItems.push({ name: '', index: renderIndex, items: [entry] });
			renderIndex += 1;
		}
	});

	return getDisplayOrderIndexRight(newItems);
};
