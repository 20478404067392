import { useMemo } from 'react';

export const useRoundedPath = (
	x: number,
	y: number,
	width: number,
	height: number,
	isVertical: boolean,
	isFirstInSeries = true,
	isLastInSeries = true,
	roundStart = true,
	roundEnd = true,
	gap = 1,
	radius = 4
): string => {
	radius = Math.min(radius, Math.min(width, height) * 0.5); // When radius exceeds half of smallest side, a weird artifact is present;
	return useMemo(() => {
		const diameter = 2 * radius;
		const topRight = (!isVertical && roundEnd) || (isVertical && roundEnd);
		const bottomRight = (isVertical && roundStart) || (!isVertical && roundEnd);
		const bottomLeft = (isVertical && roundStart) || (!isVertical && roundStart);
		const topLeft = (!isVertical && roundStart) || (isVertical && roundEnd);
		const resolvedHeight = isVertical && !isFirstInSeries ? height - gap : height;
		const resolvedWidth = !isVertical && !isFirstInSeries ? width - gap : width;

		return `M${x + radius},${y} h${width - diameter}
	 ${topRight ? `a${radius},${radius} 0 0 1 ${radius},${radius}` : `h${radius}v${radius}`}
	 v${resolvedHeight - diameter}
	 ${bottomRight ? `a${radius},${radius} 0 0 1 ${-radius},${radius}` : `v${radius}h${-radius}`}
	 h${diameter - resolvedWidth}
	 ${bottomLeft ? `a${radius},${radius} 0 0 1 ${-radius},${-radius}` : `h${-radius}v${-radius}`}
	 v${diameter - resolvedHeight}
	 ${topLeft ? `a${radius},${radius} 0 0 1 ${radius},${-radius}` : `v${-radius}h${radius}`}
	z`
			.split('\n')
			.map(m => m.trim())
			.join(' ');
	}, [x, y, width, height, isVertical, isFirstInSeries, isLastInSeries, gap, radius]);
};
