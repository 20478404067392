import { FormElement, Renderer, RenderOptions } from '../types';

export function getRendererFromOptions(element: FormElement, renderOptions: RenderOptions, defaultRenderer?: Renderer) {
	let renderer: Renderer | undefined = defaultRenderer;

	if (typeof renderOptions.getRenderer === 'function') {
		const rendererFromOptions = renderOptions.getRenderer(element);
		if (typeof rendererFromOptions === 'function') {
			renderer = rendererFromOptions;
		}
	}

	return renderer;
}
