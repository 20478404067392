import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useHorizontalStyles = makeStyles<void, 'icon' | 'number' | 'itemRoot'>()((theme, _props, classes) => {
	const { palette, typography, constructs, spacing } = theme;
	return {
		itemRoot: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			alignItems: ifFeature('encore', 'flex-start', 'center'),
			width: '100%',
			'&:first-of-type': {
				'&:after': {
					display: 'none',
				},
			},
			'&:after': {
				boxSizing: 'border-box',
				content: '""',
				display: 'block',
				height: ifFeature('encore', 1, 4),
				top: ifFeature('encore', '32px', '24px'), // half of width of circle minus half of width of gray line
				left: ifFeature('encore', '-100%', '-50%'),
				bottom: 0,
				position: 'absolute',
				width: '100%',
				zIndex: 0,
				background: palette.gray[300],
			},
		},
		unsetIndex: {
			zIndex: 'unset',
		},
		activeInprogress: {
			'&:after': {
				background: ifFeature('encore', constructs.border.primary.medium, palette.primary.main),
			},
			[`& .${classes.number}`]: {
				backgroundColor: ifFeature('encore', constructs.surface.primary.strong),
				color: ifFeature('encore', constructs.text.neutral.forcedWhite),
			},
		},
		activePrimaryContent: ifFeature(
			'encore',
			{
				color: constructs.surface.primary.strong,
				fontWeight: typography.fontWeightSemibold,
			},
			{}
		),
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			width: 'auto',
			alignItems: ifFeature('encore', 'flex-start', 'center'),
			zIndex: 10,
		},
		iconWrapper: {
			position: 'relative',
		},
		icon: ifFeature(
			'encore',
			{
				boxShadow: `0 0 0 6px ${palette.common.white}`,
			},
			{
				borderColor: palette.primary.main,
				boxShadow: `0 0 0 3px ${palette.common.white}`,
			}
		),
		noIcon: {
			border: 'none',
			boxShadow: 'none',
			marginTop: ifFeature('encore', '14px'),
		},
		content: {
			marginTop: ifFeature('encore', spacing(2), '5px'),
			textAlign: ifFeature('encore', 'left', 'center'),
			paddingRight: ifFeature('encore', spacing(0.5)),
		},
		inactiveColor: {
			color: palette.gray[700],
		},
		/* @startCleanup encore */
		disabled: {
			[`& .${classes.icon}`]: ifFeature(
				'encore',
				{},
				{
					backgroundColor: palette.gray[300],
					borderColor: palette.gray[300],
					fill: palette.gray[700],
				}
			),
		},
		/* @endCleanup encore */
		number: ifFeature(
			'encore',
			{
				height: '32px',
				width: '32px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '8px',
				marginTop: spacing(0.25),
				color: constructs.text.primary.strong,
				backgroundColor: constructs.surface.neutral.xWeak,
				fontSize: typography.small.fontSize,
				lineHeight: typography.small.lineHeight,
				fontWeight: typography.fontWeightSemibold,
				boxShadow: `0 0 0 6px ${palette.common.white}`,
			},
			{
				color: palette.gray[600],
				fontSize: '22px',
				fontWeight: typography.fontWeightBold,
			}
		),
		sizeMedium: {
			[`& .${classes.icon}`]: ifFeature(
				'encore',
				{
					marginTop: 8,
				},
				{}
			),
		},
		sizeSmall: {
			[`& .${classes.icon}`]: ifFeature(
				'encore',
				{
					marginTop: 14,
				},
				{
					width: '36px',
					minWidth: '36px',
					height: '36px',
					marginTop: 9,
				}
			),
			/* @startCleanup encore */
			[`& .${classes.number}`]: {
				fontSize: ifFeature('encore', undefined, '20px'),
			},
			/* @endCleanup encore */
		},
		sizeTeenie: {
			[`& .${classes.icon}`]: ifFeature(
				'encore',
				{
					marginTop: 24,
					backgroundColor: palette.common.white,
				},
				{
					width: 14,
					minWidth: 14,
					height: 14,
					marginTop: 19,
				}
			),
		},
	};
});
