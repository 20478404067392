import React, { ReactElement } from 'react';
import { VerticalWizardNavigationProps } from './navigation.types';
import { useStyles } from './navigation.styles';

export function Navigation({ nextButton, prevButton, cancelButton }: VerticalWizardNavigationProps): ReactElement {
	const { classes } = useStyles();

	return (
		<div className={classes.navigation}>
			{nextButton}
			{prevButton}
			{cancelButton}
		</div>
	);
}
