import React, { ComponentProps, ComponentType, forwardRef, PropsWithChildren, Ref } from 'react';
import { FormFactoryElement } from '../types/element.types';

/**
 * A higher order component intended to map values from a model object into valid component props.
 * @param Component The component that the model will be mapped to.
 * @returns A function that must receive an adapter function that converts a model into valid props for a component.
 */
export function adapted<C extends ComponentType<PropsWithChildren<any>>, D extends FormFactoryElement>(Component: C) {
	return (getProps: (data: D) => ComponentProps<C>) => {
		return forwardRef((data: D, ref: Ref<HTMLElement>) => {
			/*
			 * TODO: We need to look into a better way to set up these Typescript types later. This is a temporary way to
			 * solve the "Type 'PropsFromComponentType<C> & { ref: Ref<HTMLElement>; }' is not assignable to type 'LibraryManagedAttributes<C, any>'"" error.
			 */

			const props = { ...getProps(data), ref } as ComponentProps<C>;
			return <Component {...props} />;
		});
	};
}
