import React, { forwardRef, Ref } from 'react';
import { Box as MUIBox } from '@mui/material';
import { LayoutBoxComponentType, LayoutBoxProps } from './layout-box.types';
import { formatBaseProps, useExtractBreakpoints, transformSpacingProps } from '~utils';
import { makeStyles, ModeOptions, dyeColors } from '~styles';
import { CSSObject } from 'tss-react';

const useStyles = makeStyles<CSSObject>()(({ mode, spacing }, props) => ({
	root: {
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		...transformSpacingProps(props, spacing),
	},
}));

function LayoutBoxComponent<C extends keyof JSX.IntrinsicElements = 'div'>(props: LayoutBoxProps<C>, ref: Ref<HTMLElement>) {
	const { children, objectFit = 'fill', ...rest } = props;
	const { classes } = useStyles(
		useExtractBreakpoints({
			objectFit,
			...rest,
		})
	);
	return (
		// casting for performance
		<MUIBox className={classes.root} ref={ref} {...formatBaseProps(rest as object)}>
			{children}
		</MUIBox>
	);
}

// passing generic type params for performance
export const LayoutBox = forwardRef<Element, LayoutBoxProps>(LayoutBoxComponent) as LayoutBoxComponentType;
