import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TransferListItemProps } from '../../types';

function getFocusBoxShadow(insetColor: string, topBorderColor?: string) {
	return `inset 0 4px 0 0 ${insetColor}1a, inset 0 -4px 0 0 ${insetColor}1a, 0 -1px 0 0 ${topBorderColor || insetColor}`;
}

export const useHoverStyles = makeStyles<TransferListItemProps, 'icon'>()(
	({ constructs, mode, palette }, { disabled, rightSide }, classes) => ({
		hoverRoot: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			[`&:hover .${classes.icon}, &:focus-within .${classes.icon}`]: {
				display: disabled ? '' : 'initial',
			},
			'&&': ifFeature('encore', {
				'&:not(:last-child)': {
					[`& div`]: {
						[`& div`]: {
							borderBottomColor: constructs.border.neutral.xWeak,
						},
						'&:focus': !disabled && {
							[`& div`]: {
								borderBottomColor: constructs.border.primary.medium,
							},
						},
						'&:hover': !disabled &&
							!rightSide && {
								[`& div`]: {
									borderBottomColor: constructs.border.primary.strong,
								},
							},
					},
				},
			}),
		},
		icon: {
			display: 'none',
			fill: rightSide ? palette.gray[500] : palette.primary.main,
			height: ifFeature('encore', 20),
		},
	})
);

export const useStyles = makeStyles<TransferListItemProps, 'container' | 'containerTextHover' | 'leftContent' | 'rightContent'>()(
	({ constructs, mode, palette, spacing, typography }, { disabled, rightSide }, classes) => ({
		root: ifFeature(
			'encore',
			{
				backgroundColor: palette.common.white,
				color: constructs.text.neutral.xStrong,
				cursor: disabled ? undefined : 'pointer',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				'&:focus': {
					[`& .${classes.container}`]: {
						borderBottomColor: constructs.border.primary.medium,
						boxShadow: getFocusBoxShadow(constructs.border.primary.medium),
					},
					[`& .${classes.containerTextHover} .${classes.leftContent}`]: {
						color: disabled ? undefined : palette.primary.main,
					},
					[`& .${classes.rightContent}`]: {
						color: disabled ? undefined : constructs.text.neutral.xWeak,
					},
				},
				'&:hover': !disabled && {
					[`& .${classes.container}`]: {
						backgroundColor: rightSide ? undefined : constructs.surface.primary.weak,
						borderBottomColor: constructs.border.primary.strong,
						boxShadow: rightSide ? undefined : `0 -1px 0 0 ${constructs.border.primary.strong}`,
					},
					[`& .${classes.containerTextHover} .${classes.leftContent}`]: {
						color: rightSide ? undefined : palette.primary.main,
					},
					[`& .${classes.rightContent}`]: {
						color: constructs.text.neutral.weak,
					},
				},
				// The last item should not have a bottom border since it overlaps the container bottom border.
				// However, when using `TransferListHoverItem`, this is actually a child of the last item.
				// Thus, we get the last item relative to the container/listbox.
				[`[role="listbox"] > &:last-child .${classes.container}, [role="listbox"] > :last-child & .${classes.container}`]: {
					borderBottom: 'none',
				},
			},
			{
				backgroundColor: palette.common.white,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				'&:focus': {
					[`& .${classes.container}`]: {
						backgroundColor: disabled ? palette.grey[200] : `${palette.primary.lightest}1A`,
					},
				},
				'&:hover': {
					[`& .${classes.container}`]: {
						backgroundColor: disabled ? undefined : `${palette.primary.lightest}1A`,
					},
				},
				'&:hover, &:focus': {
					[`& .${classes.containerTextHover} .${classes.leftContent}`]: {
						color: disabled ? undefined : palette.primary.main,
					},
				},
			}
		),
		containerTextHover: {},
		container: ifFeature(
			'encore',
			{
				borderBottom: `1px solid ${constructs.border.neutral.xWeak}`,
				boxSizing: 'border-box',
				display: 'flex',
				padding: spacing(1.5, 2.5),
			},
			{
				borderBottom: `1px solid ${palette.gray[400]}`,
				boxSizing: 'border-box',
				display: 'flex',
				height: 40,
				padding: '0 10px',
			}
		),
		disabled: {
			backgroundColor: palette.gray[100],
			color: palette.gray[700],
		},
		selected: ifFeature(
			'encore',
			{
				'&&': {
					backgroundColor: constructs.surface.primary.strong,
					[`& .${classes.container}`]: {
						borderBottomColor: constructs.surface.neutral.inverted,
						boxShadow: `0 -1px 0 0 ${constructs.surface.neutral.inverted}`,
					},
					'&:hover, &:focus': {
						[`& ${classes.container}`]: {
							borderBottomColor: constructs.surface.neutral.inverted,
						},
					},
					'&:focus': {
						[`& .${classes.container}`]: {
							borderBottomColor: constructs.surface.neutral.inverted,
							boxShadow: getFocusBoxShadow(constructs.border.primary.medium, constructs.surface.neutral.inverted),
						},
					},
					'&:hover': !disabled && {
						[`& .${classes.container}`]: {
							backgroundColor: rightSide ? undefined : constructs.surface.primary.xStrong,
						},
					},
					'&&': {
						[`& .${classes.leftContent}, & .${classes.rightContent}`]: {
							color: constructs.text.neutral.inverted,
							fill: constructs.icon.neutral.inverted,
						},
					},
					'&:last-child': {
						borderBottom: 'none',
					},
				},
			},
			{
				'&&': {
					// The selected css is being overwritten by the root css in a few cases. This allows the selected css to appear at all times while selected.
					backgroundColor: palette.primary.main,
				},
				[`& .${classes.leftContent}, & .${classes.rightContent}`]: {
					color: palette.common.white,
					fill: palette.common.white,
				},
				'&:hover, &:focus': {
					backgroundColor: palette.primary.main,
				},
			}
		),
		centerHorizontal: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		leftContent: {
			flex: 2,
		},
		title: {
			lineHeight: typography.medium?.lineHeight,
			maxWidth: ifFeature('encore', 190, 210),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			userSelect: 'none',
			whiteSpace: 'nowrap',
		},
		rightContent: {
			color: ifFeature('encore', constructs.text.neutral.xWeak, palette.gray[700]),
			fontSize: ifFeature('encore', typography.medium.fontSize, typography.small?.fontSize),
			lineHeight: ifFeature('encore', typography.medium.lineHeight, '19px'),
			userSelect: 'none',
		},
	})
);
