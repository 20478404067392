import { useCallback, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { useVirtualScrollTo } from './use-virtual-scroll-to';
import { UseVirtualizer, UseVirtualizerConfig } from '../types';

/**
 * This hook acts as an abstraction on top of `react-virtual`. It returns virtual items from `react-virtual` but also
 * manages the scroll container ref and scrolls to the active index when it is changed.
 * @param config A configuration object containing the active index and the total number of items
 * @returns An object containing virtual item data and the ref to use on the scroll container
 */
export const useVirtualizer = ({ activeIndex, total }: UseVirtualizerConfig): UseVirtualizer => {
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const rowVirtualizer = useVirtual({
		estimateSize: useCallback(() => 32, []),
		parentRef: scrollContainerRef,
		size: total,
	});

	useVirtualScrollTo(rowVirtualizer.scrollToIndex, activeIndex);

	return {
		overallHeight: rowVirtualizer.totalSize,
		scrollContainerRef,
		virtualItems: rowVirtualizer.virtualItems,
	};
};
