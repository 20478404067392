// eslint-disable-next-line no-use-before-define
import React, { forwardRef, ReactElement, Ref } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/styles';
import { AnimatedBarProps } from '../types/animated-bar-props';
import { useRoundedPath } from './use-rounded-path';
import { useBarVariants } from './use-bar-variants';

const AnimatedBarRenderFunction = <Datum = unknown,>(
	{
		animate,
		bar,
		getBarValue,
		isFirstSegment,
		isLastSegment,
		isOnlyBar,
		onClick,
		onMouseLeave,
		onMouseMove,
		orientation,
		yMax = 0,
		selected,
		clipPath,
	}: AnimatedBarProps<Datum>,
	ref: Ref<SVGPathElement>
): ReactElement => {
	const { color, height, key, width, x, y } = bar;
	const isVertical = orientation === 'vertical';

	const path = useRoundedPath(x, y, width, height, isVertical, isFirstSegment, isLastSegment, false, false);
	const variants = useBarVariants(isVertical, path, width, height, yMax, x, y);
	const {
		mixins: { lighten },
	} = useTheme();

	const handleClick = () => onClick && onClick(bar);
	const handleMouseLeave = () => onMouseLeave && onMouseLeave(bar);
	const handleMouseMove = () => onMouseMove && onMouseMove(bar);

	const mouseProps = {
		onClick: handleClick,
		onMouseLeave: handleMouseLeave,
		onMouseMove: handleMouseMove,
	};

	const fill = selected ? lighten(color, 0.2) : color;
	// key is the data series name; only include it when there's more than one data series
	// spreading this object to avoid linting errors with `title` as a prop
	const barTitle = {
		title: `${isOnlyBar ? '' : key} ${getBarValue?.(bar.bar.data, key as string) || ''}`,
	};
	return animate ? (
		<motion.path
			animate="idle"
			clipPath={`url(#${clipPath})`}
			d={path}
			fill={fill}
			initial="initial"
			variants={variants}
			{...barTitle}
			{...mouseProps}
			ref={ref}
			shapeRendering="crispEdges"
		/>
	) : (
		<path
			clipPath={`url(#${clipPath})`}
			d={path}
			fill={fill}
			{...barTitle}
			{...mouseProps}
			ref={ref}
			shapeRendering="crispEdges"
		/>
	);
};

export const AnimatedBar = forwardRef(AnimatedBarRenderFunction);
