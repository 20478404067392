// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { BarChart } from './bar-chart';
import { BarChartProps } from '../types';

export const BarChartVertical = <Datum = unknown,>({
	xScaleType = 'band',
	yScaleType = 'linear',
	...rest
}: Omit<BarChartProps<Datum>, 'orientation'>): ReactElement => {
	return <BarChart<Datum> xScaleType={xScaleType} yScaleType={yScaleType} {...rest} orientation="vertical" />;
};
