import { orderIndexesByGroup } from './order-indexes-by-group';
import { proxyCallback } from './proxy-callback';
import { MenuListProps } from '../types';

/**
 * Some indexes in a group may not be contiguous inside of their containing array.
 * This function places all indexes in the same group next to each other. It then
 * translates the differences so that other components do not need individually do
 * the same mapping.
 *
 * @param props Properties to be proxied.
 * @returns
 */
export const proxyGroupedIndexes = (props: MenuListProps): MenuListProps => {
	const { getDisabled, getGroup, onSelect, renderItem, total, ...rest } = props;

	if (typeof getGroup !== 'function') {
		return props;
	}

	const order = orderIndexesByGroup(total, getGroup);

	return {
		getDisabled: typeof getDisabled === 'function' ? proxyCallback(getDisabled, order) : undefined,
		getGroup: proxyCallback(getGroup, order),
		onSelect: typeof onSelect === 'function' ? proxyCallback(onSelect, order) : undefined,
		renderItem: (index, props) => {
			const _index = order[index];

			return renderItem(_index, props);
		},
		total,
		...rest,
	};
};
