import { VirtualItem } from 'react-virtual';
import { GetGroup, GutterGroup } from '../types';

/**
 * Returns a GutterGroup object derived from a collection of virtual items. A gutter group
 * represents the bounds vertical bounds of all the items within a group.
 * @param virtualItems A collection of virtual items from `react-virtual`.
 * @param getGroup Function to get the group name based on the index of the item.
 * @returns GutterGroup
 */
export const getGutterGroups = (virtualItems: Array<VirtualItem>, getGroup: GetGroup): Array<GutterGroup> => {
	const groupedVirtualItems = virtualItems.reduce<Record<string, VirtualItem[]>>((memo, virtualItem) => {
		const group = getGroup(virtualItem.index);

		if (typeof group === 'string') {
			const memoRows: Array<VirtualItem> = memo[group] || [];

			if (group) {
				memo[group] = [...memoRows, virtualItem];
			}
		}

		return memo;
	}, {});

	return Object.keys(groupedVirtualItems).map<GutterGroup>(key => {
		const rows = [...groupedVirtualItems[key]].sort((a, b) => a.start - b.start);

		return {
			end: rows[rows.length - 1].end,
			name: key,
			start: Math.max(rows[0].start, 0),
		};
	});
};
