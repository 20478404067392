import { isEqual, omit } from 'lodash';
import React from 'react';

import { CalendarDay } from './calendar-day';
import { CalendarDayProps, DatePickerDate } from '../../types';

const ignoreProps = 'memoizeMode';
type DatePickerCalendarDayMemoizeMode = 'quick' | 'full';
type MemoizedProps<TDate extends DatePickerDate> = { memoizeMode: DatePickerCalendarDayMemoizeMode } & CalendarDayProps<TDate>;

function DatePickerCalendarDayMemoizedComponent<TDate extends DatePickerDate>(props: MemoizedProps<TDate>): JSX.Element {
	const passedProps = omit(props, ignoreProps) as CalendarDayProps<TDate>;

	return <CalendarDay<TDate> {...passedProps} />;
}

function shouldIgnoreRerender<TDate extends DatePickerDate>(
	prevProps: MemoizedProps<TDate>,
	nextProps: MemoizedProps<TDate>
): boolean {
	if (nextProps.memoizeMode === 'quick') {
		return (
			prevProps.focused === nextProps.focused &&
			prevProps.inRange === nextProps.inRange &&
			prevProps.selected === nextProps.selected
		);
	}

	const omittedPrevProps = omit(prevProps, ignoreProps);
	const omittedNextProps = omit(nextProps, ignoreProps);
	return isEqual(omittedPrevProps, omittedNextProps);
}

export const DatePickerCalendarDayMemoized = React.memo<CalendarDayProps<DatePickerDate>>(
	DatePickerCalendarDayMemoizedComponent,
	shouldIgnoreRerender
);
