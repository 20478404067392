import { Variants } from 'framer-motion';
import { useMemo } from 'react';

export const useBarValuesVariants = (isVertical: boolean, x: number, y: number, yMax: number): Variants => {
	return useMemo(() => {
		const axis = isVertical ? 'dy' : 'dx';
		const axisInitVal = isVertical ? yMax : 0;
		const axisIdleVal = isVertical ? y : x;

		return {
			initial: {
				[axis]: axisInitVal,
			},
			idle: {
				[axis]: axisIdleVal,
			},
		};
	}, [isVertical, yMax, x, y]);
};
