import { HeaderActionProps } from './action.types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<Pick<HeaderActionProps, 'active' | 'disabled' | 'focused'>>()((
	{ borderRadiuses, mixins, mode, spacing, typography, constructs },
	{ active, disabled, focused }
) => {
	const primaryColor = mode === ModeOptions.Dye ? dyeColors.main : constructs.icon.primary.strong;
	let color = constructs.icon.neutral.xStrong;
	let cursor: string | undefined;
	let backgroundColor: string | undefined;
	let fontWeight: string | number | undefined;
	let hoverBackgroundColor = constructs.surface.neutral.forcedWhite;
	let hoverColor = primaryColor;

	if (disabled) {
		color = constructs.surface.neutral.strong;
		hoverBackgroundColor = 'unset';
		hoverColor = constructs.surface.neutral.strong;
		cursor = 'unset !important';
	} else if (active) {
		color = primaryColor;
		cursor = 'pointer';
		backgroundColor = constructs.surface.neutral.xWeak;
		fontWeight = typography.fontWeightBold;
		hoverBackgroundColor = constructs.surface.neutral.xWeak;
		hoverColor = primaryColor;
	} else if (focused) {
		color = primaryColor;
		cursor = 'pointer';
		backgroundColor = constructs.surface.neutral.xWeak;
		fontWeight = typography.fontWeightBold;
		hoverBackgroundColor = constructs.surface.neutral.xWeak;
		hoverColor = primaryColor;
	} else {
		cursor = 'pointer';
		hoverBackgroundColor = constructs.surface.neutral.xWeak;
	}

	return {
		root: {
			alignItems: 'center',
			backgroundColor,
			border: 'none',
			borderRadius: borderRadiuses[200],
			boxSizing: 'border-box',
			color,
			cursor,
			display: 'inline-flex',
			fill: color,
			fontWeight,
			gap: spacing(1.5),
			marginLeft: '0',
			padding: spacing(1),
			textIndent: '0',
			'&:hover': {
				backgroundColor: hoverBackgroundColor,
				borderRadius: borderRadiuses[200],
				color: hoverColor,
				fill: hoverColor,
				textDecoration: 'none',
			},
			'&:focus-visible': {
				borderRadius: borderRadiuses[200],
				boxShadow: `0px 0px 0px 2px ${mixins.alpha(constructs.border.primary.strong, 0.8)}`,
			},
		},
	};
});
