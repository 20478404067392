import { CurrencyPickerField } from '~components/currency-picker-field';
import { FormFactoryCurrencyFieldModel } from '../types/builtin-models.types';
import { adapted } from '../utils/adapted';

export const CurrencyField = adapted(CurrencyPickerField)((model: FormFactoryCurrencyFieldModel) => {
	const { id, label, name, onBlur, onChange, required, status, value, width = 5 } = model;
	const [amount = '', code = 'USD'] = value || [];

	return {
		id,
		label,
		onBlur,
		onChange: (value: string) => {
			if (typeof onChange === 'function') {
				onChange({ id, value: [value, code] });
			}
		},
		onSelectCurrency: currencyItem => {
			if (typeof onChange === 'function') {
				onChange({ id, value: [amount, currencyItem.code] });
			}
		},
		inputNames: { amount: `${name}[0]`, currency: `${name}[1]` },
		required,
		selectedCurrencyCode: code,
		status,
		value: amount,
		width,
	};
});
