import { useTheme } from '@mui/styles';
import { getThemedButton } from './get-themed-button';
import { border, px } from './mixins';

const headerButtonPadding = '6px 40px';

export const useFlatfileTheme = (): Record<string, unknown> => {
	const theme = useTheme();

	const { palette, typography } = theme;
	return {
		global: {
			backgroundColor: palette.background.default,
			textColor: palette.primary.main,
			primaryTextColor: palette.text.primary,
			secondaryTextColor: 'pink',
			successColor: palette.success.main,
			warningColor: palette.warning.main,
			fontFamily: typography.fontFamily,
		},
		buttons: {
			primary: getThemedButton(theme, 'primary'),
			secondary: getThemedButton(theme, 'secondary', true),
			tertiary: getThemedButton(theme, 'secondary', true),
			success: getThemedButton(theme, 'primary'),
			headerMatchYes: {
				...getThemedButton(theme, 'primary'),
				padding: headerButtonPadding,
			},
			headerMatchNo: {
				...getThemedButton(theme, 'secondary', true),
				padding: headerButtonPadding,
			},
		},
		progressBar: {
			root: {
				backgroundColor: palette.background.paper,
				borderRadius: px(0),
				borderTop: 'none',
				borderBottom: 'none',
				// padding: px(20),
			},
			current: {
				color: palette.text.primary,
				fontWeight: 'bold',
			},
			complete: {
				color: palette.success.main,
			},
			incomplete: {
				color: palette.gray[700],
			},
		},
		header: {
			root: {
				backgroundColor: palette.background.paper,
			},
		},
		footer: {
			root: {
				backgroundColor: palette.background.paper,
			},
		},
		headerMatch: {
			root: {
				backgroundColor: palette.background.paper,
			},
			content: {
				backgroundColor: palette.background.default,
				border: border(1, palette.gray[200]),
				borderRadius: px(0),
			},
			icon: {
				fill: palette.primary.main,
			},
			table: {
				th: {
					backgroundColor: palette.gray[200],
					color: palette.gray[800],
					borderColor: palette.gray[200],
				},
				td: {
					color: palette.text.primary,
					borderColor: palette.gray[300],
				},
			},
		},
		dropzone: {
			root: {
				backgroundColor: palette.background.paper,
			},
			content: {
				backgroundColor: palette.background.paper,
				border: border(1, palette.primary.lightest, 'dashed'),
			},
			fileTypes: {
				border: 'none',
				color: palette.text.primary,
			},
			accepted: {
				color: palette.text.primary,
			},
		},
		columnMatch: {
			root: {
				backgroundColor: palette.background.paper,
			},
			content: {
				color: palette.text.primary,
			},
			rule: {
				color: palette.text.primary,
			},
			autoMatchRule: {
				root: {
					color: palette.text.primary,
				},
				field: {
					color: palette.text.primary,
					// backgroundColor: palette.primary.lightest,
					borderRadius: px(0),
					fontWeight: 'bold',
				},
				description: {
					color: 'green',
				},
				icon: {
					fill: palette.success.main,
				},
			},
		},
		dialog: {
			root: {
				backgroundColor: palette.background.default,
			},
			content: {
				backgroundColor: palette.background.default,
			},
			footer: {
				backgroundColor: palette.background.paper,
				border: 'none',
			},
			overlayColor: palette.gray[100],
		},
		loader: {
			root: {
				backgroundColor: palette.background.default,
				color: palette.text.secondary,
			},
			overlayColor: palette.gray[100],
		},
	};
};
