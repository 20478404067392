import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The horizontal line used to separate each `SideTabGroup`.
 *
 * You likely won't need to use this directly as it's included in `SideTabGroup`.
 */
export const SideTabDivider = styled('hr')<{ theme: Theme }>(({ theme }) => {
	const { mode, palette, spacing } = theme as Theme;
	return {
		'&:not(:first-child)': {
			border: 0,
			borderTop: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : `1px solid ${palette.grey[400]}`,
			marginTop: spacing(1.25),
			marginBottom: spacing(2),
		},
	};
});
