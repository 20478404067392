import React from 'react';
// @startCleanup encore
import {
	CircleInfo36x36,
	CheckCircle36x36,
	CircleExclamation36x36,
	TriangleExclamation36x36,
	CircleExclamation18x18,
	CircleInfo18x18,
	CheckCircle18x18,
	TriangleExclamation16x16,
	GrimIcon,
} from '@bamboohr/grim';
// @endCleanup encore
import { Theme } from '@mui/material';
import { InlineMessageStatusType, InlineMessageVariant, InlineMessageProps } from './inline-message.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, IconV2Name, IconV2Color } from '../icon-v2';
// @startCleanup encore
const iconMap: Record<string, GrimIcon> = {
	CheckCircle18x18,
	CheckCircle36x36,
	CircleExclamation18x18,
	CircleExclamation36x36,
	CircleInfo18x18,
	CircleInfo36x36,
	TriangleExclamation16x16,
	TriangleExclamation36x36,
};

const getSizedIcon = (iconName: string, fill: string) => {
	const Icon = iconMap[iconName];

	return <Icon fill={fill} />;
};
// @endCleanup encore

const getStatusIcon = (iconName: IconV2Name, color: IconV2Color) => {
	return <IconV2 color={color} name={iconName} size={16} />;
};

export const getIcon = (props: InlineMessageProps, palette: Theme['palette']) => {
	const { icon, status, description } = props;
	// @startCleanup encore
	// If description is present, default to larger icon size of 36x36, otherwise 18x18
	const size = description ? '36x36' : '18x18';
	// @endCleanup encore

	if (icon === undefined) {
		switch (status) {
			case InlineMessageStatusType.discovery:
				return ifFeature('encore', getStatusIcon('gift-solid', 'discovery-strong'));
			case InlineMessageStatusType.error:
				return ifFeature(
					'encore',
					getStatusIcon('circle-exclamation-solid', 'error-strong'),
					getSizedIcon(`CircleExclamation${size}`, palette.error.main)
				);
			case InlineMessageStatusType.info:
				return ifFeature(
					'encore',
					getStatusIcon('circle-info-solid', 'info-strong'),
					getSizedIcon(`CircleInfo${size}`, palette.info.main)
				);
			case InlineMessageStatusType.success:
				return ifFeature(
					'encore',
					getStatusIcon('circle-check-solid', 'success-strong'),
					getSizedIcon(`CheckCircle${size}`, palette.success.main)
				);
			case InlineMessageStatusType.warning:
				return ifFeature(
					'encore',
					getStatusIcon('triangle-exclamation-solid', 'warning-strong'),
					getSizedIcon(`TriangleExclamation${size}`, palette.warning.main)
				);
			default:
				return ifFeature('encore', undefined, getSizedIcon(`CircleInfo${size}`, palette.gray[800]));
		}
	} else if (typeof icon === 'string') {
		return ifFeature('encore', getStatusIcon(icon, status ? `${status}-strong` : 'neutral-extra-strong'));
	}

	return icon;
};

export const getBgFill = (
	variant: InlineMessageVariant,
	status: InlineMessageStatusType | undefined,
	palette: Theme['palette']
): string => {
	if (variant === InlineMessageVariant.light) {
		return palette.background.default;
	}
	switch (status) {
		case InlineMessageStatusType.error:
			return palette.error.light;
		case InlineMessageStatusType.info:
			return palette.info.light;
		case InlineMessageStatusType.success:
			return palette.success.light;
		case InlineMessageStatusType.warning:
			return palette.warning.light;
		default:
			return palette.gray[100];
	}
};
