import { styled } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(theme => {
	const { palette, mode, typography, constructs } = theme;

	return {
		container: {
			borderRadius: ifFeature('encore', '12px', undefined),
			color: mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.text.error.strong, palette.error.main),
			fontSize: typography.small?.fontSize,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			textAlign: 'center',
		},
	};
});

export const ConfirmationText = styled('label')({
	display: 'block',
	marginBottom: 8,
});
