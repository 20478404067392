import { MaskConfig, MaskResult, Part } from './types';
import {
	backfillString,
	getFinalPosition,
	getFullMaskStr,
	getParts,
	removeExtraDelimiters,
	repairInvalidDelimiterAddition,
} from './utils';

function getFinalValue(config: MaskConfig, parts: Array<Part>) {
	const mask = getFullMaskStr(config);
	const delimiter = config.delimiterChar;

	let finalValue = parts.map(part => part.conformedValue).join(delimiter);
	if (finalValue === mask) {
		finalValue = '';
	}

	return finalValue;
}

export function getUpdatedMask(
	config: MaskConfig,
	keydownStartPosition: number,
	keydownEndPosition: number,
	keydownInputValue: string,
	startPosition: number,
	endPosition: number,
	inputValue: string
): MaskResult {
	({ endPosition, inputValue, startPosition } = removeExtraDelimiters(config, inputValue, startPosition, endPosition));

	if (!keydownInputValue) {
		const mask = getFullMaskStr(config);

		keydownInputValue = mask;
		keydownStartPosition = 0;
		keydownEndPosition = inputValue.length;
		inputValue = backfillString(inputValue, mask);
	}

	({ endPosition, inputValue, startPosition } = repairInvalidDelimiterAddition(
		config,
		keydownStartPosition,
		keydownEndPosition,
		inputValue,
		startPosition,
		endPosition
	));

	const parts = getParts(
		config,
		keydownInputValue,
		keydownStartPosition,
		keydownEndPosition,
		inputValue,
		startPosition,
		endPosition
	);
	const finalValue = getFinalValue(config, parts);
	const finalPosition = getFinalPosition(
		config,
		parts,
		keydownInputValue,
		keydownStartPosition,
		keydownEndPosition,
		inputValue,
		startPosition,
		finalValue
	);

	return {
		position: finalPosition,
		value: finalValue,
	};
}
