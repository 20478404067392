import { isNotNullOrUndefined } from '~utils';

type Focusable = {
	focus: () => void;
};

type FocusableAndLocatable = Focusable & {
	id: string;
};

export function focusFirstFieldWithError(
	elementsByName: Record<string, HTMLElement | FocusableAndLocatable>,
	errors: Record<string, Array<string>>
) {
	const inputsWithErrors = Object.entries(errors)
		.filter(([, errors]) => errors?.length)
		.map(([name]) => {
			if (typeof name !== 'string') {
				return undefined;
			}

			const element: HTMLElement | FocusableAndLocatable = elementsByName[name];
			if (element instanceof HTMLElement) {
				return element;
			}

			if (typeof element?.id === 'string') {
				return document.getElementById(element.id);
			}
		})
		.filter(isNotNullOrUndefined);

	inputsWithErrors.sort((a, b) => a?.compareDocumentPosition(b));
	inputsWithErrors[0]?.focus();
}
