import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement } from 'react';
import { Divider as FabDivider } from '~components/divider';

export const Divider = (): ReactElement => {
	return (
		<FabDivider
			color={ifFeature('encore', 'neutral-weak')}
			light={ifFeature('encore', undefined, true)}
			margin={ifFeature('encore', 1.5)}
		/>
	);
};
