/* eslint-disable react/forbid-component-props */
// eslint-disable-next-line no-use-before-define
import React, { forwardRef, ReactElement, Ref } from 'react';
import classNames from 'classnames';
import { X10x10 } from '@bamboohr/grim';
import { merge } from 'lodash';

import { TextField } from '~components/text-field';
import { Autocomplete } from './autocomplete';
import { DEFAULT_CLEAR_TEXT, DEFAULT_CLOSE_TEXT, DEFAULT_LOADING_TEXT, DEFAULT_OPEN_TEXT } from './autocomplete.domain';
import { getInputBiId } from './autocomplete-single.domain';
/* @startCleanup encore */
import { useAdditionalStyles, useOverrideStyles } from './autocomplete-single.styles';
/* @endCleanup encore */
import { AutocompleteSingleProps, AutocompleteSingleOnChangeParam } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

function AutocompleteComponent<T>(
	{
		allowAnyInputValue = true,
		autoHighlight = false,
		biId,
		/* @startCleanup encore */
		classes = {},
		/* @endCleanup encore */
		disabled = false,
		filterSelectedOptions = false,
		groupBy,
		hasPopupToggle = false,
		id,
		inputProps,
		inputRef,
		inputValue,
		label,
		loading = false,
		loadingText = DEFAULT_LOADING_TEXT,
		onChange,
		placeholder,
		renderGroup,
		renderInput,
		required = false,
		size,
		status,
		value,
		variant,
		...restAutocompleteSingleProps
	}: AutocompleteSingleProps<T>,
	ref: Ref<HTMLInputElement>
): ReactElement {
	/* @startCleanup encore */
	const { classes: styledJade } = useOverrideStyles();
	const { classes: additionalStylesJade } = useAdditionalStyles();
	/* @endCleanup encore */

	return (
		<Autocomplete
			{...restAutocompleteSingleProps}
			autoHighlight={autoHighlight}
			autoSelect={false}
			blurOnSelect={false}
			classes={ifFeature('encore', undefined, {
				...classes,
				root: classNames(styledJade.root, classes.root),
			})}
			/* @startCleanup encore */
			clearIcon={ifFeature(
				'encore',
				undefined,
				<div className={additionalStylesJade.clearIcon}>
					<X10x10 />
				</div>
			)}
			/* @endCleanup encore */
			clearOnBlur={!allowAnyInputValue}
			clearOnEscape={false}
			clearText={DEFAULT_CLEAR_TEXT}
			closeOnSelect={true}
			closeText={DEFAULT_CLOSE_TEXT}
			disabled={disabled}
			disableListWrap={false}
			disablePortal={false}
			filterSelectedOptions={filterSelectedOptions}
			fullWidth={true}
			groupBy={groupBy}
			handleHomeEndKeys={true}
			hasClearButton={true} // keeping this true fixes an issue where onChange might not get called if you delete/clear a value.
			hasPopupToggle={hasPopupToggle}
			id={id}
			includeInputInList={!!allowAnyInputValue}
			inputValue={inputValue}
			isAnyInputValueAllowed={allowAnyInputValue}
			loading={loading}
			loadingText={loadingText}
			multiple={false}
			onChange={handleChange}
			openOnFocus={false}
			openText={DEFAULT_OPEN_TEXT}
			ref={ref}
			renderGroup={renderGroup}
			renderInput={params => {
				params.inputProps = merge(params.inputProps, inputProps, {
					required: Boolean(inputProps?.required || required),
				});
				params.inputProps['data-bi-id'] = getInputBiId(biId);
				params.placeholder = placeholder;

				if (renderInput) {
					return renderInput(params);
				}

				return (
					<div ref={params.InputProps.ref}>
						<TextField
							biId={biId}
							disabled={params.disabled}
							id={params.id}
							InputLabelProps={params.InputLabelProps}
							inputProps={params.inputProps}
							// eslint-disable-next-line react/jsx-no-duplicate-props
							InputProps={ifFeature('encore', params.InputProps, { ...params.InputProps, className: styledJade.input })}
							label={label}
							placeholder={value ? undefined : params.placeholder}
							ref={inputRef}
							required={required}
							size={size}
							status={status}
							variant={variant}
							width={100}
						/>
					</div>
				);
			}}
			selectOnFocus={false}
			size={ifFeature('encore', size, 'small')}
			value={value}
			variant={variant}
		/>
	);

	function handleChange(param: AutocompleteSingleOnChangeParam<T>, event: React.SyntheticEvent): void {
		if (param.value === null) {
			param.value = '';
		}

		onChange(param, event);
	}
}

export const AutocompleteSingle = forwardRef(AutocompleteComponent);
