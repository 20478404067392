import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { ConfettiObject } from './ConfettiObject';
import { ConfettiProps } from './types';
import { useConfettiStyles } from './styles';

export const Confetti: FC<PropsWithChildren<ConfettiProps>> = ({
	children,
	canvasClass,
	numParticles = 30,
	setRelative = true,
	isCelebrating = true,
}) => {
	const { classes, cx } = useConfettiStyles();

	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (canvasRef.current) {
			const canvas = canvasRef.current;

			// Start the confetti
			const confettiObject = new ConfettiObject(canvas, numParticles, setRelative);

			return () => {
				confettiObject.stop();
			};
		}
	}, [isCelebrating, numParticles, setRelative]);

	return (
		<>
			{isCelebrating && <canvas className={cx(classes.canvas, canvasClass)} ref={canvasRef} />}
			{children}
		</>
	);
};
