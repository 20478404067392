import { Chat, LlmConfig } from 'src/types/global';
import SideBarGroups from './SideBarGroups';
import { startOfToday, subDays } from 'date-fns';

// Helper function to sort and categorize chat sessions by time_create property
const categorizeChatSessions = (sessions: Chat[]) => {
	// Sort sessions by time_created in descending order
	sessions.sort((a, b) => {
		return b.time_created.getTime() - a.time_created.getTime();
	});

	// Starting a day at midnight is intuitive for this use case
	const today = startOfToday();
	const sevenDaysAgo = subDays(today, 7);
	const thirtyDaysAgo = subDays(today, 30);

	const todaySessions: Chat[] = [];
	const last7DaysSessions: Chat[] = [];
	const last30DaysSessions: Chat[] = [];
	const olderSessions: Chat[] = [];

	sessions.forEach((session) => {
		const sessionDate = session.time_created;
		if (sessionDate >= today) {
			todaySessions.push(session);
		} else if (sessionDate >= sevenDaysAgo) {
			last7DaysSessions.push(session);
		} else if (sessionDate >= thirtyDaysAgo) {
			last30DaysSessions.push(session);
		} else {
			olderSessions.push(session);
		}
	});

	return {
		todaySessions,
		last7DaysSessions,
		last30DaysSessions,
		olderSessions,
	};
};

interface NavContentProps {
	loadChatLogs: (
		chatID: string,
		chatName: string,
		label: string,
		id: string,
		newConfig: LlmConfig,
	) => void;
	chatNames: Chat[];
	setChatNames: (chats: Chat[]) => void;
	setChatName: (name: string) => void;
	activeId: string;
}

const NavContent = ({
	loadChatLogs,
	chatNames,
	setChatNames,
	setChatName,
	activeId,
}: NavContentProps) => {
	const sessions = categorizeChatSessions([...chatNames]);

	return (
		<div className="navPromptWrapper">
			{Object.entries(sessions).map(([key, value]) => (
				<SideBarGroups
					key={key}
					loadChatLogs={loadChatLogs}
					sessionTitle={key}
					chats={value}
					chatNames={chatNames}
					setChatNames={setChatNames}
					setChatName={setChatName}
					activeId={activeId}
				/>
			))}
		</div>
	);
};

export default NavContent;
