import { DragEvent, DragEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { SLIDEDOWN_TYPES } from '~components/slidedown';
import { UseFileDropperProps, FileDropperUploadErrors, UseFileDropperType, FileDropperValidatedFiles } from '../file-dropper.types';
import { documentFileTypes, imageFileTypes } from './constants';
import { applyError } from './utils';

export const useFileDropper = ({
	acceptedTypes = [],
	canSelectMultiple = false,
	maxByteSize = undefined,
	onFileDrop = () => console.error('No handler function provided to useFileDropper'),
	permission = true,
}: UseFileDropperProps): UseFileDropperType => {
	const [dragReference, setDragReference] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [messageProps, setMessageProps] = useState<UseFileDropperType['messageProps']>();

	const resetMessageProps = () => {
		setMessageProps(undefined);
	};

	const resetDrag = () => {
		setIsDragging(false);
		setDragReference(0);
	};

	const derivedAcceptedTypes = useMemo(
		() =>
			acceptedTypes && acceptedTypes.length
				? acceptedTypes.reduce((acc, ext) => {
						if (ext === 'documents') {
							acc = [...acc, ...documentFileTypes];
						} else if (ext === 'images') {
							acc = [...acc, ...imageFileTypes];
						} else {
							acc.push(ext);
						}
						return acc;
					}, [] as string[])
				: // If there is no list passed in, or it's empty, default to the full standard list of file types.
					[...documentFileTypes, ...imageFileTypes],
		[acceptedTypes]
	);

	const validateFile = useCallback(
		(acc: FileDropperValidatedFiles, file: File) => {
			const ext = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
			if (!derivedAcceptedTypes.includes(ext)) {
				// Bad file type
				return applyError(acc, FileDropperUploadErrors.InvalidExtension, ext);
			}
			if (maxByteSize && file.size > maxByteSize) {
				// File too large
				return applyError(acc, FileDropperUploadErrors.FileTooLarge, file.name);
			}
			acc.files.push(file);
			return acc;
		},
		[derivedAcceptedTypes, maxByteSize]
	);

	const handleFiles = useCallback(
		(rawFiles: FileList) => {
			const { files, errors } = Object.values(rawFiles).reduce(validateFile, { files: [], errors: [] });
			if (errors.length) {
				const errorMessage = `${errors[0].message}${errors[0].data.join(', ')}`;
				setMessageProps({
					message: errorMessage,
					onDismiss: resetMessageProps,
					show: true,
					type: SLIDEDOWN_TYPES.error,
				});
			}
			if (files.length) {
				if (canSelectMultiple) {
					onFileDrop(files);
				} else {
					if (files.length > 1) {
						setMessageProps({
							description: window.jQuery
								? $.__(
										'You dragged in multiple files, but only one can be added here. Make sure the file you intended to add got uploaded.'
									)
								: 'You dragged in multiple files, but only one can be added here. Make sure the file you intended to add got uploaded.',
							message: window.jQuery
								? $.__('Heads up! Only one file was uploaded, make sure it’s the one you want.')
								: 'Heads up! Only one file was uploaded, make sure it’s the one you want.',
							onDismiss: resetMessageProps,
							show: true,
							type: SLIDEDOWN_TYPES.warning,
						});
					}
					onFileDrop([files[0]]);
				}
			}
			resetDrag();
		},
		[canSelectMultiple, onFileDrop, validateFile]
	);

	const fileDrop = useCallback<DragEventHandler>(
		(e: DragEvent) => {
			e.preventDefault();
			const { files } = e.dataTransfer;
			if (files.length) {
				handleFiles(files);
			}
			resetDrag();
		},
		[handleFiles]
	);

	const fileDropAttributes = useMemo(() => {
		if (!permission) {
			return {};
		}
		const prevent: DragEventHandler = e => {
			if (e.dataTransfer.types.includes('Files')) {
				e.preventDefault();
			}
		};

		const increment: DragEventHandler = e => {
			if (e.dataTransfer.types.includes('Files')) {
				setDragReference(prev => prev + 1);
				e.preventDefault();
			}
		};

		const decrement: DragEventHandler = e => {
			if (e.dataTransfer.types.includes('Files')) {
				e.preventDefault();
				setDragReference(prev => prev - 1);
			}
		};

		return {
			onDragEnter: increment,
			onDragLeave: decrement,
			onDragOver: prevent,
			onDrop: fileDrop,
		};
	}, [fileDrop, permission]);

	useEffect(() => {
		if (dragReference === 0) {
			setIsDragging(false);
		} else {
			setIsDragging(true);
		}
	}, [dragReference]);

	return { fileDropAttributes, isDragging, messageProps };
};
