import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SideNavigationLinkProps } from './link.types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import type { SideNavigationContextValue } from '../side-navigation-context';

export const useStyles = makeStyles<
	Pick<SideNavigationLinkProps, 'active' | 'disabled'> & Pick<SideNavigationContextValue, 'compact'>
>()(({ constructs, borderRadiuses, mode, palette, spacing, typography }, { active, compact, disabled }) => {
	const primaryColor = mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main;
	let color = palette.gray[800];
	let cursor: string | undefined;
	let backgroundColor: string | undefined;
	let fontWeight: string | number | undefined;
	let hoverBackgroundColor = palette.common.white;
	let hoverColor = primaryColor;

	if (ifFeature('encore', true)) {
		if (disabled) {
			color = palette.gray[600];
			hoverBackgroundColor = 'unset';
			hoverColor = palette.gray[600];
			cursor = 'default';
		} else if (active) {
			color = palette.common.white;
			cursor = 'default';
			backgroundColor = primaryColor;
			fontWeight = typography.fontWeightBold;
			hoverBackgroundColor = primaryColor;
			hoverColor = palette.common.white;
		} else {
			hoverBackgroundColor = constructs.surface.neutral.inverted;
		}
	} else if (active) {
		color = primaryColor;
		fontWeight = '800';
	}

	return {
		root: {
			alignItems: 'center',
			backgroundColor,
			border: 'none',
			borderRadius: ifFeature('encore', borderRadiuses[200], '2px'),
			boxSizing: 'border-box',
			color,
			cursor,
			display: 'flex',
			fill: color,
			fontWeight,
			gap: ifFeature('encore', compact ? spacing(1) : spacing(1.5), '8px'),
			marginLeft: '0',
			overflowWrap: 'anywhere',
			padding: ifFeature('encore', compact ? spacing(1, 1.5) : spacing(1.5, 2), '9px 10px'),
			textIndent: '0',
			'&:hover': {
				backgroundColor: hoverBackgroundColor,
				borderRadius: ifFeature('encore', borderRadiuses[200], '4px'),
				color: hoverColor,
				fill: hoverColor,
				textDecoration: 'none',
			},
			...ifFeature(
				'encore',
				{
					'&:focus-visible': {
						borderRadius: borderRadiuses[200],
						boxShadow: `0 0 0 2px color-mix(in srgb, ${palette.primary.light} 80%, transparent)`,
						color: hoverColor,
						fill: hoverColor,
					},
				},
				{}
			),
		},
		// @startCleanup encore
		icon: ifFeature(
			'encore',
			{},
			{
				boxSizing: 'border-box',
				display: 'inline-flex',
				flexDirection: 'column',
				height: '22px',
				justifyContent: 'center',
				width: '20px',
			}
		),
		// @endCleanup encore
	};
});
