import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import React, { Component, Fragment } from 'react';
import { Loader } from '~components/loader';

import { isAtTheBottom, isBelowFold, isScrollable } from './util';

export const DEFAULT_INITIAL_PAGE = 1;
export const DEFAULT_SCROLL_OFFSET = 250;

export default class Infinite extends Component {
	get pagesLoaded() {
		const { initialPage } = this.props;

		const { pagesLoaded } = this.state;

		let _pagesLoaded = pagesLoaded;
		if (!_pagesLoaded) {
			_pagesLoaded = typeof initialPage === 'number' ? initialPage : DEFAULT_INITIAL_PAGE;
		}
		return _pagesLoaded;
	}

	addScrollEvent() {
		const { wrapper, hasMore, preloadUntilScroll } = this.props;

		if (preloadUntilScroll) {
			this.preloadUntilScroll();
		}

		if (!this._preloadingUntilScroll) {
			this.eventNode = this.eventNode || document.querySelector(wrapper) || window;

			if (hasMore) {
				this.eventNode.addEventListener('scroll', this.handleScroll);
			}
		}
	}

	destroyScrollEvent() {
		const { wrapper } = this.props;
		const _eventNode = this.eventNode || document.querySelector(wrapper) || window;

		_eventNode.removeEventListener('scroll', this.handleScroll);
	}

	getMore() {
		const { currentPage = this.pagesLoaded, fetchMore, loadMore = noop, endpoint, pageSize } = this.props;

		const nextPage = currentPage + 1;

		let promise;

		this.setState({ loading: true });

		if (endpoint) {
			promise = fetchMore({
				limit: pageSize,
				startsAt: currentPage * pageSize,
			});
		} else {
			promise = Promise.all([loadMore({ currentPage, nextPage })]);
		}

		return promise.then(() => {
			return new Promise((resolve, reject) => {
				if (!this._isMounted) {
					return reject();
				}
				return this.setState({ pagesLoaded: nextPage }, resolve);
			});
		});
	}

	/**
	 * Gets more content, then on completion disables loading state, and adds the scroll event.
	 */
	getMoreOnce() {
		return this.getMore().then(() => {
			// Need to make sure that the state is up to date before adding the event and calling for more
			this.setState({ loading: false }, () => this.addScrollEvent());
		}, noop);
	}

	/**
	 * Preloads data until it flows below the bottom of the window
	 */
	preloadUntilBelowFold() {
		const {
			hasMore,
			onPreloadComplete,
			preloadUntilBelowFold: { current },
		} = this.props;

		const { loading } = this.state;

		if (!current) {
			return;
		}

		if (!hasMore || isBelowFold(current)) {
			if (loading) {
				this.setState({ loading: false });
			}

			if (hasMore) {
				this.addScrollEvent();
			}

			if (isFunction(onPreloadComplete)) {
				onPreloadComplete();
			}
			this._preloadingUntilBelowFold = false;
			return;
		}

		this._preloadingUntilBelowFold = true;

		this.getMore().then(() => this.preloadUntilBelowFold(), noop);
	}

	preloadUntilScroll() {
		const { hasMore, onPreloadComplete } = this.props;

		// if you need lazy loading until page is scrollable, this calls loadMore callback and prevents scroll listener until true
		if (!isScrollable(this.wrapper) && hasMore) {
			this._preloadingUntilScroll = true;
			this.getMoreOnce();
			return;
		}

		if (this._preloadingUntilScroll) {
			this._preloadingUntilScroll = false;

			if (isFunction(onPreloadComplete)) {
				onPreloadComplete();
			}
		}
	}

	reset() {
		const { preloadUntilBelowFold } = this.props;

		this.destroyScrollEvent();

		this.setState(
			{
				pagesLoaded: undefined,
			},
			() => {
				if (preloadUntilBelowFold) {
					this.preloadUntilBelowFold();
				} else {
					this.addScrollEvent();
				}
			}
		);
	}

	handleResize = debounce(() => {
		const { preloadUntilScroll, preloadUntilBelowFold } = this.props;

		if (preloadUntilBelowFold && !this._preloadingUntilBelowFold) {
			this.preloadUntilBelowFold();
		} else if (preloadUntilScroll && !this._preloadingUntilScroll) {
			this.preloadUntilScroll();
		}
	}, 100);

	handleScroll = debounce(e => {
		const { offset = DEFAULT_SCROLL_OFFSET, onScroll, wrapper } = this.props;

		const { loading } = this.state;

		const scrollElement = wrapper ? this.wrapper : this.eventNode;

		if (onScroll) {
			onScroll(e);
		}

		if (!loading) {
			if (isAtTheBottom(this.wrapper, scrollElement, offset)) {
				this.destroyScrollEvent();
				this.getMoreOnce();
			}
		}
	}, 100);

	eventNode = null;

	wrapper = null;

	state = {
		loading: false,
	};

	componentDidMount() {
		this._isMounted = true;
		const { preloadUntilBelowFold, wrapper } = this.props;
		this.wrapper = document.querySelector(wrapper || 'body');

		if (preloadUntilBelowFold) {
			this.preloadUntilBelowFold();
		} else {
			this.addScrollEvent();
		}

		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.destroyScrollEvent();
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		const { children, loader } = this.props;
		const { loading } = this.state;

		return (
			<Fragment>
				{children}
				{loading && (loader || <Loader type="dot" />)}
			</Fragment>
		);
	}
}
