import { makeStyles } from '~styles';

export const useCrossFaderStyles = makeStyles()({
	crossfader: {
		position: 'relative',
		'& > div': {
			position: 'absolute',
		},
	},
	alignRight: {
		'& > div': {
			right: 0,
		},
	},
	alignBottom: {
		'& > div': {
			bottom: 0,
		},
	},
});
