import { ValueEvent } from '../types/value-event';

export const getValue = (value: unknown): Omit<ValueEvent, 'target'> | Record<string, unknown> | null => {
	if (typeof value === 'boolean') {
		return {
			checked: value,
		};
	}

	if (typeof value === 'object') {
		return value as Record<string, unknown>;
	}

	return { value };
};
