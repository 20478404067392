import { makeStyles } from '~styles';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

export const useDropdownCellEditorStyles = makeStyles()(() => {
	return {
		root: {
			background: 'none',
			color: 'inherit',
			border: 'none',
			font: 'inherit',
			cursor: 'pointer',
			outline: 'inherit',
			textAlign: 'inherit',
			width: '100%',
			height: '100%',
			padding: ifFeature('encore', 0, '0 17px'),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		formattedValue: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		menuWrapper: {
			height: '100%',
			'& > div': {
				height: '100%',
			},
		},
	};
});
