import { makeStyles } from '~styles';

export const useStyles = makeStyles<{ active: boolean; completed: boolean }>()((theme, props) => {
	const {
		borderRadiuses,
		spacing,
		constructs: { surface, text },
		typography,
	} = theme;
	const { active, completed } = props;

	return {
		root: {
			alignItems: 'center',
			backgroundColor: active ? surface.neutral.xWeak : 'transparent',
			borderRadius: borderRadiuses[400],
			display: 'flex',
			gap: spacing(1.5),
			overflow: 'hidden',
			padding: `${spacing(2)} ${spacing(2.5)}`,
		},
		stepLabel: {
			color: active || completed ? text.primary.strong : text.neutral.strong,
			fontSize: typography.large.fontSize,
			fontWeight: active ? typography.fontWeightBold : typography.fontWeightMedium,
			lineHeight: typography.large.lineHeight,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	};
});
