import { ReactNode } from 'react';
import { EnumAndStringUnion } from '~utils';
import { IconV2Name } from '~components/icon-v2';

export enum PositionInTimeline {
	START = 'start',
	MIDDLE = 'middle',
	END = 'end',
}

export type ItemClasses = {
	/**
	 * Override or extend the styles applied to the root <li> element.
	 */
	itemRoot?: string;
	/**
	 * Override or extend the styles applied to the horizontal timeline item wrapper element.
	 */
	wrapper?: string;
	/**
	 * Override or extend the styles applied to the icon element.
	 */
	icon?: string;
	/**
	 * Override or extend the styles applied to the content element.
	 */
	content?: string;
	/**
	 * Override or extend the styles applied to the primary text element.
	 */
	contentPrimary?: string;
	/**
	 * Override or extend the styles applied to the secondary text element.
	 */
	contentSecondary?: string;

	/**
	 * Override or extend the styles applied to the number when no icon is passed in.
	 */
	number?: string;
};

export type TimelineClasses = ItemClasses & {
	/**
	 * Override or extend the styles applied to the root <ul> element.
	 */
	root?: string;
};

export type TimelineItemClasses = ItemClasses & {
	/**
	 * Override or extend the styles applied to secondary-color icons
	 */
	colorSecondary?: string;
	/**
	 * Override or extend the styles applied to the icon wrapper
	 */
	iconWrapper?: string;
};

export type TimelineItem = {
	/**
	 * @deprecated number - use a string value for ID instead
	 */
	id?: string | number;
	/**
	 * If the value is a string, it will be treated as the name of a fabric icon and render the FabricIcon component. Using a string for the name of the icon is considered deprecated.
	 * If no icon is provided, the index number will be used in its place
	 * In encore, the icon will only be rendered if an IconV2Name string is passed in
	 */
	icon?: ReactNode;

	/**
	 * The color of the Timeline items.
	 *
	 * Default: `"primary"`.
	 */
	color?: 'primary' | 'secondary';
	/**
	 * Override or extend the styles applied to the component.
	 * @deprecated Styles should no longer be overridden from outside of Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	classes?: Partial<TimelineItemClasses>;
	/**
	 * Is the event the First or Last element to be displayed.
	 *
	 */
	positionInTimeline?: EnumAndStringUnion<PositionInTimeline>;
	/**
	 * The size of an individual Timeline event.
	 *
	 * Default: `"large"`.
	 */
	size?: 'teenie' | 'small' | 'medium' | 'large';

	/**
	 * Set the progress line to primary color
	 *
	 */
	isActive?: boolean;

	/**
	 * Used to change icon fill to white and its background to the brand color
	 */
	isInverted?: boolean;
	/**
	 * Display the event in a default state, (Horizontal Only).
	 *
	 * Default: `"false"`.
	 */
	isDisabled?: boolean;
	/**
	 * The Top line text for the Event.
	 *
	 * An Array will be split and encapsulated by a classless `<div>`
	 *
	 * Base styles
	 *
	 * ```css
	 * fontSize: 15px;
	 * lineHeight: 22px;
	 * color: gray10;
	 * ```
	 */
	primaryText?: string | Array<string> | JSX.Element;

	/**
	 * If an icon isn't passed in, we will display the current step on the timeline
	 */
	index?: number;

	/**
	 * The Bottom text for the Event.
	 *
	 * An Array will be split and encapsulated by a classless `<div>`
	 *
	 * Base styles
	 *
	 * ```css
	 * fontSize: 14px;
	 * lineHeight: 20px;
	 * color: gray7;
	 * ```
	 */
	secondaryText?: string | Array<string> | JSX.Element;
};

export interface TimelineProps {
	/**
	 * Override or extend the styles applied to the parent component (Horizontal Only).
	 * @deprecated Styles should no longer be overridden from outside of Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	classes?: Partial<TimelineClasses>;
	/**
	 * The color of the Timeline items.
	 *
	 * Default: `"primary"`.
	 */
	color?: 'primary' | 'secondary';
	/**
	 * Array of `TimelineItem` objects used to render each event on the timeline (see Timeline's Event component).
	 * @link ../components/timeline/event:Event
	 */
	items: Array<TimelineItem>;
	/**
	 * The size of the Timeline items.
	 *
	 * Default: `"large"`.
	 */
	size?: 'teenie' | 'small' | 'medium' | 'large';

	/**
	 * This will set the gap between events on a vertical timeline. An event includes the event header, icon, and meta text.
	 * If a number is passed in, it will be treated like spacing(numberValue). If a string is passed in, it will be treated like a CSS value.
	 * We encourage using a number value for consistency. Custom spacing is only possible in the vertical orientation.
	 *
	 * The minimum value is `2.5`.
	 *
	 * Default: `4`.
	 */
	eventGap?: number | string;

	/**
	 * The orientation of the Timeline items.
	 *
	 * Default: `"vertical"`.
	 */
	orientation?: 'vertical' | 'horizontal';
}
