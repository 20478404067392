import { Card, CardContent, CardContentSpacing, CardFlex, CardSize } from '@fabric/card';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { BaseModal, useScreenSizes } from '~components/base-modal';
import { SheetModalBodyProps } from '../sheet-modal.types';
import { BodySize, getSizeModifier, hasBodySize, renderProp } from '~utils';
import { useSheetModalBodyStyles } from './body.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { DefaultHeader } from '~components/standard-modal/default-header';

export const Body: FunctionComponent<PropsWithChildren<SheetModalBodyProps>> & {
	Size: typeof BodySize;
} = props => {
	const { classes, cx } = useSheetModalBodyStyles();
	const { children, renderHeader, renderFooter, size = BodySize.SMALL, ...rest } = props;

	const { isMediumScreen } = useScreenSizes();

	const footer = renderProp(renderFooter);
	const header = renderProp(renderHeader);

	const jadeModalContent = (
		<Card flex={CardFlex.COLUMN} hasBottomGradient={false} size={CardSize.CUSTOM}>
			<CardContent
				canOverflow={false}
				flex={CardFlex.COLUMN}
				spacingOverrides={{
					bottom: CardContentSpacing.NONE,
					left: CardContentSpacing.MEDIUM,
					right: CardContentSpacing.MEDIUM,
					top: CardContentSpacing.NONE,
				}}
			>
				{children}
				{footer}
			</CardContent>
		</Card>
	);

	const encoreModalContent = children;

	return (
		<BaseModal.Body {...rest}>
			<div
				className={cx(classes.root, {
					[classes[getSizeModifier(size as BodySize)]]: !isMediumScreen && hasBodySize(size as BodySize),
				})}
			>
				{ifFeature('encore', header || <DefaultHeader hasCloseButton={true} />, header)}
				{ifFeature('encore', encoreModalContent, jadeModalContent)}
				{ifFeature('encore', footer)}
			</div>
		</BaseModal.Body>
	);
};

Body.Size = BodySize;
