import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ mode, spacing }) => ({
	buttonControlContainer: ifFeature(
		'encore',
		{
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(1),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		{
			boxSizing: 'border-box',
			marginTop: 15,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: '0 16px',
		}
	),
	buttonContainer: {
		marginBottom: 4,
		'&:last-of-type': {
			marginBottom: 0,
		},
	},
}));
