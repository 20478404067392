import { useEffect, useRef } from 'react';
import { pushItemToStack, removeItemFromStack, replaceItemOnStack } from './stack-manager';

/**
 * Invoked when a component has unmounted
 * @param {callback} Function to invoke
 * @param {callbackArgs} arguments to pass to callback
 */
export function useUnmount(callback: (...args: unknown[]) => void, ...callbackArgs: unknown[]): void {
	const latestValuesRef = useRef<unknown[]>([]);
	latestValuesRef.current = callbackArgs;

	useEffect(() => {
		return () => {
			callback(...latestValuesRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export function useOverlayStack(type: string, isOpen: boolean): void {
	const typeRef = useRef('');
	typeRef.current = type;

	const uidRef = useRef('');

	useEffect(() => {
		if (isOpen) {
			const { uid } = pushItemToStack(typeRef.current);
			uidRef.current = uid;
		} else {
			removeItemFromStack(uidRef.current);
			uidRef.current = '';
		}
	}, [isOpen]);

	useEffect(() => {
		if (uidRef.current) {
			replaceItemOnStack(uidRef.current, type);
		}
	}, [type]);

	useUnmount(overlayItemIsOpen => {
		if (overlayItemIsOpen) {
			removeItemFromStack(uidRef.current);
		}
	}, isOpen);
}
