import type { AriaAttributes } from 'react';

/**
 * Takes a component's props with camelCased aria attributes and converts the aria attributes to standard kebab-case suitable for attaching to HTML elements. All other props remain unchanged.
 */
export function formatAriaProps(props: Record<string, unknown>): AriaAttributes {
	const formattedProps = {} as AriaAttributes;

	Object.entries(props).forEach(([key, value]) => {
		// ignore non-aria props and already kebab-cased aria props
		if (!key.startsWith('aria') || key.startsWith('aria-')) {
			formattedProps[key] = value;
		} else {
			formattedProps[`aria-${key.substring(4).toLowerCase()}`] = value;
		}
	});

	return formattedProps;
}
