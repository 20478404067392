import type { HTMLAttributes } from 'react';
import { formatAriaProps } from './format-aria-props';
import type { BaseProps } from './types';

/**
 * Converts common props to valid HTML attributes.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatBaseProps<Props extends Record<string, any>>(props: Props): FormattedBaseProps<Props> {
	const { biId, ...rest } = props as Partial<BaseProps>;
	const formattedProps = formatAriaProps(rest) as FormattedBaseProps<Props>;

	if (biId) {
		formattedProps['data-bi-id'] = biId;
	}

	return formattedProps;
}

type FormattedBaseProps<Props> = Omit<Props, keyof BaseProps> & HTMLAttributes<Element> & { 'data-bi-id'?: string };
