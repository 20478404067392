import React, { cloneElement, ReactElement, useMemo } from 'react';
import { StandardModal } from '~components/standard-modal';
import { MultiStepHeader } from './multi-step-header';
import { MultiStepModalProps } from './multi-step-modal.types';
import { useStepClasses } from './use-step-classes';
import useStyles from './multi-step-modal.style';
import { uniqueId } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const getStepId = (step: number, prefix: string) => `${prefix}-${step}`;

/**
 * Renders a series of modal body components inside a Fabric StandardModal.
 * Includes animations when transitioning between steps
 * and a step number indicator in the header.
 */
export function MultiStepModal({
	ariaLabelledby,
	currentStep,
	hasCloseButton,
	isOpen,
	biId,
	renderStepsContainer = ({ children }) => children,
	steps,
	title,
	...props
}: MultiStepModalProps): ReactElement {
	const { classes, cx } = useStyles();
	const stepClasses = useStepClasses(steps.length, currentStep, classes, cx);
	const [headerIdFallback] = useMemo(() => uniqueId('modal-header'), []);

	return (
		<StandardModal
			aria-live="polite"
			ariaLabelledby={ariaLabelledby || getStepId(currentStep, headerIdFallback)}
			contentStyles={ifFeature('encore', { padding: 0 })}
			isOpen={isOpen}
			{...props}
		>
			{renderStepsContainer({
				children: steps.map((step, index) => {
					const wrapperBiId = biId ? `${biId}-${step.props?.biId || index}` : '';
					return (
						<div className={classes.wrapper} key={step.key}>
							<div className={stepClasses[index]} data-bi-id={wrapperBiId}>
								{cloneElement(step, {
									renderHeader: (
										<MultiStepHeader
											biId={wrapperBiId ? `${wrapperBiId}-close-btn` : ''}
											hasCloseButton={hasCloseButton}
											id={getStepId(index, headerIdFallback)}
											renderHeader={step.props?.renderHeader}
											stepNumber={index}
											title={title}
											totalSteps={steps.length}
										/>
									),
								})}
							</div>
						</div>
					);
				}),
			})}
		</StandardModal>
	);
}
