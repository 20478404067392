import React, { ReactElement, useCallback } from 'react';
import { Tooltip } from '../../tooltip';
import { Statuses } from '../constants';
import { VerticalWizardStepProps } from './step.types';
import { useStyles } from './step.styles';
import { getEncoreIcon, getJadeIcon } from './get-icon';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2Name } from '~components/icon-v2';

export function Step({
	active,
	biId,
	children,
	disabled,
	hideIcon,
	icon,
	note,
	onClick,
	status,
	TooltipProps,
}: VerticalWizardStepProps): ReactElement {
	const { classes, cx } = useStyles();

	const getIconCb = useCallback((): ReactElement | null => {
		return ifFeature(
			'encore',
			getEncoreIcon(active, icon as IconV2Name, status, disabled, hideIcon),
			getJadeIcon(icon as ReactElement, status, disabled, hideIcon)
		);
	}, [active, status, disabled, hideIcon, icon]);

	const stepButton = (
		<button
			aria-disabled={disabled}
			className={classes.button}
			disabled={disabled && !TooltipProps}
			onClick={() => (disabled ? undefined : onClick?.())}
			type="button"
		>
			{ifFeature(
				'encore',
				getIconCb(),
				<span className={hideIcon ? `${classes.iconWrapper} ${classes.hideIcon}` : classes.iconWrapper}>{getIconCb()}</span>
			)}
			<span className={classes.title}>{children}</span>
			{note ? <span className={classes.note}>{note}</span> : null}
		</button>
	);

	return (
		<li
			className={cx(classes.root, {
				[classes.active]: active,
				[classes.disabled]: disabled,
				[classes.error]: status === Statuses.error,
				[classes.success]: status === Statuses.success,
			})}
			data-bi-id={biId}
		>
			{TooltipProps ? <Tooltip {...TooltipProps}>{stepButton}</Tooltip> : stepButton}
		</li>
	);
}
