/**
 * Hacky workaround to prevent `useTabsList` (used by `TabsListUnstyled`
 * under the hood) from trying to focus non-tab components (such as the
 * divider and group header). Passing `disabled` as a prop doesn't work,
 * but manually assigning it does.
 */
export function disableKeyboardFocus(el: HTMLElement | null) {
	if (!el) {
		return;
	}
	(el as HTMLInputElement).disabled = true;
}
