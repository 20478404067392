import type { IconV2Name, IconV2Size } from '~components/icon-v2';
import type { FileType, IconSize } from './types';

// @startCleanup encore
export const DEFAULT_ICON_SIZE = 'teenie';
export const ICON_SIZE_TEENIE = '16x20';
export const ICON_SIZE_SMALL = '20x24';
export const ICON_SIZE_MEDIUM = '23x28';
export const ICON_SIZE_LARGE = '30x36';
export const ICON_SIZES = {
	teenie: ICON_SIZE_TEENIE,
	small: ICON_SIZE_SMALL,
	medium: ICON_SIZE_MEDIUM,
	large: ICON_SIZE_LARGE,
};
// @endCleanup encore

export const ICON_SIZE_MAP: Record<IconSize, IconV2Size> = {
	teenie: 20,
	small: 24,
	medium: 28,
	large: 36,
};

export const FILE_TYPE_TO_NAME_MAP: Record<FileType, IconV2Name> = {
	archive: 'file-zipper-regular',
	default: 'file-regular',
	doc: 'file-lines-regular',
	excel: 'file-spreadsheet-regular',
	email: 'file-lines-regular',
	esig: 'file-contract-regular',
	image: 'file-image-regular',
	img: 'file-image-regular',
	link: 'file-regular',
	mail: 'file-lines-regular',
	pdf: 'file-lines-regular',
	powerpoint: 'file-chart-pie-regular',
	ppt: 'file-chart-pie-regular',
	text: 'file-lines-regular',
	txt: 'file-lines-regular',
	webpage: 'file-lines-regular',
	xls: 'file-spreadsheet-regular',
	zip: 'file-zipper-regular',
};
