import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { HeaderProps } from './types';
import { PropsWithChildren } from 'react';
export const useStyles = makeStyles<Partial<PropsWithChildren<HeaderProps>>>()((
	{ mixins, mode, palette, spacing, typography, constructs },
	{ borderBottom, dark, position, children, actions }
) => {
	const jadeBackgroundColor = dark ? palette.gray[900] : palette.gray[200];

	return {
		guts: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', undefined, jadeBackgroundColor),
			borderBottom: ifFeature('encore', '1px solid transparent'),
			boxShadow: ifFeature('encore', '0 0 transparent'),
			transition: 'border-bottom 0.3s ease-out, box-shadow 0.3s ease-out',
			boxSizing: ifFeature('encore', 'content-box', 'border-box'),
			display: 'flex',
			gap: ifFeature('encore', spacing(2), undefined),
			fontWeight: typography.fontWeightMedium,
			height: ifFeature('encore', 42, 80),
			minWidth: 1124,
			justifyContent: ifFeature('encore', 'space-between', undefined),
			overflow: ifFeature('encore', 'visible', 'hidden'),
			position: position === 'fixed' || position === 'sticky' ? position : 'relative',
			paddingTop: ifFeature('encore', spacing(3), undefined),
			paddingBottom: ifFeature('encore', spacing(3), 3),
			paddingLeft: ifFeature('encore', 0, spacing(4)),
			paddingRight: ifFeature('encore', 0, spacing(4)),
			'&:after': {
				backgroundImage: ifFeature(
					'encore',
					undefined,
					`linear-gradient(90deg, ${palette.primary.lightest} 0, ${palette.primary.lighter} 100%)`
				),
				bottom: 0,
				content: '""',
				display: 'block',
				height: 3,
				left: 0,
				position: 'absolute',
				width: '100%',
			},
			...(position === 'fixed'
				? {
						left: 0,
						right: 0,
						top: 0,
					}
				: {}),
		},
		gutsBorder: {
			borderBottom: borderBottom
				? ifFeature('encore', `1px solid ${mixins.alpha(constructs.border.neutral.strong, 0.2)}`)
				: undefined,
			boxShadow: borderBottom
				? ifFeature('encore', `0 2px 1px -1px ${mixins.alpha(constructs.border.neutral.strong, 0.05)}`)
				: undefined,
		},
		logo: {
			display: 'flex',
		},
		nav: {
			display: 'flex',
			flexGrow: 1,
			height: '100%',
			marginLeft: spacing(4.5),
			overflow: 'hidden',
			position: 'relative',
			'&:after': {
				backgroundImage: dark
					? `linear-gradient(270deg, ${mixins.alpha(palette.gray[900], 1)} 55%, ${mixins.alpha(palette.gray[900], 0)})`
					: `linear-gradient(270deg, ${mixins.alpha(palette.gray[200], 1)} 55%, ${mixins.alpha(palette.gray[200], 0)})`,
				content: '""',
				display: 'block',
				position: 'absolute',
				right: -40,
				top: 0,
				bottom: 0,
				width: 110,
			},
		},
		root: {
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		},
		search: {
			marginLeft: ifFeature('encore', 'auto', undefined),
		},
		spacer: {
			height: 80,
		},
	};
});
