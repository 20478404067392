import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(theme => {
	const { palette, mixins } = theme;
	return {
		root: {
			backgroundColor: ifFeature('encore', 'transparent', palette.gray[100]),
			borderBottom: mixins.border(1, ifFeature('encore', palette.gray[200], palette.gray[400])),
			borderRadius: '2px 2px 0 0',
			position: 'relative', // for background attachments to .main (like confetti)
			margin: ifFeature('encore', '0 20px'),
			padding: ifFeature('encore', '20px 0'),
			alignItems: ifFeature('encore', 'center'),
			display: ifFeature('encore', 'flex'),
		},
	};
});
