import { useEffect, useState } from 'react';
import config from '../../config';
import api from '../../services/api';
import { FeedbackModal } from './feedbackModal';
import { CheckIcon, Copy, ThumbsDown, ThumbsUp } from './icons';
import { styles } from './styles';
import { ChatLog } from 'src/pages/home/home.types';

const grayShade = 'rgb(134,132,141)';
const greenShade = 'rgb(102,188,51)';
const redShade = '#FE4A22';

interface FeedbackButtonsProps {
	chat: ChatLog;
}

const FeedbackButtons = ({ chat }: FeedbackButtonsProps) => {
	const { id: chatId, rating, unhelpful, incorrect, harmful, comment } = chat;

	const [isCopied, setIsCopied] = useState(false);
	const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);
	const [isThumbsUp, setIsThumbsUp] = useState(false);
	const [isThumbsDown, setIsThumbsDown] = useState(false);
	const [feedbackModalIsVisible, setFeedbackModalVisible] = useState(false);

	const CHAT_FEEDBACK_ENDPOINT = config.VERSION_ENDPOINT + 'chat/' + chatId;

	useEffect(() => {
		setTimeout(() => {
			if (rating === 'positive') {
				setIsThumbsUp(true);
				setIsThumbsDown(false);
			} else if (rating === 'negative') {
				setIsThumbsUp(false);
				setIsThumbsDown(true);
			} else {
				setIsThumbsUp(false);
				setIsThumbsDown(false);
			}
		}, 100);
	}, [rating]);

	if (!chat) return null;

	const handleCopyClick = () => {
		setIsCopied(true);
		setCopiedMessageVisible(true);
		if (chat.botMessage) navigator.clipboard.writeText(chat.botMessage);

		const timer = setTimeout(() => {
			setCopiedMessageVisible(false);
			setIsCopied(false);
		}, 5000);

		return () => clearTimeout(timer);
	};

	const sendFeedback = async (feedback) => {
		api.put(CHAT_FEEDBACK_ENDPOINT, {
			rating: feedback,
			unhelpful: false,
			incorrect: false,
			harmful: false,
			comment: '',
			user_suggested_answer: '',
		});
	};

	return (
		<div style={styles.buttonContainer}>
			<button onClick={() => handleCopyClick()} style={styles.iconButton}>
				{copiedMessageVisible ? (
					<CheckIcon backgroundFill={'#00000000'} fill={greenShade} />
				) : (
					<Copy fill={isCopied ? greenShade : grayShade} />
				)}
			</button>
			<button
				onClick={() => {
					setIsThumbsUp(!isThumbsUp);
					if (!isThumbsUp) setIsThumbsDown(false);
					setFeedbackModalVisible(false);
					sendFeedback(isThumbsUp ? null : 'positive');
				}}
				style={{
					...styles.iconButton,
				}}
			>
				<ThumbsUp fill={isThumbsUp ? greenShade : grayShade} />
			</button>

			<div style={styles.popoverContainer}>
				{feedbackModalIsVisible && (
					<FeedbackModal
						closeModal={() => setFeedbackModalVisible(false)}
						chatId={chatId}
						unhelpful={unhelpful}
						incorrect={incorrect}
						harmful={harmful}
						comment={comment}
					/>
				)}
				<button
					onClick={() => {
						if (!isThumbsDown) {
							setIsThumbsDown(true);
							setIsThumbsUp(false);
							setFeedbackModalVisible(!feedbackModalIsVisible);
							// Send feedback based on new state
							sendFeedback('negative');
						} else {
							// If thumbs down is active, just deactivate it without showing the modal
							setIsThumbsDown(false);
							sendFeedback(null);
							setFeedbackModalVisible(false);
						}
					}}
					style={{
						...styles.iconButton,
					}}
				>
					<ThumbsDown fill={isThumbsDown ? redShade : grayShade} />
				</button>
			</div>
		</div>
	);
};

export default FeedbackButtons;
