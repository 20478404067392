import { ScrollHelper } from '../base-modal.types';

export class BodyScrollHelper implements ScrollHelper {
	isEnabled = true;

	_backdropElement?: HTMLElement;

	_boundHandleTransitionEnd = this._handleTransitionEnd.bind(this) as (e: TransitionEvent) => void;

	_scrollY: number;

	enableScroll(): void {
		if (!this.isEnabled) {
			document.body.style.top = '';
			document.body.style.position = '';
			if (this._backdropElement) {
				this._backdropElement.removeEventListener('transitionend', this._boundHandleTransitionEnd);
			}
			window.scrollTo(0, this._scrollY);
		}

		this.isEnabled = true;
	}

	disableScroll(backdropElement?: HTMLElement): void {
		if (this.isEnabled) {
			this._scrollY = window.scrollY;

			// To keep the document body from visually jumping to the top of the page while the modal backdrop
			// is still fading in (due to the fixed positioning), negative 'top' style is temporariliy applied.
			document.body.style.top = `-${scrollY}px`;
			document.body.style.position = 'fixed';

			// Ensure previous backdrop transition end listener is removed.
			if (this._backdropElement) {
				this._backdropElement.removeEventListener('transitionend', this._boundHandleTransitionEnd);
			}

			this._backdropElement = backdropElement;

			// Add a new backdrop transition end listener.
			if (this._backdropElement) {
				this._backdropElement.addEventListener('transitionend', this._boundHandleTransitionEnd);
			}
		}

		this.isEnabled = false;
	}

	_handleTransitionEnd(e: TransitionEvent): void {
		// To allow certain position-based features (tooltip, popover, etc.) to position as expected, the
		// applied negative 'top' style is removed after the modal backdrop fade-in transition is complete.
		if (e.propertyName === 'opacity') {
			document.body.style.top = '';
			(e.target as HTMLElement).removeEventListener('transitionend', this._boundHandleTransitionEnd);
		}
	}
}
