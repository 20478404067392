import { ListItem } from '../types';

export function getAvailableFilterSelected(selectedOptions: ListItem[], availableOptions: ListItem[]): ListItem[] {
	return selectedOptions.length > 0
		? availableOptions.filter(item => {
				return !selectedOptions.find(selectedItem => {
					return selectedItem.rowKey === item.rowKey;
				});
			})
		: [...availableOptions];
}
