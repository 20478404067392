import { ifFeature } from '@bamboohr/utils/lib/feature';
import { buildTrackingDataAttributes } from '@bamboohr/utils/lib/tracking';
import React, { Fragment } from 'react';
import { LinkUnstyled } from '~components/link/unstyled';
import { IconV2 } from '~components/icon-v2';
import { urlBuilder } from '../utils';
import Page from './page';
import HiddenPages from './hidden-page';
import { useStyles } from './styles';

export default function Pages({ currentPage, hrefBuilder, handlePageClick, pageCount, trackingCategory }) {
	const isLastPage = currentPage === pageCount;
	const { classes, cx } = useStyles({ isLastPage });

	const loop = (count, current = 0, active) => {
		const list = [];

		for (let i = current; i < count; i++) {
			const value = i + 1;
			const isActive = (active && value === active) || value === currentPage;
			const url = urlBuilder(hrefBuilder, value);
			list.push(
				<Page
					className={classes.page}
					handlePageClick={handlePageClick}
					isActive={isActive}
					key={`page${value}`}
					trackingAttributes={buildTrackingDataAttributes(`Pagination_Page_${value}`, trackingCategory)}
					url={url}
					value={value}
				/>
			);
		}

		return list;
	};

	const createPages = () => {
		const PAGE_SET_SIZE = 4;
		// 6 is the limit set by UX before we add the multi-page treatment
		const hasHiddenPages = pageCount > 6;
		const multiPageTreatment = hasHiddenPages && currentPage <= pageCount - PAGE_SET_SIZE;
		const lateMultiPageTreatment = hasHiddenPages && !multiPageTreatment;
		const pageSet = Math.floor((currentPage + 3) / PAGE_SET_SIZE);

		const beginningSection =
			currentPage > PAGE_SET_SIZE ? (
				<Fragment key="beginningSection">
					<Page
						className={classes.page}
						handlePageClick={handlePageClick}
						isActive={false}
						key={`page${1}`}
						trackingCategory={trackingCategory}
						url={urlBuilder(hrefBuilder, 1)}
						value={1}
					/>
					<HiddenPages className={classes.hiddenPages} />
				</Fragment>
			) : null;

		const endSection = (
			<Fragment key="endSection">
				<HiddenPages className={classes.hiddenPages} />
				<Page
					className={classes.page}
					handlePageClick={handlePageClick}
					isActive={false}
					key={`page${pageCount}`}
					trackingCategory={trackingCategory}
					url={urlBuilder(hrefBuilder, pageCount)}
					value={pageCount}
				/>
			</Fragment>
		);

		switch (true) {
			case multiPageTreatment:
				return [
					beginningSection,
					...loop(PAGE_SET_SIZE * pageSet, PAGE_SET_SIZE * pageSet - PAGE_SET_SIZE, currentPage),
					endSection,
				];
			case lateMultiPageTreatment:
				return [beginningSection, ...loop(pageCount, pageCount - PAGE_SET_SIZE)];
			default:
				return loop(pageCount);
		}
	};

	function handlePageIncrement(e) {
		handlePageClick(e, currentPage + 1);
	}

	function handlePageDecrement(e) {
		handlePageClick(e, currentPage - 1);
	}

	const nextLinkEncoreContent = (
		<>
			{window.jQuery ? $.__('Next') : 'Next'}
			<IconV2 name="arrow-right-solid" size={12} />
		</>
	);

	return (
		<section className={classes.root}>
			{currentPage !== 1 &&
				ifFeature(
					'encore',
					<LinkUnstyled
						className={cx(classes.link, classes.prev)}
						href={urlBuilder(hrefBuilder, currentPage - 1)}
						onClick={handlePageDecrement}
						size="extra-small"
						{...buildTrackingDataAttributes('Pagination__prev', trackingCategory)}
					>
						<IconV2 name="arrow-left-solid" size={12} />
						{window.jQuery ? $.__('Prev') : 'Prev'}
					</LinkUnstyled>,
					<a
						className={classes.prev}
						href={urlBuilder(hrefBuilder, currentPage - 1)}
						onClick={handlePageDecrement}
						{...buildTrackingDataAttributes('Pagination__prev', trackingCategory)}
					>
						&laquo;
						{window.jQuery ? $.__('Prev') : 'Prev'}
					</a>
				)}
			<ul className={classes.list}>{createPages()}</ul>
			{ifFeature(
				'encore',
				currentPage === pageCount ? (
					<span className={classes.next}>{nextLinkEncoreContent}</span>
				) : (
					<LinkUnstyled
						aria-disabled={currentPage === pageCount}
						className={cx(classes.link, classes.next)}
						href={urlBuilder(hrefBuilder, currentPage + 1)}
						onClick={handlePageIncrement}
						size="extra-small"
						{...buildTrackingDataAttributes('Pagination__next', trackingCategory)}
					>
						{nextLinkEncoreContent}
					</LinkUnstyled>
				),
				<a
					aria-disabled={currentPage === pageCount}
					className={classes.next}
					href={urlBuilder(hrefBuilder, currentPage + 1)}
					onClick={handlePageIncrement}
					{...buildTrackingDataAttributes('Pagination__next', trackingCategory)}
				>
					{window.jQuery ? $.__('Next') : 'Next'}
					&nbsp; &raquo;
				</a>
			)}
		</section>
	);
}
