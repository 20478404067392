import { shouldActivateFirstItem, shouldActivateLastItem } from './assertion';
import { getDirectionFromKey } from '../../../dom-util';
import { getFirstInteractiveItem, getLastInteractiveItem, traverseDirection } from '../../../item';

export function activateItem(key, state) {
	const { activeItem, items } = state;

	let _activeItem = activeItem;

	const direction = getDirectionFromKey(key);

	if (direction) {
		_activeItem = traverseDirection(items, activeItem, direction);
	} else if (shouldActivateFirstItem(key, state)) {
		_activeItem = getFirstInteractiveItem(items);
	} else if (shouldActivateLastItem(key, state)) {
		_activeItem = getLastInteractiveItem(items);
	}

	return _activeItem;
}
