import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { FileCardProps } from '../../types';
import { isAttachmentCard, isDisplayCard, isEmptyCard } from '../../utils';
import { AttachmentFileCard } from '../attachment-file-card';
import { DisplayFileCard } from '../display-file-card';
import { EmptyFileCard } from '../empty-file-card';

const fileCardLogger = createLogger('@fabric/file-card | <FileCard />');

/**
 * Allows you to pick between different File Card types
 *
 * **NOTE:** This component is deprecated and is only documented to provide context for existing code. Use more specific File Card components instead (AttachmentFileCard, DisplayFileCard, or EmptyFileCard).
 *
 * @deprecated Use more specific File Card components instead (AttachmentFileCard, DisplayFileCard, or EmptyFileCard)
 */
export const FileCard: FunctionComponent<PropsWithChildren<FileCardProps>> = props => {
	let Card;
	if (isAttachmentCard(props)) {
		Card = AttachmentFileCard;
	} else if (isDisplayCard(props)) {
		Card = DisplayFileCard;
	} else if (isEmptyCard(props)) {
		Card = EmptyFileCard;
	} else {
		fileCardLogger.warn('"view" was invalid. Defaulting to "empty"');
		Card = EmptyFileCard;
	}
	return (
		<div className="fab-FileCard">
			<Card {...props} />
		</div>
	);
};
