import React, { Component } from 'react';
import { Menu } from '@fabric/menu';
import noop from 'lodash/noop';
import { DropdownProps } from './types';
import { DropdownTypes } from './enums';
import { ButtonProps } from '~components/button';
import { getButtonFromType, getDerivedButtonSettings, hasFullWidthToggle } from './util';
import { ItemType, ToggleButtonProps } from '@fabric/menu/types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export class Dropdown extends Component<DropdownProps> {
	static defaultProps = {
		isDisabled: false,
		onClose: noop,
		onOpen: noop,
		onSelect: noop,
		placement: {
			align: 'end',
			side: 'bottom',
		},
		showCaret: true,
		showSearch: 'never',
		type: DropdownTypes.BUTTON,
	};

	_handleClose(): Promise<void> {
		const { onClose = noop } = this.props;

		const onClosePromise = onClose();

		return Promise.resolve(onClosePromise).then(() => {
			this.setState({ isOpen: false });
		});
	}

	_handleOpen(): Promise<void> {
		const { onOpen = noop } = this.props;

		const onOpenPromise = onOpen();

		return Promise.resolve(onOpenPromise).then(() => {
			this.setState({ isOpen: true });
		});
	}

	_handleSelect = (item: ItemType, parentItem: ItemType): Promise<void> => {
		const { onSelect = noop } = this.props;

		const onSelectPromise = onSelect(item.value, { item, parentItem });

		return Promise.resolve(onSelectPromise);
	};

	_renderToggle = ({ isFocused }: { isFocused: boolean }, toggleProps: ToggleButtonProps): JSX.Element => {
		const {
			biId,
			ButtonProps,
			buttonSettings = {},
			children,
			isDisabled,
			showCaret,
			type = DropdownTypes.BUTTON,
			width,
		} = this.props;

		const ButtonToRender = getButtonFromType(type);

		const dataAttributes = {};
		if (biId) {
			dataAttributes['data-bi-id'] = `${biId}-dropdown-toggle`;
		}

		return (
			<ButtonToRender
				{...getDerivedButtonSettings(ifFeature('encore', ButtonProps, buttonSettings), children, showCaret)}
				{...dataAttributes}
				{...toggleProps} // props from `Menu` component
				/* @startCleanup encore */
				{...ifFeature('encore', {}, ButtonProps)}
				/* @endCleanup encore */
				isDisabled={isDisabled}
				isFocused={isFocused}
				tabindex="-1"
				type="button"
				width={width}
			>
				{children}
			</ButtonToRender>
		);
	};

	state = {
		isOpen: false,
	};

	render(): JSX.Element {
		const {
			biId,
			ButtonProps,
			buttonSettings,
			isDisabled,
			items,
			placement,
			renderOptionContent,
			showSearch,
			searchPlaceholder,
			searchThreshold,
			width,
			menuWidth,
		} = this.props;

		const { isOpen } = this.state;

		return (
			<Menu
				biId={biId}
				canQuickSelect={isOpen}
				isDisabled={isDisabled}
				isFullWidth={
					hasFullWidthToggle((ButtonProps as ButtonProps)?.width) ||
					hasFullWidthToggle(buttonSettings?.width) ||
					hasFullWidthToggle(width)
				}
				isOpen={isOpen}
				items={items}
				onClose={() => this._handleClose()}
				onOpen={() => this._handleOpen()}
				onSelect={this._handleSelect}
				placement={placement}
				renderOptionContent={renderOptionContent}
				renderToggle={this._renderToggle}
				searchPlaceholder={searchPlaceholder}
				searchThreshold={searchThreshold}
				showSearch={showSearch}
				width={menuWidth}
			/>
		);
	}
}
