import {
	DatePickerDate,
	DatePickerRangeOnChangeParam,
	DatePickerRangeCalendarSelectionMode,
	DatePickerRangeInputType,
	DatePickerRangeTargetSelectionType,
	DatePickerUtils,
	DatePickerDatePredicate,
	DatePickerDateType,
} from '../types';
import { getDateInfo, getIsDateDisabled, isValidRange } from '../utils';

export const DEFAULT_CALENDAR_SELECTION_MODE = 'assumedInput';
export const VALIDATE_DISABLED_DATES_WITHIN_RANGE_MAX_SPAN_YEARS = 1;

export function getCalendarTargetSelectionType(
	utils: DatePickerUtils,
	calendarSelectionMode: DatePickerRangeCalendarSelectionMode,
	focusedInputType: DatePickerRangeInputType
): DatePickerRangeTargetSelectionType {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (calendarSelectionMode === 'focusedInput') {
		return focusedInputType;
	}

	if (focusedInputType === 'end') {
		return 'end-ish';
	}

	return 'start-ish';
}

export function getDatePickerRangeOnChangeParam(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate
): DatePickerRangeOnChangeParam {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return {
		startValue: getDateInfo(utils, startDate).date,
		endValue: getDateInfo(utils, endDate).date,
	};
}

export function getMaxRangeSpanYears(
	maxRangeSpanYears: undefined | number,
	validateDisabledDatesWithinRange: boolean
): undefined | number {
	if (!validateDisabledDatesWithinRange) {
		return maxRangeSpanYears ? Math.abs(maxRangeSpanYears) : maxRangeSpanYears;
	}

	if (maxRangeSpanYears) {
		return Math.min(Math.abs(maxRangeSpanYears), VALIDATE_DISABLED_DATES_WITHIN_RANGE_MAX_SPAN_YEARS);
	}

	return VALIDATE_DISABLED_DATES_WITHIN_RANGE_MAX_SPAN_YEARS;
}

export function getRangeCompletedText(utils: DatePickerUtils, startDate: DatePickerDate, endDate: DatePickerDate): string | undefined {
	if (startDate && endDate && utils.isValid(startDate) && utils.isValid(endDate)) {
		const formattedStartDate = utils.format(startDate, 'fullDate');
		const formattedEndDate = utils.format(endDate, 'fullDate');

		return window.jQuery
			? $.__('Range updated to start at $1%s and end at $2%s', formattedStartDate, formattedEndDate)
			: `Range updated to start at ${formattedStartDate} and end at ${formattedEndDate}`;
	}
}

export function isValidDateRangeSelection({
	getDateDisabled,
	endDate,
	maxDate,
	minDate,
	startDate,
	utils,
}: {
	getDateDisabled?: DatePickerDatePredicate;
	endDate: DatePickerDate;
	maxDate: DatePickerDate;
	minDate: DatePickerDate;
	startDate: DatePickerDate;
	utils: DatePickerUtils;
}) {
	if (!isValidRange(utils, startDate, endDate)) {
		return false;
	}

	let currentDate = startDate as DatePickerDateType;
	while (utils.isBefore(currentDate, endDate as DatePickerDateType)) {
		if (getIsDateDisabled(utils, currentDate, minDate, maxDate, getDateDisabled)) {
			return false;
		}
		currentDate = utils.addDays(currentDate, 1);
	}
	return true;
}
