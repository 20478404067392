import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';

export const styles = (theme: Theme) => {
	const {
		transitions,
		palette,
		mode,
		shadows,
		spacing,
		mixins: { alpha, border },
	} = theme;

	return ifFeature(
		'encore',
		createStyles({
			root: {
				// MUI has min-height: 48. Not sure why, so to be safe, using a high number that doesn't break our design.
				minHeight: 40,
				position: 'relative',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				'& .MuiTabs-flexContainer': {
					alignItems: 'stretch',
					gap: spacing(2),
					height: '100%',
				},
				'& .MuiTabs-indicator': {
					borderRadius: 4,
					height: 3,
				},
			},
			modeFill: {
				paddingLeft: spacing(4),
				paddingRight: spacing(4),
				'& .MuiTabs-flexContainer': {
					gap: spacing(0.5),
				},
				'& .MuiTabs-indicator': {
					display: 'none',
				},
			},
			modeLine: {
				'&:before': {
					backgroundColor: palette.gray[300],
					bottom: 0,
					content: '""',
					height: 1,
					position: 'absolute',
					width: '100%',
				},
				'&:has(.MuiTabScrollButton-root):before': {
					left: 40,
					right: 40,
					width: 'unset',
				},
			},
			sizeLarge: {
				maxHeight: 'unset',
				'&:not($modeFill) .MuiTabs-flexContainer': {
					gap: spacing(2.5),
				},
			},
		}),
		createStyles({
			root: {
				maxHeight: 56,
				position: 'relative',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				'& .MuiTab-root': {
					transition: transitions.create(['color', 'fill']),
				},
				'& .MuiTabs-flexContainer': {
					padding: spacing(1, 0, 0, 0),
				},
				'& .Mui-focusVisible': {
					borderBottom: border(2, palette.grey[500]),
					'& .MuiTab-wrapper': {
						marginBottom: 9,
					},
				},
				'& .MuiTab-textColorSecondary': {
					'&.Mui-selected': {
						color: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
						fill: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					},
				},
				'& .MuiTab-textColorSecondary.Mui-disabled': {
					color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.text.disabled,
					fill: mode === ModeOptions.Dye ? dyeColors.lighter : palette.text.disabled,
				},
				'& .MuiBox-root button': {
					color: palette.gray[800],
				},
			},
			rootWithDropdown: {
				'& .MuiTabs-flexContainer': {
					alignItems: 'center',
				},
			},
			rootWithFixedDropdown: {
				'& .MuiTabs-flexContainer': {
					alignItems: 'center',
					'& div:last-child': {
						marginLeft: 'auto',
					},
				},
			},
			rootContained: {
				maxHeight: 58,
				'&:after': {
					content: '""',
					height: 0,
					width: '100%',
					position: 'absolute',
					bottom: 0,
					boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.2), 0px 4px 6px 4px rgba(0,0,0,0.14)',
				},
				'& .MuiTab-labelIcon': {
					'& .MuiTab-wrapper > svg': {
						width: 18,
						height: 18,
					},
				},
				'& .MuiTabs-flexContainer': {
					padding: spacing(1, 1, 0, 1),
				},
			},
			standard: {
				paddingLeft: spacing(2.5),
			},
			contained: {
				'& .Mui-focusVisible': {
					border: border(1, palette.grey[500]),
					'& .MuiTab-wrapper': {
						margin: '10px 15px',
					},
				},
				'& .MuiTab-textColorSecondary': {
					'&.Mui-selected': {
						transition: transitions.create('box-shadow', { easing: 'easeIn', duration: 'short' }),
						backgroundColor: palette.common.white,
						boxShadow: shadows[4],
						zIndex: 1,
					},
				},
				'& .MuiTab-root': {
					borderRadius: spacing(0.5, 0.5, 0, 0),
					'&:hover': {
						backgroundColor: palette.common.white,
						zIndex: 1,
					},
				},
				'& .MuiTabs-indicator': {
					display: 'none',
				},
			},
			darkContained: {
				color: palette.common.white,
				fill: palette.common.white,
				'& .MuiTab-textColorSecondary': {
					color: palette.common.white,
					fill: palette.common.white,
					'& .MuiTab-label .MuiTypography-root.MuiTypography-caption': {
						color: 'inherit',
					},
					'&.Mui-selected': {
						backgroundColor: palette.common.white,
						color: palette.primary.main,
						fill: palette.primary.main,
					},
				},
				'& .MuiTab-root': {
					'&:hover': {
						backgroundColor: alpha(palette.common.white, 0.2),
						color: palette.common.white,
						fill: palette.common.white,
					},
				},
				'& .MuiTab-textColorSecondary.Mui-disabled': {
					color: alpha(palette.common.white, 0.5),
					fill: alpha(palette.common.white, 0.5),
				},
				'& .MuiBox-root': {
					'& button': {
						color: palette.common.white,
					},
					'& svg': {
						fill: palette.common.white,
					},
				},
			},
			sizeCompact: {
				'& .MuiTab-root': {
					minWidth: 46,
				},
				'& .MuiTab-wrapper': {
					margin: 0,
					'& svg': {
						width: 21,
						height: 21,
					},
				},

				'& .Mui-focusVisible': {
					borderBottom: border(2, palette.grey[500]),
					'& .MuiTab-wrapper': {
						marginBottom: 0,
						marginTop: 2,
					},
				},
			},
			darkStandard: {
				'& .MuiTab-root': {
					'&:hover': {
						color: palette.common.white,
						fill: palette.common.white,
					},
				},
				'& .MuiTab-textColorSecondary': {
					color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.grey[400],
					fill: mode === ModeOptions.Dye ? dyeColors.lighter : palette.grey[500],
					'&.Mui-selected': {
						color: mode === ModeOptions.Dye ? dyeColors.main : palette.common.white,
						fill: mode === ModeOptions.Dye ? dyeColors.main : palette.common.white,
					},
				},
				'& .MuiTab-textColorSecondary.Mui-disabled': {
					color: alpha(palette.grey[400], 0.2),
					fill: alpha(palette.grey[500], 0.2),
				},
				'& .MuiTabs-indicator': {
					backgroundColor: palette.common.white,
				},
				'& .MuiTabScrollButton-root': {
					color: palette.grey[400],
				},
				'& .MuiBox-root': {
					'& button': {
						color: palette.grey[400],
					},
					'& svg': {
						fill: palette.grey[400],
					},
				},
			},
			sizeLarge: {
				maxHeight: 'unset',
				'& .MuiTab-root': {
					alignItems: 'flex-start',
					maxWidth: 296,
				},
				'& .MuiTab-wrapper': {
					alignItems: 'flex-start',
					'& .MuiTab-label .MuiTypography-caption': {
						color: palette.gray[700],
					},
				},
				'& .MuiTab-labelIcon': {
					'& .MuiTab-wrapper > *:first-child': {
						marginTop: spacing(0.5),
						minWidth: 27,
						width: 27,
						height: 27,
					},
				},
			},
			labelSizeLarge: {
				'& .MuiTab-root': {
					fontSize: '1rem',
				},
				'& .MuiTab-labelIcon *> svg': {
					minWidth: 17,
					width: 17,
					height: 17,
				},
				'& .MuiBox-root button': {
					fontSize: '1rem',
				},
			},
		})
	);
};
