import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(() => ({
	slidedownPortal: {
		height: 0,
		pointerEvents: 'none',
		position: 'fixed',
		top: ifFeature('encore', 40, 0),
		width: '100%',
	},
}));
