import { useTheme } from '@mui/styles';
import { clsx, makeStyles } from '~styles';
import React, { FC, PropsWithChildren } from 'react';
import { FileUploadItemProps } from '../../../../types';
import { IconButton } from '~components/button';
import { ProgressBar } from '~components/progress-bar';
import { LayoutBox } from '~components/layout-box';
import { FileIcon } from '~components/file-icon';

/* @startCleanup encore */
import { Pencil14x14, CircleX14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ProgressBar as JadeProgressBar } from '../../../progress-bar.react';
/* @endCleanup encore */

const useStyles = makeStyles<{ isPending: boolean }>()(({ constructs, palette, spacing, typography }, { isPending }) => {
	return {
		root: {
			alignItems: 'center',
			display: 'flex',
			fontSize: ifFeature('encore', typography.medium.fontSize),
			gap: ifFeature('encore', spacing(1.5)),
			lineHeight: ifFeature('encore', typography.medium.lineHeight),
			marginBottom: ifFeature('encore', undefined, '10px'),
			minHeight: ifFeature('encore', '32px'),

			'&:last-child': {
				marginBottom: ifFeature('encore', undefined, 0),
			},
		},
		name: {
			color: ifFeature(
				'encore',
				isPending ? constructs.text.neutral.weak : constructs.text.neutral.strong,
				palette.gray[isPending ? 500 : 800]
			),
			fontWeight: ifFeature('encore', typography.fontWeightMedium),
		},
		icon: {
			lineHeight: '0',
			marginRight: ifFeature('encore', '-4px', '8px'),
		},
		actionIcon: {
			cursor: 'pointer',
			fill: palette.gray[600],
			lineHeight: 0,
			marginLeft: ifFeature('encore', '4px', '8px'),
			'&:active, &:hover': {
				fill: palette.gray[800],
			},
		},
	};
});

export const Item: FC<PropsWithChildren<FileUploadItemProps>> = props => {
	const {
		biId,
		canRename,
		canRemove = true,
		classes: classesProp,
		isPending,
		file,
		progress,
		onRemove,
		onRename,
		temporaryId,
	} = props;

	const { classes } = useStyles({ isPending });
	const { palette } = useTheme();

	return (
		<div className={classes.root}>
			<span className={classes.icon}>
				<FileIcon ariaHidden customFill={isPending ? palette.gray[500] : undefined} filename={file.name} />
			</span>

			<span className={clsx(classes.name, classesProp?.name)}>{file.name}</span>

			{canRename
				? ifFeature(
						'encore',
						<IconButton
							aria-label={window.jQuery ? $.__('edit filename') : 'edit filename'}
							data-bi-id={biId ? `${biId}-rename-button` : ''}
							icon="pen-solid"
							noBoundingBox={true}
							onClick={() => {
								onRename(file, temporaryId);
							}}
							size="extra-small"
							type="button"
						/>,
						<span
							aria-label={window.jQuery ? $.__('edit filename') : 'edit filename'}
							className={classes.actionIcon}
							data-bi-id={biId ? `${biId}-rename-button` : ''}
							onClick={() => {
								onRename(file, temporaryId);
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									onRename(file, temporaryId);
								}
							}}
							role="button"
							tabIndex={0}
						>
							<Pencil14x14 role="presentation" />
						</span>
					)
				: null}

			{canRemove
				? ifFeature(
						'encore',
						<IconButton
							aria-label={window.jQuery ? $.__('remove file') : 'remove file'}
							data-bi-id={biId ? `${biId}-removed-button` : ''}
							icon="circle-xmark-solid"
							noBoundingBox={true}
							onClick={() => {
								onRemove(temporaryId);
							}}
							size="extra-small"
							type="button"
						/>,
						<span
							aria-label={window.jQuery ? $.__('remove file') : 'remove file'}
							className={classes.actionIcon}
							data-bi-id={biId ? `${biId}-removed-button` : ''}
							onClick={() => {
								onRemove(temporaryId);
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									onRemove(temporaryId);
								}
							}}
							role="button"
							tabIndex={0}
						>
							<CircleX14x14 role="presentation" />
						</span>
					)
				: null}

			{progress && progress < 100
				? ifFeature(
						'encore',
						<LayoutBox marginLeft={0.5}>
							<ProgressBar current={progress} height={4} total={100} width="100px" />
						</LayoutBox>,
						<LayoutBox marginLeft={1.25} width="92px">
							<JadeProgressBar progress={progress} />
						</LayoutBox>
					)
				: null}
		</div>
	);
};
