import { animate, useMotionValue, useTransform } from 'framer-motion';
import { useEffect } from 'react';

import { pieSectionTransition } from './constants';
import { PieSectionProps } from '../types';

/**
 * Animates an numeric value
 * @param value a numeric value
 * @returns a motion value
 */
const usePieSectionValue = (value: number) => {
	const motionValue = useMotionValue(value);
	useEffect(() => {
		const controls = animate(motionValue, value, pieSectionTransition);
		return controls.stop;
	}, [motionValue, value]);
	return motionValue;
};

/**
 * Hook that creates an animated path for a pie section
 * @param path the path function from visx
 * @param arc the arc
 * @returns a motion value for the animated path
 */
export const useAnimatedPieSectionPath = <Datum = unknown>(
	path: PieSectionProps<Datum>['path'],
	arc: PieSectionProps<Datum>['arc']
) => {
	// Setting up motion values
	const { startAngle, endAngle, padAngle } = arc;
	const innerRadiusMotionValue = usePieSectionValue(path.innerRadius()(arc));
	const outerRadiusMotionValue = usePieSectionValue(path.outerRadius()(arc));
	const cornerRadiusMotionValue = usePieSectionValue(path.cornerRadius()(arc));
	const startAngleMotionValue = usePieSectionValue(startAngle);
	const endAngleMotionValue = usePieSectionValue(endAngle);
	const padAngleMotionValue = usePieSectionValue(padAngle);

	const arcClone = { ...arc };
	const d = useTransform(
		[
			innerRadiusMotionValue,
			outerRadiusMotionValue,
			cornerRadiusMotionValue,
			startAngleMotionValue,
			endAngleMotionValue,
			padAngleMotionValue,
		],
		([innerRadiusValue, outerRadiusValue, cornerRadiusValue, startAngleValue, endAngleValue, padAngleValue]: [
			number,
			number,
			number,
			number,
			number,
			number,
			number,
		]) => {
			// Creating clone with animated values to avoid mutating original object
			arcClone.startAngle = startAngleValue;
			arcClone.endAngle = endAngleValue;
			arcClone.padAngle = padAngleValue;

			// path is shared across all the sections. Using hacky workaround to avoid mutating it

			// Storing old accessors
			const oldOuterRadius = path.outerRadius();
			const oldInnerRadius = path.innerRadius();
			const oldCornerRadius = path.cornerRadius();

			// Temporarily adjusting accessors for animation
			path.innerRadius(innerRadiusValue);
			path.outerRadius(outerRadiusValue);
			path.cornerRadius(cornerRadiusValue);
			const value = path(arcClone) ?? '';

			// Restoring old accessors
			path.outerRadius(oldOuterRadius);
			path.innerRadius(oldInnerRadius);
			path.cornerRadius(oldCornerRadius);
			return value;
		}
	);
	return d;
};
