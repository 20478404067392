import { styled } from '@mui/material';

export const StyledIcon = styled('span')({
	lineHeight: 0,
	marginRight: 16,
});

export const StyledRoot = styled('div')({
	alignItems: 'center',
	display: 'flex',
});
