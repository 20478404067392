import React from 'react';
import { useDayPicker } from 'react-day-picker';
import { IconButton, IconButtonProps } from '~components/button';
import { Flex } from '~components/flex';
import { CalendarBaseNavProps } from './calendar-base-nav.types';
import { getCanNavNext, getCanNavPrevious } from './utils';

const iconButtonConfig: Partial<IconButtonProps> = {
	color: 'secondary',
	size: 'small',
	variant: 'outlined',
};

export const CalendarBaseButtonNav = (props: CalendarBaseNavProps) => {
	const { children, startMonth, endMonth, navigation, ...labelHtmlProps } = props;

	const { goToMonth, nextMonth, previousMonth } = useDayPicker();

	const canNavPrevious = getCanNavPrevious(previousMonth, startMonth);

	const canNavNext = getCanNavNext(nextMonth, endMonth);

	const onNextClick = () => {
		if (canNavNext && nextMonth) {
			goToMonth(nextMonth);
		}
	};

	const onPreviousClick = () => {
		if (canNavPrevious && previousMonth) {
			goToMonth(previousMonth);
		}
	};

	return (
		<Flex alignItems="center" justifyContent="space-between">
			<Flex flex={1} justifyContent="flex-start">
				{canNavPrevious && (
					<IconButton
						{...iconButtonConfig}
						ariaLabel={window.jQuery ? $.__('previous month') : 'previous month'}
						icon="caret-left-solid"
						onClick={onPreviousClick}
					/>
				)}
			</Flex>
			<Flex flex={1} justifyContent="center">
				<span {...labelHtmlProps}>{children}</span>
			</Flex>
			<Flex flex={1} justifyContent="flex-end">
				{canNavNext && (
					<IconButton
						{...iconButtonConfig}
						ariaLabel={window.jQuery ? $.__('next month') : 'next month'}
						icon="caret-right-solid"
						onClick={onNextClick}
					/>
				)}
			</Flex>
		</Flex>
	);
};
