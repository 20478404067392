import config from 'src/config';
import { LlmConfig } from 'src/types/global';
import { create } from 'zustand';

interface LlmState {
	llmId: string;
	llmLabel: string;
	llmConfig: LlmConfig;
	updateLlmId: (newId: string) => void;
	updateLlmLabel: (newLabel: string) => void;
	updateLlmConfig: (newConfig: LlmConfig) => void;
	updateLlmState: (newState: {
		newId: string;
		newLabel: string;
		newConfig: LlmConfig;
	}) => void;
}

export const useLlmStore = create<LlmState>()((set) => ({
	llmId: config.DEFAULT_FUNCTION_ID,
	llmLabel: config.DEFAULT_FUNCTION_LABEL,
	llmConfig: config.DEFAULT_LLM_CONFIG,
	updateLlmId: (newId) => set({ llmId: newId }),
	updateLlmLabel: (newLabel) => set({ llmLabel: newLabel }),
	updateLlmConfig: (newConfig) => set({ llmConfig: newConfig }),
	updateLlmState: ({ newId, newLabel, newConfig }) =>
		set({ llmId: newId, llmLabel: newLabel, llmConfig: newConfig }),
}));
