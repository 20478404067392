import { DatePickerDate, DatePickerDatePredicate, DatePickerOnChangeParamDate, DatePickerUtils } from '../../types';
import {
	getIsDateDisabled,
	getIsDateValid,
	getIsNextMonthFirstDayAfterDate,
	getIsPreviousMonthLastDayBeforeDate,
	getToday,
} from '../../utils';

export function getDatePickerCalendarSingleOnChangeParam(selectedDate: DatePickerDate): DatePickerOnChangeParamDate {
	return {
		value: selectedDate,
	};
}

export function getIsDayDisabled(
	utils: DatePickerUtils,
	date: DatePickerDate,
	disabled?: boolean,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	getDateDisabled?: DatePickerDatePredicate
): boolean {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (disabled) {
		return true;
	}

	return getIsDateDisabled(utils, date, minDate, maxDate, getDateDisabled);
}

export function getIsDayErrored(
	utils: DatePickerUtils,
	date: DatePickerDate,
	selectedDate: DatePickerDate,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	getDateDisabled?: DatePickerDatePredicate
): boolean {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (!utils.isEqual(date, selectedDate)) return false;

	if (!selectedDate || !getIsDateValid(utils, selectedDate)) {
		return false;
	}

	if (
		getIsDateValid(utils, selectedDate) &&
		!!minDate &&
		getIsDateValid(utils, minDate) &&
		utils.isBeforeDay(selectedDate, minDate)
	) {
		return true;
	}

	if (
		getIsDateValid(utils, selectedDate) &&
		!!maxDate &&
		getIsDateValid(utils, maxDate) &&
		utils.isAfterDay(selectedDate, maxDate)
	) {
		return true;
	}

	if (getDateDisabled) {
		return getDateDisabled(selectedDate);
	}

	return false;
}

export function getIsDayFocused(
	utils: DatePickerUtils,
	date: DatePickerDate,
	focusedDate: DatePickerDate,
	justSelected: boolean
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (justSelected) {
		return false;
	}

	if (!focusedDate) {
		return false;
	}

	return utils.isSameDay(date, focusedDate);
}

export function getIsDaySelected(utils: DatePickerUtils, date: DatePickerDate, selectedDate?: DatePickerDate): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (!!selectedDate && getIsDateValid(utils, selectedDate) && utils.isSameDay(date, selectedDate)) {
		return true;
	}

	return false;
}

export function getIsLeftArrowButtonDisabled(
	utils: DatePickerUtils,
	viewDate: DatePickerDate,
	disabled: boolean,
	minDate?: DatePickerDate
): boolean {
	if (!utils || !viewDate) {
		throw new Error('Expected arguments are missing.');
	}

	return disabled || (getIsDateValid(utils, minDate) && getIsPreviousMonthLastDayBeforeDate(utils, viewDate, minDate));
}

export function getIsRightArrowButtonDisabled(
	utils: DatePickerUtils,
	viewDate: DatePickerDate,
	disabled: boolean,
	maxDate?: DatePickerDate
): boolean {
	if (!utils || !viewDate) {
		throw new Error('Expected arguments are missing.');
	}

	return disabled || (getIsDateValid(utils, maxDate) && getIsNextMonthFirstDayAfterDate(utils, viewDate, maxDate));
}

export function getViewDate(utils: DatePickerUtils, selectedDate: DatePickerDate): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!!selectedDate && getIsDateValid(utils, selectedDate)) {
		return utils.startOfMonth(selectedDate);
	}

	return utils.startOfMonth(getToday(utils));
}
