import { makeStyles } from '~styles';
import { SpinnerSize } from './spinner.types';
import { keyframes } from 'tss-react';

export const useStyles = makeStyles<{ size: SpinnerSize }>()((theme, params) => {
	const { palette } = theme;
	const { size } = params;

	const spin = keyframes({
		'100%': {
			transform: `rotate(360deg)`,
		},
	});

	return {
		root: {
			animationDuration: '1s',
			animationFillMode: 'forwards',
			animationIterationCount: 'infinite',
			animationName: spin,
			animationTimingFunction: 'linear',
			aspectRatio: 1,
			border: `3px solid ${palette.gray[300]}`,
			borderRadius: '50%',
			borderRightColor: palette.gray[500],
			boxSizing: 'border-box',
			display: 'inline-block',
			height: `${size}px`,
			width: `${size}px`,
		},
	};
});
