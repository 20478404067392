import React, { FunctionComponent, PropsWithChildren } from 'react';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Constraint, ConstraintSpacing, BaseModal, useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { StandardModalFooterProps } from '../standard-modal.types';

import { useStandardModalFooterStyles } from './footer.styles';

export const Footer: FunctionComponent<PropsWithChildren<StandardModalFooterProps>> = props => {
	const { classes } = useStandardModalFooterStyles();
	const { isMediumScreen } = useScreenSizes();

	return isMediumScreen ? (
		<FullScreenModal.Footer {...props} />
	) : (
		ifFeature(
			'encore',
			<div className={classes.root}>
				<BaseModal.Footer {...props} />
			</div>,
			<div className={classes.root}>
				<Constraint
					spacingOverrides={{
						bottom: ConstraintSpacing.SMALL,
						left: ConstraintSpacing.SMALL,
						right: ConstraintSpacing.SMALL,
						top: ConstraintSpacing.NONE,
					}}
				>
					<BaseModal.Footer {...props} />
				</Constraint>
			</div>
		)
	);
};
