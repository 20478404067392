import { Layout } from '../types';
import { copyLayout, fillHolesInLayout, fixLayoutKeys, fixLayoutKey } from '../utilities';

export function threeColumnOneByOneToTwoByTwo(layout: Layout, layoutKey: string) {
	layout = copyLayout(layout);
	layout = fillHolesInLayout(layout);
	layout = fixLayoutKeys(layout);
	layoutKey = fixLayoutKey(layoutKey);

	let innerIndex = -1;
	const outerIndex = layout.findIndex(arr => {
		innerIndex = arr.findIndex(key => key === layoutKey);
		return innerIndex > -1;
	});

	if (innerIndex === 0 || innerIndex === 2) {
		if (innerIndex === 2) {
			layout = layout.map(row => row.reverse());
		}

		layout.splice(outerIndex + 1, 0, [...layout[outerIndex]]);
		// eslint-disable-next-line prefer-destructuring
		layout[outerIndex][2] = layout[outerIndex][1];
		layout[outerIndex][1] = layoutKey;
		layout[outerIndex + 1][1] = layoutKey;

		if (innerIndex === 2) {
			layout = layout.map(row => row.reverse());
		}
	}

	if (innerIndex === 1) {
		layout.splice(outerIndex + 1, 0, [...layout[outerIndex]]);
		// eslint-disable-next-line prefer-destructuring
		layout[outerIndex + 1][0] = layout[outerIndex][2];
		layout[outerIndex][2] = layoutKey;
		layout[outerIndex + 1][2] = layoutKey;
	}

	return layout;
}
