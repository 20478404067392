import { TabsUnstyled, TabsUnstyledProps } from '@mui/base';
import { TabsUnstyledTypeMap } from '@mui/base/TabsUnstyled/TabsUnstyledProps';
import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MUIStyledCommonProps } from '@mui/system';
import React, { forwardRef, Ref } from 'react';
import { sideTabPaddingX, sideTabsLayoutPaddingY } from './constants';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabsOwnProps extends Omit<MUIStyledCommonProps<Theme>, 'className' | 'sx'> {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	className?: string;
	/**
	 * @deprecated sx should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	sx?: MUIStyledCommonProps['sx'];
}

// Used to assign `orientation="vertical"`
const VerticalTabs = forwardRef((props: TabsUnstyledProps, ref: Ref<HTMLDivElement>) => (
	<TabsUnstyled orientation="vertical" ref={ref} {...props} />
));

/**
 * @deprecated Use `SideSubNavigation` instead.
 *
 * The wrapper around every other `SideTab*` component, providing context,
 * logic, and minor styling.
 */
export const SideTabs = styled(VerticalTabs)(({ theme: { mode, spacing } }) => ({
	display: 'flex',
	outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
	// Use CSS variables for style values shared by multiple components.
	[sideTabPaddingX]: spacing(1.25),
	[sideTabsLayoutPaddingY]: spacing(2.5),
	// Generics are lost with `styled`
	// https://mui.com/material-ui/guides/typescript#complications-with-the-component-prop
})) as OverridableComponent<TabsUnstyledTypeMap<SideTabsOwnProps>>;
