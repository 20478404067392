// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/styles';
import { AnimatedPointValueProps } from '../types/animated-point-value-props';
import { usePointValueVariants } from './use-point-value-variants';
import { calculatePointValueDyOffset } from './calculate-point-value-dy-offset';

export const AnimatedPointValue = ({
	adjacentYValues,
	animate,
	displayText,
	dx,
	dy,
	xMax = 0,
	yMax = 0,
	...restProps
}: AnimatedPointValueProps): ReactElement => {
	const { palette, typography } = useTheme();
	// It's currently set to the number 14 in the theme, but could potentially change
	const fontSize = parseFloat(typography.small?.fontSize as string) ?? 14;
	const dyOffset = calculatePointValueDyOffset(dy, adjacentYValues, yMax, fontSize);
	const variants = usePointValueVariants(dx, dyOffset, yMax);

	return (
		<motion.text
			alignmentBaseline="middle"
			animate="idle"
			dx={dx}
			dy={dyOffset}
			fill={palette.gray[800]}
			fontFamily={typography.fontFamily}
			fontSize={fontSize}
			initial="initial"
			textAnchor="middle"
			variants={variants}
			{...restProps}
		>
			{displayText}
		</motion.text>
	);
};
