import React, { ReactElement } from 'react';
import { SideNavigationProps } from './side-navigation.types';
import { useStyles } from './side-navigation.styles';
import { Link } from './link';
import { Divider } from './divider';
import { SideNavigationContext } from './side-navigation-context';

export function SideNavigation(props: SideNavigationProps): ReactElement {
	const { items, compact = false } = props;
	const { classes } = useStyles();
	return (
		<SideNavigationContext.Provider value={{ compact }}>
			<div className={classes.root}>{items}</div>
		</SideNavigationContext.Provider>
	);
}

SideNavigation.Divider = Divider;
SideNavigation.Link = Link;
