import React, { FunctionComponent, PropsWithChildren } from 'react';
import { BaseModal } from '~components/base-modal';
import { StandardModalHeaderProps } from '../standard-modal.types';
import { useStyles } from './default-header.styles';

export const DefaultHeader: FunctionComponent<PropsWithChildren<StandardModalHeaderProps>> = props => {
	const { children, hasCloseButton, ...rest } = props;

	const { classes } = useStyles();

	return (
		<div className={classes.defaultHeader}>
			<div>{children}</div>
			{hasCloseButton ? (
				<div className={classes.defaultHeaderCloseButton}>
					<BaseModal.CloseButton />
				</div>
			) : null}
		</div>
	);
};
