import React, { ComponentProps, FunctionComponent, PropsWithChildren } from 'react';
import { useStyles } from './focal-content.styles';

export const FocalContent: FunctionComponent<PropsWithChildren<ComponentProps<'div'>>> = props => {
	const { children, ...rest } = props;
	const { classes } = useStyles();

	return (
		<div className={classes.root} {...rest}>
			{children}
		</div>
	);
};
