import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { mediumScreenWidth } from '~definitions/json/mobile.json';

export const useStyles = makeStyles<{ inverted?: boolean }>()(({ breakpoints, constructs, palette, mode, spacing }, { inverted }) => {
	return {
		baseModalHeader: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', inverted ? palette.common.white : palette.gray[50]),
			borderTopLeftRadius: ifFeature('encore', '12px'),
			borderTopRightRadius: ifFeature('encore', '12px'),
			display: 'flex',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			width: '100%',
			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				backgroundColor: constructs.surface.neutral.xxWeak,
			}),
		},
		baseModalCloseButton: {
			padding: ifFeature('encore', spacing(2, 2.5)),
			[breakpoints.down('sm')]: ifFeature('encore', {
				padding: spacing(2),
			}),
		},
		baseModalHeaderContent: {
			flex: ifFeature('encore', undefined, 1),
			flexGrow: ifFeature('encore', 1),
			padding: ifFeature('encore', spacing(2, 0, 2, 2.5)),
			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				padding: spacing(2, 0, 2.5, 2),
			}),
		},
	};
});
