import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Constraint, ConstraintSpacing, BaseModal, useScreenSizes } from '~components/base-modal';
import { SheetModalFooterProps } from '../sheet-modal.types';

import { useStyles } from './footer.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const Footer: FunctionComponent<PropsWithChildren<SheetModalFooterProps>> = props => {
	const { classes, cx } = useStyles();
	const { isMediumScreen } = useScreenSizes();

	return (
		<div
			className={cx(classes.root, {
				[classes.mobile]: isMediumScreen,
			})}
		>
			{ifFeature(
				'encore',
				<BaseModal.Footer {...props} minActionWidth={isMediumScreen ? 100 : undefined} />,
				<Constraint
					spacingOverrides={{
						bottom: ConstraintSpacing.SMALL,
						left: ConstraintSpacing.SMALL,
						right: ConstraintSpacing.SMALL,
						top: ConstraintSpacing.NONE,
					}}
				>
					<BaseModal.Footer {...props} minActionWidth={isMediumScreen ? 100 : undefined} />
				</Constraint>
			)}
		</div>
	);
};
