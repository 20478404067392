import React from 'react';

import { CurrencyToggleProps } from '../../types';
import { TextToggle } from '../text';
/**
 * ### `@fabric/toggle/Currency`
 * Sets the Currency toggle value for the Text Toggle
 * */
export const CurrencyToggle = (props: CurrencyToggleProps) => {
	const data = {
		...props,
		value: ['%'],
	};
	data.value.unshift(data.currencySymbol || '$');
	return <TextToggle {...data} value={data.value} />;
};
