import { useCallback, useRef } from 'react';
import { usePromptStore } from 'src/store';

interface UseChatInputOptions {
	handleSubmit: (target: HTMLTextAreaElement) => void;
	setIsPrinting?: (isPrinting: boolean) => void;
	chatLogRef?: React.RefObject<HTMLDivElement>;
}

export const useChatInput = ({
	handleSubmit,
	setIsPrinting,
	chatLogRef,
}: UseChatInputOptions) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const { prompt, updatePrompt } = usePromptStore();

	const onChangeHandler = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			updatePrompt(e.target.value);
			e.target.style.height = '30px';
			e.target.style.height = `${e.target.scrollHeight}px`;
		},
		[updatePrompt],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			const target = e.target as HTMLTextAreaElement;
			if (
				e.key === 'Enter' &&
				!e.shiftKey &&
				!e.altKey &&
				!(e.ctrlKey || e.metaKey)
			) {
				e.preventDefault();
				handleSubmit(target);
			} else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
				const start = target.selectionStart;
				const end = target.selectionEnd;
				const updatedPrompt = `${prompt.substring(0, start)}\n${prompt.substring(end)}`;
				updatePrompt(updatedPrompt);
			}
		},
		[handleSubmit, prompt, updatePrompt],
	);

	const onSubmit = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (textAreaRef.current) {
				handleSubmit(textAreaRef.current);
			}
		},
		[handleSubmit],
	);

	const stopPrinting = useCallback(() => {
		if (setIsPrinting && chatLogRef) {
			setIsPrinting(false);
			setTimeout(() => {
				chatLogRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
				});
			}, 300);
		}
	}, [chatLogRef, setIsPrinting]);

	return {
		textAreaRef,
		prompt,
		onChangeHandler,
		handleKeyDown,
		onSubmit,
		stopPrinting,
		updatePrompt,
	};
};
