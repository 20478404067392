import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ constructs, mixins, palette, spacing }) => {
	return {
		root: {
			alignItems: 'center',
			backgroundColor: palette.gray[100],
			borderRadius: '16px',
			display: 'flex',
			fill: constructs.icon.neutral.xStrong,
			height: spacing(7),
			justifyContent: 'center',
			overflow: 'hidden',
			width: spacing(7),
			'&:has(:focus-visible)': {
				boxShadow: `0px 0px 0px 2px ${mixins.alpha(constructs.border.primary.strong, 0.8)}`,
			},
			'&:hover': {
				fill: palette.primary.main,
			},
		},
		link: {
			alignItems: 'center',
			display: 'flex',
			height: '100%',
			justifyContent: 'center',
			textDecoration: 'none',
			width: '100%',
		},
	};
});
