import React, { FC, PropsWithChildren } from 'react';

import { CheckboxCellProps } from './types';

export const CheckboxCell: FC<PropsWithChildren<CheckboxCellProps>> = internalProps => {
	const { biId, rowIsSelected, rowSelectionIsDisabled = false, rowKey } = internalProps;

	return (
		<div
			style={{
				marginTop: '2px',
				width: `16px`,
			}}
		>
			<div className="fab-Checkbox js-fab-table-checkbox">
				<input
					aria-label={`Select row ${rowKey}`}
					checked={Boolean(rowIsSelected)}
					className="fab-Checkbox__input js-fab-table-checkbox-input"
					data-bi-id={biId && `${biId}-checkbox-cell-${rowKey}`}
					disabled={rowSelectionIsDisabled}
					id={`fabricTableCheckboxCell-${rowKey}`}
					readOnly={true}
					style={{
						cursor: 'pointer',
						height: '100%',
						width: '100%',
						zIndex: '1',
					}}
					type="checkbox"
				/>
				<span className="fab-Checkbox__box" />
				<label
					aria-label={window.jQuery ? $.__(`Select row %s`, rowKey) : `Select row ${rowKey}`}
					className="fab-Checkbox__label"
					htmlFor={`fabricTableCheckboxCell-${rowKey}`}
					onClick={e => e.stopPropagation()}
					onKeyUp={e => e.stopPropagation()}
				/>
			</div>
		</div>
	);
};
