import { DatePickerRangeInputType, DatePickerStatus, InputDateOnChangeParam, InputDateRangeOnChangeParam } from '../../types';

export const DEFAULT_END_LABEL = window.jQuery ? $.__('To') : 'To';
export const DEFAULT_START_LABEL = window.jQuery ? $.__('From') : 'From';

export function getInputDateRangeOnChangeParam(
	type: DatePickerRangeInputType,
	inputParam: InputDateOnChangeParam
): InputDateRangeOnChangeParam {
	const { inputValue, value } = inputParam;

	return {
		inputValue,
		type,
		value,
	};
}

export function getNoteStatus(startStatus: DatePickerStatus, endStatus: DatePickerStatus): DatePickerStatus {
	if (startStatus === 'error' || endStatus === 'error') {
		return 'error';
	}
	if (startStatus === 'warning' || endStatus === 'warning') {
		return 'warning';
	}
	if (startStatus === 'info' || endStatus === 'info') {
		return 'info';
	}
	return 'default';
}
