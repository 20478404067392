import { MagGlass15x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { Component, createRef } from 'react';
import { IconV2 } from '~components/icon-v2';

import { focusElement } from '../../dom-util';

import './search.scss';

const SEARCH_PLACEHOLDER = window.jQuery ? $.__('Search...') : 'Search...';

export class Search extends Component {
	focus() {
		focusElement(this._ref.current);
	}

	_handleChange = e => {
		const { onSearch } = this.props;

		const searchText = e.target.value;

		onSearch(searchText);
	};

	_ref = createRef();

	render() {
		const { activeItem, searchPlaceholder, searchText } = this.props;

		return (
			/* Labels do not need "for" attributes if they wrap the related inputs. */
			/* eslint-disable jsx-a11y/label-has-for */
			<label className="fab-MenuSearch" key="menuVesselSearch">
				<div className="fab-MenuSearch__icon">
					{ifFeature('encore', <IconV2 name="magnifying-glass-regular" size={16} />, <MagGlass15x14 aria-hidden={true} />)}
				</div>
				<input
					aria-activedescendant={activeItem?.id || null}
					aria-label={window.jQuery ? $.__('Search') : 'Search'}
					className="fab-MenuSearch__input"
					onChange={this._handleChange}
					placeholder={searchPlaceholder ?? SEARCH_PLACEHOLDER}
					ref={this._ref}
					type="text"
					value={searchText}
				/>
			</label>
			/* eslint-enable jsx-a11y/label-has-for */
		);
	}
}
