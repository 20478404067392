import { includes } from 'lodash';

import { Directions } from '../../enums';

export function canAffectVisibility(key = '') {
	const visibiltyKeys = ['Enter', 'Escape'];

	return includes(visibiltyKeys, key);
}

export function canCauseMovement(key = '') {
	const movementKeys = [' ', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'End', 'Enter', 'Home', 'PageDown', 'PageUp', 'Tab'];

	return includes(movementKeys, key);
}

export function canMoveCursor(key = '') {
	const cursorMovementKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

	return includes(cursorMovementKeys, key);
}

export function getDirectionFromKey(key) {
	const keyToDirection = {
		ArrowDown: Directions.DOWN,
		ArrowLeft: Directions.LEFT,
		ArrowRight: Directions.RIGHT,
		ArrowUp: Directions.UP,
		Down: Directions.DOWN,
		Left: Directions.LEFT,
		Right: Directions.RIGHT,
		Up: Directions.UP,
	};

	return keyToDirection[key];
}

export function normalizeKey(key) {
	const mismatchedKeys = {
		Down: 'ArrowDown',
		Left: 'ArrowLeft',
		Right: 'ArrowRight',
		Up: 'ArrowUp',
		Esc: 'Escape',
		Spacebar: ' ',
	};

	return mismatchedKeys[key] || key;
}
