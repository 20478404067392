import React, { RefObject, useEffect, useState } from 'react';
import { Tooltip } from '~components/tooltip';
import { Loader } from '~components/loader';
import { IconV2 } from '~components/icon-v2';
import { Flex } from '~components/flex';
import { InternalCellRendererParams } from '../data-grid.types';
import { getStatusIcon, getStatusIconLabel } from '../utils';
import {
	useCellRendererStyles,
	/* @startCleanup encore */ useCellRendererStylesJade /* @endCleanup encore */,
} from './cell-renderer.styles';
import { getStatusIconNameAndColor } from '../utils/get-status-icon';

/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox } from '../../layout-box';
/* @endCleanup encore */

export const CellRenderer = (props: InternalCellRendererParams) => {
	const { eGridCell, getStatus, value, valueFormatted, colDef } = props;
	const { status, message, title } = getStatus?.(props) ?? {};
	const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const renderValue = valueFormatted ?? value;

	/* @startCleanup encore */
	const { classes: jadeClasses } = useCellRendererStylesJade({ status });
	/* @endCleanup encore */

	const { classes } = useCellRendererStyles();

	const openTooltip = () => {
		setIsOpen(true);
	};

	const closeTooltip = () => {
		setIsOpen(false);
	};

	const renderTooltip = () => {
		return status ? <Tooltip anchorEl={anchorRef} content={message} open={isOpen} title={title} /> : null;
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				closeTooltip();
			}
		};

		eGridCell.addEventListener('focus', openTooltip);
		eGridCell.addEventListener('blur', closeTooltip);
		eGridCell.addEventListener('mouseenter', openTooltip);
		eGridCell.addEventListener('mouseleave', closeTooltip);
		eGridCell.addEventListener('keydown', handleKeyDown);

		return () => {
			eGridCell.removeEventListener('focus', openTooltip);
			eGridCell.removeEventListener('blur', closeTooltip);
			eGridCell.removeEventListener('mouseenter', openTooltip);
			eGridCell.removeEventListener('mouseleave', closeTooltip);
			eGridCell.removeEventListener('keydown', handleKeyDown);
		};
	}, [eGridCell]);

	// Update styling for cell based on status
	useEffect(() => {
		if (!status || renderValue === undefined) {
			eGridCell.classList.remove('info-col', 'error-col', 'warning-col');
			return;
		}

		switch (status) {
			case 'error':
				eGridCell.classList.add('error-col');
				eGridCell.classList.remove('info-col', 'warning-col');
				break;
			case 'info':
				eGridCell.classList.add('info-col');
				eGridCell.classList.remove('error-col', 'warning-col');
				break;
			case 'warning':
				eGridCell.classList.add('warning-col');
				eGridCell.classList.remove('info-col', 'error-col');
				break;
			default:
				throw new Error(`Unknown status: ${status as string}`);
		}
	}, [eGridCell, renderValue, status]);

	const renderIcon = ifFeature(
		'encore',
		() => {
			if (!status) {
				return null;
			}
			const icon = getStatusIconNameAndColor(status);
			return icon ? <IconV2 ariaLabel={getStatusIconLabel(status)} color={icon.color} name={icon.name} size={16} /> : null;
		},
		() => {
			if (!status) {
				return null;
			}
			const Icon = getStatusIcon(status);
			return Icon ? <Icon aria-label={getStatusIconLabel(status)} className={jadeClasses.icon} role="img" /> : null;
		}
	);

	return (
		<Flex
			alignItems="center"
			columnGap={ifFeature('encore', '8px')}
			height="100%"
			justifyContent="space-between"
			ref={setAnchorRef as unknown as RefObject<HTMLDivElement>}
			width="100%"
		>
			{ifFeature(
				'encore',
				<>
					{renderValue === undefined ? (
						<LayoutBox marginRight="auto">
							<Loader small={true} />
						</LayoutBox>
					) : (
						<div className={classes.dataGridCellValue}>{renderValue}</div>
					)}
				</>,
				<span className={jadeClasses.text}>{renderValue === undefined ? <Loader small={true} /> : renderValue}</span>
			)}
			{renderIcon()}
			{ifFeature('encore', colDef?.type === 'dropdown' && <IconV2 color="neutral-strong" name="caret-down-solid" size={16} />)}

			{renderTooltip()}
		</Flex>
	);
};
