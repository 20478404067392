import React, { ReactNode } from 'react';
import { useGroupLegendStyles } from './group-legend.styles';
import { FormNote } from '~components/form-note';
import { Stack } from '@mui/material';
import { Label } from '~components/label';

export interface GroupLegendProps {
	id: string;
	label: ReactNode;
	note?: ReactNode;
}

export const GroupLegend = ({ id, label, note }: GroupLegendProps) => {
	const { classes } = useGroupLegendStyles({ hasNote: !!note });

	const renderNote = () => {
		if (note) {
			return React.isValidElement(note) ? note : <FormNote>{note}</FormNote>;
		}
	};

	return (
		<legend id={id}>
			<Stack className={classes.legendContainer} justifyContent="start" spacing="2px">
				{React.isValidElement(label) ? label : <Label>{label}</Label>}
				{renderNote()}
			</Stack>
		</legend>
	);
};
