import React, { FunctionComponent, PropsWithChildren } from 'react';

import { BaseModal } from '~components/base-modal';
import { FullScreenModalFooterProps } from '../full-screen-modal.types';
import { useFooterStyles } from './footer.styles';
import { useScreenSizes } from '~utils';

export const Footer: FunctionComponent<PropsWithChildren<FullScreenModalFooterProps>> = props => {
	const { classes } = useFooterStyles();
	const { isMediumScreen } = useScreenSizes(true);

	return (
		<div className={classes.footer}>
			<BaseModal.Footer actionAlignment={isMediumScreen ? 'end' : 'start'} {...props} />
		</div>
	);
};
