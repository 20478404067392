import React, { ReactElement } from 'react';
import { getRenderer } from './etc/get-renderer';
import { renderChildFormItems } from './render-child-form-item';
import { FormConfig, RenderOptions } from './types';

export function renderFormItem(
	id: keyof FormConfig['layout'] & string,
	formConfig: FormConfig,
	renderOptions?: RenderOptions
): ReactElement | null {
	const { formElements, layout } = formConfig;
	const element = formElements[id];

	let childIds = layout[id]?.childIds;
	if (childIds?.includes(id)) {
		childIds = childIds.filter(_id => _id !== id);
		console.error(`A parent layout item cannot reference itself as a child. Looking at you "${id}" 👀.`);
	}

	const Renderer = getRenderer(element, renderOptions);

	return (
		<Renderer data-layout-id={id} element={element} formConfig={formConfig} key={id} renderOptions={renderOptions}>
			{Array.isArray(childIds) && childIds.length > 0 ? renderChildFormItems(childIds, formConfig, renderOptions) : null}
		</Renderer>
	);
}
