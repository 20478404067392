import { getExtFromFileName } from '@bamboohr/utils/lib/file';

export function getMessage(failedFileIds = [], failuresByFileId) {
	const [firstFailedFileId] = failedFileIds;
	const failure = failuresByFileId[firstFailedFileId] || {};
	const file = failure.nativeFile;

	const unknownErrorMessage = window.jQuery
		? $.__('Whoops, something on our side prevented your file from uploading. Please give it another try.')
		: 'Whoops, something on our side prevented your file from uploading. Please give it another try.';

	let reason = '';
	switch (failure.errorType) {
		case 'network_failure':
			reason = window.jQuery
				? $.__('For some reason we are having trouble uploading files right now. Please give it another try in a few minutes.')
				: 'For some reason we are having trouble uploading files right now. Please give it another try in a few minutes.';
			break;
		case 'storage_space_exceeded':
			reason = window.jQuery
				? $.__(
						`Uploading the file "%s" would cause you to exceed your plan's storage limit. Get in touch your BambooHR administrator for help.`,
						file.name
					)
				: `Uploading the file "${file.name}" would cause you to exceed your plan's storage limit. Get in touch your BambooHR administrator for help.`;
			break;
		case 'invalid_file_type':
			reason = window.jQuery
				? $.__(
						`Sorry, we can’t accept the ".%s" file format. Make sure you’re selecting the right file and try again.`,
						getExtFromFileName(file.name)
					)
				: `Sorry, we can’t accept the ".${getExtFromFileName(
						file.name
					)}" format. Make sure you’re selecting the right file and try again.`;
			break;
		case 'invalid_file_size':
			reason = window.jQuery
				? $.__('Whoa, this is a big file (a little too big). The maximum file size you can upload is 20 MB.')
				: 'Whoa, this is a big file (a little too big). The maximum file size you can upload is 20 MB.';
			break;
		case 'invalid_file_size_empty':
			reason = window.jQuery
				? $.__('Unable to upload an empty file. Are you sure you selected the correct file?')
				: 'Unable to upload an empty file. Are you sure you selected the correct file?';
			break;
		case 'only_previewable_file_type':
			reason = window.jQuery
				? $.__('Make sure you’re only trying to upload the file types specified.')
				: 'Make sure you’re only trying to upload the file types specified.';
			break;
		case 'unknown_error':
			reason = unknownErrorMessage;
			break;
		case 'backup_company':
			reason = window.jQuery
				? $.__(`What are you crazy? You can't upload files on a backup.`)
				: `What are you crazy? You can't upload files on a backup.`;
			break;
		case 'encrypted_pdf_not_allowed':
			reason = window.jQuery ? $.__(`Encrypted PDFs are not allowed.`) : `Encrypted PDFs are not allowed.`;
			break;
		default:
			reason = failure.message || unknownErrorMessage;
	}
	return reason;
}
