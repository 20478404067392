import type { ComponentPropsWithoutRef } from 'react';
import { isExternalUrl } from './is-external-url';

type AnchorProps = Pick<ComponentPropsWithoutRef<'a'>, 'aria-label' | 'children' | 'href' | 'rel' | 'target'>;

function getAriaLabelText(props: Pick<ComponentPropsWithoutRef<'a'>, 'children' | 'target'>): string | undefined {
	const { children, target } = props;
	if (!children || typeof children !== 'string' || target !== '_blank') {
		return;
	}
	return `${children}. ${window.jQuery ? $.__('Opens in a new tab') : 'Opens in a new tab'}.`;
}

/**
 * Attempts to add easy-to-forget attributes to an `a` element when needed.
 */
export function enhanceAnchorProps<Props extends AnchorProps>(props: Props): Props & Pick<AnchorProps, 'aria-label' | 'rel'> {
	const { 'aria-label': ariaLabel, children, href, rel, target } = props;
	return {
		...props,
		// Don't add `href` if there's no `href` - it affects a11y and styling
		href: href || undefined,
		// Try to ensure a11y for links that open in a new tab
		'aria-label': ariaLabel || getAriaLabelText({ children, target }),
		// Add proper relationship for external links
		rel: rel || (isExternalUrl(href || '') ? 'external nofollow noopener noreferrer' : undefined),
	};
}
