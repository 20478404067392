import { SlidedownPortal } from '@fabric/slidedown';
import React, { FC, PropsWithChildren } from 'react';

import { MultipleMessage } from './components/multiple-message.react';
import { SingleMessage } from './components/single-message.react';
import { FileUploadErrorMessageProps } from '../../types';

export const ErrorMessage: FC<PropsWithChildren<FileUploadErrorMessageProps>> = props => {
	const { canSelectMultiple, failedFileIds = [], failuresByFileId = {}, isVisible = false, onDismiss } = props;

	const message = canSelectMultiple ? (
		<MultipleMessage failedFileIds={failedFileIds} failuresByFileId={failuresByFileId} />
	) : (
		<SingleMessage failedFileIds={failedFileIds} failuresByFileId={failuresByFileId} />
	);

	return <SlidedownPortal message={message} onDismiss={onDismiss} show={isVisible} type="error" />;
};
