import { Typography, TypographyProps } from '~components/typography';
import React, { forwardRef, Ref } from 'react';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The muted text that is appended to the main text of a tab.
 *
 * You likely won't need to use this directly as it's included in `SideTabText`.
 */
export const SideTabTextNote = forwardRef(function SideTabTextNote(props: TypographyProps, ref: Ref<HTMLSpanElement>) {
	return <Typography color="gray.800" component="span" fontWeight="regular" ref={ref} variant="teenie" {...props} />;
});
