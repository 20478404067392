import { useReducer } from 'react';
import { FilterItem } from '../../types';

import { State, FilterActions, Action } from './types';

import { ReducerActions } from './filter-reducer-actions';

function reducer(state: State, action: Action): State {
	const { allFilterItems, onChange } = action.payload;

	const actions = new ReducerActions(allFilterItems, onChange);

	switch (action.type) {
		case FilterActions.CLEAR_FIRST_FILTER: {
			return actions.clearFirstFilter(state);
		}
		case FilterActions.CLEAR_SECOND_FILTER: {
			return actions.clearSecondFilter(state);
		}
		case FilterActions.SELECT_FIRST_FILTER: {
			return actions.selectFirstFilter(state, action.payload);
		}
		case FilterActions.SELECT_SECOND_FILTER: {
			return actions.selectSecondFilter(state, action.payload);
		}
		default:
			throw new Error('Invalid action selected');
	}
}

export function useFilter(allFilterItems: FilterItem[], onChange: (data: { filters: FilterItem[] }) => void) {
	const [state, dispatch] = useReducer(reducer, {
		firstFilterItems: [],
		secondFilterItems: [],
		firstSelectedFilter: undefined,
		secondSelectedFilter: undefined,
	});

	function clearFirstFilter() {
		dispatch({
			type: 'clearFirstFilter',
			payload: {
				allFilterItems,
				onChange,
			},
		});
	}

	function clearSecondFilter() {
		dispatch({
			type: 'clearSecondFilter',
			payload: {
				allFilterItems,
				onChange,
			},
		});
	}

	function selectFirstFilter(selectedObject: { item: FilterItem; parentItem: FilterItem }) {
		dispatch({
			type: 'selectFirstFilter',
			payload: {
				allFilterItems,
				selectedFilterItem: selectedObject,
				onChange,
			},
		});
	}

	function selectSecondFilter(selectedObject: { item: FilterItem; parentItem: FilterItem }) {
		dispatch({
			type: 'selectSecondFilter',
			payload: {
				allFilterItems,
				onChange,
				selectedFilterItem: selectedObject,
			},
		});
	}

	return {
		state,
		clearFirstFilter,
		clearSecondFilter,
		selectFirstFilter,
		selectSecondFilter,
	};
}
