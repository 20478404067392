import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<{ isActive: boolean }, 'link'>()(({ mode, palette, typography }, { isActive }, classes) => ({
	root: ifFeature(
		'encore',
		{
			'& .MuiButton-root': {
				minWidth: 'unset',
				height: '32px',
				width: '32px',
				'&:hover': isActive && {
					color: palette.common.white,
				},
				'&:focus-visible': isActive && {
					color: palette.common.white,
				},
			},
		},
		{
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : palette.grey[100],
			borderColor: 'transparent',
			borderRadius: '2px',
			borderStyle: 'solid',
			borderWidth: '1px',
			fontSize: typography.small.fontSize,
			lineHeight: '22px',
			textAlign: 'center',

			'&:hover': {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.lighter,
			},
			[`&:hover .${classes.link}`]: {
				color: palette.common.white,
			},

			...(isActive
				? {
						backgroundColor: palette.common.white,
						borderColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.lighter,

						'&:hover': {
							backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
						},
					}
				: {}),
		}
	),
	link: {
		color: isActive ? palette.primary.main : palette.grey[700],
		display: 'block',
		padding: '0 8px',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'none',
		},
	},
}));
