import { ValueFormatterFunc } from 'ag-grid-community';
import { DropdownCellEditorOption, DropdownCellEditorParams } from '../data-grid.types';

/**
 * Returns a value formatter function optimized for use with dropdowns.
 */
export const createDefaultDropdownValueFormatter = (): ValueFormatterFunc => {
	// Maps an array of options to an inner map of values to text.
	// Allows us to quickly get the formatted value when the same options and value have been formatted before.
	const cache = new WeakMap<DropdownCellEditorOption[], Map<string, string>>();

	return ({ colDef, value }) => {
		const options = (colDef.cellEditorParams as DropdownCellEditorParams | undefined)?.options;

		if (!options?.length) {
			return value as string;
		}

		const cachedResults = cache.get(options);
		if (cachedResults?.has(value)) {
			return cachedResults.get(value) as string;
		}

		// Find the option whose value matches our value and use that option's text as our formatted value.
		// Using `for` loop instead of `Array.find` for performance.
		let formattedValue = value as string;
		for (let index = 0; index < options.length; index++) {
			const option = options[index];
			if (option.value === value) {
				formattedValue = option.text;
				break;
			}
		}

		(cachedResults ?? cache.set(options, new Map()).get(options))?.set(value, formattedValue);

		return formattedValue;
	};
};
