import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import { ReactNode } from 'react';
import type { EncoreSize } from '~utils';

export const DEFAULT_CLEAR_TEXT = window.jQuery ? $.__('Clear') : 'Clear';
export const DEFAULT_CLOSE_TEXT = window.jQuery ? $.__('Close') : 'Close';
export const DEFAULT_LOADING_TEXT = window.jQuery ? $.__('Loading...') : 'Loading...';
export const DEFAULT_OPEN_TEXT = window.jQuery ? $.__('Open') : 'Open';

export const SIZES = ['small', 'medium'] as const satisfies EncoreSize[];
export const VARIANTS = ['form', 'single'] as const;

export function defaultGetTagsLimitLabel(moreCount: number): ReactNode {
	return `+${moreCount} ${window.jQuery ? $.__('More') : 'More'}`;
}

export function getPopperZIndex(): number {
	return getMaxZIndex() + 10;
}
