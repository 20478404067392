import { IconV2Props } from '~components/icon-v2';

export const SIZES = [24, 32, 40, 48, 56, 64, 72, 80, 96, 128, 160, 224] as const satisfies number[];

export const mappedIconSize = (size: number): IconV2Props['size'] => {
	switch (size) {
		case 24:
			return 12;
		case 32:
			return 20;
		case 40:
			return 24;
		case 48:
			return 30;
		case 56:
			return 32;
		case 64:
			return 36;
		case 72:
			return 40;
		case 80:
			return 48;
		case 96:
			return 60;
		case 128:
			return 90;
		case 160:
			return 120;
		case 224:
			return 170;
		default:
			return 20;
	}
};

const RADIUS_MAP = {
	'xxx-small': `4px`,
	'xx-small': `8px`,
	'x-small': `12px`,
	small: `16px`,
	medium: `20px`,
	large: `24px`,
};

export const mappedBorderRadius = (size: number) => {
	switch (size) {
		case 24:
			return RADIUS_MAP['xxx-small'];
		case 32:
		case 40:
			return RADIUS_MAP['xx-small'];
		case 48:
		case 56:
			return RADIUS_MAP['x-small'];
		case 64:
		case 72:
		case 80:
			return RADIUS_MAP.small;
		case 96:
		case 128:
		case 160:
			return RADIUS_MAP.medium;
		case 224:
			return RADIUS_MAP.large;
		default:
			return RADIUS_MAP['xx-small'];
	}
};

export const avatarBoxShadow = (size: number) => {
	switch (size) {
		case 32:
		case 40:
		case 48:
		case 56:
			return `1px 1px 0px 1px rgba(56, 49, 47, 0.04)`;
		case 64:
		case 72:
		case 80:
		case 96:
		case 128:
			return `1px 1px 0px 2px rgba(56, 49, 47, 0.03)`;
		case 160:
		case 224:
			return `2px 2px 0px 2px rgba(56, 49, 47, 0.05)`;
		default:
			return `1px 1px 0px 1px rgba(56, 49, 47, 0.04)`;
	}
};
