import React from 'react';
import { isFinite } from 'lodash';

import { ISO_DATE_REGEX } from '../shared.domain';
import {
	DatePickerDate,
	DatePickerDateInfo,
	DatePickerDatePredicate,
	DatePickerDateType,
	DatePickerError,
	DatePickerUtils,
	MonthValue,
} from '../types';

export function addDateAmount(
	utils: DatePickerUtils,
	date: DatePickerDate,
	amount: number,
	unit: 'day' | 'month' | 'year'
): DatePickerDate {
	if (!utils || !date || !getIsDateValid(utils, date) || !isFinite(amount) || !unit) {
		throw new Error('Expected arguments are missing.');
	}

	if (unit === 'day') {
		return utils.addDays(date, amount);
	}

	if (unit === 'month') {
		return utils.setMonth(date, utils.getMonth(date) + amount);
	}

	if (unit === 'year') {
		return utils.setYear(date, utils.getYear(date) + amount);
	}

	return date;
}

export function getDateError(
	utils: DatePickerUtils,
	date: DatePickerDate,
	inputValue: string,
	required: boolean,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	getDateDisabled?: DatePickerDatePredicate
): DatePickerError {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!date) {
		if (inputValue) {
			return 'invalid';
		}

		if (required) {
			return 'required';
		}

		return;
	}

	if (!utils.isValid(date)) {
		return 'invalid';
	}

	if (minDate && getIsDateValid(utils, minDate) && utils.isBeforeDay(date, minDate)) {
		return 'beforeMin';
	}

	if (maxDate && getIsDateValid(utils, maxDate) && utils.isAfterDay(date, maxDate)) {
		return 'afterMax';
	}

	if (getDateDisabled && getDateDisabled(date)) {
		return 'disabled';
	}
}

export function getDateFromProp(utils: DatePickerUtils, value: undefined | string): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!value) {
		return undefined;
	}

	const trimmedValue = value.slice(0, 10); // Only use the first 10 characters of the date string (i.e. yyyy-MM-dd);
	if (!ISO_DATE_REGEX.test(trimmedValue)) {
		return undefined;
	}

	const parsedValue = utils.parse(trimmedValue, utils.formats.isoDate) as DatePickerDate;
	return getIsDateValid(utils, parsedValue) ? parsedValue : undefined;
}

export function getDateFromYearMonth(utils: DatePickerUtils, year: number, month: MonthValue): DatePickerDate {
	if (!utils || !year || !month) {
		throw new Error('Expected arguments are missing.');
	}

	if (month < 1 || month > 12) {
		throw new Error('Month values must be within the range of 1 and 12.');
	}

	return utils.date(new Date(year, month - 1)) as DatePickerDate;
}

export function getDateInfo(utils: DatePickerUtils, date: DatePickerDate): DatePickerDateInfo {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	let isoDate: undefined | string;
	let year: undefined | number;
	let month: undefined | number;
	let day: undefined | number;

	if (date && getIsDateValid(utils, date)) {
		isoDate = utils.format(date, 'isoDate');
		year = utils.getYear(date);
		month = utils.getMonth(date) + 1;
		day = Number(utils.format(date, 'dayOfMonth'));
	}

	return {
		date: isoDate,
		day,
		month,
		year,
	};
}

export function getDefaultMaxDate(utils: DatePickerUtils): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const date = getToday(utils);
	return utils.setYear(date, utils.getYear(date) + 100);
}

export function getDefaultMinDate(utils: DatePickerUtils): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const date = getToday(utils);
	return utils.setYear(date, utils.getYear(date) - 100);
}

export function getIsDateDisabled(
	utils: DatePickerUtils,
	date: DatePickerDate,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	getDateDisabled?: DatePickerDatePredicate
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return (
		(!!minDate && getIsDateValid(utils, minDate) && utils.isBeforeDay(date, minDate)) ||
		(!!maxDate && getIsDateValid(utils, maxDate) && utils.isAfterDay(date, maxDate)) ||
		(!!getDateDisabled && getDateDisabled(date))
	);
}

export function getIsDateToday(utils: DatePickerUtils, date: DatePickerDate): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return utils.isSameDay(utils.startOfDay(date), getToday(utils));
}

/**
 * This utility changes the month and/or year but tries to preserve the day of the month.
 * If the month doesn't contain the same day of month as the reference date, the last
 * day of the month will be returned instead.
 * @param utils The date utils used all over the Date Picker components
 * @param date The reference date.
 * @param month The new month.
 * @param year The new year.
 * @returns Date with month and year changed.
 */
export function changeMonthYear(utils: DatePickerUtils, date: DatePickerDate, month: number, year: number): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!date || !getIsDateValid(utils, date)) {
		throw new Error('An invalid date was supplied.');
	}

	const viewDate = getDateFromYearMonth(utils, year, (month + 1) as MonthValue) as DatePickerDateType;

	let newDate: DatePickerDate;
	if (getDay(utils, date) > utils.getDaysInMonth(viewDate)) {
		newDate = utils.startOfDay(utils.endOfMonth(viewDate));
	} else {
		newDate = utils.setMonth(utils.setYear(date, year), month);
	}
	return newDate;
}

export function getIsDateValid(utils: DatePickerUtils, date: DatePickerDate): boolean {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return !!date && utils.isValid(date);
}

export function getIsNextMonthFirstDayAfterDate(utils: DatePickerUtils, date: DatePickerDate, compareDate: DatePickerDate): boolean {
	if (!utils || !date || !getIsDateValid(utils, date) || !compareDate || !getIsDateValid(utils, compareDate)) {
		throw new Error('Expected arguments are missing.');
	}

	const nextMonthFirstDay = utils.startOfMonth(utils.getNextMonth(date));
	return utils.isAfterDay(nextMonthFirstDay, compareDate);
}

export function getIsPreviousMonthLastDayBeforeDate(
	utils: DatePickerUtils,
	date: DatePickerDate,
	compareDate: DatePickerDate
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date) || !compareDate || !getIsDateValid(utils, compareDate)) {
		throw new Error('Expected arguments are missing.');
	}

	const previousMonthLastDay = utils.endOfMonth(utils.getPreviousMonth(date));
	return utils.isBeforeDay(previousMonthLastDay, compareDate);
}

export function isNavigationKey(event: React.KeyboardEvent): boolean {
	return ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageUp', 'PageDown'].includes(event.key);
}

export function getKeyboardNavigatedDate(
	utils: DatePickerUtils,
	date: DatePickerDate,
	key: string,
	isShiftKeyPressed: boolean
): DatePickerDate {
	if (key === 'ArrowUp') {
		date = addDateAmount(utils, date, -7, 'day');
	} else if (key === 'ArrowDown') {
		date = addDateAmount(utils, date, 7, 'day');
	} else if (key === 'ArrowLeft') {
		date = addDateAmount(utils, date, -1, 'day');
	} else if (key === 'ArrowRight') {
		date = addDateAmount(utils, date, 1, 'day');
	} else if (isShiftKeyPressed && key === 'PageUp') {
		date = addDateAmount(utils, date, -1, 'year');
	} else if (isShiftKeyPressed && key === 'PageDown') {
		date = addDateAmount(utils, date, 1, 'year');
	} else if (key === 'PageUp') {
		date = addDateAmount(utils, date, -1, 'month');
	} else if (key === 'PageDown') {
		date = addDateAmount(utils, date, 1, 'month');
	}

	return date;
}

export function getToday(utils: DatePickerUtils): DatePickerDateType {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return utils.startOfDay(utils.date() as DatePickerDateType);
}

export function getDay(utils: DatePickerUtils, date: DatePickerDate): number {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return Number(utils.format(date, 'dayOfMonth'));
}

export function parseDate(utils: DatePickerUtils, format: string, value: string): DatePickerDate {
	if (!utils || !format) {
		throw new Error('Expected arguments are missing.');
	}

	let date = utils.parse(value, format) as DatePickerDate;
	if (!date || !utils.isValid(date)) {
		date = undefined;
	}

	return date;
}
