import React, { forwardRef, useContext } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FormLabelTypeMap, FormLabel } from '@mui/material';
import { Flex } from '~components/flex';
import { useInputIcon } from '~components/text-field';
import { FieldContext } from '../form/context';
import { useLabelStyles } from './label.styles';
import { LabelOwnProps, LabelProps } from './label.types';

/**
 * @description Extension of Mui `FormLabel` with icon display based on status
 *
 * @todo Remove deconstruction and reassignment of className, htmlFor, form, and required once usage of inputId, formId, and isRequired are refactored
 */
export const Label = forwardRef(
	(
		{
			afterLabel,
			children,
			disabled,
			form,
			formId,
			htmlFor,
			inputId,
			isRequired,
			required,
			status,
			text,
			viewMode,
			...props
		}: LabelProps,
		ref
	) => {
		required = required ?? isRequired;
		const context = useContext(FieldContext);
		if (context) {
			status = context.status;
			required = context.required;
			disabled = context.disabled;
		}

		const { classes } = useLabelStyles({ disabled, status, viewMode });
		const icon = useInputIcon(status);

		form = form ?? formId;
		htmlFor = htmlFor ?? inputId;

		return (
			<Flex alignItems="center" columnGap="4px">
				{!disabled && icon && <Flex marginRight={0.5}>{icon}</Flex>}
				<FormLabel
					className={classes.root}
					disabled={disabled}
					error={status === 'error'}
					form={form}
					htmlFor={htmlFor}
					ref={ref}
					required={required}
					{...props}
				>
					{text}
					{children}
				</FormLabel>
				{afterLabel}
			</Flex>
		);
	}
) as OverridableComponent<FormLabelTypeMap<LabelOwnProps>>;
