import { isObject } from 'lodash';

import { getOffsetsFromElement } from './from-element';

export function getOffsetsFromElements(elements) {
	if (!isObject(elements)) {
		throw new Error('The "elements" argument is missing an "anchor" value');
	}
	if (!(elements.anchor instanceof HTMLElement)) {
		throw new Error(`The "elements" argument doesn't reference an "anchor" element`);
	}
	if (!(elements.vessel instanceof HTMLElement)) {
		throw new Error(`The "elements" argument doesn't reference a "vessel" element`);
	}
	if (elements.container && !(elements.container instanceof HTMLElement || elements.container === window)) {
		throw new Error(`The "elements" argument doesn't reference a valid "container" element`);
	}

	return {
		anchor: getOffsetsFromElement(elements.anchor),
		container: elements.container ? getOffsetsFromElement(elements.container) : null,
		vessel: getOffsetsFromElement(elements.vessel),
	};
}
