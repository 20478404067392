import { GetGroup } from '../types';

/**
 * Returns the name of the group at the next index using the total to determine
 * if there is a next index. If there is no next index (the current index is at
 * the end of the array) or `getGroup()` is undefined, the return value will be
 * undefined.
 */
export const getGroupAtNextIndex = ({
	getGroup,
	index,
	total,
}: {
	getGroup?: GetGroup;
	index: number;
	total: number;
}): string | undefined => {
	const nextIndex = index + 1;

	return typeof getGroup === 'function' && nextIndex < total ? getGroup(nextIndex) : undefined;
};
