import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ borderRadiuses, constructs, mode, palette, typography }) => ({
	rootDimensions: {
		height: ifFeature('encore', 368, 374),
		width: ifFeature('encore', 320, 316),
	},
	rootDisplay: {
		display: 'flex',
		flexDirection: 'column',
	},
	root: {
		backgroundColor: ifFeature('encore', constructs.surface.neutral.xWeak, palette.gray[100]),
		border: `1px solid ${ifFeature('encore', constructs.border.neutral.weak, palette.gray[400])}`,
		borderRadius: ifFeature('encore', borderRadiuses[400], 3),
		boxSizing: 'border-box',
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		overflow: ifFeature('encore', 'clip'),
	},
	itemList: {
		overflow: 'hidden',
		overflowY: 'auto',
	},
	title: {
		color: mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.text.neutral.xStrong),
		fontSize: ifFeature('encore', typography.small.fontSize),
		fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightBold),
		lineHeight: ifFeature('encore', typography.small.lineHeight, typography.medium?.lineHeight),
		marginBottom: 6,
	},
}));
