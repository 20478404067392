export enum FormItemType {
	Checkbox = 'Checkbox',
	CheckboxGroup = 'CheckboxGroup',
	CurrencyPickerField = 'CurrencyPickerField',
	DateField = 'DateField',
	Group = 'Group',
	HiddenInput = 'HiddenInput',
	IntField = 'IntField',
	PercentageField = 'PercentageField',
	Section = 'Section',
	SensitiveTextField = 'SensitiveTextField',
	// @deprecated
	Select = 'Select',
	SelectField = 'SelectField',
	TextArea = 'Textarea',
	TextField = 'TextField',
	Text = 'Text',
}
