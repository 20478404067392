import React, { useEffect, useState } from 'react';
import { DURATION } from '../slidedown/constants';

const SlidedownPortalWrapper = WrappedComponent => {
	const Wrapper = props => {
		const [shouldRender, setShouldRender] = useState(false);
		const { show } = props;

		useEffect(() => {
			if (!show) {
				setTimeout(() => setShouldRender(false), DURATION);
			} else {
				setShouldRender(true);
			}
		}, [show, shouldRender]);

		return shouldRender ? <WrappedComponent {...props} /> : null;
	};

	return Wrapper;
};

export default SlidedownPortalWrapper;
