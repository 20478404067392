import { useScreenSizes } from '@fabric/utils/hooks';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Body } from './body.react';
import { Constraint } from './constraint.react';
import { Footer } from './footer.react';
import { Header } from './header.react';
import { StandardHeadline } from './standard-headline.react';
import { UpperContent } from './upper-content.react';
import { BaseModal, ConfirmationContent, FocalContent, HeroHeadline, LowerContent } from '~components/base-modal';
import { StandardModalProps } from './standard-modal.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const StandardModal: FunctionComponent<PropsWithChildren<StandardModalProps>> & {
	Body: typeof Body;
	CloseButton: typeof BaseModal.CloseButton;
	ConfirmationContent: typeof ConfirmationContent;
	Constraint: typeof Constraint;
	FocalContent: typeof FocalContent;
	Footer: typeof Footer;
	Header: typeof Header;
	HeroHeadline: typeof HeroHeadline;
	LowerContent: typeof LowerContent;
	StandardHeadline: typeof StandardHeadline;
	UpperContent: typeof UpperContent;
} = props => {
	const { contentStyles: contentStylesProp, isOpen } = props;

	const { isMediumScreen } = useScreenSizes(isOpen);

	const contentStyles = isMediumScreen
		? {
				height: '100%',
				width: '100%',
			}
		: {};

	return <BaseModal {...props} contentStyles={ifFeature('encore', { ...contentStyles, ...contentStylesProp }, contentStyles)} />;
};

StandardModal.Body = Body;
StandardModal.CloseButton = BaseModal.CloseButton;
StandardModal.ConfirmationContent = ConfirmationContent;
StandardModal.Constraint = Constraint;
StandardModal.FocalContent = FocalContent;
StandardModal.Footer = Footer;
StandardModal.Header = Header;
StandardModal.HeroHeadline = HeroHeadline;
StandardModal.LowerContent = LowerContent;
StandardModal.StandardHeadline = StandardHeadline;
StandardModal.UpperContent = UpperContent;
