import { Variants } from 'framer-motion';
import { useMemo } from 'react';

export const useCircleVariants = (cx: number, cy: number, yMax: number): Variants => {
	return useMemo(() => {
		return {
			initial: {
				cx,
				cy: yMax,
			},
			idle: {
				cx,
				cy,
			},
		};
	}, [cx, cy, yMax]);
};
