import { makeStyles, dyeColors, ModeOptions } from '~styles';
import { RoundedBoxProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useRoundedBoxStyles = makeStyles<RoundedBoxProps>({ name: 'RoundedBox' })(
	({ palette, mode }, { backgroundColor, border, borderRadius, margin, padding }) => ({
		root: {
			backgroundColor: backgroundColor || palette.gray[100],
			border: border || 'none',
			borderRadius: ifFeature('encore', 16, borderRadius || 8),
			margin,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', '20px', padding),
		},
	})
);
