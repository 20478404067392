import React, { Fragment } from 'react';

import { BaseToggleProps } from '@fabric/toggle';
import { useStyles } from './panda.styles';
import { makeUid } from '../../../../utils';

/**
 * ### `@fabric/toggle/Panda`
 * @property {string}	[inputRef]		The Name to be passed as an Ref (for Validation)
 * @property {function}	[onChange]		Callback Function
 * @property {false}	[isChecked]		Is the Toggle Checked
 * @property {false}	[isDisabled]	Is the Toggle disabled
 * @property {false}	[isRequired]	Is the Toggle required
 * @property {string}	[name]			The Name for the Toggle
 *
 * @returns {JSX}
 * */
export const PandaToggle = (props: BaseToggleProps) => {
	const { onChange, inputRef, isChecked, isDisabled, isRequired, name, id } = props;

	const uniqueId = makeUid();
	const toggleName = name || 'fab-pandaToggle';
	const toggleId = id || `fab-Toggle--${uniqueId}`;
	const { classes } = useStyles();

	return (
		<Fragment>
			<label className={classes.toggle} htmlFor={toggleId}>
				<input
					className={classes.input}
					defaultChecked={isChecked || false}
					disabled={isDisabled}
					id={toggleId}
					name={toggleName}
					onChange={onChange}
					ref={inputRef}
					required={isRequired}
					type="checkbox"
				/>
				<span className={classes.label}></span>
			</label>
		</Fragment>
	);
};
