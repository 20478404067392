/* eslint-disable sort-keys */
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { SelectableBoxProps } from './selectable-box.types';

export const useStyles = makeStyles<Pick<SelectableBoxProps, 'boxWidth' | 'isDisabled' | 'isChecked'>, 'checkboxInner' | 'disabled'>()(
	(theme, props, classes) => {
		const { borderRadiuses, constructs, mixins, mode, palette, spacing, typography } = theme;
		const { boxWidth, isChecked, isDisabled } = props;

		return {
			root: {
				alignItems: 'start',
				backgroundColor: constructs.surface.neutral.inverted,
				border: `solid 1px ${constructs.border.neutral.xWeak}`,
				borderRadius: borderRadiuses[400],
				boxShadow: mixins.boxShadow('300', palette.gray[900]),
				boxSizing: 'border-box',
				display: 'inline-flex',
				gap: spacing(2),
				minHeight: '88px',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				padding: spacing(2.5),
				position: 'relative',
				transition: 'all ease-out 100ms',
				width: boxWidth,
				'&:hover': {
					borderColor: palette.primary.main,
					cursor: 'pointer',
				},
				'&:focus-within': {
					borderColor: palette.primary.main,
					boxShadow: `0 0 0 4px ${mixins.alpha(palette.primary.light, 0.1)}`,
				},
			},
			checkbox: {
				alignSelf: 'flex-start',
				backgroundColor: constructs.surface.neutral.xWeak,
				border: `1px solid ${constructs.border.neutral.medium}`,
				borderRadius: borderRadiuses[100],
				boxShadow: `1px 1px 0 1px ${mixins.alpha(palette.gray[900], 0.05)} inset`,
				boxSizing: 'border-box',
				display: 'flex',
				fill: constructs.icon.neutral.inverted,
				flex: '0 0 auto',
				height: spacing(2.5),
				justifyContent: 'center',
				position: 'relative',
				top: '14px',
				width: spacing(2.5),
			},
			checkboxInner: {
				alignItems: 'center',
				backgroundColor: constructs.surface.primary.strong,
				borderRadius: borderRadiuses[100],
				boxShadow: mixins.boxShadow('100', palette.gray[900]),
				display: 'flex',
				height: spacing(2.5),
				justifyContent: 'center',
				// so it will size to the outside of the parent's border
				margin: '-1px',
				opacity: 0,
				transform: 'scale(0)',
				transition: 'opacity 200ms ease, transform 200ms ease',
				width: spacing(2.5),
				// so it sits on top of its parent's border
				zIndex: '1',
			},
			iconTile: {
				alignSelf: 'flex-start',
			},
			inner: {
				alignItems: 'center',
				display: 'flex',
				flex: 1,
				gap: spacing(2),
			},
			input: {
				// hide it
				height: 1,
				opacity: 0,
				left: 0,
				top: 0,
				position: 'absolute',
				width: 1,
			},
			textWrapper: {
				flexGrow: 1,
				overflowWrap: 'anywhere',
			},
			truncatedTextWrapper: {
				overflow: 'hidden',
			},
			title: {
				color: isDisabled ? constructs.text.neutral.weak : constructs.text.primary.strong,
				fontSize: typography.large.fontSize,
				fontWeight: isChecked ? typography.fontWeightBold : typography.fontWeightMedium,
			},
			description: {
				fontColor: isDisabled ? constructs.text.neutral.weak : constructs.text.neutral.strong,
				fontSize: typography.teenie.fontSize,
			},
			truncatedText: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			checked: {
				[`&:not(.${classes.disabled})`]: {
					backgroundColor: constructs.surface.neutral.xxWeak,
					borderColor: constructs.border.primary.strong,
				},
				[`&.${classes.disabled}`]: {
					[`& .${classes.checkboxInner}`]: {
						backgroundColor: constructs.surface.neutral.medium,
						boxShadow: 'none',
					},
				},
				[`& .${classes.checkboxInner}`]: {
					opacity: 1,
					transform: 'scale(1)',
				},
			},
			disabled: {
				borderColor: constructs.border.neutral.weak,
				boxShadow: 'none',
				pointerEvents: 'none',
			},
		};
	}
);
