import { BodyText, LayoutBox } from '@bamboohr/fabric';
import NavPrompt from './NavPrompt';
import { Chat, LlmConfig } from 'src/types/global';

interface SideBarGroupsProps {
	chats: Chat[];
	sessionTitle: string;
	loadChatLogs: (
		chatID: string,
		chatName: string,
		label: string,
		id: string,
		newConfig: LlmConfig,
	) => void;
	setChatName: (name: string) => void;
	setChatNames: (chats: Chat[]) => void;
	chatNames: Chat[];
	activeId: string;
}

const SideBarGroups = ({
	loadChatLogs,
	sessionTitle,
	chats,
	chatNames,
	setChatNames,
	setChatName,
	activeId,
}: SideBarGroupsProps) => {
	if (chats.length === 0) return null;

	let title = sessionTitle;

	switch (sessionTitle) {
		case 'todaySessions':
			title = 'Today';
			break;
		case 'last7DaysSessions':
			title = 'Previous 7 Days';
			break;
		case 'last30DaysSessions':
			title = 'Previous 30 Days';
			break;
		default:
			title = 'Older';
			break;
	}

	return (
		<>
			<div className="categoryContainer">
				<LayoutBox marginLeft="12px" marginBottom="8px">
					<BodyText
						size="extra-small"
						justify="start"
						color="neutral-weak"
						weight="regular"
					>
						{title}
					</BodyText>
				</LayoutBox>
				{chats.map((chat) =>
					chat.name ? (
						<NavPrompt
							key={chat.id}
							chat={chat}
							loadChatLogs={loadChatLogs}
							setChatName={setChatName}
							chatNames={chatNames}
							setChatNames={setChatNames}
							isActive={activeId === chat.id}
						/>
					) : null,
				)}
			</div>
		</>
	);
};

export default SideBarGroups;
