import {
	// @startCleanup encore
	dyeColors,
	ModeOptions,
	// @endCleanup encore
	makeStyles,
} from '~styles';
// @startCleanup encore
import { FileIconProps } from './types';
// @endCleanup encore

export const useStyles = makeStyles<{ size: number }>()((_, { size }) => ({
	root: {
		flexShrink: 0,
		height: size,
		width: size,
	},
}));

// @startCleanup encore
export const useJadeStyles = makeStyles<FileIconProps>()(({ palette, mode }, { customFill }) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			alignItems: 'center',
			display: 'inline-flex',
			justifyContent: 'center',
			lineHeight: 0,
		},
		customFill: {
			fill: customFill,
		},
		doc: {
			fill: '#2076bb',
		},
		esig: {
			fill: '#87328f',
		},
		excel: {
			fill: '#42a149',
		},
		image: {
			fill: palette.gray[700],
		},
		link: {
			fill: palette.gray[700],
		},
		mail: {
			fill: palette.gray[700],
		},
		pdf: {
			fill: '#c32a2f',
		},
		powerpoint: {
			fill: '#c26c1a',
		},
		text: {
			fill: palette.gray[700],
		},
		unknown: {
			fill: palette.gray[700],
		},
		zip: {
			fill: palette.gray[700],
		},
	};
});
// @endCleanup encore
