import { dyeColors, makeStyles, ModeOptions } from '~styles';
//@startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
//@endCleanup encore

interface Params {
	rowCount: number;
}

export const useDataGridStyles = makeStyles<Params, 'delete'>()((
	{ mode, palette, typography, constructs, spacing },
	{ rowCount },
	classes
) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'& .ag-header': {
				backgroundColor: palette.gray[100],
			},

			'& .ag-header-row': {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak),
			},

			'& .ag-header-cell': {
				textAlign: 'center',
				fontFamily: typography.fontFamily,
				fontWeight: typography.fontWeightRegular,
				fontSize: typography.medium?.fontSize,
				paddingRight: ifFeature('encore', spacing(1)),
				paddingLeft: ifFeature('encore', spacing(1)),

				'--ag-header-column-separator-display': 'block',
				'--ag-header-column-separator-height': '100%',
				'--ag-header-column-separator-width': '1px',
				'--ag-header-column-separator-color': ifFeature('encore', constructs.border.neutral.weak, palette.gray[200]),

				'--ag-header-column-resize-handle-display': 'block',
				'--ag-header-column-resize-handle-height': '100%',
				'--ag-header-column-resize-handle-width': '1px',
				'--ag-header-column-resize-handle-color':
					mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.border.neutral.weak, palette.gray[200]),
			},
			'.ag-theme-alpine &': {
				'--ag-border-color': ifFeature('encore', constructs.border.neutral.weak, palette.gray[200]),
				'--ag-row-border-color':
					mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.border.neutral.weak, palette.gray[200]),
				'--ag-header-column-separator-display': 'block',
			},

			'& .ag-ltr .ag-cell': {
				paddingRight: ifFeature('encore', spacing(1)),
				paddingLeft: ifFeature('encore', spacing(1)),

				borderRight: `solid 1px ${
					mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.border.neutral.weak, palette.gray[200])
				}`,

				'& > *': {
					fontFamily: typography.fontFamily,
					fontSize: typography.medium.fontSize,
				},

				[`&.ag-cell-focus:not(.${classes.delete}, .error-col, .warning-col, .info-col)`]: ifFeature('encore', {
					borderRadius: 0,
					outline: `${constructs.border.info.medium} solid 2px !important`,
					outlineOffset: '-2px',
				}),
			},

			[`& .${classes.delete}`]: {
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				padding: 0,
			},

			'& .ag-cell.error-col': {
				outline: `${ifFeature('encore', constructs.border.error.medium, palette.error.main)} solid 1px !important`,
				backgroundColor: ifFeature('encore', constructs.surface.error.weak, '#fbeeee'),
				outlineOffset: '-2px',

				'&.ag-cell-focus': ifFeature('encore', {
					borderRadius: 0,
					outline: `${constructs.border.error.medium} solid 2px !important`,
				}),
			},
			'& .ag-cell.warning-col': {
				outline: `${ifFeature('encore', constructs.border.warning.medium, palette.warning.main)} solid 1px !important`,
				backgroundColor: ifFeature('encore', constructs.surface.warning.weak, '#f3e9db'),
				outlineOffset: '-2px',

				'&.ag-cell-focus': ifFeature('encore', {
					borderRadius: 0,
					outline: `${constructs.border.warning.medium} solid 2px !important`,
				}),
			},
			'& .ag-cell.info-col': {
				outline: `${ifFeature('encore', constructs.border.info.medium, palette.info.main)} solid 1px !important`,
				backgroundColor: ifFeature('encore', constructs.surface.info.weak, '#e6f1f7'),
				outlineOffset: '-2px',

				'&.ag-cell-focus': ifFeature('encore', {
					borderRadius: 0,
					outline: `${constructs.border.info.medium} solid 2px !important`,
				}),
			},
			// AG Grid has a default 150px min-height when using `domLayout="autoHeight"`. This can cause an ugly space between the last row and the bottom border, so we disable it, unless there is no data to show, in which case we leave it so the "no data" message can be displayed.
			// See bottom of this section: https://www.ag-grid.com/javascript-data-grid/grid-size/#grid-auto-height
			'&& .ag-layout-auto-height .ag-center-cols-clipper': {
				minHeight: rowCount > 0 ? 'unset' : undefined,
			},
		},
		rowCol: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[100]),
			textAlign: 'center',
		},
		delete: {
			'&:focus-within': {
				borderColor: 'transparent !important',
				borderRightColor: `${ifFeature('encore', constructs.border.neutral.weak, palette.gray[200])} !important`,
			},
		},
	};
});
