import { BEM } from '@bamboohr/utils/lib/dom';
import React from 'react';

import { PRIVACY_LINK_TEXT, TERMS_LINK_TEXT } from './constants';

export function PrivacyAndTerms() {
	const bem = new BEM('fab-InterstitialPrivacyAndTerms');
	const linkCSSClasses = `${bem.elem('link')} fab-Link fab-Link--muted`;

	return (
		<div className={bem.elem()}>
			<a
				className={linkCSSClasses}
				href="https://www.bamboohr.com/privacy.php"
				rel="noopener external nofollow noreferrer"
				target="_blank"
			>
				{PRIVACY_LINK_TEXT}
			</a>
			<span className={bem.elem('dot')}>·</span>
			<a
				className={linkCSSClasses}
				href="https://www.bamboohr.com/terms.php"
				rel="noopener external nofollow noreferrer"
				target="_blank"
			>
				{TERMS_LINK_TEXT}
			</a>
		</div>
	);
}
