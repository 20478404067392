import { useCallback, useEffect, useState } from 'react';
import config from '../config';
import api from '../services/api';
import { Dropdown, Flex } from '@bamboohr/fabric';
import { useMemo } from 'react';
import { useLlmStore } from 'src/store';
import { LlmConfig, LlmResponseMode } from 'src/types/global';

const MODES_ENDPOINT = config.VERSION_ENDPOINT + 'modes';

interface LlmSelectorProps {
	setModes: (modes: {}) => void;
}

export const LlmSelector = ({ setModes }: LlmSelectorProps) => {
	const [options, setOptions] = useState<any>([]);
	const [selectedOption, setSelectedOption] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const updateLlmState = useLlmStore((state) => state.updateLlmState);

	const getLlmData = useCallback(async () => {
		api
			.get(MODES_ENDPOINT)
			.then((response) => {
				const newOptions = response.data.map((resp: LlmResponseMode) => {
					return {
						label: resp.mode,
						value: resp.function_id,
						llm_config: resp.llm_config,
						sort_rank: resp.sort_rank ?? Infinity,
					};
				});

				newOptions.sort(
					(a: { sort_rank: number }, b: { sort_rank: number }) =>
						a.sort_rank - b.sort_rank,
				);

				const newModes = response.data.reduce(
					(accumulator: { [x: string]: string }, resp: LlmResponseMode) => {
						accumulator[resp.function_id] = resp.mode;
						return accumulator;
					},
					{},
				);

				setOptions(newOptions);
				setModes(newModes);
				updateLlmState({
					newId: newOptions[0].value,
					newLabel: newOptions[0].label,
					newConfig: newOptions[0].llm_config,
				});
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error occurred:', error);
			});
	}, [setModes, updateLlmState]);

	useEffect(() => {
		if (options.length === 0) {
			getLlmData();
		}
	}, [getLlmData, options.length]);

	const dropdownOptions = useMemo(
		() =>
			options.map(
				(option: { label: string; value: string; llm_config: LlmConfig }) => ({
					text: option.label,
					value: option,
				}),
			),
		[options],
	);

	return (
		<Flex>
			{isLoading ? (
				<span>Loading....</span>
			) : (
				<Dropdown
					ButtonProps={{
						size: 'teenie',
						variant: 'text',
						text: selectedOption ? selectedOption.label : options[0].label,
					}}
					items={dropdownOptions}
					onSelect={(selected) => {
						const { value, label, llm_config } = selected;
						setSelectedOption(selected);
						updateLlmState({
							newId: value,
							newLabel: label,
							newConfig: llm_config,
						});
					}}
					showCaret={true}
					width={100}
					menuWidth={8}
				/>
			)}
		</Flex>
	);
};
