import { Box } from '@mui/system';
import { ComponentType, PropsWithChildren } from 'react';
import { CheckboxField } from './elements/checkbox-field';
import { CurrencyField } from './elements/currency-field';
import { DateField } from './elements/date-field';
import { Group } from './elements/group';
import { Section } from './elements/section';
import { SelectField } from './elements/select-field';
import { TextareaField } from './elements/text-area-field';
import { TextField } from './elements/text-field';
import { BuiltinFormElementType } from './enums';
import { FormConfig } from './form-factory.types';

export const componentsByType: Record<string, ComponentType<PropsWithChildren<any>>> = {
	DateField,
	Group,
	CheckboxField,
	CurrencyField,
	SelectField,
	Section,
	TextField,
	TextareaField,
	Text: Box,
} as const;

export const emptyFormConfig: FormConfig = {
	layout: {
		root: {
			id: 'root',
		},
	},
	models: {
		root: {
			id: 'root',
			type: 'Group',
		},
	},
};

export const modelTypesThatValidateOnBlur = Object.freeze([
	BuiltinFormElementType.NumberField,
	BuiltinFormElementType.PercentageField,
] as Array<BuiltinFormElementType | string>);

export const modelTypesThatRevalidateOnChange = Object.freeze([
	BuiltinFormElementType.CheckboxField,
	BuiltinFormElementType.DateField,
	BuiltinFormElementType.SelectField,
] as Array<BuiltinFormElementType | string>);
