import { ifFeature } from '@bamboohr/utils/lib/feature';
import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';

export const StyledSvg = styled('svg', { shouldForwardProp: prop => prop !== 'brand' })<{ brand?: boolean; theme: Theme }>(({
	brand,
	theme,
}) => {
	const { palette, mode } = theme as Theme;
	const fill = brand ? palette.primary.main : undefined;

	return ifFeature(
		'encore',
		// mark legacy icons with construction tape so it's obvious they should not be used
		window.ENV === 'dev' || window.ENV === 'stage'
			? {
					background: 'repeating-linear-gradient(45deg, #222222, #222222 4px, #f0d232 4px, #f0d232 8px)',
					'& *': {
						display: 'none',
					},
				}
			: {
					fill: mode === ModeOptions.Dye ? dyeColors.main : fill,
					outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				},
		{
			fill: mode === ModeOptions.Dye ? dyeColors.main : fill,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		}
	);
});
