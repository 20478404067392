import { FormFactoryElement, FormFactoryElementChange } from '../types/element.types';
import { FormFactoryField } from '../types/field.types';
import { isFormField } from '../utils/is-form-field';

export function onChangeMiddleware(onChange: (change: Omit<FormFactoryElementChange<FormFactoryField>, 'onChange'>) => void) {
	return (props: FormFactoryElement): FormFactoryElement & Record<string, unknown> => {
		if (!isFormField(props)) {
			return props;
		}

		return {
			...props,
			onChange: (change: FormFactoryElementChange<FormFactoryField>) => {
				// Removing `onChange` so that someone doesn't accidentally modify it.
				const { onChange: intentionallyDiscarded, ...rest } = change;

				if ('onChange' in props && typeof props.onChange === 'function') {
					props.onChange(rest);
				}

				onChange(rest);
			},
		};
	};
}
