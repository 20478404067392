import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { BODY_SIZE_SMALL, BODY_SIZE_MEDIUM, BODY_SIZE_FULL } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStandardModalBodyStyles = makeStyles()(({ palette, mode, spacing, mixins }) => {
	return {
		root: {
			borderRadius: ifFeature('encore', '16px'),
			backgroundColor: ifFeature('encore', palette.common.white),
			alignSelf: 'center',
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			maxHeight: '90vh',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', '4px', spacing(3)),
			// @startCleanup encore
			width: ifFeature('encore', undefined, '100%'),
			// @endCleanup encore
			boxShadow: ifFeature('encore', `2px 2px 0px 2px ${mixins.alpha(palette.gray[900], 0.05)}`),
			'@media print': {
				maxWidth: '100%', // The modal is too wide when printing and this forces the modal to fit on the screen when printing
			},
		},
		sizeSmall: {
			width: ifFeature('encore', BODY_SIZE_SMALL),
			// @startCleanup encore
			maxWidth: ifFeature('encore', undefined, `calc(${BODY_SIZE_SMALL} + ${spacing(6)})`),
			// @endCleanup encore
		},
		sizeMedium: {
			width: ifFeature('encore', BODY_SIZE_MEDIUM),
			// @startCleanup encore
			maxWidth: ifFeature('encore', undefined, `calc(${BODY_SIZE_MEDIUM} + ${spacing(6)})`),
			// @endCleanup encore
		},
		sizeFull: {
			width: ifFeature('encore', BODY_SIZE_FULL),
		},
	};
});
