import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useSheetModalBodyStyles = makeStyles()(({ mixins, mode, palette, spacing }) => {
	return {
		root: {
			borderRadius: ifFeature('encore', '16px'),
			backgroundColor: ifFeature('encore', palette.common.white),
			alignSelf: 'center',
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			maxHeight: '90vh',
			padding: ifFeature('encore', '4px', spacing(3)),
			width: '100%',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			boxShadow: ifFeature('encore', `2px 2px 0px 2px ${mixins.alpha(palette.gray[900], 0.05)}`),
			'@media print': {
				maxWidth: '100%', // The modal is too wide when printing and this forces the modal to fit on the screen when printing
			},
		},
		sizeSmall: {
			maxWidth: `calc(528px + ${spacing(6)})`,
		},
		sizeMedium: {
			maxWidth: `calc(720px + ${spacing(6)})`,
		},
		sizeLarge: {
			maxWidth: `calc(912px + ${spacing(6)})`,
		},
	};
});
