import { PaletteOptions } from '@mui/material/styles/createPalette';
import jsonThemeColors from '../../definitions/json/encore-theme-colors.json';
import jsonColors from '../../definitions/json/encore-colors.json';
import { getChartColors } from './getChartColors';

export const getEncorePalette = (brandColor?: string): PaletteOptions => {
	const { themeColors } = jsonThemeColors;
	const { colors } = jsonColors;
	const selectedColors = themeColors.find(theme => theme.name === brandColor) || themeColors[0];

	const gray = {
		50: colors.gray05,
		100: colors.gray1,
		200: colors.gray2,
		300: colors.gray3,
		400: colors.gray4,
		500: colors.gray5,
		600: colors.gray6,
		700: colors.gray7,
		800: colors.gray8,
		900: colors.gray9,
		1000: colors.gray10,
	};

	return {
		action: {
			active: colors.info,
			highlight: colors.action,
		},
		background: {
			default: colors.white,
			paper: colors.gray1,
		},
		charts: getChartColors(),
		common: {
			black: '#000',
			white: '#FFF',
		},
		discovery: {
			contrastText: colors.white,
			dark: colors.discoveryDark,
			light: colors.discoveryLight,
			main: colors.discovery,
		},
		error: {
			contrastText: colors.white,
			dark: colors.errorDark,
			light: colors.errorLight,
			main: colors.error,
		},
		gray,
		grey: gray,
		info: {
			contrastText: colors.white,
			dark: colors.infoDark,
			light: colors.infoLight,
			main: colors.info,
		},
		link: {
			main: colors.link,
		},
		mode: 'light',
		primary: {
			contrastText: colors.white,
			dark: selectedColors.dark,
			light: selectedColors.light,
			/**
			 * There is no `lighter` color, so we're using the
			 * `light` color for backwards compatibility.
			 */
			lighter: selectedColors.light,
			lightest: selectedColors.lightest,
			main: selectedColors.base,
		},
		secondary: {
			contrastText: colors.gray8,
			dark: colors.gray6,
			light: colors.gray3,
			main: colors.gray2,
		},
		success: {
			contrastText: colors.white,
			dark: colors.successDark,
			light: colors.successLight,
			main: colors.success,
		},
		text: {
			primary: colors.gray9,
			secondary: colors.gray8,
		},
		warning: {
			contrastText: colors.white,
			dark: colors.warningDark,
			light: colors.warningLight,
			main: colors.warning,
		},
	};
};
