import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement } from 'react';
import { Flex } from '~components/flex';
import { Header } from '../header';
import { SubsectionProps } from './subsection.types';

export const Subsection = ({ ariaLabel, children, gap = 0, title, ...rest }: SubsectionProps): ReactElement => {
	return (
		<section {...rest}>
			{ifFeature(
				'encore',
				<Flex flexDirection="column" gap={gap}>
					{title && <Header divider="top" size="medium" title={title} />}
					{children}
				</Flex>,
				'Subsection component is only available with Encore enabled.'
			)}
		</section>
	);
};
