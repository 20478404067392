import React, { ReactNode } from 'react';
import { makeStyles } from '~styles';
import { Flex } from '~components/flex';

interface CenterProps {
	children: ReactNode;
}

const useStyles = makeStyles()(() => {
	return {
		root: {
			pointerEvents: 'none',
		},
	};
});

export const Center = ({ children }: CenterProps) => {
	const { classes } = useStyles();
	return (
		<Flex
			alignItems="center"
			bottom="0"
			className={classes.root}
			justifyContent="center"
			left="0"
			position="absolute"
			right="0"
			top="0"
		>
			{children}
		</Flex>
	);
};
