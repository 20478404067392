// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ScaleBand } from 'd3-scale';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Axis } from '../axis';
import { defaultTransition, useChartColors } from '../common';
import { SwimLanes } from '../swim-lanes';
import { BarChartProps } from '../types';
import { BarGroupsVertical } from './bar-groups-vertical';
import { Bars } from './bars';
import { AnimatedSvg } from '../animated-svg';
import { ResponsiveParentSize } from '../responsive-parent-size';

export const BarChart = <Datum = unknown,>({
	animate = true,
	axisBottom,
	axisLeft,
	axisRight,
	axisTop,
	chartTitle = window.jQuery ? $.__('bar chart') : 'bar chart',
	barGroups,
	barOuterPadding,
	barPadding = 0.2,
	barValues,
	data,
	keys,
	margin = { top: 0, right: 0, bottom: 0, left: 0 },
	getBandTotal,
	getBarGroupsCondition,
	getBarGroupsFormat,
	getBarValue = (d, key) => d[key] as number,
	getBarValueFormat,
	getColor,
	getSeriesValue,
	onClick,
	onMouseLeave,
	onMouseMove,
	hoverEffect,
	orientation = 'vertical',
	swimLanesHorizontal,
	swimLanesVertical,
	transition = defaultTransition,
	xScaleType = 'band',
	yScaleType = 'linear',
	renderOverlay,
}: BarChartProps<Datum>): ReactElement => {
	const totals = data.reduce<number[]>((acc, curr) => {
		const total = getBandTotal(curr);
		acc.push(total);
		return acc;
	}, []);

	// scales
	const color = scaleOrdinal({
		domain: keys,
		range: useChartColors({ onGetColor: getColor }).colors,
	});

	const linear = (range: number[]) =>
		scaleLinear<number>({
			domain: [0, Math.max(...totals) || 100],
			nice: true,
			range,
			round: true,
		});

	const band = (range: number[]) =>
		scaleBand<string>({
			domain: data.map(getSeriesValue),
			padding: barPadding,
			paddingOuter: barOuterPadding,
			range: range as [number, number],
			round: false,
		});

	return (
		<>
			<ResponsiveParentSize>
				{({ height, width }) => {
					const xMax = width && width - margin.right - margin.left;
					const yMax = height && height - margin.top - margin.bottom;

					const xRange = [0, xMax];
					const yRange = [yMax, 0];

					const xScale = xScaleType === 'band' ? band(xRange) : linear(xRange);
					const yScale = yScaleType === 'band' ? band(yRange) : linear(yRange);

					const swimLanesProps = {
						height: yMax,
						width: xMax,
					};

					const swimLanesHorizontalProps = {
						horizontal: {
							scale: yScale,
							...swimLanesProps,
						},
					};

					const swimLanesVerticalProps = {
						vertical: {
							scale: xScale,
							...swimLanesProps,
						},
					};

					const getAlignLabelsLeftProps = () => {
						if (typeof axisLeft === 'object' && axisLeft.alignLabelsLeft) {
							return {
								hideAxisLine: true,
								hideTicks: true,
								left: -(margin.left - 10),
								tickLabelHorizontalAnchor: 'start',
								tickLabelMaxWidth: margin.left,
							};
						}
						return {};
					};

					const getAlignLabelsTopProps = () => {
						if (typeof axisLeft === 'object' && axisLeft.alignLabelsTop) {
							return {
								hideAxisLine: true,
								hideTicks: true,
								tickLabelHorizontalAnchor: 'start',
								tickLabelVerticalAnchor: 'end',
								tickLabelXOffset: 10,
								tickLabelYOffset: -(yScale as ScaleBand<string>).bandwidth() / 2 - 5,
							};
						}
						return {};
					};

					const axisBottomProps = {
						bottom: {
							scale: xScale,
							top: yMax,
							...(typeof axisBottom === 'object' && axisBottom),
						},
					};
					const axisLeftProps = {
						left: {
							scale: yScale,
							...(typeof axisLeft === 'object' && axisLeft),
							...getAlignLabelsLeftProps(),
							...getAlignLabelsTopProps(),
						},
					};
					const axisRightProps = {
						right: {
							scale: yScale,
							left: xMax,
							...(typeof axisRight === 'object' && axisRight),
						},
					};
					const axisTopProps = {
						top: {
							scale: xScale,
							...(typeof axisTop === 'object' && axisTop),
						},
					};

					const barsProps = {
						animate,
						barValues,
						color,
						data,
						getBandTotal,
						getBarValue,
						getBarValueFormat,
						getSeriesValue,
						keys,
						onClick,
						onMouseLeave,
						onMouseMove,
						orientation,
						xMax,
						xScale,
						yMax,
						yScale,
						hoverEffect,
						renderOverlay,
					};

					const isVertical = orientation === 'vertical';

					if (xMax === 0 || yMax === 0) {
						return null; // Ensures that animations animate from the correct position
					}

					return (
						<AnimatedSvg chartTitle={chartTitle} height={height} transition={transition} width={width}>
							<Group left={margin.left} top={margin.top}>
								<SwimLanes
									{...(swimLanesHorizontal && swimLanesHorizontalProps)}
									{...(swimLanesVertical && swimLanesVerticalProps)}
								/>
								<Axis
									{...(axisBottom && axisBottomProps)}
									{...(axisLeft && axisLeftProps)}
									{...(axisRight && axisRightProps)}
									{...(axisTop && axisTopProps)}
								/>
								{barGroups && isVertical && (
									<BarGroupsVertical
										data={data}
										getBarGroupsCondition={getBarGroupsCondition}
										getBarGroupsFormat={getBarGroupsFormat}
										getSeriesValue={getSeriesValue}
										xScale={
											xScale as ScaleBand<string> /* The x-scale is a band scale, if the chart is vertical */
										}
										yMax={yMax}
									/>
								)}
								<Bars<Datum> {...barsProps} />
							</Group>
						</AnimatedSvg>
					);
				}}
			</ResponsiveParentSize>
		</>
	);
};

BarChart.displayName = 'BarChart';
