import { PieCoreProps } from './types/pie-core-props';

export const getFilteredData = <Datum = unknown>(data: Datum[] | undefined, pieValue: PieCoreProps<Datum>['pieValue']) => {
	return (
		data?.reduce<{ indexes: number[]; data: Datum[] }>(
			({ indexes, data }, curr, i) => {
				if (pieValue && pieValue(curr) !== 0) {
					data.push(curr);
					indexes.push(i);
				}
				return { data, indexes };
			},
			{ indexes: [], data: [] }
		) || { indexes: [], data: [] }
	);
};
