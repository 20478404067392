// eslint-disable-next-line no-use-before-define
import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import { TextButton } from '../../button';

interface LegendLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children: ReactNode;
	href?: string;
	onClick?: (event) => void;
}

export const LegendLink = ({ children, href, onClick, ...rest }: LegendLinkProps) => {
	if (href && typeof href === 'string') {
		return (
			<a href={href} {...rest} style={{ display: 'flex', alignItems: 'center' }}>
				{children}
			</a>
		);
	}

	if (onClick && typeof onClick === 'function') {
		return <TextButton onClick={onClick}>{children}</TextButton>;
	}

	return <>{children}</>;
};
