import { CSSProperties, useMemo } from 'react';
import { Breakpoint, Theme, useTheme } from '@mui/material';
import { CSSObject } from 'tss-react';
import { ResponsiveProp } from '~shared-types';
import { isRecord } from '../is-record';

function extractBreakpointsFromProps(
	props: {
		[key: string]: ResponsiveProp<keyof CSSProperties>;
	},
	{ breakpoints }: Theme
) {
	return Object.entries(props).reduce<CSSObject>((acc, [propName, propValue]) => {
		if (isRecord(propValue)) {
			Object.entries(propValue).forEach(([breakpointName, breakpointValue]) => {
				const breakpoint = breakpoints.up(breakpointName as Breakpoint);

				acc[breakpoint] = {
					...((acc[breakpoint] as {}) || {}),
					[propName]: breakpointValue,
				};
			});
		} else {
			acc[propName] = propValue;
		}
		return acc;
	}, {});
}

export function useExtractBreakpoints(props: Record<string, ResponsiveProp<keyof CSSProperties>>) {
	const theme = useTheme();
	return useMemo(() => {
		return extractBreakpointsFromProps(props, theme);
	}, [JSON.stringify(props), theme]);
}
