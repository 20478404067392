import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

type ToggleSizeStyles = {
	labelHeight: string;
	labelWidth: string;
	labelBeforeHeight: string;
	labelBeforeWidth: string;
	labelBeforeTop: string;
	labelBeforeRight: string;
	inputBeforeRight: string;
	valueTop: string;
	inputNestedLeft: string;
	valueLeft: string;
};

const getToggleSizeStyles = (size: 'small' | 'medium' | 'large', spacing: (value: number) => string): ToggleSizeStyles => {
	let labelHeight = spacing(2.5);
	let labelWidth = spacing(5);
	let labelBeforeHeight = spacing(2);
	let labelBeforeWidth = spacing(2);
	let labelBeforeTop = spacing(0.25);
	let labelBeforeRight = spacing(2.75);
	let inputBeforeRight = spacing(0.25);
	let valueTop = '5px';
	let inputNestedLeft = spacing(0.75);
	let valueLeft = spacing(3);

	if (size === 'large') {
		labelHeight = spacing(4);
		labelWidth = spacing(6.75);
		labelBeforeHeight = spacing(3);
		labelBeforeWidth = spacing(3);
		labelBeforeTop = spacing(0.5);
		labelBeforeRight = spacing(3.25);
		inputBeforeRight = spacing(0.5);
		valueTop = '9px';
		inputNestedLeft = spacing(1);
		valueLeft = spacing(4);
	} else if (size === 'small') {
		labelHeight = spacing(2);
		labelWidth = spacing(4);
		labelBeforeHeight = spacing(1.5);
		labelBeforeWidth = spacing(1.5);
		labelBeforeTop = spacing(0.25);
		labelBeforeRight = spacing(2.25);
		inputBeforeRight = spacing(0.25);
		valueTop = spacing(0.5);
		inputNestedLeft = spacing(0.5);
		valueLeft = spacing(2.5);
	}

	return {
		inputBeforeRight,
		inputNestedLeft,
		labelBeforeHeight,
		labelBeforeRight,
		labelBeforeTop,
		labelBeforeWidth,
		labelHeight,
		labelWidth,
		valueLeft,
		valueTop,
	};
};

export const useStyles = makeStyles<{ size: 'small' | 'medium' | 'large' }, 'value' | 'label'>()((theme, _params, classes) => {
	const { size } = _params;
	const { constructs, mixins, mode, palette, typography, spacing } = theme;
	const duration = '200ms';
	const bezier = 'cubic-bezier(0.25, 0.1, 0.25, 1)';

	const {
		inputBeforeRight,
		inputNestedLeft,
		labelBeforeHeight,
		labelBeforeRight,
		labelBeforeTop,
		labelBeforeWidth,
		labelHeight,
		labelWidth,
		valueLeft,
		valueTop,
	} = getToggleSizeStyles(size, spacing);

	return ifFeature(
		'encore',
		{
			toggle: {
				boxSizing: 'border-box',
				cursor: 'pointer',
				display: 'inline-flex',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				position: 'relative',
				userSelect: 'none',
				':has(> input:disabled)': {
					cursor: 'default',
				},
			},
			value: {
				left: valueLeft,
				position: 'absolute',
				display: 'flex',
				top: valueTop,
				transition: `left ${duration} ${bezier}`,
			},
			input: {
				height: '1px',
				opacity: 0,
				position: 'absolute',
				width: '1px',
				[`&:checked + .${classes.label}`]: {
					backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					boxShadow: `1px 1px 0px 1px ${mixins.alpha(palette.gray[900], 0.1)} inset`,
					'&:before': {
						right: inputBeforeRight,
					},
					[`& > .${classes.value}`]: {
						border: 'none',
						left: inputNestedLeft,
						opacity: 1,
					},
				},
				[`&:focus + .${classes.label}`]: {
					boxShadow: `0 0 0 2px ${palette.primary.light}, 0 0 0 4px ${
						palette.primary.lightest
					}, inset 1px 2px 0px -1px ${mixins.alpha(palette.common.black, 0.1)}`,
				},
			},
			label: {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[400],
				border: 'none',
				borderRadius: theme.borderRadiuses[1000],
				boxShadow: `1px 1px 0px 1px ${mixins.alpha(palette.gray[900], 0.03)}`,
				height: labelHeight,
				width: labelWidth,
				// circle handle
				'&:before': {
					backgroundColor: constructs.icon.neutral.inverted,
					borderRadius: theme.borderRadiuses[1000],
					content: '""',
					height: labelBeforeHeight,
					right: labelBeforeRight,
					opacity: 1,
					position: 'absolute',
					top: labelBeforeTop,
					transition: `${duration} ${bezier}`,
					width: labelBeforeWidth,
				},
			},
			icon: {
				fill: constructs.icon.neutral.inverted,
			},
		},
		{
			toggle: {
				boxSizing: 'border-box',
				cursor: 'pointer',
				display: 'inline-flex',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				position: 'relative',
				userSelect: 'none',
				':has(> input:disabled)': {
					cursor: 'default',
				},
			},
			value: {
				color: palette.common.white,
				fontSize: '24px',
				lineHeight: '25px',
				fontWeight: typography.fontWeightBold,
				left: '18px',
				position: 'absolute',
				display: 'flex',
				transition: `left ${duration} ${bezier}`,
			},
			input: {
				height: '1px',
				opacity: 0,
				position: 'absolute',
				width: '1px',
				[`&:checked + .${classes.label}`]: {
					backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
					'&:before': {
						left: '18px',
					},
					[`& > .${classes.value}`]: {
						border: 'none',
						left: '4px',
						opacity: 1,
					},
				},
			},
			label: {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[500],
				border: 'none',
				borderRadius: '2px',
				height: '16px',
				width: '32px',
				// square handle
				'&:before': {
					backgroundColor: palette.common.white,
					borderRadius: '2px',
					content: '""',
					height: '14px',
					left: '1px',
					opacity: 1,
					position: 'absolute',
					top: '1px',
					transition: `${duration} ${bezier}`,
					width: '13px',
				},
			},
			icon: {},
		}
	);
});
