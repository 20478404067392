import Ajax from '@bamboohr/utils/lib/ajax';
import { SPRITE_URL } from './constants';

const spriteId = 'fabric-sprite';
let getting = false;

export const bootstrap = (): void => {
	if (!document.getElementById(spriteId) && !getting) {
		getting = true;
		Ajax.get(SPRITE_URL)
			.then(res => {
				const sprite = res.data as string;

				const fragment = document.createRange().createContextualFragment(sprite);

				const svg = fragment.querySelector<SVGElement>('svg');
				if (svg) {
					svg.id = spriteId;
					svg.style.display = 'none';
				} else {
					throw new Error('Unable to add Fabric sprite to page.');
				}

				document.body.appendChild(fragment);
				getting = false;
			})
			.catch(err => {
				getting = false;
				console.error(err);
			});
	}
};
