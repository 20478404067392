import { CurrencyItem } from '~components/currency-field';

export const currencyItems: CurrencyItem[] = [
	{ id: 1, code: 'USD', name: 'US Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 8, code: 'CAD', name: 'Canadian Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 3, code: 'GBP', name: 'British Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 2, code: 'EUR', name: 'Euro', symbol: '\u20ac', decodedSymbol: '\u20ac', symbolPosition: 0 },
	{ id: 22, code: 'AED', name: 'UAE dirham', symbol: 'Dhs', decodedSymbol: 'Dhs', symbolPosition: 0 },
	{ id: 96, code: 'AFN', name: 'Afghanistan Afghani', symbol: '\u060b', decodedSymbol: '\u060b', symbolPosition: 1 },
	{ id: 84, code: 'ALL', name: 'Albania Lek', symbol: 'Lek', decodedSymbol: 'Lek', symbolPosition: 0 },
	{ id: 130, code: 'AMD', name: 'Armenian Dram', symbol: '\u058f', decodedSymbol: '\u058f', symbolPosition: 0 },
	{ id: 35, code: 'ANG', name: 'Netherlands Antilles Guilder', symbol: '\u0192', decodedSymbol: '\u0192', symbolPosition: 0 },
	{ id: 139, code: 'AOA', name: 'Angolan Kwanza', symbol: 'Kz', decodedSymbol: 'Kz', symbolPosition: 0 },
	{ id: 6, code: 'ARS', name: 'Argentine Peso', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 7, code: 'AUD', name: 'Australian Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 34, code: 'AWG', name: 'Aruba Guilder', symbol: '\u0192', decodedSymbol: '\u0192', symbolPosition: 0 },
	{
		id: 76,
		code: 'AZN',
		name: 'Azerbaijan New Manat',
		symbol: '\u043c\u0430\u043d',
		decodedSymbol: '\u043c\u0430\u043d',
		symbolPosition: 0,
	},
	{ id: 82, code: 'BAM', name: 'Bosnia and Herzegovina Convertible Marka', symbol: 'KM', decodedSymbol: 'KM', symbolPosition: 0 },
	{ id: 49, code: 'BBD', name: 'Barbados Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 119, code: 'BDT', name: 'Bangladeshi Taka', symbol: '\u09f3', decodedSymbol: '\u09f3', symbolPosition: 0 },
	{ id: 72, code: 'BGN', name: 'Bulgaria Lev', symbol: '\u043b\u0432', decodedSymbol: '\u043b\u0432', symbolPosition: 0 },
	{ id: 126, code: 'BHD', name: 'Bahraini Dinar', symbol: 'BD', decodedSymbol: 'BD', symbolPosition: 0 },
	{ id: 143, code: 'BIF', name: 'Burundian Franc', symbol: 'FBu', decodedSymbol: 'FBu', symbolPosition: 0 },
	{ id: 50, code: 'BMD', name: 'Bermuda Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 51, code: 'BND', name: 'Brunei Darussalam Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 65, code: 'BOB', name: 'Bolivia Boliviano', symbol: '$b', decodedSymbol: '$b', symbolPosition: 0 },
	{ id: 19, code: 'BRL', name: 'Brazilian Real', symbol: 'R$', decodedSymbol: 'R$', symbolPosition: 0 },
	{ id: 48, code: 'BSD', name: 'Bahamas Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 88, code: 'BWP', name: 'Botswana Pula', symbol: 'P', decodedSymbol: 'P', symbolPosition: 0 },
	{ id: 103, code: 'BYN', name: 'Belarus Ruble', symbol: 'p.', decodedSymbol: 'p.', symbolPosition: 0 },
	{ id: 68, code: 'BZD', name: 'Belize Dollar', symbol: 'BZ$', decodedSymbol: 'BZ$', symbolPosition: 0 },
	{ id: 141, code: 'CDF', name: 'Congolese Francs', symbol: 'FC', decodedSymbol: 'FC', symbolPosition: 0 },
	{ id: 9, code: 'CHF', name: 'Swiss Franc', symbol: 'CHF', decodedSymbol: 'CHF', symbolPosition: 0 },
	{ id: 53, code: 'CLP', name: 'Chile Peso', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 18, code: 'CNY', name: 'Chinese Yuan Renminbi', symbol: '\u00a5', decodedSymbol: '\u00a5', symbolPosition: 0 },
	{ id: 54, code: 'COP', name: 'Colombia Peso', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 36, code: 'CRC', name: 'Costa Rica Colon', symbol: '\u20a1', decodedSymbol: '\u20a1', symbolPosition: 0 },
	{ id: 46, code: 'CUP', name: 'Cuba Peso', symbol: '\u20b1', decodedSymbol: '\u20b1', symbolPosition: 0 },
	{ id: 81, code: 'CZK', name: 'Czech Republic Koruna', symbol: 'K\u010d', decodedSymbol: 'K\u010d', symbolPosition: 0 },
	{ id: 98, code: 'DKK', name: 'Denmark Krone', symbol: 'kr', decodedSymbol: 'kr', symbolPosition: 0 },
	{ id: 90, code: 'DOP', name: 'Dominican Republic Peso', symbol: 'RD$', decodedSymbol: 'RD$', symbolPosition: 0 },
	{ id: 142, code: 'DZD', name: 'Algerian Dinar', symbol: '\u062f.\u062c', decodedSymbol: '\u062f.\u062c', symbolPosition: 1 },
	{ id: 140, code: 'ECS', name: 'Ecuadorian Sucres', symbol: 'S/.', decodedSymbol: 'S/.', symbolPosition: 0 },
	{ id: 99, code: 'EEK', name: 'Estonia Kroon', symbol: 'kr', decodedSymbol: 'kr', symbolPosition: 0 },
	{ id: 105, code: 'EGP', name: 'Egypt Pound', symbol: 'E\u00a3', decodedSymbol: 'E\u00a3', symbolPosition: 0 },
	{ id: 123, code: 'ETB', name: 'Ethiopian Birr', symbol: 'Br', decodedSymbol: 'Br', symbolPosition: 0 },
	{ id: 57, code: 'FJD', name: 'Fiji Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 106, code: 'FKP', name: 'Falkland Islands (Malvinas) Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 134, code: 'GEL', name: 'Georgian Lari', symbol: '\u20be', decodedSymbol: '\u20be', symbolPosition: 0 },
	{ id: 108, code: 'GGP', name: 'Guernsey Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 104, code: 'GHC', name: 'Ghana Cedis', symbol: '\u00a2', decodedSymbol: '\u00a2', symbolPosition: 0 },
	{ id: 21, code: 'GHS', name: 'Ghanaian Cedi', symbol: 'GH\u20b5', decodedSymbol: 'GH\u20b5', symbolPosition: 0 },
	{ id: 107, code: 'GIP', name: 'Gibraltar Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 150, code: 'GMD', name: 'Gambian Dalasi', symbol: 'D', decodedSymbol: 'D', symbolPosition: 1 },
	{ id: 127, code: 'GNF', name: 'Guinean Franc', symbol: 'GNF', decodedSymbol: 'GNF', symbolPosition: 0 },
	{ id: 89, code: 'GTQ', name: 'Guatemala Quetzal', symbol: 'Q', decodedSymbol: 'Q', symbolPosition: 0 },
	{ id: 58, code: 'GYD', name: 'Guyana Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 10, code: 'HKD', name: 'Hong Kong Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 83, code: 'HNL', name: 'Honduras Lempira', symbol: 'L', decodedSymbol: 'L', symbolPosition: 0 },
	{ id: 97, code: 'HRK', name: 'Croatia Kuna', symbol: 'kn', decodedSymbol: 'kn', symbolPosition: 0 },
	{ id: 148, code: 'HTG', name: 'Haitian Gourde', symbol: 'G', decodedSymbol: 'G', symbolPosition: 0 },
	{ id: 78, code: 'HUF', name: 'Hungary Forint', symbol: 'Ft', decodedSymbol: 'Ft', symbolPosition: 0 },
	{ id: 29, code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp', decodedSymbol: 'Rp', symbolPosition: 0 },
	{ id: 43, code: 'ILS', name: 'Israel Shekel', symbol: '\u20aa', decodedSymbol: '\u20aa', symbolPosition: 0 },
	{ id: 109, code: 'IMP', name: 'Isle of Man Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 11, code: 'INR', name: 'Indian Rupee', symbol: '\u20b9', decodedSymbol: '\u20b9', symbolPosition: 0 },
	{ id: 135, code: 'IQD', name: 'Iraqui Dinar', symbol: '\u062f.\u0639', decodedSymbol: '\u062f.\u0639', symbolPosition: 1 },
	{ id: 114, code: 'IRR', name: 'Iran Rial', symbol: '\ufdfc', decodedSymbol: '\ufdfc', symbolPosition: 1 },
	{ id: 100, code: 'ISK', name: 'Iceland Krona', symbol: 'kr', decodedSymbol: 'kr', symbolPosition: 0 },
	{ id: 110, code: 'JEP', name: 'Jersey Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 80, code: 'JMD', name: 'Jamaica Dollar', symbol: 'J$', decodedSymbol: 'J$', symbolPosition: 0 },
	{ id: 122, code: 'JOD', name: 'Jordanian Dinar', symbol: 'JD', decodedSymbol: 'JD', symbolPosition: 0 },
	{ id: 4, code: 'JPY', name: 'Yen', symbol: '\u00a5', decodedSymbol: '\u00a5', symbolPosition: 0 },
	{ id: 16, code: 'KES', name: 'Kenyan Shilling', symbol: 'Ksh', decodedSymbol: 'Ksh', symbolPosition: 0 },
	{ id: 74, code: 'KGS', name: 'Kyrgyzstan Som', symbol: '\u043b\u0432', decodedSymbol: '\u043b\u0432', symbolPosition: 0 },
	{ id: 33, code: 'KHR', name: 'Cambodia Riel', symbol: '\u17db', decodedSymbol: '\u17db', symbolPosition: 0 },
	{ id: 42, code: 'KPW', name: 'Korea (North) Won', symbol: '\u20a9', decodedSymbol: '\u20a9', symbolPosition: 0 },
	{ id: 24, code: 'KRW', name: 'South Korean Won', symbol: '\u20a9', decodedSymbol: '\u20a9', symbolPosition: 0 },
	{ id: 118, code: 'KWD', name: 'Kuwaiti Dinar', symbol: 'K.D.', decodedSymbol: 'K.D.', symbolPosition: 0 },
	{ id: 52, code: 'KYD', name: 'Cayman Islands Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 73, code: 'KZT', name: 'Kazakhstan Tenge', symbol: '\u20b8', decodedSymbol: '\u00e2\u0082\u00b8', symbolPosition: 0 },
	{ id: 44, code: 'LAK', name: 'Laos Kip', symbol: '\u20ad', decodedSymbol: '\u20ad', symbolPosition: 0 },
	{ id: 111, code: 'LBP', name: 'Lebanon Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 41, code: 'LKR', name: 'Sri Lanka Rupee', symbol: '\u20a8', decodedSymbol: '\u20a8', symbolPosition: 0 },
	{ id: 59, code: 'LRD', name: 'Liberia Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 152, code: 'LSL', name: 'Lesotho Loti', symbol: 'M', decodedSymbol: 'M', symbolPosition: 0 },
	{ id: 86, code: 'LTL', name: 'Lithuania Litas', symbol: 'Lt', decodedSymbol: 'Lt', symbolPosition: 0 },
	{ id: 85, code: 'LVL', name: 'Latvia Lat', symbol: 'Ls', decodedSymbol: 'Ls', symbolPosition: 0 },
	{ id: 160, code: 'LYD', name: 'Libyan Dinar', symbol: 'LD', decodedSymbol: 'LD', symbolPosition: 0 },
	{ id: 121, code: 'MAD', name: 'Moroccan dirham', symbol: 'Dh', decodedSymbol: 'Dh', symbolPosition: 0 },
	{ id: 153, code: 'MDL', name: 'Moldovan leu', symbol: 'L', decodedSymbol: 'L', symbolPosition: 1 },
	{ id: 155, code: 'MGA', name: 'Malagasy Ariary', symbol: 'Ar', decodedSymbol: 'Ar', symbolPosition: 0 },
	{
		id: 71,
		code: 'MKD',
		name: 'Macedonia Denar',
		symbol: '\u0434\u0435\u043d',
		decodedSymbol: '\u0434\u0435\u043d',
		symbolPosition: 0,
	},
	{ id: 132, code: 'MMK', name: 'Burmese Kyat', symbol: 'K', decodedSymbol: 'K', symbolPosition: 0 },
	{ id: 45, code: 'MNT', name: 'Mongolia Tughrik', symbol: '\u20ae', decodedSymbol: '\u20ae', symbolPosition: 0 },
	{ id: 31, code: 'MOP', name: 'Macau Pataca', symbol: 'MOP$', decodedSymbol: 'MOP$', symbolPosition: 0 },
	{ id: 38, code: 'MUR', name: 'Mauritius Rupee', symbol: '\u20a8', decodedSymbol: '\u20a8', symbolPosition: 0 },
	{ id: 133, code: 'MVR', name: 'Maldivian Rufiyaa', symbol: 'Rf', decodedSymbol: 'Rf', symbolPosition: 0 },
	{ id: 136, code: 'MWK', name: 'Malawian Kwacha', symbol: 'MK', decodedSymbol: 'MK', symbolPosition: 0 },
	{ id: 30, code: 'MXN', name: 'Mexican Peso', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 25, code: 'MYR', name: 'Malaysian ringgit', symbol: 'RM', decodedSymbol: 'RM', symbolPosition: 0 },
	{ id: 87, code: 'MZN', name: 'Mozambique Metical', symbol: 'MT', decodedSymbol: 'MT', symbolPosition: 0 },
	{ id: 60, code: 'NAD', name: 'Namibia Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 20, code: 'NGN', name: 'Nigerian Naira', symbol: '\u20a6', decodedSymbol: '\u20a6', symbolPosition: 0 },
	{ id: 70, code: 'NIO', name: 'Nicaragua Cordoba', symbol: 'C$', decodedSymbol: 'C$', symbolPosition: 0 },
	{ id: 101, code: 'NOK', name: 'Norway Krone', symbol: 'kr', decodedSymbol: 'kr', symbolPosition: 0 },
	{ id: 39, code: 'NPR', name: 'Nepal Rupee', symbol: '\u20a8', decodedSymbol: '\u20a8', symbolPosition: 0 },
	{ id: 12, code: 'NZD', name: 'New Zealand Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 115, code: 'OMR', name: 'Oman Rial', symbol: '\ufdfc', decodedSymbol: '\ufdfc', symbolPosition: 1 },
	{ id: 67, code: 'PAB', name: 'Panama Balboa', symbol: 'B/.', decodedSymbol: 'B/.', symbolPosition: 0 },
	{ id: 92, code: 'PEN', name: 'Peru Nuevo Sol', symbol: 'S/.', decodedSymbol: 'S/.', symbolPosition: 0 },
	{ id: 120, code: 'PGK', name: 'Papua New Guinean Kina', symbol: 'K', decodedSymbol: 'K', symbolPosition: 0 },
	{ id: 13, code: 'PHP', name: 'Philippine Peso', symbol: 'Php', decodedSymbol: 'Php', symbolPosition: 0 },
	{ id: 15, code: 'PKR', name: 'Pakistani Rupee', symbol: 'Rs', decodedSymbol: 'Rs', symbolPosition: 0 },
	{ id: 26, code: 'PLN', name: 'Polish z\u0142oty', symbol: 'z\u0142', decodedSymbol: 'z\u0142', symbolPosition: 0 },
	{ id: 79, code: 'PYG', name: 'Paraguay Guarani', symbol: 'Gs', decodedSymbol: 'Gs', symbolPosition: 0 },
	{ id: 28, code: 'QAR', name: 'Qatari Riyal', symbol: 'QR', decodedSymbol: 'QR', symbolPosition: 0 },
	{ id: 102, code: 'RON', name: 'Romania New Leu', symbol: 'lei', decodedSymbol: 'lei', symbolPosition: 0 },
	{
		id: 66,
		code: 'RSD',
		name: 'Serbia Dinar',
		symbol: '\u0414\u0438\u043d.',
		decodedSymbol: '\u0414\u0438\u043d.',
		symbolPosition: 0,
	},
	{ id: 77, code: 'RUB', name: 'Russia Ruble', symbol: '\u20bd', decodedSymbol: '\u20bd', symbolPosition: 0 },
	{ id: 129, code: 'RWF', name: 'Rwandan Franc', symbol: 'FRw', decodedSymbol: 'FRw', symbolPosition: 0 },
	{ id: 116, code: 'SAR', name: 'Saudi Arabia Riyal', symbol: '\ufdfc', decodedSymbol: '\ufdfc', symbolPosition: 1 },
	{ id: 61, code: 'SBD', name: 'Solomon Islands Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 40, code: 'SCR', name: 'Seychelles Rupee', symbol: '\u20a8', decodedSymbol: '\u20a8', symbolPosition: 0 },
	{ id: 145, code: 'SDG', name: 'Sudanese Pound', symbol: '\u00a3Sd', decodedSymbol: '\u00a3sd', symbolPosition: 0 },
	{ id: 32, code: 'SEK', name: 'Swedish Krona', symbol: 'kr', decodedSymbol: 'kr', symbolPosition: 0 },
	{ id: 17, code: 'SGD', name: 'Singapore Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 112, code: 'SHP', name: 'Saint Helena Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 128, code: 'SLL', name: 'Sierra Leonean Leone', symbol: 'Le', decodedSymbol: 'Le', symbolPosition: 0 },
	{ id: 91, code: 'SOS', name: 'Somalia Shilling', symbol: 'S', decodedSymbol: 'S', symbolPosition: 0 },
	{ id: 62, code: 'SRD', name: 'Suriname Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 56, code: 'SVC', name: 'El Salvador Colon', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 113, code: 'SYP', name: 'Syria Pound', symbol: '\u00a3', decodedSymbol: '\u00a3', symbolPosition: 0 },
	{ id: 151, code: 'SZL', name: 'Swazi Lilangeni', symbol: 'E', decodedSymbol: 'E', symbolPosition: 0 },
	{ id: 23, code: 'THB', name: 'Thai Baht', symbol: '\u0e3f', decodedSymbol: '\u0e3f', symbolPosition: 0 },
	{ id: 156, code: 'TJS', name: 'Tajikistani Somoni', symbol: 'SM', decodedSymbol: 'SM', symbolPosition: 1 },
	{ id: 144, code: 'TND', name: 'Tunisian dinar', symbol: '\u062f.\u062a', decodedSymbol: '\u062f.\u062a', symbolPosition: 1 },
	{ id: 149, code: 'TOP', name: "Tongan pa'anga", symbol: 'T$', decodedSymbol: 'T$', symbolPosition: 0 },
	{ id: 37, code: 'TRL', name: 'Turkey Lira', symbol: '\u20a4', decodedSymbol: '\u20a4', symbolPosition: 0 },
	{ id: 93, code: 'TRY', name: 'Turkey Lira', symbol: '\u20ba', decodedSymbol: '\u20ba', symbolPosition: 0 },
	{ id: 94, code: 'TTD', name: 'Trinidad and Tobago Dollar', symbol: 'TT$', decodedSymbol: 'TT$', symbolPosition: 0 },
	{ id: 63, code: 'TVD', name: 'Tuvalu Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 14, code: 'TWD', name: 'Taiwan New Dollar', symbol: 'NT$', decodedSymbol: 'NT$', symbolPosition: 0 },
	{ id: 131, code: 'TZS', name: 'Tanzanian Shilling', symbol: 'TZS', decodedSymbol: 'TZS', symbolPosition: 0 },
	{ id: 47, code: 'UAH', name: 'Ukraine Hryvna', symbol: '\u20b4', decodedSymbol: '\u20b4', symbolPosition: 0 },
	{ id: 125, code: 'UGX', name: 'Uganda Shilling', symbol: 'USh', decodedSymbol: 'USh', symbolPosition: 0 },
	{ id: 64, code: 'UYU', name: 'Uruguay Peso', symbol: '$U', decodedSymbol: '$U', symbolPosition: 0 },
	{ id: 75, code: 'UZS', name: 'Uzbekistan Som', symbol: '\u043b\u0432', decodedSymbol: '\u043b\u0432', symbolPosition: 0 },
	{ id: 69, code: 'VEF', name: 'Venezuela Bolivar Fuerte', symbol: 'Bs', decodedSymbol: 'Bs', symbolPosition: 0 },
	{ id: 146, code: 'VES', name: 'Venezuelan Bol\u00edvar Soberano', symbol: 'Bs.S.', decodedSymbol: 'Bs.S.', symbolPosition: 0 },
	{ id: 5, code: 'VND', name: 'Vietnamese \u0111\u1ed3ng', symbol: '\u20ab', decodedSymbol: '\u20ab', symbolPosition: 0 },
	{ id: 147, code: 'VUV', name: 'Vanuatu Vatu', symbol: 'VT', decodedSymbol: 'VT', symbolPosition: 1 },
	{ id: 154, code: 'WST', name: 'Samoan t\u0101l\u0101', symbol: 'WS$', decodedSymbol: 'WS$', symbolPosition: 1 },
	{ id: 138, code: 'XAF', name: 'Central African Franc', symbol: 'CFA', decodedSymbol: 'CFA', symbolPosition: 0 },
	{ id: 55, code: 'XCD', name: 'East Caribbean Dollar', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 124, code: 'XOF', name: 'West African Franc', symbol: 'CFA', decodedSymbol: 'CFA', symbolPosition: 0 },
	{ id: 999, code: 'XXX', name: 'USD', symbol: '$', decodedSymbol: '$', symbolPosition: 0 },
	{ id: 117, code: 'YER', name: 'Yemen Rial', symbol: '\ufdfc', decodedSymbol: '\ufdfc', symbolPosition: 1 },
	{ id: 27, code: 'ZAR', name: 'South African Rand', symbol: 'R', decodedSymbol: 'R', symbolPosition: 0 },
	{ id: 137, code: 'ZMW', name: 'Zambian Kwacha', symbol: 'ZK', decodedSymbol: 'ZK', symbolPosition: 0 },
	{ id: 95, code: 'ZWD', name: 'Zimbabwe Dollar', symbol: 'Z$', decodedSymbol: 'Z$', symbolPosition: 0 },
];
