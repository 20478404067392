import { makeStyles } from '~styles';
import { IconTileProps, IconTileVariants } from './icon-tile.types';

export const useIconTileStyles = makeStyles<IconTileProps>()((theme, params) => {
	const { borderRadiuses, constructs } = theme;
	const { height, size, variant } = params;

	const getStyleBackground = (variantColor?: IconTileVariants) => {
		const backgroundMap = {
			light: constructs.surface.neutral.forcedWhite,
			muted: constructs.surface.neutral.xWeak,
			primary: constructs.surface.primary.strong,
			secondary: constructs.surface.neutral.xWeak,
		};

		return variantColor ? backgroundMap[variantColor] : constructs.surface.neutral.xWeak;
	};

	return {
		root: {
			alignItems: 'center',
			backgroundColor: getStyleBackground(variant),
			borderRadius: borderRadiuses[300],
			display: 'flex',
			flexShrink: '0',
			height: height ? height : size,
			minHeight: height ? size : undefined,
			justifyContent: 'center',
			width: size,
		},
	};
});
