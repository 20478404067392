import { makeStyles } from '~styles';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore
import { mediumScreenWidth } from '~definitions/json/mobile.json';

export default makeStyles()(({ breakpoints, typography }) => {
	return {
		header: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
			lineHeight: typography.h3.lineHeight,
			margin: '10px 0',
			overflowWrap: ifFeature('encore', 'anywhere', undefined),
			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				margin: 0,
			}),
		},
		title: {
			margin: '0',
		},
	};
});
