import { FormFactoryElement } from '../types/element.types';

export function contextMiddleware(context: Record<string, unknown>) {
	return (props: FormFactoryElement) => {
		return {
			context,
			...props,
		};
	};
}
