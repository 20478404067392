import React, { FC, isValidElement, memo, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { X14x14 } from '@bamboohr/grim';
import { FabricIcon } from '~components/icon';
import { IconButton } from '~components/button';
import { IconV2, IconV2Name } from '~components/icon-v2';
import { InPageMessagingContainerProps } from './types';
import { InPageMessagingType } from './enums';
import { encoreStatusIcon } from './const';
import { useStyles } from './in-page-messaging-container.styles';
import { getFabricIcon, getIconBrand, getIconName, shouldRenderIcon } from './utils';

export const InPageMessagingContainer: FC<PropsWithChildren<InPageMessagingContainerProps>> = memo(props => {
	const { header, body, icon, customAction, onClose, type = InPageMessagingType.neutral, isStacked, biId } = props;

	const styles = useStyles({ body, hasAction: !!customAction, hasClose: !!onClose, header, isStacked, type });

	const renderIcon = () => {
		const fabricIcon = getFabricIcon(icon, type);
		const iconName = getIconName(icon, type);
		const iconBrand = getIconBrand(icon, type);

		if (!shouldRenderIcon(icon, type)) {
			return undefined;
		}

		return isValidElement(fabricIcon) ? fabricIcon : <FabricIcon brand={iconBrand} name={iconName} />;
	};

	const renderEncoreIcon = () => {
		// Use a default icon if Encore is enabled
		let iconElement: typeof icon | null = <IconV2 name={encoreStatusIcon[type]} size={16} />;

		// Override the default icon if a custom icon is provided
		if (icon) {
			iconElement = typeof icon === 'string' ? <IconV2 name={icon as IconV2Name} size={16} /> : null;
		}

		return iconElement;
	};

	const renderHeader = () => {
		if (!header) {
			return null;
		}
		return <div className={styles.classes.header}>{header}</div>;
	};

	const renderBody = () => {
		if (!body) {
			return null;
		}
		return <div className={styles.classes.body}>{body}</div>;
	};

	const renderAction = () => {
		if (!customAction) {
			return null;
		}
		return <div className={styles.classes.action}>{customAction}</div>;
	};

	const renderDismissButton = () => {
		if (!onClose) {
			return null;
		}

		return (
			<div className={styles.classes.close}>
				<IconButton
					aria-label={window.jQuery ? $.__('Dismiss message') : 'Dismiss message'}
					biId={biId}
					floatingIcon={true}
					icon={ifFeature('encore', 'xmark-solid', <X14x14 aria-hidden={true} />)}
					noBoundingBox={true}
					onClick={onClose}
					size="large"
				/>
			</div>
		);
	};

	const iconElement = ifFeature('encore', renderEncoreIcon(), renderIcon());

	return (
		<div className={styles.classes.container}>
			{iconElement && (
				<div className={styles.classes.iconWrapper}>
					<div className={styles.classes.icon}>{iconElement}</div>
				</div>
			)}
			<div className={styles.classes.content}>
				{renderHeader()}
				{renderBody()}
			</div>
			{renderAction()}
			{renderDismissButton()}
		</div>
	);
});
