import { clsx, generateFabricClasses } from '~styles';
import { noop } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDatePickerUtils } from '../../hooks';
import { CalendarDayProps, DatePickerDate } from '../../types';
import { getIsDateValid } from '../../utils';
import { PickersDay } from '@mui/x-date-pickers';
import { usePickersDayStyles } from './styled-pickers-day';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText } from '~components/body-text';

interface CalendarDayClasses {
	active: string;
	error: string;
	inRange: string;
	marked: string;
	selected: string;
}

const calendarDayClasses = generateFabricClasses<CalendarDayClasses>('CalendarDay', [
	'active',
	'error',
	'inRange',
	'marked',
	'selected',
]);

export const CalendarDay = <TDate extends DatePickerDate>({
	active,
	day,
	errored,
	marked,
	focused,
	selected,
	inRange,
	className,
	onKeyDown,
	onMouseEnter,
	...rest
}: CalendarDayProps<TDate>) => {
	const utils = useDatePickerUtils();

	const ref = useRef<HTMLButtonElement>(null);

	const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		if (onMouseEnter) {
			onMouseEnter(e, {
				date: day,
			});
		}
	};

	// When the focused state of the day changes, focus the day
	// Used for when the arrow keys change the day, allows the day to be refocused later
	useEffect(() => {
		if (focused) {
			ref.current?.focus({ preventScroll: true });
		}
	}, [focused]);

	const { classes } = usePickersDayStyles();

	return (
		<div role="gridcell">
			<PickersDay
				{...rest}
				// eslint-disable-next-line @typescript-eslint/no-base-to-string
				aria-label={day ? day.toLocaleString({ dateStyle: 'long' }) : undefined}
				className={clsx(classes.root, className, {
					[calendarDayClasses.error]: errored, // Errored styling
					[calendarDayClasses.active]: active, // Focused styling
					[calendarDayClasses.inRange]: inRange, // Styling for items between ranges
					[calendarDayClasses.marked]: marked, // Styling for original dates, start and end
					[calendarDayClasses.selected]: selected, // Styling for currently selected items; Either start or end
				})}
				data-calendar-day
				day={day}
				onDayFocus={
					noop /* This must be set to a noop or CalendarPicker from MUI will still try to navigate (we're controlling navigation ourselves). */
				}
				onMouseEnter={handleMouseEnter}
				ref={ref}
				tabIndex={active ? 0 : -1}
			>
				{ifFeature(
					'encore',
					<BodyText size="small">
						{day && getIsDateValid(utils, day) ? utils.format(day, 'dayOfMonth') : undefined}
					</BodyText>,
					day && getIsDateValid(utils, day) ? utils.format(day, 'dayOfMonth') : undefined
				)}
			</PickersDay>
		</div>
	);
};
