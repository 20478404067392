import Ajax from '@bamboohr/utils/lib/ajax';
import { handleError } from '@bamboohr/utils/lib/handle-error';
import { useEffect, useState, type SVGProps } from 'react';

const imageCache: { [key: string]: Promise<void | HTMLElement> } = {};

export function useSvg(url: string): SVGProps<SVGSVGElement> | undefined {
	const [svgProps, setSvgProps] = useState<SVGProps<SVGSVGElement> | undefined>();

	useEffect(() => {
		const setSvg = (svgElement: HTMLElement) => {
			setSvgProps({
				dangerouslySetInnerHTML: { __html: svgElement.innerHTML },
				viewBox: svgElement.getAttribute('viewBox') ?? undefined,
				xmlns: svgElement.getAttribute('xmlns') ?? undefined,
			});
		};
		const onError = error => {
			const message = typeof error === 'string' ? error : (error as Error).message;
			handleError(typeof message === 'string' ? message : `Unknown error getting SVG at "${url}"`);
			setSvgProps(undefined);
		};

		if (url in imageCache) {
			imageCache[url].then(setSvg).catch(onError);
			return;
		}

		const cancelToken = Ajax.createCancelSource();
		imageCache[url] = Ajax.get<string>(url, { cancelToken })
			.then(({ data }) => {
				const { documentElement: svgElement } = new DOMParser().parseFromString(data, 'image/svg+xml');

				// If there's a parsing error, a `<parsererror>` element is added instead of throwing an error
				// https://developer.mozilla.org/en-US/docs/Web/API/DOMParser/parseFromString#error_handling
				if (svgElement.querySelector('parsererror')) {
					throw Error(`Failed to parse SVG at "${url}"`);
				}

				setSvg(svgElement);
				return svgElement;
			})
			.catch(onError);

		return () => {
			cancelToken.cancel();
		};
	}, [url]);

	return svgProps;
}
