import { format, parseISO } from '@bamboohr/utils/lib/datetime';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { DatePickerContext, DatePickerContextValue } from '~components/date-picker';
import { useCalendarBaseStyles } from './calendar-base.styles';
import { CalendarBaseProps } from './calendar-base.types';
import { Day, DayContext } from './day';
import { CalendarBaseLabel } from './label';
import { locales } from './locales';
import { transformDisabled } from './utils';

export function CalendarBase({
	biId,
	disabled,
	onChange,
	month,
	monthsToShow = 1,
	navigation,
	renderDay,
	selectionMode = 'read-only',
	startMonth,
	endMonth,
	value,
}: CalendarBaseProps): ReactElement {
	const { classes } = useCalendarBaseStyles();
	const { locale } = useContext<DatePickerContextValue>(DatePickerContext);
	const [monthDate, setMonthDate] = useState<Date>(parseISO(month));

	useEffect(() => {
		setMonthDate(parseISO(month));
	}, [month]);

	const handleChange = (newDate: Date) => {
		if (selectionMode !== 'read-only' && typeof onChange === 'function') {
			onChange(format(newDate, 'yyyy-MM-dd'));
		}
	};

	return (
		<DayPicker
			classNames={{
				day: classes.day,
				hidden: classes.hidden,
				month: classes.month,
				month_caption: classes.monthCaption,
				month_grid: classes.monthGrid,
				months: classes.months,
				root: classes.root,
				weekday: classes.weekday,
				weekdays: classes.weekdays,
				weeks: classes.weeks,
			}}
			components={{
				CaptionLabel: props => {
					return <CalendarBaseLabel {...props} endMonth={endMonth} navigation={navigation} startMonth={startMonth} />;
				},
				DayButton: props => {
					const {
						day: { date },
						modifiers,
					} = props;
					return (
						<DayContext.Provider value={props}>
							{typeof renderDay === 'function' ? renderDay(date.toISOString(), modifiers) : <CalendarBase.Day />}
						</DayContext.Provider>
					);
				},
			}}
			data-bi-id={biId}
			disabled={transformDisabled(disabled)}
			disableNavigation={navigation === 'none'}
			formatters={{
				// Display the 3-letter abbreviation of the month
				formatCaption: date => format(date, 'LLL y', { locale: locales[locale] }),
				// Only display the weekday's first letter
				formatWeekdayName: date => format(date, 'ccccc', { locale: locales[locale] }),
			}}
			hideNavigation={true}
			locale={locales[locale]}
			mode={selectionMode === 'read-only' ? undefined : selectionMode}
			month={monthDate}
			numberOfMonths={monthsToShow}
			onDayClick={selectionMode === 'read-only' ? () => {} : handleChange}
			onMonthChange={setMonthDate}
			required={undefined}
			selected={value ? parseISO(value) : undefined}
		/>
	);
}

CalendarBase.Day = Day;
