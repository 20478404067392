import { useTheme } from '@mui/styles';

import { GradientLegendColors } from '../types';

export const useGradientLegendColors = (customColors?: GradientLegendColors): GradientLegendColors => {
	const {
		palette: {
			primary: { lightest, main, light },
		},
	} = useTheme();
	if (customColors) {
		return customColors;
	}
	return {
		start: '#FDFBE0',
		mid: lightest,
		end: main,
		border: light,
	};
};
