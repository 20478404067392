import { ArrowLeft8x7 } from '@bamboohr/grim';
import { makeStyles } from '~styles';
import React from 'react';
import { BackButtonProps } from '../types';
import { TextButton } from '~components/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ spacing }) => ({
	root: {
		gridColumn: '1 / 4',
		gridRow: '1 / 2',
		justifySelf: 'start',
		marginBottom: ifFeature('encore', spacing(2), '10px'),
	},
}));

export const BackButton = ({ biId, ...rest }: BackButtonProps) => {
	const { classes } = useStyles();

	return (
		<TextButton
			className={classes.root}
			color="secondary"
			data-bi-id={biId && `${biId}-back-button`}
			size="small"
			startIcon={ifFeature('encore', 'angle-left-regular', <ArrowLeft8x7 />)}
			type="button"
			{...rest}
		/>
	);
};
