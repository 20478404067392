import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(() => {
	return {
		months: {},
		root: {
			alignItems: 'center',
			display: 'flex',
			gap: ifFeature('encore', 5.33),
			margin: ifFeature('encore', '0 5.33px'),
		},
		years: {
			// @startCleanup encore
			marginLeft: ifFeature('encore', undefined, 12),
			// @endCleanup encore
		},
	};
});
