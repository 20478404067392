// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { line } from '@visx/shape';
import { interpolatePath } from 'd3-interpolate-path';

import { AnimatedLineProps } from '../types/animated-line-props';
import { useAnimatedLinePath } from './use-animated-line-path';

const variants = {
	initial: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};

export const AnimatedLine = <Datum = unknown,>({
	animate,
	curve,
	data,
	defined,
	x,
	xMax = 0,
	y,
	yMax = 0,
	transition,
	...restProps
}: AnimatedLineProps<Datum>): ReactElement => {
	const fill = 'transparent';
	const path = line<Datum>({ x, y, curve, defined });
	const pathCommands = path(data) ?? '';
	const animatedPath = useAnimatedLinePath(pathCommands, yMax, interpolatePath, transition);
	return animate ? (
		<AnimatePresence>
			{pathCommands && (
				<motion.path
					animate="initial"
					aria-hidden={true}
					d={animatedPath}
					exit="exit"
					fill={fill}
					initial="initial"
					strokeLinecap="round"
					variants={variants}
					{...restProps}
				/>
			)}
		</AnimatePresence>
	) : (
		<path d={pathCommands} fill={fill} strokeLinecap="round" {...restProps} />
	);
};
