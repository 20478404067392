import { makeStyles } from '~styles';
import { GridLayoutProps } from './grid-layout.types';

export const useGridLayoutStyles = makeStyles<GridLayoutProps>()(({ spacing }, props) => {
	const spacingProps = [
		'gap',
		'rowGap',
		'columnGap',
		'padding',
		'paddingTop',
		'paddingRight',
		'paddingBottom',
		'paddingLeft',
		'paddingX',
		'paddingY',
		'margin',
		'marginTop',
		'marginRight',
		'marginBottom',
		'marginLeft',
		'marginX',
		'marginY',
	];

	const processProps = (inputProps: Record<string, any>) => {
		return Object.keys(inputProps).reduce(
			(acc, key) => {
				const value = inputProps[key];
				if (spacingProps.includes(key) && typeof value === 'number') {
					acc[key] = spacing(value);
				} else if (typeof value === 'object' && value !== null) {
					acc[key] = processProps(value);
				} else {
					acc[key] = value;
				}
				return acc;
			},
			{} as Record<string, any>
		);
	};

	return {
		root: typeof props === 'object' && props !== null ? processProps(props) : {},
	};
});
