export function guardNavigation<T>({
	isAtLimit,
	isEnabled,
	navigator,
	value,
}: {
	isAtLimit: (value: T) => boolean;
	isEnabled: (value: T) => boolean;
	navigator: (value: T) => T;
	value: T;
}): T {
	let currentValue: T = value;
	while (!isAtLimit(currentValue)) {
		currentValue = navigator(currentValue);

		if (isEnabled(currentValue)) {
			return currentValue;
		}
	}
	return value;
}
