import React, { ReactElement, ReactNode, useContext, useRef } from 'react';
import { Helium } from '~components/helium';
import { ItemType } from '../../../../../types';
import { ScrollContainer, ScrollContainerContext } from '../scroll-container';
import { getNestedVesselCSSClasses } from '../util';
import { useScrollCollapse } from './use-scroll-collapse';

interface ParentOptionProps {
	active?: boolean;

	children: ReactNode;

	condition?: string;

	expanded?: boolean;

	item: ItemType;

	menuId: string;

	placement: {
		align: 'end' | 'center' | 'start';
		side: 'bottom' | 'left' | 'right' | 'top';
	};

	onCollapse: () => void;

	onMouseEnter?: () => void;

	renderOption: (item: ItemType, inheritedSettings: Record<string, unknown>) => ReactElement;
}

const DEFAULT_PLACEMENT = {
	align: 'start',
	side: 'right',
} as const;

export function ParentOption({
	active = false,
	children,
	condition,
	expanded = false,
	item,
	menuId,
	placement = DEFAULT_PLACEMENT,
	onMouseEnter,
	onCollapse,
	renderOption,
}: ParentOptionProps) {
	const { hasScrollbar, ref: scrollContainerRef } = useContext(ScrollContainerContext);

	const anchorRef = useRef<HTMLDivElement>(null);

	useScrollCollapse(
		{
			active,
			anchorRef,
			scrollContainerRef,
		},
		onCollapse
	);

	return (
		<Helium
			isInline={false}
			isVisible={active && expanded}
			offset={{ left: hasScrollbar ? 20 : 4 }}
			parentId={menuId}
			placement={placement}
			renderAnchor={() => (
				<div key="parentAnchor" onMouseEnter={onMouseEnter} ref={anchorRef}>
					{renderOption(item, { isExpanded: active })}
				</div>
			)}
			renderVessel={() => {
				return (
					<div className={getNestedVesselCSSClasses({ condition })} data-menu-id={menuId} key="parentVessel">
						<ScrollContainer>{children}</ScrollContainer>
					</div>
				);
			}}
		/>
	);
}
