import React from 'react';
import { default as ReactTruncate } from 'react-truncate';
import { useStyles } from './truncate.styles';
import { TruncateProps } from './types';

export const Truncate = (props: TruncateProps) => {
	const { classes } = useStyles();

	return <ReactTruncate {...props} className={classes.root} />;
};
