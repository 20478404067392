import { makeStyles } from '~styles';

export const useStyles = makeStyles()(() => {
	return {
		root: {
			alignItems: 'center',
			backgroundColor: 'transparent',
			border: 'none',
			cursor: 'pointer',
			display: 'flex',
			height: '100%',
			justifyContent: 'center',
			width: '100%',
		},
	};
});
