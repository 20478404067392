import { ValidationField, ValidationTrigger } from './validation.types';

export function shouldValidate(
	field: ValidationField,
	triggerType: ValidationTrigger,
	{ hasAlreadyValidated, isAlreadyDirty }: { hasAlreadyValidated: boolean; isAlreadyDirty: boolean }
) {
	const { validateOn = 'submit', revalidateOn = 'blur' } = field;

	if (!hasAlreadyValidated && !isAlreadyDirty) {
		return false;
	}

	return validateOn === triggerType || (hasAlreadyValidated && revalidateOn === triggerType);
}
