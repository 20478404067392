import React, { FC, createContext, useState, useEffect, PropsWithChildren } from 'react';
import { CurrencyItem } from '~components/currency-field';
import { CurrencyPickerProviderProps, CurrencyProviderValue } from './types';

export const CurrencyContext = createContext<Partial<CurrencyProviderValue>>({});

export const CurrencyPickerProvider: FC<PropsWithChildren<CurrencyPickerProviderProps>> = ({
	children,
	currencyCode,
	decimalCharacter,
	defaultCurrency,
	defaultDecimalChar,
	defaultThousandsSeparator,
	getCurrencyCollection,
	getCurrencyItems,
	normalizeZeros,
	padFractionalZeros,
	scale,
	thousandsSeparator,
}) => {
	const [currencyItems, setCurrencyItems] = useState<Array<CurrencyItem>>();

	useEffect(() => {
		(getCurrencyItems || getCurrencyCollection)?.()
			.then(value => setCurrencyItems(value))
			.catch(error => console.error('Unable to get currency collection.', error));
	}, [defaultCurrency, getCurrencyCollection, getCurrencyItems]);

	return (
		<CurrencyContext.Provider
			value={{
				currencyCode: currencyCode ?? defaultCurrency,
				currencyItems,
				decimalCharacter: decimalCharacter ?? defaultDecimalChar,
				defaultCurrency: currencyCode ?? defaultCurrency,
				defaultCurrencyCollection: currencyItems,
				defaultDecimalChar: decimalCharacter ?? defaultDecimalChar,
				defaultThousandsSeparator: thousandsSeparator ?? defaultThousandsSeparator,
				normalizeZeros,
				padFractionalZeros,
				scale,
				thousandsSeparator: thousandsSeparator ?? defaultThousandsSeparator,
			}}
		>
			{children}
		</CurrencyContext.Provider>
	);
};
