import { ListItem } from '../types';
import { hasWordStartingWith } from '@bamboohr/utils/lib/string';

export function searchByTitle(term: string, list: Array<ListItem>): Array<ListItem> {
	const value = term.toString().toLowerCase().trim();
	if (value === '') {
		return list;
	}

	if (Array.isArray(list)) {
		return [...list].filter(listItem => hasWordStartingWith(listItem.title.toString(), value));
	}

	return [];
}
