import { AxisScale, SharedAxisProps } from '@visx/axis';
import { AxisCustomLabels } from '@fabric/charts';
import { makeStyles } from '~styles';

export const useAxisStyles = makeStyles<Omit<SharedAxisProps<AxisScale> & AxisCustomLabels, 'scale'> | null | undefined>()((
	{ palette, typography },
	axisProps
) => {
	const axisColor = palette.gray[400];
	return {
		root: {
			stroke: axisProps?.stroke ?? axisColor,
		},
		tickLabel: {
			fill: palette.gray[1000],
			fontFamily: typography.fontFamily,
			fontSize: typography.small.fontSize,
		},
		tick: {
			stroke: axisProps?.tickStroke ?? axisColor,
		},
	};
});
