import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import { TimelineItem, TimelineProps, PositionInTimeline } from '../types';
import { capitalize } from 'lodash';
import { getIcon, getText } from '../utils';
import { OverrideProps } from '~utils';
import { useTimelineEventStyles } from './event.styles';

export const Event = <C extends React.ElementType = 'li'>(
	props: OverrideProps<
		C,
		TimelineItem & {
			color: TimelineProps['color'];
			eventGap: TimelineProps['eventGap'];
			timelineSize: TimelineProps['size'];
		}
	>
) => {
	const {
		classes,
		color = 'primary',
		component,
		isActive,
		icon,
		index,
		isInverted = false,
		positionInTimeline,
		primaryText,
		secondaryText,
		size: sizeProp,
		timelineSize = 'large',
		eventGap = 32,
	} = props;

	const Component = component || 'li';
	const { classes: eventClasses, cx } = useTimelineEventStyles({ eventGap }, { props: { classes } });
	const size = sizeProp || timelineSize;
	const stepIcon = getIcon(icon, size, isInverted, isActive);

	return (
		<Component
			className={cx(
				eventClasses.itemRoot,
				eventClasses[`size${capitalize(size)}`],
				eventClasses[`timelineSize${capitalize(timelineSize)}`],
				eventClasses[`color${capitalize(color)}`],
				{
					[eventClasses.numberTimeline]: !stepIcon,
					[eventClasses.invert]: isInverted,
				}
			)}
		>
			<div
				className={cx(eventClasses.iconWrapper, {
					[eventClasses.iconWrapperLast]: positionInTimeline === PositionInTimeline.END,
				})}
			>
				{stepIcon ? (
					ifFeature('encore', stepIcon, <div className={eventClasses.icon}>{stepIcon}</div>)
				) : (
					<div className={ifFeature('encore', eventClasses.number, cx(eventClasses.number, eventClasses.icon))}>
						{Number(index) + 1}
					</div>
				)}
			</div>
			<div className={cx(eventClasses.content, { [eventClasses.contentLast]: positionInTimeline === PositionInTimeline.END })}>
				<div className={eventClasses.contentPrimary}>{primaryText && getText(primaryText)}</div>
				<div className={eventClasses.contentSecondary}>{secondaryText && getText(secondaryText)}</div>
			</div>
		</Component>
	);
};
