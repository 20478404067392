import { TabPanelUnstyled } from '@mui/base';
import TabPanelUnstyledProps, { TabPanelUnstyledTypeMap } from '@mui/base/TabPanelUnstyled/TabPanelUnstyledProps';
import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MUIStyledCommonProps } from '@mui/system';
import React, { ElementType, forwardRef, ReactNode, Ref, type ReactElement } from 'react';
import { sideTabsLayoutPaddingY } from '../constants';
import { SideTabPanelHeader } from './side-tab-panel-header';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabPanelOwnProps extends MUIStyledCommonProps<Theme> {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	className?: string;
	/** A string for a simple header, or any other valid `ReactNode`. */
	header?: ReactNode;

	/** If `true`, no padding will be used. Defaults to `false`. */
	noPadding?: boolean;
	/**
	 * @deprecated sx should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	sx?: MUIStyledCommonProps['sx'];
}

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export type SideTabPanelProps<Component extends ElementType = TabPanelUnstyledTypeMap['defaultComponent']> = TabPanelUnstyledProps<
	Component,
	SideTabPanelOwnProps
>;

const TabPanel = styled(TabPanelUnstyled)<SideTabPanelProps>(({ theme, noPadding }) => ({
	flex: 1,
	...(noPadding
		? {}
		: {
				color: theme.mode === ModeOptions.Dye ? dyeColors.main : undefined,
				outline: theme.mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				paddingTop: `var(${sideTabsLayoutPaddingY})`,
				paddingRight: theme.spacing(4),
				paddingBottom: `var(${sideTabsLayoutPaddingY})`,
				paddingLeft: theme.spacing(4),
			}),
}));

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The actual content that corresponds with a tab in `SideTabList`.
 */
export const SideTabPanel = forwardRef(function SideTabPanel(
	{ children, header, noPadding, ...props }: SideTabPanelProps,
	ref: Ref<HTMLDivElement>
) {
	return (
		// casting for performance
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		<TabPanel ref={ref} {...(props as any)}>
			{typeof header === 'string' ? <SideTabPanelHeader>{header}</SideTabPanelHeader> : header}
			{children}
		</TabPanel>
	);
	// Generics are lost with `forwardRef`
	// https://github.com/mui/material-ui/issues/32420#issuecomment-1146757121
}) as OverridableComponent<TabPanelUnstyledTypeMap<SideTabPanelOwnProps>>;
