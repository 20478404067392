import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useJadeStyles = makeStyles<{ hasHover?: boolean }, 'header' | 'children'>()((theme, params, classes) => {
	const { palette, mixins, transitions, mode } = theme;
	const { hasHover } = params;

	const animation = {
		easing: transitions.easing.easeInOut,
		duration: transitions.duration.short,
	};

	return {
		root: {
			border: hasHover ? undefined : mixins.border(1, palette.gray[300]),
			borderRadius: hasHover ? undefined : '8px',
			boxShadow: hasHover ? undefined : '0px 2px 16px rgba(0, 0, 0, 0.15)',
			boxSizing: 'border-box',
			display: 'flex',
			flexFlow: 'column nowrap',
			height: '100%',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			transition: transitions.create('box-shadow', animation),

			'&:hover': hasHover
				? {
						border: mixins.border(1, palette.gray[300]),
						borderRadius: '8px',
						boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.15)',

						[`& .${classes.header}`]: {
							backgroundColor: palette.gray[100],
							border: 'none',
							borderBottom: mixins.border(1, palette.gray[300]),
							borderTopLeftRadius: '8px',
							borderTopRightRadius: '8px',
						},

						[`& .${classes.children}`]: {
							border: 'none',
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							boxShadow: 'none',
						},

						'& .showOnHover': {
							display: 'block',
						},
					}
				: {},

			'& .showOnHover': {
				display: hasHover ? 'none' : 'block',
			},
		},

		header: {
			backgroundColor: hasHover ? 'transparent' : palette.gray[100],
			border: hasHover ? mixins.border(1, 'transparent') : 'none',
			borderBottom: hasHover ? 'none' : mixins.border(1, palette.gray[300]),
			borderTopLeftRadius: hasHover ? undefined : '8px',
			borderTopRightRadius: hasHover ? undefined : '8px',
			boxSizing: 'border-box',
			transition: transitions.create('background-color', animation),
		},

		children: {
			background: palette.common.white,
			border: hasHover ? mixins.border(1, palette.gray[300]) : 'none',
			borderRadius: '8px',
			borderTopLeftRadius: hasHover ? '8px' : 0,
			borderTopRightRadius: hasHover ? '8px' : 0,
			boxShadow: hasHover ? '0px 2px 6px rgba(0, 0, 0, 0.05)' : 'none',
			boxSizing: 'border-box',
			display: 'flex',
			flexFlow: 'column nowrap',
			flexGrow: 1,
			transition: transitions.create('box-shadow', animation),
		},
	};
});

export const useEncoreStyles = makeStyles<{ hasHover?: boolean }, 'header'>()((theme, params, classes) => {
	const { palette, mixins } = theme;
	const { hasHover } = params;

	return {
		root: {
			boxSizing: 'border-box',
			display: 'flex',
			flexFlow: 'column nowrap',
			height: '100%',
			'& :first-child': {
				transition: ifFeature('encore', 'background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out'),
				[`&:hover .${classes.header}`]: {
					backgroundColor: palette.gray[50],
				},
			},
			'&:hover': {
				'& .showOnHover': {
					display: 'block',
				},
			},
			'& .showOnHover': {
				display: hasHover ? 'none' : 'block',
			},
		},
		card: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		header: {
			backgroundColor: palette.common.white,
			borderBottom: mixins.border(1, palette.gray[200]),
		},
		children: {
			boxSizing: 'border-box',
			display: 'flex',
			flexFlow: 'column nowrap',
			flexGrow: 1,
			overflow: 'auto',
		},
	};
});
