import React, { forwardRef, cloneElement, FC, PropsWithChildren } from 'react';
import { Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { PencilCheck18x16 } from '@bamboohr/grim';
import { IconButton } from '~components/button';
import { dyeColors, ModeOptions } from '~styles';
import { HoverWrapperProps } from './types';
import clsx from 'classnames';
import { Tooltip } from '~components/tooltip';
import { capitalize } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const styles = ({ constructs, mode, spacing }: Theme) =>
	createStyles({
		root: {
			display: 'inline-grid',
			gridTemplateColumns: '1fr min-content',
			gridTemplateRows: '1fr',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'&:hover $iconRightInside, &:hover $iconRightOutside, &:hover $iconLeftInside, &:hover $iconLeftOutside': {
				opacity: 1,
				fill: constructs.text.neutral.weak,
			},
			'&:hover $children': {
				opacity: 1,
			},
		},
		component: {
			gridRow: '1',
		},
		children: {
			marginLeft: '8px',
			gridRow: '1',
			opacity: 0,
			alignSelf: 'center',
			'&:focus-within': {
				opacity: 1,
			},
		},
		componentLeftInside: {
			gridColumn: '1 / 3',
		},
		componentLeftOutside: {
			gridColumn: '2 / 3',
		},
		componentRightInside: {
			gridColumn: '1 / 3',
		},
		componentRightOutside: {
			gridColumn: '1 / 2',
		},
		icon: {
			alignSelf: 'center',
			gridRow: '1',
			opacity: 0,
			'&:focus': {
				opacity: 1,
			},
		},
		iconLeftInside: {
			gridColumn: '1 / 2',
			'&&': {
				paddingLeft: spacing(1.5),
			},
		},
		iconLeftOutside: {
			gridColumn: '1 / 2',
			'&&': {
				paddingRight: spacing(1.5),
			},
		},
		iconRightInside: {
			gridColumn: '2 / 3',
			'&&': {
				paddingRight: spacing(1.5),
			},
		},
		iconRightOutside: {
			gridColumn: '2 / 3',
			'&&': {
				paddingLeft: spacing(1.5),
			},
		},
	});

export const HoverWrapper = withStyles(styles)(
	forwardRef<HTMLDivElement, HoverWrapperProps & WithStyles<typeof styles>>(
		(
			{
				children,
				classes,
				component,
				hasIcon,
				iconLink,
				iconPlacement = 'right-inside',
				onClick,
				IconButtonProps,
				tooltipTitle,
				tooltipContent,
				TooltipProps,
			},
			ref
		) => {
			const clonedElement = cloneElement(component, { disabled: true, isDisabled: true });
			const horizontalPlacement = capitalize(iconPlacement.split('-')[0]);
			const overlapPlacement = capitalize(iconPlacement.split('-')[1]);

			return (
				<div className={classes.root} ref={ref}>
					<div className={clsx(classes[`component${horizontalPlacement}${overlapPlacement}`], classes.component)}>
						{clonedElement}
					</div>
					{hasIcon && (
						<Tooltip content={tooltipContent} title={tooltipTitle} {...TooltipProps}>
							<IconButton
								aria-label={window.jQuery ? $.__('Show Tooltip') : 'Show Tooltip'}
								className={clsx(classes[`icon${horizontalPlacement}${overlapPlacement}`], classes.icon)}
								floatingIcon={true}
								href={iconLink}
								icon={ifFeature('encore', 'memo-circle-check-solid', <PencilCheck18x16 />)}
								noBoundingBox
								onClick={onClick}
								{...IconButtonProps}
							/>
						</Tooltip>
					)}
					{children && <div className={classes.children}>{children}</div>}
				</div>
			);
		}
	)
) as FC<PropsWithChildren<HoverWrapperProps>>;

HoverWrapper.defaultProps = {
	hasIcon: true,
	tooltipTitle: window.jQuery ? $.__('Pending Approval') : 'Pending Approval',
	tooltipContent: window.jQuery
		? $.__('The latest change to this field is waiting for approval. Click to go to the original request.')
		: 'The latest change to this field is waiting for approval. Click to go to the original request.',
};
