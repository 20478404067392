import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { LegendItemProps } from '../types';
import { roundPercent, useChartColors } from '../common';
import { LegendLink } from './legend-link';
import { useLegendStyles } from './use-styles';

import { Flex } from '~components/flex';
import { MotionTypography } from '~components/typography';

export const LegendItem = <Datum = unknown,>({
	datum,
	index,
	legendLength,
	total,
	legendProps: {
		animate = true,
		totalLabel = window.jQuery ? $.__('Total') : 'Total',
		anchorProps,
		renderCount,
		renderLabel,
		renderTotal: TotalComponent,
		onClickLabel,
		onClickValue,
		orientation = 'vertical',
		reverse,
		showCompactSwatch,
		showPercentage,
		showValue,
		getKey,
		getValue,
		getColor,
		hrefLabel,
		hrefValue,
	},
}: Omit<LegendItemProps<Datum>, 'data'>): ReactElement => {
	const { getColor: getChartColor } = useChartColors({ length: legendLength, onGetColor: getColor, reverse });

	const legendItemStyles = {
		orientation,
		swatchColor: typeof index === 'number' ? getChartColor(index) : null,
		swatchWidth: showCompactSwatch ? 8 : 20,
	};

	const { classes } = useLegendStyles(legendItemStyles);

	const getPercentage = (value: number, total: number) => (value / total) * 100;

	const value = getValue && datum ? getValue(datum) : 0;
	const percent = getValue && datum ? getPercentage(getValue(datum), total || 1) : 0;

	const legendLabelProps = {
		href: hrefLabel ? datum && hrefLabel(datum) : undefined,
		onClick: onClickLabel ? () => datum && onClickLabel(datum) : undefined,
		...anchorProps,
	};

	const legendValueProps = {
		href: hrefValue ? datum && hrefValue(datum) : undefined,
		onClick: onClickValue ? () => datum && onClickValue(datum) : undefined,
		...anchorProps,
	};

	const swatch = <span className={classes.swatch}></span>;
	const totalValue = total === undefined ? 0 : total;
	// If animation is on, we want to use the default duration
	const animationDuration = animate ? undefined : 0;

	return datum ? (
		<Flex
			alignItems="center"
			className={classes.root}
			marginBottom="10px"
			marginRight={orientation === 'vertical' ? '0px' : '30px'}
		>
			{renderLabel ? (
				renderLabel({ datum, swatch })
			) : (
				<LegendLink {...legendLabelProps}>
					{swatch}
					{getKey(datum)}
				</LegendLink>
			)}
			{getValue && total !== 0 && showPercentage && (
				<MotionTypography
					animateDuration={animationDuration}
					className={classes.percentage}
					formatter={(v: string) => {
						// Unfortunately value gets truncated to an int in the MotionTypography component so we have to use this hack :(
						if (!datum) {
							return `0%`;
						}
						const progress = parseFloat(v) / 100;
						return `${roundPercent(progress * percent)}%`;
					}}
					to={100}
					TypographyProps={{
						className: classes.typographyStyles,
					}}
				/>
			)}
			{getValue && showValue && (
				<div className={classes.value}>
					{renderCount ? (
						renderCount({ datum })
					) : (
						<LegendLink {...legendValueProps}>
							<MotionTypography
								animateDuration={animationDuration}
								to={value}
								TypographyProps={{
									className: classes.typographyStyles,
								}}
							/>
						</LegendLink>
					)}
				</div>
			)}
		</Flex>
	) : (
		<Flex
			alignItems="center"
			className={classes.totalRow}
			justifyContent="space-between"
			marginBottom={orientation === 'vertical' ? '0' : '10px'}
			marginTop={orientation === 'vertical' ? '3px' : '0'}
			paddingTop={orientation === 'vertical' ? '3px' : '0'}
		>
			<Typography className={classes.total}>{totalLabel}</Typography>
			<span className={classes.value}>
				{TotalComponent ? (
					<TotalComponent total={totalValue} />
				) : (
					<MotionTypography
						animateDuration={animationDuration}
						to={totalValue}
						TypographyProps={{
							className: classes.typographyStyles,
						}}
					/>
				)}
			</span>
		</Flex>
	);
};
