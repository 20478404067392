import { DatePicker } from '../../../date-picker';
import { Checkbox, CheckboxGroup } from '~components/checkbox';
import { FormItemType } from '../enums';
import {
	CurrencyPickerRenderer,
	GroupRenderer,
	HiddenInputRenderer,
	IntFieldRenderer,
	PercentageFieldRenderer,
	SectionRenderer,
	SelectRenderer,
	SensitiveTextFieldRenderer,
	TextAreaRenderer,
	TextFieldRenderer,
	TextRenderer,
} from '../renderers';
import { Renderer } from '../types';
import { createRenderer } from './create-renderer';

const renderersByType: Record<FormItemType, Renderer> = {
	[FormItemType.Checkbox]: createRenderer(Checkbox),
	[FormItemType.CheckboxGroup]: createRenderer(CheckboxGroup),
	[FormItemType.CurrencyPickerField]: CurrencyPickerRenderer,
	[FormItemType.DateField]: createRenderer(DatePicker),
	[FormItemType.Group]: GroupRenderer,
	[FormItemType.HiddenInput]: HiddenInputRenderer,
	[FormItemType.IntField]: IntFieldRenderer,
	[FormItemType.PercentageField]: PercentageFieldRenderer,
	[FormItemType.Section]: SectionRenderer,
	[FormItemType.Select]: SelectRenderer,
	[FormItemType.SelectField]: SelectRenderer,
	[FormItemType.TextArea]: TextAreaRenderer,
	[FormItemType.TextField]: TextFieldRenderer,
	[FormItemType.Text]: TextRenderer,
	[FormItemType.SensitiveTextField]: SensitiveTextFieldRenderer,
};

export function getRendererByType(type: FormItemType): Renderer | undefined {
	return renderersByType[type];
}
