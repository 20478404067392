import React from 'react';
import { CaretLeft8x14 as LeftArrowIcon, CaretRight8x14 as RightArrowIcon } from '@bamboohr/grim';
import { DatePickerHeaderProps } from './date-picker-header.types';
import { useStyles } from './date-picker-header.styles';
import { SelectMonthYear } from '../index';
import { Button, IconButton } from '~components/button';
import { GridRow } from '~components/grid-row';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '~components/flex';

export const DatePickerHeader = ({
	disabled,
	hideLeftArrow = false,
	hideRightArrow = false,
	maxDate,
	minDate,
	leftArrowDisabled,
	monthValue,
	months,
	onArrowClick,
	onChange,
	onMenuClose,
	onMenuOpen,
	rightArrowDisabled,
	yearValue,
	years,
}: DatePickerHeaderProps) => {
	const { classes } = useStyles({ hideLeftArrow, hideRightArrow });

	return ifFeature(
		'encore',
		<div className={classes.root}>
			<Flex alignItems="flex-start" justifyContent="space-between">
				<div className={classes.leftArrowButton}>
					<IconButton
						aria-label={window.jQuery ? $.__('Previous Month') : 'Previous Month'}
						color="primary"
						disabled={leftArrowDisabled}
						icon="caret-left-solid"
						onClick={() => {
							onArrowClick('previous-month');
						}}
						size="small"
						variant="outlined"
					/>
				</div>
				<SelectMonthYear
					disabled={disabled}
					maxDate={maxDate}
					minDate={minDate}
					months={months}
					monthValue={monthValue}
					onChange={onChange}
					onMenuClose={onMenuClose}
					onMenuOpen={onMenuOpen}
					years={years}
					yearValue={yearValue}
				/>
				<div className={classes.rightArrowButton}>
					<IconButton
						aria-label={window.jQuery ? $.__('Next Month') : 'Next Month'}
						color="primary"
						disabled={rightArrowDisabled}
						icon="caret-right-solid"
						onClick={() => {
							onArrowClick('next-month');
						}}
						size="small"
						variant="outlined"
					/>
				</div>
			</Flex>
		</div>,
		<GridRow justifyContent="space-between">
			<div className={classes.leftArrowButton}>
				<Button
					aria-label={window.jQuery ? $.__('Previous Month') : 'Previous Month'}
					disabled={leftArrowDisabled}
					icon={ifFeature('encore', 'arrow-left-solid', <LeftArrowIcon />)}
					onClick={() => {
						onArrowClick('previous-month');
					}}
					outline
					secondary
				/>
			</div>
			<SelectMonthYear
				disabled={disabled}
				maxDate={maxDate}
				minDate={minDate}
				months={months}
				monthValue={monthValue}
				onChange={onChange}
				onMenuClose={onMenuClose}
				onMenuOpen={onMenuOpen}
				years={years}
				yearValue={yearValue}
			/>
			<div className={classes.rightArrowButton}>
				<Button
					aria-label={window.jQuery ? $.__('Next Month') : 'Next Month'}
					disabled={rightArrowDisabled}
					icon={ifFeature('encore', 'arrow-right-solid', <RightArrowIcon />)}
					onClick={() => {
						onArrowClick('next-month');
					}}
					outline
					secondary
				/>
			</div>
		</GridRow>
	);
};
