import React, { ReactElement } from 'react';
import { Button, TextButton } from '@fabric/button';
import { VerticalWizardProps } from './types';
import { Base } from './base';
import { Navigation } from './navigation';
import { Step } from './step';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox } from '~components/layout-box';

export function VerticalWizard({
	biId,
	currentStep,
	NextButtonProps,
	nextButtonText,
	onNextClick,
	onPrevClick,
	onCancelClick,
	onStepClick,
	PrevButtonProps,
	prevButtonText,
	CancelButtonProps,
	cancelButtonText,
	steps,
}: VerticalWizardProps): ReactElement {
	const showPrevButton = currentStep > 0;
	const nextButtonDisabled = steps[currentStep + 1]?.disabled;
	const prevButtonDisabled = steps[currentStep - 1]?.disabled;
	const isFinalStep = currentStep === steps.length - 1;
	const defaultNextStepText = window.jQuery
		? $.__('Next: %s', steps[currentStep + 1]?.title, { note: 'The placeholder is the name of the next step in a process' })
		: `Next: ${steps[currentStep + 1]?.title}`;
	const defaultFinalStepText = window.jQuery ? $.__('Save and Finish') : 'Save and Finish';

	const stepComponents = steps.map((step, index) => (
		<Step
			active={index === currentStep}
			biId={biId ? `${biId}-step-${index}` : undefined}
			disabled={step.disabled}
			hideIcon={step.hideIcon}
			icon={step.icon}
			key={step.title}
			note={step.note}
			onClick={() => {
				onStepClick?.(index);
			}}
			status={step.status}
			TooltipProps={step.TooltipProps}
		>
			{step.title}
		</Step>
	));

	return (
		<Base biId={biId} steps={stepComponents}>
			<Navigation
				cancelButton={
					onCancelClick ? (
						<LayoutBox alignSelf="center">
							<TextButton
								color="secondary"
								data-bi-id={biId ? `${biId}-cancel-button` : undefined}
								onClick={onCancelClick}
								type="button"
								{...CancelButtonProps}
							>
								{cancelButtonText || (window.jQuery ? $.__('Cancel') : 'Cancel')}
							</TextButton>
						</LayoutBox>
					) : undefined
				}
				nextButton={
					<Button
						data-bi-id={biId ? `${biId}-next-button` : undefined}
						disabled={nextButtonDisabled}
						onClick={onNextClick}
						size={ifFeature('encore', undefined, 'biggie')}
						type="button"
						{...NextButtonProps}
					>
						{nextButtonText || (isFinalStep ? defaultFinalStepText : defaultNextStepText)}
					</Button>
				}
				prevButton={
					showPrevButton
						? ifFeature(
								'encore',
								<Button
									color="secondary"
									data-bi-id={biId ? `${biId}-prev-button` : undefined}
									disabled={prevButtonDisabled}
									onClick={onPrevClick}
									type="button"
									variant="outlined"
									{...PrevButtonProps}
								>
									{prevButtonText ||
										(window.jQuery
											? $.__('« Back to %s', steps[currentStep - 1]?.title, {
													note: 'The placeholder is the name of the previous step in a process',
												})
											: `« Back to ${steps[currentStep - 1]?.title}`)}
								</Button>,
								<TextButton
									color="secondary"
									data-bi-id={biId ? `${biId}-prev-button` : undefined}
									disabled={prevButtonDisabled}
									onClick={onPrevClick}
									size="small"
									type="button"
									{...PrevButtonProps}
								>
									{prevButtonText ||
										(window.jQuery
											? $.__('« Back to %s', steps[currentStep - 1]?.title, {
													note: 'The placeholder is the name of the previous step in a process',
												})
											: `« Back to ${steps[currentStep - 1]?.title}`)}
								</TextButton>
							)
						: undefined
				}
			/>
		</Base>
	);
}

VerticalWizard.Base = Base;
VerticalWizard.Navigation = Navigation;
VerticalWizard.Step = Step;
