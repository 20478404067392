import { OPPOSITE_DIRECTIONS } from '../constants';

export const HORIZONTAL_ALIGN_TO_LOCATION = {
	center: 'center',
	end: 'right',
	start: 'left',
};

export const VERTICAL_ALIGN_TO_LOCATION = {
	center: 'middle',
	end: 'bottom',
	start: 'top',
};

export function toLocations(placement) {
	const { align = 'start', side = 'top' } = placement;

	let locations = null;

	if (side === 'top' || side === 'bottom') {
		locations = {
			anchor: {
				horizontal: HORIZONTAL_ALIGN_TO_LOCATION[align],
				vertical: side,
			},
			vessel: {
				vertical: OPPOSITE_DIRECTIONS[side],
				horizontal: HORIZONTAL_ALIGN_TO_LOCATION[align],
			},
		};
	}

	if (side === 'left' || side === 'right') {
		locations = {
			anchor: {
				horizontal: side,
				vertical: VERTICAL_ALIGN_TO_LOCATION[align],
			},
			vessel: {
				horizontal: OPPOSITE_DIRECTIONS[side],
				vertical: VERTICAL_ALIGN_TO_LOCATION[align],
			},
		};
	}

	if (
		locations === null ||
		!locations.anchor.horizontal ||
		!locations.anchor.vertical ||
		!locations.vessel.horizontal ||
		!locations.vessel.vertical
	) {
		throw new Error('An invalid placement was supplied.');
	}

	return locations;
}
