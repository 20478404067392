import React, { FunctionComponent, PropsWithChildren } from 'react';

import {
	BaseModal,
	ConfirmationContent,
	Constraint,
	FocalContent,
	HeroHeadline,
	LowerContent,
	OverlayTheme,
	Transition,
} from '~components/base-modal';
import { Body } from './body.react';
import { Footer } from './footer.react';
import { Header } from './header.react';
import { StandardHeadline } from './standard-headline.react';
import { UpperContent } from './upper-content.react';
import { SheetModalProps } from './sheet-modal.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const SheetModal: FunctionComponent<PropsWithChildren<SheetModalProps>> & {
	Body: typeof Body;
	CloseButton: typeof BaseModal.CloseButton;
	ConfirmationContent: typeof ConfirmationContent;
	Constraint: typeof Constraint;
	FocalContent: typeof FocalContent;
	Footer: typeof Footer;
	Header: typeof Header;
	HeroHeadline: typeof HeroHeadline;
	LowerContent: typeof LowerContent;
	StandardHeadline: typeof StandardHeadline;
	UpperContent: typeof UpperContent;
} = props => {
	return (
		<BaseModal
			{...props}
			overlayTheme={ifFeature('encore', OverlayTheme.FULLY_OPAQUE, OverlayTheme.PARTIALLY_OPAQUE)}
			transition={Transition.ZOOM_FADE}
		/>
	);
};

SheetModal.Body = Body;
SheetModal.CloseButton = BaseModal.CloseButton;
SheetModal.ConfirmationContent = ConfirmationContent;
SheetModal.Constraint = Constraint;
SheetModal.FocalContent = FocalContent;
SheetModal.Footer = Footer;
SheetModal.Header = Header;
SheetModal.HeroHeadline = HeroHeadline;
SheetModal.LowerContent = LowerContent;
SheetModal.StandardHeadline = StandardHeadline;
SheetModal.UpperContent = UpperContent;
