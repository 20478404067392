import { CSSProperties, } from 'react';

export const styles: { [key: string]: CSSProperties } = {
	popoverContent: {
		background: '#FFF',
		border: '1px solid rgba(178, 178, 178)',
		borderRadius: 5,
		position: 'absolute',
		top: 6,
		left: -359,
		padding: 24,
		width: '359px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	popoverHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 12,
		width: '100%',
		marginBottom: 16,
	},
	exitButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: 30,
		cursor: 'pointer',
	},
	exitButton: {
		background: 'transparent',
		width: 22,
		height: 16,
		borderWidth: 0,
	},
	thumbsDownContainer: {
		border: '1px solid #FE4A22',
		borderRadius: 5,
		padding: '2px 8px',
		background: 'rgba(254, 74, 34, 0.10)',
	},
	titleText: {
		width: '100%',
		textAlign: 'left',
	},
	checkboxContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 4,
		alignItems: 'center',
	},
	checkbox: {
		height: 20,
		width: 20,
		borderRadius: 2,
		backgroundColor: '#fff',
		marginRight: 8,
		padding: 0,
	},
	textArea: {
		height: 92,
		width: 275,
		borderRadius: 5,
		marginTop: 8,
		resize: 'none',
		backgroundColor: 'transparent',
		padding: '8px 12px',
		marginBottom: 12,
	},
	submitButton: {
		padding: '6px 16px',
		backgroundColor: '#5A9917',
		border: '0px solid #ffffff',
		borderRadius: 5,
	},
	buttonText: {
		color: ' white',
	},
};
