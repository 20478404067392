import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StandardHeadline as BaseModalStandardHeadline, useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { StandardModalHeadlineProps } from '../standard-modal.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const StandardHeadline: FunctionComponent<PropsWithChildren<StandardModalHeadlineProps>> = props => {
	const { isMediumScreen } = useScreenSizes();

	return isMediumScreen ? (
		<FullScreenModal.StandardHeadline {...props} />
	) : (
		<div style={{ marginTop: ifFeature('encore', undefined, '20px') }}>
			<BaseModalStandardHeadline {...props} />
		</div>
	);
};
