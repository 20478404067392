import { ListItem } from '../types';
import { clearSelected } from './clear-is-selected';

export function filterSelectedItems(
	currentSelectedOptions: Array<ListItem>,
	currentAvailableOptions: Array<ListItem>,
	setRemaining: (list: Array<ListItem>) => void,
	setSelected: (list: Array<ListItem>) => void,
	sortList: (list: Array<ListItem>) => Array<ListItem>
) {
	if (!Array.isArray(currentAvailableOptions) || !Array.isArray(currentSelectedOptions)) {
		const availableOptionsType = Array.isArray(currentAvailableOptions) ? 'array' : typeof currentAvailableOptions;

		const selectedOptionsType = Array.isArray(currentSelectedOptions) ? 'array' : typeof currentAvailableOptions;

		throw new Error(
			`Expected arrays but got ${selectedOptionsType} for currentSelectedOptions and ${availableOptionsType} for currentAvailableOptions`
		);
	}
	const remainingAvailable: Array<ListItem> = [];
	const updatedSelected = currentAvailableOptions.filter(item => {
		if (item.selected) {
			return true;
		}

		remainingAvailable.push(item);
		return false;
	});

	if (updatedSelected.length === 0) {
		return;
	}

	const currentSelected = clearSelected([...currentSelectedOptions]);

	setRemaining(remainingAvailable);
	setSelected(sortList([...currentSelected, ...updatedSelected]));
}
