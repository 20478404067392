import React, { ReactElement, RefAttributes, forwardRef } from 'react';
import { FormControlLabel, InputProps, Radio as MuiRadio } from '@mui/material';
import { FormNote } from '~components/form-note';
import { getBiId } from './radio.domain';
import { RadioProps, RadioValue } from './types';
import { useRadioStyles } from './radio.styles';

export const Radio = forwardRef<HTMLButtonElement, RadioProps>(
	(
		{
			biId,
			checked,
			className,
			classes = {},
			disabled,
			id,
			inputProps,
			label,
			name,
			note,
			onChange,
			required,
			size = 'medium',
			value,
		},
		ref
	) => {
		const { classes: radioClasses, cx } = useRadioStyles({ checked, label, note, size });

		const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
			onChange({ value }, e);
		};

		const buttonProps = {
			checked,
			checkedIcon: <span className={cx(radioClasses.radioIcon, radioClasses.radioCheckedIcon)} />,
			icon: <span className={radioClasses.radioIcon} />,
			id,
			inputProps: {
				'aria-label': label ? '' : 'radio button',
				'data-bi-id': getBiId(biId, name, value),
				...inputProps,
			} as InputProps['inputProps'],
			name,
			onChange: handleOnChange,
			required,
			size,
			value,
		};

		if (label) {
			return (
				<span aria-disabled={disabled} className={cx(radioClasses.root, classes.root)}>
					<FormControlLabel
						classes={{
							root: cx(radioClasses.labelRoot, classes.labelRoot),
							label: cx(radioClasses.label, classes.label),
						}}
						className={cx(className, {
							[radioClasses.small]: size === 'small',
						})}
						control={<MuiRadio classes={{ root: cx(radioClasses.radioRoot, classes.radio) }} {...buttonProps} />}
						disabled={disabled}
						inputRef={ref}
						label={label}
						value={value}
					/>
					{note && (
						<div className={radioClasses.note}>
							<FormNote>{note}</FormNote>
						</div>
					)}
				</span>
			);
		}

		return (
			<MuiRadio
				classes={{ root: cx(radioClasses.radioRoot, classes.radio) }}
				className={className}
				disabled={disabled}
				inputRef={ref}
				{...buttonProps}
			/>
		);
	}
) as <Value extends RadioValue = RadioValue>(props: RadioProps<Value> & RefAttributes<HTMLButtonElement>) => ReactElement;
