import { makeStyles } from '~styles';
import { HeaderProps } from './header.types';

export const useHeaderStyles = makeStyles<Partial<HeaderProps>>()(theme => {
	const { constructs, spacing, typography } = theme;

	return {
		description: {
			color: constructs.text.neutral.strong,
			fontSize: typography.small.fontSize,
			lineHeight: typography.small.lineHeight,
		},
		dividersTop: {
			backgroundColor: constructs.border.neutral.xWeak,
			border: 'none',
			height: '1px',
			margin: `${spacing(4)} 0 ${spacing(2.5)} 0`,
			width: '100%',
		},
		dividersBottom: {
			backgroundColor: constructs.border.neutral.xWeak,
			border: 'none',
			height: '1px',
			margin: `${spacing(2)} 0 ${spacing(2.5)} 0`,
			width: '100%',
		},
		iconContainer: {
			fill: constructs.text.primary.strong,
			marginRight: spacing(2),
			alignSelf: 'flex-start',
		},
		content: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		actions: {
			marginLeft: 'auto',
		},
	};
});
