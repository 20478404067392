import { includes } from 'lodash';

import { traverseItemsByCharacter } from './traversal';
import { canAffectVisibility, canCauseMovement } from '../../../dom-util';
import { hasChildren, isActionOnly } from '../../../item';

export function shouldActivateItem(key, state) {
	const { cursorIsAtBeginningOfInput, cursorIsAtEndOfInput, inputIsFocused, isOpen } = state;

	const activationKeys = ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'End', 'Home', 'PageUp', 'PageDown'];

	let shouldActivate = isOpen && includes(activationKeys, key);

	if (shouldActivate && inputIsFocused) {
		if (key === 'ArrowLeft' && !cursorIsAtBeginningOfInput) {
			shouldActivate = false;
		} else if (key === 'ArrowRight' && !cursorIsAtEndOfInput) {
			shouldActivate = false;
		}
	}

	return shouldActivate;
}

export function shouldActivateFirstItem(key, state) {
	const { isOpen } = state;

	return isOpen && (key === 'Home' || key === 'PageUp');
}

export function shouldActivateLastItem(key, state) {
	const { isOpen } = state;

	return isOpen && (key === 'End' || key === 'PageDown');
}

export function shouldActivateOtherItem(key = '', state, settings) {
	const { inputIsFocused, isOpen } = state;

	return isOpen && !inputIsFocused && shouldTraverseToMatch(key, state, settings);
}

export function shouldFocusAnchor(key, state) {
	const { activeItem, anchorIsFocused, cursorIsAtEndOfInput } = state;

	return !anchorIsFocused && ((key === 'ArrowRight' && cursorIsAtEndOfInput && hasChildren(activeItem)) || canAffectVisibility(key));
}

export function shouldClose(key, state) {
	const { isOpen } = state;

	return key === 'Escape' && isOpen;
}

export function shouldMoveCursor(key, state) {
	const { inputIsFocused, isOpen } = state;

	return isOpen && inputIsFocused && (key === 'Home' || key === 'End');
}

export function shouldOpen(key, state, { canQuickSelect = true, canSelectMultiple = false } = {}) {
	const { activeItem, isOpen, items } = state;

	const matchingItem = traverseItemsByCharacter(key, state);
	const hasDifferentMatchingItem = !!matchingItem && matchingItem !== activeItem;

	const shouldOpenForActionItem = isActionOnly(matchingItem, items);
	const shouldOpenForMultipleItems = canSelectMultiple && hasDifferentMatchingItem;
	const shouldOpenForParentItem = hasChildren(matchingItem);

	const shouldOpenForItem = canQuickSelect && (shouldOpenForMultipleItems || shouldOpenForParentItem || shouldOpenForActionItem);

	return (key === 'ArrowDown' || key === ' ' || key === 'Enter' || shouldOpenForItem) && !isOpen;
}

export function shouldPreventDefault(key, state) {
	const { anchorIsFocused, cursorIsAtEndOfInput, cursorIsAtBeginningOfInput, inputIsFocused, isOpen } = state;

	if (isOpen) {
		if (inputIsFocused && (key === 'ArrowLeft' || key === 'ArrowRight' || key === ' ')) {
			const hittingEdge = (key === 'ArrowLeft' && cursorIsAtBeginningOfInput) || (key === 'ArrowRight' && cursorIsAtEndOfInput);

			return hittingEdge;
		}

		return canCauseMovement(key);
	}

	return anchorIsFocused && (key === 'ArrowDown' || key === ' ');
}

export function shouldSelectItem(key, state) {
	const { activeItem, inputIsFocused, isOpen } = state;

	return !!(isOpen && activeItem && (key === 'Enter' || (key === ' ' && !inputIsFocused)));
}

export function shouldSelectOtherItem(key = '', state, settings) {
	const { isOpen } = state;

	return !isOpen && shouldTraverseToMatch(key, state, settings);
}

export function shouldTraverseToMatch(key = '', state, { canQuickSelect = false }) {
	const isNonSpaceCharacter = key.length === 1 && /\S/.test(key);

	return canQuickSelect && isNonSpaceCharacter;
}
