// eslint-disable-next-line no-use-before-define
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, IconV2Name } from '~components/icon-v2';
import React, { isValidElement, ReactElement, useLayoutEffect, useRef } from 'react';
import Action from './action';
import { IconButton } from '@fabric/button';
import { getVariantIcon } from './helpers';
import { TYPES } from './constants';
import { X14x14 } from '@bamboohr/grim';
import { SimpleSlidedownProps } from '../types/SimpleSlidedownProps';
import { useStyles } from './slidedown.styles';

export default function SimpleSlidedown({
	action,
	description,
	message,
	onDismiss,
	showDismiss,
	show,
	type = TYPES.info,
}: SimpleSlidedownProps): ReactElement {
	const { classes, cx } = useStyles();
	const dismissRef = useRef<HTMLButtonElement>(null);
	const prevFocusedRef = useRef<HTMLElement | null>(null);

	const icons: Record<TYPES, IconV2Name> = {
		[TYPES.discovery]: 'gift-solid',
		[TYPES.info]: 'circle-info-solid',
		[TYPES.success]: 'circle-check-solid',
		[TYPES.error]: 'circle-exclamation-solid',
		[TYPES.warning]: 'triangle-exclamation-solid',
	};

	const roles = {
		[TYPES.discovery]: 'status',
		[TYPES.info]: 'status',
		[TYPES.success]: 'status',
		[TYPES.error]: 'alert',
		[TYPES.warning]: 'alert',
	};

	// @startCleanup encore
	const divider = action && showDismiss && <span className={classes.divider}></span>;
	// @endCleanup encore

	const dismissButton =
		showDismiss === false ? null : (
			<IconButton
				aria-label={window.jQuery ? $.__('Dismiss slidedown') : 'Dismiss slidedown'}
				dark={true}
				// @startCleanup encore
				floatingIcon={ifFeature('encore', false, true)}
				// @endCleanup encore
				icon={ifFeature('encore', 'xmark-solid', <X14x14 aria-hidden="true" />)}
				// @startCleanup encore
				noBoundingBox={ifFeature('encore', false, true)}
				// @endCleanup encore
				onClick={onDismiss || Function.prototype}
				ref={dismissRef}
				size={ifFeature('encore', 'small')}
				type="button"
				variant={ifFeature('encore', 'outlined')}
			/>
		);

	useLayoutEffect(() => {
		// Focus on dismiss button when we show slidedown
		// Focus on previously focused element when slidedown closes
		if (showDismiss && dismissRef.current) {
			if (show) {
				prevFocusedRef.current = document.activeElement as HTMLElement;
				// The focus doesn't work without the timeout
				window.setTimeout(() => {
					dismissRef.current?.focus();
				}, 0);
			} else {
				prevFocusedRef.current?.focus();
			}
		}
	}, [show, showDismiss]);

	return (
		<div
			aria-hidden={!show}
			className={cx(classes.slidedown, {
				[classes.discovery]: type === TYPES.discovery,
				[classes.error]: type === TYPES.error,
				[classes.info]: type === TYPES.info,
				[classes.success]: type === TYPES.success,
				[classes.warning]: type === TYPES.warning,
			})}
			role={roles[type]}
		>
			<span className={classes.icon}>{ifFeature('encore', <IconV2 name={icons[type]} size={16} />, getVariantIcon(type))}</span>
			<div className={classes.text}>
				{message}
				{description ? <div className={classes.description}>{description}</div> : null}
			</div>
			<Action {...(isValidElement(action) ? { children: action } : action)} />
			{/* @startCleanup encore */}
			{ifFeature('encore', null, divider)}
			{/* @endCleanup encore */}
			{dismissButton}
		</div>
	);
}
