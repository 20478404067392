// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { useStyles } from './header.styles';
import { GridletHeaderProps } from '../types';
import { Flex } from '~components/flex';
import { IconV2 } from '~components/icon-v2';
import { LayoutBox } from '~components/layout-box';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BaseHeadline } from '~components/headline/base-headline';

export const Header = ({ children, className, icon, right, title, ...rest }: GridletHeaderProps) => {
	const { classes } = useStyles();
	return (
		<div className={classnames(classes.root, className)} {...rest}>
			{ifFeature(
				'encore',
				<Flex alignItems="center" gap={1.5}>
					{typeof icon === 'string' ? <IconV2 color="primary-strong" name={icon} size={20} /> : icon}
					{title || title === 0 ? (
						<BaseHeadline classes={{ root: classes.title }} size="extra-small">
							{title}
						</BaseHeadline>
					) : null}
					{right || right === 0 ? <LayoutBox marginLeft="auto">{right}</LayoutBox> : null}
				</Flex>
			)}
			{/* TODO: remove once https://bamboohr.atlassian.net/browse/FAB-3171 is done */}
			{children}
		</div>
	);
};
