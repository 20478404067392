import { makeStyles } from '~styles';
import { widths } from '~definitions/json/sizes.json';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<{ minActionWidth: number; actionAlignment: 'start' | 'end' }>()((
	theme,
	{ minActionWidth, actionAlignment }
) => {
	const { breakpoints } = theme;

	return {
		baseModalFooter: {
			display: 'flex',
			justifyContent: ifFeature('encore', actionAlignment, undefined),
			/* @startCleanup encore */
			[breakpoints.down(500)]: ifFeature('encore', undefined, { display: 'block' }),
			/* @endCleanup encore */
		},
		baseModalFooterActions: {
			display: 'flex',
		},
		baseModalFooterAction: {
			display: 'inline-flex',
			margin: ifFeature('encore', actionAlignment === 'start' ? '0 16px 0 0' : '0 0 0 16px', '12px 16px 0 0'),
			width: widths[minActionWidth],
			[breakpoints.down(500)]: {
				width: 'auto',
			},
		},
		minActionWidth: {},
	};
});
