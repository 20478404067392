export const FOCUSABLE_ELEMENT_QUERY = [
	'input:not([type="hidden"]):not(:disabled)',
	'textarea:not(:disabled)',
	'.fab-Select [role="button"]:not(:disabled)',
	'select:not(:disabled)',
	'button:not(:disabled)',
].join();

export const SINGLE_SUBMITTABLE_INPUT_QUERY = 'input:not([type="hidden"]), textarea, .fab-Select, select';

/**
 * Focuses the fist input element contained by a certain element
 * @param {HTMLElement} element The element to search inside of
 */
export function focusFirstFocusableElement(element: HTMLElement): void {
	// TODO: Remove the .fab-Select selector once the Select component has been updated to redirect focus.
	const inputElement = element ? (element.querySelector(FOCUSABLE_ELEMENT_QUERY) as HTMLInputElement) : null;

	if (inputElement) {
		// The Menu component (and therefore the Dropdown component)
		// focuses its button when an item is selected and the menu closes,
		// so we need to make sure that the modal grabs focus afterward
		window.setTimeout(() => inputElement.focus());
	}
}

/**
 * Whether or not supplied element's children match the supplied query only once
 * @param   {HTMLElement}   element
 * @param   {string}        query
 * @returns {boolean}
 */
export function elementContainsSingleQueryMatch(element: HTMLElement, query: string): boolean {
	if (!element || !(element instanceof HTMLElement)) {
		return false;
	}
	return element.querySelectorAll(String(query)).length === 1;
}

/**
 * Whether or not supplied element contains a single child that matches against the
 * query list of "submittable" input elements, for fabric and/or modal purposes
 * @param   {HTMLElement}  element
 * @returns {boolean}
 */
export function elementContainsSingleSubmittableInput(element: HTMLElement): boolean {
	if (!element || !(element instanceof HTMLElement)) {
		return false;
	}
	return elementContainsSingleQueryMatch(element, SINGLE_SUBMITTABLE_INPUT_QUERY);
}

/**
 * Minimal function that prevents default on an event
 * @param {object} event The event object
 */
export function preventDefault(event: Event): void {
	event.preventDefault();
}
