/**
 * Looks up the original index using the `order` array and invokes the callback with
 * that index.
 *
 * @param cb callback that should receive the original index
 * @param order an array mapping the internal index of a grouped item (the array key) to its original index (the array element)
 * @returns callback
 */
export function proxyCallback<T>(cb: (index: number) => T, order: Array<number>) {
	return (index: number): T => {
		return cb(order[index]);
	};
}
