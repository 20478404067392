import React from 'react';
import BEM from '@bamboohr/utils/lib/dom/bem';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { X10x10, DropdownCaret9x5 } from '@bamboohr/grim';
import { IconV2 } from '~components/icon-v2';

import { getCSSClasses, getInnerFacadeCSSClasses, getToggleButtonCSSClasses, getToggleContent } from './util';

import './toggle.scss';
import { getGutsCSSClasses } from './util/util';

export function Toggle(props) {
	const {
		appliedPlacement = {},
		biId,
		buttonProps,
		canSelectMultiple,
		condition,
		isClearable,
		isDark,
		isDisabled,
		isFocused,
		isInvalid,
		isOpen,
		onClear,
		placeholder,
		renderToggleContent,
		selectedItems = [],
		size,
		type,
		variant,
		width,
	} = props;

	const bemBlock = type === 'text' ? 'fab-SelectTextToggle' : 'fab-SelectToggle';
	const bem = new BEM(bemBlock);

	const cssClasses = getCSSClasses(bemBlock, {
		appliedPlacement,
		condition: ifFeature('encore', condition),
		isClearable,
		isDark,
		isDisabled,
		isFocused,
		isInvalid,
		isOpen,
		selectedItems,
		size,
		type,
		width,
	});

	const dataAttributes = {};
	if (biId) {
		dataAttributes['data-bi-id'] = `${biId}-select-toggle`;
	}

	const caretSize = () => {
		if (type === 'text' && (size === 'small' || size == 'teenie')) {
			return 10;
		} else {
			return 16;
		}
	};

	return (
		<div className={cssClasses} {...dataAttributes} {...buttonProps} role="button">
			<div className={bem.elem('outerFacade')} key="outerFacade">
				<div className={getInnerFacadeCSSClasses(bemBlock, { condition }, { variant })} key="innerFacade"></div>
			</div>
			<div className={getGutsCSSClasses(bemBlock, { variant })}>
				{selectedItems.length ? (
					<div className={bem.elem('content')}>
						{getToggleContent(selectedItems, renderToggleContent, canSelectMultiple)}
					</div>
				) : null}

				{placeholder && selectedItems.length < 1 ? <div className={bem.elem('placeholder')}>{placeholder}</div> : null}

				{
					/* Making this element tabbable would create a weird experience for users */
					/* eslint-disable jsx-a11y/click-events-have-key-events */
					/* eslint-disable jsx-a11y/no-static-element-interactions */
					<div
						className={bem.elem('clearButton')}
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();

							onClear();
						}}
					>
						{ifFeature('encore', <IconV2 name="xmark-regular" size={16} />, <X10x10 aria-hidden />)}
					</div>
					/* eslint-enable jsx-a11y/click-events-have-key-events */
					/* eslint-enable jsx-a11y/no-static-element-interactions */
				}

				<div className={ifFeature('encore', getToggleButtonCSSClasses(bemBlock, { condition }), bem.elem('toggleButton'))}>
					{ifFeature('encore', <IconV2 name="caret-down-solid" size={caretSize()} />, <DropdownCaret9x5 aria-hidden />)}
				</div>
			</div>
		</div>
	);
}
