import React, { FC, PropsWithChildren } from 'react';
import { makeStyles } from '~mui';
import { Theme } from '@mui/material';
import Fonts from './fonts';
import { StyleReset } from './style-reset';

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
	'@global': {
		html: {
			fontSize: typography.fabricFontSize('medium'),
			ff: typography,
		},
		body: {
			color: palette.grey['1000'],
			fontFamily: typography.fontFamily,
			fontWeight: typography.fontWeightRegular,
			WebkitFontSmoothing: 'antialiased',
			MozOsxFontSmoothing: 'grayscale',
		},
	},
}));

const BaseStyles: FC<PropsWithChildren<unknown>> = ({ children }) => {
	useStyles();

	return (
		<StyleReset>
			<Fonts />
			{children}
		</StyleReset>
	);
};

export default BaseStyles;
