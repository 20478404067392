import { ListItem } from '../types';

export function sortItemsByTitle(list: Array<ListItem>): Array<ListItem> {
	if (!Array.isArray(list)) {
		return [];
	}
	const sortedArray = [...list];
	sortedArray.sort((firstItem, secondItem) => {
		const firstTitle = firstItem.title.toString().toLowerCase();
		const secondTitle = secondItem.title.toString().toLowerCase();
		if (firstTitle < secondTitle) {
			return -1;
		}

		if (firstTitle > secondTitle) {
			return 1;
		}

		return 0;
	});

	return sortedArray;
}
