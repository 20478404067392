// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Menu, ToggleButtonProps } from '~components/menu';
import { Button } from '~components/button';
import { PickerButton } from '~components/picker-button';
import { Flex } from '~components/flex';
import { CurrencyPickerProps } from '~components/currency-picker';
import { useStyles } from './styles';
import { useMenuState } from './hooks/use-menu-state';
import { useMCUCurrencyItems } from './hooks/use-mcu-currency-items';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import type { Size } from '~components/types';
import type { EncoreSize } from '~utils';

const mapLegacyToEncoreInputSize = (size: Size): Extract<EncoreSize, 'small' | 'medium' | 'large'> => {
	switch (size) {
		case 'teenie':
			return 'small';
		case 'small':
			return 'small';
		case 'medium':
			return 'medium';
		case 'large':
			return 'large';
		case 'biggie':
			return 'large';
		default:
			return 'medium';
	}
};

/**
 * This currency menu component requires a parent to manage the state of its input
 */
export function CurrencyPicker(props: CurrencyPickerProps) {
	const {
		biId,
		buttonProps,
		currencyCollection,
		disabled,
		name,
		onChange,
		onMenuClose,
		onMenuOpen,
		renderToggle: customRenderToggle,
		size = 'medium',
		status,
		value,
		variant,
	} = props;
	const { classes } = useStyles();

	const { open, handleOpen, handleClose } = useMenuState(onMenuOpen, onMenuClose);
	const currencyMenuItems = useMCUCurrencyItems(currencyCollection);

	const renderToggle = ({ isFocused }: { isFocused: boolean }, menuButtonProps: ToggleButtonProps): ReactElement => {
		if (customRenderToggle && typeof customRenderToggle === 'function') {
			return customRenderToggle();
		}

		const {
			'aria-expanded': ariaExpanded,
			'aria-haspopup': ariaHaspopup,
			'aria-label': ariaLabel,
			'data-menu-id': dataMenuId,
			onBlur,
			onClick,
			onFocus,
			ref,
		} = menuButtonProps;

		return ifFeature(
			'encore',
			<PickerButton
				ariaExpanded={ariaExpanded.toString()}
				ariaHaspopup={ariaHaspopup.toString()}
				ariaLabel={ariaLabel}
				biId={biId}
				containerRef={ref}
				dataMenuId={dataMenuId}
				disabled={disabled}
				focused={isFocused}
				onBlur={onBlur}
				onClick={onClick}
				onFocus={onFocus}
				size={mapLegacyToEncoreInputSize(size)}
				status={status}
				variant={variant}
			>
				{value}
			</PickerButton>,
			<Button
				{...menuButtonProps}
				classes={{
					focusVisible: classes.focusVisible,
				}}
				className={classes.currencyButton}
				data-bi-id={biId}
				disabled={disabled}
				focused={isFocused}
				tabindex={-1}
				type="button"
				variant="outlined"
				{...buttonProps}
			>
				{value}
			</Button>
		);
	};

	return (
		<>
			<Flex alignItems="stretch" data-component-name="CurrencyInputMenu" data-currency-code={value} height="100%">
				<Menu
					isDisabled={disabled}
					isOpen={open}
					items={currencyMenuItems}
					onClose={handleClose}
					onOpen={handleOpen}
					onSelect={onChange}
					placement={{
						align: 'start',
						side: 'bottom',
					}}
					renderToggle={renderToggle}
					showSearch="never"
				/>
			</Flex>
			{name && <input className="currencyType js-currency-picker-type" name={name} type="hidden" value={value} />}
		</>
	);
}
