import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TaskProgressBarProps, TaskProgressNoteType, TaskProgressNotePlacement } from './types';
import { ProgressBar } from '~components/progress-bar';
import { useStyles } from './task-progress-bar.styles';

export const TaskProgressBar = (props: TaskProgressBarProps): ReactElement => {
	const { current, height, note = '', placement = 'right', total, type = 'task', width = '412px' } = props;
	const classes = useStyles(props);
	const stepString = window.jQuery ? $.__('Step') : 'Step';
	const ofString = window.jQuery ? $.__('of') : 'of';
	const percentage = Math.round((current / total) * 100);
	const a11yStepLabel = window.jQuery ? $.__(`on step %1 of %2`, current, total) : `on step ${current} of ${total}`;
	const a11yTaskLabel = window.jQuery ? $.__(`%1 of %2`, current, total) : `${current} of ${total}`;
	const a11yPercentageLabel = window.jQuery ? $.__(`%1%%`, percentage) : `${percentage}%`;
	let label;
	let ariaLabel = '';

	if (type === TaskProgressNoteType.task) {
		label = (
			<span className={classes.note}>
				{current || '0'}/{total} {note}
			</span>
		);
		ariaLabel = a11yTaskLabel;
	}

	if (type === TaskProgressNoteType.step) {
		label = <span aria-hidden={true} className={classes.note}>{`${stepString} ${current || '0'} ${ofString} ${total}`}</span>;
		ariaLabel = a11yStepLabel;
	}

	if (type === TaskProgressNoteType.percentage) {
		label = <span aria-hidden={true} className={classes.note}>{`${percentage}%`}</span>;
		ariaLabel = a11yPercentageLabel;
	}

	return (
		<div aria-label={ariaLabel} className={classes.root} role="status">
			{placement === TaskProgressNotePlacement.left && <div className={classes.marginLeft}>{label}</div>}
			{ifFeature(
				'encore',
				<ProgressBar current={current} height={height} total={total} width={width} />,
				<div className={classes.outerProgress}>
					<div className={classes.innerProgress} />
				</div>
			)}
			{placement === TaskProgressNotePlacement.right && <div className={classes.marginRight}>{label}</div>}
		</div>
	);
};

export default TaskProgressBar;
