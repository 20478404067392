// eslint-disable-next-line no-use-before-define
import React from 'react';
import { TickRendererProps } from '@visx/axis';
import { makeStyles } from '~styles';
import { Flex } from '../../flex';
import { TickIconLabel } from '../types';

const useCustomLabelsStyles = makeStyles()(({ palette, typography }) => ({
	icon: {
		fill: palette.gray[700],
	},
	label: {
		color: palette.gray[800],
		fontFamily: typography.fontFamily,
		fontSize: typography.large?.fontSize,
		marginLeft: 8,
	},
}));

export const useIconLabels = ({ height = 20, iconMap, xOffset = 0, yOffset = 0 }: TickIconLabel) => {
	const { classes: styles } = useCustomLabelsStyles();

	return ({ formattedValue, x, y }: TickRendererProps) => {
		const value = (formattedValue as string).split('|');
		const displayText = value[0];
		const iconName = value[1].trim();
		const iconComponent = iconMap[iconName];

		return (
			<foreignObject height={height} width="100%" x={x + xOffset} y={y - yOffset}>
				<Flex>
					<div className={styles.icon}>{iconComponent}</div>
					<div className={styles.label}>{displayText}</div>
				</Flex>
			</foreignObject>
		);
	};
};
