import { DatePicker } from '~components/date-picker';
import { FormFactoryDateFieldModel } from '../types/builtin-models.types';
import { adapted } from '../utils/adapted';

export const DateField = adapted(DatePicker)((model: FormFactoryDateFieldModel) => {
	const { id, label, onChange, required, status, value = '' } = model;

	return {
		label,
		onChange: ({ value }) => {
			if (typeof onChange === 'function') {
				onChange({ id, value });
			}
		},
		required,
		status,
		value,
	};
});
