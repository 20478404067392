import { SeriesPoint, BarStack } from '@visx/shape/lib/types';

const showBar = <Datum>(
	bar: {
		bar: SeriesPoint<Datum>;
		key: string;
	},
	getBarValue: ((d: Datum, key: string) => number) | undefined
): boolean => {
	if (typeof getBarValue === 'function') {
		if (!getBarValue(bar.bar.data, bar.key)) {
			return false;
		}
	} else if (!bar.bar.data[bar.key]) {
		return false;
	}
	return true;
};

/**
 * Filters out bars with a value of 0 so that they don't show up in BarChart
 * @param barStacks An array of BarStacks
 * @param getBarValue Optional accessor for getting bar value
 * @returns barStacks without bars with values of 0
 */
export const filterOutBarsWithValueOfZeroInBarStacks = <Datum>(
	barStacks: BarStack<Datum, string>[],
	getBarValue: ((d: Datum, key: string) => number) | undefined
) =>
	barStacks.map(barStack => {
		barStack.bars = barStack.bars.filter(bar => showBar<Datum>(bar, getBarValue));
		return barStack;
	});
