import React, { ReactElement } from 'react';
import { Box, Divider as MUIDivider } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { getEncoreValue } from '~utils';
import { DividerProps } from './types';

const useStyles = makeStyles<Partial<DividerProps>>()((theme, props) => {
	const { mode, palette, spacing } = theme;
	const { color, noGap, orientation } = props;

	return ifFeature(
		'encore',
		{
			root: {
				backgroundColor: 'transparent',
				borderColor:
					mode === ModeOptions.Dye
						? dyeColors.main
						: getEncoreValue(color, {
								'neutral-extra-weak': palette.gray[200],
								'neutral-weak': palette.gray[300],
								'neutral-medium': palette.gray[400],
							}),
				height: 'unset',
			},
		},
		{
			root: {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[400],
				// Ensure global legacy `hr` `height: 1px` style isn't applied. See `main/ui-src/public/global/styles/_styles.css` for deprecation notice.
				height: 'unset',
				'&.MuiDivider-light': {
					backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[300],
				},
				...(noGap
					? {}
					: {
							margin: orientation === 'vertical' ? `0 ${spacing(1)}` : `${spacing(2)} 0`,
						}),
			},
		}
	);
});

export const Divider = (props: DividerProps): ReactElement => {
	const { color = 'neutral-medium', noGap, orientation, ...rest } = props;
	const { classes } = useStyles({ color, noGap, orientation });
	return <Box classes={classes} component={MUIDivider} orientation={orientation} {...rest} />;
};
