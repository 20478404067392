import { PickersDayProps } from '@mui/x-date-pickers';
import { getDateFromProp } from '~components/date-picker/utils';
import {
	DatePickerDate,
	DatePickerDateInfo,
	DatePickerDatePredicate,
	DatePickerDateType,
	DatePickerRenderDateFunction,
	DatePickerUtils,
} from '~components/date-picker';
import { CalendarDatePredicate, CalendarRenderDateFunction } from './calendar.types';

export const dateFromISO = (utils: DatePickerUtils, date: string | undefined): DatePickerDate | undefined => {
	return typeof date === 'string' ? getDateFromProp(utils, date) : undefined;
};

export const dateToISO = (utils: DatePickerUtils, date: DatePickerDate): string => {
	return utils.format(date as DatePickerDateType, 'isoDate');
};

export const convertDatePredicate = (utils: DatePickerUtils, predicate: CalendarDatePredicate): DatePickerDatePredicate => {
	return (date: DatePickerDate) => {
		return predicate(dateToISO(utils, date));
	};
};

export const convertRenderDateFunction = (utils: DatePickerUtils, func: CalendarRenderDateFunction): DatePickerRenderDateFunction => {
	return <TDate extends DatePickerDate>(
		date: DatePickerDateInfo,
		selectedDates: Array<TDate | null>,
		pickersDayProps: PickersDayProps<TDate>
	) => {
		const calendarSelectedDates = selectedDates
			.filter((date): date is TDate => date !== null)
			.map(date => date && dateToISO(utils, date));
		const { day, onDayFocus, onDaySelect, ...rest } = pickersDayProps;
		const calendarPickersDayProps: PickersDayProps<string> = {
			day: dateToISO(utils, day),
			onDayFocus: day => {
				return onDayFocus && onDayFocus(dateFromISO(utils, day) as TDate);
			},
			onDaySelect: (day, isFinish) => {
				return onDaySelect(dateFromISO(utils, day) as TDate, isFinish);
			},
			...rest,
		};

		return func(date, calendarSelectedDates, calendarPickersDayProps);
	};
};
