import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export interface UseTabsOverflowProps {
	alwaysVisible?: boolean;
}

/**
 * Hides tabs that are overflowing and returns the index of the first hidden tab.
 *
 * @returns An object containing:
 * - `overflowIndex` - The index of the first tab that is hidden and should appear in the dropdown.
 * - `setDropdownRef` - A function that should be invoked with the dropdown root element, which should be a sibling to the tabs in the DOM.
 */
export function useTabDropdown(props: UseTabsOverflowProps) {
	const { alwaysVisible } = props;
	const [overflowIndex, setOverflowIndex] = useState(-1);
	const containerRef = useRef<HTMLElement | null>();

	const hideOverflowingTabs = useCallback(() => {
		const containerElement = containerRef.current;
		if (!containerElement?.children?.length) return;
		const tabElements = Array.from(containerElement.children) as HTMLElement[];

		// enable reading the width of all tabs
		tabElements.forEach(el => (el.style.display = 'inline-flex'));

		// the dropdown tab is always the last tab, even when hidden
		const dropdownTabElement = tabElements[tabElements.length - 1];

		// MUI's container uses a flex gap, which we need to account for
		const gap = parseInt(getComputedStyle(containerElement).gap || '0', 10);

		// used to determine the width of the container without the dropdown tab
		const containerElementScrollWidthWithoutDropdown = containerElement.scrollWidth - dropdownTabElement.offsetWidth - gap;

		// check if the tabs are overflowing
		const hasOverflow =
			(alwaysVisible ? containerElement.scrollWidth : containerElementScrollWidthWithoutDropdown) > containerElement.offsetWidth;

		// if there is no overflow, set the overflow index to -1 and return
		if (!hasOverflow) {
			setOverflowIndex(-1);
			// hide dropdown tab if it's not always visible
			if (!alwaysVisible) {
				tabElements[tabElements.length - 1].style.display = 'none';
			}
			return;
		}

		// if we get here, the tabs are overflowing, so we know we need to show the dropdown and hide at least one tab
		// we'll loop through the tabs in reverse order (excluding the dropdown tab), remove that tab, and check if the overflow is resolved
		// if so, that tab is the overflow index; it and every tab after it should be moved to the dropdown
		const newOverflowIndex = tabElements.slice(0, -1).findLastIndex(el => {
			el.style.display = 'none';
			if (containerElement.scrollWidth <= containerElement.offsetWidth) {
				// overflow is resolved
				return true;
			}
		});

		setOverflowIndex(newOverflowIndex);
	}, [alwaysVisible]);

	const setContainerRef = useCallback(
		(dropdownElement: HTMLElement | null) => {
			containerRef.current = dropdownElement?.parentElement || null;
			hideOverflowingTabs();
		},
		[hideOverflowingTabs]
	);

	useLayoutEffect(() => {
		if (!containerRef.current) return;

		const resizeObserver = new ResizeObserver(() => hideOverflowingTabs());
		resizeObserver.observe(containerRef.current);

		return () => resizeObserver.disconnect();
	}, [hideOverflowingTabs]);

	return { overflowIndex, setDropdownRef: setContainerRef };
}
