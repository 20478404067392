/**
 * Determines the tabIndex for a category, subcategory, or item in the transfer list
 * When the TransferList loads, makes the zeroth item tabbable
 * When the last item in the list is moved, makes the new last item tabbable
 */

export const getTabIndex = (focusedIndex: number, displayOrderIndex?: number, listLength = 0): 0 | -1 => {
	if (displayOrderIndex === undefined) {
		return -1;
	}
	return focusedIndex === displayOrderIndex ||
		(focusedIndex === -1 && displayOrderIndex === 0) ||
		(focusedIndex === listLength && displayOrderIndex === listLength - 1)
		? 0
		: -1;
};
