import { useSelectRows } from './index';
import { ListItem, RowClickData } from '../types';

import { sortItemsByTitle, filterSelectedItems } from '../etc';
import { cloneDeep } from 'lodash';

interface Options {
	availableOptions: Array<ListItem>;
	selectedOptions: Array<ListItem>;
	sortList?: (list: Array<ListItem>) => Array<ListItem>;
	setAvailableOptions: (arg0: Array<ListItem>) => void;
	setSelectedOptions: (arg0: Array<ListItem>) => void;
}

export function useSingleSelectControls(options: Options) {
	const {
		availableOptions = [],
		sortList = sortItemsByTitle,
		selectedOptions = [],
		setAvailableOptions,
		setSelectedOptions,
	} = options;

	const rowSelectHandler = useSelectRows();

	const reorderSubcategories = (dragName: string, hoverName: string) => {
		const newSelectItems = cloneDeep(selectedOptions);
		const draggedSubcategoryItems = newSelectItems.filter(item => {
			return item.subcategory === dragName || item.title === dragName;
		});
		const hoverSubcategoryItems = newSelectItems.filter(item => {
			return item.subcategory === hoverName || item.title === hoverName;
		});
		const firstDraggedIndex = newSelectItems.findIndex(e => e.rowKey === draggedSubcategoryItems[0].rowKey);
		const lastDraggedIndex = newSelectItems.findIndex(
			e => e.rowKey === draggedSubcategoryItems[draggedSubcategoryItems.length - 1].rowKey
		);
		let firstHoverIndex = newSelectItems.findIndex(e => e.rowKey === hoverSubcategoryItems[0].rowKey);
		newSelectItems.splice(firstDraggedIndex, lastDraggedIndex - firstDraggedIndex + 1);

		if (lastDraggedIndex < firstHoverIndex) {
			const lastHoverIndex = newSelectItems.findIndex(
				e => e.rowKey === hoverSubcategoryItems[hoverSubcategoryItems.length - 1].rowKey
			);
			newSelectItems.splice(lastHoverIndex + 1, 0, ...draggedSubcategoryItems);
		} else {
			firstHoverIndex = newSelectItems.findIndex(e => e.rowKey === hoverSubcategoryItems[0].rowKey);
			newSelectItems.splice(firstHoverIndex, 0, ...draggedSubcategoryItems);
		}

		setSelectedOptions(newSelectItems);
	};

	const useOnAvailableSelect = (CustomEventData: RowClickData, event: PointerEvent, isSubcategory = false): Array<ListItem> => {
		const updatedSelect = rowSelectHandler(availableOptions, CustomEventData.key, event, isSubcategory);
		filterSelectedItems(selectedOptions, updatedSelect, setAvailableOptions, setSelectedOptions, sortList);
		return updatedSelect;
	};

	function useOnSelectedSelect(customEventData: RowClickData, event: PointerEvent, isSubcategory = false): Array<ListItem> {
		const updatedSelect = rowSelectHandler(selectedOptions, customEventData.key, event, isSubcategory);
		filterSelectedItems(availableOptions, updatedSelect, setSelectedOptions, setAvailableOptions, sortList);
		return updatedSelect;
	}

	return {
		availableOptions,
		selectedOptions,
		useOnAvailableSelect,
		useOnSelectedSelect,
		reorderSubcategories,
	};
}
