export const ADD_ITEM = {
	isActionOnly: true,
	items: [
		{
			value: 'fab-menu-add-option',
			type: 'add',
		},
	],
	key: 'fab-menu-add-group',
	type: 'group',
};

export const DEFAULT_SEARCH_THRESHOLD = 8;
