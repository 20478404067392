import { Icon } from '@fabric/icon';
import classnames from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, IconV2Name } from '~components/icon-v2';
import { IconTile, IconTileProps } from '~components/icon-tile';
import {
	SelectableBoxStylesProps,
	StyledContainer,
	StyledContent,
	StyledDescription,
	StyledIcon,
	StyledInput,
	StyledLabel,
	StyledText,
	StyledTitle,
} from './styles';
import { useStyles } from './selectable-box.styles';
import { shouldTruncate } from './selectable-box.utils';
import { SelectableBoxProps } from './selectable-box.types';

export const SelectableBox: FunctionComponent<PropsWithChildren<SelectableBoxProps>> = props => {
	const {
		biId,
		boxWidth = ifFeature('encore', '320px', 'auto'),
		/* @startCleanup encore */
		children,
		classes: classesProp = {},
		className,
		/* @endCleanup encore */
		description,
		icon,
		inputId,
		inputProps,
		isChecked = false,
		isDisabled = false,
		truncated = false,
		name,
		onChange,
		title,
		type = 'radio',
		value,
	} = props;

	const { classes, cx } = useStyles({ boxWidth, isChecked, isDisabled });

	if (!children && !title) {
		throw new Error('must have a title or children to render');
	}

	/* @startCleanup encore */
	const styleProps: SelectableBoxStylesProps = {
		boxWidth,
		hasDescription: !!description,
		hasIcon: !!icon,
		isChecked,
		isDisabled,
		truncated,
		type,
	};

	const jadeJsx = (
		<StyledContainer className={classnames('SelectableBox', classesProp.root, className)} data-bi-id={biId} {...styleProps}>
			<StyledInput
				{...inputProps}
				checked={isChecked}
				className={classnames('SelectableBox__input', classesProp.input)}
				disabled={isDisabled}
				id={inputId}
				name={name}
				onChange={(event): void => {
					const {
						target: { name: targetName, value: targetValue },
					} = event;
					onChange(targetName, targetValue);
				}}
				readOnly={true}
				type={type}
				value={value || name}
			/>
			<StyledLabel {...styleProps} className={classesProp.label} htmlFor={inputId}>
				{children || (
					<StyledContent {...styleProps} className={classesProp.content}>
						{icon && (
							<StyledIcon
								aria-hidden={true}
								className={classnames('SelectableBox__icon', classesProp.icon)}
								{...styleProps}
							>
								{typeof icon === 'string' ? <Icon name={icon?.trim()} /> : icon}
							</StyledIcon>
						)}
						<StyledText {...styleProps} className={classesProp.text}>
							<StyledTitle className={classnames('SelectableBox__title', classesProp.title)} {...styleProps}>
								{title}
							</StyledTitle>

							{description && (
								<StyledDescription {...styleProps} className={classesProp.description}>
									{description}
								</StyledDescription>
							)}
						</StyledText>
					</StyledContent>
				)}
			</StyledLabel>
		</StyledContainer>
	);
	/* @endCleanup encore */

	let iconTileVariant: IconTileProps['variant'];
	if (isDisabled) {
		iconTileVariant = 'muted';
	} else if (isChecked) {
		iconTileVariant = 'primary';
	}

	const truncateTitle = shouldTruncate(truncated, 'title');
	const truncateDescription = shouldTruncate(truncated, 'description');

	return ifFeature(
		'encore',
		<label
			className={cx(classes.root, { [classes.checked]: isChecked, [classes.disabled]: isDisabled })}
			data-bi-id={biId}
			htmlFor={inputId}
		>
			<div className={classes.inner}>
				<input
					{...inputProps}
					checked={isChecked}
					className={classes.input}
					disabled={isDisabled}
					id={inputId}
					name={name}
					onChange={(event): void => {
						const {
							target: { name: targetName, value: targetValue },
						} = event;
						onChange(targetName, targetValue);
					}}
					readOnly={true}
					type={type}
					value={value || name}
				/>
				<div className={classes.iconTile}>
					{icon && <IconTile icon={icon as IconV2Name} size={48} variant={iconTileVariant} />}
				</div>
				<div className={cx(classes.textWrapper, { [classes.truncatedTextWrapper]: truncateTitle || truncateDescription })}>
					<p className={cx(classes.title, { [classes.truncatedText]: truncateTitle })}>{title}</p>
					{description && (
						<p className={cx(classes.description, { [classes.truncatedText]: truncateDescription })}>{description}</p>
					)}
				</div>
				{type === 'checkbox' && (
					<div className={classes.checkbox}>
						<div className={classes.checkboxInner}>
							<IconV2 name="check-solid" size={12} />
						</div>
					</div>
				)}
			</div>
		</label>,
		jadeJsx
	);
};
