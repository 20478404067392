import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';

interface GetSectionLabelArgs<Datum> {
	arc: PieArcDatum<Datum>;
	pieKey: (arc: Datum) => string;
	total?: number;
}

export const getSectionLabel = <Datum>({ arc, pieKey, total }: GetSectionLabelArgs<Datum>): string => {
	const value = total !== undefined ? `${Math.round((arc.value / total) * 100)}%` : `${arc.value}`;
	const description = pieKey(arc.data);
	return `${value} ${description}`;
};
