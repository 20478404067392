import React from 'react';
import { CalendarPicker, CalendarPickerProps, CalendarPickerClasses } from '@mui/x-date-pickers';
import { DatePickerDate } from '~components/date-picker/types';
import { useStyles } from './calendar.styles';

interface CalendarProps extends Omit<CalendarPickerProps<DatePickerDate>, 'classes' | 'className'> {
	/**
	 * classes should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * @deprecated
	 */
	classes?: Partial<CalendarPickerClasses>;
	/**
	 * className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * @deprecated
	 */
	className?: string;
	weeksToShow: number;
}

export const Calendar = ({ componentsProps, weeksToShow, components, ...props }: CalendarProps) => {
	const { classes } = useStyles({ weeks: weeksToShow });
	return (
		<CalendarPicker
			{...props}
			className={classes.root}
			components={{
				LeftArrowButton: () => null,
				RightArrowButton: () => null,
				SwitchViewButton: () => null,
			}}
		/>
	);
};
