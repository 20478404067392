import { useMemo } from 'react';
import { FormFieldDefinition, useForm } from '../hook-form';
import { FormElement, FormInputElement } from '../render-form/types';

function isInputElement(element: FormElement): element is FormInputElement {
	return typeof element.name === 'string';
}

function getFieldDefinitions(formElements: Record<string, FormElement>): Array<FormFieldDefinition<FormElement>> {
	return Object.values(formElements).filter(isInputElement);
}

export function useFormController(formElements: Record<string, FormElement> = {}) {
	const fieldDefinitions = useMemo(() => getFieldDefinitions(formElements) || [], [formElements]);
	const formDefinition = useForm(fieldDefinitions);

	return formDefinition;
}
