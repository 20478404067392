import React, { FC, PropsWithChildren } from 'react';
import { uniqueId } from 'lodash';
import { Event } from './event/event';
import { HorizontalEvent } from './horizontalEvent';
import { useTimelineStyles } from './timeline.styles';
import { getPositionInTimeline } from './utils';
import { TimelineProps } from '../timeline';

export const Timeline: FC<PropsWithChildren<TimelineProps>> = props => {
	const { classes, color, items = [], orientation, size, eventGap = 4 } = props;
	const isHorizontal = orientation === 'horizontal';
	const { classes: timelineClasses, cx } = useTimelineStyles();
	return (
		<ul
			className={cx(timelineClasses.root, classes?.root, {
				[timelineClasses.horizontal]: isHorizontal,
			})}
		>
			{items.map((event, index) => {
				const id = event.id || uniqueId('step__');
				const positionInTimeline = getPositionInTimeline(index, items.length);
				return orientation === 'horizontal' ? (
					<HorizontalEvent
						classes={classes}
						color={color}
						index={index}
						key={id}
						positionInTimeline={positionInTimeline}
						size={size}
						{...event}
					/>
				) : (
					<Event
						color={color}
						eventGap={eventGap}
						index={index}
						key={id}
						positionInTimeline={positionInTimeline}
						timelineSize={size}
						{...event}
					/>
				);
			})}
		</ul>
	);
};
