import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<{ hasDivider?: boolean }>()((theme, params) => {
	const { palette, mixins } = theme;
	const { hasDivider } = params;

	return {
		root: {
			padding: ifFeature('encore', '0 24px 24px', hasDivider ? '0 24px 24px' : '12px 24px 24px'),
			'&:before': hasDivider
				? {
						borderTop: mixins.border(1, ifFeature('encore', palette.gray[200], palette.gray[300])),
						boxSizing: 'border-box',
						content: '""',
						display: 'block',
						marginBottom: ifFeature('encore', 24, 12),
					}
				: { display: 'none' },
		},
	};
});
