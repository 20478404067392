/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useRef } from 'react';
import { merge } from 'lodash';
import { FlatfileImporter as Flatfile, FlatfileResults, IDataHookResponse } from '@flatfile/react';
import { Scalar } from '@flatfile/adapter/build/main/interfaces';
import { FileImporterConfiguration, FileImporterCustomerRecord, FileImporterHandler } from './types';
import { useFileImporterContext } from './use-file-importer-context';
import { useFlatfileTheme } from './theme';
import { columnValidator } from './validation/column-validator';
import { recordValidator } from './validation/record-validator';
import { generateI18nOverrides } from './helpers/generateI18nOverrides';
import { FileImporterResults } from './types/file-importer-results';

/**
 * @deprecated FileImporter is deprecated and no longer functions. Use the DataGrid component instead.
 */
export const useFileImporter = (customer: FileImporterCustomerRecord, options: FileImporterConfiguration): FileImporterHandler => {
	const { licenseKey, validateColumn, validateRecord, labels } = useFileImporterContext();
	const theme = useFlatfileTheme();

	const {
		dataType,
		title,
		fields,
		labels: labelOverrides = {},
		needsRecordValidation,
		maxRecords,
		enableManualInput = false,
		displayEncoding = false,
	} = options;
	const jsonFields = JSON.stringify(fields);

	const flatFields = useMemo(() => {
		return fields.map(({ needsColumnValidation, referenceFields, ...field }) => field);
	}, [jsonFields]);

	const i18nOverrides = useMemo(() => {
		return generateI18nOverrides(merge(labels, labelOverrides));
	}, [JSON.stringify(labels), JSON.stringify(labelOverrides)]);

	const importer = useMemo(() => {
		const flatfileOptions = {
			type: dataType,
			title,
			fields: flatFields,
			disableManualInput: !enableManualInput,
			displayEncoding,
			maxRecords,
			i18nOverrides,
			theme,
		};

		return new Flatfile(licenseKey, flatfileOptions, customer);
	}, [dataType, title, enableManualInput, displayEncoding, theme, licenseKey, JSON.stringify(customer), jsonFields]);

	const allColumns = useRef<Record<string, [Scalar, number][]>>({});
	useMemo(() => {
		fields.forEach(field => {
			if (field.needsColumnValidation) {
				importer.registerFieldHook(field.key, async columnValues => {
					allColumns.current[field.key] = columnValues;

					const referenceValues = (field.referenceFields || []).reduce((acc, key) => {
						acc[key] = allColumns.current[key];
						return acc;
					}, {});

					return columnValidator(customer.userId, dataType, field.key, columnValues, referenceValues, validateColumn);
				});
			}
		});
	}, [dataType, importer, validateColumn, jsonFields]);

	useMemo(() => {
		if (!needsRecordValidation) return;
		importer.registerRecordHook((recordData, index, mode) => {
			if (mode === 'change') {
				return recordValidator(customer.userId, dataType, recordData, index, validateRecord);
			}
		});
	}, [dataType, importer, validateRecord, needsRecordValidation]);

	const handleImporterClose = () => {
		importer.close();
	};

	const handleRequestCorrectionsFromUser = (msg?: string, rowToUpdate?: IDataHookResponse[]): Promise<FlatfileResults> => {
		return importer.requestCorrectionsFromUser(msg || 'Could not import, please check the file and fix the errors.', rowToUpdate);
	};

	const handleImporterDisplayLoader = (loadingText?: string) => {
		importer.displayLoader(loadingText || 'Loading');
	};

	const importFile = async (loadingText?: string): Promise<FileImporterResults> => {
		const { data, batchId, createdAt, failureReason, fileName, rawOutput } = await importer.requestDataFromUser();

		handleImporterDisplayLoader(loadingText);

		return {
			type: dataType,
			data,
			recordCount: data.length,
			createdAt,
			externalId: batchId,
			failureReason,
			rawFileName: fileName,
			rawOutput,
		};
	};

	return { importFile, handleImporterClose, handleImporterDisplayLoader, handleRequestCorrectionsFromUser };
};
