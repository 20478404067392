import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles, dyeColors, ModeOptions } from '~styles';
import { SlidedownPortal } from '~components/slidedown';
import { FileDropperProps, UseFileDropperProps } from './file-dropper.types';
import { useFileDropper } from './use-file-dropper';

const useStyles = makeStyles()(({ borderRadiuses, mode, palette, spacing }) => ({
	root: ifFeature(
		'encore',
		{
			border: '2px dashed transparent',
			borderRadius: borderRadiuses[300],
			display: 'inline-block',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: spacing(2),
		},
		{
			border: '3px dashed transparent',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: spacing(1),
		}
	),
	dragging: {
		borderColor: palette.info.main,
	},
}));

export function FileDropper({ children, ...props }: FileDropperProps): JSX.Element {
	const { fileDropAttributes, isDragging, messageProps } = useFileDropper(props as UseFileDropperProps);

	const { classes, cx } = useStyles();

	return (
		<div className={cx(classes.root, { [classes.dragging]: isDragging })} {...fileDropAttributes}>
			<SlidedownPortal {...messageProps} />
			{children}
		</div>
	);
}
