import { useMemo } from 'react';
import { CurrencyItem } from '~components/currency-field';
import { CurrencyMenuItem } from '~components/currency-picker';
import { ItemGroupType } from '~components/menu';

interface CurrencyMenuSectionItem {
	items: Array<CurrencyMenuItem>;
	type: ItemGroupType;
	key: string;
}

const mostCommonCurrencyCount = 4;

function createCurrencyMenuSectionObject(type: ItemGroupType, key: string): CurrencyMenuSectionItem {
	return { items: [], type, key };
}

export const useMCUCurrencyItems = (currencyCollection: CurrencyItem[]) => {
	const currencyMenuItems = useMemo(() => {
		const menuItems = [createCurrencyMenuSectionObject('group', 'most_common'), createCurrencyMenuSectionObject('group', 'rest')];
		currencyCollection.forEach((currencyItem, index) => {
			const menuItemIndex = index < mostCommonCurrencyCount ? 0 : 1;
			menuItems[menuItemIndex].items.push({
				key: currencyItem.code,
				text: currencyItem.text || `${currencyItem.code} - ${currencyItem.name}`,
				value: currencyItem.code,
				disabled: currencyItem.disabled || false,
				data: currencyItem,
			});
		});

		return menuItems;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(currencyCollection)]);

	return currencyMenuItems;
};
