import { alpha, Theme } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { OutlinedInputProps } from './types';
import { heights as encoreHeights } from '~definitions/json/sizes.json';

// These objects are used for Jade styles
const xPadding = 1.25;
const heights = {
	teenie: 28,
	small: 32,
	medium: 34,
	large: 36,
	biggie: 42,
};
const yPadding = {
	teenie: 0.875,
	small: 0.9375,
	medium: 0.625,
	large: 0.625,
	biggie: 1.315,
};

// This function is used for Jade styles
const getRootBorder = (
	{ mixins, palette }: Theme,
	{ disabled, status, viewMode }: Pick<OutlinedInputProps, 'disabled' | 'status' | 'viewMode'>
) => {
	if (disabled) {
		return mixins.border(1, palette.grey[300]);
	}
	if (status) {
		return mixins.border(1, mixins.fieldStatusColor(status, palette));
	}
	if (viewMode) {
		return mixins.border(1, palette.grey[300]);
	}
	return mixins.border(1, palette.grey[400]);
};

// This function is used for Jade styles
const getRootHoverStyles = (
	{ mixins, palette }: Theme,
	{ classes, disabled, viewMode = false }: Pick<OutlinedInputProps, 'classes' | 'disabled' | 'viewMode'>
) => {
	if (classes && 'focused' in classes) {
		return {
			border: !disabled && !status && viewMode ? mixins.border(1, palette.grey[400]) : undefined,
		};
	}
	return {
		border: !disabled && !status && viewMode ? mixins.border(1, palette.grey[400]) : undefined,
		boxShadow: disabled ? 'none' : '0 1px 0 0 rgba(0, 0, 0, 0.05)',
	};
};

// This function is used for Jade styles
const getRootFocusStyles = (
	theme: Theme,
	{
		classes,
		disabled,
		status,
		focusRing = true,
		viewMode,
	}: Pick<OutlinedInputProps, 'classes' | 'disabled' | 'status' | 'focusRing' | 'viewMode'>
) => {
	const { constructs, mixins, palette } = theme;

	if (!focusRing) {
		return {};
	}
	if (classes && 'focused' in classes) {
		return {};
	}
	return ifFeature(
		'encore',
		{
			...mixins.inputOutline(theme, { disabled, focused: true, status, viewMode }),
			'&>.MuiInputAdornment-root': {
				fill: mixins.fieldStatusColor(status, palette, constructs.icon.primary.medium),
			},
		},
		{
			boxShadow: `${alpha(mixins.fieldStatusColor(status, palette, palette.primary.lighter), 0.25)} 0 0 0 2px`,
			borderColor: mixins.fieldStatusColor(status, palette, palette.primary.lighter),
			'&>.MuiInputAdornment-root': {
				fill: palette.primary.main,
			},
		}
	);
};

// This function is used for Encore styles
const getEncoreSizes = ({ typography }: Theme, multiline?: boolean) => {
	return {
		teenie: {
			fontSize: typography.small?.fontSize,
			lineHeight: typography.small?.lineHeight,
			maxHeight: multiline ? 'unset' : encoreHeights.teenie,
			minHeight: encoreHeights.teenie,
		},
		small: {
			fontSize: typography.small?.fontSize,
			lineHeight: typography.small?.lineHeight,
			maxHeight: multiline ? 'unset' : encoreHeights.small,
			minHeight: encoreHeights.small,
		},
		medium: {
			fontSize: typography.medium.fontSize,
			lineHeight: typography.medium.lineHeight,
			maxHeight: multiline ? 'unset' : encoreHeights.medium,
			minHeight: encoreHeights.medium,
		},
		large: {
			fontSize: typography.large.fontSize,
			lineHeight: typography.large.lineHeight,
			maxHeight: multiline ? 'unset' : encoreHeights.large,
			minHeight: encoreHeights.large,
		},
		biggie: {
			fontSize: typography.large.fontSize,
			lineHeight: typography.large.lineHeight,
			maxHeight: multiline ? 'unset' : encoreHeights.biggie,
			minHeight: encoreHeights.biggie,
		},
	};
};

// This function is used for Encore styles
const getSingleVariantBorderRadius = (theme: Theme, props: OutlinedInputProps): string => {
	const { borderRadiuses } = theme;
	const { multiline, size } = props;

	const defaultSizeToRadiusMap: Record<NonNullable<OutlinedInputProps['size']>, number> = {
		teenie: 400,
		small: 400,
		medium: 500,
		large: 600,
		biggie: 600,
	};

	return multiline ? (borderRadiuses[defaultSizeToRadiusMap[size]] as string) : borderRadiuses[1000];
};

export const useStyles = makeStyles<OutlinedInputProps>()((theme, props) => {
	const { borderRadiuses, mixins, mode, palette, spacing, transitions, typography } = theme;
	const { denseAdornment, disabled, endAdornment, multiline, size, startAdornment, status, variant, viewMode } = props;

	const getEncoreLeftPadding = () => {
		if (variant === 'single') {
			return startAdornment ? 1 : 2;
		}
		return startAdornment ? 1 : 1.5;
	};

	const startAdornmentPadding = () => {
		return variant === 'single' ? spacing(2) : spacing(1.5);
	};

	return ifFeature(
		'encore',
		{
			root: {
				backgroundColor: disabled ? palette.gray[50] : palette.common.white,
				borderRadius: variant === 'single' ? getSingleVariantBorderRadius(theme, props) : borderRadiuses[200],
				...mixins.inputOutline(theme, { disabled, status, viewMode }),
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				// when multiline, on Chrome on Mac with scroll bars always showing, scroll bar overflows corners without this
				// we only apply to multiline because it can cause issues in other contexts where we want overflow,
				// such as cutting off the focus ring of a button at the end of the input
				overflow: multiline ? 'clip' : undefined, // this handles the scrollbar overflow; clip causes issues when not multiline
				padding: 0,
				transition: transitions.create(['border', 'box-shadow']),
				'&:hover': {
					...mixins.inputOutline(theme, { disabled, focused: false, hover: true, status, viewMode }),
				},
				'&:focus, &:focus-within': getRootFocusStyles(theme, props),
				'&>.MuiInputAdornment-root': {
					fill: palette.gray[800],
					flexShrink: 0,
					transition: transitions.create(['fill']),
				},
				'&>.MuiInputAdornment-positionEnd': {
					paddingRight: denseAdornment ? 0 : spacing(2),
					margin: 0,
					// make border of button line up with border of input
					position: 'relative',
					right: -1,
				},
				'&>.MuiInputAdornment-positionStart': {
					paddingLeft: denseAdornment ? 0 : startAdornmentPadding(),
					margin: 0,
					// make border of button line up with border of input
					position: 'relative',
					left: -1,
				},
			},
			denseAdornment: {},
			input: {
				color: palette.gray[800],
				height: '100%',
				padding: spacing(1, endAdornment ? 1 : 2, 1, getEncoreLeftPadding()),
				position: 'relative',
				'&::placeholder': {
					color: palette.gray[600],
					opacity: 1,
				},
				'&.Mui-disabled': {
					color: palette.gray[600],
					WebkitTextFillColor: 'unset',
					'&::placeholder': {
						color: palette.gray[600],
						opacity: 1,
					},
				},
			},
			size: {
				...getEncoreSizes(theme, multiline)[size],
			},
			focused: {},
		},
		{
			root: {
				backgroundColor: palette.common.white,
				borderRadius: 2,
				border: getRootBorder(theme, props),
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				transition: transitions.create(['all']),
				'&:hover': getRootHoverStyles(theme, props),
				'&:focus-within': getRootFocusStyles(theme, props),
				'&>.MuiInputAdornment-root': {
					backgroundColor: palette.gray[100],
					height: heights[size] - 2,
					padding: spacing(0, ['large', 'biggie'].includes(size) ? 1 : 0.75),
					justifyContent: 'center',
					maxHeight: 'unset',
					fill: palette.gray[600],
				},
				'&>.MuiInputAdornment-positionEnd': {
					margin: 0,
				},
				'&>.MuiInputAdornment-positionStart': {
					margin: 0,
				},
			},
			denseAdornment: {
				'&.MuiInputBase-root>.MuiInputAdornment-root': {
					padding: 0,
				},
			},
			input: {
				boxSizing: 'border-box',
				height: 'unset',
				position: 'relative',
				paddingLeft: spacing(xPadding),
			},
			size: {
				fontSize: typography[size]?.fontSize,
				lineHeight: typography[size]?.lineHeight,
				minHeight: heights[size],
				maxHeight: multiline ? 'unset' : heights[size],
				paddingTop: spacing(yPadding[size]),
				paddingBottom: spacing(yPadding[size]),
			},
			focused: {
				// Needs to be implemented so we can pass it through
			},
		}
	);
});
