import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles, dyeColors, ModeOptions } from '~styles';
import { LabelProps } from './label.types';

export type LabelStyleProps = Pick<LabelProps, 'status' | 'disabled' | 'viewMode'>;

export const useLabelStyles = makeStyles<LabelStyleProps>({ name: 'Label' })((
	{ typography, spacing, palette, mode, mixins: { fieldStatusColor } },
	{ status = 'default', disabled, viewMode }
) => {
	const statusColor = status !== 'default' ? fieldStatusColor(status, palette) : palette.gray[800];

	let encoreStatusColor = palette.gray[900];
	if (disabled) {
		encoreStatusColor = palette.gray[600];
	} else if (status !== 'default') {
		encoreStatusColor = fieldStatusColor(status, palette);
	} else if (viewMode) {
		encoreStatusColor = palette.gray[700];
	}

	return ifFeature(
		'encore',
		{
			root: {
				color: mode === ModeOptions.Dye ? dyeColors.main : encoreStatusColor,
				fill: mode === ModeOptions.Dye ? dyeColors.main : encoreStatusColor,
				fontSize: typography.small?.fontSize,
				fontWeight: typography.fontWeightMedium,
				lineHeight: typography.small?.lineHeight,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				position: 'relative',
				transform: 'none',
				whiteSpace: 'wrap',
				'& > .MuiFormLabel-asterisk': {
					color: encoreStatusColor,
					fontSize: typography.small?.fontSize,
					fontWeight: typography.fontWeightMedium,
					lineHeight: typography.small?.lineHeight,
					marginLeft: '-3px',
				},
				'&.Mui-disabled': {
					color: encoreStatusColor,
				},
				'&.Mui-focused': {
					color: encoreStatusColor,
				},
				pointerEvents: 'auto',
			},
		},
		{
			root: {
				display: 'flex',
				alignItems: 'center',
				fontSize: typography.small.fontSize,
				whiteSpace: 'nowrap',
				lineHeight: typography.teenie.lineHeight,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				'& > svg': {
					marginRight: spacing(0.75),
				},
				'& > .MuiFormLabel-asterisk': {
					color: disabled ? palette.grey[600] : palette.primary.main,
					marginLeft: spacing(0.5),
				},
				'&.Mui-disabled': {
					color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.grey[600],
				},
				'&.Mui-focused': {
					color: mode === ModeOptions.Dye ? dyeColors.main : statusColor,
				},
				pointerEvents: 'auto',
				color: mode === ModeOptions.Dye ? dyeColors.main : statusColor,
				fill: mode === ModeOptions.Dye ? dyeColors.main : statusColor,
			},
		}
	);
});
