import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ spacing, mode }) => ({
	root: {
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		'ul&': {
			listStyleType: 'disc',
			paddingLeft: ifFeature('encore', spacing(1.5), spacing(3)),
			alignContent: 'center',
		},
		'ol&': {
			listStyleType: 'decimal',
			paddingLeft: ifFeature('encore', spacing(2.25), spacing(3)),
		},
	},
}));
