// eslint-disable-next-line no-use-before-define
import React, { forwardRef, ReactElement, RefObject, useContext, useImperativeHandle, useRef } from 'react';
import { CurrencyPicker } from '~components/currency-picker';
import { AmountInput } from './amount-input';
import { CurrencyInputProps } from './types';
import { useSelectedCurrency } from './hooks/use-selected-currency';
import { CurrencyContext } from '~components/currency-picker-provider';
import { currencyItems } from './currency-items';
import { useReadableContext } from '~components/readable';

/**
 * @deprecated Use CurrencyField component instead.
 */
export const CurrencyPickerField = forwardRef((props: CurrencyInputProps, ref: RefObject<{ focus: () => void }>): ReactElement => {
	const { isReadable } = useReadableContext();
	const { defaultCurrency, defaultCurrencyCollection, defaultDecimalChar, defaultThousandsSeparator } = useContext(CurrencyContext);
	const { id, viewMode = false, currencyCollection: initialCurrencyCollection } = props;
	let appliedProps = props;

	if (!initialCurrencyCollection && defaultCurrencyCollection) {
		appliedProps = { currencyCollection: defaultCurrencyCollection, ...props };
	}

	if (isReadable || viewMode) {
		const { currencyCollection, ...rest } = props;
		appliedProps = rest;
	}

	const {
		currencyCollection,
		inputNames,
		normalizeZeros = true,
		padFractionalZeros = true,
		decimalCharacter,
		thousandsSeparator,
		useUnmaskedValue = false,
		selectedCurrency,
		size,
		status,
		disabled,
		onSelectCurrency,
		variant,
		...rest
	} = appliedProps;

	const defaultCurrencyItem =
		currencyItems.find(item => {
			return item.code === defaultCurrency;
		}) || currencyItems[0];

	const currencyItemFromProps =
		typeof selectedCurrency === 'string' ? currencyCollection?.find(item => item.code === selectedCurrency) : selectedCurrency;

	const { selectedCurrencyItem, handleCurrencyCodeSelected } = useSelectedCurrency(
		currencyItemFromProps || defaultCurrencyItem,
		onSelectCurrency
	);

	const amountInputRef = useRef<HTMLInputElement>(null);

	useImperativeHandle(
		ref,
		() => {
			return {
				id,
				focus: () => {
					amountInputRef.current?.focus();
				},
			};
		},
		[id, amountInputRef]
	);

	return (
		<AmountInput
			currencyPicker={
				currencyCollection ? (
					<CurrencyPicker
						currencyCollection={currencyCollection || [selectedCurrencyItem]}
						disabled={disabled}
						name={inputNames.currency}
						onChange={handleCurrencyCodeSelected}
						size={size}
						status={status}
						value={selectedCurrencyItem.code}
						variant={variant}
					/>
				) : undefined
			}
			decimalCharacter={decimalCharacter || defaultDecimalChar}
			disabled={disabled}
			id={id}
			name={inputNames.amount}
			normalizeZeros={normalizeZeros}
			padFractionalZeros={padFractionalZeros}
			ref={amountInputRef}
			selectedCurrencyItem={selectedCurrencyItem}
			size={size}
			status={status}
			thousandsSeparator={thousandsSeparator || defaultThousandsSeparator}
			useUnmaskedValue={useUnmaskedValue}
			variant={variant}
			{...rest}
		/>
	);
});
