import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ borderRadiuses, constructs, mixins }) => {
	return {
		root: {
			borderRadius: ifFeature('encore', borderRadiuses[500]),
			backgroundColor: ifFeature('encore', mixins.alpha(constructs.surface.neutral.xxStrong, 0.2)),
			transform: ifFeature('encore', 'none'),
		},
	};
});
