export function hasValue(name: string, values: Record<string, unknown>) {
	const value = values[name];

	if (typeof value === 'string') {
		return value.trim().length > 0;
	}

	if (Array.isArray(value)) {
		return value.length > 0;
	}

	return typeof value === 'number' || !!value;
}
