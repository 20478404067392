import { useKeydown } from './use-keydown';
import { UseKeyboardSelectionConfig } from '../types';

/**
 * This hook triggers the `onSelect` callback when a key is pressed to select an item.
 * @param config Object containing the active index, keyboard target element to listen to, and the callback to invoke when an item is selected
 */
export const useKeyboardSelection = ({ activeIndex, onSelect, keyboardTarget = document }: UseKeyboardSelectionConfig): void => {
	useKeydown(keyboardTarget, event => {
		const isTypableInput =
			keyboardTarget instanceof HTMLInputElement && (keyboardTarget.type === 'text' || keyboardTarget.type === 'search');

		if (
			typeof activeIndex === 'number' &&
			typeof onSelect === 'function' &&
			(event.key === 'Enter' || (event.key === ' ' && !isTypableInput))
		) {
			onSelect(activeIndex);
		}
	});
};
