import React, { FC, PropsWithChildren } from 'react';
import co from 'classnames';

import { CheckboxHeaderProps } from './types';

export const CheckboxHeader: FC<PropsWithChildren<CheckboxHeaderProps>> = props => {
	const {
		biId,
		classes,
		checked,
		onToggleAll,
		headerVariant,
		stickyLeft,
		stickyHeader,
		stickyLeftBorder,
		stickyLeftScreen,
		left,
		stickyHeaderTop,
		uuid,
	} = props;
	const compiledId = `fabricTableCheckboxHeader-${uuid}`;

	const classNames = co({
		[classes.header]: true,
		[classes.stickyTop]: stickyHeader,
		[classes.stickyLeft]: stickyLeft,
		[classes.stickyLeftBorder]: stickyLeftBorder,
		[classes.stickyLeftScreen]: stickyLeftScreen,
		[classes.minimalRightPadding]: true,
		[classes.whiteHeaderVariant]: headerVariant === 'white',
	});

	const inlineStyles = {
		left,
		top: stickyHeaderTop || undefined,
		width: '36px',
	};

	return (
		<th className={classNames} key={compiledId} style={inlineStyles}>
			<div
				style={{
					marginTop: '2px',
					width: `16px`,
				}}
			>
				<div className="fab-Checkbox">
					<input
						aria-label="Select all rows"
						checked={Boolean(checked)}
						className="fab-Checkbox__input"
						data-bi-id={biId && `${biId}-select-all`}
						id={compiledId}
						onClick={e => {
							onToggleAll();
						}}
						readOnly={true}
						type="checkbox"
					/>
					<span className="fab-Checkbox__box" />
					<label
						aria-label={window.jQuery ? $.__('Select all rows') : 'Select all rows'}
						className="fab-Checkbox__label"
						htmlFor={compiledId}
					/>
				</div>
			</div>
		</th>
	);
};
