// eslint-disable-next-line no-use-before-define
import React, { Component, ReactElement } from 'react';
import SlidedownTransition from '../transition';
import SimpleSlidedown from './simple';
import { checkForAutoCloseInClass } from './helpers';
import { TYPES } from './constants';
import { SlidedownProps, SlidedownState } from '../types';

class Slidedown extends Component<SlidedownProps, SlidedownState> {
	constructor(props: SlidedownProps) {
		super(props);

		// eslint-disable-next-line react/state-in-constructor
		this.state = {
			visible: props.showOnMount !== false,
		};
	}

	_handleVisible = (): void => this.setState(prevState => ({ visible: !prevState.visible }));

	runAutoClose = -1;

	componentDidMount = (): void => checkForAutoCloseInClass(this);

	componentDidUpdate = (): void => checkForAutoCloseInClass(this);

	render(): ReactElement {
		const { action, description, message, type } = this.props;
		const { visible } = this.state;

		return (
			<SlidedownTransition appear={true} show={visible}>
				<SimpleSlidedown
					action={action}
					description={description}
					message={message}
					onDismiss={this._handleVisible}
					show={visible}
					showDismiss={type !== TYPES.success}
					type={type}
				/>
			</SlidedownTransition>
		);
	}
}

export default Slidedown;
