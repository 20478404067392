import React, { useEffect, useRef, ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { DropdownCaret9x5, CaretRight5x9, PlusArrow21x16 } from '@bamboohr/grim';
import { IconV2 } from '~components/icon-v2';
import { TransferListCategoryProps } from '../../types';
import { getTabIndex } from '../../etc';
import { useStyles } from './styles';
import { TransferListHoverItem } from '../transfer-list-item';
import { TransferListSubcategory } from '../transfer-list-subcategory';

export function TransferListCategory(props: TransferListCategoryProps): ReactElement {
	const {
		displayOrderIndex,
		expanded,
		focusedIndex,
		items,
		name,
		onClick,
		onToggleExpand,
		subcategories,
		transferListItemProps,
		transferListSide,
	} = props;

	const categoryRef = useRef<HTMLDivElement>(null);

	const { classes } = useStyles(transferListItemProps);

	useEffect(() => {
		if (categoryRef?.current && displayOrderIndex === focusedIndex) {
			categoryRef.current.focus();
		}
	}, [categoryRef, displayOrderIndex, focusedIndex]);

	const handleKeydown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === ' ' || evt.key === 'Enter') {
			evt.preventDefault();
			onToggleExpand(!expanded);
		}
	};

	const renderLooseItems = () => {
		return items.map(item => {
			const { children, displayOrderIndex, title, value } = item;
			return (
				<TransferListHoverItem
					{...transferListItemProps}
					disabled={item.disabled}
					icon={ifFeature('encore', <IconV2 name="arrow-right-solid" size={20} />, <PlusArrow21x16 />)}
					isFocused={focusedIndex === displayOrderIndex}
					key={item.rowKey}
					listIndex={displayOrderIndex || -2}
					onClick={onClick}
					rightSide={false}
					rowKey={item.rowKey}
					tabIndex={getTabIndex(focusedIndex, displayOrderIndex)}
					title={title}
					value={value}
				>
					{children}
				</TransferListHoverItem>
			);
		});
	};

	const renderSubcategories = (): ReactElement[] | undefined => {
		if (subcategories) {
			return subcategories.map(subcategory => {
				const { name, grouped = false, items, index, displayOrderIndex } = subcategory;
				return (
					<TransferListSubcategory
						focusedIndex={focusedIndex}
						grouped={grouped}
						icon={ifFeature('encore', <IconV2 name="arrow-right-solid" size={20} />, <PlusArrow21x16 />)}
						index={index}
						isFocused={focusedIndex === displayOrderIndex}
						items={items}
						key={name}
						name={name}
						onClick={onClick}
						subcategoryClasses={props.subcategoryClasses}
						tabIndex={getTabIndex(focusedIndex, displayOrderIndex)}
						transferListItemProps={transferListItemProps}
						transferListSide={transferListSide}
					/>
				);
			});
		}
		return undefined;
	};

	const kebabCategoryName = name.replace(' ', '-');
	const categoryId = `transfer-list-category-${kebabCategoryName}`;
	const categoryContentsId = `transfer-list-category-${kebabCategoryName}-contents`;
	return (
		<>
			<div
				aria-controls={categoryContentsId}
				aria-expanded={expanded}
				className={classes.root}
				data-isCategory={true}
				id={categoryId}
				onClick={() => onToggleExpand(!expanded)}
				onKeyDown={handleKeydown}
				ref={categoryRef}
				role="button"
				tabIndex={getTabIndex(focusedIndex, displayOrderIndex)}
			>
				<div className={classes.container}>
					<div className={`${classes.leftContent} ${classes.centerHorizontal}`}>
						{expanded
							? ifFeature(
									'encore',
									<IconV2 name="angle-down-regular" size={16} />,
									<DropdownCaret9x5 className={classes.expandedDropdown} />
								)
							: ifFeature('encore', <IconV2 name="angle-right-regular" size={16} />, <CaretRight5x9 />)}
						<div className={classes.title}>{name}</div>
					</div>
				</div>
			</div>
			<div aria-labelledby={categoryId} className={classes.items} id={categoryContentsId} role="listbox">
				{expanded && renderSubcategories()}
				{expanded && renderLooseItems()}
			</div>
		</>
	);
}
