import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles<
	void,
	'active' | 'disabled' | 'error' | 'hideIcon' | 'iconWrapper' | 'success' | 'title' | 'note'
>()(({ mixins, palette, spacing, typography, constructs, borderRadiuses }, _props, classes) => {
	return {
		root: {
			borderRadius: ifFeature('encore', borderRadiuses[400], '4px'),
			listStyleType: 'none',
			[`&:hover:not(.${classes.disabled})`]: {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite, palette.common.white),
				/* @startCleanup encore */
				[`&:not(.${classes.active}):not(.${classes.error}):not(.${classes.success}) .${classes.iconWrapper}`]: {
					backgroundColor: palette.gray[100],
				},
				/* @endCleanup encore */
			},
		},
		button: {
			alignItems: 'center',
			backgroundColor: 'transparent',
			border: 0,
			borderRadius: '4px',
			cursor: 'pointer',
			display: 'flex',
			fontFamily: 'inherit',
			gap: spacing(1.5),
			height: '100%',
			outline: 'transparent',
			padding: ifFeature('encore', spacing(2), `${spacing(1)} ${spacing(1.5)}`),
			width: '100%',

			/* @startCleanup encore */
			'&:focus': {
				boxShadow: ifFeature('encore', undefined, `0 0 0 2px ${mixins.alpha(palette.primary.lightest, 0.35)}`),
			},
			/* @endCleanup encore */
			'&:disabled': {
				cursor: 'default',
			},
		},
		/* @startCleanup encore */
		iconWrapper: {
			alignItems: 'center',
			border: `2px solid ${palette.gray[700]}`,
			borderRadius: '50%',
			boxSizing: 'border-box',
			display: 'flex',
			fill: palette.gray[700],
			flexShrink: 0,
			height: '28px',
			justifyContent: 'center',
			width: '28px',
		},
		/* @endCleanup encore */
		title: {
			color: ifFeature('encore', constructs.text.neutral.strong, palette.gray[900]),
			flex: '1 1 auto',
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightMedium,
			lineHeight: typography.large.lineHeight,
			textAlign: 'start',
		},
		note: {
			color: ifFeature('encore', constructs.text.neutral.strong, palette.gray[700]),
			flex: '0 1 auto',
			fontSize: typography.teenie.fontSize,
			lineHeight: typography.teenie.lineHeight,
		},
		active: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite, palette.common.white),
			/* @startCleanup encore */
			[`& .${classes.iconWrapper}`]: {
				borderColor: palette.primary.main,
			},
			/* @endCleanup encore */
			[`& .${classes.title}`]: {
				color: ifFeature('encore', constructs.text.primary.strong, palette.primary.main),
				fontWeight: typography.fontWeightBold,
			},
		},
		disabled: {
			/* @startCleanup encore */
			[`& .${classes.iconWrapper}`]: {
				backgroundColor: 'transparent',
				borderColor: palette.gray[600],
				fill: palette.gray[600],
			},
			/* @endCleanup encore */
			[`& .${classes.title}`]: {
				color: ifFeature('encore', constructs.text.neutral.xWeak, palette.gray[700]),
			},

			[`& .${classes.note}`]: {
				color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[700]),
			},
		},
		error: {
			/* @startCleanup encore */
			[`& .${classes.iconWrapper}`]: {
				backgroundColor: palette.common.white,
				border: 'none',
				fill: palette.error.main,
			},
			/* @endCleanup encore */
			[`& .${classes.title}`]: {
				color: ifFeature('encore', constructs.text.error.strong, palette.error.main),
				fontWeight: ifFeature('encore', undefined, typography.fontWeightSemibold),
			},
			/* @startCleanup encore */
			[`& .${classes.hideIcon}`]: {
				backgroundColor: 'transparent',
				border: `2px solid ${palette.error.main}`,
			},
			/* @endCleanup encore */
		},
		/* @startCleanup encore */
		hideIcon: {},
		/* @endCleanup encore */
		success: {
			/* @startCleanup encore */
			[`& .${classes.iconWrapper}`]: {
				backgroundColor: palette.primary.main,
				borderColor: palette.primary.main,
				fill: palette.common.white,
			},
			/* @endCleanup encore */
			[`& .${classes.title}`]: {
				color: ifFeature('encore', constructs.text.primary.strong, palette.gray[1000]),
				fontWeight: ifFeature('encore', undefined, typography.fontWeightSemibold),
			},
		},
	};
});
