import { getFileTypeFromExt } from '@bamboohr/utils/lib/file/file-type';

// @startCleanup encore
import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import isString from 'lodash/isString';
import { FileType, IconSize, IconType } from './types';
import { DEFAULT_ICON_SIZE, ICON_SIZES } from './file-icon.const';
import type { Palette, Theme } from '@mui/material';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
// @endCleanup encore

// @startCleanup encore
/**
 * Instance of DevLogger for file-icon
 */
export const fileIconLogger = createLogger('@fabric/file-icon | <FileIcon />');

export function getIconFillFromFileType(fileType: FileType): string {
	/* doc, esig, excel, image, link, mail, pdf, powerpoint, text, unknown, zip, */
	let iconFill: string;
	switch (fileType) {
		case 'doc':
			iconFill = 'doc';
			break;
		case 'esig':
			iconFill = 'esig';
			break;
		case 'xls':
			iconFill = 'excel';
			break;
		case 'img':
			iconFill = 'image';
			break;
		case 'webpage':
			iconFill = 'link';
			break;
		case 'email':
			iconFill = 'mail';
			break;
		case 'pdf':
			iconFill = 'pdf';
			break;
		case 'ppt':
			iconFill = 'powerpoint';
			break;
		case 'txt':
			iconFill = 'text';
			break;
		case 'archive':
			iconFill = 'zip';
			break;
		default:
			iconFill = 'unknown';
			fileIconLogger.warn(`fileType of "${fileType}" invalid, defaulting to "unknown"`);
			break;
	}
	return iconFill;
}

/**
 * Get the Fabric icon name from the given file name
 *
 * @param  {string} fileType The file type
 *
 * @returns {string} The icon name for the type of file given
 */
export function getIconNameFromFileType(fileType: FileType): string {
	const iconType = getIconTypeFromFileType(fileType);
	return `fab-file-type-${iconType}`;
}

/**
 * Get the combined icon name and size based off the requested icon name and size
 *
 * @param {string} fileType Name of the icon to use
 * @param {string} size Size of the icon request teenie, small, medium, or large (defaults to "teenie")
 *
 * @returns {string} Returns concatenated string of icon name and size
 */
export function getIconNameFromFileTypeAndSize(fileType: FileType, size: IconSize): string {
	const iconName = getIconNameFromFileType(fileType);
	const iconDimensions = getIconDimensions(size);
	return `${iconName}-${iconDimensions}`;
}

/**
 * Gets the icon dimensions requested if valid (mapped or custom)
 *
 * @param {string} size Size of the icon request teenie, small, medium, or large  (defaults to "teenie")
 *
 * @returns {string} Returns size of valid mapped size
 */
export function getIconDimensions(size: IconSize): string {
	const sizes = Object.keys(ICON_SIZES);
	if (!isString(size) || sizes.indexOf(size) === -1) {
		size = DEFAULT_ICON_SIZE;
	}
	const iconSize = ICON_SIZES[size];
	return iconSize;
}

/**
 * Get the Fabric icon name from the given file name
 *
 * @param  {string} fileType The file type
 *
 * @returns {string} The icon name for the type of file given
 */
export function getIconTypeFromFileType(fileType: FileType): IconType {
	/* GOTCHA: These types map to those of the FE-Utils package to get the filetype from the name/ext */
	let iconType: IconType;
	switch (fileType) {
		case 'doc':
		case 'pdf':
		case 'text':
		case 'txt':
			iconType = 'text';
			break;
		case 'excel':
		case 'xls':
			iconType = 'excel';
			break;
		case 'powerpoint':
		case 'ppt':
			iconType = 'powerpoint';
			break;
		case 'email':
		case 'mail':
			iconType = 'mail';
			break;
		case 'webpage':
		case 'link':
			iconType = 'link';
			break;
		case 'archive':
		case 'zip':
			iconType = 'zip';
			break;
		case 'image':
		case 'img':
			iconType = 'image';
			break;
		case 'esig':
			iconType = 'esig';
			break;
		default:
			iconType = 'unknown';
			fileIconLogger.warn(`fileType of "${fileType}" invalid, defaulting to "unknown"`);
			break;
	}
	return iconType;
}

/**
 * Checks if a given value is nil (null, undefined) or empty according to lodash.isNil and lodash.isEmpty
 *
 * @param {any} value Value to be checked if null, undefined, or empty (string, array, etc).
 *
 * @returns {boolean} Returns boolean representing if value is null, undefined, or empty
 */
export function isNilOrEmpty(value: unknown): boolean {
	return isNil(value) || isEmpty(value);
}

/**
 * Checks if a given file name or type would yield a type unknown icon
 *
 * @param {string} value Value to check type of
 *
 * @returns {boolean} Returns boolean representing icon type results in 'unknown'
 */
export function isUnknownIconType(value: string): boolean {
	if (value === 'esig') {
		return false;
	}
	const iconType = getFileTypeFromExt(value);
	return iconType === 'default';
}
// @endCleanup encore

// `getFileTypeFromExt` is meant to work with file extensions, but we have several file "types" that are not valid file extensions. We can't just remove them without throwing errors in existing code, so we handle them separately.
const validFileTypesThatAreNotValidFileExtensions = [
	'archive',
	'email',
	'excel',
	'image',
	'img',
	'link',
	'mail',
	'powerpoint',
	'text',
	'webpage',
];

/**
 * Coerces a single file type from various props
 */
export function getFileType({ esig, filename, type }: { esig?: boolean; filename?: string; type?: FileType }): FileType {
	if (esig || type === 'esig') {
		return 'esig';
	}
	// `default` is returned by `getFileTypeFromExt` when the file type is unknown, but if the type is explicitly set to `default`, we should return it as is
	if (type === 'default') {
		return 'default';
	}
	if (type && validFileTypesThatAreNotValidFileExtensions.includes(type)) {
		return type;
	}
	if (type) {
		const mappedType = getFileTypeFromExt(type);
		if (mappedType !== 'default') {
			return mappedType;
		}
	}
	return filename ? getFileTypeFromExt(filename) : 'default';
}

export function getFileIconColor(fileType: FileType, palette: Palette) {
	switch (fileType) {
		case 'doc':
			return '#2076BB';
		case 'xls':
		case 'excel':
			return '#3AA837';
		case 'pdf':
			return '#C32A2F';
		case 'powerpoint':
		case 'ppt':
			return '#C26C1A';
		case 'esig':
			return '#87328F';
		default:
			return palette.gray[600];
	}
}
