import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useTileStyles = makeStyles<void, 'primary' | 'placeholder'>({ name: 'Tile' })(
	({ palette, borderRadiuses, mixins, constructs, mode }: Theme, _props, classes) => ({
		root: {
			transition: 'background-color 0.15s, border-color 0.15s ease-in-out',
			borderRadius: ifFeature('encore', borderRadiuses[400], 8),
			borderWidth: ifFeature('encore', 1, 2),
			boxSizing: 'border-box', // inner border
			borderStyle: 'solid',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		primary: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[100]),
			borderColor: ifFeature('encore', constructs.border.neutral.xWeak, palette.gray[100]),
			boxShadow: ifFeature('encore', mixins.boxShadow('300', palette.gray[900])),
			'&:hover, &:focus, &:focus-within': ifFeature(
				'encore',
				{},
				{
					backgroundColor: palette.gray[200],
					borderColor: palette.gray[200],
				}
			),
		},
		secondary: {
			backgroundColor: 'none',
			borderColor: ifFeature('encore', constructs.border.neutral.weak, palette.gray[200]),
			boxShadow: ifFeature('encore', mixins.boxShadow('300', palette.gray[900])),
		},
		placeholder: {
			borderColor: palette.gray[300],
			borderStyle: 'dashed',
			'&:hover, &:focus, &:focus-within': ifFeature(
				'encore',
				{},
				{
					borderColor: palette.gray[400],
				}
			),
		},
		active: ifFeature(
			'encore',
			{},
			{
				[`&.${classes.primary}`]: {
					backgroundColor: palette.gray[200],
					borderColor: palette.gray[200],
				},
				[`&.${classes.placeholder}`]: {
					borderColor: palette.gray[400],
				},
			}
		),
	})
);
