import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { HoverSettings } from './types';
import { HoverWrapper } from '../hover-wrapper';
import { Button } from '~components/button';

export interface HoverActionProps {
	hover: HoverSettings;
	children: ReactElement;
}

export const HoverAction: FunctionComponent<PropsWithChildren<HoverActionProps>> = props => {
	const {
		children,
		hover: { className, handleHoverAction, text },
	} = props;

	return (
		<HoverWrapper component={children} hasIcon={false}>
			<Button className={className} onClick={handleHoverAction} type="button">
				{text}
			</Button>
		</HoverWrapper>
	);
};
