import { createContext } from 'react';
import { FileUploadContext } from './types';

export const FilesContext = createContext<FileUploadContext>({
	acceptedTypes: [],
	allFileIds: [],
	canRename: false,
	canSelectMultiple: false,
	progressByFileId: {},
	filesById: {},
	isDisabled: false,
	pendingFileIds: [],
	removeFile: () => null,
	renameFile: () => null,
	upload: () => null,
});
