import { StatusOptions } from '../data-grid.types';

export const getStatusIconLabel = (status: StatusOptions) => {
	switch (status) {
		case 'error':
			return window.jQuery ? $.__('error') : 'error';
		case 'warning':
			return window.jQuery ? $.__('warning') : 'warning';
		case 'info':
			return window.jQuery ? $.__('info') : 'info';
		default:
			return '';
	}
};
