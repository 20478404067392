import { memoize } from 'lodash';

const MEASUREMENT_ELEMENT_ID = '__react_svg_text_measurement_id';

interface GetSvgTextRectOptions {
	className: string;
	style: Record<string, unknown>;
}

/**
 * Function that is used to get the svg styled height and width of text
 * Originally was going to use visx-text/src/util/getStringWidth.ts, but it only returns the width
 * @param str Text string to find width of
 * @param options? Optional parameters to style the text
 * @param options?.className? Optional className for svg text
 * @param options?.style? Optional
 * @returns SVGRect of styled text (x, y, width, height)
 */
function getSvgTextRect(str: string, options?: Partial<GetSvgTextRectOptions>): SVGRect | null {
	try {
		// Calculate length of each word to be used to determine number of words per line
		let textEl = document.getElementById(MEASUREMENT_ELEMENT_ID) as SVGTextElement | null;
		if (!textEl) {
			const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
			svg.setAttribute('aria-hidden', 'true');
			svg.style.width = '0';
			svg.style.height = '0';
			svg.style.position = 'absolute';
			svg.style.top = '-100%';
			svg.style.left = '-100%';
			textEl = document.createElementNS('http://www.w3.org/2000/svg', 'text');
			textEl.setAttribute('id', MEASUREMENT_ELEMENT_ID);
			svg.appendChild(textEl);
			document.body.appendChild(svg);
		}

		if (options?.className) {
			textEl.classList.add(options.className);
		}
		if (options?.style) {
			Object.assign(textEl.style, options?.style);
		}
		textEl.textContent = str;

		return textEl.getBBox();
	} catch (e) {
		return null;
	}
}

export default memoize(getSvgTextRect, (str: string, style) => `${str}_${JSON.stringify(style)}`);
