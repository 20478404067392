import React from 'react';
import { TruncateV2Props } from './truncate-v2.types';
import { useStyles } from './truncate-v2.styles';

export const TruncateV2 = (props: TruncateV2Props) => {
	const { id, verticalAlign, width = 'auto', children } = props;
	const { classes } = useStyles({ verticalAlign, width });

	return (
		<span className={classes.root} id={id}>
			{children}
		</span>
	);
};
