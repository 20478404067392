import { Dispatch, KeyboardEvent } from 'react';

type Setter = Dispatch<number | ((prevIndex: number) => number)>;

export const handleListNavigation = (evt: KeyboardEvent<HTMLDivElement>, focusedIndex: number, setFocusedIndex: Setter) => {
	const listItemLength = evt.currentTarget.querySelectorAll(
		'[data-isCategory="true"], [role="option"]:not([data-focus-disabled="true"])'
	).length;

	if (evt.key === 'ArrowDown') {
		evt.preventDefault();
		// We don't want the zeroth list item to actually focus when the
		// component mounts, but it looks focused when its tabbed to.
		// This avoids having to press down twice to get to the next list item
		if (focusedIndex === -1) {
			setFocusedIndex(1);
		} else if (focusedIndex < listItemLength - 1) {
			setFocusedIndex(focusedIndex + 1);
		}
	} else if (evt.key === 'ArrowUp') {
		evt.preventDefault();
		// When the last item in the list is moved, the new last item gets
		// the tabindex but isn't actually focused, like we're doing with
		// the zeroth item on load
		if (focusedIndex >= listItemLength) {
			setFocusedIndex(listItemLength - 2);
		} else if (focusedIndex > 0) {
			setFocusedIndex(focusedIndex - 1);
		}
	} else if (evt.key === 'Home') {
		evt.preventDefault();
		setFocusedIndex(0);
	} else if (evt.key === 'End') {
		evt.preventDefault();
		setFocusedIndex(listItemLength - 1);
	}
};
