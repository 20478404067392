import { OPPOSITE_DIRECTIONS } from '../../constants';
import { Directions } from '../../enums';

export function flipHorizontally(locations) {
	const anchorHorizontalPlacement = OPPOSITE_DIRECTIONS[locations.anchor.horizontal] || locations.anchor.horizontal;
	const vesselVerticalPlacement = OPPOSITE_DIRECTIONS[locations.vessel.horizontal] || locations.vessel.horizontal;

	return {
		anchor: { ...locations.anchor, horizontal: anchorHorizontalPlacement },
		vessel: { ...locations.vessel, horizontal: vesselVerticalPlacement },
	};
}

export function shouldFlipHorizontally(collisions) {
	const leftCollision = collisions.includes(Directions.LEFT);
	const rightCollision = collisions.includes(Directions.RIGHT);

	return (leftCollision && !rightCollision) || (rightCollision && !leftCollision);
}
