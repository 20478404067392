// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Accordion } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { AccordionBaseProps } from './accordion.types';

const useStyles = makeStyles<Partial<AccordionBaseProps>>()(({ borderRadiuses, constructs, mixins, mode, spacing }, props) => ({
	root: {
		backgroundColor: 'transparent',
		// needed so focus state doesn't move the accordion when it adds a border
		border: ifFeature('encore', '1px solid transparent'),
		borderRadius: ifFeature('encore', borderRadiuses[300]),
		boxShadow: 'none',
		boxSizing: 'border-box',
		'&:before': {
			display: 'none',
		},
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		/* @startCleanup encore */
		overflow: ifFeature('encore', undefined, props.hideDescriptionOnCollapse ? 'hidden' : undefined),
		/* @endCleanup encore */
		'& + &': {
			marginTop: ifFeature('encore', spacing(2)),
		},
		// Override the disabled backgroundColor from MUI
		'&.Mui-disabled': {
			backgroundColor: ifFeature('encore', 'transparent'),
		},
		'&:has(.MuiAccordionSummary-root.Mui-focusVisible)': {
			borderColor: ifFeature('encore', constructs.border.primary.medium),
			boxShadow: ifFeature('encore', `0 0 0 4px ${mixins.alpha(constructs.border.primary.medium, 0.1)}`),
		},
	},
	disabled: {},
	expanded: {},
}));

export function AccordionBase({
	classes: classesProp,
	containerRef,
	hideDescriptionOnCollapse,
	...rest
}: AccordionBaseProps): JSX.Element {
	const { classes } = useStyles({ hideDescriptionOnCollapse }, { props: { classes: classesProp } });
	return <Accordion classes={classes} ref={containerRef} {...rest} />;
}
