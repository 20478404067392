import { useFormWatch } from '.';
import { UseFormProps, Path, FieldValues } from '../hook-form';
import { FormConfig } from '../render-form/types';
import { getValuesByToken } from './etc/get-values-by-token';

export type UseFormTokenWatchCallback = (token: string, value: unknown, data: Record<string, unknown>) => void;

export function useFormTokenWatch<T extends FieldValues>(
	callback: UseFormTokenWatchCallback,
	controller: UseFormProps<T>,
	formConfig?: FormConfig
) {
	useFormWatch(controller, (name, value, data) => {
		if (!formConfig) {
			return;
		}

		const { formElements = {} } = formConfig;
		const { token } = formElements[name as Path<T>] || {};

		if (token) {
			callback(token, value, getValuesByToken(formConfig, data));
		}
	});
}
