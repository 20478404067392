export const Directions = Object.freeze({
	BOTTOM: 'bottom',
	CENTER: 'center',
	LEFT: 'left',
	MIDDLE: 'middle',
	RIGHT: 'right',
	TOP: 'top',
});

export const CollisionStrategies = Object.freeze({
	CONSTRAIN: 'constrain',
	CUSTOM: 'custom',
	FLIP: 'flip',
	SLIDE: 'slide',
});
