import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type ToggleSizeOptions = {
	toggle: string;
	handle: string;
	fontSize: 'small' | 'medium' | 'large' | 'biggie';
	lineHeight: 'small' | 'medium' | 'large' | 'biggie';
};
type ToggleSizes = {
	small: ToggleSizeOptions;
	medium: ToggleSizeOptions;
	large: ToggleSizeOptions;
};

export const useStyles = makeStyles<
	{
		size?: string;
		isDisabled?: boolean;
		isView?: boolean;
	},
	'toggle' | 'label' | 'handle'
>()((theme, _params, classes) => {
	const { palette, typography, mode, borderRadiuses } = theme;
	const { size, isDisabled, isView } = _params;
	const { label, handle } = classes;

	const sizes: ToggleSizes = {
		small: {
			toggle: '32px',
			handle: '26px',
			fontSize: 'small',
			lineHeight: 'small',
		},
		medium: {
			toggle: ifFeature('encore', '40px', '34px'),
			handle: ifFeature('encore', '34px', '28px'),
			fontSize: ifFeature('encore', 'medium', 'large'),
			lineHeight: ifFeature('encore', 'medium', 'biggie'),
		},
		large: {
			toggle: '48px',
			handle: '42px',
			fontSize: ifFeature('encore', 'large', 'large'),
			lineHeight: ifFeature('encore', 'large', 'large'),
		},
	};

	/**
	 * Encore has 3 sizes but jade only had 2, one named 'regular which will map to 'medium'.
	 * Map these jade sizes to their corresponding encore sizes while preserving the jade implementation
	 */
	const toggleSize: ToggleSizeOptions = sizes[size === 'regular' ? 'medium' : size || 'medium'];
	const duration = '200ms';
	const bezier = 'cubic-bezier(0.25, 0.1, 0.25, 1)';

	let labelColor = palette.gray[700];
	if (isDisabled) labelColor = palette.gray[500];
	if (isView) labelColor = palette.gray[600];

	let handleBackgroundColor = palette.primary.main;
	if (isDisabled) handleBackgroundColor = palette.gray[400];
	if (isView) handleBackgroundColor = palette.gray[600];
	if (mode === ModeOptions.Dye) handleBackgroundColor = dyeColors.main;

	return {
		toggle: {
			boxSizing: 'border-box',
			color: isDisabled || isView ? palette.gray[500] : 'inherit',
			position: 'relative',
			backgroundColor: palette.common.white,
			border: `1px solid ${isDisabled || isView ? palette.gray[300] : palette.gray[400]}`,
			boxShadow: isDisabled ? 'none' : '0 1px 0 0 rgba(0, 0, 0, 0.05)',
			display: 'inline-flex',
			height: toggleSize.toggle,
			minWidth: '64px',
			width: 'auto',
			borderRadius: ifFeature('encore', borderRadiuses[200], '2px'),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: '0 3px',
			pointerEvents: isDisabled || isView ? 'none' : 'auto',
			userSelect: 'none',
			'&:active, &:focus-within': {
				boxShadow: ifFeature(
					'encore',
					`0 0 0 1px ${mode === ModeOptions.Dye ? dyeColors.main : palette.primary.light},
					 0 0 0 4px ${mode === ModeOptions.Dye ? dyeColors.main : palette.primary.lightest}
					`,
					`0 0 0 2px ${mode === ModeOptions.Dye ? dyeColors.main : palette.primary.lightest}`
				),
				outline: 0,
				borderColor: palette.primary.lighter,
			},
		},
		label: {
			boxSizing: 'border-box',
			textAlign: 'center',
			textTransform: 'capitalize',
			fontFamily: typography.fontFamily,
			color: palette.gray[700],
			cursor: isDisabled ? 'default' : 'pointer',
			fontWeight: typography.fontWeightRegular,
			fontSize: typography[toggleSize.fontSize].fontSize,
			lineHeight: typography[toggleSize.lineHeight].lineHeight,
			width: '50%',
			padding: '2px 10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			whiteSpace: 'nowrap',
			zIndex: 1,
			transition: `${duration} ${bezier}`,
			'&:last-of-type': {
				color: labelColor,
			},
		},
		handle: {
			'&:before': {
				backgroundColor: handleBackgroundColor,
				borderRadius: ifFeature('encore', borderRadiuses[200], '2px'),
				content: '""',
				height: toggleSize.handle,
				width: '50%',
				left: '2px',
				top: '2px',
				position: 'absolute',
				transition: `${duration} ${bezier}`,
			},
		},
		input: {
			height: '1px',
			opacity: 0,
			position: 'absolute',
			width: '1px',
			[`&:first-of-type:checked ~ .${label}:first-of-type`]: {
				color: palette.common.white,
				textShadow: `0 0 0.01px ${palette.common.white}`, // Using Bold causes the toggles width to shift - Hacky workaround to prevent that from happening
			},
			[`&:last-of-type:checked ~ .${label}:last-of-type`]: {
				color: palette.common.white,
				textShadow: `0 0 0.01px ${palette.common.white}`, // Using Bold causes the toggles width to shift - Hacky workaround to prevent that from happening
			},
			[`&:last-of-type:checked ~ .${handle}:before`]: {
				left: 'calc(50% - 2px)',
			},
		},
	};
});
