import { getFormattedValue } from '../components/input-date/input-date.domain';
import {
	DatePickerDate,
	DatePickerDetailedOnChangeParam,
	DatePickerError,
	DatePickerOnChangeParam,
	DatePickerOnErrorParam,
	DatePickerUtils,
	InputDateOnChangeParam,
} from '../types';
import { getDateInfo } from '../utils';

export function getDatePickerOnChangeParam(utils: DatePickerUtils, date: DatePickerDate): DatePickerOnChangeParam {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return {
		value: getDateInfo(utils, date).date,
	};
}

export function getDatePickerDetailedOnChangeParam(utils: DatePickerUtils, date: DatePickerDate): DatePickerDetailedOnChangeParam {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return {
		value: getDateInfo(utils, date).date,
		text: getFormattedValue(utils, date),
	};
}

export function getDatePickerOnInputChangeParam(
	utils: DatePickerUtils,
	param: InputDateOnChangeParam
): DatePickerDetailedOnChangeParam {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	return {
		value: getDateInfo(utils, param.value).date,
		text: param.inputValue,
	};
}

export function getDatePickerOnErrorParam(error: DatePickerError): DatePickerOnErrorParam {
	return {
		error,
	};
}
