import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ constructs, typography }) => {
	return {
		titleSmall: {
			color: constructs.text.neutral.xStrong,
			fontWeight: typography.fontWeightSemibold,
		},
	};
});
