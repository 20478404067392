import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default makeStyles()(({ constructs, palette, typography }) => {
	return {
		stepCounter: {
			alignItems: 'center',
			borderRight: ifFeature('encore', undefined, `2px solid ${palette.gray[300]}`),
			display: 'flex',
			color: palette.gray[700],
			fontSize: typography.fabricFontSize('teenie'),
			marginRight: ifFeature('encore', undefined, '12px'),
			padding: ifFeature('encore', `0 0 0 12px`, '0 12px'),
		},
		dot: {
			backgroundColor: ifFeature('encore', palette.gray[400], '#c4c4c4'),
			borderRadius: '50%',
			display: 'inline-block',
			height: ifFeature('encore', '11px', '7px'),
			width: ifFeature('encore', '11px', '7px'),
			'&:first-of-type': {
				marginLeft: '10px',
			},
		},
		dotCurrent: {
			backgroundColor: palette.primary.main,
			height: ifFeature('encore', '15px', '9px'),
			width: ifFeature('encore', '15px', '9px'),
		},
		dotCompleted: {
			backgroundColor: constructs.surface.primary.medium,
		},
		separator: {
			borderTop: '1px solid #c4c4c4',
			margin: '0 2px',
			width: '6px',
		},
	};
});
