import { createContext } from 'react';
import { FieldStatus } from '../types';

export type FieldContextValue =
	| {
			status?: FieldStatus;
			disabled?: boolean;
			required?: boolean;
			/** `id` to associate with note on nested form label   */
			noteId?: string;
	  }
	| undefined;

export const FieldContext = createContext<FieldContextValue>(undefined);
