import { makeStyles } from '~styles';

export const useDeletionModalStyles = makeStyles()(theme => {
	const { palette, spacing, typography } = theme;

	return {
		icon: {
			fill: palette.error.main,
		},
		subtitle: {
			lineHeight: typography.body1.lineHeight,
			marginTop: spacing(0.5),
		},
	};
});
