import { OPPOSITE_DIRECTIONS } from '../../constants';
import { Directions } from '../../enums';

export function flipVertically(locations) {
	const anchorVerticalPlacement = OPPOSITE_DIRECTIONS[locations.anchor.vertical] || locations.anchor.vertical;
	const vesselVerticalPlacement = OPPOSITE_DIRECTIONS[locations.vessel.vertical] || locations.vessel.vertical;

	return {
		anchor: { ...locations.anchor, vertical: anchorVerticalPlacement },
		vessel: { ...locations.vessel, vertical: vesselVerticalPlacement },
	};
}

export function shouldFlipVertically(collisions) {
	const topCollision = collisions.includes(Directions.TOP);
	const bottomCollision = collisions.includes(Directions.BOTTOM);

	return (topCollision && !bottomCollision) || (bottomCollision && !topCollision);
}
