export const MODAL_CLOSE_TIMEOUT = 250;

export enum OverlayTheme {
	PARTIALLY_OPAQUE = 'partiallyOpaque',
	FULLY_OPAQUE = 'fullyOpaque',
}

export enum Transition {
	VERTICAL_FADE = 'VERTICAL_FADE',
	ZOOM_FADE = 'ZOOM_FADE',
}

export const TransitionModifiers = {
	[Transition.VERTICAL_FADE]: 'verticalFade',
	[Transition.ZOOM_FADE]: 'zoomFade',
};
