import { format, parseISO } from 'date-fns';
import { isArray, isUndefined } from 'lodash';
import { Matcher } from 'react-day-picker';
import { IsoMatcher } from './calendar-base.types';

const transformCallbackToMatcher = (callback: (date: string) => boolean): Matcher => {
	return (date: Date) => callback(format(date, 'yyyy-MM-dd'));
};
const transformObjectToMatcher = (isoMatcher: IsoMatcher): Matcher => {
	return Object.entries(isoMatcher).reduce((acc, [key, value]) => {
		if (isArray(value)) {
			acc[key] = value;
		} else if (typeof value === 'number') {
			acc[key] = value;
		} else {
			acc[key] = parseISO(value);
		}
		return acc;
	}, {} as Matcher);
};

const transformIsoMatcherToMatcher = (isoMatcher: IsoMatcher): Matcher => {
	switch (typeof isoMatcher) {
		case 'boolean':
			return isoMatcher;
		case 'function':
			return transformCallbackToMatcher(isoMatcher);
		case 'string':
			return parseISO(isoMatcher);
		case 'object':
			return transformObjectToMatcher(isoMatcher);
		default:
			return false;
	}
};

export const transformDisabled = (disabled?: IsoMatcher | IsoMatcher[]): Matcher | Matcher[] | undefined => {
	if (isUndefined(disabled)) {
		return undefined;
	}
	if (isArray(disabled)) {
		return disabled.map(transformIsoMatcherToMatcher);
	}
	return transformIsoMatcherToMatcher(disabled);
};
