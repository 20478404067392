import { Box } from '@mui/material';
import { BoxTypeMap } from '@mui/system';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { Flex, FlexProps } from '~components/flex';
import { Typography } from '~components/typography';
import { generateFabricClasses } from '~styles';
import { useAdornedTextStyles } from './adorned-text.styles';
import { AdornedTextClasses, AdornedTextOwnProps, AdornedTextProps } from './types';

const adornedTextClasses = generateFabricClasses<AdornedTextClasses>('AdornedText', ['root', 'adornment', 'content']);

/**
 * @deprecated AdornedText should no longer be used.
 *
 * Use `BodyText` or `Headline` instead.
 *
 * For vertical layouts, use `LayoutBox`, `StyledBox`, `Flex`, or `Grid` to position the icon and text.
 */
export const AdornedText = forwardRef<HTMLDivElement, AdornedTextProps>(
	(
		{
			AdornedProps,
			adornment,
			biId,
			children,
			classes: classesProp,
			ContentProps,
			hasMargin = true,
			typographyProps,
			verticalLayout,
			...rest
		},
		ref
	) => {
		const { classes, cx } = useAdornedTextStyles(
			{ AdornedProps, ContentProps, verticalLayout },
			{ props: { classes: classesProp } }
		);

		return (
			<Flex
				alignItems="center"
				className={cx(classes.root, { [classes.noMargin]: !hasMargin }, adornedTextClasses.root)}
				data-bi-id={biId}
				flexDirection={verticalLayout ? 'column' : 'row'}
				justifyContent={verticalLayout ? 'center' : 'flex-start'}
				ref={ref}
				{...(rest as FlexProps)}
			>
				{adornment ? (
					<Box className={cx(classes.adornment, adornedTextClasses.adornment)} {...AdornedProps}>
						{adornment}
					</Box>
				) : null}
				<Flex className={cx(classes.content, adornedTextClasses.content)} {...ContentProps}>
					{typeof children === 'string' ? <Typography {...typographyProps}>{children}</Typography> : children}
				</Flex>
			</Flex>
		);
	}
) as OverridableComponent<BoxTypeMap<AdornedTextOwnProps>>;

export const AdornedTextForStorybook = AdornedText as FC<PropsWithChildren<AdornedTextProps>>;
