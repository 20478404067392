import { CircleExclamation13x13, InfoCircle13x13, TriangleExclamation13x11 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactNode } from 'react';
import { IconV2 } from '~components/icon-v2';

type StatusIcons = {
	[key: string]: ReactNode;
};
const statusIcons = (): StatusIcons => {
	return ifFeature(
		'encore',
		{
			error: <IconV2 color="error-medium" name="circle-exclamation-solid" size={16} />,
			info: <IconV2 color="info-medium" name="circle-info-solid" size={16} />,
			warning: <IconV2 color="warning-medium" name="triangle-exclamation-solid" size={16} />,
		},
		{
			info: <InfoCircle13x13 />,
			error: <CircleExclamation13x13 />,
			warning: <TriangleExclamation13x11 />,
		}
	);
};

export const useInputIcon = (status?: string): ReactNode => {
	return status ? statusIcons()[status] : null;
};
