import { AnyMaskedOptions, MaskedEnum } from 'imask';
import { selectableMasks } from '~components/masked-input/etc';

export function getMask(fieldType: string, redactedText?: string): Array<AnyMaskedOptions> {
	const mask = selectableMasks[fieldType] || { mask: /.*/ };

	return redactedText
		? [
				mask,
				{
					mask: MaskedEnum,
					enum: [redactedText],
				} as unknown as AnyMaskedOptions,
			]
		: [mask];
}
