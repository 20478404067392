import { makeStyles, ModeOptions, dyeColors } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useFormNoteStyles = makeStyles({ name: 'FormNote' })(({ constructs, palette, typography, mode }) =>
	ifFeature(
		'encore',
		{
			root: {
				color: constructs.text.neutral.weak,
				fontSize: typography.xsmall?.fontSize,
				lineHeight: typography.xsmall?.lineHeight,
				marginTop: 0,
				marginLeft: 0,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				'&.Mui-disabled, &.Mui-error': {
					color: constructs.text.neutral.weak,
				},
			},
			disabled: {
				color: constructs.text.neutral.weak,
			},
		},
		{
			root: {
				display: 'block',
				color: palette.grey[700],
				fontSize: typography.teenie.fontSize,
				lineHeight: typography.teenie.lineHeight,
				margin: '1px 0 0',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			},
			disabled: {
				color: palette.grey[700],
			},
		}
	)
);
