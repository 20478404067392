import React, { ReactElement } from 'react';
import { CurrencyToggle } from '../currency';
import { PandaToggle } from '../panda';
import { RequiredToggle } from '../required';
import { RoundedToggle } from '../rounded';
import { TextToggle } from '../text';
import { ToggleProps } from '../../types';

const TYPES = {
	required: RequiredToggle,
	rounded: RoundedToggle,
	text: TextToggle,
	currency: CurrencyToggle,
	panda: PandaToggle,
};

/**
 * @deprecated Use one of the individual Toggle components instead.
 */
export const Toggle = (props: ToggleProps): ReactElement | null => {
	const { type = 'required' } = props;
	const ToggleType: (props) => ReactElement | null = TYPES[type];

	return ToggleType(props);
};
export default Toggle;
