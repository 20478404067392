// eslint-disable-next-line no-use-before-define
import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FormControl, InputLabel, Theme, SimplePaletteColorOptions } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import clsx from 'classnames';
import { uniqueId } from 'lodash';
import { InputWrapper } from '~components/form/components/input-wrapper';
import { ItemType } from '~components/menu';
import { Note, NoteInputContainer } from '~components/form/components/common/note';
import { useReadableContext } from '~components/readable';
import { Select } from './select';
import { SelectFieldProps, SelectProps } from './types';

const styles = ({ palette, spacing, typography }: Theme) => {
	const labelSize = {
		teenie: '32px',
		small: '32px',
		medium: '40px',
		large: '48px',
		biggie: '48px',
	};

	return ifFeature(
		'encore',
		createStyles({
			root: ({ labelPlacement, notePlacement, type }: SelectFieldProps) => ({
				alignItems: labelPlacement === 'inline' && notePlacement === 'inline' && type === 'text' ? 'center' : undefined,
			}),
			label: ({ labelPlacement, size, type }: SelectFieldProps) => ({
				height: labelPlacement === 'inline' && type !== 'text' ? labelSize[size ?? 'medium'] : undefined,
			}),
			error: {},
			warning: {},
			info: {},
			note: {},
		}),
		createStyles({
			root: {},
			label: ({ status }: { status: SelectFieldProps['status'] }) => ({
				display: 'flex',
				alignItems: 'center',
				fontSize: typography.fabricFontSize('small'),
				lineHeight: 1,
				whiteSpace: 'nowrap',
				'&>svg': {
					marginRight: spacing(0.75),
				},
				'& > .MuiInputLabel-asterisk': {
					color: palette.primary.main,
				},
				'&.Mui-focused': {
					color: status ? (palette[status as string] as SimplePaletteColorOptions).main : palette.grey[800],
				},
				pointerEvents: 'auto',
			}),
			error: {
				color: palette.error.main,
				fill: palette.error.main,
			},
			warning: {
				color: palette.warning.main,
				fill: palette.warning.main,
			},
			info: {
				color: palette.info.main,
				fill: palette.info.main,
			},
			note: {}, // needed for props checking
		})
	);
};

export const SelectField = withStyles(styles)(
	forwardRef<HTMLDivElement, SelectFieldProps & WithStyles<typeof styles>>((props, ref) => {
		const { isReadable } = useReadableContext();
		let appliedProps = props;
		const { classes, status, type } = props;

		if (isReadable || type === 'view') {
			const { placeholder, isClearable, ...rest } = props;
			appliedProps = { ...rest, isClearable: ifFeature('encore', status ? true : false, false), type: 'view' };
		}

		const {
			afterLabel,
			ariaLabel,
			canSelectMultiple,
			className,
			disabled,
			isDisabled,
			label,
			labelPlacement = 'block',
			listenToItemsOnChange,
			note,
			notePlacement = 'inline',
			onChange,
			required,
			searchPlaceholder,
			value,
			variant,
			width = 100,
			...rest
		} = appliedProps;
		const id = appliedProps.id || uniqueId('fab-select');

		let resolvedValue: Array<ItemType['value']> = [];
		if (Array.isArray(value)) {
			resolvedValue = value;
		} else if (!(typeof value === 'undefined' || value === null)) {
			resolvedValue = [value];
		}

		const handleChange = (values: SelectProps['selectedValues'], items: ItemType[]) => {
			if (typeof onChange === 'function') {
				let eventValue = values;

				if (listenToItemsOnChange) {
					eventValue = items;
				}

				if (Array.isArray(eventValue) && !Array.isArray(value) && !canSelectMultiple) {
					[eventValue] = eventValue;
				}

				onChange({ target: { value: eventValue } });
			}
		};

		const _disabled = disabled || isDisabled;

		return ifFeature(
			'encore',
			<InputWrapper
				afterLabel={afterLabel}
				classes={{ root: classes.root, label: classes.label }}
				disabled={disabled}
				id={id}
				label={label}
				labelPlacement={labelPlacement}
				note={note}
				notePlacement={notePlacement}
				required={required}
				status={status}
				variant={variant}
				viewMode={type === 'view'}
				width={width}
			>
				<Select
					ariaLabel={ariaLabel || (typeof label === 'string' ? label : '')}
					canSelectMultiple={canSelectMultiple}
					condition={status}
					isDisabled={_disabled}
					name={id}
					required={required}
					{...rest}
					id={id}
					onChange={handleChange}
					searchPlaceholder={searchPlaceholder}
					selectedValues={resolvedValue}
					variant={variant}
					width={width}
				/>
			</InputWrapper>,
			<FormControl className={className} error={status === 'error'} ref={ref}>
				{label && (
					<InputLabel
						classes={{
							root: clsx({
								[classes.label]: true,
								[classes.error]: status === 'error',
								[classes.warning]: status === 'warning',
								[classes.info]: status === 'info',
							}),
						}}
						disabled={_disabled}
						error={status === 'error'}
						htmlFor={id}
						required={required}
						shrink
					>
						{label}
					</InputLabel>
				)}
				<NoteInputContainer inputHasLabel={Boolean(label)}>
					<Select
						ariaLabel={ariaLabel || (typeof label === 'string' ? label : '')}
						canSelectMultiple={canSelectMultiple}
						condition={status}
						isDisabled={_disabled}
						name={id}
						required={required}
						{...rest}
						id={id}
						onChange={handleChange}
						searchPlaceholder={searchPlaceholder}
						selectedValues={resolvedValue}
						width={width}
					/>
					<Note classes={{ root: classes.note }} disabled={_disabled} note={note} status={status} />
				</NoteInputContainer>
			</FormControl>
		);
	})
) as FC<PropsWithChildren<SelectFieldProps>>;
