import { getMatchersFromStringArray, MaskConfig, MaskMatcher } from '../../utils/mask';
import { DatePickerDate, DatePickerDatePredicate, DatePickerUtils } from './types';
import {
	getDisplayMonthAbbreviations,
	getIsDateDisabled,
	getKeyboardNavigatedDate,
	guardNavigation,
	isOutsideConstraints,
} from './utils';

export const DAY_MEMOIZE_MODE_FULL_TIMEOUT_MILLISECONDS = 300;

export const DAY_MEMOIZE_MODE_FULL_THRESHOLD_DAYS = 120;

export const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;
export const ISO_DATE_REGEX = /^\d\d\d\d-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[0-1])$/;

export const dayMatcher: MaskMatcher = {
	fixedLength: 2,
	regex: /^(?:0[1-9]?|[1-2][0-9]?|3[0-1]?)/,
};
export const monthNumericMatcher: MaskMatcher = {
	fixedLength: 2,
	regex: /^(?:0[1-9]?|1[0-2]?)/,
};
export const yearMatcher: MaskMatcher = {
	fixedLength: 4,
	regex: /^\d{1,4}/,
};

const singleDigitDayRegex = /^[4-9]/;
const singleDigitMonthNumericRegex = /^[2-9]/;

export const dayPreMatchHook = (dayStr: string): string => {
	const match = singleDigitDayRegex.exec(dayStr);
	if (match) {
		return `0${match[0]}`;
	}

	return dayStr;
};

export const predictSingleDigitNumericMonths = (monthStr: string): string => {
	const match = singleDigitMonthNumericRegex.exec(monthStr);
	if (match) {
		return `0${match[0]}`;
	}

	return monthStr;
};

export function getMaskConfig(utils: DatePickerUtils): MaskConfig {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const type = utils.formats.fullDate as string;

	if (type === 'dd/MM/yyyy') {
		return {
			delimiterChar: '/',
			order: ['day', 'month', 'year'],
			parts: {
				day: {
					mask: 'dd',
					matchers: [dayMatcher],
					preMatchHook: dayPreMatchHook,
				},
				month: {
					mask: 'mm',
					matchers: [monthNumericMatcher],
					preMatchHook: predictSingleDigitNumericMonths,
				},
				year: {
					mask: 'yyyy',
					matchers: [yearMatcher],
				},
			},
		};
	}

	if (type === 'yyyy-MM-dd') {
		return {
			delimiterChar: '-',
			order: ['year', 'month', 'day'],
			parts: {
				day: {
					mask: 'dd',
					matchers: [dayMatcher],
					preMatchHook: dayPreMatchHook,
				},
				month: {
					mask: 'mm',
					matchers: [monthNumericMatcher],
					preMatchHook: predictSingleDigitNumericMonths,
				},
				year: {
					mask: 'yyyy',
					matchers: [yearMatcher],
				},
			},
		};
	}

	if (type === 'dd MMM yyyy') {
		return {
			delimiterChar: ' ',
			order: ['day', 'month', 'year'],
			parts: {
				day: {
					mask: 'dd',
					matchers: [dayMatcher],
					preMatchHook: dayPreMatchHook,
				},
				month: {
					mask: 'mon',
					matchers: getMatchersFromStringArray(getDisplayMonthAbbreviations(utils)),
				},
				year: {
					mask: 'yyyy',
					matchers: [yearMatcher],
				},
			},
		};
	}

	// Default is type === 'MM/dd/yyyy'
	return {
		delimiterChar: '/',
		order: ['month', 'day', 'year'],
		parts: {
			day: {
				mask: 'dd',
				matchers: [dayMatcher],
				preMatchHook: dayPreMatchHook,
			},
			month: {
				mask: 'mm',
				matchers: [monthNumericMatcher],
				preMatchHook: predictSingleDigitNumericMonths,
			},
			year: {
				mask: 'yyyy',
				matchers: [yearMatcher],
			},
		},
	};
}

/**
 * A function that will try to reapply the same navigation action until a limit is reached so that
 * dates on the other side of disabled dates in a calendar can still be navigated to with the keyboard.
 */
export function navigateWithKeyboard({
	activeDate,
	getDateDisabled,
	keyboardKey,
	maxDate,
	minDate,
	shiftKeyPressed,
	utils,
}: {
	activeDate: DatePickerDate;
	getDateDisabled?: DatePickerDatePredicate;
	keyboardKey: string;
	maxDate: DatePickerDate;
	minDate: DatePickerDate;
	shiftKeyPressed: boolean;
	utils: DatePickerUtils;
}) {
	return guardNavigation({
		isAtLimit: (date: DatePickerDate) => isOutsideConstraints(utils, { maxDate, minDate }, date),
		isEnabled: (date: DatePickerDate) => !getIsDateDisabled(utils, date, minDate, maxDate, getDateDisabled),
		navigator: (date: DatePickerDate) => getKeyboardNavigatedDate(utils, date, keyboardKey, shiftKeyPressed),
		value: activeDate,
	});
}
