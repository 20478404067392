import classnames from 'classnames';
import { getConditionModifier } from '@fabric/utils/inputs';

import { isActionOnly as isItemActionOnly, isDisabled as isItemDisabled } from '../../../item';

export function getGroupCSSClasses(item) {
	return classnames('fab-MenuList__group', {
		'fab-MenuList__group--anchoredBottom': item.anchor === 'bottom',
		'fab-MenuList__group--anchoredTop': item.anchor === 'top',
	});
}

export function getNestedVesselCSSClasses({ condition } = {}) {
	return classnames('fab-MenuList__nested', {
		[`fab-MenuList__nested${getConditionModifier(condition)}`]: !!condition,
	});
}

export function isActionOnly(item, inheritedSettings = {}) {
	return isItemActionOnly(item) || !!inheritedSettings.isActionOnly;
}

export function isDisabled(item, inheritedSettings = {}) {
	return isItemDisabled(item) || !!inheritedSettings.isDisabled;
}
