import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Body } from './body.react';
import { Constraint } from './constraint.react';
import { Footer } from './footer.react';
import { Header } from './header.react';
import { StandardHeadline } from './standard-headline.react';
import { BaseModal, ConfirmationContent, FocalContent, HeroHeadline, LowerContent, UpperContent } from '~components/base-modal';

import { FullScreenModalProps } from './full-screen-modal.types';

export const FullScreenModal: FunctionComponent<PropsWithChildren<FullScreenModalProps>> & {
	Body: typeof Body;
	CloseButton: typeof BaseModal.CloseButton;
	ConfirmationContent: typeof ConfirmationContent;
	Constraint: typeof Constraint;
	FocalContent: typeof FocalContent;
	Footer: typeof Footer;
	Header: typeof Header;
	HeroHeadline: typeof HeroHeadline;
	LowerContent: typeof LowerContent;
	StandardHeadline: typeof StandardHeadline;
	UpperContent: typeof UpperContent;
} = props => {
	return (
		<BaseModal
			{...props}
			contentStyles={{
				height: '100%',
				width: '100%',
			}}
		/>
	);
};

FullScreenModal.Body = Body;
FullScreenModal.CloseButton = BaseModal.CloseButton;
FullScreenModal.ConfirmationContent = ConfirmationContent;
FullScreenModal.Constraint = Constraint;
FullScreenModal.FocalContent = FocalContent;
FullScreenModal.Footer = Footer;
FullScreenModal.Header = Header;
FullScreenModal.HeroHeadline = HeroHeadline;
FullScreenModal.LowerContent = LowerContent;
FullScreenModal.StandardHeadline = StandardHeadline;
FullScreenModal.UpperContent = UpperContent;
