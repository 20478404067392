import { useKeyboardNavigation } from './use-keyboard-navigation';
import { UseFlatKeyboardNavigationOptions } from '../types';

/**
 * This hook listens to the keyboard keys that change what the active item is. When key should
 * change the active index, the `onChange` callback is invoked.
 * @param config A configuration object containing information required to listen to key events and update the active index
 */
export const useFlatKeyboardNavigation = ({
	activeIndex,
	getDisabled,
	keyboardTarget,
	onChange,
	total,
}: UseFlatKeyboardNavigationOptions): void => {
	useKeyboardNavigation({
		activePath: typeof activeIndex === 'undefined' ? undefined : [activeIndex],
		getDisabled:
			typeof getDisabled === 'function'
				? path => {
						return getDisabled(path[0]);
					}
				: undefined,
		getTotal: path => (path.length === 0 ? total : 0),
		onChange: path => {
			onChange(path[0]);
		},
		targetElement: keyboardTarget,
	});
};
