import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, spacing }) => {
	return ifFeature(
		'encore',
		{
			root: {
				display: 'flex',
				flexDirection: 'column',
				gap: spacing(0.5),
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				width: '200px',
			},
		},
		{
			root: {
				background: 'none',
				borderRight: `1px solid ${palette.primary.lightest}`,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				padding: '16px 20px',
				width: '225px',
			},
		}
	);
});
