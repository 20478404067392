import { FileUploadFailure, FileUploadFileMetadata, FileUploadState } from './types';

export function addIdsToAllFileIds(fileIds: string[] = []) {
	return ({ allFileIds }: { allFileIds: string[] }): { allFileIds: string[] } => {
		return {
			allFileIds: [...allFileIds, ...fileIds],
		};
	};
}

export function addIdsToPendingFileIds(fileIds: string[] = []) {
	return ({ pendingFileIds }: { pendingFileIds: string[] }): { pendingFileIds: string[] } => {
		return {
			pendingFileIds: [...pendingFileIds, ...fileIds],
		};
	};
}

export function addIdToFailedFileIds(fileId: string) {
	return ({ failedFileIds }: { failedFileIds: string[] }): { failedFileIds: string[] } => {
		return {
			failedFileIds: [...failedFileIds, fileId],
		};
	};
}

export function addFilesToFilesById(temporaryIds: string[], filesByTemporaryId: Record<string, FileUploadFileMetadata>) {
	return ({ filesById }: { filesById: Record<string, FileUploadFileMetadata> }): Pick<FileUploadState, 'filesById'> => {
		return {
			filesById: {
				...filesById,
				...temporaryIds.reduce<Record<string, FileUploadFileMetadata>>((memo, temporaryId) => {
					memo[temporaryId] = filesByTemporaryId[temporaryId];

					return memo;
				}, {}),
			},
		};
	};
}

export function addPendingFiles(temporaryIds: string[], filesByTemporaryId: Record<string, FileUploadFileMetadata>) {
	return (state: FileUploadState): Pick<FileUploadState, 'allFileIds' | 'pendingFileIds' | 'filesById'> => {
		return {
			...addIdsToAllFileIds(temporaryIds)(state),
			...addIdsToPendingFileIds(temporaryIds)(state),
			...addFilesToFilesById(temporaryIds, filesByTemporaryId)(state),
		};
	};
}

export function removeIdFromPendingFileIds(fileId: string) {
	return ({ pendingFileIds }: { pendingFileIds: string[] }): { pendingFileIds: string[] } => {
		return {
			pendingFileIds: pendingFileIds.filter(pendingFileId => pendingFileId !== fileId),
		};
	};
}

export function removeIdFromAllFileIds(fileId: string) {
	return ({ allFileIds }: { allFileIds: string[] }): { allFileIds: string[] } => {
		return {
			allFileIds: allFileIds.filter(id => id !== fileId),
		};
	};
}

export function removeFileFromFilesById(fileId: string) {
	return ({
		filesById,
	}: {
		filesById: Record<string, FileUploadFileMetadata>;
	}): { filesById: Record<string, FileUploadFileMetadata> } => {
		const copy = { ...filesById };

		delete copy[fileId];

		return {
			filesById: copy,
		};
	};
}

export function removeFileFromProgressByFileId(fileId: string) {
	return ({
		progressByFileId,
	}: {
		progressByFileId: Record<string, number | undefined>;
	}): { progressByFileId: Record<string, number | undefined> } => {
		const copy = { ...progressByFileId };

		delete copy[fileId];

		return {
			progressByFileId: copy,
		};
	};
}

export function removeFile(fileId: string) {
	return (
		state: Readonly<FileUploadState>
	): Pick<FileUploadState, 'allFileIds' | 'filesById' | 'pendingFileIds' | 'progressByFileId'> => {
		return {
			...removeFileFromFilesById(fileId)(state),
			...removeFileFromProgressByFileId(fileId)(state),
			...removeIdFromAllFileIds(fileId)(state),
			...removeIdFromPendingFileIds(fileId)(state),
		};
	};
}

export function setFileInFilesById(file: FileUploadFileMetadata, fileId: string) {
	return ({
		filesById,
	}: {
		filesById: Record<string, FileUploadFileMetadata>;
	}): { filesById: Record<string, FileUploadFileMetadata> } => ({
		filesById: {
			...filesById,
			[fileId]: file,
		},
	});
}

export function setUploadedFile(fileId: string, uploadedFile: FileUploadFileMetadata) {
	return (state: FileUploadState): Pick<FileUploadState, 'filesById' | 'pendingFileIds'> => ({
		...setFileInFilesById(uploadedFile, fileId)(state),
		...removeIdFromPendingFileIds(fileId)(state),
	});
}

export function setFailedFileIds(fileIds: string[]): (state: Partial<FileUploadState>) => { failedFileIds: string[] } {
	return () => ({
		failedFileIds: fileIds,
	});
}

export function updateProgress(fileId: string, progress: number) {
	return ({
		progressByFileId,
	}: {
		progressByFileId: Record<string, number | undefined>;
	}): { progressByFileId: Record<string, number | undefined> } => ({
		progressByFileId: {
			...progressByFileId,
			[fileId]: progress === 100 ? undefined : progress,
		},
	});
}

export function updateWithFailures(
	rejections: FileUploadFailure[]
): (state: Partial<FileUploadState>) => Pick<FileUploadState, 'failedFileIds' | 'failuresByFileId' | 'showErrors'> {
	const failedIds = rejections.map<string>(({ temporaryId }) => temporaryId);
	const failuresByFileId = rejections.reduce((memo, failure) => {
		memo[failure.temporaryId] = failure;
		return memo;
	}, {});

	return state => ({
		...setFailedFileIds(failedIds)(state),
		failuresByFileId,
		showErrors: true,
	});
}
