import {
	DatePickerDate,
	DatePickerUtils,
	DatePickerDatePredicate,
	MonthValue,
	SelectMonthYearOnChangeParam,
	DatePickerDateType,
} from '../../types';
import { getDateFromYearMonth } from '../../utils';

export function getMonthDisabled(
	utils: DatePickerUtils,
	month: MonthValue,
	year: number,
	getDateDisabled?: DatePickerDatePredicate,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate
): boolean {
	if (!utils || !month || !year) {
		throw new Error('Expected arguments are missing.');
	}
	const monthDate = getDateFromYearMonth(utils, year, month);

	if (!monthDate) {
		throw new Error('Expected arguments are missing.');
	}

	if (maxDate && (utils.isSameMonth(maxDate, monthDate) || utils.isSameMonth(minDate as DatePickerDateType, monthDate))) {
		return false;
	}

	if (minDate && (utils.isBeforeDay(minDate, monthDate) || utils.isAfterDay(maxDate as DatePickerDateType, monthDate))) {
		return true;
	}

	return getDateDisabled ? getDateDisabled(monthDate) : false;
}

export function getSelectMonthYearOnChangeParam(month: MonthValue, year: number): SelectMonthYearOnChangeParam {
	return {
		month,
		year,
	};
}

export function getYearDisabled(utils: DatePickerUtils, year: number, getDateDisabled?: DatePickerDatePredicate): boolean {
	if (!utils || !year) {
		throw new Error('Expected arguments are missing.');
	}

	if (!getDateDisabled) {
		return false;
	}

	for (let i = 0; i < 12; i++) {
		const date = utils.date(new Date(year, i)) as DatePickerDate;
		if (!getDateDisabled(date)) {
			return false;
		}
	}

	return true;
}
