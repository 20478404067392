import React, { ReactElement } from 'react';
import clsx from 'classnames';
import { WistiaVideoProps } from './types';
import { useStyles } from './styles';

export const WistiaVideo = ({
	classes: classesProp,
	className,
	embedOptions = '',
	wistiaId = '',
	iframeProps,
	square,
	...props
}: WistiaVideoProps): ReactElement => {
	const classes = useStyles({ classes: classesProp });

	const prefixedAllowFullScreen = {
		mozallowfullscreen: 'true',
		msallowfullscreen: 'true',
		oallowfullscreen: 'true',
		webkitallowfullscreen: 'true',
	};

	return (
		<div className={clsx(classes.root, className, { [classes.rounded]: !square })} {...props}>
			<iframe
				allowFullScreen={true}
				className={clsx('wistia_embed', classes.iframe)}
				frameBorder="0"
				name="wistia_embed"
				scrolling="no"
				src={`//fast.wistia.net/embed/iframe/${wistiaId}?videoFoam=true${embedOptions}`}
				title="Video"
				{...prefixedAllowFullScreen}
				{...iframeProps}
			/>
		</div>
	);
};
