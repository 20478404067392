import { noop } from 'lodash';
import React, { Component, createRef } from 'react';

import {
	activateItem,
	moveCursor,
	shouldActivateItem,
	shouldActivateOtherItem,
	shouldOpen,
	shouldClose,
	shouldFocusAnchor,
	shouldMoveCursor,
	shouldPreventDefault,
	shouldSelectItem,
	shouldSelectOtherItem,
	traverseItemsByCharacter,
} from './util';
import { cursorIsAtBeginningOf, cursorIsAtEndOf, focusElement, hasKeyModifier, isFocused, normalizeKey } from '../../dom-util';

export class Anchor extends Component {
	static defaultProps = {
		onActiveItemChange: noop,
		onClose: noop,
		onOpen: noop,
		onSelect: noop,
	};

	focus() {
		focusElement(this._ref.current);
	}

	_addKeyEvents() {
		const { isDisabled } = this.props;

		if (!isDisabled) {
			document.addEventListener('keydown', this._handleKeydown);
		}
	}

	_getState(event) {
		const { target } = event;

		const anchorElement = this._ref.current;
		const inputElement = target.closest('input[type="text"]');

		const { activeItem, items, isOpen } = this.props;

		return {
			activeItem,
			anchorIsFocused: isFocused(anchorElement),
			cursorIsAtBeginningOfInput: cursorIsAtBeginningOf(inputElement),
			cursorIsAtEndOfInput: cursorIsAtEndOf(inputElement),
			inputIsFocused: isFocused(inputElement),
			items,
			isOpen,
		};
	}

	_removeKeyEvents() {
		document.removeEventListener('keydown', this._handleKeydown);
	}

	_updateDom(event) {
		const { key, target } = event;

		const normalizedKey = normalizeKey(key);
		const state = this._getState(event);

		const inputElement = target.closest('input[type="text"]');

		if (shouldPreventDefault(normalizedKey, state)) {
			event.preventDefault();
		}

		if (shouldMoveCursor(normalizedKey, state)) {
			moveCursor(event, inputElement);
		}

		if (shouldFocusAnchor(normalizedKey, state)) {
			this.focus();
		}
	}

	_updateProps(event) {
		const { activeItem, canQuickSelect = false, canSelectMultiple, onActiveItemChange, onClose, onOpen, onSelect } = this.props;

		const normalizedKey = normalizeKey(event.key);
		const state = this._getState(event);

		if (shouldClose(normalizedKey, state)) {
			onClose();
		} else if (shouldOpen(normalizedKey, state, { canQuickSelect, canSelectMultiple })) {
			onOpen();
		}

		if (shouldActivateItem(normalizedKey, state)) {
			onActiveItemChange(activateItem(normalizedKey, state));
		} else if (shouldActivateOtherItem(normalizedKey, state, { canQuickSelect })) {
			onActiveItemChange(traverseItemsByCharacter(normalizedKey, state));
		}

		if (shouldSelectItem(normalizedKey, state)) {
			onSelect(activeItem);
		} else if (shouldSelectOtherItem(normalizedKey, state, { canQuickSelect })) {
			onSelect(traverseItemsByCharacter(normalizedKey, state), true);
		}
	}

	_handleFocus = event => {
		const { isDisabled } = this.props;
		if (isDisabled) {
			event.stopPropagation();
		} else {
			this.setState({ isFocused: true });
		}
	};

	_handleBlur = event => {
		const { isDisabled } = this.props;
		if (isDisabled) {
			event.stopPropagation();
		} else {
			this.setState({ isFocused: false });
		}
	};

	_handleClick = e => {
		const { isDisabled, isOpen, onClose, onOpen } = this.props;

		const { nativeEvent } = e;

		/*
		 * Ignore simulated clicks (see https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail).
		 * This is required in order hide the list when a button is inside the toggle and "Enter" is pressed.
		 * NOTE: IE 11 always sends zero as the value for the "detail" property so we need to also check to make sure "isPrimary" is not true.
		 */
		const isSimulated = nativeEvent && nativeEvent.detail === 0 && !nativeEvent.isPrimary;
		if (isDisabled || isSimulated) {
			return;
		}

		// We need to prevent default behavior so that the menu can open when it is inside of a label
		if (e.target.closest('label')) {
			e.preventDefault();
		}

		if (isOpen) {
			onClose();
		} else {
			onOpen();
		}
	};

	_handleKeydown = event => {
		const { isAdding } = this.props;

		if (!isAdding && !hasKeyModifier(event)) {
			this._updateDom(event);
			this._updateProps(event);
		}
	};

	state = {};

	_ref = createRef();

	componentDidMount() {
		const { isOpen } = this.props;

		if (isOpen) {
			this._addKeyEvents();
		}
	}

	componentDidUpdate() {
		const { isOpen } = this.props;

		const { isFocused: isFocusedState } = this.state;

		if (isOpen || isFocusedState) {
			this._addKeyEvents();
		} else {
			this._removeKeyEvents();
		}
	}

	componentWillUnmount() {
		this._removeKeyEvents();
	}

	render() {
		const { ariaLabel, biId, isFullWidth, isDisabled, isOpen, menuId, menuPlacement, renderToggle, selectedItems } = this.props;

		const { isFocused: isFocusedState } = this.state;

		const _isFocused = !isDisabled && (isFocusedState || isOpen);

		const style = {
			width: isFullWidth ? '100%' : null,
		};

		const dataAttributes = {
			'data-menu-id': menuId,
		};

		const eventProps = {
			onBlur: this._handleBlur,
			onClick: this._handleClick,
			onFocus: this._handleFocus,
		};

		const ariaControlsAttribute = isOpen ? { 'aria-controls': menuId } : {};

		if (biId) {
			dataAttributes['data-bi-id'] = `${biId}-menu-toggle`;
		}

		return (
			<>
				{renderToggle(
					{ appliedPlacement: menuPlacement, isFocused: _isFocused, selectedItems },
					{
						...dataAttributes,
						...eventProps,
						...ariaControlsAttribute,
						'aria-expanded': isOpen,
						'aria-haspopup': true,
						'aria-label': ariaLabel,
						ref: this._ref,
						style,
						tabIndex: 0,
					}
				)}
			</>
		);
	}
}
