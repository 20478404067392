import { getDimensions } from './dimensions';
import { getWindowOffsets } from './window';

export function getOffsetsFromElement(element) {
	const bodyOffsets = document.body.getBoundingClientRect();

	const elementOffsets = element === window ? getWindowOffsets() : element.getBoundingClientRect();
	const elementDimensions = getDimensions(elementOffsets);

	const top = elementOffsets.top - bodyOffsets.top;
	const left = elementOffsets.left - bodyOffsets.left;

	return {
		bottom: top + elementDimensions.height,
		left,
		right: left + elementDimensions.width,
		top,
	};
}
