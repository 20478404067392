import { hasVerticalScrollbar } from '@bamboohr/utils/lib/dom';
import { RefObject, useEffect, useState } from 'react';

export function useHasScrollbar(scrollContainerRef: RefObject<HTMLDivElement>) {
	const [hasScrollbar, setHasScrollbar] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		const scrollContainerElement = scrollContainerRef.current;
		if (!scrollContainerElement) {
			return;
		}
		const _hasScrollbar = hasVerticalScrollbar(scrollContainerElement);
		if (_hasScrollbar || _hasScrollbar !== hasScrollbar) {
			setHasScrollbar(_hasScrollbar);
		}
	});

	return hasScrollbar;
}
