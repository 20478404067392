import React, { forwardRef, ReactElement } from 'react';

import { DatePickerComponent } from './date-picker-component';
import { DatePickerUtilsProvider } from '../date-picker-utils-provider';
import { DatePickerProps } from '../types';
import { useReadableContext } from '~components/readable';

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(function DatePicker(props, ref): ReactElement {
	let appliedProps = props;
	const { viewMode = false } = props;
	const { isReadable } = useReadableContext();

	if (isReadable || viewMode) {
		appliedProps = { ...props, viewMode: true };
	}

	return (
		<DatePickerUtilsProvider>
			<DatePickerComponent {...appliedProps} ref={ref} />
		</DatePickerUtilsProvider>
	);
});
