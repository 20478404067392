import { ifFeature } from '@bamboohr/utils/lib/feature';
import clsx from 'classnames';
import React from 'react';
import { Button } from '~components/button';
import { useStyles } from './page.styles';
/**
 * Page - a page node for pagination
 *
 * @param  {number} { value             page number, required
 * @param  {boolean} isActive           boolean to determine if the page is active, required
 * @param  {function} handlePageClick   function to handle page number update, required
 * @param  {function} hrefBuilder       callback function to build an href according to the consumer, optional
 * @param  {string} trackingCategory }  string used to create tracking data attributes for pages
 * @return {node}                       return a page node
 */
export default function Page({ className, value, isActive, handlePageClick, url, trackingAttributes }) {
	const { classes } = useStyles({ isActive });

	function handleClick(event) {
		handlePageClick(event, value);
	}

	const encoreButton = (
		<li {...trackingAttributes} className={clsx(className, classes.root)}>
			<Button
				aria-label={`Page ${value}`}
				color="primary"
				href={url}
				onClick={handleClick}
				size="teenie"
				variant={isActive ? 'contained' : 'outlined'}
			>
				{value}
			</Button>
		</li>
	);

	return ifFeature(
		'encore',
		encoreButton,
		<li {...trackingAttributes} className={clsx(className, classes.root)} onClick={handleClick}>
			<a aria-label={`Page ${value}`} className={classes.link} href={url}>
				{value}
			</a>
		</li>
	);
}
