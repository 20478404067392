import React, { Fragment, FunctionComponent, PropsWithChildren } from 'react';

import { useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { StandardModalTitleProps } from '../../standard-modal.types';

import { useTitleStyles } from './title.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Headline } from '~components/headline';

export const Title: FunctionComponent<PropsWithChildren<StandardModalTitleProps>> = props => {
	const { children, text } = props;
	const { classes } = useTitleStyles();

	const { isMediumScreen } = useScreenSizes();

	const titleComponent = ifFeature(
		'encore',
		<Headline color="primary" size="medium">
			{text}
		</Headline>,
		<h3 className={classes.root}>{text}</h3>
	);

	return isMediumScreen ? (
		<FullScreenModal.Header.Title {...props} />
	) : (
		<Fragment>
			{titleComponent}
			{children}
		</Fragment>
	);
};
