import co from 'classnames';
import React, { ReactElement } from 'react';
import { CheckCircle28x28 } from '@bamboohr/grim';
import { WizardProps, WizardStep } from './types';

import './styles.scss';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { StyledBox } from '~components/styled-box';
import { Flex } from '~components/flex';
import { WizardStepIndicator } from './wizard-step-indicator';
import { BodyText } from '~components/body-text';
import { Divider } from '~components/divider';

export function Wizard(props: WizardProps): ReactElement {
	const { currentStep, steps, tabOverflowLimit } = props;

	function getStepAriaLabel(currentStep: number, stepNumber: number): string {
		if (currentStep > stepNumber) {
			return window.jQuery ? $.__('Completed step') : 'Completed step';
		}

		if (currentStep === stepNumber) {
			return window.jQuery ? $.__('Current step') : 'Current step';
		}

		return window.jQuery ? $.__('Pending step') : 'Pending step';
	}

	function renderSteps(): Array<ReactElement> {
		const filteredSteps = Object.entries(steps).splice(0, 9) as Array<[string, WizardStep]>;

		return filteredSteps.map(([stepKey, step]) => {
			const stepNumber = parseInt(stepKey, 10);

			return (
				<WizardStepIndicator
					active={currentStep === stepNumber}
					ariaLabel={getStepAriaLabel(currentStep, stepNumber)}
					completed={currentStep > stepNumber}
					key={stepNumber}
					name={step.name}
					stepNumber={stepNumber}
				/>
			);
		});
	}

	/* @startCleanup encore */
	function renderStepsJade(): Array<ReactElement> {
		const stepList: number[] = Object.keys(steps).map(stepNumber => parseInt(stepNumber, 10));
		let filteredSteps = stepList.slice();
		const indexedCurrentStep = currentStep - 1;

		/**
		 * if there's more tabs than retail:
		 * tabOverflowLimit prop can be set to control how many tabs show
		 * and the last step will show at the end with ellipsis if there's a gap between values
		 */
		if (tabOverflowLimit && stepList.length > tabOverflowLimit) {
			const oneWayOverflowLimit = Math.abs(tabOverflowLimit / 2);
			let startIndex = indexedCurrentStep - oneWayOverflowLimit;
			let endIndex = indexedCurrentStep + oneWayOverflowLimit;

			if (startIndex < 0) {
				startIndex = 0;
				endIndex = endIndex + oneWayOverflowLimit - indexedCurrentStep;
			}

			filteredSteps = stepList.slice(startIndex, endIndex);
			if (endIndex < stepList.length) {
				filteredSteps.splice(-1, 1, stepList.length);
			}
		}

		return filteredSteps.map((stepNumber, _index, array): ReactElement => {
			const isLastItemOverflow = array[array.length - 1] !== array[array.length - 2] + 1;
			const { name } = steps[stepNumber];

			const stepsClassNames = co({
				Wizard__steps: true,
				Wizard__steps__tab__overflow: tabOverflowLimit !== undefined && tabOverflowLimit > 0,
				'Wizard__steps--completed': currentStep > stepNumber,
				'Wizard__steps--current': currentStep === stepNumber,
				'Wizard__steps--pending': currentStep < stepNumber,
			});

			const iconClassNames = co({
				Wizard__steps__icon: true,
				'Wizard__steps__icon--completed': currentStep > stepNumber,
				'Wizard__steps__icon--current': currentStep === stepNumber,
			});

			return (
				<li aria-label={getStepAriaLabel(currentStep, stepNumber)} className={stepsClassNames} key={stepNumber}>
					{tabOverflowLimit !== undefined && stepNumber === stepList.length && isLastItemOverflow && (
						<div className="Wizard__stepsEllipsis">. . . .</div>
					)}
					<div className={iconClassNames}>{stepNumber < currentStep ? <CheckCircle28x28 /> : stepNumber}</div>
					<div className="Wizard__steps__content">{name}</div>
				</li>
			);
		});
	}
	/* @endCleanup encore */

	const note = steps?.[currentStep]?.note;

	return ifFeature(
		'encore',
		<StyledBox backgroundColor="neutral-weak" borderRadius="large" boxShadow="large" paddingX={4} paddingY={2}>
			<Flex alignItems="center" component="ul" gap={2}>
				{renderSteps()}
			</Flex>
			{note && (
				<>
					<Divider color="neutral-weak" marginBottom={2.5} marginTop={2} />
					<BodyText color="neutral-extra-strong" size="medium">
						{note}
					</BodyText>
				</>
			)}
		</StyledBox>,
		<div className="Wizard">
			<ul className="Wizard__steps-container">{renderStepsJade()}</ul>
			{note && <div className="Wizard__notes">{note}</div>}
		</div>
	);
}
