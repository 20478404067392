import { ValidateRecord } from '../types';
import { IDataHookResponse } from '@flatfile/adapter/build/main/interfaces';

export const recordValidator = async (
	userId: string,
	dataType: string,
	recordData: Record<string, unknown>,
	index: number,
	validateRecord: ValidateRecord
): Promise<IDataHookResponse> => {
	const results = await validateRecord(userId, dataType, recordData, index);

	if (results) {
		const errors = results[0];
		return Object.entries(errors).reduce((acc, [key, message]) => {
			acc[key] = {
				info: [{ message, level: 'error' }],
			};
			return acc;
		}, {});
	}
	return results;
};
