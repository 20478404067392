/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode, RefObject, useRef } from 'react';
import { ScrollContainerContext } from './scroll-container-context';
import { useHasScrollbar } from './use-has-scrollbar';

type ScrollContainerProps = {
	children: ReactNode;
	referenceElement?: RefObject<HTMLElement>;
	onScrollVisibilityChange?: (visible: boolean) => void;
};

export function ScrollContainer({ children }: ScrollContainerProps) {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const hasScrollbar = useHasScrollbar(scrollContainerRef);

	return (
		<ScrollContainerContext.Provider value={{ hasScrollbar, ref: scrollContainerRef }}>
			<div className="fab-MenuList__scrollContainer" ref={scrollContainerRef}>
				{children}
			</div>
		</ScrollContainerContext.Provider>
	);
}
