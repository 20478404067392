import { Box } from '@mui/material';
import React, { Children, cloneElement } from 'react';
import { ListItem } from './list-item';
import { useStyles } from './list.styles';
import { ListProps } from './types';

/**
 * A simple `ul` or `ol` with markers (bullets/numbers). If you don't need markers, you don't need this component.
 */
export function List({ children, ordered = false, size }: ListProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.root} component={ordered ? 'ol' : 'ul'}>
			{Children.toArray(children).map((child: React.ReactElement) => {
				return cloneElement(child, { size });
			})}
		</Box>
	);
}
List.Item = ListItem;
