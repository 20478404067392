import { UseFormRegister, RegisterOptions, FieldValues } from 'react-hook-form';
import { FormFieldDefinition, ControlledFormField, Fields } from './types';
import { getRequired } from './validators/get-required';
import { fastenData } from './fasten-data';

export const getFields = <T extends FieldValues>(
	fieldDefinitions: FormFieldDefinition<T>[],
	register: UseFormRegister<T>,
	initialFields: Partial<Fields<T>> = {}
): Fields<T> => {
	return fieldDefinitions.reduce((acc, { name, autoRegister, required, maxLength, validate, setValueAs, ...rest }) => {
		const rules: Partial<RegisterOptions> = { maxLength, validate, setValueAs };

		const props: { required?: boolean } = {};
		if (required) {
			rules.required = getRequired(required);
			props.required = true;
		}

		const registration = autoRegister ? register(name, rules) : { isControlled: true, rules };

		// eslint-disable-next-line no-use-before-define
		return fastenData<T, ControlledFormField<T>>(acc, name.split('.'), {
			...rest,
			...registration,
			...props,
			name,
		});
	}, initialFields as Fields<T>);
};
