import { DatePickerDate, DatePickerUtils } from '../types';

export function isValidRange(utils: DatePickerUtils, startDate: DatePickerDate, endDate: DatePickerDate) {
	return !!(
		startDate &&
		endDate &&
		utils.isValid(startDate) &&
		utils.isValid(endDate) &&
		(utils.isBefore(startDate, endDate) || utils.isSameDay(startDate, endDate))
	);
}
