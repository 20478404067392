import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, spacing, typography }) => {
	return ifFeature(
		'encore',
		{
			root: {
				color: mode === ModeOptions.Dye ? dyeColors.main : undefined,
				fontSize: typography.small.fontSize,
				fontWeight: typography.fontWeightSemibold,
				lineHeight: typography.small.lineHeight,
				overflowWrap: 'anywhere',
				padding: `${spacing(1)} ${spacing(1.5)}`,
			},
		},
		{
			root: {
				color: mode === ModeOptions.Dye ? dyeColors.main : undefined,
				fontSize: typography.teenie.fontSize,
				lineHeight: typography.teenie.lineHeight,
				padding: '9px 10px',
			},
		}
	);
});
