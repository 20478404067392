import { ifFeature } from '@bamboohr/utils/lib/feature';
import { InputStatus } from '~components/form/components/common/outlined-input/types/outlined-input-props';
import { alpha, darken, lighten } from '@mui/material/styles';
import { shadows } from '~definitions/json/encore-shadows.json';
import { Mixins } from '@mui/material/styles/createMixins';
import { Palette, PaletteColor } from '@mui/material/styles/createPalette';
import { FieldStatus } from 'components/form/types';
import { InputOutlineOptions } from 'types/mui/material/styles/createMixins';

/**
 * Determines the color for a given input status.
 * @param status - The input status.
 * @param palette - The color palette.
 * @param defaultColor - The default color to use if no specific color is defined for the status.
 * @returns The color for the input status.
 */
function fieldStatusColor(
	status: InputStatus | FieldStatus,
	palette: Palette,
	defaultColor = ifFeature('encore', palette.gray[900], palette.gray[800])
) {
	switch (status) {
		case 'default':
			return palette.primary.main;
		case 'error':
			return palette.error.main;
		case 'info':
			return palette.info.main;
		case 'warning':
			return palette.warning.main;
		default:
			return defaultColor;
	}
}

/**
 * Generates a box shadow CSS value.
 * @param strength - The strength of the shadow.
 * @param color - The color of the shadow.
 * @returns The box shadow CSS value.
 */
const boxShadow = (strength: keyof typeof shadows, color: string) => {
	return `${shadows[strength].lengths} ${alpha(color, shadows[strength].opacity)}`;
};

const border = (width: number, color: string): string => {
	return `${width}px solid ${color}`;
};

const buttonReset = {
	background: 'none',
	border: 'none',
	cursor: 'pointer',
	textDecoration: 'none',
	'-moz-appearance': 'none',
	'-webkit-appearance': 'none',
};

function focusRing(paletteColor: PaletteColor) {
	return {
		border: `1px solid ${paletteColor.lighter}`,
		boxShadow: `0 0 0 2px ${alpha(paletteColor.lightest, 0.35)}`,
		outline: 0,
	};
}

function hexToRgb(hex: string): string {
	const matches = hex.match(/[A-Za-z0-9]{2}/g);
	return matches ? matches.map(v => parseInt(v, 16)).join() : '';
}

function inlineSvg(svg: string, { fill }: { fill: string }): string {
	const fillReplaced = svg.replace(/fill="#0{3}(?:0{3})?"/g, `fill="${fill}"`);
	const base64Svg = window.btoa(fillReplaced);
	return `url("data:image/svg+xml;base64,${base64Svg}")`;
}

/**
 * Generates the CSS styles for an input outline.
 * @param palette - The color palette from the Theme.
 * @param options - The outline options.
 * @returns The CSS style parameters for the input outline.
 */
const inputOutline = ({ palette }: { palette: Palette }, options: InputOutlineOptions) => {
	const { disabled, status, viewMode, focused = false, hover = false } = options;

	// Constants for style configurations
	const SHADOW_STRENGTH_FOCUSED = '500';
	const SHADOW_STRENGTH_UNFOCUSED = '100';
	const BORDER_INCREASE_FOCUSED = 0.5;

	// Disabled state has a different border color and no shadow
	if (disabled) {
		return {
			border: `1px solid ${palette.gray[400]}`,
		};
	}

	// If a status is present, adjust the color and add a boxShadow border when focused (for better transition animation)
	// The default status is undefined; this only applies to error, warning, and info
	if (status) {
		const color = fieldStatusColor(status, palette);
		if (focused) {
			return {
				border: `1px solid ${color}`,
				boxShadow: `${boxShadow(SHADOW_STRENGTH_FOCUSED, color)}, 0 0 0 ${BORDER_INCREASE_FOCUSED}px ${color}`,
			};
		}
		return {
			border: `1px solid ${color}`,
			boxShadow: `${boxShadow(SHADOW_STRENGTH_UNFOCUSED, fieldStatusColor(status, palette))}`,
		};
	}

	// Default status (status undefined)
	if (focused) {
		const color = palette.primary.lighter;
		return {
			border: `1px solid ${color}`,
			boxShadow: `${boxShadow(SHADOW_STRENGTH_FOCUSED, color)}, 0 0 0 ${BORDER_INCREASE_FOCUSED}px ${color}`,
		};
	}

	// ViewMode has a different border color and no shadow
	if (viewMode) {
		// Adjust border color based on hover state
		const hoverBorderColor = hover ? palette.gray[300] : palette.gray[200];
		return {
			border: `1px solid ${hoverBorderColor}`,
		};
	}

	// Default state
	return {
		border: `1px solid ${palette.gray[400]}`,
		boxShadow: `${boxShadow(SHADOW_STRENGTH_UNFOCUSED, palette.gray[900])}`,
	};
};

function popoverSurface(palette: Palette) {
	return {
		border: border(1, palette.gray[600]),
		boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
	};
}
export const px = (value: number): string => `${value}px`;

export function getMixins(): Partial<Mixins> {
	return {
		alpha,
		border,
		boxShadow,
		buttonReset,
		darken,
		fieldStatusColor,
		focusRing,
		hexToRgb,
		inlineSvg,
		inputOutline,
		lighten,
		popoverSurface,
	};
}
