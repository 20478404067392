import { BreakpointsOptions } from '@mui/material';

export const getBreakpoints = (): BreakpointsOptions => ({
	values: {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1200,
		xl: 1800,
	},
	unit: 'px',
	step: 5,
});
