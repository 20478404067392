import { Flex, Headline, Loader } from '@bamboohr/fabric';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChatLogs } from 'src/components/ChatLogs';
import SideBar from 'src/components/sideBar/SideBar';
import UserAvatar from 'src/components/UserAvatar';
import { useLlmStore, usePromptStore } from 'src/store';
import { Chat, LlmConfig } from 'src/types/global';
import NewChatContainer from '../../components/NewChatContainer';
import config from '../../config';
import api from '../../services/api';
import { reportError } from '../../utils/rollbar';
import { ChatLog } from './home.types';
import { buildChatRequest, getChatSessions, hasCustomLLMConfig } from './utils';
import { useMessageStore } from 'src/store';
import { variantData } from 'src/components/messageBanner/variantData';

// Configs
const CHAT_ENDPOINT = config.VERSION_ENDPOINT + 'chat';
const CHAT_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + 'chat-session';
const GENERATE_NAME_ENDPOINT =
	config.VERSION_ENDPOINT + 'chat-session/generate-name';

const Home = ({ currentUser, setCurrentUser }) => {
	// Chat Info & Data
	const [currentChatSessionId, setCurrentChatSessionId] = useState('');
	const [currentChatSessionName, setCurrentChatSessionName] = useState('');
	const [chatSessionNames, setChatSessionNames] = useState<Chat[]>([]);
	const [chatLog, setChatLog] = useState<ChatLog[]>([]);
	const [isChatloading, setIsChatLoading] = useState<boolean>(false);
	const [hasPrinted, setHasPrinted] = useState(false);

	// Input & Feedback State
	const [responseFromAPI, setReponseFromAPI] = useState(false);
	const [errorOccurred, setErrorOccurred] = useState(false);

	const [form, setForm] = useState(null);
	const [modes, setModes] = useState({});

	const showMessage = useMessageStore((state) => state.setMessage);

	// Refs
	const chatLogRef = useRef<HTMLDivElement>(null);

	const prompt = usePromptStore((state) => state.prompt);
	const updatePrompt = usePromptStore((state) => state.updatePrompt);
	const clearPrompt = usePromptStore((state) => state.clearPrompt);
	const llmId = useLlmStore((state) => state.llmId);
	const llmLabel = useLlmStore((state) => state.llmLabel);
	const llmConfig = useLlmStore((state) => state.llmConfig);
	const setLlmId = useLlmStore((state) => state.updateLlmId);
	const setLlmLabel = useLlmStore((state) => state.updateLlmLabel);
	const updateLlmState = useLlmStore((state) => state.updateLlmState);

	const displayErrorBanner = (status: string) => {
		const { headerText, subText } =
			variantData[status] || variantData['generic-error'];

		showMessage({
			message: headerText,
			description: subText,
			visible: true,
			type: 'error',
		});
	};

	useEffect(() => {
		if (chatSessionNames.length === 0) {
			async function fetchData() {
				const newChatSessions = await getChatSessions(CHAT_SESSIONS_ENDPOINT);
				setChatSessionNames(newChatSessions);
			}
			fetchData();
		}
	}, [chatSessionNames.length, modes]);

	const removeMostRecentPrompt = useCallback(() => {
		const newChatLog = [...chatLog];
		newChatLog.pop();
		setChatLog(newChatLog);
	}, [chatLog]);

	useEffect(() => {
		if (errorOccurred) {
			removeMostRecentPrompt();
			setErrorOccurred(false);
			if (chatLog.length === 1) {
				api.delete(`${CHAT_SESSIONS_ENDPOINT}/${currentChatSessionId}`);
			}
		}
	}, [chatLog, currentChatSessionId, errorOccurred, removeMostRecentPrompt]);

	useEffect(() => {
		if (!currentChatSessionId) return;

		const thisChat = chatSessionNames.find(
			(x) => x.id === currentChatSessionId,
		);

		if (thisChat) {
			setLlmId(thisChat.llmId || config.DEFAULT_FUNCTION_ID);
			setLlmLabel(thisChat.llmLabel || config.DEFAULT_FUNCTION_LABEL);
		}
	}, [currentChatSessionId, chatSessionNames, setLlmId, setLlmLabel]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 400);
	}, [chatLog]);

	const callAPI = async (inputPrompt: string) => {
		try {
			let response;
			if (chatLog.length > 0) {
				const request = buildRequest(currentChatSessionId, inputPrompt);
				response = await api.post(CHAT_ENDPOINT, request);
			} else {
				// Create new Chat Session document
				const newChatSession = await api.post(CHAT_SESSIONS_ENDPOINT);
				setCurrentChatSessionId(newChatSession.data.id);

				if (llmId === 'mini-rag') {
					response = await api.put(
						`${CHAT_SESSIONS_ENDPOINT}/${newChatSession.data.id}/update-context`,
						form,
						{ headers: { 'Content-Type': 'multipart/form-data' } },
					);
				} else {
					// Make new Chat document
					const request = buildRequest(newChatSession.data.id, inputPrompt);
					response = await api.post(CHAT_ENDPOINT, request);
				}
				const status = response.data.status;
				if (status === 'llm-service-error' || status === 'pdf-parse-error') {
					throw new Error('LLM service provider unavailable');
				}
				const sessionName = await api.post(GENERATE_NAME_ENDPOINT, {
					question: inputPrompt,
					answer: response.data.observation,
				});
				setChatSessionNames((prev) => [
					...prev,
					{
						name: sessionName.data,
						id: newChatSession.data.id,
						llmId,
						llmLabel,
						llmConfig,
						time_created: new Date(newChatSession.data.time_created),
					},
				]);
				setCurrentChatSessionName(sessionName.data);
				await api.put(`${CHAT_SESSIONS_ENDPOINT}/${newChatSession.data.id}`, {
					session_name: sessionName.data,
					function_id: llmId,
					llm_config: llmConfig,
					llm_label: llmLabel,
				});
			}
			const data = response.data;
			setChatLog((prev) => [
				...prev.slice(0, -1), // Remove the last prompt to avoid duplication
				{
					chatPrompt: inputPrompt,
					botMessage: data.observation,
					id: data.id,
					llmId,
					llmLabel,
					rating: data.rating,
					unhelpful: data.unhelpful,
					incorrect: data.incorrect,
					harmful: data.harmful,
					comment: data.comment,
					sources: data.sources,
				},
			]);
			if (data.status !== null || data.status === 'token-limit-exceeded') {
				throw new Error(data.status);
			}
		} catch (error) {
			updatePrompt(inputPrompt);
			console.error(error);
			const status =
				error.status || error?.response?.data?.status || 'generic-error';

			if (error.response?.status === 401) {
				displayErrorBanner('cookies-expired');
			} else {
				displayErrorBanner(status);
			}

			setErrorOccurred(true);
			reportError('Error in AI Sandbox', error);
		}
		setReponseFromAPI(false);
		setIsChatLoading(false); // Ensure the loader is hidden after the API call
	};

	const handleSubmit = async (
		textAreaTarget: HTMLTextAreaElement | HTMLInputElement,
	) => {
		setHasPrinted(false);

		if (!llmId) return;

		if (llmId === 'mini-rag' && form === null && chatLog.length === 0) {
			alert('You must upload a file to use this mode');
			return;
		}

		const userPrompt = prompt;
		clearPrompt();
		textAreaTarget.style.height = 'auto';

		if (!responseFromAPI && userPrompt.trim() !== '') {
			setReponseFromAPI(true);
			setChatLog((prev) => [...prev, { chatPrompt: userPrompt }]);
			textAreaTarget.blur();
			await callAPI(userPrompt);

			if (prompt !== '') {
				textAreaTarget.style.height = `${textAreaTarget.scrollHeight}px`;
			}
			textAreaTarget.focus();
		}
	};

	const buildRequest = (chatSessionId: string, inputPrompt: string) => {
		const context = { prompt: inputPrompt };
		const params = { context };
		if (hasCustomLLMConfig(config, llmId)) {
			context['messages'] = buildChatRequest(chatLog, inputPrompt);
			params['llm-config'] = llmConfig;
		} else {
			context['question'] = inputPrompt;
		}
		return { function_id: llmId, 'chat-session-id': chatSessionId, params };
	};

	const scrollToBottom = () => {
		if (chatLogRef.current) {
			const isUserAtBottom =
				chatLogRef.current.scrollHeight - chatLogRef.current.scrollTop ===
				chatLogRef.current.clientHeight;
			if (isUserAtBottom) {
				chatLogRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
				});
			}
		}
	};

	const logout = async () => {
		setChatLog([]);
		setChatSessionNames([]);
		setCurrentUser(null);
	};

	const createNewChat = () => {
		setChatLog([]);
		setCurrentChatSessionId('');
		setCurrentChatSessionName('');
		setForm(null);
		updateLlmState({
			newId: config.DEFAULT_FUNCTION_ID,
			newLabel: config.DEFAULT_FUNCTION_LABEL,
			newConfig: config.DEFAULT_LLM_CONFIG,
		});
	};

	const clearAllChats = () => {
		setChatLog([]);
		setCurrentChatSessionId('');
		setCurrentChatSessionName('');
		setChatSessionNames([]);
		setForm(null);
		updateLlmState({
			newId: config.DEFAULT_FUNCTION_ID,
			newLabel: config.DEFAULT_FUNCTION_LABEL,
			newConfig: config.DEFAULT_LLM_CONFIG,
		});
	};

	const loadChatLogs = async (
		chatID: string,
		chatName: string,
		label: string,
		id: string,
		newConfig: LlmConfig,
	) => {
		setHasPrinted(true);
		setIsChatLoading(true);
		try {
			const res = await api.get(`${CHAT_ENDPOINT}/${chatID}`);
			const savedChatLog = res.data.map((resp) => ({
				chatPrompt: resp['prompt'],
				botMessage: resp['response'],
				id: resp.id,
				rating: resp['rating'],
				unhelpful: resp['unhelpful'],
				incorrect: resp['incorrect'],
				harmful: resp['harmful'],
				comment: resp['comment'],
				sources: resp['sources'],
			}));
			setChatLog(savedChatLog);
			setCurrentChatSessionId(chatID);
			setCurrentChatSessionName(chatName);
			updateLlmState({
				newId: id,
				newLabel: label,
				newConfig,
			});
			clearPrompt();
			setIsChatLoading(false);
		} catch (error) {
			const status =
				error.status || error?.response?.data?.status || 'generic-error';
			displayErrorBanner(status);
		}
	};

	return (
		<Flex height="100%" width="100vw" padding={'24px'}>
			<SideBar
				createNewChat={createNewChat}
				loadChatLogs={loadChatLogs}
				chatNames={chatSessionNames}
				setChatNames={setChatSessionNames}
				setChatName={setCurrentChatSessionName}
				activeId={currentChatSessionId}
			/>
			<Flex
				flexDirection={'column'}
				width="100%"
				height="100%"
				gap={2}
				minWidth={0}
			>
				<Flex alignItems="center" justifyContent={'space-between'}>
					<span className="chatSessionNameContainer">
						<Headline size="medium">{currentChatSessionName}</Headline>
					</span>
					<UserAvatar
						currentUser={currentUser}
						logout={logout}
						clearAllChats={clearAllChats}
					/>
				</Flex>
				<div className="chatContainer">
					{isChatloading ? (
						<Flex flex={1} alignItems="center" justifyContent="center">
							<Loader />
						</Flex>
					) : (
						<>
							{chatLog.length > 0 ? (
								<ChatLogs
									chatLogRef={chatLogRef}
									chatLog={chatLog}
									handleSubmit={handleSubmit}
									hasPrinted={hasPrinted}
									setHasPrinted={setHasPrinted}
								/>
							) : (
								<>
									<NewChatContainer
										currentUser={currentUser}
										handleSubmit={handleSubmit}
										setModes={setModes}
										setForm={setForm}
									/>
								</>
							)}
						</>
					)}
				</div>
			</Flex>
		</Flex>
	);
};

export default Home;
