import { tabUnstyledClasses } from '@mui/base';
import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { BoxTypeMap } from '@mui/system';
import React, { ElementType, forwardRef, ReactNode, Ref } from 'react';
import { SideTabTextNote } from './side-tab-text-note';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

const useStyles = makeStyles()(({ mode, palette, typography }) => {
	return {
		root: {
			color: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[800],
			[`.${tabUnstyledClasses.disabled} &`]: {
				color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[500],
			},
			[`.${tabUnstyledClasses.selected} &`]: {
				color: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
				fontWeight: typography.fontWeightHeavy,
			},
		},
	};
});

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabTextOwnProps {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	className?: string;
	/** Additional, muted text that is appended to the main text. */
	note?: ReactNode;
	/**
	 * @deprecated sx should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	sx?: TypographyProps['sx'];
}

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export type SideTabTextProps<Component extends ElementType = BoxTypeMap['defaultComponent']> = TypographyProps<
	Component,
	SideTabTextOwnProps
>;

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * A wrapper around the text of a tab to provide proper styling.
 *
 * You likely won't need to use this directly as it's included in `SideTab`.
 */
export const SideTabText = forwardRef(function SideTabText({ children, note, ...props }: SideTabTextProps, ref: Ref<HTMLSpanElement>) {
	const { classes } = useStyles();

	return (
		<Typography classes={classes} component="span" {...props} ref={ref}>
			{children} {note && <SideTabTextNote>{note}</SideTabTextNote>}
		</Typography>
	);
}) as OverridableComponent<TypographyTypeMap<SideTabTextOwnProps>>;
