import { Tabs as MuiTabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { FC, PropsWithChildren, useState } from 'react';
import { styles } from './tabs.styles';
import { TabsProps } from './types/tabs-props';
import { TabDropdown } from './tab-dropdown';
import { TabsContext } from './tabs-context';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TabsJade } from './tabs-jade';

/**
 * Tabs organize and allow navigation between groups of content that are related and at the same level of hierarchy.
 * This component is a wrapper around Material-UI Tabs @link https://material-ui.com/components/tabs/#tabs
 */
export const Tabs = withStyles(styles)((props: PropsWithChildren<TabsProps>) => {
	const {
		ariaLabel = '',
		classes = {},
		// `value` type comes from MUI as `any`
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		value,
		onChange,
		dark = false,
		children,
		dropdownLabel = window.jQuery ? $.__('More') : 'More',
		fixedDropdownItems,
		fixedDropdownPosition = false,
		mode = 'line',
	} = props;

	// handle legacy values
	let { size = 'medium', variant = 'standard' } = props;
	if (!['medium', 'large'].includes(size)) {
		size = 'medium';
	}
	if (variant === 'scrollable') {
		variant = 'standard';
	}

	const { modeFill, modeLine, root, sizeLarge } = classes;

	const [isSelectedValueFromDropdown, setIsSelectedValueFromDropdown] = useState<boolean>(false);
	const handleChange = (event, value, isDropdownValue = false) => {
		setIsSelectedValueFromDropdown(isDropdownValue);
		onChange(value, event);
	};

	return ifFeature(
		'encore',
		<TabsContext.Provider value={{ dark: dark && mode === 'fill', fixedDropdownPosition, mode, size, value }}>
			<MuiTabs
				aria-label={ariaLabel}
				className={classnames({
					[root as string]: true,
					[modeFill as string]: mode === 'fill',
					[modeLine as string]: mode === 'line',
					[sizeLarge as string]: size === 'large',
				})}
				onChange={handleChange}
				scrollButtons={false}
				selectionFollowsFocus={false}
				value={isSelectedValueFromDropdown ? false : (value as unknown)}
				variant={variant}
			>
				{children}
				<TabDropdown
					fixedItems={fixedDropdownItems}
					fixedPosition={fixedDropdownPosition}
					label={dropdownLabel}
					onSelect={value => handleChange(null, value, true)}
					size={size}
					tabs={children}
				/>
			</MuiTabs>
		</TabsContext.Provider>,
		<TabsJade {...props} />
	);
}) as FC<PropsWithChildren<TabsProps>>;
