import { InputAdornment as MUIInputAdornment } from '@mui/material';
import { makeStyles as makeStylesMUI, createStyles as createStylesMUI } from '@mui/styles';

// Material-ui Components
export { ClickAwayListener, Collapse, Container, ImageList as GridList, useMediaQuery, Popper, styled } from '@mui/material';
export { withStyles, useTheme } from '@mui/styles';
/**
 * @deprecated Please use `Nub` instead
 */
export const InputAdornment = MUIInputAdornment;

/**
 * We are restricting custom styles outside of Fabric. As alternatives, please use Fabric's layout components instead or ask about adding styles to a new component.
 * For more information about restricting styling in the design system, please see ADR-018: https://bamboohr.slite.com/app/docs/Tred4hApzcSVT2
 * @deprecated
 */
export const makeStyles = makeStylesMUI;

/**
 * We are restricting custom styles outside of Fabric. As alternatives, please use Fabric's layout components instead or ask about adding styles to a new component.
 * For more information about restricting styling in the design system, please see ADR-018: https://bamboohr.slite.com/app/docs/Tred4hApzcSVT2
 * @deprecated
 */
export const createStyles = createStylesMUI;

// Material-ui Types
export type {
	AvatarProps,
	ClickAwayListenerProps,
	CollapseProps,
	CreateMUIStyled,
	PopperProps,
	PopperPlacementType,
	GridSize,
	GridDirection,
	GridWrap,
	Theme,
} from '@mui/material';

export type { WithStyles } from '@mui/styles';
