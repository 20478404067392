import { Flex, IconV2 } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';
import SandboxIcon from '../icons/Sandbox';
import NavContent from './NavContent';
import NewChatNavButton from './NewChatNavButton';
import { LlmConfig, Chat } from 'src/types/global';

interface SideBarProps {
	createNewChat: () => void;
	loadChatLogs: (
		chatID: string,
		chatName: string,
		label: string,
		id: string,
		newConfig: LlmConfig,
	) => void;
	chatNames: Chat[];
	setChatNames: (chats: Chat[]) => void;
	setChatName: (name: string) => void;
	activeId: string;
}

export default function SideBar({
	createNewChat,
	loadChatLogs,
	chatNames,
	setChatNames,
	setChatName,
	activeId,
}: SideBarProps) {
	const [isPinned, setIsPinned] = useState(true);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		function handleMouseMove(event) {
			if (event.clientX <= 150) {
				setIsHovered(true);
			}
		}

		window.addEventListener('mousemove', handleMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	const handleMouseEnter = () => {
		if (!isPinned) {
			setIsHovered(true);
		}
	};

	const handleMouseLeave = () => {
		if (!isPinned) {
			setIsHovered(false);
		}
	};

	return (
		<aside
			className={`sideMenu ${isPinned ? 'pinned' : ''} ${isHovered ? 'hovered' : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{
				marginRight: isPinned ? '24px' : '0',
				position: isPinned ? 'relative' : 'absolute',
				zIndex: isPinned ? '1' : '1000',
				display: isPinned || isHovered ? 'flex' : 'none',
				padding: !isPinned && isHovered ? '24px' : '0',
			}}
		>
			<Flex gap="8px" alignItems={'center'} justifyContent="space-between">
				<Flex gap="8px" alignItems={'center'}>
					<SandboxIcon fill="#48413F" />
					<h1 className="sideMenu-title">AI Sandbox</h1>
				</Flex>

				<button
					onClick={() => setIsPinned(!isPinned)}
					className="sideMenu-collapse-button"
				>
					<IconV2
						color="neutral-strong"
						size={16}
						name={
							isPinned
								? 'arrow-left-to-line-regular'
								: 'arrow-right-to-line-regular'
						}
					/>
				</button>
			</Flex>

			<NewChatNavButton createNewChat={createNewChat} />

			<NavContent
				loadChatLogs={loadChatLogs}
				chatNames={chatNames}
				setChatNames={setChatNames}
				setChatName={setChatName}
				activeId={activeId}
			/>
		</aside>
	);
}
