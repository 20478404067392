import { InputValueData, MaskConfig } from '../types';
import { getCursorType, getPartIndexes, getPartRelativeCursorIndexes } from '../utils';

export function removeExtraDelimiters(
	config: MaskConfig,
	inputValue: string,
	startPosition: number,
	endPosition: number
): InputValueData {
	let newInputValue = '';
	let newStartPosition = startPosition;
	let newEndPosition = endPosition;
	let hasInputValueStarted = false;
	const delimiter = config.delimiterChar;
	const inputValueArray = inputValue.split('');
	inputValueArray.forEach((char, index) => {
		const nextChar = inputValueArray[index + 1];

		if (hasInputValueStarted) {
			if (char === delimiter && nextChar === delimiter) {
				if (index < startPosition) {
					newStartPosition--;
				}
				if (index < endPosition) {
					newEndPosition--;
				}
			} else if (char === delimiter && nextChar === undefined) {
				if (index < startPosition) {
					newStartPosition = index;
				}
				if (index < endPosition) {
					newEndPosition = index;
				}
			} else {
				newInputValue = `${newInputValue}${char}`;
			}
		} else if (char === delimiter) {
			if (index < startPosition) {
				newStartPosition--;
			}
			if (index < endPosition) {
				newEndPosition--;
			}
		} else {
			newInputValue = `${newInputValue}${char}`;
			hasInputValueStarted = true;
		}
	});

	return {
		endPosition: newEndPosition,
		inputValue: newInputValue,
		startPosition: newStartPosition,
	};
}

export function repairInvalidDelimiterAddition(
	config: MaskConfig,
	keydownStartPosition: number,
	keydownEndPosition: number,
	inputValue: string,
	startPosition: number,
	endPosition: number
): InputValueData {
	const delimiter = config.delimiterChar;
	const inputValueParts = inputValue.split(delimiter);
	if (inputValueParts.length > config.order.length) {
		const cursorType = getCursorType(keydownStartPosition, keydownEndPosition);
		if (cursorType === 'point') {
			const inputIndexes = getPartIndexes(inputValue, delimiter);
			const relativeCursorIndexes = inputIndexes.map(partIndexes =>
				getPartRelativeCursorIndexes(partIndexes, startPosition, endPosition)
			);
			const foundIndex = relativeCursorIndexes.findIndex(part => part?.startIndex === 0);
			if (foundIndex > -1) {
				const removeIndex = inputIndexes[foundIndex].startIndex - 1;
				const inputValueArray = inputValue.split('');
				inputValueArray.splice(removeIndex, 1);
				inputValue = inputValueArray.join('');

				if (removeIndex < startPosition) {
					startPosition--;
				}

				if (removeIndex < endPosition) {
					endPosition--;
				}
			}
		}
	}

	return {
		endPosition,
		inputValue,
		startPosition,
	};
}
