import React, { ReactElement } from 'react';
import { clsx } from '~styles';
import { useStyles } from './carousel-base.styles';
import { CarouselBaseProps } from './types';
import { createWidth, createLeft } from './utils';

export function CarouselBase({
	ariaLabelledBy,
	containerRef,
	containerRole = 'group',
	currentIndex,
	slideGap = '0',
	slides,
	slidesToShow = 1,
	singlePageSlideAlignment,
}: CarouselBaseProps): ReactElement {
	const { classes } = useStyles({ singlePageSlideAlignment });

	const width = createWidth(slideGap, slidesToShow, slides.length);
	const left = createLeft(slideGap, slidesToShow, currentIndex);

	return (
		<div
			aria-atomic={false}
			aria-labelledby={ariaLabelledBy}
			aria-live="polite"
			aria-roledescription="carousel"
			className={classes.container}
			ref={containerRef}
			role={containerRole}
		>
			<div
				className={classes.slider}
				style={{
					width,
					left,
					gridColumnGap: slideGap,
				}}
			>
				{slides.map((slideData, index) => {
					const hideSlide = index < currentIndex || index >= currentIndex + slidesToShow;
					return (
						<div
							aria-labelledby={slideData.ariaLabelledBy}
							aria-roledescription="slide"
							className={clsx({ [classes.hideSlide]: hideSlide })}
							key={slideData.ariaLabelledBy}
							role="group"
						>
							{slideData.slide}
						</div>
					);
				})}
			</div>
		</div>
	);
}
