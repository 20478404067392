import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { FormControl, FormGroup } from '@mui/material';

import { Checkbox } from './checkbox';
import { getBiId, getValue, updateItems } from './checkbox-group.domain';
import { useStyles } from './checkbox-group.styles';
import { CheckboxGroupProps, CheckboxOnChangeParam } from './types';
import { FieldContext } from '@fabric/form/context';
import { GroupLegend } from '../form/components/common/group-legend';
import { uniqueId } from 'lodash';

export const CheckboxGroup = forwardRef<HTMLFieldSetElement, CheckboxGroupProps>(
	(
		{
			biId,
			CheckboxProps = {},
			classes = {},
			className,
			disabled,
			form,
			items = [],
			label,
			name,
			note,
			onChange,
			required,
			size = 'medium',
			status = 'default',
			tabIndex,
			value,
		}: CheckboxGroupProps,
		ref
	): JSX.Element => {
		const { classes: styles } = useStyles();
		const noteId = useMemo(() => uniqueId('checkbox-group-note'), []);
		const legendId = useMemo(() => uniqueId('radio-group-legend'), []);

		function handleItemChange(param: CheckboxOnChangeParam, e: React.ChangeEvent<HTMLInputElement>): void {
			const updatedItems = updateItems(items, param, value);

			onChange(
				{
					updatedItems,
					value: getValue(updatedItems),
				},
				e
			);
		}

		return (
			<FormControl
				aria-describedby={note ? noteId : undefined}
				classes={{
					root: classNames(styles.root, classes.root),
				}}
				className={className}
				component="fieldset"
				disabled={disabled}
				ref={ref}
				required={required}
			>
				<div data-bi-id={getBiId(biId, name)}>
					{label && (
						<FieldContext.Provider value={{ status, required, disabled, noteId }}>
							<GroupLegend id={legendId} label={label} note={note} />
						</FieldContext.Provider>
					)}
					<FormGroup
						classes={{
							root: classes.checkboxContainer,
						}}
					>
						{items.map((item, index) => {
							const checked = !!(value?.includes(item.value) || item.checked);
							return (
								<Checkbox
									{...CheckboxProps}
									biId={biId}
									checked={checked}
									className={classNames(styles.checkbox, classes.checkbox)}
									disabled={disabled || item.disabled}
									form={form}
									id={item.id}
									key={item.id || `${item.value}-${index}`}
									label={item.label}
									name={item.name || name}
									note={item.note}
									onChange={handleItemChange}
									size={size}
									tabIndex={tabIndex}
									value={item.value}
								/>
							);
						})}
					</FormGroup>
				</div>
			</FormControl>
		);
	}
);
