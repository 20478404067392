import {
	CircleExclamation12x12,
	CircleExclamation14x14,
	CircleExclamation18x18,
	TriangleExclamation12x11,
	TriangleExclamation14x12,
	TriangleExclamation18x16,
} from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactNode } from 'react';
import { IconV2 } from '~components/icon-v2';

import { CheckboxSize, CheckboxStatus } from './types';

export const SIZE_TO_PIXELS_MAP = {
	biggie: 24,
	large: 20,
	medium: 16,
	small: 14,
};

export const ENCORE_SIZE_MAP: { [key: string]: CheckboxSize } = {
	biggie: 'large',
	large: 'large',
	medium: 'medium',
	small: 'medium',
};

export function getBiId(biId = '', name = '', value: string | number): string | undefined {
	if (biId) {
		const basePart = `${biId}-checkbox`;
		const namePart = name ? `-${name}` : '';
		const valuePart = `-${value}`;

		return `${basePart}${namePart}${valuePart}`;
	}
}

export function getCheckboxToLabelMargin(size: CheckboxSize): number {
	return ifFeature('encore', size === 'biggie' || size === 'large' ? 12 : 8, size === 'biggie' ? 10 : 8);
}

export function getPixelsFromSize(size: CheckboxSize): number {
	return SIZE_TO_PIXELS_MAP[size] || SIZE_TO_PIXELS_MAP.medium;
}

export function getSelectedIconScaleFromSize(size: CheckboxSize): number {
	// fab-check-14x14 and minus-12x3 icons should be 100% scale when size is 'biggie'.
	// Smaller icon sizes will scale proportionally.
	return getPixelsFromSize(size) / SIZE_TO_PIXELS_MAP.biggie;
}

export function getStatusIcon(status: CheckboxStatus, size: CheckboxSize): ReactNode {
	if (status === 'error') {
		switch (size) {
			case 'small':
				return <CircleExclamation12x12 />;
			case 'medium':
				return <CircleExclamation14x14 />;
			case 'large':
				return <CircleExclamation18x18 />;
			case 'biggie':
				return <CircleExclamation18x18 />;
			default:
				return <CircleExclamation14x14 />;
		}
	}

	if (status === 'warning') {
		switch (size) {
			case 'small':
				return <TriangleExclamation12x11 />;
			case 'medium':
				return <TriangleExclamation14x12 />;
			case 'large':
				return <TriangleExclamation18x16 />;
			case 'biggie':
				return <TriangleExclamation18x16 />;
			default:
				return <TriangleExclamation14x12 />;
		}
	}
}

export function getEncoreStatusIcon(status: CheckboxStatus): ReactNode {
	if (status === 'error') {
		return <IconV2 name="circle-exclamation-solid" size={16} />;
	}

	if (status === 'warning') {
		return <IconV2 name="triangle-exclamation-solid" size={16} />;
	}
}
