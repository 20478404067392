import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles, createStyles, Theme } from '~mui';
import { dyeColors, ModeOptions } from '~styles';

export const useStyles = makeStyles(({ borderRadiuses, mode }: Theme) =>
	createStyles({
		root: {
			aspectRatio: '16 / 9',
			display: 'flex',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			overflow: 'hidden',
			width: ifFeature('encore', '100%', '704px'),
		},
		iframe: {
			aspectRatio: 'inherit',
			height: '100%',
			width: '100%',
		},
		rounded: {
			borderRadius: ifFeature('encore', borderRadiuses[400], '10px'),
		},
	})
);
