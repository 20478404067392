import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

import { sizeStyles } from './icon-button-sizes.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const styles = (theme: Theme) => {
	const { constructs, palette, mixins } = theme;

	return createStyles({
		active: {},
		altStyling: {},
		focusVisible: {},
		outlinedPrimary: {},
		outlinedSecondary: {},
		endIcon: {},
		disabled: {
			'&$floatingIcon': {
				borderColor: ifFeature('encore', undefined, 'transparent'),
				boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0)'),
			},
			'&$noBoundingBox': {
				fill: ifFeature('encore', palette.gray[500]),
			},
		},
		processing: {
			'&$floatingIcon': {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite),
				borderColor: ifFeature('encore', undefined, palette.grey[300]),
			},
		},
		floatingIcon: {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			boxShadow: ifFeature('encore', 'none', '0 1px 0 0 rgba(0, 0, 0, 0)'),
			verticalAlign: ifFeature('encore', undefined, 'unset'),
			// GOTCHA: Adding the `hover` boxShadow here will override `active` and `focus` boxShadow styles on `outlinedPrimary` and `outlinedSecondary` button classes due to specificity. For this reason, we must reinclude the `outlinedPrimary` and `outlinedSecondary` active and focus styles at this level.
			'&:hover': {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite, palette.primary.contrastText),
				boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0.05)'),
			},
			'&:focus, &$focusVisible': {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.forcedWhite),
			},
			'&$outlinedPrimary': {
				'&:active, &$active': {
					boxShadow: ifFeature('encore', undefined, 'inset 0 1px 1px rgba(0, 0, 0, 0.12)'),
				},
				'&$focusVisible': {
					boxShadow: ifFeature(
						'encore',
						undefined,
						`0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 2px ${mixins.alpha?.(palette.primary.lightest, 0.35)}`
					),
				},
			},
			'&$outlinedSecondary': {
				'&:active, &$active': {
					boxShadow: ifFeature('encore', undefined, `inset 0 1px 1px rgba(0, 0, 0, 0.15)`),
				},
				'&$focusVisible': {
					boxShadow: ifFeature('encore', undefined, `0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 2px ${palette.grey[300]}`),
				},
			},
			'&$altStyling': {
				borderColor: 'transparent',
				boxShadow: ifFeature('encore', 'none', '0 1px 0 0 rgba(0, 0, 0, 0)'),
				'&:hover': {
					backgroundColor: ifFeature('encore', undefined, 'transparent'),
					boxShadow: ifFeature('encore', undefined, '0 2px 2px 0 rgba(0, 0, 0, 0.15)'),
				},
			},
		},
		noBoundingBox: {
			backgroundColor: ifFeature('encore', undefined, 'transparent'),
			fill: ifFeature('encore', palette.gray[600], palette.grey[600]),
			verticalAlign: ifFeature('encore', undefined, 'unset'),
			border: ifFeature('encore', 'none'),
			'&:hover': {
				backgroundColor: ifFeature('encore', undefined, 'transparent'),
				fill: ifFeature('encore', palette.gray[900], palette.grey[800]),
			},
			'&$altStyling': {
				fill: ifFeature('encore', undefined, mixins.alpha?.(palette.primary.contrastText, 0.7)),
				boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0)'),
				'&:hover': {
					fill: ifFeature('encore', undefined, palette.primary.contrastText),
					boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0)'),
				},
			},
			'&:active, &$active': {
				fill: ifFeature('encore', palette.gray[900]),
			},
			'&&$focusVisible, &&:focus': ifFeature('encore', {
				fill: palette.gray[800],
				border: 'none',
				boxShadow: 'none',
				outline: 'none',
				'&:after': {
					boxShadow: `0px 0px 0px 2px ${mixins.alpha(palette.primary.light, 0.8)}`,
					borderRadius: '4px',
					top: '-2px',
					left: '-2px',
					right: '-2px',
					bottom: '-2px',
				},
			}),
		},
		hoverOnly: {
			backgroundColor: ifFeature('encore', undefined, 'transparent'),
			cursor: ifFeature('encore', undefined, 'default'),
			fill: ifFeature('encore', palette.gray[600], palette.grey[600]),
			verticalAlign: ifFeature('encore', undefined, 'unset'),
			'&:hover': {
				backgroundColor: ifFeature('encore', undefined, 'transparent'),
				fill: palette.primary.main,
			},
			'&:active, &$active': {
				boxShadow: ifFeature('encore', undefined, 'none'),
			},
			'&$focusVisible, &:focus': {
				'&:after': {
					boxShadow: ifFeature('encore', `0px 0px 0px 2px ${mixins.alpha(palette.primary.light, 0.8)}`),
					borderRadius: ifFeature('encore', '4px'),
					top: ifFeature('encore', '-2px'),
					left: ifFeature('encore', '-2px'),
					right: ifFeature('encore', '-2px'),
					bottom: ifFeature('encore', '-2px'),
				},
			},
			'&$focusVisible': {
				boxShadow: ifFeature('encore', undefined, 'none'),
				fill: palette.primary.main,
			},
			'&$altStyling': {
				boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0)'),
				'&:hover': {
					boxShadow: ifFeature('encore', undefined, '0 1px 0 0 rgba(0, 0, 0, 0)'),
				},
			},
		},
		...sizeStyles(theme),
	});
};
