import React, { useCallback, useEffect, useRef } from 'react';
import CropperJs from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { useStyles } from './cropper.styles';
import { CropperProps } from './cropper.types';
import { loadCropper } from './load-cropper';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function Cropper({
	biId,
	imageAlt,
	initialCropBoxCoords,
	onChange,
	previewSelector,
	imageSrc,
	shape = ifFeature('encore', 'square', 'circle'),
}: CropperProps) {
	const { classes } = useStyles({ shape });
	const containerRef = useRef<HTMLDivElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const cropperRef = useRef<CropperJs>();

	const onCropUpdate = useCallback(() => {
		if (cropperRef.current) {
			const { height, width, x, y } = cropperRef.current.getData();
			const roundedCoords = {
				height: Math.round(height),
				width: Math.round(width),
				x: Math.round(x),
				y: Math.round(y),
			};
			onChange(roundedCoords);
		}
	}, [onChange]);

	useEffect(() => {
		const { cleanup } = loadCropper({
			containerRef,
			cropperRef,
			imageRef,
			initialCropBoxCoords,
			previewSelector,
			shape,
			onCropUpdate,
			biId,
		});

		return () => {
			cleanup();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shape, imageSrc]);

	return (
		<div className={classes.root} ref={containerRef}>
			<img alt={imageAlt} className={classes.image} ref={imageRef} src={imageSrc} />
		</div>
	);
}
