import React, { ReactElement } from 'react';
import { useStyles } from './styles';
import { GutterGroups } from '../gutter-groups';
import { useVirtualizer } from '../hooks';
import { MenuListCoreProps } from '../types';
import { VirtualItem } from '../virtual-item';

export const MenuListCore = (props: MenuListCoreProps): ReactElement => {
	const {
		activeIndex,
		className,
		getDisabled = () => false,
		getGroup,
		hasGutter,
		onActive,
		onSelect,
		renderGroup,
		renderItem,
		size,
		total,
	} = props;

	const { classes, cx } = useStyles();

	const virtualizer = useVirtualizer({
		activeIndex,
		total,
	});

	return (
		<div className={cx(classes.root, className)} ref={virtualizer.scrollContainerRef}>
			{hasGutter ? (
				<div className={classes.gutter} style={{ height: `${virtualizer.overallHeight}px` }}>
					{typeof getGroup === 'function' ? (
						<GutterGroups
							getGroup={getGroup}
							renderGroup={renderGroup}
							size={size}
							virtualItems={virtualizer.virtualItems}
						/>
					) : null}
				</div>
			) : null}

			<ul className={classes.virtualItems} style={{ height: `${virtualizer.overallHeight}px` }}>
				{/* This allows us to use the style prop to position the virtual item */
				/* eslint-disable react/forbid-component-props */}
				{virtualizer.virtualItems.map(virtualItem => {
					return (
						<VirtualItem
							active={activeIndex === virtualItem.index}
							classes={{
								root: classes.virtualItem,
								groupEnd: hasGutter ? classes.groupEndWithGutter : classes.groupEndWithoutGutter,
								groupLabel: hasGutter ? classes.groupLabelWithGutter : classes.groupLabelWithoutGutter,
							}}
							getDisabled={getDisabled}
							getGroup={getGroup}
							index={virtualItem.index}
							key={virtualItem.key}
							onActive={onActive}
							onSelect={onSelect}
							ref={virtualItem.measureRef}
							renderGroup={renderGroup}
							renderItem={renderItem}
							size={size}
							style={{ top: `${virtualItem.start}px` }}
							total={total}
						/>
					);
				})}
				{/* eslint-enable react/forbid-component-props */}
			</ul>
		</div>
	);
};
