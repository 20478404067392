import { isEnabled } from '@bamboohr/utils/lib/feature';
import { Slider } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import { SliderSelectProps } from './types';
import { useStyles } from './styles';
import classNames from 'classnames';

export const SliderSelect = (props: PropsWithChildren<SliderSelectProps>): ReactElement => {
	const {
		biId,
		className,
		classes = {},
		defaultValue,
		disabled,
		disableSwap,
		getAriaLabel,
		getAriaValueText,
		marks,
		max,
		min,
		onChange,
		onChangeCommitted,
		step,
		scale,
		staticLabels,
		value,
		valueLabelDisplay,
		valueLabelFormat,
		...rest
	} = props;

	let mutableMarks = marks;
	let mutableValueLabelDisplay = valueLabelDisplay;

	const { classes: styles } = useStyles({ staticLabels });

	if (staticLabels) {
		if (!isEnabled('encore')) {
			mutableMarks = false;
		}
		mutableValueLabelDisplay = 'off';
	}

	return (
		<>
			<Slider
				{...rest}
				classes={{
					mark: classNames(styles.mark, classes.mark, { [styles.markDisabled]: disabled }),
					markLabel: classNames(styles.markLabel, classes.markLabel),
					rail: classNames(styles.rail, classes.rail),
					root: classNames(styles.root, classes.root),
					thumb: classNames(styles.thumb, classes.thumb),
					track: classNames(styles.track, classes.track),
					valueLabel: classNames(styles.valueLabel, classes.valueLabel),
				}}
				className={classNames(styles.root, className)}
				// @ts-expect-error componentsProps doesn't support data-* attributes until MUI 6.0
				componentsProps={{ input: { 'data-bi-id': biId } }}
				defaultValue={defaultValue}
				disabled={disabled}
				disableSwap={disableSwap}
				getAriaLabel={getAriaLabel}
				getAriaValueText={getAriaValueText}
				marks={mutableMarks}
				max={max}
				min={min}
				onChange={onChange}
				onChangeCommitted={onChangeCommitted}
				scale={scale}
				step={step}
				value={value}
				valueLabelDisplay={mutableValueLabelDisplay}
				valueLabelFormat={valueLabelFormat}
			/>
			{Array.isArray(value) && staticLabels && (
				<div className={styles.customLabelsWrapper}>
					<span className={`${styles.customLabel} ${styles.leftLabel}`}>
						{typeof valueLabelFormat === 'function' ? valueLabelFormat(value[0], 0) : value[0]}
					</span>
					<span className={`${styles.customLabel} ${styles.rightLabel}`}>
						{typeof valueLabelFormat === 'function' ? valueLabelFormat(value[1], 1) : value[1]}
					</span>
				</div>
			)}
		</>
	);
};
