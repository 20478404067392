import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ spacing }) => {
	return {
		navigation: {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'nowrap',
			/* @startCleanup encore */
			'& > :nth-child(2)': {
				alignSelf: ifFeature('encore', undefined, 'center'),
			},
			/* @endCleanup encore */
			rowGap: ifFeature('encore', spacing(2), spacing(1.5)),
		},
	};
});
