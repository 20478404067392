import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ borderRadiuses, constructs, palette, spacing }) => {
	return {
		header: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[100]),
			borderTopLeftRadius: ifFeature('encore', borderRadiuses['300'], undefined),
			borderTopRightRadius: ifFeature('encore', borderRadiuses['300'], undefined),
			height: '100%',
			padding: spacing(2, 2.5),
		},
	};
});
