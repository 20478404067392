// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './styles';
import { TransferListProps, TransferListType, TransferListSide } from '../../types';
import { categorizeListItems } from '../transfer-list-category-list/etc/left-categorize-list-items';
import { TransferListBase } from '../transfer-list-base';

export function TransferList(props: TransferListProps): ReactElement {
	const {
		aboveListChildren,
		categorized = false,
		classes: classesOverride = {},
		LeftTransferListBaseProps = {},
		renderButtonControls,
		RightTransferListBaseProps = {},
		rightListItems = [],
		onLeftRowSelect,
		onRightRowSelect,
		reorderSubcategories,
		transferListType = TransferListType.MULTI_SELECT,
	} = props;

	const { classes } = ifFeature('encore', useStyles(), useStyles(undefined, { props: { classes: classesOverride } }));
	const leftListItems = categorizeListItems(props, categorized);

	return (
		<>
			{typeof aboveListChildren === 'function' && <div>{aboveListChildren()}</div>}
			<div className={classes.transferListRoot}>
				<div>
					<TransferListBase
						{...LeftTransferListBaseProps}
						ariaLabel={LeftTransferListBaseProps.title || (window.jQuery ? $.__('Browse items') : 'Browse items')}
						categorized={categorized}
						listItems={leftListItems}
						onRowSelect={onLeftRowSelect}
						transferListSide={TransferListSide.LEFT}
						transferListType={transferListType}
					/>
				</div>
				{ifFeature(
					'encore',
					transferListType !== TransferListType.SINGLE_SELECT &&
						typeof renderButtonControls === 'function' &&
						renderButtonControls(),
					<div className={classes.transferListCenterColumn}>
						{transferListType !== TransferListType.SINGLE_SELECT &&
							typeof renderButtonControls === 'function' &&
							renderButtonControls()}
					</div>
				)}
				<div>
					<TransferListBase
						{...RightTransferListBaseProps}
						ariaLabel={RightTransferListBaseProps.title || (window.jQuery ? $.__('Selected items') : 'Selected items')}
						categorized={categorized}
						listItems={rightListItems}
						onRowSelect={onRightRowSelect}
						reorderSubcategories={reorderSubcategories}
						transferListSide={TransferListSide.RIGHT}
						transferListType={transferListType}
					/>
				</div>
			</div>
		</>
	);
}
