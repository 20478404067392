// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import SlidedownTransition from '../transition';
import SimpleSlidedown from '../slidedown/simple';
import SlidedownWrapper from './wrapper';
import { checkForAutoClose } from '../slidedown/helpers';
import { TYPES } from '../slidedown/constants';
import { PortalProps } from '../types';
import { useStyles } from './portal.styles';

export function Portal({ action, description, message, onDismiss, show, type }: PortalProps): ReactElement {
	const [visible, setVisible] = useState(show);
	const portalElement = useRef(document.createElement('div'));
	const timeoutIdRef = useRef(0);
	const { classes } = useStyles();

	const onDismissRef = useRef(onDismiss);
	onDismissRef.current = onDismiss;

	const _handleVisible = useCallback((): void => {
		if (onDismissRef.current && typeof onDismissRef.current === 'function') {
			onDismissRef.current();
		}
		setVisible(false);
	}, [onDismissRef]);

	useEffect(() => {
		portalElement.current.className = classes.slidedownPortal;
		portalElement.current.style.zIndex = String(getMaxZIndex() + 10);
		document.body.appendChild(portalElement.current);
		const elementToCleanUp = portalElement.current;

		return () => {
			document.body.removeChild(elementToCleanUp);
		};
	}, [classes.slidedownPortal]);

	useEffect(() => {
		checkForAutoClose({
			action,
			_handleVisible,
			timeoutIdRef,
			type,
			visible,
		});
	}, [_handleVisible, timeoutIdRef, type, visible]);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	return createPortal(
		<SlidedownTransition appear={true} show={visible}>
			<SimpleSlidedown
				action={action}
				description={description}
				message={message}
				onDismiss={_handleVisible}
				show={true}
				showDismiss={type !== TYPES.success}
				type={type}
			/>
		</SlidedownTransition>,
		portalElement.current
	);
}

export default SlidedownWrapper(Portal);
