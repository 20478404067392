/**
 * @startCleanup encore
 */
import themeColorsJson from '../json/theme-colors.json';

const { themeColors } = themeColorsJson;
const defaultThemeName = 'lime1';

const defaultThemeColors = themeColors.find(theme => theme.name === defaultThemeName) || themeColors[0];

export function hexToRgb(hex: string, alpha: string): string {
	hex = hex.replace('#', '');
	const r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
	const g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
	const b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
	if (alpha) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	return `rgb(${r}, ${g}, ${b})`;
}

export const themeDefaults = {
	themeBaseDefault: defaultThemeColors.base,
	themeLightDefault: defaultThemeColors.light,
	themeLighterDefault: defaultThemeColors.lighter,
	themeLightestDefault: defaultThemeColors.lightest,
	themeLightest10Default: hexToRgb(defaultThemeColors.lightest, '0.1'),
	themeLightest35Default: hexToRgb(defaultThemeColors.lightest, '0.35'),
	themeLightest50Default: hexToRgb(defaultThemeColors.lightest, '0.5'),
};

export const currentTheme = {
	themeBase: `var(--fabric-theme-base, ${themeDefaults.themeBaseDefault})`,
	themeLight: `var(--fabric-theme-light, ${themeDefaults.themeLightDefault})`,
	themeLighter: `var(--fabric-theme-lighter, ${themeDefaults.themeLighterDefault})`,
	themeLightest: `var(--fabric-theme-lightest, ${themeDefaults.themeLightestDefault})`,
	themeLightest10: `var(--fabric-theme-lightest10, ${themeDefaults.themeLightest10Default})`,
	themeLightest35: `var(--fabric-theme-lightest35, ${themeDefaults.themeLightest35Default})`,
	themeLightest50: `var(--fabric-theme-lightest50, ${themeDefaults.themeLightest50Default})`,
};

export const theme = {
	...themeDefaults,
	...currentTheme,
};

/**
 * @endCleanup encore
 */
