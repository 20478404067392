import { CardContent, CardContentSpacing } from '@fabric/card';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { ConstraintProps, SpacingOverridesType } from '../base-modal.types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const DEFAULT_SPACING_OVERRIDES: SpacingOverridesType = {
	bottom: CardContentSpacing.SMALL,
	left: CardContentSpacing.SMALL,
	right: CardContentSpacing.SMALL,
	top: CardContentSpacing.SMALL,
};

const useStyles = makeStyles<{ canScroll: boolean }>()((theme, params) => {
	const { mode } = theme;
	const { canScroll } = params;
	return {
		root: {
			flexGrow: 1,
			overflow: canScroll ? 'auto' : 'visible',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		encoreContent: {
			padding: '32px 20px',
		},
	};
});

export const Constraint: FunctionComponent<PropsWithChildren<ConstraintProps>> & {
	Spacing: typeof CardContentSpacing;
} = props => {
	const { canScroll = true, children, spacingOverrides = DEFAULT_SPACING_OVERRIDES, className, ...rest } = props;

	const { classes, cx } = useStyles({ canScroll });
	const _spacingOverrides: SpacingOverridesType = {
		...DEFAULT_SPACING_OVERRIDES,
		...spacingOverrides,
	};

	const jadeContent = <CardContent spacingOverrides={_spacingOverrides}>{children}</CardContent>;
	const encoreContent = <div className={classes.encoreContent}>{children}</div>;

	return (
		<div className={cx(classes.root, className)} {...rest}>
			{ifFeature('encore', encoreContent, jadeContent)}
		</div>
	);
};

Constraint.Spacing = CardContentSpacing;

export { CardContentSpacing as ConstraintSpacing };
