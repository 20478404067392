import { makeStyles } from '~styles';
import type { BaseOwnProps } from './base.types';
import type { CSSProperties } from 'react';

type Props = Required<Pick<BaseOwnProps, 'actionPlacement' | 'actions' | 'orientation' | 'titleSize'>> &
	Pick<BaseOwnProps, 'description' | 'height' | 'width'>;

type LayoutKey = `${Props['orientation']}-${Props['actionPlacement']}`;

const layoutWithActions: Record<LayoutKey, CSSProperties> = {
	'horizontal-bottom': {
		gridTemplateAreas: `
			"icon text"
			".    actions"
		`,
		gridTemplateColumns: 'auto 1fr',
		gridTemplateRows: '1fr auto',
	},
	'horizontal-right': {
		gridTemplateAreas: `
			"icon text actions"
		`,
		gridTemplateColumns: `auto 1fr auto`,
	},
	'vertical-bottom': {
		gridTemplateAreas: `
			"icon right"
			"text text"
			"actions actions"
		`,
		gridTemplateRows: `auto 1fr auto`,
	},
	'vertical-right': {
		gridTemplateAreas: `
			"icon right"
			"text actions"
		`,
		gridTemplateColumns: '1fr auto',
	},
};

function getLayout(orientation: Props['orientation'], actionPlacement?: Props['actionPlacement']): CSSProperties {
	if (!actionPlacement) {
		return {
			gridTemplateAreas:
				orientation === 'horizontal'
					? '"icon text"'
					: `"icon right" 
					   "text text"`,
			gridTemplateColumns: 'auto 1fr',
			display: 'inline-grid',
		};
	}

	return {
		display: 'inline-grid',
		...layoutWithActions[`${orientation}-${actionPlacement}` satisfies LayoutKey],
	};
}

export const useBaseStyles = makeStyles<Props>()((theme, props) => {
	const {
		constructs,
		mixins: { boxShadow },
		spacing,
	} = theme;
	const { actionPlacement, actions, description, height, orientation, width } = props;

	return {
		root: {
			...getLayout(orientation, actions.length ? actionPlacement : undefined),
			backgroundColor: constructs.surface.neutral.inverted,
			border: `1px solid ${constructs.border.neutral.xWeak}`,
			borderRadius: spacing(2),
			boxShadow: boxShadow('300', constructs.surface.neutral.xxStrong),
			boxSizing: 'border-box',
			gap: spacing(2),
			gridAutoRows: 'min-content',
			height,
			padding: spacing(2.5),
			width,
		},
		icon: {
			gridArea: 'icon',
		},
		right: {
			gridArea: 'right',
			justifySelf: 'flex-end',
			'&:empty': {
				display: 'none',
			},
		},
		textContainer: {
			alignSelf: orientation === 'horizontal' && !description ? 'center' : undefined,
			gridArea: 'text',
		},
		text: {
			wordBreak: 'break-word',
		},
		actions: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: spacing(2),
			gridArea: 'actions',
			rowGap: spacing(1),
		},
	};
});
