import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, spacing }) => {
	return {
		container: {
			height: '100%',
			position: 'absolute',
			width: '100%',
		},
		endorse: {
			position: 'absolute',
			width: 'calc(100% - 54px)',
			bottom: '32px',
			left: '27px',
			borderBottom: `2px solid ${palette.gray[500]}`,
		},
		icon: {
			fill: palette.gray[600],
			paddingBottom: '10px',
		},
		initials: {
			width: '288px',
		},
		root: {
			position: 'relative',
			backgroundColor: palette.gray[100],
			borderRadius: spacing(1),
			height: '188px',
			maxWidth: '100%',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			width: '600px',
		},
		toggle: {
			display: 'flex',
			justifyContent: 'center',
			position: 'absolute',
			top: '-12px',
			width: '100%',
		},
	};
});
