import { FormConfig } from '../render-form/types';

export const emptyFormConfig: FormConfig = {
	layout: {
		root: { id: 'root' },
	},
	formElements: {
		root: { id: 'root', type: 'group' },
	},
	idsByName: {},
	idsByToken: {},
};
