import { MaskOptions } from '../types';
export const selectableMasks: Record<string, MaskOptions> = {
	employeeSsn: {
		mask: '000-00-0000',
	},
	employeeSin: {
		mask: '000-000-000',
	},
	employeeNin: {
		mask: 'aa aa aa aa a',
		definitions: {
			a: /[\w\d]/,
		},
	},
	employeeNationalId: {
		mask: '######[##########]',
		definitions: {
			'#': /[\w\d]/,
		},
	},
	validatePassportNumber: {
		mask: /^[\w\d]{0,20}$/,
	},
	ccExpirationField: {
		mask: '00/00',
	},
};
