import { isActingGroup } from '../../etc/is-acting-group';
import { isHidden } from '../../etc/is-hidden';
import { FormConfig, FormLayout } from '../../types';

export function hasOnlyGroups(id: keyof FormLayout, formConfig: FormConfig) {
	const { layout } = formConfig;
	const { childIds } = layout[id];

	return (
		childIds?.every(childId => {
			return isHidden(childId, formConfig) || isActingGroup(childId, formConfig);
		}) || false
	);
}
