import { FormFactoryElement } from '../types/element.types';
import { isFormField } from '../utils/is-form-field';
import { ComponentValidation } from '../validation';
import { validationMessageMiddleware } from './validation-message-middleware';
import { validationTriggerMiddleware } from './validation-trigger-middleware';

export function validationMiddleware(validation: ComponentValidation) {
	return (model: FormFactoryElement) => {
		if (isFormField(model)) {
			model = validationMessageMiddleware(validation)(model);
			model = validationTriggerMiddleware(validation)(model);
		}
		return model;
	};
}
