import { TextArea } from '~components/text-area';
import React from 'react';
import { RendererProps } from '../types';
import { getValidInputWidthValue } from '../etc/get-valid-input-width-value';

export function TextAreaRenderer({ element }: RendererProps) {
	const { id, type, width: elementWidth, ...rest } = element;

	const width = getValidInputWidthValue(elementWidth);
	const defaultWidth = 10; // Default as defined here https://design.bamboohr.net/3fb00d017/p/50a7f2-sizes-and-widths/b/31f4f4

	return <TextArea {...rest} key={id} minRows={3} width={width || defaultWidth} />;
}
