/* eslint-disable sort-keys */
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { getEncoreValue } from '~utils';
import { BodyTextProps } from './body-text.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import type { CSSProperties } from 'react';
import { SIZES } from './constants';

export const useStyles = makeStyles<Partial<BodyTextProps>>()((theme, props) => {
	const { constructs, mode, palette, spacing, typography } = theme;
	const { color, icon, inline, italic, justify, size, tabularNums, weight, whiteSpace } = props;

	const getFontFamily = (): CSSProperties['fontFamily'] => {
		if (size && ['emphasis-small', 'emphasis-medium'].includes(size)) {
			const sizeToFontFamilyMap: Record<
				Extract<BodyTextProps['size'], 'emphasis-small' | 'emphasis-medium'>,
				CSSProperties['fontFamily']
			> = {
				'emphasis-small': typography.h4.fontFamily,
				'emphasis-medium': typography.h3.fontFamily,
			};

			return sizeToFontFamilyMap[size] as CSSProperties['fontFamily'];
		}

		return undefined;
	};

	const getLineHeight = (): CSSProperties['lineHeight'] => {
		if (size && ['emphasis-extra-small', 'emphasis-small', 'emphasis-medium'].includes(size)) {
			const sizeToLineHeightMap: Record<
				Extract<BodyTextProps['size'], 'emphasis-extra-small' | 'emphasis-small' | 'emphasis-medium'>,
				CSSProperties['lineHeight']
			> = {
				'emphasis-extra-small': typography.h5.lineHeight,
				'emphasis-small': typography.h4.lineHeight,
				'emphasis-medium': typography.h3.lineHeight,
			};

			return sizeToLineHeightMap[size] as CSSProperties['lineHeight'];
		}

		return getEncoreValue(size as (typeof SIZES)[number], {
			// The line heights for small and teenie are wrong in our theme, so we'll hard-code the right values
			'extra-extra-small': '15px',
			'extra-small': ifFeature('encore', typography.xsmall?.lineHeight, '17px'),
			small: ifFeature('encore', typography.small.lineHeight, '19px'),
			medium: typography.medium.lineHeight,
			large: typography.large.lineHeight,
		});
	};

	const getFontSize = (): CSSProperties['fontSize'] => {
		if (size && ['emphasis-extra-small', 'emphasis-small', 'emphasis-medium'].includes(size)) {
			const sizeToFontSizeMap: Record<
				Extract<BodyTextProps['size'], 'emphasis-extra-small' | 'emphasis-small' | 'emphasis-medium'>,
				CSSProperties['fontSize']
			> = {
				'emphasis-extra-small': typography.h5.fontSize,
				'emphasis-small': typography.h4.fontSize,
				'emphasis-medium': typography.h3.fontSize,
			};

			return sizeToFontSizeMap[size] as CSSProperties['fontSize'];
		}

		return getEncoreValue(size as (typeof SIZES)[number], {
			'extra-extra-small': '11px',
			'extra-small': typography.teenie.fontSize,
			small: typography.small.fontSize,
			medium: typography.medium.fontSize,
			large: typography.large.fontSize,
		});
	};

	const fontFillColor = getEncoreValue(color, {
		primary: ifFeature('encore', constructs.text.primary.strong, palette.primary.main),
		success: ifFeature('encore', constructs.text.success.medium, palette.success.dark),
		'success-medium': constructs.text.success.medium,
		'success-strong': constructs.text.success.strong,
		error: ifFeature('encore', constructs.text.error.medium, palette.error.dark),
		'error-medium': constructs.text.error.medium,
		'error-strong': constructs.text.error.strong,
		warning: ifFeature('encore', constructs.text.warning.medium, palette.warning.dark),
		'warning-medium': constructs.text.warning.medium,
		'warning-strong': constructs.text.warning.strong,
		info: ifFeature('encore', constructs.text.info.medium, palette.info.dark),
		'info-medium': constructs.text.info.medium,
		'info-strong': constructs.text.info.strong,
		discovery: ifFeature('encore', constructs.text.discovery.medium, palette.discovery.dark),
		'discovery-medium': constructs.text.discovery.medium,
		'discovery-strong': constructs.text.discovery.strong,
		'neutral-weak': ifFeature('encore', constructs.text.neutral.weak, palette.gray[700]),
		'neutral-medium': ifFeature('encore', constructs.text.neutral.medium, palette.gray[800]),
		'neutral-strong': ifFeature('encore', constructs.text.neutral.strong, palette.gray[1000]),
		'neutral-extra-strong': ifFeature('encore', constructs.text.neutral.xStrong, palette.gray[1000]),
		'neutral-inverted': ifFeature('encore', constructs.text.neutral.inverted),
	});

	const lineHeight = getLineHeight();

	let display: CSSProperties['display'];
	if (inline) {
		display = 'inline';
	} else if (icon) {
		display = 'flex';
	}

	return {
		root: {
			color: fontFillColor,
			display,
			fill: fontFillColor,
			fontFamily: getFontFamily(),
			fontSize: getFontSize(),
			fontStyle: italic ? 'italic' : undefined,
			fontVariantNumeric: tabularNums ? 'tabular-nums' : 'inherit',
			fontWeight: getEncoreValue(weight, {
				regular: typography.fontWeightRegular,
				medium: typography.fontWeightMedium,
				semibold: typography.fontWeightSemibold,
				bold: typography.fontWeightBold,
			}),
			justifyContent: justify,
			lineHeight: inline ? '1lh' : lineHeight,
			margin: 0,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			overflowWrap: 'anywhere',
			padding: 0,
			textAlign: justify,
			whiteSpace,
			wordBreak: 'normal',
			verticalAlign: inline ? 'top' : undefined,
		},
		icon: {
			alignItems: 'center',
			display: 'inline-flex',
			height: '1lh',
			marginRight:
				size && ['emphasis-extra-small', 'emphasis-small', 'emphasis-medium'].includes(size) ? spacing(1.5) : spacing(1),
			verticalAlign: inline ? 'top' : undefined,
		},
	};
});
