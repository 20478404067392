import { useEffect } from 'react';
import { UseFormProps, Path, FieldValues } from '../hook-form';

export type UseFormWatchCallback<T> = (
	name: Path<T> | readonly Path<T>[] | Path<T>[],
	value: unknown,
	data: Record<string, unknown>
) => void;

export function useFormWatch<T extends FieldValues>(controller: UseFormProps<T>, callback: UseFormWatchCallback<T>) {
	const { watch } = controller;

	useEffect(() => {
		const subscription = watch((data, { name, type }) => {
			if (name && !Array.isArray(name) && type === 'change') {
				// We need to allow the change transaction to finish first.
				setTimeout(() => {
					const _data = data as Record<string, unknown>;
					callback(name, _data[name], _data);
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [callback, watch]);
}
