import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ constructs, mode, palette, spacing, typography }) => ({
	blankStateIcon: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: ifFeature('encore', spacing(3), 16),
		'& svg': {
			fill: ifFeature('encore', constructs.icon.neutral.medium, palette.gray[700]),
		},
	},
	blankStateText: ifFeature(
		'encore',
		{
			color: constructs.text.neutral.medium,
			fontSize: typography.medium.fontSize,
			fontWeight: typography.fontWeightRegular,
			lineHeight: typography.medium.lineHeight,
			padding: spacing(0, 7),
			textAlign: 'center',
		},
		{
			color: palette.gray[700],
			fontSize: typography.h3.fontSize,
			fontWeight: typography.fontWeightBold,
			lineHeight: typography.h3.lineHeight,
			padding: '0 25px',
			textAlign: 'center',
		}
	),
	blankStateContainer: {
		alignItems: ifFeature('encore', 'center'),
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: ifFeature('encore', '100%', 374),
		justifyContent: 'center',
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
	},
}));
