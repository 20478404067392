import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { InputWrapperProps } from './types';

export const useStyles = makeStyles<Partial<InputWrapperProps>>()((theme, params) => {
	const { mixins, palette, spacing, typography, mode } = theme;
	const { disabled, labelPlacement, status, viewMode } = params;

	let labelColor = palette.gray[900];

	if (disabled) {
		labelColor = palette.gray[600];
	} else if (status) {
		labelColor = mixins.fieldStatusColor(status, palette);
	} else if (viewMode) {
		labelColor = palette.gray[700];
	}

	return ifFeature(
		'encore',
		{
			root: {
				alignItems: 'start',
				columnGap: spacing(1.5),
				display: 'inline-flex',
				flexDirection: labelPlacement === 'inline' ? 'row' : 'column',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				rowGap: spacing(0.5),
			},
			/**
			 * We should avoid using `display: flex` on the label because it will push the required asterisk to the side
			 * if the label spans more than one line. See: https://bamboohr.atlassian.net/browse/EN-3781
			 */
			label: {
				alignItems: 'center',
				color: labelColor,
				fontSize: typography.small?.fontSize,
				fontWeight: typography.fontWeightMedium,
				lineHeight: typography.small?.lineHeight,
				position: 'relative',
				transform: 'none',
				whiteSpace: 'wrap',
				zIndex: 'unset',
				'&.MuiInputLabel-root': {
					maxWidth: 'unset',
				},
				'& > .MuiInputLabel-asterisk': {
					marginLeft: '-3px', // This removes the space between the label and the asterisk
					color: labelColor,
					fontSize: typography.small?.fontSize,
					fontWeight: typography.fontWeightMedium,
					lineHeight: typography.small?.lineHeight,
				},
				'&.Mui-disabled': {
					color: labelColor,
				},
				'&.Mui-focused': {
					color: labelColor,
				},
				pointerEvents: 'auto',
			},
			/**
			 * The label wrapper is being set to a fixed height via `classes.label` so that the label will remain centered with
			 * the top row of the input when the input is multi-line. See: https://bamboohr.atlassian.net/browse/FAB-3303
			 */
			labelWrapper: {
				alignItems: 'center',
				display: 'flex',
				flexShrink: 0,
				gap: spacing(0.5),
			},
			error: {
				color: palette.error.main,
				fill: palette.error.main,
			},
			warning: {
				color: palette.warning.main,
				fill: palette.warning.main,
			},
			info: {
				color: palette.info.main,
				fill: palette.info.main,
			},
			note: {},
		},
		{
			root: {
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			},
			label: {
				display: 'flex',
				alignItems: 'center',
				fontSize: typography.small?.fontSize,
				whiteSpace: 'nowrap',
				lineHeight: 1,
				'&>svg': {
					marginRight: spacing(0.75),
				},
				'& > .MuiInputLabel-asterisk': {
					color: palette.primary.main,
				},
				'&.Mui-focused': {
					color: status ? palette[status].main : palette.grey[800],
				},
				pointerEvents: 'auto',
			},
			labelWrapper: {},
			error: {
				color: palette.error.main,
				fill: palette.error.main,
			},
			warning: {
				color: palette.warning.main,
				fill: palette.warning.main,
			},
			info: {
				color: palette.info.main,
				fill: palette.info.main,
			},
			note: {}, // needed for props checking
		}
	);
});
