import { useEffect, useRef } from 'react';

export function useDidUpdate(cb: () => {}, dependencies: React.DependencyList = []): void {
	const initialMount = useRef(true);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (!initialMount.current) {
			cb();
		}

		initialMount.current = false;
	}, dependencies);
	/* eslint-enable react-hooks/exhaustive-deps */
}
