import React from 'react';
import { SelectField } from '../../../select';
import { RendererProps } from '../types';
import { getValidInputWidthValue } from '../etc/get-valid-input-width-value';

export function SelectRenderer({ element }: RendererProps) {
	const { onChange, type, width: elementWidth, ...rest } = element;
	const getWidth = getValidInputWidthValue(elementWidth);
	const defaultWidth = 5; // Default as defined here https://design.bamboohr.net/3fb00d017/p/50a7f2-sizes-and-widths/b/31f4f4
	const width = getWidth ? getWidth : defaultWidth;
	return (
		<SelectField
			onChange={event => {
				if (typeof onChange === 'function') {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call
					onChange(typeof event.target?.value === 'undefined' ? null : event.target.value);
				}
			}}
			width={width}
			{...rest}
		/>
	);
}
