// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ExclamationCircle21x21, CheckCircle20x20, InfoCircle21x21 } from '@bamboohr/grim';
import { TYPES } from '../constants';

export const getVariantIcon = (type: TYPES): ReactElement => {
	switch (type) {
		case TYPES.error: {
			return <ExclamationCircle21x21 />;
		}
		case TYPES.success: {
			return <CheckCircle20x20 />;
		}
		default: {
			return <InfoCircle21x21 />;
		}
	}
};
