import { BlankState } from './blank-state';
import { ButtonControls } from './button-controls';
import { FilterSelect } from './filter-select';
import { MultiSelectTransferList } from './multi-select-transfer-list';
import { SearchBar } from './search-bar';
import { SelectSearchBar } from './select-search-bar';
import { TransferListItem, TransferListHoverItem } from './transfer-list-item';
import { TransferListSubcategory } from './transfer-list-subcategory';
import { TransferListCategory } from './transfer-list-category';
import { TransferListCategoryList } from './transfer-list-category-list';
import { TransferListBase } from './transfer-list-base';
import { TransferList } from './transfer-list';

const TransferListComponents: {
	BlankState: typeof BlankState;
	ButtonControls: typeof ButtonControls;
	FilterSelect: typeof FilterSelect;
	SearchBar: typeof SearchBar;
	TransferListBase: typeof TransferListBase;
	TransferListItem: typeof TransferListItem;
	TransferListHoverItem: typeof TransferListHoverItem;
	TransferListCategory: typeof TransferListCategory;
	TransferListSubcategory: typeof TransferListSubcategory;
	SelectSearchBar: typeof SelectSearchBar;
	TransferListCategoryList: typeof TransferListCategoryList;
} = {
	BlankState,
	ButtonControls,
	FilterSelect,
	SearchBar,
	TransferListItem,
	TransferListBase,
	TransferListHoverItem,
	TransferListCategory,
	TransferListSubcategory,
	SelectSearchBar,
	TransferListCategoryList,
};

export { SearchBar, TransferListComponents, TransferList, MultiSelectTransferList };
