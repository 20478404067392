import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { Fragment, ReactElement } from 'react';
import { Link } from '~components/link';

export const Legal = (): ReactElement => {
	return (
		<Fragment>
			{ifFeature(
				'encore',
				<Link
					href="https://www.bamboohr.com/privacy.php?_ga=2.47001447.152851771.1626205797-455172675.1573665630"
					target="_blank"
				>
					{window.jQuery ? $.__('Privacy Policy') : 'Privacy Policy'}
				</Link>,
				<a
					href="https://www.bamboohr.com/privacy.php?_ga=2.47001447.152851771.1626205797-455172675.1573665630"
					rel="noopener external nofollow noreferrer"
					target="_blank"
				>
					{window.jQuery ? $.__('Privacy Policy') : 'Privacy Policy'}
				</a>
			)}
			&nbsp;&nbsp;·&nbsp;&nbsp;
			{ifFeature(
				'encore',
				<Link
					href="https://www.bamboohr.com/terms.php?_ga=2.51825897.152851771.1626205797-455172675.1573665630"
					target="_blank"
				>
					Terms of Service
				</Link>,
				<a
					href="https://www.bamboohr.com/terms.php?_ga=2.51825897.152851771.1626205797-455172675.1573665630"
					rel="noopener external nofollow noreferrer"
					target="_blank"
				>
					Terms of Service
				</a>
			)}
		</Fragment>
	);
};
