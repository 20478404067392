import { useEffect, useMemo, useRef } from 'react';
import { FieldValues, useForm as useHookForm, UseFormProps as UseHookFormProps } from 'react-hook-form';
import { isEqual } from 'lodash';
import { controlledInput } from './controlled-input';
import { getFields } from './get-fields';
import { Fields, FormFieldDefinition, UseFormProps } from './types';
import { registerControlledField } from './register-controlled-field';
import { isRecord } from '@fabric/utils';

export const useForm = <TFieldValues extends FieldValues>(
	fieldDefinitions?: FormFieldDefinition<TFieldValues>[],
	config?: UseHookFormProps<TFieldValues>
): UseFormProps<TFieldValues> => {
	const form = useHookForm<TFieldValues>(config);
	const { register, control } = form;

	const definitionsRef = useRef<FormFieldDefinition<TFieldValues>[] | undefined>([]);
	if (!isEqual(definitionsRef.current, fieldDefinitions)) {
		definitionsRef.current = fieldDefinitions;
	}

	const fields = useMemo(() => {
		if (!fieldDefinitions || fieldDefinitions.length === 0) return {} as Fields<TFieldValues>;
		return getFields<TFieldValues>(fieldDefinitions, register);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [definitionsRef.current, register]);

	const controlled = controlledInput<TFieldValues>(control);
	const registerControlled = (definition: FormFieldDefinition<TFieldValues>) =>
		registerControlledField<TFieldValues>(definition, register);

	useEffect(() => {
		Object.entries(fields).forEach(([name, props]) => {
			if (isRecord(props) && 'value' in props) {
				// @ts-ignore - The react-hook-form is too complicated to cast
				form.setValue(name, props.value);
			}
		});
	}, [form, fields]);

	return {
		fields,
		controlled,
		registerControlled,
		...form,
	};
};
