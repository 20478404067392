import { ValidationConfig, ValidationField, ValidationGetValue, ValidationTrigger } from './validation.types';
import { anyErrors } from './any-errors';
import { getRulesFromField } from './get-rules-from-field';
import { getRulesFromFields } from './get-rules-from-fields';
import { shouldValidate } from './should-validate';
import { validate } from './validate';
import { validateSingle } from './validate-single';

export class Validation {
	dirty: Record<string, boolean> = {};

	errors: Record<string, Array<string>> = {};

	fields: Record<string, ValidationField> = {};

	getValue?: ValidationGetValue;

	hasAlreadyValidated = false;

	valid = true;

	values: Record<string, unknown> = {};

	onErrorsChange: (errors: Record<string, Array<string>>) => void;

	onValidityChange: (instance: Validation) => void;

	constructor(config: ValidationConfig) {
		this.fields = config.fields;
		this.getValue = config.getValue;
	}

	addField(name: string, field: ValidationField) {
		this.fields[name] = {
			...(this.fields[name] || {}),
			...field,
		};
	}

	clear() {
		this.errors = {};
		this.dirty = {};
		this.hasAlreadyValidated = false;
	}

	getFieldValue(field: ValidationField, args: Array<unknown>) {
		const getValue = field.getValue || this.getValue;

		return typeof getValue === 'function' ? getValue(args) : args;
	}

	removeField(name: string) {
		delete this.dirty[name];
		delete this.errors[name];
		delete this.fields[name];
	}

	trigger(name: string, value: unknown, triggerType: ValidationTrigger) {
		const { hasAlreadyValidated } = this;
		const isAlreadyDirty = !!this.dirty[name];

		if (shouldValidate(this.fields[name], triggerType, { hasAlreadyValidated, isAlreadyDirty })) {
			const values = { ...this.values, [name]: value };
			const rules = getRulesFromField(this.fields[name]);

			validateSingle(name, values, rules).then(
				errors => {
					this.setFieldErrors(name, errors);
				},
				() => {
					console.error(`Could not validate a field with the name of ${name}`);
				}
			);
		}
	}

	setAllErrors(errors: Record<string, Array<string>>) {
		this.errors = errors;

		if (typeof this.onErrorsChange === 'function') {
			this.onErrorsChange(this.errors);
		}
	}

	setFieldDirty(name: string) {
		this.dirty[name] = true;
	}

	setFieldErrors(name: string, errors: Array<string>) {
		this.errors = { ...this.errors, [name]: errors };

		if (typeof this.onErrorsChange === 'function') {
			this.onErrorsChange(this.errors);
		}
	}

	async validate() {
		const allRules = getRulesFromFields(this.fields);

		this.setAllErrors(await validate(this.values, allRules));

		this.hasAlreadyValidated = true;

		this.valid = !anyErrors(this.errors);

		return this.valid;
	}
}
