import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ constructs, palette, spacing, typography }) => ({
	noMatchFilter: ifFeature(
		'encore',
		{
			alignSelf: 'center',
			color: constructs.text.neutral.medium,
			fontSize: typography.medium.fontSize,
			fontWeight: typography.fontWeightRegular,
			lineHeight: typography.medium.lineHeight,
			margin: `auto ${spacing(7)}`,
			textAlign: 'center',
		},
		{
			color: palette.gray[700],
			fontSize: typography.h4.fontSize,
			fontWeight: typography.fontWeightBold,
			lineHeight: typography.h4.lineHeight,
			marginTop: 40,
			padding: '0 20px',
			textAlign: 'center',
		}
	),
	transferListCenterColumn: {
		display: 'flex',
		flexDirection: 'column',
		height: 374,
		justifyContent: 'center',
		minWidth: 80,
	},
}));
