import React from 'react';
import { BannerLinkProps } from './types';
import { TextButton } from '~components/button';
import { Link } from '~components/link';

export function BannerLink({ children, href, onClick }: BannerLinkProps): JSX.Element {
	return href ? (
		<Link color="inverted" href={href} onClick={onClick}>
			{children}
		</Link>
	) : (
		<TextButton dark inline onClick={onClick}>
			{children}
		</TextButton>
	);
}
