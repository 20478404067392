import React, { Fragment } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import Nub from '../nub';

const Input = props => {
	const {
		hasError,
		hasInfo,
		hasWarning,
		id,
		isDisabled,
		isView,
		placeholder,
		name,
		nubIcon,
		onBlur,
		onFocus,
		onChange,
		size,
		tabindex,
		value,
		width,
	} = props;

	const hasNubIcon = hasNub(nubIcon);
	const nub = hasNubIcon ? <Nub icon={nubIcon} /> : null;

	const widthVariant = width ? width.toString() : null;
	const classes = classNames({
		'fab-TextInput': true,
		'fab-TextInput--error': hasError,
		'fab-TextInput--info': hasInfo,
		'fab-TextInput--warning': hasWarning,
		'fab-TextInput--view': isView,
		'fab-TextInput--withNub': hasNubIcon,
		[`fab-TextInput--${size}`]: size,
		[`fab-TextInput--width${widthVariant}`]: widthVariant,
	});

	return (
		<Fragment>
			<input
				className={classes}
				disabled={isDisabled}
				id={id}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				placeholder={placeholder}
				tabIndex={tabindex ? tabindex.toString() : '0'}
				type="text"
				value={value}
			/>
			{nub}
		</Fragment>
	);
};

const hasNub = nubIcon => {
	return !isNil(nubIcon) && typeof nubIcon === 'string' && nubIcon.replace(/\s/, '').length > 0;
};

export default Input;
