import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { RadioProps } from './types';

const TRANSITION_DURATION = 200;

export const useRadioStyles = makeStyles<Omit<RadioProps, 'onChange' | 'value'>, 'root'>({ name: 'Radio' })((
	{ mixins, mode, palette, transitions, typography },
	{ checked, label, note, size },
	classes
) => {
	const hasLabel = !!label;
	const hasNote = !!note;
	const radioRootMarginTop = ifFeature('encore', 0, size === 'small' ? '0' : '2px');
	const transitionOpts = { easing: 'ease', duration: TRANSITION_DURATION };

	return {
		root: {
			marginBottom: ifFeature('encore', hasNote ? '2px' : '12px', hasNote ? '1px' : '6px'),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		labelRoot: {
			marginLeft: 0,
			marginRight: 0,
			'& > .MuiButtonBase-root:hover': {
				backgroundColor: ifFeature('encore', 'unset'),
			},
		},
		label: {
			color: checked ? palette.grey[1000] : palette.grey[800],
			fontSize: typography.body1.fontSize, // 15px
			fontWeight: checked ? 600 : typography.fontWeightRegular,
			lineHeight: '1.466rem', // 22px
			marginLeft: '8px',
			'&.MuiFormControlLabel-label.Mui-disabled': {
				color: ifFeature('encore', palette.gray[600]),
			},
		},
		note: {
			color: ifFeature('encore', palette.gray[600]),
			margin: ifFeature('encore', '1px 0 4px 28px', '1px 0 4px 24px'),
		},
		small: {
			'& .MuiFormControlLabel-label': {
				fontSize: typography.fabricFontSize('small'),
				lineHeight: ifFeature('encore', typography.small.lineHeight, '1.1333rem'), // 17px
			},
		},
		radioRoot: {
			display: 'inline-flex',
			marginTop: hasLabel ? radioRootMarginTop : 0,
			padding: ifFeature('encore', 0),
			alignSelf: ifFeature('encore', 'start'),
			position: ifFeature('encore', 'relative'),
			top: ifFeature('encore', size === 'medium' ? '1px' : '2px'),
		},
		radioIcon: {
			backgroundColor: ifFeature('encore', palette.gray[100]),
			border: ifFeature('encore', mixins.border(1, palette.gray[400]), mixins.border(1, palette.grey[500])),
			borderRadius: '50%',
			boxShadow: ifFeature(
				'encore',
				`inset 1px 1px 0 0 ${mixins.alpha(palette.gray[900], 0.05)}`,
				`inset 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px ${mixins.alpha(palette.primary.lighter, 0.35)}`
			),
			width: ifFeature('encore', size === 'small' ? '16px' : '20px', size === 'small' ? '14px' : '16px'),
			height: ifFeature('encore', size === 'small' ? '16px' : '20px', size === 'small' ? '14px' : '16px'),
			position: 'relative',
			transition: transitions.create('box-shadow', transitionOpts),
			'&, &:before, &:after': {
				boxSizing: 'border-box',
			},
			'&:before, &:after': {
				content: '""',
				display: 'block',
				borderRadius: '50%',
				width: 'calc(100% + 2px)',
				height: 'calc(100% + 2px)',
				position: 'absolute',
				top: '-1px',
				left: '-1px',
				transform: 'scale(0)',
				transition: transitions.create('transform', transitionOpts),
			},
			'&:before': {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
				transformOrigin: '50% 50%',
			},
			'&:after': {
				border: ifFeature(
					'encore',
					size === 'small' ? mixins.border(4, 'transparent') : mixins.border(5, 'transparent'),
					mixins.border(4, 'transparent')
				),
				backgroundClip: 'padding-box',
				backgroundColor: palette.common.white,
				borderRadius: '50%',
				transitionDelay: '-1s',
			},
			[`.${classes.root}.Mui-checked &, .${classes.root} input:checked + &`]: {
				boxShadow: ifFeature('encore', `1px 1px 0 0 ${mixins.alpha(palette.gray[900], 0.06)}`),
			},
			[`.${classes.root}.Mui-focusVisible &, .${classes.root} input:focus + &`]: {
				boxShadow: ifFeature(
					'encore',
					`0 0 0 3px ${mixins.alpha(palette.primary.lightest, 1)}`,
					`inset 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`
				),
				transitionDelay: '100ms',
			},
			'input:disabled ~ &': {
				boxShadow: 'none',
				border: ifFeature('encore', mixins.border(1, palette.grey[200]), mixins.border(1, palette.grey[400])),
			},
		},
		radioCheckedIcon: {
			'&:before, &:after': {
				transform: 'scale(1)',
			},
			'input:disabled ~ &:before': {
				backgroundColor: palette.grey[400],
			},
		},
	};
});
