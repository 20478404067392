import { ModeOptions, dyeColors, makeStyles } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, typography }) => ({
	searchInput: {
		marginBottom: 15,
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		width: '235px',
	},
	dropdownList: {
		maxHeight: '180px',
		overflowX: 'hidden',
	},
	dropdownOption: {
		boxSizing: 'border-box',
		fontSize: typography.small.fontSize,
		height: '16px',
		padding: '10px 15px',
		width: '439px',
		'&:hover': {
			backgroundColor: palette.primary.main,
			color: palette.common.white,
		},
	},
}));
