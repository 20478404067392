import React from 'react';

export function GoToAppLink(props) {
	const { children, path = '/', text = window.jQuery ? $.__('Login to BambooHR') : 'Login to BambooHR' } = props;

	return (
		<a className="fab-InterstitialGoToAppLink fab-Link fab-Link--muted" href={path} rel="noopener external nofollow noreferrer">
			{children || text}
		</a>
	);
}
