import { RangeErrorType } from './constants';
import { DatePickerRangeError } from '../../types';

export const getDateMissingError = (
	inputValue: string,
	rangeErrorType: RangeErrorType,
	required: boolean
): DatePickerRangeError | undefined => {
	if (inputValue) {
		return {
			error: 'invalid',
			type: rangeErrorType,
		};
	}

	if (required) {
		return {
			error: 'required',
			type: rangeErrorType,
		};
	}
};
