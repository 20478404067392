/* @startCleanup encore */
import { makeStyles } from '~styles';

export const useOverrideStyles = makeStyles()(() => ({
	root: {
		'& .MuiInputBase-root': {
			display: 'flex',
			flexWrap: 'wrap',
			padding: '0 24px 0 0',
		},
	},
	input: {
		lineHeight: '21px',
	},
}));

export const useAdditionalStyles = makeStyles()(({ palette }) => ({
	clearIcon: {
		alignItems: 'center',
		display: 'flex',
		fill: palette.gray[500],
		justifyContent: 'center',
		'&:hover': {
			fill: palette.gray[700],
		},
	},
}));
/* @endCleanup encore */
