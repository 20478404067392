import { hasWordStartingWith } from '@bamboohr/utils/lib/string';
import { isPlainObject } from 'lodash';

import { findPath } from '../path';
import { getParents, searchItems, searchForItemsStartingWith } from '../traversal';
import { startsWithCaseInsensitive } from '../../util';

export function hasChildren(item) {
	return !!(item && Array.isArray(item.items) && item.items.length);
}

export function hasItem(items, item) {
	return findPath(items, item).length > 0;
}

export function isActionOnly(item, items = []) {
	if (!item) {
		return false;
	}

	const parentIsActionOnly = getParents(items, item).some(parent => isActionOnly(parent, items));

	return !!item.isActionOnly || !!item.href || parentIsActionOnly;
}

export function isAnchored(item) {
	return !!item && (item.anchor === 'top' || item.anchor === 'bottom');
}

export function isDisabled(item, items = []) {
	if (!item) {
		return false;
	}

	const parentIsDisabled = getParents(items, item).some(parent => isDisabled(parent, items));

	return item.isDisabled === true || parentIsDisabled;
}

export function isAddType(item) {
	return !!item && item.type === 'add';
}

export function isGroup(item) {
	return !!item && item.type === 'group';
}

export function isSameItem(itemA) {
	return itemB => {
		const strictlyEqual = itemA === itemB;
		const bothItemsAreObjects = isPlainObject(itemA) && isPlainObject(itemB);
		const anItemHasKeyProperty = bothItemsAreObjects && (itemA.hasOwnProperty('key') || itemB.hasOwnProperty('key'));
		const bothItemsHaveSameKey = anItemHasKeyProperty && itemA.key === itemB.key;
		const bothItemsHaveSameValue = bothItemsAreObjects && JSON.stringify(itemA.value) === JSON.stringify(itemB.value);
		const bothItemsHaveSameIdentity = anItemHasKeyProperty ? bothItemsHaveSameKey : bothItemsHaveSameValue;

		return strictlyEqual || bothItemsHaveSameIdentity;
	};
}

export function isGroupContainingMatches(item, searchText, search = searchItems) {
	let hasMatch = false;

	if (isGroup(item)) {
		const childItems = search(item.items, searchText);

		hasMatch = childItems.length > 0 || isAnchored(item);
	}

	return hasMatch;
}

export function isNotGroupAndMatches(item = {}, searchText = '', test = hasWordStartingWith) {
	return !isGroup(item) && test(item.text, searchText);
}

export function isSelected(item, selected = []) {
	const isSame = isSameItem(item);
	return selected.length ? selected.some(isSame) : !!item.isSelected;
}

export function shouldInclude(item, searchText) {
	return isNotGroupAndMatches(item, searchText) || isGroupContainingMatches(item, searchText);
}

export function shouldIncludeBecauseItemStartsWith(item, searchText) {
	return (
		isNotGroupAndMatches(item, searchText, startsWithCaseInsensitive) ||
		isGroupContainingMatches(item, searchText, searchForItemsStartingWith)
	);
}
