import React, { ReactNode } from 'react';
import { FabricIcon } from '~components/icon';
import { IconTile } from '~components/icon-tile';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import { uniqueId } from 'lodash';
import { PositionInTimeline } from '../types';
import { EnumAndStringUnion } from '~utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const getText = (value: string | Array<string> | JSX.Element) => {
	if (Array.isArray(value)) {
		return value.map(val => {
			const key = uniqueId('subStep__');
			return <div key={key}>{val}</div>;
		});
	}
	return value;
};

const getIconTileSize = size => {
	switch (size) {
		case 'small':
			return 40;
		case 'medium':
			return 48;
		case 'large':
			return 64;
		default:
			return 64;
	}
};

export const getIcon = (iconValue: ReactNode | string, size, isInverted = false, isActive = false) => {
	const completedText = window.jQuery ? $.__('Completed') : 'Completed';
	const notCompletedText = window.jQuery ? $.__('Not Completed') : 'Not Completed';

	const icon =
		typeof iconValue === 'string'
			? ifFeature(
					'encore',
					size === 'teenie' ? (
						<IconV2
							ariaLabel={isInverted ? notCompletedText : completedText}
							color={isInverted ? 'primary-strong' : 'neutral-extra-extra-strong'}
							name={iconValue as IconV2Name}
							size={16}
						/>
					) : (
						<IconTile
							icon={iconValue as IconV2Name}
							size={getIconTileSize(size)}
							variant={isInverted || isActive ? 'primary' : undefined}
						/>
					),
					<FabricIcon aria-label={isInverted ? notCompletedText : completedText} name={iconValue || ''} />
				)
			: ifFeature('encore', null, iconValue);

	return icon;
};

export const getPositionInTimeline = (index: number, length: number): EnumAndStringUnion<PositionInTimeline> => {
	if (length === 1) {
		return PositionInTimeline.END;
	}
	if (index === 0) {
		return PositionInTimeline.START;
	}
	if (index === length - 1) {
		return PositionInTimeline.END;
	}
	return PositionInTimeline.MIDDLE;
};
