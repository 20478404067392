import { useDidUpdate } from '@fabric/utils/hooks';

export function useTriggerChange(ref, dependencies = []) {
	useDidUpdate(() => {
		if (ref && ref.current) {
			const inputElement = ref.current;
			const eventConfig = { bubbles: true, cancelable: true };

			inputElement.dispatchEvent(new Event('input', eventConfig));
			inputElement.dispatchEvent(new Event('change', eventConfig));
		}
	}, dependencies);
}
