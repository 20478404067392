// created from https://docs.google.com/spreadsheets/d/1ayXZtLDMzJNsjjxB5JI01ej-MiWu04xqogzCvzKeuIY/edit?usp=sharing

// These styles are blacklisted because they are unrelated to layout and should be controlled by the design system.
export const stylesBlacklist = [
	'animation',
	'background',
	'border',
	'box-shadow',
	'caret-color',
	'@charset',
	'color',
	'font',
	'@font-face',
	'@import',
	'@keyframes',
	'outline',
	'transition',
	'text-decoration',
] as const;
