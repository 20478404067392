import React, { useMemo } from 'react';

import {
	DatePickerContext,
	DEFAULT_FORMAT_TYPE,
	DEFAULT_LOCALE,
	SUPPORTED_FORMAT_TYPES,
	SUPPORTED_LOCALES,
} from './date-picker-provider.domain';
import { DatePickerContextValue, DatePickerProviderProps } from '../types';
import { DatePickerUtilsLuxon as LuxonUtils } from '../date-picker-utils';

export function DatePickerProvider({
	children,
	formatType: formatTypeProp = 'MM/dd/yyyy',
	locale: localeProp = 'en-us',
}: DatePickerProviderProps): JSX.Element {
	const locale = useMemo(() => {
		return SUPPORTED_LOCALES.includes(localeProp) ? localeProp : DEFAULT_LOCALE;
	}, [localeProp]);

	const formatType = useMemo(() => {
		return SUPPORTED_FORMAT_TYPES.includes(formatTypeProp) ? formatTypeProp : DEFAULT_FORMAT_TYPE;
	}, [formatTypeProp]);

	const datePickerProviderValue = useMemo<DatePickerContextValue>(
		() => ({
			formatType,
			locale,
			utils: new LuxonUtils({ formats: { fullDate: formatType, isoDate: 'yyyy-MM-dd' }, locale }),
		}),
		[formatType, locale]
	);

	return <DatePickerContext.Provider value={datePickerProviderValue}>{children}</DatePickerContext.Provider>;
}
