import { LayoutKey } from '../types';

/**
 * Sorts an array of layout keys and places a specified value at the end and leaving other values alone
 * input = ['a', 'c', 'b']
 *
 * shiftValuesToEnd(input, 'c') // ['a', 'b', 'c']
 */
export function shiftValuesToEnd(array: LayoutKey[], value: LayoutKey): LayoutKey[] {
	return [...array].sort((a, b) => {
		return b === value ? -1 : 0;
	});
}

/**
 * Moves an item in array to the end of the array based on its index
 *
 * shiftValueAtIndexToEnd(['a', 'c', 'b'], 1) // ['a', 'b', 'c']
 */
export function shiftValueAtIndexToEnd(array: LayoutKey[], index: number): LayoutKey[] {
	const arrayCopy = [...array];
	arrayCopy.push(arrayCopy.splice(index, 1)[0]);
	return arrayCopy;
}
