import React, { ReactElement } from 'react';
import { SideSubNavigationTitleProps } from './title.types';
import { useStyles } from './title.style';

/**
 * @deprecated Title should not be used with Encore.
 */
export function Title({ children }: SideSubNavigationTitleProps): ReactElement {
	const { classes } = useStyles();
	return <div className={classes.root}>{children}</div>;
}
