/**
 * ARROW_WIDTH
 * = 18px applied to .arrow
 */
export const ARROW_WIDTH = '1em';
/**
 * ARROW_HEIGHT
 * = width / sqrt(2) = (length of the hypotenuse).
 */
export const ARROW_HEIGHT = '0.71em';
/**
 * ARROW_WIDTH_OFFSET
 * Negative half ARROW_WIDTH.
 */
export const ARROW_WIDTH_OFFSET = '-0.5em';
/**
 * PAPER_SPACING
 * Rougly the height of the arrow after transform + offset by 2px.
 */
export const ARROW_MARGIN_CALC = `((${ARROW_HEIGHT} * 0.8) + 4px)`;

export const TRANSITION_DURATION = 200;
