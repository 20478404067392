import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { makeStyles } from '~styles';
import { BamboohrLogo115x17 } from '@bamboohr/grim';

import { useStyles } from './footer.styles';
import { Legal } from './legal';
import { Copyright } from './copyright';
import { FooterProps } from './types';

const useIconStyles = makeStyles()(({ palette }) => ({
	icon: {
		fill: palette.gray[800],
	},
}));

/**
 * @deprecated We no longer need/want a global footer.
 */
export const Footer = forwardRef<HTMLDivElement, FooterProps>(({ classes: classesProp, children, right }, ref) => {
	const { classes: styles } = useIconStyles();
	const { classes, cx } = useStyles();

	const defaultChildren = (
		<div className={cx(classes.defaultChildren, classesProp?.defaultChildren)}>
			<div>
				<Legal />
			</div>
			<span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
			<Copyright />
		</div>
	);

	const defaultRight = (
		<div className={styles.icon}>
			<BamboohrLogo115x17 />
		</div>
	);

	return (
		<div className={cx(classes.root, classesProp?.root)} ref={ref} role="contentinfo">
			<div className={cx(classes.guts, classesProp?.guts)}>
				<div className={cx(classes.left, classesProp?.left)}>
					{typeof children === 'undefined' || children === '' ? defaultChildren : children}
				</div>
				<div className={cx(classes.right, classesProp?.right)}>
					{typeof right === 'undefined' || right === '' ? defaultRight : right}
				</div>
			</div>
		</div>
	);
});

export const FooterStorybook = Footer as FC<PropsWithChildren<FooterProps>>;
