import { FormConfig } from './form-factory.types';
import { getModelValues } from './utils/get-model-values';
import { getValue } from './utils/get-value';
import { useValidation, ValidationFields } from './validation';

/**
 * A wrapper hook that gets values from model objects and passes them into the validation hook, returning
 * the `useValidation` hook's return value.
 * @param models The models that contain the values to validate.
 * @param fields The field validation definition object used to control how a field is validated and when.
 * @returns The return value of the `useValidation` hook.
 */
export function useModelValidation(models: FormConfig['models'], fields: ValidationFields = {}) {
	return useValidation(getModelValues(models), fields, { getValue });
}
