import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Box } from '@mui/material';
import { TileProps } from './tile.types';
import { useTileStyles } from './tile.styles';

/**
 * @deprecated Use `Tile V2` instead.
 */
export const Tile: React.FC<TileProps> = ({ variant = 'primary', isActive, ...props }) => {
	const { classes, cx } = useTileStyles();

	return (
		<Box
			className={cx([
				classes.root,
				variant === 'primary' && classes.primary,
				variant === 'secondary' && classes.secondary,
				variant === 'placeholder' && classes.placeholder,
				isActive && classes.active,
			])}
			height={ifFeature('encore', 184, 148)}
			width={ifFeature('encore', 200, 224)}
			{...props}
		/>
	);
};
