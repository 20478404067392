import React, { ReactElement } from 'react';
import { DatePickerCalendarSingle } from '~components/date-picker/components/date-picker-calendar-single';
import { DatePickerOnChangeParamDate } from '~components/date-picker';
import { DatePickerUtilsProvider } from '~components/date-picker/date-picker-utils-provider';
import { useDatePickerUtils } from '~components/date-picker/hooks';
import { CalendarProps } from './calendar.types';
import { convertDatePredicate, convertRenderDateFunction, dateFromISO, dateToISO } from './utils';

export const Calendar = ({
	disabled,
	getDateDisabled,
	maxDate,
	minDate,
	onCancel,
	onChange,
	renderDate,
	value,
}: CalendarProps): ReactElement => {
	const utils = useDatePickerUtils();

	return (
		<DatePickerUtilsProvider>
			<DatePickerCalendarSingle
				disabled={disabled}
				getDateDisabled={getDateDisabled && convertDatePredicate(utils, getDateDisabled)}
				maxDate={dateFromISO(utils, maxDate)}
				minDate={dateFromISO(utils, minDate)}
				onChange={({ value }: DatePickerOnChangeParamDate) => onChange({ value: dateToISO(utils, value) })}
				renderDate={renderDate && convertRenderDateFunction(utils, renderDate)}
				value={dateFromISO(utils, value)}
			/>
		</DatePickerUtilsProvider>
	);
};
