import { filterListItems } from './index';
import { FilterItem, ListItem } from '../types';

export function getFilteredList(filters: FilterItem[], filteredAvailable: ListItem[]) {
	const filtersIsArray = Array.isArray(filters);
	const filteredAvailableIsArray = Array.isArray(filteredAvailable);
	if (filtersIsArray && filteredAvailableIsArray) {
		return filters.length > 0 ? filterListItems(filteredAvailable, filters) : [...filteredAvailable];
	}

	const filterType = filtersIsArray ? 'array' : typeof filters;
	const filteredAvailableType = filteredAvailableIsArray ? 'array' : typeof filteredAvailable;
	throw new Error(`Arrays expected but received ${filterType} for filters and ${filteredAvailableType} for filteredAvailable`);
}
