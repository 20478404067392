import { styled, Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { widths } from '~definitions/json/encore-sizes.json';
import { fabricFontSize, fabricLineHeight } from '@fabric/definitions/js/text';

export const EmptyFileCardRoot = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { borderRadiuses, constructs, spacing, palette, typography } = theme as Theme;
	return {
		backgroundColor: palette.common.white,
		border: 'solid 1px',
		borderColor: ifFeature('encore', constructs.border.neutral.weak, palette.gray[300]),
		borderRadius: ifFeature('encore', borderRadiuses[400], '2px'),
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		fontSize: fabricFontSize('teenie'),
		height: ifFeature('encore', '128px', '112px'),
		lineHeight: fabricLineHeight('teenie'),
		padding: ifFeature('encore', spacing(2), '9px 12px 12px'),
		width: ifFeature('encore', widths[7], '176px'),
		'& .EmptyFileCard__detail': {
			display: 'flex',
			flexGrow: 1,
			maxWidth: '100%',
		},
		'& .EmptyFileCard__icon': {
			display: ifFeature('encore', 'flex'),
			/* @startCleanup encore */
			fill: ifFeature('encore', undefined, palette.gray[600]),
			/* @endCleanup encore */
			marginRight: ifFeature('encore', spacing(1), '6px'),
		},
		'& .EmptyFileCard__info': {
			alignItems: 'center',
			color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[500]),
			display: 'flex',
			fontSize: ifFeature('encore', typography.small.fontSize, fabricFontSize('teenie')),
			height: 'auto',
			lineHeight: ifFeature('encore', typography.small.lineHeight, fabricLineHeight('teenie')),
		},
		'& .EmptyFileCard__title': {
			color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[500]),
			display: 'inline-block',
			fontSize: ifFeature('encore', typography.medium.fontSize, fabricFontSize('small')),
			fontWeight: ifFeature('encore', typography.fontWeightMedium),
			lineHeight: ifFeature('encore', typography.medium.lineHeight, fabricLineHeight('small')),
			maxHeight: ifFeature('encore', '42px', '38px'),
			minHeight: '34px',
			overflow: 'hidden',
			width: '100%',
			wordBreak: 'break-all',
		},
	};
});
