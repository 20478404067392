import { dyeColors, makeStyles, ModeOptions, ThemeNameOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, typography, themeName, palette }) => ({
	h1: {
		fontFamily: typography.h1.fontFamily,
		fontSize: typography.h1.fontSize,
		lineHeight: typography.h1.lineHeight,
	},
	h2: {
		fontFamily: typography.h2.fontFamily,
		fontSize: typography.h2.fontSize,
		lineHeight: typography.h2.lineHeight,
	},
	h3: {
		fontFamily: typography.h3.fontFamily,
		fontSize: typography.h3.fontSize,
		lineHeight: typography.h3.lineHeight,
	},
	h4: {
		fontFamily: typography.h4.fontFamily,
		fontSize: typography.h4.fontSize,
		lineHeight: typography.h4.lineHeight,
	},
	h5: {
		fontFamily: typography.h5.fontFamily,
		fontSize: typography.h5.fontSize,
		lineHeight: typography.h5.lineHeight,
	},
	h6: {
		fontFamily: typography.h6.fontFamily,
		fontSize: typography.h6.fontSize,
		lineHeight: typography.h6.lineHeight,
	},
	biggie: {
		fontFamily: typography.biggie.fontFamily,
		fontSize: typography.biggie.fontSize,
		lineHeight: typography.biggie.lineHeight,
	},
	large: {
		fontFamily: typography.large.fontFamily,
		fontSize: typography.large.fontSize,
		lineHeight: typography.large.lineHeight,
	},
	medium: {
		fontFamily: typography.medium.fontFamily,
		fontSize: typography.medium.fontSize,
		lineHeight: typography.medium.lineHeight,
	},
	root: {
		color: themeName === ThemeNameOptions.Encore ? palette.grey[900] : undefined,
		'&&': {
			color: mode === ModeOptions.Dye ? dyeColors.main : undefined,
		},
	},
	small: {
		fontFamily: typography.small.fontFamily,
		fontSize: typography.small.fontSize,
		lineHeight: typography.small.lineHeight,
	},
	teenie: {
		fontFamily: typography.teenie.fontFamily,
		fontSize: typography.teenie.fontSize,
		lineHeight: typography.teenie.lineHeight,
	},
}));
