import React, { forwardRef, ReactElement } from 'react';

import { DatePickerRangeComponent } from './date-picker-range-component';
import { DatePickerUtilsProvider } from '../date-picker-utils-provider';
import { DatePickerRangeProps } from '../types';

export const DatePickerRange = forwardRef<HTMLInputElement, DatePickerRangeProps>(function DatePickerRange(props, ref): ReactElement {
	return (
		<DatePickerUtilsProvider>
			<DatePickerRangeComponent {...props} ref={ref} />
		</DatePickerUtilsProvider>
	);
});
