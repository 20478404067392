import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { CurrencyPickerField } from '~components/currency-picker-field';
import { CurrencyItem } from '~components/currency-field';
import { CurrencyContext } from '~components/currency-picker-provider';
import { RendererProps } from '../types';

export function CurrencyPickerRenderer({ element }: RendererProps) {
	const name = typeof element.name === 'string' ? element.name : '';

	const currencyContext = useContext(CurrencyContext);

	const defaultAmount = Array.isArray(element.value) && typeof element.value[0] === 'string' ? element.value[0] : '';
	const defaultCurrencyCode: string = Array.isArray(element.value) && typeof element.value[1] === 'string' ? element.value[1] : '';
	const defaultSymbol = (element.currency as Partial<CurrencyItem>)?.symbol;

	const defaultSelectedCurrency =
		defaultCurrencyCode && defaultSymbol
			? ({
					...(element.currency as Partial<CurrencyItem>),
					name: defaultSymbol,
					code: defaultCurrencyCode,
				} as CurrencyItem)
			: undefined;

	const [amount, setAmount] = useState(defaultAmount);
	const [selectedCurrency, setSelectedCurrency] = useState(defaultSelectedCurrency);

	return (
		<CurrencyPickerField
			{...element}
			inputNames={{ amount: `${name}[0]`, currency: `${name}[1]` }}
			onChange={_amount => {
				setAmount(_amount);
				if (typeof element.onChange === 'function') {
					const isMissingAmount = isEmpty(_amount);
					const isMissingCode = isEmpty(selectedCurrency?.code) && isEmpty(currencyContext.defaultCurrency);

					const value =
						isMissingAmount || isMissingCode ? null : [_amount, selectedCurrency?.code || currencyContext.defaultCurrency];
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call
					element.onChange(value);
				}
			}}
			onSelectCurrency={currency => {
				setSelectedCurrency(currency);
				if (typeof element.onChange === 'function') {
					const value = isEmpty(amount) || isEmpty(currency.code) ? null : [amount, currency.code];
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call
					element.onChange(value);
				}
			}}
			selectedCurrency={selectedCurrency}
			submitUnmaskedValue={true}
			useUnmaskedValue={true}
			value={amount}
		/>
	);
}
