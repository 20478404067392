import { ReactElement } from 'react';

export enum TaskProgressNotePlacement {
	left = 'left',
	right = 'right',
}

export enum TaskProgressNoteType {
	task = 'task',
	step = 'step',
	percentage = 'percentage',
}

export interface TaskProgressBarProps {
	/**
	 * @deprecated classes should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * Class names that can be passed in to override styles
	 */
	classes?: {
		root?: string;
		note?: string;
		outerProgress?: string;
		innerProgress?: string;
	};
	/**
	 * The current progress value.
	 */
	current: number;
	/**
	 * The height of the ProgressBar rendered in pixels. Only used in Encore.
	 * @default 12
	 */
	height?: number;
	/**
	 * Note that is displayed to the right of the progress bar.
	 */
	note?: string | ReactElement;
	/**
	 * Note placement.
	 */
	placement?: 'left' | 'right';
	/**
	 * Note type
	 *
	 * Default: `"task"`
	 * Note: Selecting "task" type will display note in the following format: `"current/total {note text}"`
	 * Note: Selecting "step" type will display note in the following format: `"Step current of total"`
	 * Note: Selecting "percentage" type will display note in the following format: `"Math.round((current / total) * 100)%"`
	 */
	type?: 'task' | 'step' | 'percentage';
	/**
	 * The total value.
	 */
	total: number;
	/**
	 * The width of the ProgressBar rendered. Only used in Encore.
	 * @default '412px'
	 */
	width?: React.CSSProperties['width'];
}
