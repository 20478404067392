import { ChangeIndexArgs } from '../types';

export const changeIndex = ({
	difference,
	numSlides,
	slidesToShow = Math.abs(difference),
	currentIndex,
	setCurrentIndex,
}: ChangeIndexArgs) => {
	const minIndex = 0;
	const maxIndex = Math.max(numSlides - slidesToShow, minIndex);
	let newIndex = currentIndex + difference;
	if (newIndex < minIndex) {
		newIndex = minIndex;
	} else if (newIndex > maxIndex) {
		newIndex = maxIndex;
	}
	if (newIndex !== currentIndex) {
		setCurrentIndex(newIndex);
	}
};
