import { IconV2Color, IconV2Size } from '~components/icon-v2';
import { IconTileSizes, IconTileVariants } from './icon-tile.types';

export const getIconSize = function (size: IconTileSizes): IconV2Size {
	const map: Record<IconTileSizes, IconV2Size> = {
		40: 16,
		44: 20,
		48: 24,
		56: 24,
		64: 32,
	};
	return map[size];
};
export const getIconColor = function (variant?: IconTileVariants): IconV2Color {
	const map: Record<IconTileVariants, IconV2Color> = {
		light: 'primary-strong',
		muted: 'neutral-weak',
		primary: 'neutral-forced-white',
		secondary: 'neutral-extra-strong',
	};
	return variant ? map[variant] : 'primary-strong';
};
