import { largeScreenWidth, mediumScreenWidth } from '~definitions/json/mobile.json';
import { isEqual } from 'lodash';
import { useEffect, useState, useCallback } from 'react';

import { ScreenSizes } from '../types';

export function useScreenSizes(shouldWatch: boolean): ScreenSizes {
	const largeMql = window.matchMedia(`only screen and (max-width: ${largeScreenWidth}px)`);
	const mediumMql = window.matchMedia(`only screen and (max-width: ${mediumScreenWidth}px)`);

	const getBreakpoints = useCallback((): ScreenSizes => {
		return {
			isLargeScreen: largeMql.matches,
			isMediumScreen: mediumMql.matches,
			isMobile: largeMql.matches || mediumMql.matches,
		};
	}, [mediumMql, largeMql]);

	const [breakpoints, setBreakpoints] = useState(getBreakpoints());

	useEffect((): (() => void) => {
		const _breakpoints = getBreakpoints();

		let handleMediaQueryChange: EventListener;

		if (!isEqual(breakpoints, _breakpoints)) {
			setBreakpoints(_breakpoints);
		}

		if (shouldWatch) {
			handleMediaQueryChange = (): void => {
				setBreakpoints(getBreakpoints());
			};

			largeMql.addEventListener('change', handleMediaQueryChange);
			mediumMql.addEventListener('change', handleMediaQueryChange);
		}

		return (): void => {
			largeMql.removeEventListener('change', handleMediaQueryChange);
			mediumMql.removeEventListener('change', handleMediaQueryChange);
		};
	}, [breakpoints, getBreakpoints, largeMql, mediumMql, shouldWatch]);

	return breakpoints;
}
