import React, { ElementType, forwardRef } from 'react';
import { Box as MUIBox, BoxProps as MUIBoxProps } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

const useStyles = makeStyles()(({ mode }) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});

/**
 * @deprecated Box should no longer be used.
 *
 * - Use `LayoutBox` if you need a generic container that supports functionality like margins, padding, position, and size and doesn't require grid or flex functionality.
 * - Use `StyledBox` if you need a generic container that supports Layout Box attributes and has visual styling such as background color, border color, border radius, border style, and box shadow.
 * - Use `Flex` if you need a generic container that supports Layout Box attributes and displays its children in a flex container.
 * - Use `Grid` if you need a generic container that supports Layout Box attributes and displays its children in a grid.
 */
export const Box = forwardRef((props: MUIBoxProps<ElementType, { component?: ElementType }>, ref) => {
	const { children, className, ...rest } = props;
	const { classes, cx } = useStyles();

	return (
		<MUIBox className={cx(classes.root, className)} {...rest} ref={ref}>
			{children}
		</MUIBox>
	);
});
