import { getLocaleInfo } from '../date-picker-utils/date-picker-utils-shared';
import { DatePickerDateType, DatePickerUtils } from '../types';

export function getDisplayMonthAbbreviations(utils: DatePickerUtils): Array<string> {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const code = utils.locale;
	const localeInfo = getLocaleInfo(code);
	if (localeInfo) {
		return localeInfo.monthAbbreviations;
	}

	return getMonthAbbreviations(utils);
}

export function getMonthAbbreviations(utils: DatePickerUtils): Array<string> {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const months: Array<string> = [];
	for (let i = 0; i < 12; i++) {
		// To ensure the month abbreviations get returned as expected we are going to use the 2nd day of the month
		// instead of the 1st, and supply a date of 1970 instead of leaving it as defined as 0.
		// Both of these measures should prevent a date of Jan 1st (as an example) from unexpectedly getting converted to Dec 31st.
		// This ensures the months array starts appropriately with 'Jan' (or its localized equivalient) instead of 'Dec'.
		const referenceMonthDate = new Date(1970, i, 2);
		months.push(utils.format(utils.date(referenceMonthDate) as DatePickerDateType, 'monthShort'));
	}
	return months;
}
