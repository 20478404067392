// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { AccordionDetails as MuiAccordionDetails, Theme } from '@mui/material';
import { useAccordionBodyStyles } from './accordion-body.styles';
import { AccordionBodyProps } from './accordion.types';

export function AccordionBody({ classes = {}, ...rest }: AccordionBodyProps): JSX.Element {
	const { classes: styles } = useAccordionBodyStyles();
	const { root: rootClass, ...restClasses } = classes;
	return <MuiAccordionDetails classes={{ root: classnames(styles.root, rootClass), ...restClasses }} {...rest} />;
}
