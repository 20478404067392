import { useCallback, useRef, useState } from 'react';
import { ValidationConfig, ValidationFields } from './validation.types';
import { ComponentValidation } from './component-validation';
import { getValue } from './get-value';

export function useForceUpdate() {
	const [, updateState] = useState({});
	return useCallback(() => updateState({}), []);
}

/**
 * A hook used to validate fields in a form. It must be passed an object containing all
 * of the values contained by fields in the form by their field name.
 *
 * NOTE: This validation hook is experimental and is not meant for general use.
 *
 * @param values An object containing all field values by field name.
 * @param fields An object containing validation configuration for each field by field name.
 * @param config Additional configuration settings that can be used for further customization.
 */
export function useValidation(
	values: Record<string, unknown>,
	fields: ValidationFields = {},
	config?: Omit<ValidationConfig, 'fields'>
): ComponentValidation {
	const forceUpdate = useForceUpdate();

	const validationRef = useRef(new ComponentValidation({ getValue, ...config, fields }));

	validationRef.current.values = values;
	validationRef.current.onErrorsChange = forceUpdate;

	return validationRef.current;
}
