import { verifyOffsets } from './offsets';

export function getDimensions(offsets) {
	verifyOffsets(offsets);

	return {
		height: offsets.bottom - offsets.top,
		width: offsets.right - offsets.left,
	};
}
