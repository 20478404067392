import { IDataHookRecord, Scalar } from '@flatfile/adapter/build/main/interfaces';
import { normalizeColumnValidationResults } from './normalize-column-validation-results';
import { ValidateColumn } from '../types';
import { normalizeColumns } from './normalize-columns';

export const columnValidator = async (
	userId: string,
	dataType: string,
	columnName: string,
	columnValues: [Scalar, number][],
	siblingColumnValues: Record<string, [Scalar, number][]>,
	validateColumn: ValidateColumn
): Promise<[IDataHookRecord, number][]> => {
	const columns = normalizeColumns(columnValues);
	const siblingColumns: Record<string, Scalar[]> = {};
	const startIndex = columnValues[0][1];

	for (const key in siblingColumnValues) {
		siblingColumns[key] = normalizeColumns(siblingColumnValues[key]);
	}

	const results = await validateColumn(userId, dataType, columnName, columns, siblingColumns);
	return normalizeColumnValidationResults(results, startIndex);
};
