import React, { FC, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { useMaxZIndex } from '../base-modal/hooks';
import { useStyles } from './dialog.styles';
import { DialogProps } from './dialog.types';
import { Footer } from './footer';
import { Header } from './header';

export const Dialog: FC<PropsWithChildren<DialogProps>> & { Header: typeof Header; Footer: typeof Footer } = ({
	children,
	container,
	footer,
	header,
	height = '600px',
	offsetRight = '64px',
	offsetTop = '24px',
	open,
	width = '300px',
}) => {
	const containerElement = container ? document.getElementById(container) : document.body;
	const maxZIndex = useMaxZIndex(open);

	const { classes } = useStyles({
		height,
		offsetRight,
		offsetTop,
		open,
		width,
		zIndex: maxZIndex,
	});

	return open && containerElement
		? ReactDOM.createPortal(
				<dialog className={classes.root}>
					{header}
					{children}
					{footer}
				</dialog>,
				containerElement
			)
		: null;
};

Dialog.Header = Header;
Dialog.Footer = Footer;
