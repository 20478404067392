import { Layout } from '../types';
import {
	copyLayout,
	rotateLayoutCounterClockwise,
	rotateLayoutClockwise,
	shiftValueAtIndexToEnd,
	fillHolesInLayout,
	fixLayoutKeys,
	fixLayoutKey,
} from '../utilities';

export function twoColumnOneByOneToTwoByTwo(layout: Layout, layoutKey: string) {
	/**
	 * The following steps will be explained with an example layout to demonstrate what each step is doing
	 * const layout = [
	 *     ['a', 'b'],
	 *     ['.', 'd'],
	 *     ['e', 'f'],
	 * ];
	 * const layoutKey = 'b';
	 */

	/**
	 * Don't mutate the original layout that is passed in
	 * fix lengths
	 * fix keys
	 * fix layoutKey
	 */
	layout = copyLayout(layout);
	layout = fillHolesInLayout(layout);
	layout = fixLayoutKeys(layout);
	layoutKey = fixLayoutKey(layoutKey);

	/**
	 * First, we need to understand where the layoutKey is sitting inside of the layout
	 * using the example above
	 * outerIndex = the index of the array it sits in, in this case 0
	 * innerIndex = 1 because well, it's at index 1 👉 ['a', 'b']
	 */
	let innerIndex = -1;
	let outerIndex = layout.findIndex(arr => {
		innerIndex = arr.findIndex(key => key === layoutKey);
		return innerIndex > -1;
	});

	/**
	 * We can set the layoutKey to an empty slot to create a hole.
	 * But we have to remember where this is because there may be other intentional holes in the layout
	 * layout = [
	 *     ['a', '.'], -> We still care about the indexes! (outerIndex = 0, innerIndex = 1)
	 *     ['.', 'd'],
	 *     ['e', 'f'],
	 * ]
	 */
	layout[outerIndex][innerIndex] = '.';

	/**
	 * We absolutely know that we are going from a 1x1 to a 2x2,
	 * so we can simply just place duplicates above the original layoutKey
	 * there are now 2 rows above this... we need to account for this in the index later (outerIndex = 0 + 2, innerIndex = 1)
	 * layout = [
	 *     ['b', 'b'],
	 *     ['b', 'b'],
	 *     ['a', '.'], // (2,1)
	 *     ['.', 'd'],
	 *     ['e', 'f']
	 * ]
	 */
	layout.splice(outerIndex, 0, [layoutKey, layoutKey], [layoutKey, layoutKey]);
	outerIndex += 2;

	/**
	 * To make it easier to close the holes
	 * we can rotate the layout counter clockwise so each column is in it's own array
	 * We then reverse the layout so that our indexes just need to be swapped
	 *
	 * Before reversing:
	 * layout = [
	 *     ['b', 'b', '.', 'd', 'f'],
	 *     ['b', 'b', 'a', '.', 'e'],
	 * ]
	 *
	 * after reversing:
	 * layout = [
	 *     ['b', 'b', 'a', '.', 'e'],
	 *     ['b', 'b', '.', 'd', 'f'],
	 * ]
	 *
	 * now, if we swap our outerIndex and innerIndex, they still point to the correct location
	 * (outerIndex = 1, innerIndex = 2)
	 */
	layout = rotateLayoutCounterClockwise(layout).reverse();
	[outerIndex, innerIndex] = [innerIndex, outerIndex];

	/**
	 * Now we need to move the "." we placed in the layout to the end of the layout
	 *
	 * REMEMBER! In the context of this function,
	 * our usage of '.' is different from an intentional "." used in implementation
	 * layout = [
	 *     ['b', 'b', 'a', 'c', 'e'],
	 *     ['b', 'b', 'd', 'f', '.'],
	 * ]
	 */
	layout[outerIndex] = shiftValueAtIndexToEnd(layout[outerIndex], innerIndex);

	/**
	 * Reverse the order and rotate back up
	 *
	 * After reverse:
	 * layout = [
	 *     ['b', 'b', 'd', 'f', '.'],
	 *     ['b', 'b', 'a', 'c', 'e'],
	 * ]
	 *
	 * After rotation
	 * layout = [
	 *     ['b', 'b'],
	 *     ['b', 'b'],
	 *     ['a', 'd'],
	 *     ['c', 'f'],
	 *     ['e', '.']
	 * ]
	 */
	return rotateLayoutClockwise(layout.reverse());
}
