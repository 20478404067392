import React, { ChangeEvent, FunctionComponent, PropsWithChildren } from 'react';

import { ConfirmationContentProps } from '../base-modal.types';

import { ConfirmationText, useStyles } from './styles';
import { FocalContent } from '../focal-content.react';
import { TextField } from '~components/text-field';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ConfirmationContent: FunctionComponent<PropsWithChildren<ConfirmationContentProps>> = props => {
	const { children, confirmationText, onConfirmationChange, ...rest } = props;
	const { classes } = useStyles();

	const inputId = 'confirmation-content-input';

	return (
		<FocalContent className={classes.container} {...rest}>
			{children}

			<ConfirmationText htmlFor={inputId}>
				{typeof confirmationText === 'string' ? <p>{confirmationText}</p> : confirmationText}
			</ConfirmationText>

			{ifFeature(
				'encore',
				<TextField
					id={inputId}
					inputProps={{ autocomplete: 'off' }}
					onChange={(e: ChangeEvent<HTMLInputElement>): void => onConfirmationChange && onConfirmationChange(e)}
					width={4}
				/>,
				<input
					autoComplete="off"
					className="fab-TextInput fab-TextInput--width4"
					id={inputId}
					onChange={(e: ChangeEvent<HTMLInputElement>): void => onConfirmationChange && onConfirmationChange(e)}
					type="text"
				/>
			)}
		</FocalContent>
	);
};
