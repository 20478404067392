import * as React from 'react';

const SandboxIcon = ({ height = 33, width = 30.49, fill = '#000' }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 345.04 388.67"
		height={height}
		width={width}
		fill={fill}
	>
		<path d="M231.56,325.09l-.67.97c-17.91,8.17-34.39,21.92-51.57,30.83-.49.25-5.59,1.79-6.07,1.81-1.4.06-2.81.15-4.13-.45-47.63-28.07-94.97-56.72-142.47-84.96-8.65-5.14-15.45-8.77-16.54-19.94-1.27-13.09,1.17-26.67-.68-39.74l142.46,85.22c5.11,1.57,15.42,10.24,20.17,10.24,5.6,0,22.41-12.38,28.07-15.72,41.48-24.44,83.88-47.38,124.78-72.81,1.86-1.15,8.7-6.59,10.06-6.33,1.01.19.69,1.19.67,1.81-.46,12.54.83,27.25-.35,39.22-1.01,10.22-9,14.71-16.82,19.66-27.89,17.69-58.58,33.04-86.92,50.19Z" />
		<path d="M10.03,155.23c.62-.71,1.41.73,1.71,1.02,11.99,11.8,30.55,20.09,45.28,28.89,35.99,21.49,71.76,43.35,107.63,65.03,8.72,4.37,11.14,2.25,18.83-2.04,6.91-3.85,13.46-8.55,20.28-12.55,39.56-23.15,80.82-44.98,119.31-69.77,4.45-2.86,7.67-5.51,11.3-9.37.64,1.08.84,2.67.93,3.92.97,13.38-.81,27.9,0,41.4l-.64,1.8c-46.82,30.71-95.93,57.88-144.28,86.14-3.86,2.25-14.39,10.24-18.37,10.15-1.1-.02-3.77-1.66-4.95-2.26-22.35-11.44-46.18-28.26-68.11-41.33-29.07-17.33-58.62-33.97-87.33-51.93-.55-.35-1.62-.39-1.62-.47v-48.64Z" />
		<path d="M167.5,108.42c-1.07-1.07-16.45,2.01-18.96,2.62-25.97,6.35-49.92,23.31-62.82,46.61-3.24,5.86-5.86,12.11-8.49,18.24l-36.2-22.17-.59-1.52c42.95-24.2,84.62-50.64,127.07-75.72,1.17-1.1-.42-18.63.68-19.67l9.97-1.28c.47,5.57-.44,19.77.9,20.96,36.74,22.46,73.38,45.17,110.46,67.07,1.33.79,18.03,9.36,17.21,10.44-.19.25-4.53,1.88-5.66,2.56-35.28,21.16-70.45,42.3-106.01,63.01-3.84,2.23-19.91,13.06-22.95,12.87-1.72-.11-13.86-7.18-16.34-8.62,0,0-36.9-23.59-36.9-23.59,6.5,3.25,13.16,6.12,19.6,9.51,4.7,2.47,9.37,5,14.12,7.37,19.18,14.62,23.04,10.66,41.19-2.26,2.54-1.5,12.16-8.22,14.64-9.74,8.71-5.08,17.61-9.9,26.38-15.05,2.22-1.81,4.34-3.73,6.58-5.51,1-.79,3.99-1.34,3.85-3.05-.13-1.63-2.78-2.42-3.91-3.16-1.8-1.19-3.18-2.89-4.94-4.14-21.16-15.14-42.93-29.79-66.3-41.25-.64-.36-2.59-3.33-2.59-3.55,0-2.85.74-20.23,0-20.98ZM192.63,110.34c-1.76-.31-13.67-2.42-14.18-1.92-.28.26-.24,5.98-.29,7.65-.06,2.32-.73,8.13.88,9.69.56.55,8.42,4.02,10.15,5.05,20.96,12.31,43.99,27.24,63.63,41.55,3.93.31,8.49,1.49,11.69-1.12,2.74-2.68.89-8.75-2.85-13.94-2.78-3.86-6.17-7.3-9.46-10.7-15.39-18.61-34.98-31.94-59.57-36.25Z" />
		<path d="M204.44,17.38c4.47-.61,14.09-.73,16.1,4.25,1.41,3.5,0,10.21-1.06,13.82-2.93,10.04-10.7,17.31-20.85,19.89-5.85,1.48-19.32,2.95-18.98-6.17.56-15.1,8.72-29.61,24.79-31.79Z" />
		<path d="M133.91,15.56c13.65-2,28.82,11.13,31.49,24.13.43,2.09,1.51,8.87,1.32,10.6-.24,2.14-3.66,4.07-5.49,4.74-19.1,7.02-39.92-13.32-36.86-32.6.59-3.73,6.11-6.36,9.55-6.87ZM155.31,44.55c.6-.67-1.03-5.04-1.47-6.07-2.49-5.78-9.14-10.95-15.36-11.99-4.05-.68-4.37-.41-3.75,3.75.48,3.22,3.59,6.53,6.02,8.57,2.13,1.79,12.28,8.29,14.57,5.74Z" />
	</svg>
);

export default SandboxIcon;
