import { Flex, LayoutBox, Loader } from '@bamboohr/fabric';
import { RefObject } from 'react';
import ReactMarkdown from 'react-markdown';
import { ChatLog } from 'src/pages/home/home.types';
import { useLlmStore } from 'src/store';
import BotResponse from '../BotResponse';
import CodeBlock from '../codeBlock/CodeBlock';
import FeedbackButtons from './FeedbackButtons';
import { styles } from './styles';

interface ChatLogRowProps {
	chat: ChatLog;
	chatLogRef: RefObject<HTMLDivElement>;
	hasPrinted: boolean;
	setHasPrinted: (hasPrinted: boolean) => void;
	isLast: boolean;
	isPrinting: boolean;
	setIsPrinting: (isPrinting: boolean) => void;
}

export const ChatLogRow = ({
	chat,
	chatLogRef,
	hasPrinted,
	setHasPrinted,
	isLast,
	isPrinting,
	setIsPrinting,
}: ChatLogRowProps) => {
	const llmLabel = useLlmStore((state) => state.llmLabel);
	return (
		<div
			className="chatLog"
			ref={chatLogRef}
			id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, '-')}`}
		>
			<Flex marginY="24px" justifyContent="center">
				<Flex flexDirection="column" alignItems="end" width={'100%'}>
					<div style={styles.chatPrompt}>
						<Flex padding="12px 16px">
							<ReactMarkdown
								className="react-markdown"
								components={{
									code: CodeBlock,
								}}
							>
								{chat.chatPrompt}
							</ReactMarkdown>
						</Flex>
					</div>
				</Flex>
			</Flex>

			<div style={styles.botMessageMainContainer}>
				<Flex paddingY="24px" justifyContent="center" width="100%">
					<LayoutBox width={'100%'}>
						<Flex gap="12px" alignItems={'start'}>
							<img
								src="/images/bamboohrlogo.png"
								alt="BambooHR logo"
								style={styles.avatarImage}
							/>
							<div style={styles.nameContainer}>{llmLabel}</div>
							<FeedbackButtons chat={chat} />
						</Flex>
						{chat.botMessage ? (
							<div style={styles.botMessage}>
								<BotResponse
									response={chat.botMessage}
									chatLogRef={chatLogRef}
									hasPrinted={hasPrinted}
									setHasPrinted={setHasPrinted}
									sources={chat.sources}
									isLast={isLast}
									isPrinting={isPrinting}
									setIsPrinting={setIsPrinting}
								/>
							</div>
						) : (
							<LayoutBox marginTop="20px">
								<Loader />
							</LayoutBox>
						)}
					</LayoutBox>
				</Flex>
			</div>
		</div>
	);
};
