export function defaultSorter<T>(data: T[], sortBy: (value: T) => boolean | number | string, asc: boolean): T[] {
	return [...data].sort((a, b) => {
		if (!asc) {
			[a, b] = [b, a];
		}

		const aValue = sortBy(a);
		const bValue = sortBy(b);

		if (aValue < bValue) {
			return -1;
		}

		if (bValue < aValue) {
			return 1;
		}

		return 0;
	});
}
