import React from 'react';
import Input from './input';

/**
 * @deprecated Use TextField instead.
 */
const TextInput = props => {
	const { isDisabled, isPending } = props;

	return (
		<div className="fab-InputWrapper">
			<Input {...props} isDisabled={isDisabled || isPending} />
			{isPending ? <div className="fab-TextInput__statusIcon"></div> : null}
		</div>
	);
};

export default TextInput;
