/* eslint-disable no-prototype-builtins */
import { ValueEvent } from '../types/value-event';
import { SetValueAs } from '../types/set-value-as';

export const handleChange =
	(onChange: (value: unknown) => void, currentValue: unknown, setValueAs?: SetValueAs) =>
	(event: ValueEvent, ...extra: []): void => {
		if (setValueAs) {
			const stuff = [...extra, currentValue];
			return onChange(setValueAs(event, ...stuff));
		}

		if (!event) return onChange(event);

		if (event.hasOwnProperty('target')) {
			if (event.target?.hasOwnProperty('checked')) {
				return onChange(event.target.checked);
			}
			return onChange(event.target?.value);
		}
		if (event.hasOwnProperty('checked')) {
			return onChange(event.checked);
		}

		if (event.hasOwnProperty('value')) {
			return onChange(event.value);
		}

		onChange(event);
	};
