import { fixCollisions } from '../collision';
import { toPlacement } from '../locations';
import { calculateVesselOffsets, getOffsetsFromElements } from '../offsets';
import { toLocations } from '../placement';

export function calculatePosition(elements, placement, settings = {}) {
	const { collisionStrategies = [], offset, isAttached } = settings;

	// Find the natural height/width.
	const vesselClone = elements.vessel.cloneNode(true);
	vesselClone.style.visibility = 'hidden';
	vesselClone.style.removeProperty('height');
	vesselClone.style.removeProperty('width');
	elements.vessel.parentNode.appendChild(vesselClone);

	const originalOffsets = getOffsetsFromElements({ ...elements, vessel: vesselClone });

	vesselClone.remove();

	if (isAttached) {
		if (placement.side === 'top' || placement.side === 'bottom') {
			originalOffsets.vessel.left = originalOffsets.anchor.left;
			originalOffsets.vessel.right = originalOffsets.anchor.right;
		} else if (placement.side === 'left' || placement.side === 'right') {
			originalOffsets.vessel.top = originalOffsets.anchor.top;
			originalOffsets.vessel.bottom = originalOffsets.anchor.bottom;
		}
	}

	const locations = toLocations(placement);

	const updatedOffsets = {
		...originalOffsets,
		vessel: calculateVesselOffsets(originalOffsets, locations, offset),
	};

	const { locations: updatedLocations, offsets } = fixCollisions(updatedOffsets, locations, collisionStrategies, offset);

	return {
		offsets,
		placement: toPlacement(updatedLocations),
	};
}

export function modifyPosition(element, offsets) {
	element.style.position = 'absolute';
	element.style.left = `${offsets.left}px`;
	element.style.top = `${offsets.top}px`;
	element.style.height = `${offsets.bottom - offsets.top}px`;
}
