// eslint-disable-next-line no-use-before-define
import React, { ReactElement, RefObject, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { TransferListItemProps } from '../../types';

import { useStyles } from './styles';

export function TransferListItem(props: TransferListItemProps): ReactElement {
	const {
		children,
		disabled,
		isFocused,
		listIndex,
		onClick,
		onFocus,
		onHover,
		toolTipRender,
		rowKey,
		selected = false,
		tabIndex,
		title,
		value,
	} = props;

	const { classes } = useStyles(props);
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();

	useEffect(() => {
		if (anchorEl && isFocused) {
			anchorEl.focus();
		}
	}, [anchorEl, isFocused]);

	const handleClick = useCallback(
		(event: SyntheticEvent) => {
			if (onClick && typeof onClick === 'function' && !disabled) {
				onClick({ value, rowKey, listIndex }, event.nativeEvent as PointerEvent);
			}
		},
		[disabled, listIndex, onClick, rowKey, value]
	);

	const handleKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === ' ') {
				event.preventDefault();
				handleClick({ nativeEvent: event } as unknown as SyntheticEvent);
			}
		},
		[handleClick]
	);

	const containerClasses = classNames(classes.root, {
		[classes.selected]: selected,
	});

	const innerContainerClasses = classNames(
		classes.container,
		{
			[classes.disabled]: disabled,
		},
		{
			[classes.containerTextHover]: !selected,
		}
	);

	function handleHover(hovering: boolean) {
		if (onHover && typeof onHover === 'function') {
			onHover(hovering);
		}
	}

	function handleFocus(focused: boolean) {
		if (onFocus && typeof onFocus === 'function') {
			onFocus(focused);
		}
	}

	return (
		<div
			aria-disabled={disabled}
			aria-selected={selected}
			className={containerClasses}
			data-focus-disabled={listIndex < 0}
			onBlur={() => {
				handleFocus(false);
			}}
			onClick={handleClick}
			onFocus={() => {
				handleFocus(true);
			}}
			onKeyDown={handleKeydown}
			onMouseLeave={() => {
				handleHover(false);
			}}
			onMouseOver={() => {
				handleHover(true);
			}}
			ref={setAnchorEl as unknown as RefObject<HTMLDivElement>}
			role="option"
			tabIndex={tabIndex}
		>
			<div className={innerContainerClasses}>
				{typeof toolTipRender === 'function' && toolTipRender(anchorEl, { disabled, title, value })}
				<div className={`${classes.leftContent} ${classes.centerHorizontal}`}>
					<div className={classes.title}>{title}</div>
				</div>
				{children && <div className={`${classes.rightContent} ${classes.centerHorizontal}`}>{children}</div>}
			</div>
		</div>
	);
}
