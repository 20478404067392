import React, { ReactElement } from 'react';

import { Header } from './header';
import { SectionProps } from './section.types';
import { Subsection } from './subsection';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useSectionStyles } from './section.styles';

export const Section = ({
	ariaLabel,
	ariaLabelledBy,
	children,
	flex,
	gap = 0,
	height,
	minHeight,
	paddingTop = '32px',
	title,
	overflow,
	overflowX,
	overflowY,
}: SectionProps): ReactElement => {
	const { classes } = useSectionStyles({ flex, gap, height, minHeight, paddingTop, title, overflow, overflowX, overflowY });

	return (
		<section aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} className={classes.root}>
			{ifFeature(
				'encore',
				<>
					{title && <Header size="large" title={title} />}
					{children}
				</>,
				'Section component is only available with Encore enabled.'
			)}
		</section>
	);
};

Section.Header = Header;
Section.Subsection = Subsection;
