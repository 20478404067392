import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { mediumScreenWidth } from '~definitions/json/mobile.json';

export const useFooterStyles = makeStyles()(theme => {
	const { breakpoints, palette, constructs, spacing } = theme;

	return {
		footer: {
			backgroundColor: ifFeature('encore', palette.common.white, palette.gray[100]),
			borderBottomLeftRadius: ifFeature('encore', '16px'),
			borderBottomRightRadius: ifFeature('encore', '16px'),
			borderTop: ifFeature('encore', `1px solid ${palette.gray[200]}`, `1px solid ${palette.gray[300]}`),
			flexShrink: 0,
			padding: ifFeature('encore', spacing(3, 5), '24px 32px 32px'),
			/* @startCleanup encore */
			[breakpoints.down('md')]: {
				padding: ifFeature('encore', undefined, '12px 24px 32px'),
			},
			[breakpoints.down(500)]: {
				padding: ifFeature('encore', undefined, '8px 16px 20px'),
			},
			/* @endCleanup encore */
			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				backgroundColor: constructs.surface.neutral.xxWeak,
				padding: spacing(2, 2.5),
			}),
		},
	};
});
