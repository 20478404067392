import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { GridRowProps } from './types';

export const useStyles = makeStyles<{ margin?: GridRowProps['margin'] }>()(({ spacing, mode }, { margin }) => ({
	root: {
		alignItems: 'flex-end',
		display: 'flex',
		marginBottom: spacing(2),
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		'&>:not(:last-child)': {
			marginRight: spacing(1),
		},
		...(margin === 'none' && {
			marginBottom: 0,
		}),
		...(margin === 'dense' && {
			marginBottom: spacing(1),
		}),
	},
}));
