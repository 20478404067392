import { useRef } from 'react';
import config from '../config';
import { Button, IconV2 } from '@bamboohr/fabric';

interface FileUploaderProps {
	handleSubmit: (target: HTMLTextAreaElement | HTMLInputElement) => void;
	setForm: (form: any) => void;
	setInputPrompt: (prompt: string) => void;
}

const FileUploader = ({
	handleSubmit,
	setForm,
	setInputPrompt,
}: FileUploaderProps) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleClick = (event: React.MouseEvent<Element>) => {
		event.preventDefault();

		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const target = event.target as HTMLInputElement;
		const selectedFile = target.files ? target.files[0] : null;

		// Reset file input if no file is selected
		if (!selectedFile) {
			target.files = null;
			return;
		}
		// Make sure user is selecting only PDFs
		const fileExtension = selectedFile.name.split('.').pop();
		if (!config.SUPPORTED_FILE_TYPES.includes(`.${fileExtension}`)) {
			alert(
				'Invalid file type. Please select a valid file type. ' +
					'If the file type is not in full lower case, then change it to be. ',
			);
			target.files = null;
			return;
		}

		const formData = new FormData();
		formData.append('file', selectedFile, selectedFile.name);
		formData.append(
			'prompt',
			`Can you process my file: "${selectedFile.name}"?`,
		);

		setForm(formData);

		setInputPrompt(`Can you process my file: "${selectedFile.name}"?`);
		setTimeout(() => {
			handleSubmit(target);
		}, 100);
	};

	return (
		<>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleFileUpload}
			/>

			<Button
				onClick={handleClick}
				startIcon={<IconV2 name="upload-solid" size={16} />}
				width={7}
				type="button"
			>
				Upload Reference PDF
			</Button>
		</>
	);
};

export default FileUploader;
