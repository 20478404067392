import { AdjacentYValues } from '../types/animated-point-value-props';

const offset = 25;

export const calculatePointValueDyOffset = (dy: number, adjacentYValues: AdjacentYValues, yMax: number, fontSize: number): number => {
	// Handle edge cases where the point value goes off the chart
	if (dy + offset + fontSize / 2 > yMax) {
		return dy - offset;
	}
	if (dy - offset - fontSize / 2 < 0) {
		return dy + offset;
	}
	// Trying to retrieve the average value between the previous and current points
	const prevYVal = adjacentYValues.prevY ?? dy;
	const nextYVal = adjacentYValues.nextY ?? dy;

	// If the average value is less than the current point, display below the point
	if ((nextYVal + prevYVal) / 2 <= dy) {
		return dy + offset;
	}

	// Display above the point
	return dy - offset;
};
