import { DatePickerMonthPredicate, MonthValue, SelectItem, SelectMonthOnChangeParam } from '../../types';

export function getSelectMonthItems(months: Array<string> = [], getMonthDisabled?: DatePickerMonthPredicate): Array<SelectItem> {
	if (months.length !== 12) {
		throw new Error('12 months are expected.');
	}

	return months.map((month, index) => {
		const monthValue = (index + 1) as MonthValue;
		return {
			isDisabled: getMonthDisabled ? getMonthDisabled(monthValue) : false,
			text: month,
			value: monthValue,
		};
	});
}

export function getSelectMonthOnChangeParam(values: Array<MonthValue>): SelectMonthOnChangeParam {
	return {
		value: values[0],
	};
}
