import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode }) => {
	return {
		popoverArrow: {
			transition: 'transform 0.5s',
		},
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});
