import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { LinkProps } from './types';

export const useStyles = makeStyles<Partial<LinkProps> & { dark?: boolean }>()((
	{ breakpoints, mode, palette, spacing, typography },
	{ active, dark }
) => {
	let color = dark ? palette.common.white : palette.gray[700];
	let backgroundColor = 'transparent';

	if (active) {
		backgroundColor = dark ? palette.gray[800] : palette.gray[100];
		color = dark ? palette.common.white : palette.primary.main;
	}

	return {
		root: {
			alignItems: 'center',
			backgroundColor,
			borderRadius: 'unset',
			color,
			display: 'inline-flex',
			fontSize: typography.fabricFontSize('large'),
			fontWeight: active ? typography.fontWeightBold : typography.fontWeightMedium,
			height: '100%',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: spacing(0, 2),
			textDecoration: 'none',
			transition: 'padding 0.2s ease-in-out',
			whiteSpace: 'nowrap',

			'&:after': {
				color: 'transparent',
				display: active ? 'block' : 'none',
				fontWeight: typography.fontWeightBold,
				position: 'absolute',
				content: 'attr(data-text)',
			},

			'&:hover': {
				backgroundColor: dark ? palette.gray[800] : palette.gray[100],
				color: dark ? palette.common.white : palette.primary.main,
				textDecoration: 'none',
			},

			[breakpoints.up('xl')]: {
				padding: '0 20px',
			},
		},
	};
});
