// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { useTheme } from '@mui/styles';
import { GridColumns, GridRows } from '@visx/grid';

import { SwimLanesProps } from '../types';

export const SwimLanes: FC<PropsWithChildren<SwimLanesProps>> = ({ horizontal, vertical }) => {
	const { palette } = useTheme();
	const strokeSettings = {
		stroke: palette.gray[400],
		strokeDasharray: '2,2',
		strokeWidth: 1,
	};

	return (
		<>
			{horizontal && <GridRows {...strokeSettings} {...horizontal} />}
			{vertical && <GridColumns {...strokeSettings} {...vertical} />}
		</>
	);
};
