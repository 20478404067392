import classnames from 'classnames';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import { capitalize } from '@fabric/utils';
import { SIDES } from './constants';
import { CardFlex, ContentSpacing, SpacingStyle } from '../../enums';
import { Side } from '../../types';

const logger = createLogger('@fabric/card | <Content />');

export function getClassName(
	baseName: string,
	{ canOverflow, flex, spacingOverrides }: { canOverflow?: boolean; flex?: CardFlex; spacingOverrides?: SpacingStyle } = {}
): string {
	return classnames(baseName, {
		[`${baseName}--noOverflow`]: canOverflow === false,
		[`${baseName}--customSpacing`]: !!(typeof spacingOverrides === 'object' && Object.keys(spacingOverrides).length),
		[`${baseName}--flexColumn`]: flex === CardFlex.COLUMN,
		[`${baseName}--flexRow`]: flex === CardFlex.ROW,
	});
}

export function getSpacingStyles(spacingOverrides: SpacingStyle = {}): SpacingStyle {
	return Object.entries(spacingOverrides || {}).reduce((memo: SpacingStyle, [key, value]: [Side, ContentSpacing]) => {
		const hasValidKey = SIDES.includes(key);
		const hasValidValue = Object.keys(ContentSpacing).some(k => ContentSpacing[k] === value);

		if (!hasValidKey) {
			logger.warn(`An invalid spacing override key was supplied. Expected one of "${SIDES.join('", ')}" but got ${key}.`);
		}

		if (!hasValidValue) {
			logger.warn(
				`An invalid spacing override value was supplied. Use the "ContentSpacing" enum to ensure a valid value is used.`
			);
		}

		if (hasValidKey && hasValidValue && value !== ContentSpacing.CUSTOM) {
			memo[`padding${capitalize(key)}`] = value;
		}

		return memo;
	}, {});
}
