import React, { forwardRef, ReactNode } from 'react';
import { FabricIcon } from '~components/icon';
import { Button as MuiButton, ExtendButton, useTheme } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { ThemeNameOptions } from '~styles';
import clsx from 'classnames';
import { capitalize, isString, toString } from 'lodash';
import { enhanceAnchorProps } from '~utils';

import { BaseButtonOwnProps, BaseButtonProps, BaseButtonSize } from '../../types/base-button.types';
import ButtonUtils from '../../utils';

import { styles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, IconV2Name, type IconV2Size } from '~components/icon-v2';

export const BaseButton = withStyles(styles)(
	forwardRef<HTMLElement, BaseButtonProps & WithStyles<typeof styles>>(
		(
			{
				active,
				alignContent = 'center',
				ariaLabel: unhyphenatedAriaLabel,
				'aria-label': hyphenatedAriaLabel,
				classes = {},
				className,
				color: colorProp = 'primary',
				children,
				component = 'button',
				dark,
				disabled,
				endIcon: endIconProp,
				focused,
				href,
				icon,
				noSpacing,
				processing,
				startIcon: startIconProp,
				size,
				tabindex,
				toggled,
				type,
				variant: variantProp = 'contained',
				width: widthProp,

				// deprecated props below
				clickAction,
				isActive,
				isDisabled,
				isFocused,
				isProcessing,
				iconAfter,
				iconBefore,
				outline,
				secondary,
				text,
				...rest
			},
			ref
		) => {
			const startIcon = startIconProp || iconBefore;
			const endIcon = endIconProp || iconAfter;
			const variant = outline ? 'outlined' : variantProp;
			const width = variant === 'text' ? undefined : widthProp;
			const ariaLabel = unhyphenatedAriaLabel || hyphenatedAriaLabel;
			const { themeName } = useTheme();

			return (
				<MuiButton
					classes={{
						root: clsx(classes.root, classes[`size${capitalize(size)}`], classes[`width${toString(width)}`], {
							[classes.active as string]: active || isActive,
							[classes.focusVisible as string]: focused || isFocused,
							[classes.processing as string]: processing || isProcessing,
							[classes.noSpacing as string]: noSpacing || themeName === ThemeNameOptions.Encore,
							[classes.textOnly as string]: isString(children) || !!text,
							[classes.iconOnly as string]: !!icon,
							[classes.altStyling as string]: dark,
							[classes.alignContentLeft as string]: alignContent === 'left',
							[classes.alignContentRight as string]: alignContent === 'right',
						}),
						disabled: classes.disabled,
						focusVisible: classes.focusVisible,
						contained: classes.contained,
						containedPrimary: classes.containedPrimary,
						containedSecondary: classes.containedSecondary,
						outlined: clsx(classes.outlined, {
							[classes.toggled as string]: ifFeature('encore', toggled, false),
						}),
						outlinedPrimary: classes.outlinedPrimary,
						outlinedSecondary: classes.outlinedSecondary,
						text: classes.text,
						textPrimary: classes.textPrimary,
						textSecondary: classes.textSecondary,
						startIcon: classes.startIcon,
						endIcon: classes.endIcon,
					}}
					className={clsx(
						className,
						// avoid global anchor styles
						{ 'fab-LinkUnstyled': component === 'a' || !!href },
						// TODO: remove once we're sure this is not needed
						{ 'fab2-LinkUnstyled': component === 'a' || !!href }
					)}
					color={secondary ? 'secondary' : colorProp}
					component={component}
					disabled={disabled || processing || isDisabled || isProcessing}
					endIcon={renderIcon(endIcon, size)}
					onClick={clickAction}
					ref={ref}
					startIcon={renderIcon(startIcon, size)}
					tabIndex={ButtonUtils.sanitizeTabIndex(tabindex)}
					type={ButtonUtils.getAllowedButtonTypes({
						children,
						component,
						endIcon,
						href,
						iconAfter,
						iconBefore,
						icon,
						text,
						type,
					})}
					variant={variant}
					{...enhanceAnchorProps({ ...rest, 'aria-label': ariaLabel, href })}
				>
					<span className={classes.content}>{icon ? renderIcon(icon, size) : children || text}</span>
				</MuiButton>
			);
		}
	)
) as ExtendButton<{ props: BaseButtonOwnProps; defaultComponent: 'button' }>;

/**
 * TODO if button has value for clickAction then it's likely it's the old button so default type to submit.
 * otherwise then default to 'button'
 *
 * Floating icon styles button the border should be set to 0
 */
function renderIcon(icon: BaseButtonProps['icon'], buttonSize: BaseButtonSize = 'medium'): ReactNode {
	return ifFeature(
		'encore',
		isString(icon) ? <IconV2 name={icon as IconV2Name} size={getIconSize(buttonSize)} /> : icon,
		isString(icon) ? <FabricIcon name={icon} /> : icon
	);
}

function getIconSize(buttonSize: BaseButtonSize): IconV2Size {
	switch (buttonSize) {
		case 'extra-small':
		case 'teenie':
			return 12;
		case 'large':
		case 'biggie':
		case 'huge':
			return 20;
		default:
			return 16;
	}
}
