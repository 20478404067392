import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';
import { ListProps } from './types';
import { getEncoreValue } from '~utils';

const roundedRectangleSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
		<rect fill="#000" height="8" rx="2" ry="2" width="8" x="0" y="0" />
	</svg>`;

export const useStyles = makeStyles<Pick<ListProps, 'size'>>()((theme, props) => {
	const { constructs, mixins, palette, spacing, typography } = theme;
	const { size } = props;

	const encoreFontSize = getEncoreValue(size, {
		small: typography.small.fontSize,
		medium: typography.medium.fontSize,
		large: typography.large.fontSize,
	});

	const encoreLineHeight = getEncoreValue(size, {
		small: typography.small.lineHeight,
		medium: typography.medium.lineHeight,
		large: typography.large.lineHeight,
	});

	const encoreMargin = getEncoreValue(size, {
		small: spacing(1),
		medium: spacing(1),
		large: spacing(1.5),
	});

	const encoreTopPixels = getEncoreValue(size, {
		// Sets the top value in background so the bulletpoint is centered
		small: '6px',
		medium: '7px',
		large: '7px',
	});

	return {
		root: {
			color: ifFeature('encore', constructs.text.neutral.xStrong, undefined),
			fontSize: ifFeature('encore', encoreFontSize, typography.medium.fontSize),
			lineHeight: ifFeature('encore', encoreLineHeight, typography.medium.lineHeight),
			marginBottom: ifFeature('encore', encoreMargin, undefined),
			// Style bullets/numbers
			'&::marker': {
				'ul > &': {
					color: ifFeature('encore', constructs.icon.primary.medium, palette.primary.main),
				},

				// Embolden numbers
				'ol > &': {
					display: 'listItem',
					fontWeight: typography.fontWeightSemibold,
				},
			},

			'ul > &': ifFeature('encore', {
				background: `${mixins.inlineSvg(roundedRectangleSvg, {
					fill: constructs.icon.primary.medium,
				})} top ${encoreTopPixels} left no-repeat`,
				listStyle: 'none',
				paddingLeft: spacing(2),
				position: 'relative',
			}),
		},
	};
});
