import CropperJs from 'cropperjs';
import { noop } from 'lodash';
import { CropperResponse, LoadCropper } from './cropper.types';

const resizeCropBox = (cropperRef: LoadCropper['cropperRef'], cornerClass: string) => {
	return (event: KeyboardEvent) => {
		const { key, shiftKey } = event;
		const cropBoxData = cropperRef.current?.getCropBoxData();
		const step = shiftKey ? 10 : 1;

		if (cropBoxData) {
			const newCropBoxData = { ...cropBoxData };
			switch (key) {
				case 'ArrowUp':
					event.preventDefault();
					if (cornerClass.includes('point-ne') || cornerClass.includes('point-nw')) {
						newCropBoxData.top -= step;
						newCropBoxData.height += step;
					} else if (cornerClass.includes('point-se') || cornerClass.includes('point-sw')) {
						newCropBoxData.height -= step;
					}
					break;

				case 'ArrowDown':
					event.preventDefault();
					if (cornerClass.includes('point-ne') || cornerClass.includes('point-nw')) {
						newCropBoxData.top += step;
						newCropBoxData.height -= step;
					} else if (cornerClass.includes('point-se') || cornerClass.includes('point-sw')) {
						newCropBoxData.height += step;
					}
					break;

				case 'ArrowLeft':
					event.preventDefault();
					if (cornerClass.includes('point-nw') || cornerClass.includes('point-sw')) {
						newCropBoxData.left -= step;
						newCropBoxData.width += step;
					} else if (cornerClass.includes('point-ne') || cornerClass.includes('point-se')) {
						newCropBoxData.left += step;
						newCropBoxData.width -= step;
					}

					if (cornerClass.includes('point-ne') || cornerClass.includes('point-se')) {
						newCropBoxData.width -= step;
					} else if (cornerClass.includes('point-nw') || cornerClass.includes('point-sw')) {
						newCropBoxData.width += step;
					}
					break;

				case 'ArrowRight':
					event.preventDefault();
					if (cornerClass.includes('point-nw') || cornerClass.includes('point-sw')) {
						newCropBoxData.left += step;
						newCropBoxData.width -= step;
					} else if (cornerClass.includes('point-ne') || cornerClass.includes('point-se')) {
						newCropBoxData.left -= step;
						newCropBoxData.width += step;
					}

					if (cornerClass.includes('point-ne') || cornerClass.includes('point-se')) {
						newCropBoxData.width += step;
					} else if (cornerClass.includes('point-nw') || cornerClass.includes('point-sw')) {
						newCropBoxData.width -= step;
					}
					break;

				default:
					return;
			}

			cropperRef.current?.setCropBoxData(newCropBoxData);
		}
	};
};

const moveCropBox = (cropperRef: LoadCropper['cropperRef']) => (event: KeyboardEvent) => {
	const { key, shiftKey } = event;
	const cropBoxData = cropperRef.current?.getCropBoxData();
	const step = shiftKey ? 10 : 1;

	if (cropBoxData) {
		switch (key) {
			case 'ArrowUp':
				event.preventDefault();
				cropperRef.current?.setCropBoxData({
					top: cropBoxData.top - step,
				});
				break;
			case 'ArrowDown':
				event.preventDefault();
				cropperRef.current?.setCropBoxData({
					top: cropBoxData.top + step,
				});
				break;
			case 'ArrowLeft':
				event.preventDefault();
				cropperRef.current?.setCropBoxData({
					left: cropBoxData.left - step,
				});
				break;
			case 'ArrowRight':
				event.preventDefault();
				cropperRef.current?.setCropBoxData({
					left: cropBoxData.left + step,
				});
				break;
			default:
				break;
		}
	}
};

function setupMoveButtons({ containerRef, cropperRef, onCropUpdate, biId }: LoadCropper) {
	let eventListeners: { target: EventTarget; type: string; listener }[] = [];

	if (!containerRef.current) {
		return noop;
	}
	const cropperFace = containerRef.current?.querySelector('.cropper-face');
	const cropBox = containerRef.current?.querySelector('.cropper-crop-box') as HTMLDivElement;
	cropBox.setAttribute('tabindex', '0');

	let isCropBoxFocused = false;

	const handleCropBoxFocus = () => {
		isCropBoxFocused = true;
	};
	const handleCropBoxBlur = () => {
		isCropBoxFocused = false;
	};

	cropBox.addEventListener('focus', handleCropBoxFocus);
	eventListeners.push({ target: cropBox, type: 'focus', listener: handleCropBoxFocus });

	cropBox.addEventListener('blur', handleCropBoxBlur);
	eventListeners.push({ target: cropBox, type: 'blur', listener: handleCropBoxBlur });

	const focusCropBox = () => {
		cropBox.focus();
	};
	cropperFace?.addEventListener('click', focusCropBox);
	if (cropperFace) {
		eventListeners.push({ target: cropperFace, type: 'click', listener: focusCropBox });
	}

	// Event listener for moving the crop box (when focused)
	const moveCropBoxListener = event => {
		if (isCropBoxFocused) {
			moveCropBox(cropperRef)(event);
			onCropUpdate();
		}
	};

	cropBox.addEventListener('keydown', moveCropBoxListener);
	eventListeners.push({ target: cropBox, type: 'keydown', listener: moveCropBoxListener });

	const cornerClasses = ['.cropper-point.point-sw', '.cropper-point.point-se', '.cropper-point.point-ne', '.cropper-point.point-nw'];

	// Event listeners for resizing the crop box (on tabbed corner buttons)
	cornerClasses.forEach(cornerClass => {
		const corner = containerRef.current?.querySelector(cornerClass) as HTMLDivElement;
		if (corner) {
			corner.setAttribute(
				'aria-label',
				window.jQuery
					? $.__('Resize crop box with arrow keys. Hold shift to resize faster.')
					: 'Resize crop box with arrow keys. Hold shift to resize faster.'
			);
			if (biId) {
				const dataBiIdAttribute = cornerClass.split('.').pop();
				corner.setAttribute('data-bi-id', `${biId}-${dataBiIdAttribute as string}`);
			}
			corner.setAttribute('tabindex', '0');
			const handleResizeEvent = event => {
				if (!isCropBoxFocused) {
					resizeCropBox(cropperRef, cornerClass)(event);
					onCropUpdate();
				}
			};
			corner?.addEventListener('keydown', handleResizeEvent);
			eventListeners.push({ target: corner, type: 'keydown', listener: handleResizeEvent });

			cropperFace?.after(corner);
		}
	});

	return () => {
		// Removing the event listeners when the component unmounts
		eventListeners.forEach(({ target, type, listener }) => {
			target.removeEventListener(type, listener);
		});
		eventListeners = [];
	};
}

function getAspectRatio(shape) {
	if (typeof shape === 'number') {
		return shape;
	}
	switch (shape) {
		case 'circle':
		case 'square':
			return 1;
		case 'rectangle':
		default:
			return 0;
	}
}

export function loadCropper({
	biId,
	containerRef,
	cropperRef,
	imageRef,
	initialCropBoxCoords,
	onCropUpdate,
	previewSelector,
	shape,
}: LoadCropper): CropperResponse {
	let cleanupMoveButtons: () => void;

	if (imageRef?.current && !cropperRef.current) {
		/**
		 * For CropperJs options
		 * @see https://github.com/fengyuanchen/cropperjs#options
		 */

		cropperRef.current = new CropperJs(imageRef.current, {
			aspectRatio: getAspectRatio(shape),
			autoCropArea: 1,
			center: false,
			cropend: onCropUpdate,
			data: initialCropBoxCoords,
			guides: false,
			highlight: false,
			minContainerHeight: 50,
			minContainerWidth: 50,
			movable: false,
			preview: previewSelector,
			ready: () => {
				cleanupMoveButtons = setupMoveButtons({ containerRef, cropperRef, imageRef, onCropUpdate, biId });
				onCropUpdate();
			},
			rotatable: false,
			scalable: false,
			toggleDragModeOnDblclick: false,
			viewMode: 2,
			zoomable: false,
		});
	}

	return {
		cleanup: () => {
			if (typeof cleanupMoveButtons === 'function') {
				cleanupMoveButtons();
			}

			cropperRef.current?.destroy();
			cropperRef.current = undefined;
		},
	};
}
