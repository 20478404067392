import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<{ isLastPage: boolean }, 'page'>()(
	({ mode, palette, typography, spacing, mixins, borderRadiuses }, { isLastPage = false }, classes) => ({
		root: {
			display: 'flex',
			flexWrap: 'nowrap',
			flexDirection: 'row',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			alignItems: ifFeature('encore', 'center'),
		},
		list: {
			display: 'flex',
			flexWrap: 'nowrap',
			flexDirection: 'row',
		},
		link: {
			color: palette.link.main,
			fill: palette.link.main,
			position: 'relative',
			textDecoration: 'none',
			fontSize: typography.teenie.fontSize,
			lineHeight: typography.teenie.lineHeight,

			'&:hover': {
				textDecoration: 'underline',
			},
			'&:hover, &:focus-visible': {
				color: palette.primary.main,
				fill: palette.primary.main,
			},
			'&:focus-visible:after': {
				borderRadius: borderRadiuses[200],
				boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.light, 0.8)}`,
				content: "''",
				position: 'absolute',
				top: -4,
				right: 0,
				bottom: -4,
				left: 0,
				width: 'unset',
			},
		},
		next: ifFeature(
			'encore',
			{
				fontWeight: typography.fontWeightSemibold,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: spacing(0.5),
				padding: `0 12px`,

				...(isLastPage
					? {
							pointerEvents: 'none',
							color: palette.gray[700],
							fill: palette.gray[700],
							fontSize: typography.teenie.fontSize,
							lineHeight: typography.teenie.lineHeight,
							opacity: 0.5,

							'&:hover': {
								textDecoration: 'none',
							},
						}
					: {}),
			},
			{
				color: palette.info.main,
				fontSize: typography.small.fontSize,
				textDecoration: 'none',

				'&:hover': {
					textDecoration: 'underline',
				},

				...(isLastPage
					? {
							pointerEvents: 'none',
							color: palette.grey[500],

							'&:hover': {
								textDecoration: 'none',
							},
						}
					: {}),
			}
		),
		prev: ifFeature(
			'encore',
			{
				fontWeight: typography.fontWeightSemibold,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: spacing(0.5),
				padding: '0 12px',
			},
			{
				color: palette.info.main,
				fontSize: typography.small.fontSize,
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline',
				},
			}
		),
		page: {
			marginLeft: ifFeature('encore', '7px', '8px'),

			'&:last-child': {
				marginRight: ifFeature('encore', '7px', '8px'),
			},
		},
		hiddenPages: {
			marginLeft: '5px',
			marginRight: '5px',

			[`& + .${classes.page}`]: {
				marginLeft: 0,
			},
		},
	})
);
