/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Pie } from '@visx/shape';
import { PieSection } from './pie-section';
import { PieCoreProps } from './types/pie-core-props';
import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { getSectionLabel } from './get-section-label';
import { getFilteredData } from './get-filtered-data';

export const PieCore = <Datum = unknown,>({
	animate = true,
	animateInitial = true,
	ariaHidden,
	data,
	padAngle = Math.PI / 180,
	pieKey,
	pieValue,
	getColor,
	onClick,
	onMouseMove,
	onMouseOut,
	activeIndex,
	activeGrowthMargin = 0,
	pointerEvents,
	total,
	outerRadius,
	pieSort = (a, b) => (pieValue && a !== undefined && b !== undefined ? pieValue(b) - pieValue(a) : 0),
	...rest
}: PieCoreProps<Datum>): ReactElement => {
	const handleMouseMove = (e, d, index) => {
		if (onMouseMove) {
			onMouseMove(e, d, index);
		}
	};

	const getState = (index: number) => {
		if (activeGrowthMargin) {
			if (activeIndex < 0) return 'idle';
			return activeIndex === index ? 'active' : 'dim';
		}

		return activeIndex >= 0 && activeIndex !== index ? 'inactive' : 'idle';
	};
	const getIsActive = index => animate && activeGrowthMargin && activeIndex === index;

	const { indexes, data: filteredData } = getFilteredData(data, pieValue);

	const sortedIndexes = [...indexes];
	sortedIndexes.sort((a, b) => (data && pieSort ? pieSort(data[a], data[b]) : 0));

	const getSortedArcs = (arcs: PieArcDatum<Datum>[]) =>
		arcs
			.map((a, i) => ({ ...a, originalIndex: indexes[i] }))
			.sort(
				(a, b) => a.startAngle - b.startAngle
			); /** Sorts arcs by angle so that they are rendered clockwise, starting at the top */

	const animatedOuterRadius = outerRadius
		? (d: PieArcDatum<Datum>) => {
				const original = typeof outerRadius === 'function' ? outerRadius(d) : outerRadius;
				if (getIsActive(sortedIndexes[d.index])) {
					return original + activeGrowthMargin * 0.5;
				}
				return original;
			}
		: undefined;

	return (
		<Pie data={filteredData} outerRadius={animatedOuterRadius} padAngle={padAngle} pieSort={pieSort} pieValue={pieValue} {...rest}>
			{({ arcs, path }) =>
				getSortedArcs(arcs).map(arc => {
					const sectionLabel = getSectionLabel({ arc, pieKey, total });
					return (
						<PieSection<Datum>
							animate={animate}
							animateInitial={animateInitial}
							arc={arc}
							ariaHidden={ariaHidden}
							datum={arc.data}
							fill={getColor(arc.originalIndex)}
							/**
							 * If either the key of the datum or the index of the datum changes, the section should be treated as a new one.
							 * This allows us to gracefully handle the shuffling of datums.
							 * */
							key={`arc-${pieKey(arc.data)}-${arc.originalIndex}`}
							label={sectionLabel}
							onClick={onClick}
							onMouseMove={(e, d) => handleMouseMove(e, d, arc.originalIndex)}
							onMouseOut={onMouseOut}
							path={path}
							pointerEvents={pointerEvents}
							state={getState(arc.originalIndex)}
						/>
					);
				})
			}
		</Pie>
	);
};
