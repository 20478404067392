import { DatePickerDate, DatePickerDateType, DatePickerRangeTargetSelectionType, DatePickerUtils } from '../../../types';
import { getDefaultMaxDate, getIsDateValid } from '../../../utils';

/**
 * Returns a max date that is derived from other settings, such as: maxRangeSpanYears and targetSelectionType.
 * Consolidating these settings into a single max date simplifies the logic in the DatePickerCalendarRange
 * component as well as in the other utilities the DatePickerCalendarRange component uses.
 *
 * For example: if a start date is selected and maxRangeSpanYears is set, the DatePickerCalendarRange
 * now must enforce a max date of the start date plus the number of years indicated in maxRangeSpanYears.
 *
 * @param utils The date utils used all over the Date Picker components
 * @param settings An object containing settings that affect the max date
 * @returns A new max date derived from the original max date settings and other DatePickerCalendarRange settings.
 */
export function getDerivedMaxDate(
	utils: DatePickerUtils,
	{
		endDate,
		maxRangeSpanYears,
		maxDate,
		startDate,
		targetSelectionType,
	}: {
		endDate?: DatePickerDate;
		maxRangeSpanYears?: number;
		maxDate?: DatePickerDate;
		startDate?: DatePickerDate;
		targetSelectionType?: DatePickerRangeTargetSelectionType;
	} = {}
): DatePickerDate | undefined {
	maxDate = (getIsDateValid(utils, maxDate) ? maxDate : getDefaultMaxDate(utils)) as DatePickerDateType;

	if (targetSelectionType === 'start' && !!endDate && getIsDateValid(utils, endDate) && utils.isBefore(endDate, maxDate)) {
		return endDate;
	}

	const referenceDate = targetSelectionType === 'start' || targetSelectionType === 'start-ish' ? endDate : startDate;
	if (maxRangeSpanYears && !!referenceDate && getIsDateValid(utils, referenceDate)) {
		const maxDateFromMaxYearSpan = utils.addMonths(referenceDate, 12 * maxRangeSpanYears);

		if (utils.isBefore(maxDateFromMaxYearSpan, maxDate)) {
			return maxDateFromMaxYearSpan;
		}
	}

	return maxDate;
}
