// eslint-disable-next-line no-use-before-define
import React, { ReactElement, RefAttributes, forwardRef } from 'react';
import { FormControl, RadioGroup as MuiRadioGroup } from '@mui/material';
import { ScreenReaderOnlyText } from '~components/screen-reader-only-text';
import { GroupLegend } from '~components/form/components/common/group-legend/group-legend';
import { getBiId } from './radio-group.domain';
import { Radio } from './radio';
import { RadioGroupProps, RadioValue } from './types';
import { FieldContext } from '~components/form/context';

export const RadioGroup = forwardRef<HTMLFieldSetElement, RadioGroupProps>(
	(
		{
			ariaLabel = window.jQuery ? $.__('Radio Group') : 'Radio Group',
			biId,
			className,
			classes = {},
			disabled = false,
			fullWidth,
			id,
			items,
			label,
			name,
			onChange,
			required = false,
			note,
			size = 'medium',
			status = 'default',
			value,
		},
		ref
	) => {
		const noteId = `${id || 'radio-group'}-note`;
		const legendId = `${id || 'radio-group'}-legend`;

		return (
			<FormControl
				aria-describedby={note ? noteId : undefined}
				classes={{ root: classes.root }}
				className={className}
				component="fieldset"
				disabled={disabled}
				fullWidth={fullWidth}
				ref={ref}
				required={required}
			>
				{label ? (
					<FieldContext.Provider value={{ status, required, disabled, noteId }}>
						<GroupLegend id={legendId} label={label} note={note} />
					</FieldContext.Provider>
				) : (
					<legend id={legendId}>
						<ScreenReaderOnlyText>{ariaLabel}</ScreenReaderOnlyText>
					</legend>
				)}
				<div data-bi-id={getBiId(biId, name)}>
					<MuiRadioGroup
						aria-labelledby={legendId}
						aria-required={required}
						name={name}
						ref={ref}
						role="radiogroup"
						value={value}
					>
						{items.map((item, i) => {
							const key = item.id || `${item.value}-${i}`;
							return (
								<Radio
									checked={item.value === value}
									disabled={item.disabled}
									id={item.id}
									key={key}
									label={item.label}
									note={item.note}
									onChange={onChange}
									size={size}
									value={item.value}
								/>
							);
						})}
					</MuiRadioGroup>
				</div>
			</FormControl>
		);
	}
) as <Value extends RadioValue = RadioValue>(props: RadioGroupProps<Value> & RefAttributes<HTMLFieldSetElement>) => ReactElement;
