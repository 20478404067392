import { makeStyles } from '~styles';

export const useScreenReaderOnlyTextStyles = makeStyles()({
	visuallyHidden: {
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: '1px',
		overflow: 'hidden',
		position: 'absolute',
		whiteSpace: 'nowrap',
		width: '1px',
	},
});
