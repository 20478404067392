import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Constraint as BaseModalConstraint, ConstraintSpacing, useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { StandardModalConstraintProps } from '../standard-modal.types';

export const Constraint: FunctionComponent<PropsWithChildren<StandardModalConstraintProps>> & {
	Spacing: typeof ConstraintSpacing;
} = props => {
	const { isMediumScreen } = useScreenSizes();

	return isMediumScreen ? <FullScreenModal.Constraint {...props} /> : <BaseModalConstraint {...props} />;
};

Constraint.Spacing = ConstraintSpacing;
