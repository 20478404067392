import React, { JSXElementConstructor, ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TransferListType, TransferListSide, TransferListItemProps, ListItemClickData, ListItem } from '../../types';
import { PlusArrow21x16, CircleX12x12 } from '@bamboohr/grim';
import { TransferListHoverItem, TransferListItem } from '../transfer-list-item';
import { IconV2 } from '~components/icon-v2';

export function getItemRenderer(
	item: ListItem,
	onClick: (customEventData: ListItemClickData, event: PointerEvent) => ListItem[],
	props: Partial<TransferListItemProps>,
	isFocused: boolean,
	tabIndex: 0 | -1,
	listIndex: number,
	type: TransferListType,
	side: TransferListSide
): ReactElement {
	const newProps: TransferListItemProps = {
		...props,
		...item,
		onClick,
		isFocused,
		tabIndex,
		listIndex,
		icon: ifFeature(
			'encore',
			side === TransferListSide.RIGHT ? (
				<IconV2 name="circle-xmark-solid" size={20} />
			) : (
				<IconV2 name="arrow-right-solid" size={20} />
			),
			side === TransferListSide.RIGHT ? <CircleX12x12 /> : <PlusArrow21x16 />
		),
		rightSide: type === TransferListType.SINGLE_SELECT && side === TransferListSide.RIGHT,
	};

	const renderersByType: Record<TransferListType, JSXElementConstructor<TransferListItemProps>> = {
		[TransferListType.SINGLE_SELECT]: TransferListHoverItem,
		[TransferListType.MULTI_SELECT]: TransferListItem,
	};

	const Component = renderersByType[type];

	return <Component key={item.rowKey} {...newProps} />;
}
