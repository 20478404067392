import { ReactElement } from 'react';
import { getTabIndex } from '../../etc';
import { getItemRenderer } from './get-item-renderer';

import { ListItem, ListItemClickData, TransferListItemProps, TransferListType, TransferListSide } from '../../types';

export function renderUncategorizedList(
	listItems: ListItem[],
	onClick: (customEventData: ListItemClickData, event: PointerEvent) => ListItem[],
	props: Partial<TransferListItemProps>,
	focusedIndex: number,
	type: TransferListType,
	side: TransferListSide
): Array<ReactElement> | undefined {
	if (Array.isArray(listItems)) {
		return listItems.map((item, index): ReactElement => {
			const isFocused = index === focusedIndex;
			const tabIndex = getTabIndex(focusedIndex, index, listItems.length);
			return getItemRenderer(item, onClick, props, isFocused, tabIndex, index, type, side);
		});
	}
}
