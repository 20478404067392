import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStandardModalFooterStyles = makeStyles()(({ breakpoints, palette, spacing }) => {
	return ifFeature(
		'encore',
		{
			root: {
				backgroundColor: palette.gray[50],
				borderBottomLeftRadius: '12px',
				borderBottomRightRadius: '12px',
				padding: '16px 20px',
				[breakpoints.down('sm')]: {
					padding: spacing(2),
				},
			},
		},
		{
			root: {
				alignItems: 'center',
				display: 'flex',
				paddingBottom: '16px',
				paddingTop: '14px',
				position: 'relative',
				'&:before': {
					background: `linear-gradient(90deg, ${palette.primary.lightest} 0, ${palette.primary.lighter} 100%)`,
					content: '""',
					display: 'block',
					height: '2px',
					left: 0,
					position: 'absolute',
					top: 0,
					width: '100%',
				},
			},
		}
	);
});
