import { makeStyles } from '~styles';
import { DURATION, OFFSET } from '../slidedown/constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(() => ({
	default: {
		opacity: 0,
		pointerEvents: 'none',
		transform: `translate(0, -${OFFSET.toString()}px)`,
		transition: `
			transform ${DURATION.toString()}ms cubic-bezier(0.29, 0, 0.4, 1),
			opacity ${DURATION.toString()}ms ease-in-out
		`,
		visibility: 'hidden',
	},
	entering: {
		opacity: 0,
		transform: `translate(0, -${OFFSET.toString()}px)`,
		visibility: 'visible',
	},
	entered: {
		opacity: ifFeature('encore', 1, 0.92),
		transform: `translate(0, 0)`,
		visibility: 'visible',
	},
	exiting: {
		visibility: 'visible',
	},
	exited: {
		visibility: 'hidden',
	},
}));
