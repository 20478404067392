import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Constraint as BaseModalConstraint, ConstraintSpacing } from '~components/base-modal';
import { FullScreenModalConstraintProps } from '../full-screen-modal.types';

export const Constraint: FunctionComponent<PropsWithChildren<FullScreenModalConstraintProps>> & {
	Spacing: typeof ConstraintSpacing;
} = props => {
	return <BaseModalConstraint {...props} canScroll={false} />;
};

Constraint.Spacing = ConstraintSpacing;
