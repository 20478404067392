import React, { ComponentType, PropsWithChildren } from 'react';
import { LayoutFactory } from '~components/layout-factory';
import { FormFactoryBuiltinElementsUnion, FormFactoryProps } from './form-factory.types';
import { contextMiddleware } from './middleware/context-middleware';
import { onChangeMiddleware } from './middleware/on-change-middleware';
import { validationMiddleware } from './middleware/validation-middleware';
import { FormFactoryElement } from './types/element.types';
import { FormFactoryField } from './types/field.types';
import { getComponentByType } from './utils/get-component-by-type';

export function FormFactory<
	C extends ComponentType<PropsWithChildren<M>>,
	M extends FormFactoryElement | FormFactoryField<FormFactoryElement> = FormFactoryBuiltinElementsUnion,
>({ context, getComponent, layout, models, onChange, validation }: FormFactoryProps<C, M>) {
	return (
		<LayoutFactory<C, M | FormFactoryBuiltinElementsUnion>
			getComponent={model => {
				if ('hidden' in model && model.hidden) {
					return null;
				}

				let component: C | null | undefined = typeof getComponent === 'function' ? getComponent(model) : undefined;
				if (typeof component === 'undefined') {
					component = getComponentByType(model.type) as C;
				}

				if (typeof component === 'undefined') {
					throw new Error(`Could not find a component that matches model ${JSON.stringify(model)}`);
				}

				return component;
			}}
			layout={layout}
			middleware={[
				...(context
					? [
							contextMiddleware({
								context,
							}),
						]
					: []),
				onChangeMiddleware(changes => {
					if (typeof onChange === 'function') {
						onChange(changes);
					}
				}),
				...(validation ? [validationMiddleware(validation)] : []),
			]}
			models={models}
		/>
	);
}
