import { makeStyles } from '~styles';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore
export const useStyles = makeStyles()(({ typography }) => {
	return {
		textInput: {
			backgroundColor: 'transparent',
			boxSizing: 'border-box',
			border: 'none',
			display: 'flex',
			fontSize: typography.medium.fontSize,
			height: '100%',
			padding: ifFeature('encore', 0, '0 17px'),
			width: '100%',
			'&:focus': {
				outline: 'none',
			},
		},
	};
});
