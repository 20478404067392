import { useMemo } from 'react';

import { DonutChartProps } from '../../types';

type UseTotalArguments<Datum = unknown> = Pick<DonutChartProps<Datum>, 'data' | 'pieValue'>;

export const useTotal = <Datum = unknown>({ data, pieValue }: UseTotalArguments<Datum>): number => {
	const total = useMemo(() => {
		return data.reduce((acc, datum) => {
			return acc + (pieValue ? pieValue(datum) : 0);
		}, 0);
	}, [data, pieValue]);
	return total;
};
