import { isFunction } from 'lodash';
import React from 'react';
import { CalendarBase } from '~components/calendar-base';
import { CalendarV2Props } from './calendar-v2.types';

export const CalendarV2 = (props: CalendarV2Props) => {
	const { value, onChange, disabled, navigation, startMonth, endMonth, renderDay, biId } = props;

	return (
		<CalendarBase
			biId={biId}
			disabled={disabled}
			endMonth={endMonth}
			month={value ?? new Date().toISOString()}
			navigation={navigation}
			onChange={onChange}
			renderDay={(date, modifiers) => {
				const { disabled } = modifiers;
				return isFunction(renderDay) ? (
					renderDay(date, modifiers)
				) : (
					<CalendarBase.Day component={disabled ? 'div' : 'button'} />
				);
			}}
			selectionMode="single"
			startMonth={startMonth}
			value={value}
		/>
	);
};

CalendarV2.Day = CalendarBase.Day;
