import { useSelectRows } from './use-select-rows';
import { ListItem, RowClickData } from '../types';

import { sortItemsByTitle, filterSelectedItems } from '../etc';

interface Options {
	availableOptions: Array<ListItem>;
	selectedOptions: Array<ListItem>;
	sortList?: (list: Array<ListItem>) => Array<ListItem>;
	setAvailableOptions: (arg0: Array<ListItem>) => void;
	setSelectedOptions: (arg0: Array<ListItem>) => void;
}

export function useMultiSelectControls(options: Options) {
	const {
		availableOptions = [],
		sortList = sortItemsByTitle,
		selectedOptions = [],
		setAvailableOptions,
		setSelectedOptions,
	} = options;

	const rowSelectHandler = useSelectRows();

	const onClickAddAll = () => {
		const allOptions = [...selectedOptions, ...availableOptions];
		const disabledOptions = allOptions.filter(option => option.disabled === true);
		const enabledOptions = allOptions.filter(option => option.disabled !== true);
		setAvailableOptions(disabledOptions);
		setSelectedOptions(sortList(enabledOptions));
	};
	const onClickAddHighlighted = () => {
		filterSelectedItems(selectedOptions, availableOptions, setAvailableOptions, setSelectedOptions, sortList);
	};
	const onClickRemoveHighlighted = () => {
		filterSelectedItems(availableOptions, selectedOptions, setSelectedOptions, setAvailableOptions, sortList);
	};
	const onClickRemoveAll = () => {
		const newAvailable = [...availableOptions, ...selectedOptions];
		setAvailableOptions(sortList(newAvailable));
		setSelectedOptions([]);
	};

	function useOnAvailableSelect(customEventData: RowClickData, event: PointerEvent): Array<ListItem> {
		const updatedSelect = rowSelectHandler(availableOptions, customEventData.key, event);
		setAvailableOptions(updatedSelect);
		return updatedSelect;
	}

	function useOnSelectedSelect(customEventData: RowClickData, event: PointerEvent): Array<ListItem> {
		const updatedSelect = rowSelectHandler(selectedOptions, customEventData.key, event);
		setSelectedOptions(updatedSelect);
		return updatedSelect;
	}

	return {
		availableOptions,
		selectedOptions,
		onClickAddAll,
		onClickAddHighlighted,
		onClickRemoveHighlighted,
		onClickRemoveAll,
		useOnAvailableSelect,
		useOnSelectedSelect,
	};
}
