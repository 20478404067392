export enum TYPES {
	discovery = 'discovery',
	error = 'error',
	info = 'info',
	success = 'success',
	warning = 'warning',
}

export const DURATION = 500;
export const OFFSET = 68;
export const AUTO_HIDE_DURATION = 5000;
export const AUTO_HIDE_ACTION_DURATION = 10000;
