import { SectionProps } from './section.types';
import { makeStyles } from '~styles';

export const useSectionStyles = makeStyles<Partial<SectionProps>>()((theme, props) => {
	const { borderRadiuses, constructs, mixins, palette, spacing } = theme;
	const { flex, gap, height, minHeight, paddingTop, title, overflow, overflowX, overflowY } = props;

	return {
		root: {
			backgroundColor: palette.common.white,
			borderRadius: borderRadiuses[400],
			boxShadow: mixins.boxShadow('500', constructs.surface.neutral.xxStrong),
			boxSizing: 'border-box',
			display: 'flex',
			flex,
			flexDirection: 'column',
			gap: gap !== undefined ? spacing(gap) : undefined,
			height: height || 'auto',
			minHeight: minHeight || 'auto',
			padding: spacing(4),
			paddingTop: title ? spacing(2.5) : paddingTop,
			overflow,
			overflowX,
			overflowY,
			'@media print': {
				background: 'none',
				borderRadius: 0,
				boxShadow: 'none',
				paddingInline: 0,
			},
		},
	};
});
