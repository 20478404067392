import {
	DatePickerDate,
	DatePickerDatePredicate,
	DatePickerDateType,
	DatePickerRangeTargetSelectionType,
	DatePickerUtils,
} from '../../../types';
import { getClosestEnabledDates, getIsDateDisabled, getUpdatedConstraintsForRange } from '../../../utils';

/**
 * This utility will return the closest date to the provided date that is enabled and exists in one of the visible months.
 * If the date is already enabled and visible in one of the calendars then it will be the return value. If no dates are enabled
 * in either of the visible months then `undefined` will be returned.
 * @param utils The date utils used all over the Date Picker components
 * @param firstMonth The first visible date
 * @param lastMonth The last visible date
 * @param targetSelectionType The target selection type
 * @param constraints The constraints that affect if a date is enabled or disabled
 * @param date The date that is currently active
 * @returns The date that should now be focused
 */
export function getClosestEnabledAndVisibleDate(
	utils: DatePickerUtils,
	firstMonth: DatePickerDate,
	lastMonth: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	constraints: { getDateDisabled?: DatePickerDatePredicate; maxDate?: DatePickerDate; minDate?: DatePickerDate } = {},
	date: DatePickerDate = firstMonth
) {
	const updatedConstraints = getUpdatedConstraintsForRange(
		utils,
		firstMonth,
		utils.startOfDay(utils.endOfMonth(lastMonth as DatePickerDateType)),
		constraints
	);

	if (!getIsDateDisabled(utils, date, updatedConstraints.minDate, updatedConstraints.maxDate, updatedConstraints.getDateDisabled)) {
		return date;
	}

	const { next, previous } = getClosestEnabledDates(utils, date, updatedConstraints);

	if (targetSelectionType === 'start' || targetSelectionType === 'start-ish') {
		return next || previous;
	}

	if (targetSelectionType === 'end' || targetSelectionType === 'end-ish') {
		return previous || next;
	}
}
