import { CategorizedListItems, ListItem, TransferListItemProps } from '../../types';

export const getCategorizedItemData = (listItems: ListItem[]): CategorizedListItems => {
	let categoryIndex = 0;
	let subcategoryIndex = 0;
	return listItems.reduce((acc: CategorizedListItems, entry: TransferListItemProps, index): CategorizedListItems => {
		entry.listIndex = index;
		if (entry.category) {
			if (!acc[entry.category]) {
				acc[entry.category] = { category: entry.category, items: [], subcategories: [], index: categoryIndex };
				categoryIndex += 1;
			}
			if (entry.subcategory) {
				const subcategory = acc[entry.category].subcategories.find(subcategory => subcategory.name === entry.subcategory);
				if (!subcategory) {
					acc[entry.category].subcategories.push({
						items: [entry],
						name: entry.subcategory,
						grouped: entry.grouped,
						index: subcategoryIndex,
					});
					subcategoryIndex += 1;
				} else {
					subcategory.items.push(entry);
				}
			} else if (entry.value !== undefined) {
				acc[entry.category].items.push(entry);
			}
		}
		return acc;
	}, {} as CategorizedListItems);
};
