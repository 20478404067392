interface ConfettiParticle {
	xPos: number;
	yPos: number;
	size: number;
	density: number;
	color: string;
	tilt: number;
	tiltAngleIncremental: number;
	tiltAngle: number;
}

export class ConfettiObject {
	private target: HTMLElement;

	private canvas: HTMLCanvasElement;

	private readonly context: CanvasRenderingContext2D | null;

	private drawInterval: number;

	private readonly setRelative: boolean;

	private readonly numParticles: number;

	private readonly particles: ConfettiParticle[];

	private angle: number;

	private tiltAngle: number;

	private previousTimeStamp: number;

	private elapsedTime: number;

	private readonly standardElapsedTime: number;

	private deviationMultiplyer: number;

	constructor(canvasEl: HTMLCanvasElement, numParticlesParam = 30, setRelativeParam = true) {
		// parentElement could potentially return null
		this.target = canvasEl.parentElement ?? document.createElement('div');
		this.canvas = canvasEl;
		this.context = canvasEl.getContext('2d');
		this.drawInterval = 0;
		this.setRelative = setRelativeParam;

		this.numParticles = numParticlesParam;
		this.particles = [];

		this.angle = 0;
		this.tiltAngle = 0;

		// Typical elapsed time between animations
		this.standardElapsedTime = 13.346;

		this.initialize();

		this.start();
	}

	initialize() {
		this.setDimensions();

		if (this.setRelative) {
			this.target.style.position = 'relative';
		}

		for (let i = 0; i < this.numParticles; i++) {
			this.particles.push(this.getNewParticle());
		}

		window.addEventListener('resize', this.setDimensions.bind(this));
	}

	setDimensions() {
		this.canvas.width = this.target.clientWidth;
		this.canvas.height = this.target.clientHeight;
	}

	draw(timestamp: number) {
		if (!this.context) {
			return;
		}

		if (this.previousTimeStamp === undefined) {
			this.previousTimeStamp = timestamp;
			this.elapsedTime = this.standardElapsedTime;
		} else {
			this.elapsedTime = timestamp - this.previousTimeStamp;
		}

		this.deviationMultiplyer = this.elapsedTime / this.standardElapsedTime;

		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		for (let i = 0; i < this.particles.length; i++) {
			const p = this.particles[i];
			this.context.beginPath();
			this.context.lineWidth = p.size * 0.3;
			this.context.strokeStyle = p.color;
			this.context.moveTo(p.xPos + p.tilt + p.size * 0.25, p.yPos);
			this.context.lineTo(p.xPos + p.tilt, p.yPos + p.tilt * 0.5 + p.size * 0.1);
			this.context.stroke();
		}

		this.update();

		this.previousTimeStamp = timestamp;
		this.drawInterval = window.requestAnimationFrame(this.draw.bind(this));
	}

	getNewParticle() {
		return {
			xPos: Math.random() * this.canvas.width,
			yPos: Math.random() * this.canvas.height,
			size: this.randomFromTo(5, 30),
			density: Math.random() * this.numParticles + 10,
			color: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
				Math.random() * 255
			)}, 0.7)`,
			tilt: Math.floor(Math.random() * 10) - 10,
			tiltAngleIncremental: Math.random() * 0.04 + 0.03,
			tiltAngle: 0,
		};
	}

	// eslint-disable-next-line class-methods-use-this
	randomFromTo(from: number, to: number) {
		return Math.floor(Math.random() * (to - from + 1) + from);
	}

	update() {
		this.angle += 0.01;
		this.tiltAngle += 0.1;

		for (let i = 0; i < this.particles.length; i++) {
			const p = this.particles[i];
			p.tiltAngle += p.tiltAngleIncremental * this.deviationMultiplyer;
			// Previous fall speed multiplier was 0.15, adjusted to 0.1 with move to requestAnimationFrome
			p.yPos += (Math.cos(this.angle + p.density) + 1 + p.size * 0.5) * 0.1 * this.deviationMultiplyer;
			p.xPos += Math.sin(this.angle) * 0.5 * this.deviationMultiplyer;
			p.tilt = Math.sin(p.tiltAngle - i / 3) * 15;

			// Sending particles back from the top when it exits
			if (p.xPos > this.canvas.width + 15 || p.xPos < -15 || p.yPos > this.canvas.height + 15) {
				p.tilt = Math.floor(Math.random() * 10) - 10;
				// 66.67% of the particles
				if (i % 5 > 0 || i % 2 === 0) {
					p.xPos = Math.random() * this.canvas.width;
					p.yPos = -10;
				} else {
					p.yPos = Math.random() * this.canvas.height;
					if (Math.sin(this.angle) > 0) {
						// From the left
						p.xPos = -5;
					} else {
						// From the right
						p.xPos = this.canvas.width + 5;
					}
				}
			}
		}
	}

	start() {
		this.drawInterval = window.requestAnimationFrame(this.draw.bind(this));
	}

	stop() {
		if (this.drawInterval) {
			window.cancelAnimationFrame(this.drawInterval);
		}
		if (this.context) {
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		}
	}
}
