import { Layout } from '../types';

/**
 * Rotates a layout clockwise
 * input = [
 *     ['a', 'b'],
 *     ['c', 'd'],
 * ]
 *
 * output = [
 *     ['c', 'a'],
 *     ['d', 'b'],
 * ]
 */
export function rotateLayoutClockwise(layout: Layout): Layout {
	const values: Layout = Array.from({ length: layout[0].length }, () => []);

	layout.forEach(innerArray => {
		innerArray.forEach((element, i) => {
			values[i].unshift(element);
		});
	});

	return values;
}
