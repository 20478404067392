import { Icon } from '@fabric/icon';
import { Typography } from '@fabric/typography';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StandardHeadlineProps } from '../base-modal.types';

import { StyledRoot, StyledIcon } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { UpperContent } from '~components/base-modal';
import { BadgeV2 } from '~components/badge-v2';

export const StandardHeadline: FunctionComponent<PropsWithChildren<StandardHeadlineProps>> = props => {
	const { children, icon, text = '', description, ...rest } = props;

	return ifFeature(
		'encore',
		<UpperContent>
			<BadgeV2 icon={icon || <></>} size="large" subtitle={description} title={text} />
			{children}
		</UpperContent>,
		<StyledRoot {...rest}>
			{icon ? <StyledIcon>{typeof icon === 'string' ? <Icon brand={true} name={icon} /> : icon}</StyledIcon> : null}
			{text ? (
				<Typography component="h3" sx={{ m: 0 }} variant="h4">
					{text}
				</Typography>
			) : null}
			{children}
		</StyledRoot>
	);
};
