// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ScaleBand } from 'd3-scale';
import { BarValuesProps } from '../types';
import { AnimatedBarValues } from './animated-bar-values';

type ScaleType = string & { valueOf(): number };

export const BarValues = <Datum = unknown,>({
	animate,
	data,
	orientation,
	getBandTotal,
	getBarValueFormat,
	getBarValue,
	getSeriesValue,
	keys,
	xMax,
	xScale,
	yMax,
	yScale,
}: BarValuesProps<Datum>): ReactElement => {
	// add more padding for each digit
	const getHorizontalDxValue = (v: number) => +v?.toString()?.length * 3 + 13;

	const isVertical = orientation === 'vertical';

	let barSize = 0;
	if (keys) {
		for (let i = 0; i < keys.length; i++) {
			barSize += getBarValue(data, keys[i]);
		}
	} else {
		barSize = getBandTotal(data);
	}

	const value = barSize;
	const seriesName = getSeriesValue(data);
	const displayText = getBarValueFormat ? getBarValueFormat(value, data) : String(value);
	const x = xScale((isVertical ? getSeriesValue(data) : value) as ScaleType) as number;
	const y = yScale((isVertical ? value : getSeriesValue(data)) as ScaleType) as number;
	const dx = isVertical ? (xScale as ScaleBand<string>)?.bandwidth() / 2 : getHorizontalDxValue(value);
	const dy = isVertical ? -15 : (yScale as ScaleBand<string>)?.bandwidth() / 2;

	return (
		<AnimatedBarValues
			animate={animate}
			displayText={displayText}
			dx={dx}
			dy={dy}
			orientation={orientation}
			title={window.jQuery ? $.__('total for %s', seriesName) : `total for ${seriesName}`}
			x={x}
			xMax={xMax}
			y={y}
			yMax={yMax}
		/>
	);
};
