import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import { useScreenSizes as useFabricScreenSizes } from '@fabric/utils/hooks';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { BaseModalContext } from './context';
import { BodyScrollHelper } from './utils/body-scroll-helper';
import { preventDefault } from './utils/dom';
import { countItemsOfType, useUnmount } from '../../utils';

const scrollHelper = new BodyScrollHelper();

export function useMaxZIndex(isOpen: boolean): number | undefined {
	const maxZIndex = useRef<number | undefined>();

	return useMemo((): number | undefined => {
		if (isOpen) {
			maxZIndex.current = getMaxZIndex() + 10;
		}
		return maxZIndex.current;
	}, [isOpen]);
}

export function usePreventScroll(canDisableScroll: boolean, isOpen: boolean, backdropElement?: HTMLElement): void {
	useEffect((): void => {
		const openModalCount = countItemsOfType('MODAL');
		if (canDisableScroll) {
			if (isOpen && openModalCount === 1 && backdropElement) {
				scrollHelper.disableScroll(backdropElement);
			} else if (!isOpen && openModalCount === 0) {
				scrollHelper.enableScroll();
			}
		}
	}, [canDisableScroll, isOpen, backdropElement]);

	useUnmount(
		(modalIsOpen, modalCanDisableScroll): void => {
			const openModalCount = countItemsOfType('MODAL');
			// a modal was open when unmounted but the stack has been updated to
			// have 0 modals, so take care of this last item of business
			if (modalIsOpen && modalCanDisableScroll && openModalCount === 0) {
				scrollHelper.enableScroll();
			}
		},
		isOpen,
		canDisableScroll
	);
}

export function usePreventSubmit(): (contentElement: HTMLElement) => void {
	const contentRef = useRef<HTMLElement | undefined>();
	const handleSubmit = useCallback((e: Event) => e.preventDefault(), []);

	useEffect(
		() => (): void => {
			if (contentRef.current) {
				contentRef.current.removeEventListener('submit', preventDefault);
			}
		},
		[contentRef, handleSubmit]
	);

	return (contentElement: HTMLElement): void => {
		if (contentElement) {
			contentRef.current = contentElement;
			contentElement.addEventListener('submit', preventDefault);
		}
	};
}

export function useScreenSizes(): { isMediumScreen: boolean } {
	const { isOpen } = useContext(BaseModalContext);

	return useFabricScreenSizes(isOpen);
}

export function useContentRef() {
	return useRef<HTMLElement | null>(null);
}
