import { AgGridReact } from 'ag-grid-react';
import { RefObject } from 'react';

export function getAllRowData<TData>(gridRef: RefObject<AgGridReact>) {
	return () => {
		const rows: Array<TData> = [];
		gridRef.current?.api.forEachNode(rowNode => {
			rows.push(rowNode.data);
		});
		return rows;
	};
}
