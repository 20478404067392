/* eslint-disable sort-keys */
import { makeStyles } from '~styles';
import { getEncoreValue } from '~utils';
import { StyledBoxV2Props } from './styled-box-v2.types';

export const useStyles = makeStyles<StyledBoxV2Props>()((theme, props) => {
	const {
		borderRadiuses,
		constructs: { border, surface },
		mixins,
	} = theme;
	const { backgroundColor, borderColor, borderRadius, borderStyle, boxShadow, objectFit } = props;

	const backgroundColors = {
		none: 'transparent',
		'primary-weak': surface.primary.weak,
		'primary-medium': surface.primary.medium,
		'primary-strong': surface.primary.strong,
		'success-weak': surface.success.weak,
		'success-medium': surface.success.medium,
		'success-strong': surface.success.strong,
		'warning-weak': surface.warning.weak,
		'warning-medium': surface.warning.medium,
		'warning-strong': surface.warning.strong,
		'error-weak': surface.error.weak,
		'error-medium': surface.error.medium,
		'error-strong': surface.error.strong,
		'info-weak': surface.info.weak,
		'info-medium': surface.info.medium,
		'info-strong': surface.info.strong,
		'discovery-weak': surface.discovery.weak,
		'discovery-medium': surface.discovery.medium,
		'discovery-strong': surface.discovery.strong,
		'notice-strong': surface.notice.strong,
		'neutral-extra-extra-weak': surface.neutral.xxWeak,
		'neutral-extra-weak': surface.neutral.xWeak,
		'neutral-weak': surface.neutral.weak,
		'neutral-medium': surface.neutral.medium,
		'neutral-strong': surface.neutral.strong,
		'neutral-extra-strong': surface.neutral.xStrong,
		'neutral-extra-extra-strong': surface.neutral.xxStrong,
		'neutral-inverted': surface.neutral.inverted,
		'neutral-forced-white': surface.neutral.forcedWhite,
	};

	const borderColors = {
		none: 'transparent',
		'primary-weak': border.primary.weak,
		'primary-medium': border.primary.medium,
		'primary-strong': border.primary.strong,
		'success-weak': border.success.weak,
		'success-medium': border.success.medium,
		'success-strong': border.success.strong,
		'warning-weak': border.warning.weak,
		'warning-medium': border.warning.medium,
		'warning-strong': border.warning.strong,
		'error-weak': border.error.weak,
		'error-medium': border.error.medium,
		'error-strong': border.error.strong,
		'info-weak': border.info.weak,
		'info-medium': border.info.medium,
		'info-strong': border.info.strong,
		'discovery-weak': border.discovery.weak,
		'discovery-medium': border.discovery.medium,
		'discovery-strong': border.discovery.strong,
		'neutral-extra-extra-weak': border.neutral.xxWeak,
		'neutral-extra-weak': border.neutral.xWeak,
		'neutral-weak': border.neutral.weak,
		'neutral-medium': border.neutral.medium,
		'neutral-strong': border.neutral.strong,
		'neutral-extra-strong': border.neutral.xStrong,
		'neutral-inverted': border.neutral.inverted,
		'neutral-forced-white': border.neutral.forcedWhite,
	};

	return {
		root: {
			backgroundColor: getEncoreValue(backgroundColor, backgroundColors),
			borderColor: getEncoreValue(borderColor, borderColors),
			borderRadius: getEncoreValue(borderRadius, {
				none: 'unset',
				small: borderRadiuses[200],
				medium: borderRadiuses[300],
				large: borderRadiuses[400],
				full: borderRadiuses[1000],
			}),
			boxShadow: getEncoreValue(boxShadow, {
				none: 'unset',
				small: mixins.boxShadow('300', surface.neutral.xxStrong),
				medium: mixins.boxShadow('300', surface.neutral.xxStrong),
				large: mixins.boxShadow('500', surface.neutral.xxStrong),
				'extra-large': mixins.boxShadow('500', surface.neutral.xxStrong),
			}),
			borderStyle,
			borderWidth: 1,
			objectFit,
		},
	};
});
