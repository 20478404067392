import { FieldValues, useFormContext as useHookFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { getFields } from './get-fields';
import { FormFieldDefinition, UseFormProps } from './types';

export const useFormContext = <T extends FieldValues>(fieldDefinitions?: FormFieldDefinition<T>[]): UseFormProps<T> => {
	const form = useHookFormContext<T>() as UseFormProps<T>;

	const { register, fields: formFields } = form;
	const fields = useMemo(() => {
		if (!fieldDefinitions) return formFields;

		return getFields<T>(fieldDefinitions, register, formFields);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(fieldDefinitions), JSON.stringify(formFields), register]);

	return {
		...form,
		fields,
	};
};
