// eslint-disable-next-line no-use-before-define
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { forwardRef, ReactElement, RefObject, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CurrencyContext } from '~components/currency-picker-provider';
import { useReadableContext } from '~components/readable';
import { CurrencyPicker } from '~components/currency-picker';
import { ControlledAmountInput } from './controlled-amount-input';
import { CurrencyFieldProps } from './types';
import { useSelectedCurrency } from './hooks/use-selected-currency';
import { currencyItems as defaultCurrencyItems } from './currency-items';
import { useStyles } from './currency-field.styles';

export const CurrencyField = forwardRef((props: CurrencyFieldProps, ref: RefObject<{ focus: () => void }>): ReactElement => {
	const {
		afterLabel,
		biId,
		disabled,
		id,
		label,
		minValue,
		name,
		note,
		notePlacement = 'block',
		onBlur,
		onChange,
		onFocus,
		required,
		size = ifFeature('encore', 'medium', 'small'),
		status,
		submitUnmaskedValue = true,
		value = {},
		variant,
		viewMode = false,
		width,
	} = props;

	const [amount, setAmount] = useState<string>(value?.amount || '');

	const { classes } = useStyles({ disabled, size, status });

	const { isReadable } = useReadableContext();
	const { currencyCode, currencyItems, decimalCharacter, normalizeZeros, padFractionalZeros, scale, thousandsSeparator } =
		useContext(CurrencyContext);

	useEffect(() => {
		setAmount(value?.amount || '');
	}, [value.amount]);

	const onChangeCurrency = (newCurrencyCode: string) => {
		onChange?.({ amount, currencyCode: newCurrencyCode });
	};

	const defaultCurrencyItem =
		defaultCurrencyItems.find(item => {
			return item.code === currencyCode;
		}) || defaultCurrencyItems[0];

	const currencyItemFromProps = value?.currencyCode ? currencyItems?.find(item => item.code === value?.currencyCode) : undefined;

	const { selectedCurrencyItem, handleCurrencyCodeSelected } = useSelectedCurrency(
		currencyItemFromProps || defaultCurrencyItem,
		onChangeCurrency
	);

	const onChangeAmount = (newAmount: string) => {
		setAmount(newAmount);
		onChange?.({ amount: newAmount, currencyCode: selectedCurrencyItem.code });
	};

	const amountInputRef = useRef<HTMLInputElement>(null);

	useImperativeHandle(
		ref,
		() => {
			return {
				id,
				focus: () => {
					amountInputRef.current?.focus();
				},
			};
		},
		[id, amountInputRef]
	);

	const currencyPicker = (
		<CurrencyPicker
			biId={biId && `${biId}-currency`}
			buttonProps={ifFeature('encore', {
				classes: { focusVisible: classes.focusVisible },
			})}
			currencyCollection={currencyItems && currencyItems.length ? currencyItems : [selectedCurrencyItem]}
			disabled={disabled}
			name={name && `${name}-currency`}
			onChange={handleCurrencyCodeSelected}
			size={size}
			status={status}
			value={selectedCurrencyItem.code}
			variant={variant}
		/>
	);

	return (
		<ControlledAmountInput
			afterLabel={afterLabel}
			biId={biId && `${biId}-amount`}
			currencyCode={selectedCurrencyItem.code}
			currencySymbol={selectedCurrencyItem.symbol}
			disabled={disabled}
			id={id}
			label={label}
			menuButton={!isReadable && !viewMode && currencyItems ? currencyPicker : undefined}
			minValue={minValue}
			name={name && `${name}-amount`}
			normalizeZeros={normalizeZeros}
			note={note}
			notePlacement={notePlacement}
			onBlur={onBlur}
			onChange={onChangeAmount}
			onFocus={onFocus}
			padFractionalZeros={padFractionalZeros}
			radix={decimalCharacter || '.'}
			ref={amountInputRef}
			required={required}
			scale={scale}
			size={size}
			status={status}
			submitUnmaskedValue={submitUnmaskedValue}
			symbolPosition={selectedCurrencyItem.symbolPosition}
			thousandsSeparator={thousandsSeparator}
			value={amount}
			variant={variant}
			viewMode={viewMode}
			width={width}
		/>
	);
});
