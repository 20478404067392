import React, { cloneElement, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { Button, TextButton } from '@fabric/button';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';

import { useActionHotKeys } from './hooks';
import { BaseModalContext } from '../context';
import { ModalFooterProps } from '../base-modal.types';

import { useStyles } from './footer.style';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const footerDevLogger = createLogger('@fabric/overlays/base-modal | <BaseModal.Footer />');

export const Footer: FunctionComponent<PropsWithChildren<ModalFooterProps>> = props => {
	const { actionHotKeys = [], actions = [], actionAlignment = 'end', minActionWidth = 100, children, className, ...rest } = props;
	const { classes, cx } = useStyles({ minActionWidth, actionAlignment });

	const { contentRef, isOpen, isLoading, isProcessing } = useContext(BaseModalContext);

	const validActionTypes: [typeof Button, typeof TextButton] = [Button, TextButton];
	const validActions = actions.filter(action => action && validActionTypes.includes(action.type));

	if (actions.length !== validActions.length) {
		const namesOfValidActionTypes = validActionTypes.map(({ name }) => name);

		footerDevLogger.error(
			`An unsupported component was supplied as an action to your modal's footer; it will be ignored. Valid components are: ${namesOfValidActionTypes.join(
				', '
			)}.`
		);
	}

	const canUseHotkeys = isOpen && !isLoading && !isProcessing;
	const actionCallbacks = actions.map(action => action.props.clickAction);

	useActionHotKeys(actionHotKeys, actionCallbacks, canUseHotkeys, contentRef);

	return (
		<div className={classes.baseModalFooter}>
			<div className={classes.baseModalFooterActions}>
				{validActions.map(Action => (
					<div className={cx(classes.baseModalFooterAction, className)} key={Action.key?.toString()} {...rest}>
						{cloneElement(Action, {
							isDisabled: Action.props.isDisabled || isProcessing,
							isProcessing,
							/**
							 * Force any "secondary" buttons to use "outline" styles.
							 */
							outline: Action.props.outline || Action.props.secondary,
							size: ifFeature('encore', undefined, 'large'),
							width: minActionWidth,
						})}
					</div>
				))}
			</div>
			{children}
		</div>
	);
};
