// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BlankTransferListStateProps } from '../../types';

import { useStyles } from './styles';

export function BlankState(props: BlankTransferListStateProps): ReactElement {
	const { classes: classNames, icon, text } = props;
	const { classes } = ifFeature('encore', useStyles(), useStyles(undefined, { props: { classes: classNames } }));
	return (
		<div className={classes.blankStateContainer} data-container="blank-state">
			{icon && <div className={classes.blankStateIcon}>{icon}</div>}
			<div className={classes.blankStateText}>{text}</div>
		</div>
	);
}
