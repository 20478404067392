import { ValidationField, ValidationRule } from './validation.types';
import { hasValue } from './validators/has-value';
import { matchesPattern } from './validators/matches-pattern';

export function getRulesFromField(field: ValidationField): Array<ValidationRule> {
	const { required, pattern } = field;
	let { rules = [] } = field;

	if (required && typeof required === 'boolean') {
		rules = [
			...rules,
			{
				test: hasValue,
				message: 'This field is required.',
			},
		];
	} else if (required) {
		rules = [
			...rules,
			{
				test: hasValue,
				message: required.message,
			},
		];
	}

	if (typeof pattern === 'string') {
		rules = [
			...rules,
			{
				test: matchesPattern(pattern),
				message: `This field's value does not match the expected format.`,
			},
		];
	} else if (pattern) {
		rules = [
			...rules,
			{
				test: matchesPattern(pattern.value),
				message: pattern.message,
			},
		];
	}

	return rules;
}
