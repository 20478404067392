import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';
import { getAddItemText, getCSSClasses } from './util';

import './add-option.scss';

export function AddOption(props) {
	const { biId = null, children, onHover, onSelect } = props;

	const dataAttributes = {};

	if (biId) {
		dataAttributes['data-bi-id'] = `${biId}-menu-add-option`;
	}

	return (
		/* Key events are being handled by the "Anchor" component and no other role makes sense for this element. */
		/* eslint-disable jsx-a11y/click-events-have-key-events */
		/* eslint-disable jsx-a11y/no-static-element-interactions */
		<div {...dataAttributes} className={getCSSClasses(props)} onClick={onSelect} onMouseEnter={onHover}>
			{children ||
				ifFeature(
					'encore',
					<>
						<IconV2 name="circle-plus-regular" size={16} />
						{getAddItemText()}
					</>,
					getAddItemText()
				)}
		</div>
		/* eslint-enable jsx-a11y/click-events-have-key-events */
		/* eslint-enable jsx-a11y/no-static-element-interactions */
	);
}
