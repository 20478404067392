import React, { ReactElement } from 'react';
import { InputSize, PickerButtonProps } from './picker-button.types';
import { usePickerButtonStyles } from './picker-button.styles';
import { ButtonUnstyled as Button } from '@mui/base';
import { IconV2, type IconV2Size } from '~components/icon-v2';
import type { Size } from '~components/types';

export function PickerButton(props: PickerButtonProps): ReactElement {
	const {
		ariaControls,
		ariaExpanded,
		ariaHaspopup,
		ariaLabel,
		biId,
		children,
		containerRef,
		dataMenuId,
		disabled = false,
		icon,
		id,
		focused = false,
		onBlur,
		onClick,
		onFocus,
		size = 'medium',
	} = props;
	const { classes } = usePickerButtonStyles(props);

	// This includes both the Size type for backward compatibility
	const getIconSize = (size: InputSize | Size): IconV2Size => {
		const sizes: Record<InputSize | Size, IconV2Size> = {
			teenie: 12,
			small: 12,
			medium: 16,
			large: 20,
			biggie: 20,
		};

		return sizes[size] ?? 16;
	};

	return (
		<div className={classes.root}>
			<div className={classes.divider}></div>
			<Button
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				aria-haspopup={ariaHaspopup}
				aria-label={ariaLabel}
				className={classes.button}
				data-bi-id={biId}
				data-menu-id={dataMenuId}
				disabled={disabled}
				focused={focused}
				id={id}
				onBlur={onBlur}
				onClick={onClick}
				onFocus={onFocus}
				ref={containerRef}
			>
				{children || (icon && <IconV2 name={icon} size={getIconSize(size)} />) || null}
			</Button>
		</div>
	);
}
