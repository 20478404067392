import { withStyles } from '@mui/styles';
import React, { Component } from 'react';

import Total from './total';
import Pages from './pages';
import { styles } from './pagination.styles';
import { urlBuilder } from './utils';

export class Pagination extends Component {
	constructor(props) {
		super(props);
		const { currentPage } = props;
		this.state = {
			currentPage: currentPage || 1,
		};
	}

	saveBrowserHistory(page) {
		const { hrefBuilder } = this.props;
		const url = urlBuilder(hrefBuilder, page);
		window.history.pushState({ page }, '', url);
	}

	buildMarkup() {
		const { classes = {}, pageSize, totalItemsCount, hrefBuilder, trackingCategory } = this.props;
		const { currentPage } = this.state;
		const pageCount = Math.ceil(totalItemsCount / pageSize);

		return (
			<div className={classes.root}>
				<Total {...{ pageSize, totalItemsCount, currentPage, pageCount }} />
				<Pages {...{ pageCount, currentPage, hrefBuilder, trackingCategory }} handlePageClick={this.handlePageClick} />
			</div>
		);
	}

	setPage(page) {
		this.setState({ currentPage: page });
	}

	handlePageClick = (e, value) => {
		const { deepLink, onPageChange, endpoint, fetchMore, pageSize, fullRefresh } = this.props;
		const { currentPage } = this.state;

		if (fullRefresh) {
			return;
		}

		e.preventDefault();

		if (value === currentPage) {
			return;
		}

		const nextPage = value;

		if (deepLink) {
			this.saveBrowserHistory(nextPage);
		}
		if (endpoint) {
			fetchMore({
				startsAt: (nextPage - 1) * pageSize,
				limit: pageSize,
			});
		}
		if (onPageChange) {
			onPageChange({ currentPage, nextPage });
		}

		this.setState({
			currentPage: nextPage,
		});
	};

	componentDidUpdate(prevProps) {
		const { currentPage } = this.props;
		if (currentPage !== prevProps.currentPage) {
			this.setPage(currentPage);
		}
	}

	render() {
		const { children } = this.props;
		return (
			<>
				{children}
				{this.buildMarkup()}
			</>
		);
	}
}

const DefaultPagination = withStyles(styles)(Pagination);

export default DefaultPagination;
