import { ifFeature } from '@bamboohr/utils/lib/feature';
import { colors as encoreColors } from '../json/encore-colors.json';
import { theme as encoreTheme } from './encore-theme';
import { themeColors } from '../json/theme-colors.json';
// @startCleanup encore
import { colors } from '../json/colors.json';
import { theme } from './theme';
// @endCleanup encore

export type ThemeNames =
	| 'themeBaseDefault'
	| 'themeLightDefault'
	| 'themeLighterDefault'
	| 'themeLightestDefault'
	| 'themeLightest10Default'
	| 'themeLightest35Default'
	| 'themeLightest50Default'
	| 'themeBase'
	| 'themeLight'
	| 'themeLighter'
	| 'themeLightest'
	| 'themeLightest10'
	| 'themeLightest35'
	| 'themeLightest50';

export type ThemeVariants = Exclude<keyof (typeof themeColors)[0], 'name'>;

export type ColorNames = keyof typeof colors;

export type ThemeColorNames = ThemeNames | ColorNames;

export function fabricColor(name: string): string {
	const allColors = ifFeature('encore', { ...encoreColors, ...encoreTheme }, { ...colors, ...theme });
	const color: string = allColors[name];

	if (color) {
		return color;
	}

	console.error(`The requested color definition (${name.toString()}) does not exist.`);
	return '';
}
