import { LayoutKey, Layout } from '../types';
import { chunk } from 'lodash';
import { fillHolesInLayout } from './fill-holes-in-layout';

export function toLayout(array: LayoutKey[], columns: number): Layout {
	if (array.length === 0) {
		return [];
	}

	array = [...array];

	const chunked = chunk(array, columns);

	if (chunked[0].length < columns) {
		chunked[0].length = columns;
	}

	return fillHolesInLayout(chunked);
}
