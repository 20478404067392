import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ constructs, palette, spacing, mode }) => {
	return {
		actions: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'flex-end',
			rowGap: spacing(2),
			columnGap: ifFeature('encore', spacing(2)),
		},
		image: {
			fill: palette.common.white,
			flex: '0 0 auto',
		},
		root: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxStrong, palette.gray[1000]),
			boxSizing: 'border-box',
			display: 'flex',
			gap: spacing(2.5),
			minHeight: '118px',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: spacing(2, 4),
			width: '100%',
		},
	};
});
