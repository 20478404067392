import { ifFeature } from '@bamboohr/utils/lib/feature';
import { keyframes, styled, Theme } from '@mui/material';
import { dyeColors, excludeProps, ModeOptions } from '~styles';

const bouncedelay = keyframes`
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
`;

export const StyledRoot = styled('div', { shouldForwardProp: excludeProps(['small']) })<{ small?: boolean }>(({ small }) => {
	let smallStyle = {};
	if (small) {
		smallStyle = {
			width: 80,

			'& > div': {
				height: 16,
				width: 16,
				marginLeft: 10,
			},
		};
	}

	return {
		margin: '0 auto',
		textAlign: 'center',
		width: 145,
		...smallStyle,
	};
});

export const StyledBase = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { palette, mode } = theme as Theme;
	return {
		animation: `${bouncedelay} 1.8s infinite ease-in-out`,

		/* GOTCHA: Prevent first frame from flickering when animation starts */
		animationFillMode: 'both',
		/* TODO: remove hardcoded background color when CSS variables exist in main app */
		backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', palette.grey[400], palette.gray[500]),
		borderRadius: ifFeature('encore', '15%', '100%'),
		display: 'inline-block',
		height: ifFeature('encore', 32, 35),
		width: ifFeature('encore', 32, 35),
		marginLeft: 20,

		'&:first-of-type': {
			marginLeft: 0,
		},
	};
});

export const StyledBounce1 = styled(StyledBase)({
	animationDelay: '-0.6s',
});

export const StyledBounce2 = styled(StyledBase)({
	animationDelay: '-0.3s',
});
