// eslint-disable-next-line no-use-before-define
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/styles';
import { AnimatedBarValuesProps } from '../types/animated-bar-values-props';
import { useBarValuesVariants } from './use-bar-values-variants';

export const AnimatedBarValues = ({
	animate,
	displayText,
	dx,
	dy,
	orientation,
	title,
	x,
	y,
	yMax = 0,
	xMax = 0,
}: AnimatedBarValuesProps) => {
	const { palette, typography } = useTheme();

	const initial = animate ? 'initial' : 'idle';

	const isVertical = orientation === 'vertical';

	const variants = useBarValuesVariants(isVertical, x + dx, y + dy, yMax);

	return (
		<motion.text
			alignmentBaseline="middle"
			animate="idle"
			dx={x + dx}
			dy={y + dy}
			fill={palette.gray[800]}
			fontFamily={typography.fontFamily}
			fontSize={typography.small?.fontSize}
			height={dy}
			initial={initial}
			textAnchor={isVertical ? 'middle' : 'start'}
			variants={variants}
			{...{ title }}
		>
			{displayText}
		</motion.text>
	);
};
