import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { DatePickerCalendarRangeStyleProps } from '../../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<DatePickerCalendarRangeStyleProps>()((theme, props) => {
	const { borderRadiuses, constructs, mixins, mode, palette, spacing } = theme;
	const { maxWeekRowsCount } = props;

	return {
		calendars: {
			display: 'flex',
			justifyContent: ifFeature('encore', 'space-evenly'),
			width: '100%',
		},
		endPicker: {
			// @startCleanup encore
			borderLeft: ifFeature('encore', undefined, mixins.border(1, palette.grey[400])),
			// @endCleanup encore
			'& .PrivatePickersFadeTransitionGroup-root': {
				'&>div>div>div:first-of-type': {
					borderLeft: ifFeature('encore', mixins.border(1, constructs.border.neutral.xWeak)),
				},
			},
			'& .PrivatePickersSlideTransition-root': {
				'&>div:first-of-type': {
					borderLeft: ifFeature('encore', mixins.border(1, constructs.border.neutral.xWeak)),
				},
			},
		},
		hidden: {
			visibility: 'hidden',
		},
		picker: {
			backgroundColor: palette.grey[100],
			borderRadius: ifFeature('encore', borderRadiuses[200]),
			margin: ifFeature('encore', spacing(0.5)),
			overflow: 'hidden',
			position: 'relative',
			'& .MuiPickersBasePicker-pickerView': {
				minHeight: 0,
				padding: 0,
			},
			'& .MuiPickersCalendar-transitionContainer': {
				backgroundColor: palette.common.white,
				marginTop: 10,
				minHeight: 12 + maxWeekRowsCount * 32,
				transition: 'min-height 100ms',
			},
			'& .MuiPickersCalendar-transitionContainer > div': {
				top: 8,
			},
			'& .MuiPickersCalendarHeader-transitionContainer': {
				display: 'none',
			},
			width: ifFeature('encore', 633, 616),
		},
		root: {
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : undefined,
			display: 'flex',
			width: ifFeature('encore', 633, 616),
		},
		startPicker: {},
	};
});
