import { useEffect } from 'react';

/**
 * Scrolls the active index into view.
 * @param scrollToIndex A function that will update the scroll position of the scroll container
 * @param activeIndex The currently active index to scroll into view
 */
export const useVirtualScrollTo = (scrollToIndex: (index: number) => void, activeIndex?: number): void => {
	useEffect(() => {
		if (typeof activeIndex !== 'undefined') {
			scrollToIndex(activeIndex);
		}
	}, [activeIndex, scrollToIndex]);
};
