import { dyeColors, ModeOptions, makeStyles } from '~styles';
import { AvatarListOptionStyleProps } from './types';

export const useStyles = makeStyles<Partial<AvatarListOptionStyleProps>>()((theme, props) => {
	const { palette, spacing, typography, mode } = theme;
	const { disabled } = props;

	return {
		root: {
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: 0,
		},
		text: {
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(0.25),
			margin: 0,
		},
		textPrimary: {
			fontSize: typography.medium?.fontSize,
			lineHeight: typography.medium?.lineHeight,
			color: disabled ? palette.gray[600] : palette.gray[1000],
		},
		textSecondary: {
			fontSize: typography.xsmall?.fontSize,
			lineHeight: typography.xsmall?.lineHeight,
			color: palette.gray[600],
		},
	};
});

export const useJadeStyles = makeStyles<Partial<AvatarListOptionStyleProps>>()((theme, props) => {
	const { palette, spacing, mode } = theme;
	const { highlighted, isStringAvatar } = props;

	let avatarBGColor = 'transparent';
	let avatarFillColor = palette.primary.main;

	if (isStringAvatar) {
		avatarBGColor = highlighted ? palette.primary.contrastText : palette.primary.main;
	}

	if (mode === ModeOptions.Dye) {
		avatarFillColor = dyeColors.main;
	} else if (highlighted) {
		avatarFillColor = palette.primary.contrastText;
	}

	return {
		root: {
			backgroundColor: highlighted ? palette.primary.main : 'transparent',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: spacing(1, 1, 1, 2),
			'& .MuiListItemAvatar-root': {
				minWidth: 0,
			},
			'& .MuiListItemText-root': {
				margin: 0,
			},
		},
		avatar: {
			backgroundColor: avatarBGColor,
			color: highlighted ? palette.primary.main : palette.primary.contrastText,
			fill: avatarFillColor,
			height: 32,
			marginRight: 10,
			width: 32,
		},
		textPrimary: {
			color: highlighted ? palette.primary.contrastText : palette.grey[1000],
			fontSize: 15,
			lineHeight: '22px',
		},
		textSecondary: {
			color: highlighted ? palette.primary.contrastText : palette.grey[700],
			fontSize: 13,
			lineHeight: '22px',
		},
	};
});
