import React, { useRef } from 'react';
import { MagGlass16x16, CircleX14x14 } from '@bamboohr/grim';
import { TextField } from '~components/text-field';
import { IconV2 } from '~components/icon-v2';
import { IconButton } from '@fabric/button';
import { InputAdornment } from '@mui/material';
import { SearchFilterProps } from './types';
import { useSearchFilterStyles } from './search-filter.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const SearchFilter = (props: SearchFilterProps) => {
	const { onClear, value, placeholder = 'Search', width, onChange, onBlur, onFocus, onKeyDown, biId } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const { classes } = useSearchFilterStyles({ value });

	return (
		<TextField
			biId={biId}
			InputProps={{
				ariaLabel: window.jQuery ? $.__('Search') : 'Search',
				endAdornment: (
					<InputAdornment className={classes.clearAdornment} position="end">
						<IconButton
							ariaLabel={window.jQuery ? $.__('Search filter clear button') : 'Search filter clear button'}
							biId={`${biId}-search-filter-clear`}
							floatingIcon={true}
							hasBounding={false}
							icon={ifFeature('encore', 'circle-xmark-solid', <CircleX14x14 />)}
							onClick={() => {
								onClear();
								inputRef.current?.focus();
							}}
							type="button"
							variant="outlined"
						/>
					</InputAdornment>
				),
				startAdornment: (
					<InputAdornment position="start">
						{ifFeature(
							'encore',
							<IconV2 name="magnifying-glass-regular" size={16} />,
							<MagGlass16x16 aria-hidden={true} />
						)}
					</InputAdornment>
				),
			}}
			onBlur={onBlur}
			onChange={input => onChange(input.target.value)}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			placeholder={placeholder}
			ref={inputRef}
			value={value}
			width={width}
		/>
	);
};
