import { widths } from '~definitions/json/sizes.json';
import { makeStyles } from '~styles';
import { TextFieldProps } from './types';

export const useStyles = makeStyles<Partial<TextFieldProps>>()((theme, params) => {
	const { labelPlacement, size, width } = params;

	const labelSize = {
		teenie: '32px',
		small: '32px',
		medium: '40px',
		large: '48px',
		biggie: '48px',
	};
	const labelHeight = size ? labelSize[size] : undefined;

	return {
		label: {
			height: labelPlacement === 'inline' ? labelHeight : undefined,
		},
		width: {
			width: !width || width === 100 ? '100%' : widths[width],
		},
	};
});
