import { DatePickerDate, DatePickerDatePredicate, DatePickerDateType, DatePickerUtils } from '../../../types';
import { getClosestEnabledDates, getIsDateDisabled, getUpdatedConstraintsForRange } from '../../../utils';

/**
 * This utility will return the closest date to the provided date that is enabled and exists in one of the visible months.
 * If the date is already enabled and visible in one of the calendars then it will be the return value. If no dates are enabled
 * in either of the visible months then `undefined` will be returned.
 * @param utils The date utils used all over the Date Picker components
 * @param month The month that is visible
 * @param date The date that is currently marked for focus
 * @param constraints [{}] The constraints for what dates are enabled.
 * @returns The date that should now be focused
 */
export function getClosestEnabledAndVisibleDate(
	utils: DatePickerUtils,
	month: DatePickerDate,
	constraints: { getDateDisabled?: DatePickerDatePredicate; maxDate?: DatePickerDate; minDate?: DatePickerDate } = {},
	date: DatePickerDate = month
) {
	const updatedConstraints = getUpdatedConstraintsForRange(
		utils,
		month,
		utils.startOfDay(utils.endOfMonth(month as DatePickerDateType)),
		constraints
	);

	if (!getIsDateDisabled(utils, date, updatedConstraints.minDate, updatedConstraints.maxDate, updatedConstraints.getDateDisabled)) {
		return date;
	}

	const { next, previous } = getClosestEnabledDates(utils, date, updatedConstraints);

	return next || previous;
}
