import React, { useEffect, ReactElement, ReactNode, FC, PropsWithChildren } from 'react';
import { InterstitialProps } from '@fabric/interstitial';
import { Card, CardContent } from '@fabric/card';
import { createPortal } from 'react-dom';

import { Footer } from './components/footer.react';
import { capitalize } from './util';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './interstitial.styles';

const rootElement = document.createElement('div');

export const Interstitial: FC<PropsWithChildren<InterstitialProps>> = ({
	cardSize,
	centerByCard,
	children,
	overlay = 'everything',
	renderOuterContent,
	renderCard,
	renderFooterEtc,
}) => {
	const { classes } = useStyles({ cardSize });

	useEffect(() => {
		if (overlay === 'everything') {
			document.body.appendChild(rootElement);
		}
	}, [overlay]);

	renderCard =
		renderCard ||
		ifFeature(
			'encore',
			() => <div className={classes.card}>{children}</div>,
			() => (
				<Card size={cardSize}>
					<CardContent>{children}</CardContent>
				</Card>
			)
		);

	const template = (
		<div className={`fab-Interstitial fab-Interstitial--overlay${capitalize(overlay)}`}>
			<div className="fab-Interstitial__outer">
				<div className="fab-Interstitial__inner">
					<div className="fab-Interstitial__card">{renderCard()}</div>

					<div className={centerByCard ? 'fab-Interstitial__outerContentWrapper--absolute' : ''}>
						{renderOuterContent && renderOuterContent()}

						<div className="fab-Interstitial__footer">
							<Footer renderFooterEtc={renderFooterEtc} />
						</div>
					</div>
				</div>
			</div>
			<div className="fab-Interstitial__overlay"></div>
		</div>
	);
	const renderPortal = createPortal as unknown as (children: ReactNode, container: HTMLDivElement) => ReactElement;
	return overlay === 'everything' ? renderPortal(template, rootElement) : template;
};

Interstitial.displayName = 'Interstitial';

export default Interstitial;
