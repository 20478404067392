import { SelectChangeArgs } from '~components/select';
import { FormFactorySelectFieldModel } from '../types/builtin-models.types';

export function selectFieldAdapter(model: FormFactorySelectFieldModel) {
	const { id, items, label, note, onChange, required, status, value, width = 5 } = model;

	return {
		id,
		label,
		onChange: (event: SelectChangeArgs) => {
			// The type here is wrong. The value isn't an array of values if it is a single-select.
			const value = event.target?.value as unknown as string;

			if (typeof onChange === 'function' || typeof value === 'string' || typeof value === 'undefined') {
				onChange({ id, value });
			}
		},
		items,
		note,
		required,
		status,
		value,
		width,
	};
}
