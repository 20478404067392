import classnames from 'classnames';
import { ItemUtil, Menu } from '@fabric/menu';
import { noop } from 'lodash';
import React, { Component, createRef } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { Native } from './components/native.react';
import { Toggle } from './components/toggle.react';
import { getToggleContent } from './components/toggle.react/util';
import { DEFAULT_PLACEHOLDER_TEXT, DEFAULT_SIZE } from './constants';

import { ItemType } from '@fabric/menu/types';
import { SelectProps, SelectState } from './types';

import './select.scss';

export class Select extends Component<SelectProps, SelectState> {
	get _condition() {
		const { condition } = this.props;

		const { isInvalid } = this.state;

		return isInvalid ? 'error' : condition;
	}

	static defaultProps = {
		canSelectMultiple: false,
		isClearable: true,
		isDark: false,
		isDisabled: false,
		name: '',
		onChange: noop,
		onClose: noop,
		onDeselect: noop,
		onOpen: noop,
		onSelect: noop,
		placeholder: DEFAULT_PLACEHOLDER_TEXT,
		placement: {
			align: 'start',
			side: 'bottom',
		},
		required: false,
		selectedValues: [],
		showSearch: 'auto',
		size: DEFAULT_SIZE,
		variant: 'form',
	};

	static getDerivedStateFromProps(props) {
		const { items, selectedValues } = props;

		if (!Array.isArray(selectedValues)) {
			throw new Error(`@fabric/select - "selectedValues" prop must be an instance of Array. Got ${typeof selectedValues}`);
		}

		return {
			selectedItems: ItemUtil.getSelected(items, ItemUtil.getFromValues(items, selectedValues)),
		};
	}

	_close() {
		const { open, onClose = noop } = this.props;

		const onClosePromise = onClose();

		if (typeof open === 'boolean') {
			return;
		}

		return Promise.resolve(onClosePromise).then(() => {
			this.setState({ isOpen: false });
		});
	}

	_change(selectedValues) {
		const { items, onChange = noop } = this.props;

		const onChangePromise = onChange(selectedValues, ItemUtil.getFromValues(items, selectedValues));

		return Promise.resolve(onChangePromise);
	}

	_deselect(item: ItemType, parentItem) {
		const { onDeselect = noop, selectedValues = [] } = this.props;

		const _selectedValues = selectedValues.filter(selectedValue => selectedValue !== item.value);

		const onDeselectPromise = onDeselect(item.value, _selectedValues, { item, parentItem });

		return Promise.resolve(onDeselectPromise).then(() => {
			return this._change(_selectedValues);
		});
	}

	_open() {
		const { open, onOpen = noop } = this.props;

		const onOpenPromise = onOpen();

		if (typeof open === 'boolean') {
			return;
		}

		return Promise.resolve(onOpenPromise).then(() => {
			this.setState({ isOpen: true });
		});
	}

	_select(item: ItemType, parentItem: ItemType, metadata = { isQuickSelect: false }) {
		const { canSelectMultiple, onSelect = noop, selectedValues = [] } = this.props;
		const { selectedItems } = this.state;

		const { isQuickSelect } = metadata;

		let _selectedValues = selectedValues;
		const _selectedItems = selectedItems as ItemType[];

		if (!ItemUtil.isActionOnly(item) && !item.exclusive && !isQuickSelect && canSelectMultiple) {
			if (ItemUtil.hasExclusiveItemSelected(_selectedItems)) {
				_selectedValues = [item.value];
			} else {
				_selectedValues = [...selectedValues, item.value];
			}
		} else if (!item.isActionOnly) {
			_selectedValues = [item.value];
		}

		const onSelectPromise = onSelect(item.value, _selectedValues, { item, parentItem });

		return Promise.resolve(onSelectPromise).then(() => {
			return this._change(_selectedValues);
		});
	}

	_handleClear = () => {
		const { isDisabled, onClear = noop } = this.props;

		if (isDisabled) {
			return;
		}

		const clearPromise = onClear();

		return Promise.resolve(clearPromise).then(() => {
			return this._change([]);
		});
	};

	_handleDeselect = (item, parentItem) => {
		return this._deselect(item, parentItem);
	};

	_handleSelect = (item, parentItem, metadata) => {
		return this._select(item, parentItem, metadata);
	};

	_handleValidation = isValid => {
		this.setState({ isInvalid: !isValid });
	};

	_renderToggle = ({ appliedPlacement, isFocused }, buttonProps) => {
		const {
			biId,
			canSelectMultiple,
			isClearable,
			isDark,
			isDisabled,
			open: openProp,
			placeholder,
			renderToggleContent,
			size,
			type,
			variant,
			width,
		} = this.props;

		const { isOpen: isOpenState, selectedItems } = this.state;

		const isOpen = typeof openProp === 'boolean' ? openProp : isOpenState;

		return (
			<Toggle
				appliedPlacement={appliedPlacement}
				biId={biId}
				buttonProps={buttonProps}
				canSelectMultiple={canSelectMultiple}
				condition={this._condition}
				isClearable={isClearable}
				isDark={isDark}
				isDisabled={isDisabled}
				isFocused={isFocused}
				isOpen={isOpen}
				onClear={this._handleClear}
				placeholder={placeholder?.length ? placeholder : DEFAULT_PLACEHOLDER_TEXT}
				renderToggleContent={renderToggleContent}
				selectedItems={selectedItems}
				size={size}
				type={type}
				variant={variant}
				width={width}
			/>
		);
	};

	_menuRef: React.RefObject<Menu> = createRef();

	state = {
		isOpen: false,
		isInvalid: false,
		selectedItems: [],
	};

	render() {
		const {
			'aria-label': hyphenatedAriaLabel,
			ariaLabel: unhyphenatedAriaLabel,
			biId,
			canSelectMultiple,
			id,
			inputRef,
			isDisabled,
			items,
			menuWidth,
			name,
			onAdd,
			onSearch,
			open: openProp,
			placeholder,
			placement,
			renderOptionContent,
			renderToggleContent,
			required,
			searchPlaceholder,
			searchText,
			searchThreshold,
			showSearch,
			size,
			type,
			width,
		} = this.props;

		const { isOpen: isOpenState, selectedItems } = this.state;

		const isOpen = typeof openProp === 'boolean' ? openProp : isOpenState;

		const cssClasses = classnames({
			'fab-Select': true,
			'fab-Select--fullWidth': ifFeature('encore', width == 100 && type !== 'text', width == 100),
		});

		// The aria-label for the toggle button is read by screen readers, rather than the content of the button,
		// so we need to append the button text to the aria-label so that they're both read
		const ariaLabel = unhyphenatedAriaLabel || hyphenatedAriaLabel;
		const toggleContent = (
			selectedItems.length ? getToggleContent(selectedItems, renderToggleContent, canSelectMultiple) : placeholder
		) as string;

		return (
			<div className={cssClasses}>
				<Menu
					ariaLabel={`${ariaLabel || ''} ${toggleContent || ''}`}
					biId={biId}
					canQuickSelect={true}
					canSelectMultiple={canSelectMultiple}
					condition={this._condition}
					isAttached={ifFeature('encore', false, type !== 'text')}
					isDisabled={isDisabled}
					isFullWidth={width == 100}
					isOpen={isOpen}
					items={items}
					listRole={canSelectMultiple ? 'listbox' : 'menu'}
					onAdd={onAdd}
					onClose={() => this._close()}
					onDeselect={this._handleDeselect}
					onOpen={() => this._open()}
					onSearch={onSearch}
					onSelect={this._handleSelect}
					placement={placement}
					ref={this._menuRef}
					renderOptionContent={renderOptionContent}
					renderToggle={this._renderToggle}
					searchPlaceholder={searchPlaceholder}
					searchText={searchText}
					searchThreshold={searchThreshold}
					selectedItems={selectedItems}
					showSearch={showSearch}
					size={size}
					width={menuWidth}
				/>

				<Native
					canSelectMultiple={canSelectMultiple}
					id={id}
					inputRef={inputRef}
					isDisabled={isDisabled}
					name={name}
					onFocus={() => {
						const menu = this._menuRef.current;
						if (menu) {
							menu.focus();
						}
					}}
					onValidation={this._handleValidation}
					required={required}
					selectedValues={ItemUtil.getValues(selectedItems)}
				/>
			</div>
		);
	}
}
