/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Typography } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { ControlledInput } from './types';
import { handleChange, getValue } from './values';

export const controlledInput =
	<TFieldValues extends FieldValues>(formControl: Control<TFieldValues>): ControlledInput<TFieldValues> =>
	(renderComponent, { isControlled, rules = {}, defaultValue = '', name, ...rest }) => {
		if (!isControlled)
			return (
				<Typography color="error" variant="caption">
					{name} was not registered as a controlledField
				</Typography>
			);

		const { setValueAs, ...resolvedRules } = rules;

		return (
			<Controller<TFieldValues>
				control={formControl}
				defaultValue={defaultValue}
				key={name}
				name={name}
				render={({ field }) => {
					const valueProps = getValue(field.value);
					const onChange = handleChange(field.onChange, field.value, setValueAs);

					const renderProps = { ...rest, ...field, ...valueProps, name, onChange };
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					return renderComponent(renderProps);
				}}
				rules={resolvedRules}
			/>
		);
	};
