import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ borderRadiuses, constructs, mixins, mode, palette, spacing, typography }) => {
	return {
		slidedown: {
			alignItems: ifFeature('encore', 'flex-start', 'center'),
			borderRadius: ifFeature('encore', borderRadiuses[300], '0 0 3px 3px'),
			boxShadow: ifFeature(
				'encore',
				`3px 3px 10px 2px ${mixins.alpha(constructs.surface.neutral.xxStrong, 0.1)}`,
				'0 2px 5px 0 rgba(0, 0, 0, 0.25)'
			),
			boxSizing: 'border-box',
			color: constructs.text.neutral.forcedWhite,
			display: 'flex',
			minHeight: spacing(6),
			margin: '0 auto',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', spacing(1.5, 2), spacing(0, 2)),
			pointerEvents: 'all',
			textAlign: 'left',
			maxWidth: '832px',
		},
		action: {
			marginLeft: spacing(6),
			paddingRight: spacing(1.5),
			position: 'relative',
		},
		actionLink: {
			color: palette.common.white,
			fontSize: typography.small.fontSize,
			lineHeight: typography.teenie.lineHeight,
			fontWeight: typography.fontWeightHeavy,
			'&:hover': {
				color: palette.common.white,
			},
		},
		// @startCleanup encore
		divider: {
			position: 'relative',
			margin: '0 8px 0 0',
			'&:after': {
				display: 'block',
				backgroundColor: `rgba(255, 255, 255, 0.6)`,
				position: 'absolute',
				content: '""',
				height: spacing(2.5),
				pointerEvents: 'none',
				width: '1px',
				top: '50%',
				marginTop: '-10px',
			},
		},
		// @endCleanup encore
		icon: {
			alignItems: 'center',
			display: 'flex',
			fill: 'currentColor',
			marginTop: ifFeature('encore', spacing(1)),
			marginRight: spacing(ifFeature('encore', 1.5, 1)),
		},
		text: {
			flex: '1',
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: ifFeature('encore', typography.large.lineHeight, typography.medium.lineHeight),
			marginTop: ifFeature('encore', spacing(0.5)),
			// @startCleanup encore
			paddingTop: ifFeature('encore', undefined, '13px'),
			paddingBottom: ifFeature('encore', undefined, '13px'),
			// @endCleanup encore
		},
		description: {
			fontSize: typography.small.fontSize,
			fontWeight: typography.fontWeightRegular,
			lineHeight: typography.small.lineHeight,
		},
		discovery: {
			backgroundColor: ifFeature('encore', constructs.surface.discovery.strong, palette.discovery.main),
		},
		error: {
			backgroundColor: ifFeature('encore', constructs.surface.error.strong, palette.error.main),
		},
		info: {
			backgroundColor: ifFeature('encore', constructs.surface.info.strong, palette.info.main),
		},
		success: {
			backgroundColor: ifFeature('encore', constructs.surface.success.strong, palette.success.main),
		},
		warning: {
			backgroundColor: ifFeature('encore', constructs.surface.warning.strong, palette.warning.main),
		},
	};
});
