import { ListItem } from '../types';

export function parseSelectedItems(listItems: Array<ListItem>, lastSelectedIndex: number, selectedRowIndex: number): Array<number> {
	if (!Array.isArray(listItems)) {
		return [];
	}
	let pushing = false;
	const changed: Array<number> = [];
	for (let index = 0; index < listItems.length; index++) {
		const { disabled = false } = listItems[index];

		if (!disabled && pushing) {
			changed.push(index);
		}

		if (!disabled && (listItems[index] === listItems[selectedRowIndex] || listItems[index] === listItems[lastSelectedIndex])) {
			if (pushing) {
				break;
			}
			changed.push(index);
			pushing = true;
		}
	}

	return changed;
}
