import { createTheme, Theme, ThemeOptions, Palette } from '@mui/material/styles';
import { Typography } from '@mui/material/styles/createTypography';
import { createBreakpoints, createSpacing } from '@mui/system';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { EnumAndStringUnion } from '~utils';
import jsonEncoreThemeColors from '../definitions/json/encore-theme-colors.json';
import jsonThemeColors from '../definitions/json/theme-colors.json';
import { borderRadiuses } from '../definitions/json/encore-border-radiuses.json';
/* @startCleanup encore */
import { spacing as jadeSpacing } from '../definitions/json/sizes.json';
/* @endCleanup encore */
import { spacing as themeSpacing } from '../definitions/json/encore-sizes.json';
import { getBreakpoints } from './breakpoints/fabricBreakpoints';
import { getComponents, getEncoreComponents } from './components';
import { ModeOptions, ThemeNameOptions } from './constants';
import { getConstructs } from './constructs/get-constructs';
import { getGradients } from './get-gradients';
import { getLayout } from './layout/fabricLayout';
import { getMixins } from './mixins';
import { getEncorePalette, getMuiPalette } from './palette';
import { getTypography, getEncoreTypography } from './typography';

export const getMuiTheme = (
	brandColor = 'lime1',
	mode?: EnumAndStringUnion<ModeOptions>,
	themeName?: EnumAndStringUnion<ThemeNameOptions>
): Theme => {
	const breakpoints = getBreakpoints();
	const gradients = getGradients();
	const layout = getLayout();
	const mixins = getMixins();

	// createTheme will run createPalette and PaletteOptions will become Palette.
	// For overrides, pretend that the object is a full Palette. I think this is an oversight in the MUI TS definitions.
	const modePalette: Partial<Palette> = {};
	const themeNamePalette = ifFeature('encore', getEncorePalette, getMuiPalette)(brandColor) as Palette;
	// Both a mode and a themeName can be active at the same time,
	// and mode-specific styles override themeName styles
	const palette = { ...themeNamePalette, ...modePalette };

	const { themeColors } = ifFeature('encore', jsonEncoreThemeColors, jsonThemeColors);
	const selectedColors = themeColors.find(theme => theme.name === brandColor) || themeColors[0];

	const spacing = createSpacing(ifFeature('encore', themeSpacing.root, jadeSpacing.root));

	// forcing dark mode in jade to visually imply constructs are for Encore
	const constructs = getConstructs({
		borderRadiuses,
		// @ts-expect-error selectedColors for jade does not have a dark variant so this throws a type error.
		brandColors: selectedColors,
		paletteMode: ifFeature('encore', 'light', 'dark'),
		spacing,
	});

	const typography = ifFeature('encore', getEncoreTypography, getTypography)(palette) as Typography;

	const components = ifFeature('encore', getEncoreComponents, () =>
		getComponents({
			breakpoints: createBreakpoints(breakpoints),
			palette,
			spacing,
			typography,
		})
	)();

	const options = {
		breakpoints,
		components,
		layout,
		mixins,
		palette,
		typography,
	} as ThemeOptions;

	return createTheme(options, { borderRadiuses, constructs, gradients, mode, themeName });
};
