import { ReactElement, ReactNode } from 'react';
import { IconV2Name } from '~components/icon-v2';

export enum InlineMessageStatusType {
	'error' = 'error',
	'success' = 'success',
	'warning' = 'warning',
	'info' = 'info',
	'neutral' = 'neutral',
	'discovery' = 'discovery',
}

export enum InlineMessageVariant {
	'default' = 'default',
	'light' = 'light',
}

export interface InlineMessageProps {
	/**
	 * Content shown in an element to the right of the title and description
	 */
	action?: ReactNode;
	/**
	 * Optional label to give more context for the InlineMessage and its contents
	 */
	ariaLabel?: string;
	/**
	 * Applied to the wrapper as a `data-bi-id` attribute
	 */
	biId?: string;
	/**
	 * Detail about the message.
	 */
	description?: ReactNode;
	/**
	 * Determines whether to hide icon, show default or show icon passed by dev
	 * If icon prop is false, the icon is hidden.
	 * If undefined, the default icon for the indicated status is displayed.
	 * Can also be overridden with an Icon component which would then be displayed
	 */
	icon?: ReactElement | IconV2Name | boolean;
	/**
	 * Status of message, each status has its own default icon and fills
	 *
	 * @default 'neutral'
	 */
	status?: InlineMessageStatusType;
	/**
	 * The summary text of the message.
	 */
	title?: ReactNode;
	/**
	 * The general style to use.
	 *
	 * @default 'default'
	 */
	variant?: InlineMessageVariant;
}
