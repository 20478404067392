// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { useStyles } from './body.styles';
import { GridletBodyProps } from '../types';

export const Body = ({ children, className, ...rest }: GridletBodyProps) => {
	const { classes } = useStyles();
	return (
		<div className={classnames(classes.root, className)} {...rest}>
			{children}
		</div>
	);
};
