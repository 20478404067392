// yes, this file sounds like a contradiction, but we need to override global styles to make this component truly unstyled

import { makeStyles } from '~styles';

export const useStyles = makeStyles()(() => ({
	root: {
		color: 'unset',
		cursor: 'unset',
		fontWeight: 'unset',
		position: 'unset',
		textDecoration: 'unset',
		'&:any-link': {
			cursor: 'pointer', // simulate browser default
		},
		'&:hover': {
			color: 'unset',
			textDecoration: 'none', // override browser default for easier styling
		},
		'&:focus-visible:after': {
			display: 'none',
		},
	},
}));
