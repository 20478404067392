import { Layout } from '../types';

/**
 * An example of a return string is '"a b c""d e f"'
 * which will be used and evaluated by the browser as:
 * gridTemplateArea:
 * 		"a b c"
 * 		"d e f";
 */
export function layoutToGridTemplateAreas(layout: Layout): string {
	const mapped = layout.map((row, rowIndex) => {
		return row
			.map((area, areaIndex) => {
				if (area === undefined) {
					return `lattice-internal-null-${rowIndex}-${areaIndex}`;
				}
				return area;
			})
			.join(' ');
	});
	return `"${mapped.join('""')}"`;
}
