import React, { ReactElement } from 'react';
import { Divider } from './divider';
import { Link } from './link';
import { Title } from './title';
import { GroupTitle } from './group-title';
import { SideSubNavigationProps } from './side-sub-navigation.types';
import { useStyles } from './side-sub-navigation.styles';
export function SideSubNavigation({ items }: SideSubNavigationProps): ReactElement {
	const { classes } = useStyles();
	return <div className={classes.root}>{items}</div>;
}

SideSubNavigation.Divider = Divider;
SideSubNavigation.Link = Link;
SideSubNavigation.Title = Title;
SideSubNavigation.GroupTitle = GroupTitle;
