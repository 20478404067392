import React, { ComponentProps, FunctionComponent, PropsWithChildren } from 'react';

import { Constraint } from '../constraint.react';
import { useStyles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const LowerContent: FunctionComponent<PropsWithChildren<ComponentProps<'div'>>> = props => {
	const { children, ...rest } = props;
	const { classes } = useStyles();

	const jadeContent = (
		<Constraint
			spacingOverrides={{
				bottom: Constraint.Spacing.SMALL,
				top: Constraint.Spacing.SMALL,
			}}
		>
			{children}
		</Constraint>
	);

	const encoreContent = children;

	return (
		<div className={classes.root} {...rest}>
			{ifFeature('encore', encoreContent, jadeContent)}
		</div>
	);
};
