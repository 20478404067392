import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/system';
import { TabPanelProps } from './types';
import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles<Pick<TabPanelProps, 'mode'>>()((theme, props) => {
	const { palette } = theme;
	const { mode } = props;

	return {
		root:
			mode === 'fill'
				? {
						backgroundColor: palette.gray[100],
						borderRadius: 8,
					}
				: {},
	};
});

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ mode, value, selectedValue, tabPanelId, children, ...rest }) => {
	const { classes } = useStyles({ mode });

	return (
		<Box
			aria-labelledby={`tab-${value}`}
			className={ifFeature('encore', classes.root)}
			hidden={value !== selectedValue}
			id={tabPanelId}
			role="tabpanel"
			tabIndex={0}
			{...rest}
		>
			{children}
		</Box>
	);
};
