import { MutableRefObject, ReactElement } from 'react';
import { SimpleSlidedownProps } from '../../types/SimpleSlidedownProps';
import { TYPES, AUTO_HIDE_DURATION, AUTO_HIDE_ACTION_DURATION } from '../constants';
import { ActionProps } from '~components/slidedown/types';

interface Slidedown {
	props: Partial<SimpleSlidedownProps>;
	state: {
		visible: boolean;
		type?: TYPES | undefined;
	};
	runAutoClose: number;
	_handleVisible: () => void;
}

export const checkForAutoCloseInClass = (slidedown: Slidedown): void => {
	const { visible } = slidedown.state;
	const { action } = slidedown.props;
	const type = slidedown.props.type || slidedown.state.type || TYPES.info;

	const duration = action ? AUTO_HIDE_ACTION_DURATION : AUTO_HIDE_DURATION;

	if (visible && type === TYPES.success) {
		slidedown.runAutoClose = window.setTimeout(() => slidedown._handleVisible(), duration);
	} else {
		clearTimeout(slidedown.runAutoClose);
	}
};

interface CheckForAutoCloseArgs {
	_handleVisible: () => void;
	timeoutIdRef: MutableRefObject<number>;
	type?: TYPES;
	visible: boolean;
	action?: ActionProps | ReactElement;
}

export const checkForAutoClose = ({
	_handleVisible,
	timeoutIdRef,
	type = TYPES.info,
	visible,
	action,
}: CheckForAutoCloseArgs): void => {
	const duration = action ? AUTO_HIDE_ACTION_DURATION : AUTO_HIDE_DURATION;

	if (visible && type === TYPES.success) {
		timeoutIdRef.current = window.setTimeout(() => _handleVisible(), duration);
	} else {
		clearTimeout(timeoutIdRef.current);
	}
};
