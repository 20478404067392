import { Card, CardContent, CardContentSpacing, CardSize, CardFlex } from '@fabric/card';
import { renderProp } from '@fabric/utils';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { BaseModal, useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { getSizeModifier } from '~utils';
import { StandardModalBodyProps } from '../standard-modal.types';
import { useStandardModalBodyStyles } from './body.styles';
import { hasCardSize } from '~components/card/util';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { DefaultHeader } from '../default-header';

export const Body: FunctionComponent<PropsWithChildren<StandardModalBodyProps>> & {
	Size: typeof CardSize;
} = props => {
	const { classes, cx } = useStandardModalBodyStyles();
	const { children, renderHeader, renderFooter, size = CardSize.SMALL, ...rest } = props;

	const { isMediumScreen } = useScreenSizes();

	const footer = renderProp(renderFooter);
	const header = renderProp(renderHeader);

	const jadeModalContent = (
		<Card flex={CardFlex.COLUMN} hasBottomGradient={false} size={CardSize.FULL}>
			<CardContent
				canOverflow={false}
				flex={CardFlex.COLUMN}
				spacingOverrides={{
					bottom: CardContentSpacing.NONE,
					left: CardContentSpacing.LARGE,
					right: CardContentSpacing.LARGE,
					top: CardContentSpacing.NONE,
				}}
			>
				{children}
				{ifFeature('encore', undefined, footer)}
			</CardContent>
		</Card>
	);

	const encoreModalContent = children;

	return isMediumScreen ? (
		<FullScreenModal.Body {...props} />
	) : (
		<BaseModal.Body {...rest}>
			<div className={cx(classes.root, { [classes[getSizeModifier(size as CardSize)]]: hasCardSize(size as CardSize) })}>
				{ifFeature('encore', header || <DefaultHeader hasCloseButton={true} />, header)}
				{ifFeature('encore', encoreModalContent, jadeModalContent)}
				{ifFeature('encore', footer, undefined)}
			</div>
		</BaseModal.Body>
	);
};

Body.Size = CardSize;
