import { SensitiveTextField } from '~components/sensitive-text-field';
import { SensitiveItem, SensitiveTextFieldProps } from '~components/sensitive-text-field/types';
import { RendererProps } from '../types';
import { omit } from 'lodash';
import React from 'react';

export function SensitiveTextFieldRenderer({ element }: RendererProps) {
	const sensitiveValue = (element.sensitive as Partial<SensitiveItem>)?.value;
	const fieldType = element['data-secure-field-type'] as Partial<string>;

	const props = omit(element, ['className', 'sensitive', 'token', 'type', 'validation']) as Partial<SensitiveTextFieldProps> &
		Pick<SensitiveTextFieldProps, 'onChange' | 'onShow'>;

	return <SensitiveTextField fieldType={fieldType} sensitiveValue={sensitiveValue} width={4} {...props} />;
}
