import classnames from 'classnames';
import { Box } from '@mui/material';
import { FabricIcon } from '@fabric/icon';
import { ChevronRight5x9 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isUrlExternal } from '@fabric/utils';
import { noop } from 'lodash';
import React, { Fragment, PureComponent, createRef } from 'react';
import { IconV2 } from '~components/icon-v2';
import { getBiId, getOptionCSSClasses, isOptionToggleable, scrollIntoView } from './util';
import { LinkUnstyled } from '~components/link/unstyled';

import './option.scss';

export class Option extends PureComponent {
	_scrollActiveItemIntoView() {
		const { isActive } = this.props;

		if (isActive) {
			window.requestAnimationFrame(() => {
				window.requestAnimationFrame(() => {
					scrollIntoView(this._ref.current);
				});
			});
		}
	}

	_renderContent() {
		const { isActive, isDisabled, isParent, item, renderContent = noop } = this.props;

		const content = renderContent(item, { isActive, isDisabled, isParent });

		return content || null;
	}

	_renderWithCheckbox() {
		const { isDisabled, isSelected, item, size } = this.props;

		const content = this._renderContent();
		const labelCSSClasses = classnames('fab-Checkbox__label', {
			'fab-Checkbox__label--small': size === 'small' || size === 'teenie',
		});

		return (
			<div className="fab-MenuOption__content">
				<div className="fab-Checkbox">
					<input
						checked={isSelected}
						className="fab-Checkbox__input"
						disabled={isDisabled}
						readOnly={true}
						type="checkbox"
					/>
					<span className={labelCSSClasses}>
						{content || (
							<Fragment>
								{item.icon
									? ifFeature(
											'encore',
											typeof item.icon === 'string' ? <IconV2 name={item.icon} size={16} /> : item.icon,
											typeof item.icon === 'string' ? <FabricIcon name={item.icon} /> : item.icon
										)
									: null}
								<div>
									{item.text}
									{item.note && !content ? <div className="fab-Checkbox__note">{item.note}</div> : null}
								</div>
							</Fragment>
						)}
					</span>
				</div>
			</div>
		);
	}

	_renderWithoutCheckbox() {
		const { isParent, item } = this.props;

		const content = this._renderContent();

		return (
			<Fragment>
				<div className="fab-MenuOption__content">
					{content || (
						<Fragment>
							<div className="fab-MenuOption__row">
								{item.icon
									? ifFeature(
											'encore',
											<div className="fab-MenuOption__contentIcon" key="icon">
												{typeof item.icon === 'string' ? <IconV2 name={item.icon} size={16} /> : item.icon}
											</div>,
											<div className="fab-MenuOption__contentIcon" key="icon">
												{typeof item.icon === 'string' ? <FabricIcon name={item.icon} /> : item.icon}
											</div>
										)
									: null}
								<div>
									{item.text}
									{item.note ? <div className="fab-MenuOption__note">{item.note}</div> : null}
								</div>
							</div>
						</Fragment>
					)}
				</div>

				{isParent && (
					<div className="fab-MenuOption__parentIcon">
						{ifFeature('encore', <IconV2 name="caret-right-solid" size={16} />, <ChevronRight5x9 />)}
					</div>
				)}
			</Fragment>
		);
	}

	_ref = createRef();

	componentDidMount() {
		this._scrollActiveItemIntoView();
	}

	componentDidUpdate(prevProps) {
		const { isActive: oldActive } = prevProps;

		const { isActive: newActive } = this.props;

		if (newActive && newActive !== oldActive) {
			this._scrollActiveItemIntoView();
		}
	}

	render() {
		const {
			anchor,
			biId,
			canSelectMultiple,
			isActionOnly,
			isActive,
			isDisabled,
			isExpanded,
			isGroupChild,
			isParent,
			isSelected,
			item,
			onHover = noop,
			onSelect = noop,
			parentItem = {},
			size,
			role,
		} = this.props;

		const isToggleable = isOptionToggleable({
			canSelectMultiple,
			exclusive: item.exclusive,
			isActionOnly,
			isParent,
		});

		const cssClasses = getOptionCSSClasses({
			anchor,
			isActive,
			isDisabled,
			isGroupChild,
			isToggleable,
			size,
		});

		const dataAttributes = {};

		if (biId) {
			dataAttributes['data-bi-id'] = getBiId(biId, item, parentItem);
		}

		/* Key events are being handled by the "Anchor" component and no other role makes sense for this element. */
		/* eslint-disable jsx-a11y/click-events-have-key-events */
		/* eslint-disable jsx-a11y/no-static-element-interactions */

		if (item.href && !isParent) {
			const isExternal = isUrlExternal(item.href);
			return ifFeature(
				'encore',
				<LinkUnstyled
					{...dataAttributes}
					aria-disabled={isDisabled || null}
					aria-selected={role === 'option' ? isSelected : undefined}
					className={cssClasses}
					component={item.component}
					containerRef={this._ref}
					href={item.href}
					id={item.id}
					onClick={onSelect}
					onMouseEnter={() => {
						if (!isDisabled) {
							onHover();
						}
					}}
					ref={this._ref}
					role={role}
					target={isExternal ? '_blank' : null}
				>
					{this._renderWithoutCheckbox()}
				</LinkUnstyled>,
				<a
					{...dataAttributes}
					aria-disabled={isDisabled || null}
					aria-selected={role === 'option' ? isSelected : undefined}
					className={cssClasses}
					href={item.href}
					id={item.id}
					onClick={onSelect}
					onMouseEnter={() => {
						if (!isDisabled) {
							onHover();
						}
					}}
					ref={this._ref}
					rel={isExternal ? 'noopener noreferrer' : null}
					role={role}
					target={isExternal ? '_blank' : null}
				>
					{this._renderWithoutCheckbox()}
				</a>
			);
		}

		return (
			<Box
				{...dataAttributes}
				aria-disabled={isDisabled || null}
				aria-expanded={(isParent || null) && isExpanded}
				aria-haspopup={isParent || null}
				aria-selected={role === 'option' ? isSelected : undefined}
				className={cssClasses}
				component={ifFeature('encore', item.component)}
				id={item.id}
				onClick={onSelect}
				onMouseEnter={() => {
					if (!isDisabled) {
						onHover();
					}
				}}
				ref={this._ref}
				role={role}
				tabIndex={-1}
			>
				{isToggleable ? this._renderWithCheckbox() : this._renderWithoutCheckbox()}
			</Box>
		);
		/* eslint-enable jsx-a11y/click-events-have-key-events */
		/* eslint-enable jsx-a11y/no-static-element-interactions */
	}
}
