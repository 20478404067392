/* @startCleanup encore */
import { styled, Theme } from '@mui/material';
import { fabricColor } from '~definitions/js/colors';
import { dyeColors, ModeOptions } from '~styles';
import { allUnionValues } from '~utils';
import { inputSelection, SelectableBoxTruncatedOptions } from './selectable-box.types';
import { shouldTruncate } from './selectable-box.utils';

/**
 * *note: if parent prop has `children`, `hasChildren` will be true.
 * *note: all other `has*` props will be false
 */
export interface SelectableBoxStylesProps {
	boxWidth: string;
	hasDescription: boolean;
	hasIcon: boolean;
	isChecked: boolean;
	isDisabled: boolean;
	truncated: boolean | SelectableBoxTruncatedOptions;
	type: inputSelection;
}

const allStyleProps = allUnionValues<keyof SelectableBoxStylesProps>();

const styleProps = allStyleProps(['boxWidth', 'hasDescription', 'hasIcon', 'isChecked', 'isDisabled', 'truncated', 'type']) as (
	| string
	| number
	| symbol
)[];

const options: Parameters<typeof styled>[1] = {
	shouldForwardProp: prop => !styleProps.includes(prop),
};

const baseThemeColor = 'themeBase';

const borderRadius = 2;

export const StyledContainer = styled(
	'div',
	options
)<SelectableBoxStylesProps>(({ boxWidth, isChecked, isDisabled, theme }) => {
	const { mode, palette } = theme;

	return {
		display: 'inline-block',
		position: 'relative',
		borderRadius,
		boxSizing: 'border-box',
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		pointerEvents: isDisabled ? 'none' : undefined,
		label: 'containerStyle',
		':focus-within': {
			boxShadow: `0 0 1px 1px ${fabricColor('themeLightest')}`,
		},
		width: boxWidth,
	};
});

export const StyledContent = styled(
	'div',
	options
)<SelectableBoxStylesProps>(({ hasDescription }) => {
	if (!hasDescription) {
		return {
			alignContent: 'center',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			label: 'contentStyle',
		};
	}

	return {
		display: 'flex',
		label: 'contentStyle',
	};
});

export const StyledDescription = styled(
	'p',
	options
)<SelectableBoxStylesProps>(({ isDisabled, truncated, theme }) => {
	const { palette, typography } = theme as Theme;
	return {
		color: isDisabled ? palette.gray[600] : palette.gray[700],
		fontSize: typography.teenie?.fontSize,
		label: 'descriptionStyle',
		lineHeight: typography.teenie?.lineHeight,
		marginBottom: 0,
		...(shouldTruncate(truncated, 'description')
			? {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}
			: {}),
	};
});

export const StyledIcon = styled(
	'span',
	options
)<SelectableBoxStylesProps>(({ hasDescription, isChecked, isDisabled, theme }) => {
	const { palette } = theme as Theme;
	let iconFillColor = isChecked ? palette.primary.main : palette.gray[700];

	if (isDisabled) {
		iconFillColor = palette.gray[500];
	}

	return {
		display: 'flex',
		fill: iconFillColor,
		justifyContent: 'center',
		margin: `0 ${hasDescription ? '13px' : 0} ${hasDescription ? 0 : '7px'} 0`,
		paddingTop: hasDescription ? '2px' : 0,
		label: 'iconStyle',
	};
});

export const StyledText = styled(
	'div',
	options
)<SelectableBoxStylesProps>(({ truncated }) =>
	shouldTruncate(truncated)
		? {
				label: 'textStyle',
				overflow: 'hidden',
			}
		: {}
);

export const StyledTitle = styled(
	'h6',
	options
)<SelectableBoxStylesProps>(({ hasDescription, isChecked, isDisabled, truncated, theme }) => {
	const { palette, typography } = theme as Theme;
	let titleColor = isChecked ? palette.primary.main : palette.gray[800];

	if (isDisabled) {
		titleColor = palette.gray[600];
	}

	return {
		color: titleColor,
		fontSize: typography.medium?.fontSize,
		fontWeight: isChecked ? typography.fontWeightBold : typography.fontWeightMedium,
		label: 'titleStyle',
		margin: `0 0 ${hasDescription ? '-1px' : 0} 0`,
		textAlign: hasDescription ? 'left' : 'center',
		...(shouldTruncate(truncated, 'title')
			? {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}
			: {}),

		'&:after': {
			display: 'block',
			content: `attr(title)`,
			fontWeight: typography.fontWeightBold,
			height: '1px',
			color: 'transparent',
			overflow: 'hidden',
			visibility: 'hidden',
		},
	};
});

export const StyledLabel = styled(
	'label',
	options
)<SelectableBoxStylesProps>(({ boxWidth, type, hasDescription, hasIcon, isChecked, isDisabled, theme }) => {
	const { palette, spacing } = theme as Theme;

	let boxMinHeight = 0;
	let boxMinWidth = 192;
	let boxMaxWidth = 400;
	let boxPadding = spacing(2, 4, 2, 2);
	let borderColor = isChecked ? palette.primary.main : palette.gray[500];

	if (isDisabled) {
		borderColor = palette.gray[300];
	}

	let checkBorderColor = isChecked ? palette.common.white : palette.gray[300];
	let checkboxBackgroundColor = isChecked ? palette.primary.main : 'transparent';
	let checkboxBorderColor = isChecked ? palette.primary.main : palette.gray[300];

	// Used to overlap the border if checked, unless hovering when type is checkbox
	function getCheckSizeAndPosition(checked: boolean) {
		return {
			width: checked ? 21 : 20,
			height: checked ? 21 : 20,
			bottom: checked ? -1 : 0,
			right: checked ? -1 : 0,
		};
	}

	if (isDisabled) {
		checkBorderColor = isChecked ? palette.common.white : 'transparent';
		checkboxBackgroundColor = isChecked ? palette.gray[500] : 'transparent';
		checkboxBorderColor = isChecked ? palette.gray[500] : 'transparent';
	}

	if (hasIcon && !hasDescription) {
		boxMinHeight = 92;
		boxMinWidth = 120;
		boxMaxWidth = 256;
		boxPadding = '20px 32px 16px';
	}

	return {
		backgroundColor: palette.common.white,
		borderRadius: 2,
		border: '1px solid',
		borderColor,
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'inline-block',
		minHeight: boxMinHeight,
		height: '100%',
		// Only set min and max widths if `boxWidth` has not been specified
		...(boxWidth
			? undefined
			: {
					maxWidth: boxMaxWidth,
					minWidth: boxMinWidth,
				}),
		outline: 'none',
		padding: boxPadding,
		position: 'relative',
		label: 'base',
		width: '100%',
		'&:after': {
			background: 'none',
			border: '2px solid',
			borderColor: checkBorderColor,
			borderRadius: 0,
			borderRight: 'none',
			borderTop: 'none',
			bottom: 8,
			boxSizing: 'border-box',
			content: '""',
			display: isChecked || type === 'checkbox' ? 'block' : 'none',
			height: 6,
			position: 'absolute',
			right: 4,
			transform: 'rotate(-50deg)',
			width: 11,
		},
		'&:before': {
			backgroundColor: checkboxBackgroundColor,
			borderStyle: 'solid',
			borderWidth: '1px 0 0 1px',
			borderColor: checkboxBorderColor,
			borderTopLeftRadius: 4,
			borderBottomRightRadius: borderRadius,
			boxSizing: 'border-box',
			content: '""',
			display: isChecked || type === 'checkbox' ? 'block' : 'none',
			margin: 0,
			position: 'absolute',
			...getCheckSizeAndPosition(isChecked),
		},

		'.SelectableBox > .SelectableBox__input:active + &, &:hover': {
			borderColor: isChecked ? fabricColor(baseThemeColor) : fabricColor('gray8'),
			boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',

			'&::before, &::after': {
				display: 'block',
			},
		},

		'.SelectableBox > .SelectableBox__input:focus + &:hover': {
			'&::before': {
				backgroundColor: isChecked ? fabricColor(baseThemeColor) : fabricColor('white'),
				borderColor: isChecked ? fabricColor(baseThemeColor) : fabricColor('gray3'),
			},

			'&::after': {
				borderColor: isChecked ? fabricColor('white') : fabricColor('gray3'),
			},
		},
	};
});

// Purposefully not using `options` as 2nd arg to `styled` since we don't pass
// `styledProps` into this component, and we want `type` to be passed through.
export const StyledInput = styled('input')(() => ({
	height: 1,
	opacity: 0,
	position: 'absolute',
	width: 1,
}));

/* @endCleanup encore */
