import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

interface LegendStyleProps {
	swatchColor?: string | null;
	swatchWidth: number;
	orientation: 'horizontal' | 'vertical';
}

export const useLegendStyles = makeStyles<LegendStyleProps>()(({ palette, typography }, props) => {
	const { swatchColor, orientation, swatchWidth } = props;

	return {
		root: {
			'& button': {
				border: 'none',
				fontSize: ifFeature('encore', typography.small.fontSize),
				fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightRegular),
				height: 'auto',
				justifyContent: ifFeature('encore', 'flex-end'),
				lineHeight: ifFeature('encore', typography.small.lineHeight, typography.fabricLineHeight('medium')),
				minWidth: ifFeature('encore', 0),
			},
		},
		swatch: {
			alignItems: 'center',
			display: 'flex',
			'&:before': {
				alignItems: 'center',
				backgroundColor: swatchColor || palette.gray[400],
				borderRadius: ifFeature('encore', 4, 3),
				content: '""',
				display: 'flex',
				flexShrink: 0,
				height: 20,
				justifyContent: 'center',
				marginRight: 8,
				width: swatchWidth,
			},
		},
		percentage: {
			color: ifFeature('encore', palette.gray[600], palette.gray[800]),
			marginLeft: 8,
		},
		value: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'flex-end',
			marginLeft: 32,
		},
		totalRow: {
			borderTop: orientation === 'vertical' ? `1px solid ${palette.divider}` : 'none',
			fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
			'& p': {
				fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
			},
			paddingTop: orientation === 'vertical' ? 8 : 0,
		},
		total: {
			fontSize: ifFeature('encore', typography.small.fontSize),
			lineHeight: ifFeature('encore', typography.small.lineHeight),
			marginLeft: ifFeature('encore', undefined, swatchWidth + 8),
		},
		typographyStyles: {
			color: 'inherit',
			fontSize: ifFeature('encore', typography.small.fontSize),
			lineHeight: ifFeature('encore', typography.small.lineHeight),
		},
	};
});
