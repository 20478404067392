import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from '~styles';

interface SafeAreaProps {
	children: ReactNode;
	innerRadius: number;
	bevelWidth: number;
}

interface StyleProps {
	size: number;
}

const useStyles = makeStyles<StyleProps>()((_, { size }) => {
	return {
		root: {
			height: size,
			left: '50%',
			position: 'absolute',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			width: size,
			zIndex: -1,
		},
	};
});

export const SafeArea: FC<PropsWithChildren<SafeAreaProps>> = ({ children, innerRadius, bevelWidth }) => {
	const size = innerRadius * 1.5 - bevelWidth;
	const { classes } = useStyles({ size });
	return <div className={classes.root}>{children}</div>;
};
