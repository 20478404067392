import { useState, useEffect, useRef } from 'react';
import { styles } from './styles';
import { ThumbsDown } from '../icons';
import { XIcon } from '../../icons/x-icon';
import ModalCheckbox from './ModalCheckbox';

import config from '../../../config';
import api from '../../../services/api';

interface FeedbackModalProps {
	closeModal: () => void;
	chatId: string | undefined;
	unhelpful: boolean | undefined;
	incorrect: boolean | undefined;
	harmful: boolean | undefined;
	comment: string | undefined;
}

export const FeedbackModal = ({
	closeModal,
	chatId,
	unhelpful,
	incorrect,
	harmful,
	comment,
}: FeedbackModalProps) => {
	const [feedbackData, setFeedbackData] = useState({
		harmful: harmful || false,
		incorrect: incorrect || false,
		unhelpful: unhelpful || false,
		comment: comment,
		userSuggestedAnswer: '',
	});

	const CHAT_FEEDBACK_ENDPOINT = config.VERSION_ENDPOINT + 'chat/' + chatId;

	const updateFeedbackData = (newDataKey, newDataValue) => {
		setFeedbackData({ ...feedbackData, [newDataKey]: newDataValue });
	};

	const sendFeedback = async () => {
		api.put(CHAT_FEEDBACK_ENDPOINT, {
			rating: 'negative',
			unhelpful: feedbackData.unhelpful || false,
			incorrect: feedbackData.incorrect || false,
			harmful: feedbackData.harmful || false,
			comment: feedbackData.comment || '',
			user_suggested_answer: feedbackData.userSuggestedAnswer || '',
		});
	};

	const popoverRef = useRef<HTMLDivElement>(null);
	const [popoverPosition, setPopoverPosition] = useState({
		top: '100%',
		left: '-359px',
		bottom: 'auto',
	});

	useEffect(() => {
		const handleLocation = () => {
			if (popoverRef.current) {
				const rect = popoverRef.current.getBoundingClientRect();
				const viewportHeight = window.innerHeight;
				const spaceBelow = viewportHeight - rect.top;
				const spaceAbove = rect.top;

				if (spaceBelow < spaceAbove) {
					setPopoverPosition({ top: 'auto', bottom: '100%', left: '-359px' });
				} else {
					setPopoverPosition({ top: '100%', bottom: 'auto', left: '-359px' });
				}
			}
		};

		handleLocation();
	}, []);

	return (
		<div
			style={{ ...styles.popoverContent, ...popoverPosition }}
			ref={popoverRef}
		>
			<div style={styles.popoverHeader}>
				<div style={styles.thumbsDownContainer}>
					<ThumbsDown fill={'#FE4A22'} />
				</div>
				<strong style={styles.titleText}>
					Why did you choose this rating?
				</strong>
				<div style={styles.exitButtonContainer}>
					<button
						style={styles.exitButton}
						onClick={() => {
							closeModal();
						}}
					>
						<XIcon fill="#999" />
					</button>
				</div>
			</div>

			<ModalCheckbox
				label="Potentially Harmful"
				onChange={updateFeedbackData}
				checked={feedbackData.harmful}
				field="harmful"
			/>
			<ModalCheckbox
				label="Not Entirely Correct"
				onChange={updateFeedbackData}
				checked={feedbackData.incorrect}
				field="incorrect"
			/>
			<ModalCheckbox
				label="Less Helpful Than It Could Be"
				onChange={updateFeedbackData}
				checked={feedbackData.unhelpful}
				field="unhelpful"
			/>
			<textarea
				style={styles.textArea}
				placeholder="Provide additional detail or feedback"
				onChange={(event) =>
					setFeedbackData({
						...feedbackData,
						comment: event.target.value,
					})
				}
			/>
			<textarea
				style={styles.textArea}
				placeholder="What’s a complete example of an excellent answer to this question? (optional)"
				onChange={(event) =>
					setFeedbackData({
						...feedbackData,
						userSuggestedAnswer: event.target.value,
					})
				}
			/>
			<button
				style={styles.submitButton}
				onClick={() => {
					sendFeedback();
					closeModal();
				}}
			>
				<strong style={styles.buttonText}>Submit</strong>
			</button>
		</div>
	);
};
