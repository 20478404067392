import React, { ReactElement } from 'react';
import { SkeletonProps } from './skeleton.types';
import { Skeleton as MUISkeleton } from '@mui/material';
import { useStyles } from './skeleton.styles';

export function Skeleton(props: SkeletonProps): ReactElement {
	const { animation = 'wave', className, ...rest } = props;
	const { classes, cx } = useStyles();

	return <MUISkeleton animation={animation} className={cx(classes.root, className)} {...rest} />;
}
