import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useStyles } from './summary-block.styles';
import { SummaryBlockProps } from './types';
import { RoundedBox } from '../rounded-box/rounded-box';
import { Flex } from '@fabric/flex';

/**
 * @deprecated Old version of the InlineMessage component, use that instead of this
 */
export const SummaryBlock = ({ icon, prefix, value = '--', label, ...rest }: SummaryBlockProps): ReactElement => {
	const { backgroundColor, color, flex, height, iconColor, iconHeight, iconWidth, margin, padding, className, classes, labelData } =
		rest;

	const { classes: styles } = useStyles({
		backgroundColor,
		color,
		flex,
		height,
		iconColor,
		iconHeight,
		iconWidth,
		margin,
		padding,
	} as SummaryBlockProps);

	const hasLabelWarning = !!labelData?.text;

	const text = (
		<Flex alignItems="flex-start" className={classes?.textWrapper} flexDirection="column" key="text" width="80%">
			<span className={classnames(styles.value, classes?.value)}>
				{prefix}
				{value}
			</span>
			<Flex alignItems="center" justifyContent="space-between" width={hasLabelWarning ? '100%' : ''}>
				<span className={classnames(styles.label, classes?.label)}>{label}</span>
				{hasLabelWarning ? (
					<span className={classnames(styles.label, styles[labelData.status], classes?.labelData)}>{labelData?.text}</span>
				) : null}
			</Flex>
		</Flex>
	);

	icon = React.cloneElement(icon, { className: classnames(styles.icon, classes?.icon), key: 'icon', 'aria-hidden': true });
	const content = [icon, text];

	return (
		<RoundedBox className={classnames(styles.root, classes?.root, className)} {...rest}>
			{content}
		</RoundedBox>
	);
};
