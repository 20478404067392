import { isEqual } from 'lodash';
import React, { PureComponent, createRef } from 'react';
import composeRefs from '@seznam/compose-react-refs';

export class Native extends PureComponent {
	_triggerInputEvent(inputElement) {
		inputElement.dispatchEvent(
			new CustomEvent('change', {
				bubbles: true,
			})
		);

		inputElement.dispatchEvent(
			new CustomEvent('input', {
				bubbles: true,
			})
		);
	}

	_handleValidation = (event = {}) => {
		const { onValidation } = this.props;

		const {
			detail: { isValid },
		} = event;

		onValidation(isValid);
	};

	_ref = createRef();

	componentDidMount() {
		const { current } = this._ref;

		if (current) {
			current.addEventListener('validation', this._handleValidation);
		}
	}

	componentDidUpdate(prevProps) {
		const { selectedValues: previouslySelectedValue } = prevProps;

		const { selectedValues: currentlySelectedValue } = this.props;

		if (!isEqual(previouslySelectedValue, currentlySelectedValue)) {
			this._triggerInputEvent(this._ref.current);
		}
	}

	componentWillUnmount() {
		const { current } = this._ref;

		if (current) {
			current.removeEventListener('validation', this._handleValidation);
		}
	}

	render() {
		const { canSelectMultiple, id, inputRef, isDisabled, name, onFocus, required, selectedValues = [] } = this.props;

		const additionalProps = {};

		let _selectedValues = canSelectMultiple ? selectedValues : selectedValues.slice(-1);

		if (_selectedValues.length === 0) {
			_selectedValues = [''];

			additionalProps['data-has-default-value'] = '';
		}

		const value = canSelectMultiple ? _selectedValues : _selectedValues[0];
		const composedRef = composeRefs(this._ref, inputRef);

		return (
			<select
				{...additionalProps}
				aria-hidden={true}
				className="chzn-ignore"
				disabled={isDisabled}
				id={id}
				multiple={canSelectMultiple}
				name={name}
				onFocus={onFocus}
				readOnly={true}
				ref={composedRef}
				required={required}
				style={{ border: 'none', height: 0, opacity: 0, overflow: 'hidden', position: 'absolute', width: 0 }}
				tabIndex={-1}
				value={value}
			>
				{_selectedValues.map(selectedValue => (
					<option key={selectedValue} value={selectedValue} />
				))}
			</select>
		);
	}
}
