import { makeStyles } from '~styles';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
export const useStyles = makeStyles()(() => {
	return {
		datePicker: ifFeature(
			'encore',
			{
				// Below both rules are for: When ag grid cell changes width, Material UI wrappers should adjust too.
				// Plus overrides default DatePicker styles.
				'& .MuiFormControl-root': {
					// @startCleanup encore
					width: ifFeature('encore', undefined, '100%'),
					// @endCleanup encore
				},

				'& > .fabInternal-DatePickerInput .MuiInputBase-root': {
					// @startCleanup encore
					width: ifFeature('encore', undefined, '100%'),
					// @endCleanup encore
					backgroundColor: 'transparent',
					border: 'none',
					boxShadow: 'none',
					'&&:focus-within': {
						boxShadow: 'none',
					},
				},

				// @startCleanup encore
				// Select Input field
				'& > .fabInternal-DatePickerInput input': {
					paddingLeft: ifFeature('encore', undefined, '0'),
				},

				// Selects root IconButton(calendar icon)
				'& .fabInternal-DatePickerInputNub': {
					paddingRight: ifFeature('encore', undefined, '0px'),
				},

				//Selects calendar icon
				'& .fabInternal-DatePickerInputNub > button  svg': {
					height: ifFeature('encore', undefined, '16px'),
					width: ifFeature('encore', undefined, '16px'),
				},
				// @endCleanup encore
			},
			{
				backgroundColor: 'transparent',
				display: 'block',
				height: '100%',
				padding: '0 6px',
				'&& div': {
					backgroundColor: 'transparent',
					border: 'none',
					height: '100%',
					width: '100%',
					'&:focus-within': {
						boxShadow: 'none',
					},
				},
				// Targets containing div of the Datepicker input
				'& > div > div > div > div > div:first-of-type': {
					alignItems: 'baseline',
					boxShadow: 'none',
					display: 'flex',
					height: '100%',
					padding: '0 16px',
					width: '50%',
					marginLeft: '0',
				},
			}
		),
	};
});
