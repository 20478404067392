import { MaskMatcher } from '../types';

export function getMatcherFromString(str: string): MaskMatcher {
	const strArray = str.split('');
	const maxLength = str.length;
	const firstChar = strArray.shift() as string;
	const remainingCharPatterns: Array<string> = [];

	while (strArray.length) {
		remainingCharPatterns.push(strArray.join(''));
		strArray.pop();
	}

	const regexStr = `^${firstChar}(?:${remainingCharPatterns.join('|')})?`;
	const regex = new RegExp(regexStr, 'i');

	return {
		regex,
		maxLength,
	};
}

export function getMatchersFromStringArray(arr: Array<string>): Array<MaskMatcher> {
	return arr.map(str => getMatcherFromString(str));
}
