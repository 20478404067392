import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const textStyles = ({
	palette,
	typography,
	mixins,
	constructs,
	borderRadiuses,
}: Theme): Record<'text' | 'textPrimary' | 'textSecondary', any> => ({
	text: {
		boxShadow: 'none',
		color: ifFeature('encore', palette.link.main, palette.info.main),
		fill: ifFeature('encore', palette.link.main, palette.primary.main),
		fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightSemibold),
		lineHeight: ifFeature('encore', typography.medium?.lineHeight),
		padding: 0,
		userSelect: 'text',
		position: ifFeature('encore', 'relative'),
		'& $label': {
			height: 'inherit',
		},
		'&$iconOnly': {
			height: 'unset',
		},
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			textDecoration: 'underline',
			color: palette.primary.main,
			fill: palette.primary.main,
		},
		'&:active, &$active': {
			boxShadow: 'none !important',
			color: palette.primary.main,
			fill: palette.primary.main,
		},
		'&$disabled, &$disabled$altStyling': {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			boxShadow: 'none',
			color: ifFeature('encore', constructs.text.neutral.xWeak, palette.grey[500]),
			fill: ifFeature('encore', constructs.text.neutral.xWeak, palette.grey[500]),
		},
		...ifFeature(
			'encore',
			{
				'&&$focusVisible, &&:focus, &:focus-visible': {
					border: '1px solid transparent',
					boxShadow: 'none',
					outline: 'none',
				},
				'&&$focusVisible, &:focus-visible': {
					'&::after': {
						content: '""',
						position: 'absolute',
						left: '-8px',
						top: '-4px',
						right: '-8px',
						bottom: '-4px',
						boxShadow: `0px 0px 0px 2px ${mixins.alpha(palette.primary.light, 0.8)}`,
						borderRadius: borderRadiuses[200],
					},
					color: palette.primary.main,
					fill: palette.primary.main,
				},
			},
			{
				'&$focusVisible, &:focus': {
					boxShadow: `0 0 0 2px ${palette.primary.lightest}`,
				},
			}
		),
		...ifFeature(
			'encore',
			{
				'&$altStyling': {
					'&:hover': {
						'&::after': {
							content: '""',
							position: 'absolute',
							top: '-4px',
							left: '-8px',
							right: '-8px',
							bottom: '-4px',
							boxShadow: `0px 0px 0px 2px ${mixins.alpha(constructs.surface.neutral.inverted, 0.3)}`,
							borderRadius: borderRadiuses[200],
						},
						textDecoration: 'none',
					},
					'&&$focusVisible, &&:focus-visible': {
						'&::after': {
							boxShadow: `0px 0px 0px 2px ${mixins.alpha(constructs.surface.neutral.inverted, 0.8)}`,
						},
						border: 'none',
						color: constructs.surface.neutral.inverted,
						fill: constructs.surface.neutral.inverted,
						textDecoration: 'none',
					},
					'&$disabled': {
						textDecoration: 'none',
					},
					'&&': {
						border: 'none',
						boxShadow: 'none',
					},
					textDecoration: 'underline',
				},
			},
			{
				'&$altStyling': {
					'&:hover, &$focusVisible, &:focus, &:active, &$active': {
						boxShadow: 'none',
						color: palette.primary.contrastText,
						fill: palette.primary.contrastText,
						textDecoration: 'underline',
					},
				},
			}
		),
	},
	textPrimary: {
		color: ifFeature('encore', palette.link.main, palette.info.main),
		fill: ifFeature('encore', palette.link.main, palette.info.main),
		fontWeight: ifFeature('encore', typography.fontWeightMedium),
		'&$altStyling': {
			color: ifFeature('encore', constructs.surface.neutral.inverted, palette.primary.contrastText),
			fill: ifFeature('encore', constructs.surface.neutral.inverted, palette.primary.contrastText),
			'&$focusVisible, &:focus': {
				color: ifFeature('encore', constructs.surface.neutral.inverted),
				fill: ifFeature('encore', constructs.surface.neutral.inverted),
			},
		},
	},
	textSecondary: {
		color: palette.grey[700],
		fill: palette.grey[700],
		fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightRegular),
		'&$altStyling': {
			color: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
			fill: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
			'&$focusVisible, &:focus': {
				color: ifFeature('encore', constructs.surface.neutral.medium),
				fill: ifFeature('encore', constructs.surface.neutral.medium),
			},
		},

		'&$disabled': {
			color: ifFeature('encore', constructs.text.neutral.xWeak, palette.grey[500]),
			fill: ifFeature('encore', constructs.text.neutral.xWeak, palette.grey[500]),
		},
	},
});
