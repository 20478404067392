import { ifFeature } from '@bamboohr/utils/lib/feature';
import { InputAdornment } from '@mui/material';
import React, { forwardRef } from 'react';
import { BaseIconV2 } from '~components/icon-v2/base-icon-v2';
import { TextField } from '~components/text-field';
import { MagGlass16x16 } from '@bamboohr/grim';
import { ClearButton } from './clear-button';
import { SearchInputProps } from '../types';
import { useStyles } from './search-input.styles';

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
	const {
		classes: classesProp,
		hasVisibleResults,
		placeholder = window.jQuery ? $.__('Search...') : 'Search...',
		onClear,
		value,
		...rest
	} = props;
	const { classes, cx } = useStyles({ value }, { props: { classes: classesProp } });

	return (
		<TextField
			classes={classesProp}
			id="global-search-header-input"
			InputProps={{
				ariaLabel: window.jQuery ? $.__('Search') : 'Search',
				autoComplete: 'off',
				classes: { root: cx(classes.root, { [classes.focus]: hasVisibleResults }) },
				denseAdornment: ifFeature('encore', true),
				endAdornment: (
					<InputAdornment className={classes.clearAdornment} position="end">
						<ClearButton onClear={onClear} />
					</InputAdornment>
				),
				startAdornment: (
					<InputAdornment className={classes.searchAdornment} disablePointerEvents position="start">
						{ifFeature(
							'encore',
							<BaseIconV2 className={classes.searchIcon} name="magnifying-glass-regular" />,
							<MagGlass16x16 aria-hidden={true} className={classes.searchIcon} />
						)}
					</InputAdornment>
				),
			}}
			name="Global Search"
			placeholder={placeholder}
			ref={ref}
			size={ifFeature('encore', 'medium', 'large')}
			value={value}
			{...rest}
		/>
	);
});
