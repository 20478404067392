import { ListItem, TransferListProps } from '../../../types';
import { cloneDeep } from 'lodash';

export const categorizeListItems = (listItems: TransferListProps, categorized: boolean) => {
	if (!categorized) {
		return listItems && listItems.leftListItems ? listItems.leftListItems : [];
	}
	const categorizeKey = {};
	let newLeftCategory = [] as ListItem[];
	if (listItems && listItems.leftListItems && Array.isArray(listItems.leftListItems)) {
		newLeftCategory = cloneDeep(listItems.leftListItems);
		listItems.leftListItems.forEach(elem => {
			if (elem.category && !categorizeKey[elem.category]) {
				categorizeKey[elem.category] = elem.category;
			}
		});
	}
	if (listItems && listItems.rightListItems && Array.isArray(listItems.rightListItems)) {
		listItems.rightListItems.forEach(elem => {
			if (elem.category && !categorizeKey[elem.category]) {
				const newRightListItems = cloneDeep(elem);
				newRightListItems.subcategory = undefined;
				newRightListItems.value = undefined;
				newRightListItems.title = '';
				newRightListItems.rowKey = '';
				newRightListItems.children = undefined;

				newLeftCategory.push(newRightListItems);
				categorizeKey[elem.category] = elem.category;
			}
		});
	}
	return newLeftCategory;
};
