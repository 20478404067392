import React, { Fragment } from 'react';

import { TextToggleProps } from '@fabric/toggle';
import { useStyles } from './text-toggle.styles';
import { makeUid } from '../../../../utils';

/**
 * ### `@fabric/toggle/Text`
 * @property {string}	[inputRef]		The Name to be passed as an Ref (for Validation)
 * @property {function}	[onChange]		Callback Function
 * @property {false}	[isChecked]		Is the Toggle Checked
 * @property {false}	[isDisabled]	Is the Toggle disabled
 * @property {false}	[isRequired]	Is the Toggle required
 * @property {string}	[name]			The Name for the Toggle
 * @property {string[]}	[value]			The values for the Toggle
 * */
export const TextToggle = (props: TextToggleProps) => {
	const {
		biId,
		id,
		inputRef,
		isChecked,
		isDisabled = false,
		isRequired,
		isView = false,
		name,
		onChange,
		size = 'medium',
		value,
	} = props;
	const { classes } = useStyles({ isDisabled, isView, size });

	if (typeof value === 'undefined') {
		console.error(`Toggle Type "text" Requires the "value" Property.`);
		return null;
	}
	if (!Array.isArray(value)) {
		console.error(`The "value" Property must be an Array.`);
		return null;
	}
	if (Array.isArray(value) && value.length !== 2) {
		console.error(`The "value" Property must contain 2 strings.`);
		return null;
	}

	const uniqueId = makeUid();
	const toggleName = name || 'fab-textToggle';
	const toggleId = id || `fab-text-Toggle--${uniqueId}`;

	return (
		<div className={classes.toggle} id={toggleId}>
			{value.map((option, i) => {
				const inputId = `fab-text-Toggle${option}-${toggleName}`;
				let checked = !!isChecked;

				if (i !== 0) {
					checked = !isChecked;
				}

				return (
					<Fragment key={`${option}`}>
						<input
							checked={isChecked === true || isChecked === false ? checked : undefined}
							className={classes.input}
							data-bi-id={biId && `${biId}-${option}`}
							defaultChecked={isChecked === undefined ? checked : undefined}
							disabled={isDisabled}
							id={inputId}
							name={`${toggleName}[]`}
							onChange={event => onChange(event, { value: option })}
							ref={inputRef}
							required={isRequired}
							type="radio"
						/>
						<label className={classes.label} htmlFor={inputId}>
							{option}
						</label>
					</Fragment>
				);
			})}
			<span className={classes.handle} />
		</div>
	);
};
