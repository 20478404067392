// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Flex, FlexProps } from '../../flex';
import { LegendProps } from '../types';
import { LegendItem } from './legend-item';

export const Legend = <Datum = unknown,>(props: LegendProps<Datum>): ReactElement => {
	const { data: ogData, getKey, getValue, orientation = 'vertical', reverse, showTotal } = props;
	const [...data] = ogData;

	if (reverse) {
		data.reverse();
	}

	const legendLength = data.length;

	const total = getValue && data.reduce((prev, curr): number => prev + getValue(curr), 0);

	const rootProps: FlexProps = orientation === 'vertical' ? { flexDirection: 'column' } : { flexDirection: 'row', flexWrap: 'wrap' };

	return (
		<Flex {...rootProps}>
			{data.map((item, index) => (
				<LegendItem
					datum={item}
					index={index}
					key={getKey(item)}
					legendLength={legendLength}
					legendProps={props}
					total={total}
				/>
			))}
			{getValue && showTotal && <LegendItem legendProps={props} total={total} />}
		</Flex>
	);
};
