import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useStyles } from './text-cell-editor.styles';
import { InternalCellEditorParams } from '../data-grid.types';

export const TextCellEditor = forwardRef((props: InternalCellEditorParams, ref) => {
	const { parseValue, value } = props;
	const [cellValue, setCellValue] = useState(value);
	const { classes } = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	useImperativeHandle(ref, () => {
		return {
			getValue() {
				return parseValue(cellValue);
			},
		};
	});

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCellValue(event.target.value);
	};

	return <input className={classes.textInput} onChange={onChange} ref={inputRef} type="text" value={cellValue} />;
});
