import { Box } from '@mui/material';
import { MotionConfig } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

import { AnimatedSvgProps } from './type';

export const AnimatedSvg: FC<PropsWithChildren<AnimatedSvgProps>> = ({ transition, width, height, children, chartTitle }) => (
	<MotionConfig transition={transition}>
		<Box height={height} position="absolute" width={width}>
			<svg height={height} width={width} {...{ title: chartTitle }}>
				{children}
			</svg>
		</Box>
	</MotionConfig>
);
