import classnames from 'classnames';
import { capitalize, kebabCase } from 'lodash';

export function getBiId(biId, item, parentItem = {}) {
	const childValue = item.value && typeof item.value === 'string' ? item.value : item.key;
	const parentValue = parentItem && typeof parentItem.value === 'string' ? parentItem.value : parentItem.key;

	return `${biId}-menu-option${parentValue ? `-${kebabCase(parentValue)}` : ''}${childValue ? `-${kebabCase(childValue)}` : ''}`;
}

export function getOptionCSSClasses(data = {}) {
	const { anchor, isActive, isDisabled, isGroupChild, isToggleable, size } = data;

	return classnames({
		'fab-MenuOption': true,
		'fab-MenuOption--active': isActive,
		'fab-MenuOption--anchorBottom': anchor === 'bottom',
		'fab-MenuOption--anchorTop': anchor === 'top',
		'fab-MenuOption--disabled': isDisabled,
		'fab-MenuOption--groupChild': isGroupChild,
		'fab-MenuOption--toggleable': isToggleable,
		[`fab-MenuOption--size${capitalize(size)}`]: !!size,
	});
}

export function getScrollContainer(element) {
	const { parentElement } = element;

	if (!parentElement || element.hasAttribute('data-menu-id')) {
		return;
	}

	return parentElement.scrollHeight > parentElement.clientHeight ? parentElement : getScrollContainer(parentElement);
}

export function isOptionToggleable(data) {
	const { canSelectMultiple, exclusive, isActionOnly, isParent } = data;

	return !!canSelectMultiple && !isParent && !isActionOnly && !exclusive;
}

export function scrollIntoView(element) {
	const scrollContainer = getScrollContainer(element);

	if (!scrollContainer || !document.body.contains(scrollContainer)) {
		return;
	}

	const scrollContainerOffsets = scrollContainer.getBoundingClientRect();
	const elementOffsets = element.getBoundingClientRect();

	const yOffsetDifference = elementOffsets.top - scrollContainerOffsets.top;

	let { scrollTop } = scrollContainer;

	if (yOffsetDifference < 0) {
		scrollTop += yOffsetDifference;
	} else if (yOffsetDifference + element.clientHeight > scrollContainer.clientHeight) {
		scrollTop += yOffsetDifference + element.clientHeight - scrollContainer.clientHeight;
	}

	scrollContainer.scrollTop = scrollTop;
}
