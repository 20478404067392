import { X16x16 } from '@bamboohr/grim';
import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { IconButton } from '~components/button';

import { BaseModalContext } from '../context';
import { CloseButtonProps } from '../base-modal.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';

export const CloseButton: FunctionComponent<PropsWithChildren<CloseButtonProps>> = ({ biId }) => {
	const { isProcessing, close } = useContext(BaseModalContext);

	const jadeCloseButton = (
		<IconButton
			aria-label={window.jQuery ? $.__('close modal') : 'close modal'}
			data-bi-id={biId}
			icon={<X16x16 aria-hidden="true" />}
			noBoundingBox={true}
			onClick={close}
			type="button"
		/>
	);

	const encoreCloseButton = (
		<IconButton
			aria-label={window.jQuery ? $.__('close modal') : 'close modal'}
			data-bi-id={biId}
			icon="xmark-solid"
			onClick={close}
			size="small"
			type="button"
			variant="outlined"
		/>
	);

	return isProcessing ? null : ifFeature('encore', encoreCloseButton, jadeCloseButton);
};
