// eslint-disable-next-line no-use-before-define
import React, { PureComponent, ReactElement } from 'react';
import SlidedownTransition from '../transition';
import SimpleSlidedown from '../slidedown/simple';
import { checkForAutoCloseInClass, squeakyClean } from '../slidedown/helpers';
import { DURATION, TYPES } from '../slidedown/constants';
import { BasicSlidedownProps } from '../types';

interface BasicSlidedownState {
	message: string;
	type?: TYPES;
	visible: boolean;
}

class BasicSlidedown extends PureComponent<BasicSlidedownProps, BasicSlidedownState> {
	constructor(props: BasicSlidedownProps) {
		super(props);

		// eslint-disable-next-line react/state-in-constructor
		this.state = {
			message: props.message || '',
			type: props.type,
			visible: props.showOnMount !== false,
		};
	}

	hideAlert = (): void => this.setState({ visible: false });

	setAlert = (message: string, type: TYPES): void => {
		const { visible } = this.state;

		if (visible) {
			this.hideAlert();
			window.setTimeout(() => this.setAlert(message, type), DURATION);
		} else {
			this.setState({
				message,
				type,
				visible: true,
			});
		}
	};

	_handleVisible = (): void => this.setState(prevState => ({ visible: !prevState.visible }));

	runAutoClose = -1;

	componentDidMount = (): void => checkForAutoCloseInClass(this);

	componentDidUpdate = (): void => {
		checkForAutoCloseInClass(this);
	};

	render(): ReactElement {
		const { message, type, visible } = this.state;

		return (
			<div style={{ height: 0 }}>
				<SlidedownTransition appear={true} show={visible}>
					<SimpleSlidedown
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						message={squeakyClean(message) as unknown as string}
						onDismiss={this._handleVisible}
						show={visible}
						showDismiss={type !== TYPES.success}
						type={type}
					/>
				</SlidedownTransition>
			</div>
		);
	}
}

export default BasicSlidedown;
