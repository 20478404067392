import { LighterToLightestColors, Gradients } from './types';

function lighterToLightestLinear(colors: LighterToLightestColors, angle = '90deg'): string {
	return `linear-gradient(${angle}, ${colors.lighter} 0, ${colors.lightest} 100%)`;
}

export function getGradients(): Gradients {
	return {
		lighterToLightestLinear,
	};
}
