import { useEffect, useRef } from 'react';

/**
 * Provides an alternative way to listen to the keydown event via React hooks.
 * @param targetElement The element to listen to
 * @param callback The callback to invoke when a 'keydown' event is triggered
 */
export const useKeydown = (targetElement: HTMLElement | HTMLDocument, callback: (event: KeyboardEvent) => void): void => {
	const callbackRef = useRef<(event: KeyboardEvent) => void>();
	callbackRef.current = callback;

	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (typeof callbackRef.current === 'function') {
				callbackRef.current(event);
			}
		};

		targetElement.addEventListener('keydown', handleKeydown);

		return () => {
			targetElement.removeEventListener('keydown', handleKeydown);
		};
	}, [callbackRef, targetElement]);
};
