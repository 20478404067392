import classNames from 'classnames';
import { FloatingIconButton } from '@fabric/button';
import { FileIcon } from '@fabric/file-icon';
import { FileType } from '@fabric/file-icon/types';
import { IconV2 } from '~components/icon-v2';
import { CircleX12x12 } from '@bamboohr/grim';
import React, { FunctionComponent, PropsWithChildren, SyntheticEvent } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	NO_SIGNATURE_NEEDED,
	SIGNATURE_CANCELED,
	SIGNATURE_DECLINED,
	SIGNATURE_PENDING,
	SIGNATURE_REQUIRED,
	SIGNATURE_SIGNED,
	VIRUS_DETECTED,
} from './constants';
import {
	DetailStyled,
	DisplayStyled,
	FileStyled,
	IconStyled,
	InfoStyled,
	LinkStyled,
	RemoveButtonStyled,
	TitleStyled,
	TypeStyled,
} from './styles';
import { DisplayFileCardProps } from '@fabric/file-card';
import { isFileTypeEsig } from '../../utils';

export const DisplayFileCard: FunctionComponent<PropsWithChildren<DisplayFileCardProps>> = props => {
	const {
		children,
		fileId,
		fileName = '',
		fileType,
		isDisabled = false,
		isSafe = true,
		onClick,
		onRemove,
		signatureState = 'pending',
	} = props;
	const fileNameDisplay = children || fileName;
	const fileNameTitle = typeof fileNameDisplay === 'string' ? fileNameDisplay : '';
	let fileTypeDisplay = fileType.toUpperCase();
	const isEsig = isFileTypeEsig(fileType);
	if (isEsig) {
		fileTypeDisplay = 'Esignature';
	}
	const className = classNames({
		'fab-FileCard--action': isSafe && onClick,
		'fab-FileCard--disabled': !isSafe || isDisabled || signatureState === 'canceled',
	});

	function handleClick() {
		if (isDisabled || !isSafe || !onClick) {
			return;
		}
		onClick(fileId);
	}

	/**
	 * GOTCHA: This was not in the initial mocks but was added in due to its usage in Company Registration for Payroll
	 * Their need was for the display view but to also have the ability to remove a document
	 */
	function renderRemoveButton() {
		if (!onRemove || isDisabled) {
			return null;
		}
		const removeFile = (event: SyntheticEvent) => {
			if (isDisabled) {
				return;
			}
			onRemove(fileId);
			event.stopPropagation();
		};
		return (
			<RemoveButtonStyled className="FileCard__remove">
				<FloatingIconButton
					aria-label={`${window.jQuery ? $.__('Remove File') : 'Remove File'}`}
					clickAction={removeFile}
					hasBounding={false}
					icon={ifFeature('encore', <IconV2 name="circle-xmark-solid" size={12} />, <CircleX12x12 />)}
					secondary={true}
					type="button"
				/>
			</RemoveButtonStyled>
		);
	}

	function renderTitle() {
		return <TitleStyled title={fileNameTitle}>{fileNameDisplay}</TitleStyled>;
	}

	function getEsigTextFromState(): string {
		if (signatureState === 'canceled') {
			return SIGNATURE_CANCELED;
		}
		if (signatureState === 'required') {
			return SIGNATURE_REQUIRED;
		}
		if (signatureState === 'signed') {
			return SIGNATURE_SIGNED;
		}
		if (signatureState === 'noSignatureNeeded') {
			return NO_SIGNATURE_NEEDED;
		}
		if (signatureState === 'declined') {
			return SIGNATURE_DECLINED;
		}
		return SIGNATURE_PENDING;
	}

	function renderText() {
		let displayName = fileTypeDisplay;
		if (!isSafe) {
			displayName = VIRUS_DETECTED;
		}
		if (!isEsig) {
			return displayName;
		}
		return <LinkStyled className={signatureState}>{getEsigTextFromState()}</LinkStyled>;
	}

	return (
		// eslint-disable-next-line jsx-a11y/role-supports-aria-props
		<DisplayStyled
			aria-disabled={isDisabled || !isSafe}
			aria-labelledby={`filecard-${fileId}`}
			className={className}
			onClick={handleClick}
			role="region"
		>
			<FileStyled>
				<DetailStyled>
					{renderTitle()}
					{renderRemoveButton()}
				</DetailStyled>
				<InfoStyled>
					<TypeStyled id={`filecard-${fileId}`} title={fileNameTitle}>
						<IconStyled>
							<FileIcon esig={isEsig} type={fileType as FileType} />
						</IconStyled>
						{renderText()}
					</TypeStyled>
				</InfoStyled>
			</FileStyled>
		</DisplayStyled>
	);
};
