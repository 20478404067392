import { Variants } from 'framer-motion';
import { useMemo } from 'react';

export const useBarVariants = (
	isVertical: boolean,
	path: string,
	width: number,
	height: number,
	yMax: number,
	x: number,
	y: number
): Variants => {
	return useMemo(() => {
		const scaleProp = isVertical ? 'scaleY' : 'scaleX';
		const translateProp = isVertical ? 'y' : 'x';
		const length = isVertical ? height : width;
		const start = isVertical ? y : x;
		const max = isVertical ? yMax : 0;

		return {
			initial: {
				[scaleProp]: 0,
				[translateProp]: max - start - length / 2,
				d: path,
			},
			idle: {
				[scaleProp]: 1,
				[translateProp]: 0,
				d: path,
			},
		};
	}, [isVertical, height, width, y, x, yMax, path]);
};
