import classnames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Avatar as MuiAvatar } from '@mui/material';
import { IconV2 } from '~components/icon-v2';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import React, { forwardRef, RefObject, ReactElement } from 'react';
import { AvatarProps } from './types';
import { mappedIconSize, mappedBorderRadius, avatarBoxShadow } from './avatar.constants';

// @startCleanup encore
import { PersonCircle32x32 } from '@bamboohr/grim';
// @endCleanup encore

const useStyles = makeStyles()(theme => {
	const { constructs, palette, typography, mode } = theme;
	return {
		root: {
			backgroundColor: ifFeature('encore', constructs.icon.neutral.strong, palette.gray[500]),
			fontSize: typography.medium?.fontSize,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		// @startCleanup encore
		fallback: {
			backgroundColor: palette.common.white,
			fill: palette.gray[500],
			height: '100%',
			width: '100%',
		},
		// @endCleanup encore
	};
});

export const Avatar = forwardRef((props: AvatarProps, ref: RefObject<HTMLDivElement>): ReactElement => {
	const { classes } = useStyles(undefined, { props: { classes: props.classes } });

	const { alt, children, className, size = 32, sx, ...rest } = props;
	const ariaLabel = alt || (window.jQuery ? $.__('Avatar') : 'Avatar');

	return (
		<MuiAvatar
			className={classnames(classes.root, className)}
			imgProps={{
				alt: alt || (window.jQuery ? $.__('Avatar') : 'Avatar'),
			}}
			ref={ref}
			sx={{
				borderRadius: ifFeature('encore', mappedBorderRadius(size)),
				boxShadow: ifFeature('encore', avatarBoxShadow(size)),
				height: size,
				width: size,
				...sx,
			}}
			{...rest}
		>
			{ifFeature(
				'encore',
				children || <IconV2 aria-label={ariaLabel} color="neutral-inverted" name="user-solid" size={mappedIconSize(size)} />,
				children || <PersonCircle32x32 aria-label={ariaLabel} className={classes.fallback} role="img" />
			)}
		</MuiAvatar>
	);
});
