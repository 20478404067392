import { useTabsList } from '@mui/base';
import React, { ReactNode } from 'react';
import { SideTabDivider } from './side-tab-divider';
import { SideTabGroupHeader } from './side-tab-group-header';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabGroupProps {
	children?: ReactNode;

	/** A string for a simple header, or any other valid `ReactNode`. */
	header?: ReactNode;
}

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * Renders the provided children below a divider and optional header,
 * wrapped in a Fragment.
 */
export function SideTabGroup({ children, header }: SideTabGroupProps) {
	// Used to assign the proper tabindex to each tab.
	const { processChildren } = useTabsList({ children, ref: null });
	return (
		<>
			<SideTabDivider />
			{typeof header === 'string' ? <SideTabGroupHeader>{header}</SideTabGroupHeader> : header}
			{processChildren()}
		</>
	);
}
