export const dyeColors = {
	contrastText: '#000',
	dark: '#f6f',
	light: '#f6f',
	lighter: '#f9f',
	lightest: '#fcf',
	main: '#f0f',
};

export enum ModeOptions {
	Dye = 'dye',
	Normal = 'normal',
}

export enum ThemeNameOptions {
	Encore = 'encore',
	Jade = 'jade',
}
