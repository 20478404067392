import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ palette, typography, mixins }) => {
	return {
		currencyButton: {
			// Need to increase specificity because the Button styles are overriding these styles
			'&&': {
				color: ifFeature('encore', palette.gray[600], palette.grey[900]),
				border: `none`,
				borderLeft: ifFeature('encore', undefined, `1px solid ${palette.grey[100]}`),
				fontSize: typography?.teenie?.fontSize,
				fontWeight: ifFeature('encore', undefined, typography.fontWeightRegular),
				height: '100%',
				lineHeight: ifFeature('encore', typography.teenie.lineHeight),
				fontFamily: typography.fontFamily,
				padding: ifFeature('encore', '0', '0 5px'),
				borderRadius: ifFeature('encore', 8),
				minWidth: ifFeature('encore', '54px'),
				maxWidth: ifFeature('encore', '54px'),
				'&:disabled': {
					border: `none`,
					borderLeft: ifFeature('encore', undefined, `1px solid ${palette.grey[100]}`),
					backgroundColor: ifFeature('encore', palette.gray[50]),
				},
				'&:focus': {
					background: 'white !important',
					borderLeft: ifFeature('encore', undefined, `1px solid transparent !important`),
					boxShadow: ifFeature('encore', `0 0 0 4px ${mixins.alpha(palette.primary.main, 0.1)}`),
				},
				'&:hover': {
					background: palette.grey[100],
					border: `none`,
					borderLeft: ifFeature('encore', undefined, `1px solid ${palette.grey[100]}`),
					boxShadow: ifFeature('encore', `0 0 0 1px ${palette.gray[400]}`, 'none'),
				},
			},
		},
		focusVisible: {
			borderLeft: ifFeature('encore', undefined, `1px solid transparent !important`),
		},
	};
});
