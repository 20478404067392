import { hasCollisionWithAnchor } from '../container';
import { Directions } from '../../enums';
import { getDimensions } from '../../offsets';
import { isLeftOrRightLocation } from '../../locations';

export function shouldSlideVertically(collision, offsets, locations) {
	const isVerticalCollision = collision === Directions.TOP || collision === Directions.BOTTOM;

	return (
		isVerticalCollision &&
		!hasCollisionWithAnchor(offsets) &&
		isLeftOrRightLocation(locations.anchor) &&
		isLeftOrRightLocation(locations.vessel)
	);
}

export function slideVertically(collision, offsets) {
	const { container, vessel } = offsets;

	const vesselDimensions = getDimensions(vessel);

	const newOffsets = { ...vessel };
	if (collision === Directions.TOP) {
		newOffsets.top = container.top;
		newOffsets.bottom = container.top + vesselDimensions.height;
	} else if (collision === Directions.BOTTOM) {
		newOffsets.top = container.bottom - vesselDimensions.height;
		newOffsets.bottom = container.bottom;
	}
	return newOffsets;
}
