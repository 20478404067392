/* eslint-disable sort-keys */
import { colors } from '../../definitions/json/encore-colors.json';
import jsonThemeColors from '../../definitions/json/encore-theme-colors.json';

export type PrimitiveMap = ReturnType<typeof getEncorePrimitives>;

// this exists to serve as a mapping to the Figma defined primitives,
// the json definitions already exist and are used in other codebases, but don't match Figma
export const getEncorePrimitives = (brandColors: (typeof jsonThemeColors.themeColors)[0]) => {
	return {
		functional: {
			brandColor: colors.brandColor,
			link: colors.link,
		},
		neutral: {
			0: colors.white,
			50: colors.gray05,
			100: colors.gray1,
			200: colors.gray2,
			300: colors.gray3,
			400: colors.gray4,
			500: colors.gray5,
			600: colors.gray6,
			700: colors.gray7,
			800: colors.gray8,
			900: colors.gray9,
			1000: colors.gray10,
		},
		primary: {
			100: brandColors.lightest,
			500: brandColors.lighter,
			900: brandColors.base,
			1000: brandColors.dark,
		},
		sentiment: {
			success: {
				100: colors.successLight,
				500: colors.success,
				900: colors.successDark,
			},
			error: {
				100: colors.errorLight,
				500: colors.error,
				900: colors.errorDark,
			},
			warning: {
				100: colors.warningLight,
				500: colors.warning,
				900: colors.warningDark,
			},
			info: {
				100: colors.infoLight,
				500: colors.info,
				900: colors.infoDark,
			},
			discovery: {
				100: colors.discoveryLight,
				500: colors.discovery,
				900: colors.discoveryDark,
			},
			attention: {
				100: colors.attentionLight,
				500: colors.attention,
				900: colors.attentionDark,
			},
			notice: {
				900: colors.noticeDark,
			},
		},
	};
};
