import { noop } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker } from '~components/date-picker';
import { useStyles } from './date-picker-cell-editor.styles';
import { InternalCellEditorParams } from '../data-grid.types';
import { ItemType } from '../../types';

export const DatePickerCellEditor = forwardRef((props: InternalCellEditorParams, ref) => {
	const { parseValue, stopEditing, value: initialCellValue } = props;
	const { classes } = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);
	const [doneEditing, setDoneEditing] = useState(false);
	const [cellValue, setCellValue] = useState<ItemType>({ value: initialCellValue });

	useEffect(() => inputRef.current?.focus(), []);

	useEffect(() => {
		if (doneEditing) {
			stopEditing();
		}
	}, [doneEditing, stopEditing]);

	useImperativeHandle(
		ref,
		() => {
			return {
				getValue() {
					let returnValue;
					const { text, value } = cellValue || {};

					if (value) {
						// We have a valid date so we'll return that value.
						returnValue = value;
					} else if (!text) {
						// The user must have cleared the value or there was no initial value.
						returnValue = null;
					} else {
						// Revert back to the previous value because the editor doesn't contain a valid date.
						returnValue = initialCellValue;
					}

					return parseValue(returnValue);
				},
			};
		},
		[cellValue, initialCellValue, parseValue]
	);

	return (
		<DatePicker
			className={classes.datePicker}
			inputProps={{
				ref: inputRef,
			}}
			onCalendarDateSelect={({ text = '', value = '' }) => {
				setCellValue({ text, value });
				setDoneEditing(true);
			}}
			onChange={noop}
			onInputChange={({ text = '', value = '' }) => {
				// We only want to change the value when there's a valid date or the field is empty.
				if (value || !(text && value)) {
					setCellValue({ text, value });
				}
			}}
			value={cellValue.value}
		/>
	);
});
