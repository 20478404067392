import React, { FC, PropsWithChildren } from 'react';
import { CardFlex, CardProps, CardSize } from '@fabric/card';
import { getClassName } from './util';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './card.styles';
import './styles.scss';

/**
 * Creates a card.
 *
 * To apply standard padding to your content, wrap it inside of the
 * CardContent component (another named export from @fabric/card).
 * @deprecated
 */
export const Card: FC<PropsWithChildren<CardProps>> = props => {
	const { children, encoreOnly, hasBottomGradient = true, flex, hasHover = false, size } = props;
	const { classes } = useStyles(
		{ flex: flex as CardFlex, hasHover, size: size as CardSize },
		ifFeature('encore', { props: { classes: props.classes } })
	);

	const jadeContent = (
		<div className={getClassName('fab-Card', { flex: flex as CardFlex, hasBottomGradient, size: size as CardSize })}>
			{children}
			{hasBottomGradient && <div className="fab-Card__bottomGradient"></div>}
		</div>
	);

	const encoreContent = <div className={classes.root}>{children}</div>;

	return ifFeature('encore', encoreContent, encoreOnly ? <>{children}</> : jadeContent);
};

Card.displayName = 'Card';

export default Card;
