import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TransferListSubcategoryProps, TransferListSide } from '../../types';

export const useSubcategoryStyles = makeStyles<TransferListSubcategoryProps, 'title'>()(
	({ constructs, mode, palette, spacing, typography }, { disabled, transferListSide }, classes) => ({
		root: {
			backgroundColor: palette.common.white,
			border: ifFeature('encore', `1px solid transparent`),
			// @startCleanup encore
			borderBottom: ifFeature('encore', undefined, `1px solid ${palette.gray[400]}`),
			// @endCleanup encore
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			paddingBottom: ifFeature('encore', spacing(4.5), '15px'),
			position: 'relative',
			'&:not(:last-child)': {
				borderBottomColor: ifFeature('encore', constructs.border.neutral.xWeak),
			},
		},
		draggingStyles: {
			opacity: 0,
		},
		rootHover: ifFeature(
			'encore',
			{
				'&&': {
					backgroundColor: transferListSide === TransferListSide.RIGHT ? undefined : constructs.surface.primary.weak,
					borderColor: transferListSide === TransferListSide.RIGHT ? undefined : constructs.border.primary.strong,
					[`& .${classes.title}`]: {
						color: transferListSide === TransferListSide.RIGHT ? undefined : constructs.text.primary.strong,
					},
				},
			},
			{
				backgroundColor: `${palette.primary.lightest}1A`,
			}
		),
		subcategoryOption: {
			height: '100%',
			position: 'absolute',
			width: '100%',
		},
		container: {
			display: 'flex',
			// @startCleanup encore
			height: ifFeature('encore', undefined, 40),
			// @endCleanup encore
			padding: ifFeature('encore', spacing(1.5, 3, 1.5, 2.5), '0 10px'),
		},
		centerHorizontal: {
			// @startCleanup encore
			alignItems: ifFeature('encore', undefined, 'center'),
			// @endCleanup encore
			display: 'flex',
			flexDirection: 'row',
		},
		leftContent: {
			color: ifFeature('encore', constructs.text.neutral.medium, disabled ? palette.gray[600] : palette.gray[1000]),
			flex: 2,
		},
		title: {
			fontWeight: ifFeature('encore', typography.fontWeightSemibold),
			lineHeight: typography.medium?.lineHeight,
			maxWidth: 210,
			overflow: 'hidden',
			marginLeft: ifFeature('encore', spacing(3.5)),
			textOverflow: 'ellipsis',
			userSelect: 'none',
			whiteSpace: 'nowrap',
		},
		rightContent: {
			color: palette.gray[600],
			fontSize: ifFeature('encore', typography.medium.fontSize, typography.small?.fontSize),
			lineHeight: ifFeature('encore', typography.medium.lineHeight, '19px'),
			userSelect: 'none',
		},
		icon: {
			display: 'none',
			fill: transferListSide === TransferListSide.RIGHT ? palette.gray[600] : palette.primary.main,
		},
		hover: {
			display: 'initial',
		},
		childrenContainer: {
			border: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`),
			// @startCleanup encore
			borderLeft: ifFeature('encore', undefined, `1px solid ${palette.gray[400]}`),
			borderRight: ifFeature('encore', undefined, `1px solid ${palette.gray[400]}`),
			borderTop: ifFeature('encore', undefined, `1px solid ${palette.gray[400]}`),
			// @endCleanup encore
			display: 'flex',
			justifyContent: 'center',
			margin: ifFeature('encore', `46px 28px 0`, '40px 15px 0'),
			zIndex: 1,
		},
		children: {
			width: '100%',
		},
	})
);
