import React, { ReactElement } from 'react';
import { AutocompleteMultiple } from '../../../autocomplete';
import { ListItem, SelectSearchBarProps } from '../../types';
import { useStyles } from './styles';

export const SelectSearchBar = (props: SelectSearchBarProps): ReactElement => {
	const { label, leftListItems, selectedOptions, placeholder, onSelect } = props;
	const { classes } = useStyles();
	return (
		<div className={classes.searchInput}>
			<AutocompleteMultiple<ListItem>
				classes={{ listbox: classes.dropdownList, option: classes.dropdownOption }}
				closeOnSelect={true}
				getOptionLabel={field => field.title}
				label={label}
				onChange={(param, event) => {
					const { value, rowKey } = param.value[0];
					onSelect({ value, key: rowKey }, event.nativeEvent as PointerEvent);
				}}
				options={
					selectedOptions.length > 0
						? leftListItems.filter(item => {
								return !selectedOptions.find(selectedItem => {
									return selectedItem.rowKey === item.rowKey;
								});
							})
						: [...leftListItems]
				}
				placeholder={placeholder}
				value={[]}
			/>
		</div>
	);
};
