import React from 'react';
import { useScreenReaderOnlyTextStyles } from './screen-reader-only-text.styles';
import { ScreenReaderOnlyTextProps } from './screen-reader-only-text.types';

/**
 * This component should be used when text should be read by screen readers but should not be visible in the UI.
 * Even though it can take a ReactNode as its children, it renders its children inside a `<span>`, so only inline elements should be used.
 */
export function ScreenReaderOnlyText({ ariaAtomic, ariaLive, children }: ScreenReaderOnlyTextProps) {
	const { classes } = useScreenReaderOnlyTextStyles();

	return (
		<span aria-atomic={ariaAtomic} aria-live={ariaLive} className={classes.visuallyHidden}>
			{children}
		</span>
	);
}
