import React, { type ElementType, type ReactElement } from 'react';
import { BaseProps } from './base.types';
import { useBaseStyles } from './base.styles';
import { BodyText } from '~components/body-text';
import { IconTile } from '~components/icon-tile';
import { BaseHeadline } from '~components/headline/base-headline';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { formatBaseProps } from '~utils';
import { Box } from '@mui/material';

export function Base<C extends ElementType = 'div'>(props: BaseProps<C>): ReactElement | null {
	const {
		actionPlacement = 'bottom',
		actions = [],
		component = 'div',
		description,
		height,
		icon,
		orientation = 'vertical',
		right,
		title,
		titleSize = 'small',
		variant,
		width,
		...rest
	} = props;
	const { classes } = useBaseStyles({ actionPlacement, actions, description, height, orientation, titleSize, width }, { props });

	let titleColor: 'neutral-medium' | 'primary' = 'primary';
	let descriptionColor: 'neutral-medium' | 'neutral-strong' = 'neutral-strong';
	let iconColor: 'secondary' | undefined;

	switch (variant) {
		case 'muted':
			titleColor = 'neutral-medium';
			descriptionColor = 'neutral-medium';
			iconColor = 'secondary';
			break;
		default:
			titleColor = 'primary';
			descriptionColor = 'neutral-strong';
			iconColor = undefined;
	}

	return ifFeature(
		'encore',
		<Box component={component} {...formatBaseProps(rest)} className={classes.root}>
			{icon ? (
				<div className={classes.icon}>
					{typeof icon === 'string' ? <IconTile icon={icon} size={48} variant={iconColor} /> : icon}
				</div>
			) : (
				icon
			)}
			{orientation === 'vertical' ? <div className={classes.right}>{right}</div> : null}
			<div className={classes.textContainer}>
				{titleSize === 'small' ? (
					<BodyText color={titleColor} weight="medium">
						<span className={classes.text}>{title}</span>
					</BodyText>
				) : (
					<BaseHeadline color={titleColor} component="p" size={titleSize === 'medium' ? 'extra-small' : 'medium'}>
						<span className={classes.text}>{title}</span>
					</BaseHeadline>
				)}
				{description ? (
					<BodyText color={descriptionColor} size="extra-small">
						<span className={classes.text}>{description}</span>
					</BodyText>
				) : null}
			</div>
			{actions.length ? <div className={classes.actions}>{actions}</div> : null}
		</Box>,
		null
	);
}
