import { Collapse } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ChevronLeft8x13 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';
import { AccordionBase } from './accordion-base';
import { AccordionBody } from './accordion-body';
import { AccordionHeader } from './accordion-header';
import { AccordionHeaderContent } from './accordion-header-content';
import { AccordionProps, AccordionVariant } from './accordion.types';
import { useAccordionStyles } from './accordion.styles';
import { useAccordionBorderTheme, useAccordionCardTheme, useAccordionCompactTheme } from './accordion-themes';

export const Accordion = ({
	biId,
	/* @startCleanup encore */
	className,
	classes: classesProp,
	/* @endCleanup encore */
	children,
	collapsedSize = '62px',
	defaultExpanded,
	description,
	disabled,
	expandIcon,
	expanded: expandedProp,
	headerContent,
	headerIcon,
	headerRight,
	hideDescriptionOnCollapse = false,
	hideExpandIcon = false,
	onChange,
	/* @startCleanup encore */
	sx,
	/* @endCleanup encore */
	title,
	variant,
	...rest
}: AccordionProps): JSX.Element => {
	const [accordionId] = useState(typeof title === 'string' ? title.replaceAll(' ', '-') : uniqueId());
	const [expanded, setExpanded] = useState(expandedProp ?? defaultExpanded);

	// Keep the useAccordionTheme hooks as they are for now
	// Once Encore is permanently on and use of the hooks has been
	// removed from the other repos, we can refactor the way these
	// variant styles are being applied
	const variantClasses: Record<AccordionVariant, ClassNameMap> = {
		border: useAccordionBorderTheme(),
		card: useAccordionCardTheme(),
		compact: useAccordionCompactTheme(),
	};

	const { classes, cx } = useAccordionStyles(undefined, {
		props: {
			classes: ifFeature('encore', variantClasses[variant || ''], {
				...variantClasses[variant || ''],
				...classesProp,
			}) as ClassNameMap,
		},
	});

	useEffect(() => {
		if (typeof expandedProp === 'boolean') {
			setExpanded(expandedProp);
		}
	}, [expandedProp]);

	const DefaultExpandIcon = ChevronLeft8x13;

	const expandIconProp = !hideExpandIcon && {
		expandIcon:
			expandIcon ||
			ifFeature('encore', !disabled && <IconV2 name="angle-left-regular" size={20} />, <DefaultExpandIcon aria-hidden="true" />),
	};

	// This needs to be an array, not two elements in a React Fragment
	// because MUI's Accordion assumes there are two children and creates an empty element if there is only one
	const accordionContent = [
		<AccordionHeader
			aria-controls={`accordion-control-${accordionId}`}
			aria-disabled={disabled}
			classes={{ root: classes.header, content: classes.headerContent, expandIconWrapper: classes.expandIcon }}
			data-bi-id={biId}
			hideDescriptionOnCollapse={hideDescriptionOnCollapse}
			id={`accordion-header-wrapper-${accordionId}`}
			key="header"
			role="button"
			{...expandIconProp}
		>
			{headerContent || (
				<AccordionHeaderContent
					description={description}
					disabled={disabled}
					expanded={expanded}
					headerIcon={headerIcon}
					headerRight={headerRight}
					hideDescriptionOnCollapse={hideDescriptionOnCollapse}
					id={`accordion-header-${accordionId}`}
					title={title}
				/>
			)}
		</AccordionHeader>,

		/* @startCleanup encore */
		/* For Jade, if the accordion does NOT hide the description on Collapse, we use this Transition component. */
		/* @endCleanup encore */
		<Collapse in={ifFeature('encore', expanded, hideDescriptionOnCollapse || expanded)} key="contents" timeout="auto">
			<AccordionBody classes={{ root: classes.body }} id={`accordion-control-${accordionId}`}>
				{children}
			</AccordionBody>
		</Collapse>,
	];

	return (
		<AccordionBase
			classes={{ root: classes.root, disabled: classes.disabled }}
			className={cx(
				/* @startCleanup encore */
				ifFeature('encore', undefined, className),
				/* @endCleanup encore */
				{
					[classes.expanded]: expanded,
					[classes.hideDescriptionOnCollapse]: hideDescriptionOnCollapse,
				}
			)}
			defaultExpanded={defaultExpanded}
			disabled={disabled}
			expanded={expandedProp}
			hideDescriptionOnCollapse={hideDescriptionOnCollapse}
			id={`accordion-root-${accordionId}`}
			onChange={(e, isExpanded) => {
				/**
				 * expandedProp value should always take priority.
				 */
				setExpanded(expandedProp ?? isExpanded);
				// eslint-disable-next-line no-unused-expressions
				onChange?.(isExpanded, e);
			}}
			square={true}
			/* @startCleanup encore */
			sx={ifFeature('encore', undefined, sx)}
			/* @endCleanup encore */
			TransitionProps={{ in: true }}
			{...rest}
		>
			{ifFeature(
				'encore',
				accordionContent,
				// If the accordion hides the description on Collapse, we use this Transition component.
				<Collapse
					collapsedSize={collapsedSize}
					id={`section-content-${accordionId}`}
					in={!hideDescriptionOnCollapse || expanded}
					timeout="auto"
				>
					{accordionContent}
				</Collapse>
			)}
		</AccordionBase>
	);
};
