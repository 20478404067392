import { isObjectLike } from 'lodash';
import { FilterItem, ListItem } from '../types';

export function filterListItems(availableOptions: ListItem[], listFilters: FilterItem[]) {
	let filteredList: ListItem[];

	if (!Array.isArray(availableOptions)) {
		throw new Error(`Expected an array but got ${typeof availableOptions}`);
	}

	if (Array.isArray(listFilters) && listFilters.length > 0) {
		filteredList = availableOptions.filter(listItem => {
			const { value } = listItem;

			let includeItem = true;

			for (let index = 0; index < listFilters.length; index++) {
				const filter = listFilters[index];
				if (typeof value === 'object' && value && isObjectLike(value)) {
					if (value[filter.filterId] !== filter.value) {
						includeItem = false;
						break;
					}
				} else {
					includeItem = false;
					break;
				}
			}
			return includeItem;
		});
	} else {
		filteredList = [...availableOptions];
	}

	return filteredList;
}
