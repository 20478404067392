import { uniqueId } from 'lodash';
import React, { forwardRef } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { OutlinedInput } from '~components/form/components/common/outlined-input/outlinedInput';
import { InputWrapper } from '~components/form/components/input-wrapper';
import { useReadableContext } from '~components/readable';
import { useStyles } from './text-field.styles';
import { TextFieldProps } from './types';

/**
 *
 * Text input for all forms and such.
 * The `ref` is forwarded to the Input.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props: TextFieldProps, ref) => {
	const { isReadable } = useReadableContext();

	let appliedProps = props;
	const { viewMode = isReadable } = props;

	if (viewMode) {
		const { placeholder, ...rest } = props;
		appliedProps = rest;
	}

	const {
		InputLabelProps,
		InputProps,
		afterLabel,
		autoFocus,
		biId,
		classes = {},
		className,
		defaultValue,
		disabled = false,
		focusRing = true,
		inputProps,
		label,
		labelPlacement = 'block',
		maxRows,
		minRows,
		multiline = false,
		name,
		note,
		notePlacement = 'inline',
		onBlur,
		onChange,
		onFocus,
		onKeyDown,
		placeholder,
		required = false,
		rows,
		rowsMax,
		size = 'medium',
		status,
		value,
		variant,
		width = 100,
	} = appliedProps;

	const { classes: styles } = useStyles({ labelPlacement, size, width });

	// Always provide an ID, since it ties the label to the input, which is needed for the input to be accessible.
	const id = appliedProps.id || uniqueId('FabricTextField-');

	const labelClasses = ifFeature(
		'encore',
		{
			label: styles.label,
			...(classes ?? {}),
		},
		classes
	);

	return (
		<InputWrapper
			afterLabel={afterLabel}
			classes={labelClasses}
			className={className}
			disabled={disabled}
			id={id}
			InputLabelProps={InputLabelProps}
			label={label}
			labelPlacement={labelPlacement}
			note={note}
			notePlacement={notePlacement}
			required={required}
			status={status}
			variant={variant}
			viewMode={viewMode}
			width={width}
		>
			<OutlinedInput
				{...InputProps}
				autoFocus={autoFocus}
				biId={biId}
				classes={{
					root: styles.width,
					// TODO: This needs to be addressed at some point. Right now the InputProps.classes are
					// overriding the root classes and causing certain TextField classes to not get applied
					// when they probably should.
					// The issue is documented more in depth here:
					// https://bamboohr.slite.com/app/docs/FxXrhP0bQkW2fE#c264357a
					...(InputProps?.classes || {}),
				}}
				defaultValue={defaultValue}
				disabled={disabled}
				focusRing={focusRing}
				id={id}
				inputProps={inputProps}
				maxRows={maxRows ?? rowsMax}
				minRows={minRows}
				multiline={multiline}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				ref={ref}
				rows={rows}
				size={size}
				status={status}
				value={value}
				variant={variant}
				viewMode={viewMode}
			/>
		</InputWrapper>
	);
});
