import classnames from 'classnames';
import { capitalize } from '@fabric/utils';

import { CardFlex, CardSize } from './enums';

export function getSizeCSSClass(baseName = 'fab-Card', size: CardSize = CardSize.MEDIUM): string {
	return `${baseName}--size${capitalize(size)}`;
}

export function getClassName(
	baseName: string,
	{ flex, hasBottomGradient, size }: { flex?: CardFlex; hasBottomGradient?: boolean; size?: CardSize } = {}
): string {
	return classnames(baseName, getSizeCSSClass(baseName, size), {
		[`${baseName}--flexColumn`]: flex === CardFlex.COLUMN,
		[`${baseName}--flexRow`]: flex === CardFlex.ROW,
		[`${baseName}--withoutBottomGradient`]: !hasBottomGradient,
	});
}

export function hasCardSize(size: CardSize): boolean {
	return Object.values(CardSize).includes(size);
}
