import { makeStyles, createStyles, Theme } from '~mui';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { fabricFontSize, fabricLineHeight } from '@fabric/definitions/js/text';
import { dyeColors, ModeOptions } from '~styles';
import { TaskProgressBarProps } from './types';

export const useStyles = makeStyles(({ constructs, palette, spacing, mode }: Theme) => {
	return ifFeature(
		'encore',
		createStyles({
			root: {
				alignItems: 'center',
				display: 'flex',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			},
			note: {
				color: constructs.text.neutral.medium,
				fontSize: fabricFontSize('small'),
				lineHeight: fabricLineHeight('small'),
			},
			marginLeft: {
				marginRight: spacing(1.5),
			},
			marginRight: {
				marginLeft: spacing(1.5),
			},
			innerProgress: {},
			outerProgress: {},
		}),

		createStyles({
			root: {
				alignItems: 'center',
				display: 'flex',
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			},
			note: {
				color: palette.gray['700'],
				fontSize: fabricFontSize('small'),
				lineHeight: fabricLineHeight('small'),
			},
			marginLeft: {
				marginRight: spacing(1),
			},
			marginRight: {
				marginLeft: spacing(1),
			},
			outerProgress: {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : palette.gray['200'],
				borderRadius: '6px',
				height: '12px',
				overflow: 'hidden',
				width: '412px',
			},
			innerProgress: {
				background:
					mode === ModeOptions.Dye
						? dyeColors.main
						: `linear-gradient(to right, ${palette.primary.lightest}, ${palette.primary.lighter})`,
				height: '100%',
				minWidth: '6px',
				width: ({ current, total }: TaskProgressBarProps) => (current >= total ? '100%' : `${(100 * current) / total}%`),
			},
		})
	);
});
