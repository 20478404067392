import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ borderRadiuses, layout, palette }) => {
	return {
		root: {
			backgroundColor: palette.grey[50],
			borderRadius: borderRadiuses[600],
			boxSizing: 'border-box',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			minWidth: '100%',
			/*
			 * This is necessary to allow children to use `position: sticky`. Setting `overflow: hidden` will break `position: sticky`.
			 * The previous implementation used `contain: paint` but it prevents content from expanding the capsule without also using `width: fit-content`.
			 */
			overflow: 'clip',
			padding: `${layout.pageCapsule.padding}px`,
			'@media print': {
				background: 'none',
			},
		},
	};
});
