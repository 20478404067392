import { kebabCase } from 'lodash';
import { Layout, LayoutKey } from '../types';

export function fixLayoutKey(layoutKey: LayoutKey): LayoutKey {
	if (layoutKey === '.') {
		return layoutKey;
	}
	return kebabCase(layoutKey);
}

export function fixLayoutKeys(layout: Layout): Layout {
	return layout.map(arr =>
		arr.map(layoutKey => {
			return typeof layoutKey === 'string' ? fixLayoutKey(layoutKey) : '.';
		})
	);
}
