import { Variants } from 'framer-motion';
import { useMemo } from 'react';

export const usePointValueVariants = (dx: number, dy: number, yMax: number): Variants => {
	return useMemo(() => {
		return {
			initial: {
				dx,
				dy: yMax,
			},
			idle: {
				dx,
				dy,
			},
		};
	}, [dx, dy, yMax]);
};
