import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { widths } from '~definitions/json/sizes.json';
import { AutocompleteStyleProps } from './types';
import { makeStyles as makeStylesMUI, createStyles, Theme } from '~mui';

export const useStyles = makeStyles<AutocompleteStyleProps>()((theme, props) => {
	const { constructs, spacing } = theme;
	const { hasClearButton, hasPopupToggle, variant } = props;

	// Add gap between text and adornments via input padding
	const popupToggleWidth = hasPopupToggle ? parseInt(spacing(variant === 'form' ? 4 : 5), 10) : 0;
	const clearButtonWidth = hasClearButton ? parseInt(spacing(5), 10) : 0;
	const inputPaddingRight = Math.max(
		popupToggleWidth + clearButtonWidth + (hasClearButton ? 0 : parseInt(spacing(1.5), 10)),
		parseInt(spacing(2), 10)
	);

	return {
		root: {
			'& .MuiInputBase-root': {
				paddingLeft: variant === 'single' ? spacing(2) : spacing(1.5),
				paddingRight: inputPaddingRight,
			},
			'& .MuiAutocomplete-input': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'flex',
				height: '100%',
				right: 0,
			},
			'& .MuiAutocomplete-clearIndicator, & .MuiAutocomplete-popupIndicator': {
				background: 'unset',
				borderRadius: 'unset',
				display: 'grid',
				fill: constructs.icon.neutral.medium,
				margin: 0,
				placeContent: 'center',
				transitionDuration: '0.2s',
				transitionProperty: 'background, fill',
				'&:hover': {
					fill: constructs.icon.neutral.strong,
				},
			},
			'& .MuiAutocomplete-clearIndicator': {
				padding: 0,
				width: clearButtonWidth,
			},
			'& .MuiAutocomplete-popupIndicator': {
				padding: 0,
				paddingRight: spacing(0.5),
				width: popupToggleWidth,
				'&:before': {
					backgroundColor: constructs.border.neutral.weak,
					content: '""',
					height: `calc(100% - ${spacing(2)} - 2px)`, // -2px to account for border
					position: 'absolute',
					width: 1,
				},
			},
			// when open, prevent MUI flip of button and flip the icon instead
			'& .MuiAutocomplete-popupIndicatorOpen': {
				transform: 'none',
				'& svg': {
					transform: 'rotate(180deg)',
				},
			},
		},
	};
});

/* @startCleanup encore */
export const useJadeStyles = makeStylesMUI((theme: Theme) => {
	const { mixins, palette, typography, mode } = theme;

	return createStyles({
		defaultPopupIcon: {
			alignItems: 'center',
			display: 'flex',
			fill: palette.grey[700],
			justifyContent: 'center',
		},
		inputRoot: {
			backgroundColor: palette.background.default,
		},
		listbox: {
			padding: '4px 0',

			'& .MuiAutocomplete-option': {
				minHeight: ({ hasRenderOption }: AutocompleteStyleProps) => (hasRenderOption ? 0 : 32),
				// Override MUI specificity
				'&.Mui-focused.Mui-focused': {
					backgroundColor: palette.primary.main,
					color: palette.primary.contrastText,
				},
				'&[aria-selected="true"]:not(.Mui-focused)': {
					backgroundColor: 'inherit',
					color: 'inherit',
				},
			},
		},
		groupLabel: {
			backgroundColor: 'inherit',
			color: palette.gray[700],
			fontSize: typography.fabricFontSize('teenie'),
			lineHeight: typography.fabricLineHeight('medium'),
			padding: '0 10px',
		},
		groupUl: {
			'& .MuiAutocomplete-option': {
				padding: ({ hasRenderOption }: AutocompleteStyleProps) => (hasRenderOption ? 0 : '0 10px'),
			},
		},
		option: {},
		paper: {
			margin: '4px 0 4px 0',
			'&:not(:empty)': {
				backgroundColor: palette.common.white,
				border: `1px solid ${palette.primary.main}`,
				borderRadius: 2,
				boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`,
			},
		},
		popper: ({ popperZIndex, popupMinWidthFactor }) => ({
			minWidth: popupMinWidthFactor !== 100 ? (widths[String(popupMinWidthFactor)] as string) : undefined,
			zIndex: popperZIndex,
		}),
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'& .MuiIconButton-root': {
				height: 32,
				backgroundColor: palette.grey[100],
				color: palette.grey[700],
				marginRight: 0,
				width: 25,
				'&.Mui-disabled': {
					color: palette.grey[400],
				},
			},
			'& .MuiAutocomplete-input': {
				minWidth: 30,
				width: 0,
			},
			'& .MuiAutocomplete-inputRoot': {
				'&.Mui-disabled': {
					'& .MuiAutocomplete-tag:not(.MuiChip-root)': {
						color: palette.gray[500],
					},
				},
				'& .MuiAutocomplete-tag:not(.MuiChip-root)': {
					color: palette.gray[700],
				},
			},
			'& .MuiAutocomplete-clearIndicator': {
				backgroundColor: 'transparent',
				padding: 10,
			},
		},
	});
});
/* @endCleanup encore */
