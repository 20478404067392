import { useCallback, useEffect, useState } from 'react';
import { CurrencyItem } from '../types';

export const useSelectedCurrency = (currencyItem: CurrencyItem, onSelectCurrency: (currencyCode: string) => void) => {
	const [selectedCurrencyItem, setSelectedCurrencyItem] = useState<CurrencyItem>(currencyItem);

	useEffect(() => {
		setSelectedCurrencyItem(currencyItem);
	}, [currencyItem]);

	const handleCurrencyCodeSelected = useCallback(
		(selectedItem: { value: string; data: CurrencyItem }) => {
			setSelectedCurrencyItem(selectedItem.data);
			if (onSelectCurrency) {
				onSelectCurrency(selectedItem.data.code);
			}
		},
		[onSelectCurrency]
	);

	return {
		handleCurrencyCodeSelected,
		selectedCurrencyItem,
	};
};
