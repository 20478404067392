import { inRange } from 'lodash';
import trimCanvas from 'trim-canvas';

/**
 * Creates an image of the given canvas with trimmed whitespace
 *
 * @param {HTMLCanvasElement} canvas The canvas we want to copy
 * @returns {string} A data URL with an image in png format
 */
export function trimAndConvertToImage(canvas: HTMLCanvasElement): string {
	// We don't want trim-canvas to modify the original canvas, so we're making a copy
	const copy = document.createElement('canvas');
	copy.height = canvas.height;
	copy.width = canvas.width;

	const context = copy.getContext('2d', { willReadFrequently: true });
	if (context !== null) {
		context.drawImage(canvas, 0, 0);
	}

	(trimCanvas as (canvas: HTMLCanvasElement) => HTMLCanvasElement)(copy);

	return copy.toDataURL();
}

export const fitText = (fieldElement: HTMLElement, options: { maxFontSize: number; minFontSize: number }) => {
	const { maxFontSize, minFontSize } = options;

	if (textFits(fieldElement)) {
		growText(fieldElement, maxFontSize);
	} else {
		shrinkText(fieldElement, minFontSize);
	}
};

export const textFits = (fieldElement: HTMLElement) => {
	const { clientHeight, clientWidth, scrollWidth, scrollHeight } = fieldElement;
	return inRange(clientWidth, scrollWidth - 2, scrollWidth + 2) && inRange(clientHeight, scrollHeight - 2, scrollHeight + 2);
};

// Grows the font size of the field input so that the largest allowed font size is used
const growText = (fieldElement: HTMLElement, maxFontSize: number = MAX_FONT_SIZE) => {
	const fontSize = parseInt(fieldElement.style.fontSize, 10) || MIN_FONT_SIZE;
	const newFontSize = Math.ceil((1 + RESIZE_RATIO) * fontSize);

	if (maxFontSize !== -1 && newFontSize > maxFontSize) {
		return;
	}

	fieldElement.style.fontSize = `${newFontSize}px`;

	if (textFits(fieldElement)) {
		// Try to grow more
		growText(fieldElement, maxFontSize);
	} else {
		// Grew too big
		fieldElement.style.fontSize = `${fontSize}px`;
	}
};

//Shrinks the font size of the field input so that the text will fit inside of the input without causing it to scroll.
const shrinkText = (fieldElement: HTMLElement, minFontSize: number = MIN_FONT_SIZE) => {
	const fontSize = parseInt(fieldElement.style.fontSize, 10) || MIN_FONT_SIZE;
	const newFontSize = Math.ceil((1 - RESIZE_RATIO) * fontSize);

	fieldElement.style.fontSize = `${newFontSize}px`;

	if (newFontSize <= minFontSize) {
		return;
	}

	if (textFits(fieldElement) === false) {
		shrinkText(fieldElement, minFontSize);
	}
};

export const RESIZE_RATIO = 0.1;
export const MIN_FONT_SIZE = 45;
export const MAX_FONT_SIZE = 90;
