export function isAtTheBottom(contentElement, scrollableElement, offset) {
	const scrollTop = 'scrollTop' in scrollableElement ? scrollableElement.scrollTop : scrollableElement.pageYOffset;
	const scrollPosition = contentElement.scrollHeight - Math.ceil(scrollTop) - offset;
	return scrollPosition <= contentElement.clientHeight;
}

export function isBelowFold(element) {
	const rect = element.getBoundingClientRect();
	return rect.bottom > window.innerHeight;
}

export function isScrollable(element) {
	if (element.tagName.toLowerCase() === 'body') {
		return element.scrollHeight > window.innerHeight;
	}
	return element.scrollHeight > element.clientHeight;
}
