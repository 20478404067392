import { BamboohrLogo113x18 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import { Svg } from '~components/svg';
import { PrivacyAndTerms } from '../privacy-and-terms.react';

export function Footer(props) {
	const { renderFooterEtc = () => <PrivacyAndTerms /> } = props;

	return (
		<div className="fab-InterstitialFooter">
			<div className="fab-InterstitialFooter__extra">{renderFooterEtc()}</div>

			<a
				className="fab-InterstitialFooter__logo"
				href="https://www.bamboohr.com"
				rel="noopener external nofollow noreferrer"
				target="_blank"
			>
				{ifFeature(
					'encore',
					<Svg color="neutral-strong" height={17} image="bamboohr-logo" width={115} />,
					<BamboohrLogo113x18 title="BambooHR Logo" />
				)}
			</a>
		</div>
	);
}
