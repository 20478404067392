// eslint-disable-next-line no-use-before-define
import React from 'react';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';
import { AccordionHeaderProps } from './accordion.types';

const useStyles = makeStyles<Partial<AccordionHeaderProps>>()(({ constructs, palette, spacing, typography }, props) => ({
	root: {
		alignItems: 'start',
		fill: ifFeature('encore', constructs.icon.neutral.medium),
		minHeight: ifFeature('encore', '58px', '62px'),
		padding: ifFeature('encore', spacing(2), props.hideDescriptionOnCollapse ? spacing(2, 2, 0) : spacing(2)),
		'&': {
			boxSizing: 'border-box',
		},
		'&.MuiAccordionSummary-root.Mui-expanded': {
			fill: ifFeature('encore', constructs.icon.primary.strong),
			minHeight: ifFeature('encore', '58px', '62px'),
		},
		'&.MuiAccordionSummary-root.Mui-disabled': {
			opacity: 1,
		},
		'&.Mui-focusVisible': {
			backgroundColor: ifFeature('encore', 'transparent'),
			fill: ifFeature('encore', constructs.icon.primary.strong),
		},
	},
	content: {
		margin: 0,
		'&.Mui-expanded': {
			margin: 0,
		},
	},
	expandIconWrapper: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		/* @startCleanup encore */
		fill: ifFeature('encore', undefined, palette.grey[600]),
		/* @endCleanup encore */
		height: 'auto',
		margin: spacing(0, 0, 0, 2),
		minHeight: ifFeature('encore', typography.h5.lineHeight, typography.h4.lineHeight),
		padding: 0,
		/* @startCleanup encore */
		transformOrigin: ifFeature('encore', undefined, '4px 7px'),
		/* @endCleanup encore */
		'&.Mui-expanded': {
			transform: 'rotate(-90deg)',
		},
		'.MuiAccordionSummary-root:hover &': {
			fill: ifFeature('encore', constructs.icon.primary.strong),
		},
	},
	expanded: {},
	disabled: {},
}));

export function AccordionHeader({
	/* @startCleanup encore */
	classes: classesProp,
	/* @endCleanup encore */
	hideDescriptionOnCollapse,
	...rest
}: AccordionHeaderProps): JSX.Element {
	const { classes } = useStyles(
		{ hideDescriptionOnCollapse },
		/* @startCleanup encore */
		{ props: { classes: classesProp } }
		/* @endCleanup encore */
	);
	return <MuiAccordionSummary classes={classes} {...rest} />;
}
