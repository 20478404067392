import Ajax from '@bamboohr/utils/lib/ajax';
import { ICON_MANIFEST_URL } from './constants';

export function getIconManifest() {
	return Ajax.get(ICON_MANIFEST_URL)
		.then(res => res.data)
		.catch(() => {
			console.error('Unable to retrieve icon manifest');
			return {};
		});
}

export default function getIconNames() {
	return getIconManifest().then(icons => Object.keys(icons).sort());
}
