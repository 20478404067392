import { noop } from 'lodash';
import React, { PureComponent } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { hasChildren, hasItem, isSelected as isItemSelected, isSameItem } from '../../item';
import { AddOption } from '../add-option.react';
import { Option } from '../option.react';

import { ParentOption } from './parent-option';
import { ScrollContainer } from './scroll-container';
import { getGroupCSSClasses, isActionOnly, isDisabled } from './util';

import './list.scss';

export class List extends PureComponent {
	constructor(props) {
		super(props);
		this.menuRef = React.createRef();
	}

	static defaultProps = {
		isVisible: false,
		menuPlacement: {
			align: 'start',
			side: 'bottom',
		},
		onItemHover: noop,
		role: 'menu',
	};

	_getKey(item) {
		return item.key ?? item.value;
	}

	_isActive(item) {
		const { activeItem } = this.props;

		return !!activeItem && (isSameItem(activeItem)(item) || (hasChildren(item) && hasItem(item.items, activeItem)));
	}

	_isExpanded(item) {
		const { activeItem, isVisible } = this.props;

		const { expandedParent } = this.state;

		return isVisible && ((expandedParent && isSameItem(expandedParent)(item)) || hasItem(item.items, activeItem));
	}

	_renderAddOption(item) {
		const { biId, onActiveItemChange, onSelect } = this.props;

		return (
			<>
				{ifFeature('encore', item.anchor === 'bottom' && <div className="fab-MenuList__anchorDividerBottom"></div>)}
				<AddOption
					anchor={item.anchor}
					biId={biId}
					isActive={this._isActive(item)}
					key={this._getKey(item)}
					onHover={() => onActiveItemChange(item)}
					onSelect={() => onSelect(item)}
				>
					{item.text}
				</AddOption>
				{ifFeature('encore', item.anchor === 'top' && <div className="fab-MenuList__anchorDividerTop"></div>)}
			</>
		);
	}

	_renderGroup(item, inheritedSettings = {}) {
		inheritedSettings = {
			anchor: item.anchor,
			isActionOnly: isActionOnly(item, inheritedSettings),
			isDisabled: isDisabled(item, inheritedSettings),
			isGroupChild: true,
		};

		return (
			<div className={getGroupCSSClasses(item)} key={this._getKey(item)}>
				{ifFeature('encore', <div className="fab-MenuList__groupDivider" />)}
				{item.text && <div className="fab-MenuList__groupText">{item.text}</div>}
				<div className="fab-MenuList__groupChildren">
					{hasChildren(item) && this._renderItems(item.items, inheritedSettings)}
				</div>
				{item.anchor === 'top' && ifFeature('encore', <div className="fab-MenuList__anchorDivider" />)}
			</div>
		);
	}

	_renderItems(items, inheritedSettings = {}) {
		return items.map(item => {
			if (item.type === 'add') {
				return this._renderAddOption(item);
			}
			if (item.type === 'group') {
				return this._renderGroup(item, inheritedSettings);
			}
			if (hasChildren(item)) {
				return this._renderParentOption(item, inheritedSettings);
			}
			return this._renderOption(item, inheritedSettings);
		});
	}

	_renderParentOption(item, inheritedSettings) {
		const ancestors = [...(inheritedSettings.ancestors || []), item];

		const active = this._isActive(item);
		const expanded = this._isExpanded(item);

		return (
			<ParentOption
				active={active}
				expanded={expanded}
				item={item}
				key={this._getKey(item)}
				onCollapse={() => this.setState({ expandedParent: null })}
				onMouseEnter={() => this._handleParentItemMouseEnter(item)}
				renderOption={this._renderOption}
			>
				{this._renderItems(item.items, { ...inheritedSettings, ancestors, parentItem: item })}
			</ParentOption>
		);
	}

	_getItemIds(items) {
		return items.reduce((acc, item) => {
			let ids = `${acc} ${item.id}`;
			if (item.items && this._isExpanded(item)) {
				ids += this._getItemIds(item.items);
			}
			return ids;
		}, '');
	}

	_handleParentItemMouseEnter = item => {
		clearTimeout(this._parentTimer);

		this._parentTimer = setTimeout(() => {
			this.setState({ expandedParent: item });
		}, 200);
	};

	_renderOption = (item, inheritedSettings = {}) => {
		const { biId, canSelectMultiple, role, onActiveItemChange, onSelect, renderOptionContent, selectedItems, size } = this.props;
		const isParent = hasChildren(item);

		return (
			<>
				{ifFeature('encore', item.anchor === 'bottom' && <div className="fab-MenuList__anchorDividerBottom"></div>)}
				<Option
					anchor={inheritedSettings.anchor ?? ifFeature('encore', item.anchor)}
					biId={biId}
					canSelectMultiple={canSelectMultiple}
					isActionOnly={isActionOnly(item, inheritedSettings)}
					isActive={this._isActive(item)}
					isDisabled={isDisabled(item, inheritedSettings)}
					isExpanded={inheritedSettings.isExpanded}
					isGroupChild={!!inheritedSettings.isGroupChild}
					isParent={isParent}
					isSelected={isItemSelected(item, selectedItems)}
					item={item}
					key={this._getKey(item)}
					onHover={() => onActiveItemChange(item)}
					onSelect={() => onSelect(item)}
					parentItem={inheritedSettings.parentItem}
					renderContent={renderOptionContent}
					role={role === 'menu' ? 'menuitem' : 'option'}
					size={size}
				/>
				{ifFeature('encore', item.anchor === 'top' && <div className="fab-MenuList__anchorDividerTop"></div>)}
			</>
		);
	};

	state = {};

	componentDidMount() {
		window.setTimeout(() => {
			this.menuRef.current?.focus();
		}, 50);
	}

	componentDidUpdate(prevProps) {
		const { isVisible } = this.props;

		if (isVisible && isVisible !== prevProps.isVisible && this.menuRef.current) {
			window.setTimeout(() => {
				this.menuRef.current?.focus();
			}, 50);
		}
	}

	render() {
		const { activeItem, canSelectMultiple, items, menuId, role, placeholder } = this.props;

		const anchoredBottom = items.filter(item => item.anchor === 'bottom');
		const anchoredTop = items.filter(item => item.anchor === 'top');
		const nonAnchorItems = items.filter(item => item.anchor !== 'top' && item.anchor !== 'bottom');
		const itemsIds = this._getItemIds(items);

		const dataAttributes = {
			'aria-activedescendant': activeItem ? activeItem.id : null,
			'aria-multiselectable': role === 'listbox' ? canSelectMultiple : undefined,
			'aria-owns': itemsIds,
			id: menuId,
			ref: this.menuRef,
			role,
			tabIndex: -1,
		};

		return (
			<div {...dataAttributes} className="fab-MenuList">
				{anchoredTop.length > 0 && this._renderItems(anchoredTop)}

				{nonAnchorItems.length > 0 ? <ScrollContainer>{this._renderItems(nonAnchorItems)}</ScrollContainer> : null}

				{nonAnchorItems.length < 1 && placeholder ? <div className="fab-MenuList__placeholder">{placeholder}</div> : null}

				{anchoredBottom.length > 0 && this._renderItems(anchoredBottom)}
			</div>
		);
	}
}
