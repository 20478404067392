import { RefObject, useEffect, useRef } from 'react';

export function useScrollCollapse(
	{
		active = false,
		anchorRef,
		scrollContainerRef,
	}: { active?: boolean; anchorRef: RefObject<HTMLElement>; scrollContainerRef: RefObject<HTMLElement> },
	onCollapse: () => void
) {
	const onCollapseRef = useRef<typeof onCollapse>();
	onCollapseRef.current = onCollapse;

	useEffect(() => {
		const scrollContainerElement = scrollContainerRef.current;
		const anchorElement = anchorRef.current;

		if (!active || !anchorElement || !scrollContainerElement) {
			return;
		}

		const intersectionObserver = new IntersectionObserver(
			([entry]) => {
				const onCollapse = onCollapseRef.current;

				if (!entry.isIntersecting && onCollapse) {
					onCollapse();
				}
			},
			{ root: scrollContainerElement, threshold: 1 }
		);

		intersectionObserver.observe(anchorElement);

		return () => intersectionObserver.disconnect();
	}, [active, anchorRef, scrollContainerRef]);
}
