import { Link as MuiLink } from '@mui/material';
import React, { FC, PropsWithChildren, useContext } from 'react';

import { HeaderContext } from './header-context';
import { LinkProps } from './types';
import { useStyles } from './link.styles';

export const Link: FC<PropsWithChildren<LinkProps>> = props => {
	const { dark } = useContext(HeaderContext);
	const { active, children, classes: classesProp, ...rest } = props;
	const { classes } = useStyles({ active, dark }, { props: { classes: classesProp } });

	return (
		<MuiLink classes={classesProp} className={classes.root} data-text={children} {...rest}>
			{children}
		</MuiLink>
	);
};
