import { capitalize } from '@fabric/utils';
import React, { Children, cloneElement, FunctionComponent, PropsWithChildren } from 'react';

import { SelectableBoxGroupProps } from '@fabric/selectable-box';

export const SelectableBoxGroup: FunctionComponent<PropsWithChildren<PropsWithChildren<SelectableBoxGroupProps>>> = props => {
	const { ariaLabel, children, type } = props;
	const capitalizedType = capitalize(type);
	return (
		<div
			aria-label={ariaLabel}
			className={`fab-${capitalizedType}Group SelectableBoxWrapper`}
			role={type === 'radio' ? 'radiogroup' : undefined}
		>
			{Children.map(children, child => {
				return cloneElement(child as never, {
					type,
				});
			})}
		</div>
	);
};
