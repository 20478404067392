import classnames from 'classnames';
import { getConditionModifier } from '@fabric/utils/inputs';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function getCSSClasses({ isAttached, menuPlacement = {}, width }) {
	return classnames('fab-MenuVessel', {
		'fab-MenuVessel--attached': isAttached,
		'fab-MenuVessel--bottom': menuPlacement.side === 'bottom',
		'fab-MenuVessel--top': menuPlacement.side === 'top',
		...ifFeature('encore', { [`fab-MenuVessel--width${width}`]: width }),
	});
}

export function getInnerFacadeCSSClasses({ condition = '' } = {}) {
	return classnames('fab-MenuVessel__innerFacade', {
		[`fab-MenuVessel__innerFacade${getConditionModifier(condition)}`]: !!condition,
	});
}
