import React, { FC, memo, PropsWithChildren } from 'react';
import { InPageMessagingContainer } from './in-page-messaging-container';
import { InPageMessagingProps } from './types';
import { useStyles } from './in-page-messaging.styles';
import { getStack, getType, log } from './utils';
import { InPageMessagingType } from './enums';

export const InPageMessaging: FC<PropsWithChildren<InPageMessagingProps>> = memo(props => {
	const stack = getStack(props);

	const legacyType = getType(props);
	const { type } = props;
	const appliedType = type ? InPageMessagingType[type] : legacyType;

	const renderInPageMessaging = () => {
		return stack.map((notification, index) => {
			const { key = index, biId } = notification;
			return (
				<InPageMessagingContainer biId={biId} isStacked={stack.length > 1} {...notification} key={key} type={appliedType} />
			);
		});
	};

	const styles = useStyles({ type: appliedType });

	if (stack.length === 0) {
		log('No content was provided to the InPageMessaging component. Please check your implementation', 'error');
		return null;
	} else {
		return <div className={styles.classes.main}>{renderInPageMessaging()}</div>;
	}
});
