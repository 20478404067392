import { makeStyles } from '~styles';

export const useAccordionBodyStyles = makeStyles()(({ spacing }) => {
	return {
		root: {
			display: 'flex',
			padding: spacing(0, 2, 3),
		},
	};
});
