import { makeStyles } from '~styles';
import { InputStatus } from '~components/form/components/common/outlined-input/types/outlined-input-props';

export const useStyles = makeStyles<{ size?: string; status?: InputStatus; disabled?: boolean }>()((
	{ mixins, palette },
	{ size, status, disabled }
) => {
	const getDividerHeight = size => {
		switch (size) {
			case 'small':
			case 'teenie':
				return '16px';
			case 'medium':
				return '24px';
			case 'large':
			case 'biggie':
				return '32px';
			default:
				return '16px';
		}
	};

	const getButtonHeight = size => {
		switch (size) {
			case 'small':
			case 'teenie':
				return '30px';
			case 'medium':
				return '38px';
			case 'large':
			case 'biggie':
				return '46px';
			default:
				return '30px';
		}
	};

	return {
		divider: {
			'&&': {
				borderColor: disabled ? palette.gray[400] : mixins.fieldStatusColor(status, palette, palette.gray[400]),
				height: getDividerHeight(size),
			},
		},
		menuButton: {
			'&&': {
				'& .MuiButton-root': {
					height: getButtonHeight(size),
					'&:hover': {
						boxShadow: `0 0 0 1px ${mixins.fieldStatusColor(status, palette, palette.gray[400])}`,
					},
					'&:focus': {
						borderColor: mixins.fieldStatusColor(status, palette, palette.primary.light),
						boxShadow: `0 0 0 4px ${mixins.alpha(mixins.fieldStatusColor(status, palette, palette.primary.main), 0.1)}`,
						outlineColor: mixins.fieldStatusColor(status, palette, palette.primary.light),
					},
				},
			},
		},
		focusVisible: {
			'&&&': {
				boxShadow: `0 0 0 4px ${mixins.alpha(mixins.fieldStatusColor(status, palette, palette.primary.light), 0.1)}`,
				color: mixins.fieldStatusColor(status, palette, palette.primary.main),
				outlineColor: mixins.fieldStatusColor(status, palette, palette.primary.light),
				borderColor: mixins.fieldStatusColor(status, palette, palette.primary.light),
			},
		},
	};
});
