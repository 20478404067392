import React, { Component } from 'react';
import TextMask from 'react-text-mask';

/**
 * @deprecated use MaskedInput instead
 */
export class InputMasked extends Component {
	_handleBlur = event => {
		const { onBlur } = this.props;

		this.setState({ showMask: false });
		if (typeof onBlur === 'function') {
			onBlur(event);
		}
	};

	_handleFocus = event => {
		const { onFocus } = this.props;

		if (typeof onFocus === 'function') {
			onFocus(event);
		}

		window.setTimeout(() => {
			this.setState({ showMask: true });
		});
	};

	state = { showMask: false };

	render() {
		const { classes, showMask } = this.state;
		const variantStyle = classes || '';
		const _props = { ...this.props, className: `fab-TextInput ${variantStyle}` };

		return <TextMask {..._props} onBlur={this._handleBlur} onFocus={this._handleFocus} showMask={showMask} />;
	}
}
