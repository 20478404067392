import { getFileTypeFromExt } from '@bamboohr/utils/lib/file/file-type';
import { isBoolean } from 'lodash';
import React, { FC, PropsWithChildren } from 'react';
// @startCleanup encore
import iconNameToComponentMap from './icon-name-to-component-map';
import { FabricIcon } from '@fabric/icon';
// @endCleanup encore

import { FileIconProps, FileType } from './types';
import {
	fileIconLogger,
	getFileIconColor,
	getFileType,
	// @startCleanup encore
	getIconNameFromFileTypeAndSize,
	getIconFillFromFileType,
	// @endCleanup encore
	isUnknownIconType,
	isNilOrEmpty,
} from './utils';
import { useJadeStyles, useStyles } from './file-icon.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ICON_SIZE_MAP, FILE_TYPE_TO_NAME_MAP } from './file-icon.const';
import { useTheme } from '@mui/material';
import { BaseIconV2 } from '~components/icon-v2/base-icon-v2';

/**
 * Displays an icon given an e-signature flag, file name, or explicit type.
 *
 * **NOTE:**
 *
 * No individual prop is required, but if no values are set for the props "esig", "filename", or "type" the icon won't render and an error will appear in the console.
 * The order of precedence from greatest to least is "type", "esig", and then "filename".
 */
export const FileIcon: FC<PropsWithChildren<FileIconProps>> = ({
	// @startCleanup jade
	customFill = '',
	// @endCleanup jade
	esig = false,
	filename = '',
	size = 'teenie',
	type,
}) => {
	const { classes, cx } = useStyles({ size: ICON_SIZE_MAP[size] });
	const { palette } = useTheme();

	const fileType = getFileType({ esig, filename, type });

	// @startCleanup jade
	const { classes: jadeClasses } = useJadeStyles({ customFill });

	if (!esig && isNilOrEmpty(type) && isNilOrEmpty(filename)) {
		const errorMessage = '"filename" or "type" is required.';
		fileIconLogger.error(errorMessage);
		return null;
	}
	if (isNilOrEmpty(type) && isNilOrEmpty(filename) && !isBoolean(esig)) {
		const errorMessage = '"esig", "filename" or "type" is required.';
		fileIconLogger.error(errorMessage);
		return null;
	}

	let jadeFileType = filename as FileType;

	if (esig) {
		jadeFileType = 'esig';
	}
	if (!isNilOrEmpty(type) && !isUnknownIconType(type as string)) {
		jadeFileType = type as FileType;
	}
	if (!esig) {
		jadeFileType = getFileTypeFromExt(jadeFileType);
	}

	const jadeHasCustomFill = !isNilOrEmpty(customFill);
	const jadeIconName = getIconNameFromFileTypeAndSize(jadeFileType, size);
	const jadeIconFill = getIconFillFromFileType(jadeFileType);
	const JadeIcon = iconNameToComponentMap[jadeIconName];
	const jadeIconClassNames = cx(jadeClasses.root, {
		[jadeClasses[jadeIconFill]]: !jadeHasCustomFill,
		[jadeClasses.customFill]: jadeHasCustomFill,
	});
	// @endCleanup jade

	return ifFeature(
		'encore',
		<BaseIconV2
			className={classes.root}
			fill={getFileIconColor(fileType, palette)}
			name={FILE_TYPE_TO_NAME_MAP[fileType] || FILE_TYPE_TO_NAME_MAP.default}
		/>,
		<div className={jadeIconClassNames}>
			{JadeIcon ? <JadeIcon aria-hidden="true" /> : <FabricIcon aria-hidden="true" name={jadeIconName} />}
		</div>
	);
};

export default FileIcon;
