export const fastenData = <T, TData = unknown>(
	fields: Record<keyof T, TData> | Record<string, TData>[],
	nameParts: string[],
	data: TData
): Record<keyof T, TData> => {
	const [name, ...rest] = nameParts;

	if (rest.length === 0) {
		fields[name] = data;
	} else {
		if (!fields[name]) {
			fields[name] = isNaN(rest[0] as unknown as number) ? {} : [];
		}
		fields[name] = fastenData(fields[name], rest, data);
	}

	return fields as Record<keyof T, TData>;
};
