import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<{ hideLeftArrow: boolean; hideRightArrow: boolean }>()((theme, props) => {
	const { constructs, mixins } = theme;
	const { hideLeftArrow, hideRightArrow } = props;
	return {
		root: {
			'&:nth-child(2)': {
				borderLeft: ifFeature('encore', mixins.border(1, constructs.border.neutral.xWeak)),
			},
		},
		leftArrowButton: {
			visibility: hideLeftArrow ? 'hidden' : 'visible',
		},
		rightArrowButton: {
			visibility: hideRightArrow ? 'hidden' : 'visible',
		},
	};
});
