import { makeStyles } from '~styles';

export const useCalendarBaseStyles = makeStyles<void, 'weeks'>()((
	{ borderRadiuses, constructs, spacing, typography },
	props,
	classes
) => {
	return {
		// The <td> inside the month table
		day: {
			border: '4px solid transparent',
			boxSizing: 'content-box',
			color: constructs.text.neutral.xStrong,
			fontSize: typography.small.fontSize,
			fontWeight: typography.fontWeightMedium,
			height: spacing(5),
			width: spacing(5),
		},
		// Applied to dates outside the current month
		hidden: {
			visibility: 'hidden',
		},
		// Wrapper around the month table and its header
		month: {
			position: 'relative',
			// The first month when two are showing
			'&:has(+ &)': {
				paddingRight: spacing(0.5),
			},
			// The second month when two are showing
			'& + &': {
				paddingLeft: `calc(${spacing(0.5)} + 1px)`,
				// The vertical bars between months
				'&::before, &::after': {
					backgroundColor: constructs.border.neutral.xWeak,
					boxSizing: 'content-box',
					content: '""',
					left: `-${spacing(0.5)}`,
					position: 'absolute',
					width: '1px',
				},
				'&::before': {
					border: `solid ${constructs.surface.neutral.xxWeak}`,
					borderWidth: spacing(1, 0.5),
					height: '65px',
					top: 0,
				},
				'&::after': {
					bottom: 0,
					// the height of 5 week rows - 4px to account for the border
					height: '216px',
					margin: spacing(0.5),
				},
			},
			// Increase the height when one or both months has 6 weeks
			'&:has(tr:nth-child(6)) + &::after, & + &:has(tr:nth-child(6))::after': {
				height: '260px',
			},
		},
		// The month/year header
		monthCaption: {
			backgroundColor: constructs.surface.neutral.xxWeak,
			color: constructs.text.neutral.strong,
			fontSize: typography.h5.fontSize,
			fontWeight: typography.h5.fontWeight,
			lineHeight: typography.h5.lineHeight,
			padding: spacing(2, 2, 1.5),
		},
		// the month <table>
		monthGrid: {
			borderCollapse: 'collapse',
			borderRadius: `0 0 ${borderRadiuses[300]} ${borderRadiuses[300]}`,
			// compensate for the border
			marginTop: `-2px`,
			width: 'max-content',
			// Add space between the thead and tbody, since those can't use margins
			'& > thead::after': {
				content: '""',
				display: 'table-row',
				height: spacing(1),
			},
		},
		// wrapper around the month (or months, when there are two)
		months: {
			borderRadius: borderRadiuses[300],
			display: 'flex',
			overflow: 'hidden',
		},
		root: {
			backgroundColor: constructs.surface.neutral.inverted,
			borderRadius: borderRadiuses[400],
			padding: spacing(0.5),
			position: 'relative',
			width: 'fit-content',
		},
		// The <th> with the weekday name
		weekday: {
			// It's not a visible border - it blends in with the background-color of the thead row.
			border: `4px solid ${constructs.surface.neutral.xxWeak}`,
			fontSize: typography.xsmall?.fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: typography.xsmall?.lineHeight,
			paddingBottom: '2px',
		},
		// The <tr> inside <thead>
		weekdays: {
			backgroundColor: constructs.surface.neutral.xxWeak,
			color: constructs.text.neutral.weak,
		},
		weeks: {
			position: 'relative',
		},
	};
});
