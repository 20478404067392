import React, { ReactElement, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TransferListItemProps } from '../../types';
import { useHoverStyles } from './styles';
import { TransferListItem } from './transfer-list-item';

export const TransferListHoverItem = (props: TransferListItemProps): ReactElement => {
	const { classes } = useHoverStyles(props);
	const ref = useRef<HTMLDivElement>(null);
	const { listIndex, icon, reorderSubcategories, title, rightSide, onHover } = props;

	const [, drop] = useDrop({
		accept: 'Subcategory',
		hover(item: { name: string; firstIndex: number; lastIndex: number; index: number }, monitor) {
			if (!ref.current) return;
			const dragName = item.name;
			const dragIndex = item.index;
			const hoverIndex = listIndex;
			const hoverName = title;

			if (dragName === hoverName) return;

			const monitorOffset = monitor.getClientOffset();
			const hoverBoundingRect: { bottom: number; top: number } = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverActualY = monitorOffset !== null ? monitorOffset.y - hoverBoundingRect.top : 0;

			// if dragging down, continue only when hover is smaller than middle Y
			if (hoverIndex && dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

			// if dragging up, continue only when hover is bigger than middle Y
			if (hoverIndex && dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

			if (reorderSubcategories && typeof reorderSubcategories === 'function') {
				reorderSubcategories(dragName, hoverName);
			}
		},
	});

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'Subcategory',
		item: { name: title, index: listIndex },
		collect: monitor => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	}));

	drag(drop(ref));

	return (
		<div className={classes.hoverRoot} ref={rightSide ? ref : undefined} style={isDragging ? { opacity: 0 } : undefined}>
			<TransferListItem
				{...props}
				onHover={hovering => {
					if (onHover && typeof onHover === 'function') {
						onHover(hovering);
					}
				}}
				selected={false}
			>
				<div className={classes.icon}>{icon}</div>
			</TransferListItem>
		</div>
	);
};
