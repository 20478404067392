// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SearchBarProps } from '../../types';

import { useStyles } from './styles';
import { TextField } from '~components/text-field';
import { Nub } from '~components/nub';
import { IconV2 } from '~components/icon-v2';

export function SearchBar(props: SearchBarProps): ReactElement {
	const {
		biId,
		classes: classesOverride,
		icon,
		onChange = () => {
			return 1;
		},
		placeholder,
	} = props;

	const { classes, cx } = useStyles(undefined, { props: { classes: classesOverride } });
	const [isFocused, setIsFocused] = useState(false);

	const searchInputClasses = cx(classes.searchInput, {
		[classes.searchInputWithIcon]: !!icon,
	});

	const iconClasses = cx({
		[classes.searchInputIcon]: !isFocused,
		[classes.searchInputIconFocused]: isFocused,
	});

	return ifFeature(
		'encore',
		<TextField
			aria-label={placeholder}
			data-bi-id={biId}
			InputProps={{
				startAdornment: (
					<Nub position="start">
						<IconV2 color="neutral-strong" name="magnifying-glass-regular" size={12} />
					</Nub>
				),
			}}
			onBlur={() => {
				setIsFocused(false);
			}}
			onChange={event => {
				onChange(event.target.value);
			}}
			onFocus={() => {
				setIsFocused(true);
			}}
			placeholder={placeholder}
			size="small"
		/>,
		<div className={classes.root}>
			<input
				aria-label={placeholder}
				className={searchInputClasses}
				data-bi-id={biId}
				defaultValue=""
				onBlur={() => {
					setIsFocused(false);
				}}
				onChange={event => {
					onChange(event.target.value);
				}}
				onFocus={() => {
					setIsFocused(true);
				}}
				placeholder={placeholder}
				type="text"
			/>
			{icon && (
				<div className={classes.searchInputIconContainer}>
					<div className={iconClasses}>{icon}</div>
				</div>
			)}
		</div>
	);
}
