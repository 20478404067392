import { Box as MUIBox } from '@mui/material';
import React, { useEffect } from 'react';
import { CSSObject } from 'tss-react';
import { makeStyles } from '~styles';
import { validateStyles } from './layout-escape-hatch-validation';
import { GetStylesType, LayoutEscapeHatchProps } from './layout-escape-hatch.types';
import { formatBaseProps } from '~utils';

const useStyles = makeStyles<{ getStyles: GetStylesType }>()((theme, { getStyles }) => ({
	root: (typeof getStyles === 'function' ? getStyles(theme) : getStyles) as CSSObject, // coerce to CSSObject which is more inclusive than ours
}));

/**
 * ### Purpose
 * This component enables developers to create layouts that are currently unsupported by Fabric.
 * ### Restrictions
 * Any css unrelated to layouts is blocked, except for print styles.
 * ### Helper Functions
 * The `makeLayoutEscapeHatchStyles` function is available to support typings as a developer builds the getStyles function.
 *
 * The `createLayoutEscapeHatchStyles` function is available to use in conjunction with `makeLayoutEscapeHatchStyles` to better support typings on the return type of the getStyles function.
 * This is because of a shortcoming in TypeScript that does not correctly error on some return types.
 *
 * @prop {ReactNode} [children]
 * @prop {GetStylesType} [getStyles] A styles object or function for creating one using the theme.
 * @prop {ReasonString} [reason] The *reason* prop describes the reason for using this component.
 * If a reason is identified that is not found in the existing string union, please reach out to the FEA-Guild.
 * @prop {String} [unsupportedCSSFeature] The name of the feature unsupported by Fabric that does **not** exist in `reason`.
 * @prop {Ref<HTMLDivElement>} [containerRef] A ref to the container element.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function LayoutEscapeHatch<C extends keyof JSX.IntrinsicElements = 'div'>(props: LayoutEscapeHatchProps<C>) {
	const { children, getStyles, containerRef, reason, unsupportedCSSFeature, ...rest } = props as LayoutEscapeHatchProps<C> & {
		unsupportedCSSFeature: string;
	};
	const { classes } = useStyles({ getStyles });

	useEffect(() => {
		validateStyles(getStyles);
	}, [getStyles]);

	return (
		<MUIBox className={classes.root} ref={containerRef} {...formatBaseProps(rest as object)}>
			{children}
		</MUIBox>
	);
}
