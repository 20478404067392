import { BodyText, Flex, Headline, IconButton, Link } from '@bamboohr/fabric';
import { useChatInput } from 'src/hooks/useChatInput';
import config from '../config';
import FileUploader from './FileUploader';
import { LlmSelector } from './LlmSelector';
import { useLlmStore } from 'src/store';
import { User } from 'src/types/global';

type NewChatContainerProps = {
	currentUser: User;
	handleSubmit: (target: HTMLTextAreaElement | HTMLInputElement) => void;
	setModes: (modes: {}) => void;
	setForm: (form: any) => void;
};

const NewChatContainer = ({
	currentUser,
	handleSubmit,
	setModes,
	setForm,
}: NewChatContainerProps) => {
	const {
		textAreaRef,
		prompt,
		onChangeHandler,
		handleKeyDown,
		onSubmit,
		updatePrompt,
	} = useChatInput({ handleSubmit });
	const llmId = useLlmStore((state) => state.llmId);
	return (
		<Flex
			height={'100%'}
			flexDirection="column"
			alignItems="center"
			justifyContent={'space-between'}
			paddingY="30px"
		>
			<Flex
				alignItems="flex-start"
				flexDirection="column"
				justifyContent={'center'}
				paddingX="59px"
				width={'80%'}
				flex="1"
			>
				<Flex
					flexDirection="column"
					alignItems="flex-start"
					marginBottom={'55px'}
				>
					<Headline size="extra-large">Hi {currentUser.name}!</Headline>
					<Headline size="small" color="neutral-weak">
						What can I help you with today?
					</Headline>
				</Flex>

				<form className="intro-form">
					<Flex
						flexDirection={'column'}
						justifyContent={'center'}
						paddingX="16px"
						paddingY="12px"
						gap="8px"
					>
						<textarea
							name="inputPrompt"
							className="intro-form-textarea"
							ref={textAreaRef}
							rows={1}
							onKeyDown={handleKeyDown}
							autoFocus
							value={prompt}
							onChange={onChangeHandler}
							placeholder="Ask a question..."
						/>
						<Flex alignItems={'center'} justifyContent="space-between">
							<Flex
								alignItems="center"
								gap="20px"
								maxWidth="90%"
								flexWrap="wrap"
							>
								<LlmSelector setModes={setModes} />
								{llmId === 'mini-rag' ? (
									<FileUploader
										handleSubmit={handleSubmit}
										setForm={setForm}
										setInputPrompt={(prompt: string) => {
											updatePrompt(prompt);
										}}
									/>
								) : null}
							</Flex>

							<IconButton
								disabled={!prompt || !textAreaRef}
								icon="paper-plane-regular"
								variant="outlined"
								onClick={onSubmit}
							/>
						</Flex>
					</Flex>
				</form>
			</Flex>

			<Flex
				alignItems="start"
				flexDirection="column"
				paddingX="59px"
				maxWidth={'80%'}
				gap="4px"
			>
				<BodyText justify="start" size="extra-extra-small" color="neutral-weak">
					Remember that AI is still learning, and it uses large language models
					which are trained on only public, historical internet and may not be
					current, may hallucinate “facts”, and may reflect harmful biases.
				</BodyText>
				<BodyText justify="start" size="extra-extra-small" color="neutral-weak">
					<span style={{ fontWeight: 'bold' }}>Co-pilot, not auto-pilot!</span>{' '}
					You are responsible for evaluating and editing any content you will
					use. Always use AI for good and according to the{' '}
					<Link
						href={config.INTERNAL_POLICY_URL}
						target="_blank"
						rel="noreferrer"
					>
						policy.
					</Link>
				</BodyText>

				<BodyText size="extra-extra-small" color="neutral-weak">
					This sandbox is a work in progress. Any feedback is welcome via{' '}
					<Link href={config.SLACK_URL} target="_blank" rel="noreferrer">
						#working-with-ai{' '}
					</Link>
				</BodyText>
			</Flex>
		</Flex>
	);
};

export default NewChatContainer;
