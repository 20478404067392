// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { AxisBottom, AxisLeft, AxisRight, AxisTop, TickRendererProps } from '@visx/axis';
import { AxisProps, AxisCustomLabels, TickLabel } from '../types';
import { DefaultXAxisProps, DefaultYAxisProps } from './axis.domain';
import { useAxisStyles } from './axis.styles';

export const Axis: FC<PropsWithChildren<AxisProps>> = ({ bottom, left, right, top }) => {
	const { classes } = useAxisStyles({});
	const getTickLabelHorizontalAnchor = (props: TickLabel) => {
		if (props.tickLabelHorizontalAnchor) {
			return props.tickLabelHorizontalAnchor;
		}
		switch (props) {
			case left:
				return 'end';

			case right:
				return 'start';

			case top:
			case bottom:
			default:
				return 'middle';
		}
	};

	const getTickLabelVerticalAnchor = (props: TickLabel) => {
		if (props.tickLabelVerticalAnchor) {
			return props.tickLabelVerticalAnchor;
		}
		switch (props) {
			case top:
			case bottom:
				return 'end';

			case left:
			case right:
			default:
				return 'middle';
		}
	};

	const getTickLabelWidth = ({ tickLabelMaxWidth }: TickLabel) => tickLabelMaxWidth;
	const getTickLabelXOffset = ({ tickLabelXOffset }: TickLabel) => tickLabelXOffset;
	const getTickLabelYOffset = ({ tickLabelYOffset }: TickLabel) => tickLabelYOffset;

	const getTickLabelProps = props => () => ({
		dx: getTickLabelXOffset(props),
		dy: getTickLabelYOffset(props),
		textAnchor: getTickLabelHorizontalAnchor(props),
		verticalAnchor: getTickLabelVerticalAnchor(props),
		width: getTickLabelWidth(props),
		className: classes.tickLabel,
	});

	const getCustomLabel = ({ tickCustomLabel }: AxisCustomLabels) => {
		if (tickCustomLabel) {
			return {
				tickComponent: (props: TickRendererProps) => tickCustomLabel(props),
			};
		}
	};

	return (
		<>
			{bottom && (
				<g {...{ title: window.jQuery ? $.__('Bottom axis for chart') : 'Bottom axis for chart' }}>
					<AxisBottom
						tickLabelProps={getTickLabelProps(bottom)}
						{...bottom}
						{...getCustomLabel(bottom)}
						{...DefaultXAxisProps(bottom)}
						axisClassName={classes.root}
					/>
				</g>
			)}
			{left && (
				<g {...{ title: window.jQuery ? $.__('Left axis for chart') : 'Left axis for chart' }}>
					<AxisLeft
						tickLabelProps={getTickLabelProps(left)}
						{...left}
						{...getCustomLabel(left)}
						{...DefaultYAxisProps(left)}
						axisClassName={classes.root}
					/>
				</g>
			)}
			{right && (
				<g {...{ title: window.jQuery ? $.__('Right axis for chart') : 'Right axis for chart' }}>
					<AxisRight
						tickLabelProps={getTickLabelProps(right)}
						{...right}
						{...getCustomLabel(right)}
						{...DefaultYAxisProps(right)}
						axisClassName={classes.root}
					/>
				</g>
			)}
			{top && (
				<g {...{ title: window.jQuery ? $.__('Top axis for chart') : 'Top axis for chart' }}>
					<AxisTop
						tickLabelProps={getTickLabelProps(top)}
						{...top}
						{...getCustomLabel(top)}
						{...DefaultXAxisProps(top)}
						axisClassName={classes.root}
					/>
				</g>
			)}
		</>
	);
};
