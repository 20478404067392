import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { GlobalNavigationProps } from './global-navigation.types';

export const useStyles = makeStyles<Partial<GlobalNavigationProps>>()(theme => {
	const { mode, spacing } = theme;
	return {
		footer: {
			bottom: spacing(3),
			display: 'grid',
			gap: spacing(1.5),
			position: 'sticky',
		},
		links: {
			display: 'grid',
			gap: spacing(2),
			overflowX: 'hidden',
			overflowY: 'auto',
		},
		root: {
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(2),
			height: '100%',
			justifyContent: 'space-between',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: spacing(3),
			width: 'fit-content',
		},
	};
});
