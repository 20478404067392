import { DatePickerDate, DatePickerDateType, DatePickerUtils } from '../types';

interface LocaleInfo {
	monthAbbreviations: Array<string>; // [0] = January, [11] = December
	startOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 = Sunday, 6 = Saturday
	weekdayAbbreviations: Array<string>; // [0] = Sunday, [6] = Saturday
}

export function getLocaleInfo(code = 'us'): LocaleInfo {
	code = code.toLowerCase();

	if (code === 'de') {
		return {
			monthAbbreviations: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			startOfWeek: 1,
			weekdayAbbreviations: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
		};
	}

	if (code === 'es' || code === 'es-ar') {
		return {
			monthAbbreviations: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
			startOfWeek: 0,
			weekdayAbbreviations: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
		};
	}

	if (code === 'fr' || code === 'fr-ca') {
		return {
			monthAbbreviations: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
			startOfWeek: 0,
			weekdayAbbreviations: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
		};
	}

	if (code === 'ja') {
		return {
			monthAbbreviations: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
			startOfWeek: 0,
			weekdayAbbreviations: ['日', '月', '火', '水', '木', '金', '土'],
		};
	}

	if (code === 'nl') {
		return {
			monthAbbreviations: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
			startOfWeek: 1,
			weekdayAbbreviations: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
		};
	}

	if (code === 'pt' || code === 'pt-br') {
		return {
			monthAbbreviations: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
			startOfWeek: 0,
			weekdayAbbreviations: ['do', 'se', 'te', 'qu', 'qu', 'se', 'sá'],
		};
	}

	if (code === 'zh' || code === 'zh-cn') {
		return {
			monthAbbreviations: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
			startOfWeek: 0,
			weekdayAbbreviations: ['日', '一', '二', '三', '四', '五', '六'],
		};
	}

	return {
		monthAbbreviations: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		startOfWeek: 0,
		weekdayAbbreviations: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
	};
}

export function getWeekArray(utils: DatePickerUtils, date: DatePickerDate): undefined | Array<Array<DatePickerDate>> {
	const code = utils.locale as string;
	const localeInfo = getLocaleInfo(code);
	if (localeInfo) {
		const startOfMonth = utils.startOfMonth(date as DatePickerDateType);
		const startOfMonthWeekdayNumber = utils.getDiff(startOfMonth, utils.startOfWeek(startOfMonth), 'days') + 1;
		const daysInMonth = utils.getDaysInMonth(date as DatePickerDateType);
		const previousMonthCalendarDaysCount = (startOfMonthWeekdayNumber - localeInfo.startOfWeek + 7) % 7;
		const calendarStartDate = utils.addDays(startOfMonth, -previousMonthCalendarDaysCount);
		const numberOfCalendarWeeks = Math.ceil((previousMonthCalendarDaysCount + daysInMonth) / 7);

		const weeks: Array<Array<DatePickerDate>> = [];
		for (let w = 0; w < numberOfCalendarWeeks; w++) {
			const week: Array<DatePickerDate> = [];
			for (let d = 0; d < 7; d++) {
				week.push(utils.addDays(calendarStartDate, w * 7 + d));
			}
			weeks.push(week);
		}

		return weeks;
	}
}

export function getWeekdays(locale: string): Array<string> {
	const localeInfo = getLocaleInfo(locale);
	const weekdayAbbreviations = [...localeInfo.weekdayAbbreviations];
	const removedAbbreviations = weekdayAbbreviations.splice(0, localeInfo.startOfWeek);
	weekdayAbbreviations.push(...removedAbbreviations);
	return weekdayAbbreviations;
}
