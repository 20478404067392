export const DEFAULT_COLUMN_DEF = {
	resizable: true,
	suppressMovable: true,
	initialWidth: 190,
	editable: true,
};

export const DELETION_COLUMN_NAME = 'dataGridDelete';

export const ROW_ID_COLUMN_NAME = 'dataGridRowId';
