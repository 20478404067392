import { useTheme } from '@mui/styles';
import { useCallback } from 'react';
import { dyeColors, ModeOptions } from '~styles';

export interface UseChartColorsProps {
	length?: number;
	onGetColor?: (index: number) => string;
	reverse?: boolean;
}
export interface UseChartColors {
	colors: string[];
	getColor: (index: number) => string;
}

export const useChartColors = ({ length, onGetColor, reverse }: UseChartColorsProps = {}): UseChartColors => {
	const { palette, mode } = useTheme();
	const {
		charts: [...ogColors],
	} = palette;

	let colors = ogColors;

	if (mode === ModeOptions.Dye) {
		colors = [dyeColors.main];
		onGetColor = undefined;
	}

	if (length) {
		colors = colors.slice(0, length);
	}

	if (reverse) {
		colors.reverse();
	}

	const getColor = useCallback(
		index => {
			return colors[index % colors.length];
		},
		[colors]
	);

	const colorArray = colors.map((c, i) => (onGetColor ? onGetColor(i) : c));

	return {
		colors: colorArray,
		getColor: onGetColor || getColor,
	};
};
