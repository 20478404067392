import React, { Fragment } from 'react';

import { RoundedToggleProps } from '@fabric/toggle';
import { useStyles } from './rounded-toggle.styles';
import { makeUid } from '../../../../utils';
/**
 * ### `@fabric/toggle/Rounded`
 * @property {string}	[inputRef]		The Name to be passed as an Ref (for Validation)
 * @property {function}	[onChange]		Callback Function
 * @property {false}	[isChecked]		Is the Toggle Checked
 * @property {false}	[isDisabled]	Is the Toggle disabled
 * @property {false}	[isRequired]	Is the Toggle required
 * @property {string}	[name]			The Name for the Toggle
 * @property {string}	[size]			The Size of the Toggle
 *
 * @returns {JSX}
 * */
export const RoundedToggle = (props: RoundedToggleProps) => {
	const {
		ariaLabel,
		biId,
		id,
		inputRef,
		isChecked,
		isControlled = false,
		isDisabled,
		isRequired,
		name,
		onChange,
		size = 'regular',
	} = props;

	const uniqueId = makeUid();
	const toggleName = name || 'fab-roundedToggle';
	const toggleId = id || `fab-rounded-Toggle--${uniqueId}`;

	const { classes } = useStyles({ disabled: isDisabled, large: size === 'large' });

	return (
		<Fragment>
			<label className={classes.toggle} htmlFor={toggleId}>
				<input
					aria-checked={isChecked}
					aria-label={ariaLabel}
					checked={isControlled ? isChecked || false : undefined}
					className={classes.input}
					data-bi-id={biId}
					defaultChecked={isControlled ? undefined : isChecked || false}
					disabled={isDisabled}
					id={toggleId}
					name={toggleName}
					onChange={onChange}
					ref={inputRef}
					required={isRequired}
					role="switch"
					type="checkbox"
				/>
				<span className={classes.label}></span>
			</label>
		</Fragment>
	);
};
