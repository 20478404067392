import { ListItem } from '../types';

export function mergeSelectedItemLists(parentArray: ListItem[], selectedItems: ListItem[]): ListItem[] {
	if (!Array.isArray(parentArray)) {
		throw new Error(`Expected array for first argument but received: ${typeof parentArray}`);
	}
	let selectedItemsObject: Record<string, boolean | undefined> = {};

	if (Array.isArray(selectedItems)) {
		selectedItemsObject = selectedItems.reduce((listAsObject, item) => {
			listAsObject[item.rowKey] = item.selected;
			return listAsObject;
		}, {});
	} else {
		console.error(`Expected array for selectedItems but received: ${typeof selectedItems}`);
	}

	return parentArray.map((item): ListItem => {
		return { ...item, selected: selectedItemsObject[item.rowKey] === true };
	});
}
