import React, { forwardRef, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Fade, Paper, Popper, PopperProps, PopoverActions, Typography } from '@mui/material';
import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { X12x12 } from '@bamboohr/grim';
import { getAnchorEl } from '@fabric/utils';
import { focusFirstFocusableElement } from '@fabric/utils/focus-first-focusable-element';
import { Flex } from '~components/flex';
import { IconButton } from '~components/button';
import clsx from 'classnames';
import { merge } from 'lodash';
import { Instance, Placement } from '@popperjs/core';
import { TRANSITION_DURATION } from './constants';
import { useStyles } from './styles';
import { PopoverProps } from './types';

type RecordArray = Record<string, unknown>[];
const arrayToObject = (key: string, array: RecordArray) => {
	return array.reduce((acc, value) => {
		acc[value[key] as string] = value;
		return acc;
	}, {});
};

const mergeArrays = (key: string, original: RecordArray = [], overrides: RecordArray = []) => {
	const result = merge(arrayToObject(key, original), arrayToObject(key, overrides));
	return Object.values(result);
};

export const Popover = forwardRef<HTMLElement, PopoverProps>(
	(
		{
			action,
			anchorEl: anchorElProp,
			arrowFill,
			autofocus = true,
			children,
			classes = {},
			className,
			container,
			disableOuterClickOnClose = false,
			disablePortal = false,
			hasArrow = true,
			hasCloseButton = true,
			id,
			isRounded = true,
			modifiers: modifiersProp = [],
			hasMaxWidth = true,
			noPadding,
			offset = 11,
			onClose,
			onEnter,
			onEntered,
			onEntering,
			onExit,
			onExited,
			onExiting,
			onFlip,
			open,
			PaperProps = {},
			placement: placementProp = 'top',
			title,
			titleComponent = 'h4',
			variant,
			...rest
		},
		ref
	) => {
		/**
		 * Used for grabbing absolute max z-index after mounting
		 */
		const [shouldRerenderAfterMount, setShouldRerenderAfterMount] = useState(open);
		const [arrowRef, setArrowRef] = useState(null);
		const popperRef = useRef<PopperProps['popperRef']>(null);

		const popoverTitleId = `popover-title-${id || ''}`;
		const popoverChildrenId = `popover-children-${id || ''}`;

		const focusAnchor = useCallback((): void => {
			const anchor = getAnchorEl(anchorElProp) as HTMLElement;
			if (anchor) {
				anchor.focus();
			}
		}, [anchorElProp]);

		const zIndex = useMemo(() => {
			return open ? getMaxZIndex() + 10 : 0;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [open, shouldRerenderAfterMount]);
		const { classes: styles } = useStyles({ arrowFill, hasCloseButton, hasMaxWidth, noPadding, title, zIndex });

		const placementRef = useRef<Placement>(placementProp);

		useEffect(() => {
			if (shouldRerenderAfterMount) {
				setShouldRerenderAfterMount(false);
			}
		}, [shouldRerenderAfterMount]);

		useEffect(() => {
			const listener = (e: KeyboardEvent) => {
				if (e.key === 'Escape' && open && typeof onClose === 'function') {
					focusAnchor();
					onClose();
				}
			};
			document.addEventListener('keyup', listener);
			return () => document.removeEventListener('keyup', listener);
		}, [focusAnchor, onClose, open]);

		const refCurrent = typeof ref !== 'function' ? ref?.current : null;
		useEffect(() => {
			if (autofocus && open && refCurrent) {
				focusFirstFocusableElement(refCurrent);
			}
		}, [autofocus, open, refCurrent]);

		useEffect(() => {
			const anchor = getAnchorEl(anchorElProp) as HTMLElement;
			if (anchor) {
				anchor.setAttribute('aria-expanded', open.toString());
			}
		}, [anchorElProp, open]);

		useEffect(() => {
			const anchor = getAnchorEl(anchorElProp) as HTMLElement;
			if (anchor) {
				anchor.setAttribute('aria-haspopup', 'dialog');
				if (id) {
					anchor.setAttribute('aria-controls', id);
				}
			}
		}, [anchorElProp, id]);

		useImperativeHandle(
			action,
			() =>
				(open
					? {
							updatePosition: () => {
								if (popperRef?.current) {
									const popperInstance = popperRef.current as unknown as { update?: () => void };
									if (popperInstance.update) {
										popperInstance.update();
									}
								}
							},
						}
					: null) as PopoverActions,
			[open, popperRef]
		);

		useEffect(() => {
			if (!getAnchorEl(anchorElProp) && anchorElProp !== null) {
				console.error('Invalid `anchorEl` prop supplied to Popover. Must be a ReactElement.');
			}
		}, [anchorElProp]);

		if (anchorElProp === null) {
			return null;
		}

		const defaultModifiers = [
			{
				name: 'arrow',
				enabled: hasArrow,
				options: {
					element: arrowRef,
				},
			},
			{
				name: 'flip',
				enabled: true,
			},
			{
				name: 'preventOverflow',
				enabled: ifFeature('encore', true, false),
			},
			{
				name: 'hide',
				enabled: false,
			},
			{
				name: 'onFlip',
				enabled: true,
				phase: 'afterWrite',
				fn({ state }: Instance) {
					if (onFlip && state.placement !== placementRef.current) {
						placementRef.current = state.placement;
						onFlip(state.placement);
					}
				},
			},
			{
				name: 'offset',
				options: {
					offset: [0, offset],
				},
			},
		];

		const popoverElement = ifFeature(
			'encore',
			<div className={styles.popover}>
				{(title || hasCloseButton) && (
					<Flex
						gap={1.5}
						justifyContent="space-between"
						paddingBottom={1}
						paddingLeft={2.5}
						paddingRight={1.5}
						paddingTop={2.5}
					>
						{title && (
							<Typography
								className={styles.title}
								color="primary"
								component={titleComponent}
								id={popoverTitleId}
								variant="h4"
							>
								{title}
							</Typography>
						)}
						{hasCloseButton && (
							<div className={styles.closeButton}>
								<IconButton
									ariaLabel={window.jQuery ? $.__('Close popover') : 'Close popover'}
									color="secondary"
									icon="xmark-solid"
									onClick={() => {
										if (typeof onClose === 'function') {
											focusAnchor();
											onClose();
										}
									}}
									size="teenie"
									type="button"
									variant="outlined"
								/>
							</div>
						)}
					</Flex>
				)}
				<div className={styles.content} id={popoverChildrenId}>
					{children && typeof children === 'string' ? <p>{children}</p> : children}
				</div>
				{/* Autofocus breaks if the arrow ref is missing so we'll show an empty span even though Encore doesn't have arrows */}
				<span ref={setArrowRef as unknown as RefObject<HTMLSpanElement>} />
			</div>,
			<div className={clsx(styles.popover, !hasMaxWidth && styles.popoverNoMaxWidth, classes.popover)}>
				<Paper
					classes={{
						root: clsx(
							styles.paper,
							{ [styles.paperBordered]: variant === 'bordered' },
							{ [styles.paperFocused]: variant === 'focused' },
							classes.paper
						),
					}}
					elevation={0}
					square={!isRounded}
					{...PaperProps}
				>
					<div className={styles.content}>
						<div className={styles.titleContainer}>
							{title && (
								<Typography
									classes={{ root: clsx(styles.title, classes.title) }}
									color="primary"
									component={titleComponent}
									id={popoverTitleId}
									variant="h4"
								>
									{title}
								</Typography>
							)}
							{hasCloseButton && (
								<button
									aria-label={window.jQuery ? $.__('Close popover') : 'Close popover'}
									className={clsx(styles.closeButton, classes.closeButton)}
									onClick={() => {
										if (typeof onClose === 'function') {
											focusAnchor();
											onClose();
										}
									}}
									type="button"
								>
									<X12x12 aria-hidden={true} />
								</button>
							)}
						</div>
						<div className={clsx(styles.constraint, classes.constraint)}>
							<div id={popoverChildrenId}>{children && typeof children === 'string' ? <p>{children}</p> : children}</div>
						</div>
					</div>
					{/* Autofocus breaks if the arrow ref is missing so we'll show an empty span if hasArrow is set to false */}
					<span
						className={
							hasArrow
								? clsx(
										styles.arrow,
										{ [styles.arrowBordered]: variant === 'bordered' },
										{ [styles.arrowFocused]: variant === 'focused' },
										classes.arrow
									)
								: undefined
						}
						ref={setArrowRef as unknown as RefObject<HTMLSpanElement>}
					/>
				</Paper>
			</div>
		);

		return (
			<Popper
				anchorEl={anchorElProp}
				aria-describedby={`${popoverTitleId} ${popoverChildrenId}`}
				aria-labelledby={popoverTitleId}
				className={clsx(className, styles.root, classes.root)}
				container={container}
				disablePortal={disablePortal}
				id={id}
				open={open}
				placement={placementProp}
				ref={ref as RefObject<HTMLDivElement>}
				role="dialog"
				transition={true}
				{...rest}
				modifiers={mergeArrays('name', defaultModifiers, modifiersProp) as PopperProps['modifiers']}
				popperRef={popperRef as PopperProps['popperRef']}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener
						onClickAway={() => {
							if (typeof onClose === 'function' && !disableOuterClickOnClose) {
								focusAnchor();
								onClose();
							}
						}}
					>
						<Fade
							onEnter={onEnter}
							onEntered={onEntered}
							onEntering={onEntering}
							onExit={onExit}
							onExited={onExited}
							onExiting={onExiting}
							{...TransitionProps}
							timeout={TRANSITION_DURATION}
						>
							{popoverElement}
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		);
	}
);
