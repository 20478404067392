import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ palette }) => {
	return {
		defaultHeader: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', palette.common.white, undefined),
			borderTopLeftRadius: ifFeature('encore', '12px', undefined),
			borderTopRightRadius: ifFeature('encore', '12px', undefined),
			display: 'flex',
			width: '100%',
			justifyContent: ifFeature('encore', 'space-between', undefined),
		},
		defaultHeaderCloseButton: {
			padding: ifFeature('encore', '16px 20px 0 0', undefined),
		},
	};
});
