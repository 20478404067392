import { Traverser } from '../../types';

export function traverseWithKey(traverser: Traverser, key: string): void {
	switch (key) {
		case 'ArrowDown':
			traverser.next();
			break;
		case 'ArrowUp':
			traverser.prev();
			break;
		case 'ArrowRight':
			traverser.dive();
			break;
		case 'ArrowLeft':
			traverser.surface();
			break;
		case 'End':
		case 'PageDown':
			traverser.last();
			break;
		case 'Home':
		case 'PageUp':
			traverser.first();
			break;
		default:
			break;
	}
}
