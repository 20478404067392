import { Button, Flex } from '@bamboohr/fabric';

interface NewChatProps {
	createNewChat: () => void;
}

const NewChat = ({ createNewChat }: NewChatProps) => {
	return (
		<Flex marginY="24px" gap="12px">
			<Button
				alignContent="left"
				color="secondary"
				startIcon="circle-plus-regular"
				onClick={createNewChat}
				width={100}
			>
				New Chat
			</Button>
		</Flex>
	);
};

export default NewChat;
