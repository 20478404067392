/* eslint-disable sort-keys */
import { makeStyles } from '~styles';
import { getEncoreValue } from '~utils';
import { StyledBoxProps } from './styled-box.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<StyledBoxProps>()((theme, props) => {
	const {
		borderRadiuses,
		constructs: { border, surface },
		mixins,
		palette,
	} = theme;
	const { backgroundColor, borderColor, borderRadius, borderStyle, boxShadow, objectFit, v2 } = props;

	const backgroundStatusColors = {
		'primary-weak': surface.primary.weak,
		'primary-medium': surface.primary.medium,
		'primary-strong': surface.primary.strong,
		'success-weak': surface.success.weak,
		'success-medium': surface.success.medium,
		'success-strong': surface.success.strong,
		'warning-weak': surface.warning.weak,
		'warning-medium': surface.warning.medium,
		'warning-strong': surface.warning.strong,
		'error-weak': surface.error.weak,
		'error-medium': surface.error.medium,
		'error-strong': surface.error.strong,
		'info-weak': surface.info.weak,
		'info-medium': surface.info.medium,
		'info-strong': surface.info.strong,
		'discovery-weak': surface.discovery.weak,
		'discovery-medium': surface.discovery.medium,
		'discovery-strong': surface.discovery.strong,
		'notice-strong': surface.notice.strong,
	};

	const backgroundColors = {
		none: 'transparent',
		...ifFeature(
			'encore',
			{
				...backgroundStatusColors,
				'neutral-extra-extra-weak': surface.neutral.xxWeak,
				'neutral-extra-weak': surface.neutral.xWeak,
				...(v2
					? {
							'neutral-weak': surface.neutral.weak,
							'neutral-medium': surface.neutral.medium,
							'neutral-strong': surface.neutral.strong,
							'neutral-extra-strong': surface.neutral.xStrong,
						}
					: {
							'neutral-weak': surface.neutral.forcedWhite,
							'neutral-medium': surface.neutral.xxWeak,
							'neutral-strong': surface.neutral.xxWeak,
							'neutral-extra-strong': surface.neutral.xxWeak,
						}),
				'neutral-extra-extra-strong': surface.neutral.xxStrong,
				'neutral-inverted': surface.neutral.inverted,
				'neutral-forced-white': surface.neutral.forcedWhite,
			},
			{
				...({} as typeof backgroundStatusColors),
				'neutral-extra-extra-weak': palette.gray[50],
				'neutral-extra-weak': palette.gray[100],
				...(v2
					? {
							'neutral-weak': palette.gray[200],
							'neutral-medium': palette.gray[200],
							'neutral-strong': palette.gray[600],
							'neutral-extra-strong': palette.gray[800],
						}
					: {
							'neutral-weak': palette.background.default,
							'neutral-medium': palette.gray[100],
							'neutral-strong': palette.gray[200],
							'neutral-extra-strong': palette.gray[300],
						}),

				'neutral-extra-extra-strong': palette.gray[900],
				'neutral-inverted': palette.common.black,
				'neutral-forced-white': palette.common.white,
			}
		),
	};

	const borderStatusColors = {
		'primary-weak': border.primary.weak,
		'primary-medium': border.primary.medium,
		'primary-strong': border.primary.strong,
		'success-weak': border.success.weak,
		'success-medium': border.success.medium,
		'success-strong': border.success.strong,
		'warning-weak': border.warning.weak,
		'warning-medium': border.warning.medium,
		'warning-strong': border.warning.strong,
		'error-weak': border.error.weak,
		'error-medium': border.error.medium,
		'error-strong': border.error.strong,
		'info-weak': border.info.weak,
		'info-medium': border.info.medium,
		'info-strong': border.info.strong,
		'discovery-weak': border.discovery.weak,
		'discovery-medium': border.discovery.medium,
		'discovery-strong': border.discovery.strong,
	};

	const borderColors = {
		none: 'transparent',
		...ifFeature(
			'encore',
			{
				...borderStatusColors,
				'neutral-extra-extra-weak': border.neutral.xxWeak,
				'neutral-extra-weak': border.neutral.xWeak,
				...(v2
					? {
							'neutral-weak': border.neutral.weak,
							'neutral-medium': border.neutral.medium,
							'neutral-strong': border.neutral.strong,
							'neutral-extra-strong': border.neutral.xStrong,
						}
					: {
							'neutral-weak': border.neutral.xWeak,
							'neutral-medium': border.neutral.medium,
							'neutral-strong': border.neutral.medium,
							'neutral-extra-strong': border.neutral.medium,
						}),

				'neutral-inverted': border.neutral.inverted,
				'neutral-forced-white': border.neutral.forcedWhite,
			},
			{
				...({} as typeof borderStatusColors),
				'neutral-extra-extra-weak': palette.gray[100],
				'neutral-extra-weak': palette.gray[200],
				...(v2
					? {
							'neutral-weak': palette.gray[300],
							'neutral-medium': palette.gray[400],
							'neutral-strong': palette.gray[500],
							'neutral-extra-strong': palette.gray[700],
						}
					: {
							'neutral-weak': palette.gray[200],
							'neutral-medium': palette.gray[300],
							'neutral-strong': palette.gray[400],
							'neutral-extra-strong': palette.gray[500],
						}),
				'neutral-inverted': palette.common.black,
				'neutral-forced-white': palette.common.white,
			}
		),
	};

	return {
		root: {
			backgroundColor: getEncoreValue(backgroundColor, backgroundColors),
			borderColor: getEncoreValue(borderColor, borderColors),
			borderRadius: getEncoreValue(borderRadius, {
				none: 'unset',
				small: ifFeature('encore', borderRadiuses[200], 2),
				medium: ifFeature('encore', borderRadiuses[300], 8),
				large: ifFeature('encore', borderRadiuses[400], 16),
				full: ifFeature('encore', borderRadiuses[1000], 0),
			}),
			boxShadow: getEncoreValue(boxShadow, {
				none: 'unset',
				small: ifFeature('encore', mixins.boxShadow('300', surface.neutral.xxStrong), '0px 1px 0px 0px rgba(0, 0, 0, 0.05)'),
				medium: ifFeature('encore', mixins.boxShadow('300', surface.neutral.xxStrong), '0px 1px 4px 0px rgba(0, 0, 0, 0.15)'),
				large: ifFeature('encore', mixins.boxShadow('500', surface.neutral.xxStrong), '0px 2px 6px 0px rgba(0, 0, 0, 0.05)'),
				'extra-large': ifFeature(
					'encore',
					mixins.boxShadow('500', surface.neutral.xxStrong),
					'0px 8px 14px 3px rgba(0, 0, 0, 0.05)'
				),
			}),
			borderStyle: ifFeature('encore', borderStyle === 'dashed' ? 'none' : borderStyle, borderStyle),
			borderWidth: 1,
			objectFit,
		},
	};
});
