import { AxisScale, SharedAxisProps } from '@visx/axis';
import { AxisCustomLabels, LineChartProps } from '@fabric/charts';
import { getSVGTextRect } from '@fabric/utils/charts';
import { clsx } from '~styles';
import { useAxisStyles } from './axis.styles';

/**
 * Predict what the axis max value is
 * Currently, not predicting it correctly, but we're more concerned with the number string length
 * to properly give the correct amount of width for the axis
 * @param num takes a number
 * @returns number that should be max scale value
 */
function predictedMaxScaleValue(num: number): number {
	if (num === 0) {
		return 1;
	}
	const strNum = num.toString();
	if (num >= 10 && strNum[0] === '9' && parseFloat(strNum.substring(1, strNum.length)) > 0) {
		const flooredStrNum = Math.floor(num).toString();
		return Math.pow(10, flooredStrNum.length);
	}

	return Math.ceil(num);
}

interface TickFormatValue {
	value: number;
	index: number;
}
const defaultYTickFormat = (value: number, index: number, values: TickFormatValue[]): string => {
	const maxValue = values?.length ? values[values.length - 1].value : value;

	if (value === 0) {
		return '0';
	}
	// only show the decimal if the maxValue is less than 10
	if (maxValue < 10) {
		return (Math.round(value * 10) / 10).toFixed(1);
	}

	return value.toLocaleString();
};

const DefaultAxisProps = (
	axisProps?: boolean | Omit<SharedAxisProps<AxisScale> & AxisCustomLabels, 'scale'> | null
): Omit<SharedAxisProps<AxisScale>, 'scale'> => {
	if (axisProps && typeof axisProps === 'object') {
		return {
			tickClassName: axisProps.tickClassName ?? '',
			tickLength: axisProps.tickLength ?? 8,
		};
	}

	return {
		tickLength: 8,
	};
};

export const DefaultYAxisProps = (
	axisProps?: boolean | Omit<SharedAxisProps<AxisScale> & AxisCustomLabels, 'scale'> | null
): Omit<SharedAxisProps<AxisScale>, 'scale'> => {
	if (axisProps && typeof axisProps === 'object') {
		return {
			tickFormat: axisProps.tickFormat ?? defaultYTickFormat,
			...DefaultAxisProps(axisProps),
		};
	}
	return {
		...DefaultAxisProps(),
	};
};

export const DefaultXAxisProps = (
	axisProps?: boolean | Omit<SharedAxisProps<AxisScale> & AxisCustomLabels, 'scale'> | null
): Omit<SharedAxisProps<AxisScale>, 'scale'> => {
	if (axisProps && typeof axisProps === 'object') {
		return {
			...DefaultAxisProps(axisProps),
		};
	}
	return {
		...DefaultAxisProps(),
	};
};

export const getAxisLeftWidth = (
	axisLeft: LineChartProps<Record<string, number | string>>['axisLeft'],
	yDomainMax: number,
	{ classes }: ReturnType<typeof useAxisStyles>
): number => {
	let width = 0;

	const { tickClassName, tickFormat, tickLength } = DefaultYAxisProps(axisLeft);
	width += tickLength ?? 0;

	if (!axisLeft) {
		return width;
	}

	const maxScaleValue = predictedMaxScaleValue(yDomainMax);

	const textToMeasure = tickFormat ? tickFormat(maxScaleValue, 0, []) : maxScaleValue.toString();

	const textBBox = getSVGTextRect(textToMeasure ?? '0', { className: clsx(classes.tickLabel, classes.tick, tickClassName) });

	width += textBBox?.width ?? 0;

	return width;
};

export const getAxisRightWidth = (
	axisRight: LineChartProps<Record<string, number | string>>['axisRight'],
	yDomainMax: number,
	{ classes }: ReturnType<typeof useAxisStyles>
): number => {
	let width = 0;

	const { tickClassName, tickFormat, tickLength } = DefaultYAxisProps(axisRight);
	width += tickLength ?? 0;

	if (!axisRight) {
		return width;
	}

	const maxScaleValue = predictedMaxScaleValue(yDomainMax);

	const textToMeasure = tickFormat ? tickFormat(maxScaleValue, 0, []) : maxScaleValue.toString();

	const textBBox = getSVGTextRect(textToMeasure ?? '0', { className: clsx(classes.tickLabel, classes.tick, tickClassName) });

	width += textBBox?.width ?? 0;

	return width;
};

export const getAxisTopHeight = (
	axisTop: LineChartProps<Record<string, number | string>>['axisTop'],
	{ classes }: ReturnType<typeof useAxisStyles>
): number => {
	let height = 0;

	const { tickClassName, tickLength } = DefaultXAxisProps(axisTop);

	// {A|g} tall characters
	const textBBox = getSVGTextRect('{A|g}', { className: clsx(classes.tickLabel, classes.tick, tickClassName) });

	if (!axisTop) {
		return textBBox ? Math.ceil(textBBox.height / 2) : 0;
	}

	height += tickLength ?? 0;
	height += textBBox ? textBBox.height : 0;

	return height;
};

export const getAxisBottomHeight = (
	axisBottom: LineChartProps<Record<string, number | string>>['axisBottom'],
	{ classes }: ReturnType<typeof useAxisStyles>
): number => {
	let height = 0;

	const { tickClassName, tickLength } = DefaultXAxisProps(axisBottom);

	// {A|g} tall characters
	const textBBox = getSVGTextRect('{A|g}', { className: clsx(classes.tickLabel, classes.tick, tickClassName) });

	if (!axisBottom) {
		return textBBox ? Math.ceil(textBBox.height / 2) : 0;
	}

	height += tickLength ?? 0;
	height += textBBox ? textBBox.height : 0;

	return height;
};
