import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { HoverSettings } from './types';
import { HoverWrapper } from '../hover-wrapper';
import { TextButton } from '~components/button';

export interface HoverLinkProps {
	hover: HoverSettings;
	children: ReactElement;
}

export const HoverLink: FunctionComponent<PropsWithChildren<HoverLinkProps>> = props => {
	const { children, hover } = props;

	return (
		<HoverWrapper component={children} hasIcon={false}>
			<TextButton href={hover.link}>{window.jQuery ? $.__('View') : 'View'}</TextButton>
		</HoverWrapper>
	);
};
