import { PropsWithChildren } from 'react';
import { IconV2Name } from '~components/icon-v2';

export enum PillType {
	Brand = 'Brand',
	Discovery = 'Discovery',
	Error = 'Error',
	Greenhouse = 'Greenhouse',
	Inactive = 'Inactive',
	Info = 'Info',
	Inverted = 'Inverted',
	Neutral = 'Neutral',
	Warning = 'Warning',
	Success = 'Success',
}

export interface PillBaseProps {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * Class names applied to the root element.
	 */
	className?: string;
	/**
	 * @deprecated classes should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * Override or extend the styles applied to the component.
	 */
	classes?: {
		/**
		 * Styles applied to the root element.
		 */
		root?: string;
	};

	/**
	 * Accepts an IconV2Name that will be the icon displayed to the left of the pill content. The icon will only be displayed if it ends with '-solid'.
	 */
	icon?: IconV2Name;

	/**
	 * Muted pill variant which correspondes to lighter pill, icon, and text color
	 */
	muted?: boolean;

	/**
	 * Preset pill type which corresponds to a pill color
	 */
	type?: PillType;
}

export type PillProps = PropsWithChildren<PillBaseProps>;
