import { BarList } from '../types';

/**
 * Computes the dimensions of a bar stack as it's not given
 * @param barStack the bar stack to measure
 * @returns the dimensions
 */
export const getBarStackDimensions = <Datum = unknown>(barStack: BarList<Datum>) => {
	const maxOrDefined = (a: number | undefined, b: number) => {
		return Math.max(a ?? b, b);
	};
	const minOrDefined = (a: number | undefined, b: number) => {
		return Math.min(a ?? b, b);
	};
	const {
		x = 0,
		y = 0,
		right = 0,
		bottom = 0,
	} = barStack.bars.reduce<{ x?: number; y?: number; right?: number; bottom?: number }>(
		({ x, y, right, bottom }, { x: barX = 0, y: barY = 0, width = 0, height = 0 }) => {
			return {
				x: minOrDefined(x, barX),
				y: minOrDefined(y, barY),
				right: maxOrDefined(right, barX + width),
				bottom: maxOrDefined(bottom, barY + height),
			};
		},
		{}
	);
	return {
		x,
		y,
		width: right - x,
		height: bottom - y,
	};
};
