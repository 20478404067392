import { TextField } from '~components/text-field';
import { Box } from '@mui/material';
import React from 'react';
import { RendererProps } from '../types';

export function PercentageFieldRenderer({ element }: RendererProps) {
	const { note } = element;

	return (
		<TextField
			{...element}
			note={
				<>
					<Box
						sx={{
							color: 'gray.700',
							display: 'inline-block',
							fontWeight: 'fontWeightRegular',
							marginLeft: 0.5,
							marginRight: 1.5,
							verticalAlign: 'baseline',
						}}
					>
						%
					</Box>
					{note}
				</>
			}
			width={2}
		/>
	);
}
