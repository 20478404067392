import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ palette }) => {
	return {
		baseModalBody: {
			borderBottomLeftRadius: ifFeature('encore', '16px'),
			borderBottomRightRadius: ifFeature('encore', '16px'),
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			transition: 'opacity 350ms ease, transform 350ms ease',
			width: '100%',
		},
		baseModalBodyHidden: {
			opacity: 0,
			transform: 'translateY(-20px)',
		},
	};
});
