export enum BuiltinFormElementType {
	CheckboxField = 'CheckboxField',
	CurrencyField = 'CurrencyField',
	DateField = 'DateField',
	Group = 'Group',
	NumberField = 'NumberField',
	PercentageField = 'PercentageField',
	Section = 'Section',
	SelectField = 'SelectField',
	TextField = 'TextField',
	TextareaField = 'TextareaField',
}
