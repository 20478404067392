import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyledRoot, StyledBase, StyledBounce1, StyledBounce2 } from './styles';
import { LoaderProps } from './types';

function buildLoaderClasses(blockName?: string): string {
	const block = blockName ? ` ${blockName}__progress` : '';

	return block;
}

export const Loader: FunctionComponent<PropsWithChildren<LoaderProps>> = ({ blockName, small }) => {
	return (
		<StyledRoot
			aria-busy="true"
			aria-label={window.jQuery ? $.__('loading') : 'loading'}
			aria-live="polite"
			className={buildLoaderClasses(blockName)}
			role="status"
			small={small}
		>
			<StyledBounce1 />
			<StyledBounce2 />
			<StyledBase />
		</StyledRoot>
	);
};

Loader.displayName = 'Loader';
