import { SlidedownPortal } from '@bamboohr/fabric';
import { FC } from 'react';
import { useMessageStore } from 'src/store';

export const MessageSlidedown: FC = () => {
	const message = useMessageStore((state) => state.message);
	const clearMessage = useMessageStore((state) => state.clearMessage);

	return (
		<SlidedownPortal
			action={message?.action}
			description={message?.description}
			message={message?.message}
			onDismiss={clearMessage}
			show={!!message?.visible}
			type={message?.type}
		/>
	);
};
