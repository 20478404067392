import { makeStyles, dyeColors, ModeOptions } from '~styles';
import { Theme } from '@mui/material';

export const useConfettiStyles = makeStyles({ name: 'Confetti' })(({ mode }: Theme) => ({
	canvas: {
		outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		bottom: 0,
		left: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 0,
		top: 0,
	},
}));
