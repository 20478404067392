import React from 'react';
import { useExtractBreakpoints } from '~utils';
import { GridLayoutItemProps } from './item.types';
import { useItemStyles } from './item.styles';

export function Item(props: GridLayoutItemProps) {
	const { children, component = 'div', id, ...rest } = props;
	const { classes } = useItemStyles(useExtractBreakpoints(rest) as GridLayoutItemProps);
	const Component = component;

	return (
		<Component className={classes.root} id={id}>
			{children}
		</Component>
	);
}
