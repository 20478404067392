/* eslint-disable react/forbid-component-props */
import FocusTrap from 'focus-trap-react';
import { uniqueId } from 'lodash';
import React, { forwardRef, ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { ScreenReaderOnlyText } from '~components/screen-reader-only-text';
import { Popover } from '~components/popover';
import { DatePickerCalendarRange } from '../components/date-picker-calendar-range';
import { InputDateRange } from '../components/input-date-range';
import { useDatePickerUtils } from '../hooks';
import {
	DatePickerDate,
	DatePickerRangeInputType,
	DatePickerRangeOnChangeParamDate,
	DatePickerRangeProps,
	DatePickerRangeStyleProps,
	InputDateRangeOnChangeParam,
} from '../types';
import { getDateFromProp, getDateInfo, getDefaultMaxDate, getDefaultMinDate, getRangeCompletion, getRangeError } from '../utils';
import {
	DEFAULT_CALENDAR_SELECTION_MODE,
	getCalendarTargetSelectionType,
	getDatePickerRangeOnChangeParam,
	getMaxRangeSpanYears,
	getRangeCompletedText,
	isValidDateRangeSelection,
} from './date-picker-range.domain';
import { useStyles } from './date-picker-range.styles';

export const DatePickerRangeComponent = forwardRef<HTMLInputElement, DatePickerRangeProps>(function DatePickerRangeComponent(
	{
		biId,
		calendarSelectionMode = DEFAULT_CALENDAR_SELECTION_MODE,
		classes = {},
		className,
		DatePickerCalendarRangeProps = {},
		disabled,
		endBiId,
		endDisabled,
		endInputProps = {},
		endLabel,
		endRequired,
		endStatus,
		endValue: endValueProp,
		getDateDisabled,
		id,
		InputDateRangeProps = {},
		maxDate: maxDateProp,
		maxRangeSpanYears: maxRangeSpanYearsProp,
		minDate: minDateProp,
		note,
		onChange,
		onClose,
		onError,
		onOpen,
		onPopupToggle,
		open,
		renderDate,
		required = false,
		size = 'medium',
		startBiId,
		startDisabled,
		startInputProps = {},
		startLabel,
		startRequired,
		startStatus,
		startValue: startValueProp,
		validateDisabledDatesWithinRange = false,
		variant = 'form',
		viewMode,
		width,
	}: DatePickerRangeProps,
	ref
): ReactElement {
	const refIsUnmounted = useRef(false);
	const refEndInput = useRef<HTMLInputElement>(null);
	const refRangeInputRoot = useRef<HTMLDivElement>(null);
	const refStartInput = useRef<HTMLInputElement>(null);
	const refPopoverRoot = useRef<HTMLDivElement>(null);
	const refLastChangedStartInputValue = useRef('');
	const refLastChangedEndInputValue = useRef('');
	const [startPopupToggleButton, setStartPopupToggleButton] = useState<HTMLButtonElement | null>();
	const refEndPopupToggleButton = useRef<HTMLButtonElement>(null);

	useLayoutEffect(() => {
		return () => {
			refIsUnmounted.current = true;
		};
	}, []);

	const [shouldTransitionArrow, setShouldTransitionArrow] = useState(false);
	const styleProps: DatePickerRangeStyleProps = {
		shouldTransitionArrow: open || shouldTransitionArrow,
	};
	const { cx, classes: styles } = useStyles();
	const { palette } = useTheme();
	const utils = useDatePickerUtils();
	const [isOpen, setIsOpen] = useState(false);
	const [focusedInputType, setFocusedInputType] = useState<DatePickerRangeInputType>('start');
	const [menuControlsOpen, setMenuControlsOpen] = useState(false);
	const [fillColor, setFillColor] = useState(palette.gray[100]);

	const startValue = useMemo(() => getDateFromProp(utils, startValueProp), [utils, startValueProp]);
	const endValue = useMemo(() => getDateFromProp(utils, endValueProp), [utils, endValueProp]);
	const minDateValue = useMemo(() => getDateFromProp(utils, minDateProp), [utils, minDateProp]);
	const minDate = useMemo(() => minDateValue || getDefaultMinDate(utils), [utils, minDateValue]);
	const maxDateValue = useMemo(() => getDateFromProp(utils, maxDateProp), [utils, maxDateProp]);
	const maxDate = useMemo(() => maxDateValue || getDefaultMaxDate(utils), [utils, maxDateValue]);
	const maxRangeSpanYears = getMaxRangeSpanYears(maxRangeSpanYearsProp, validateDisabledDatesWithinRange);

	useEffect(() => {
		if (startValueProp && !startValue) {
			console.warn(`The provided 'DatePickerRange' component's 'startValue' prop is being ignored because it is invalid.`);
		}
	}, [startValue, startValueProp]);

	useEffect(() => {
		if (endValueProp && !endValue) {
			console.warn(`The provided 'DatePickerRange' component's 'endValue' prop is being ignored because it is invalid.`);
		}
	}, [endValue, endValueProp]);

	useEffect(() => {
		if (minDateProp && !minDateValue) {
			console.warn(`The provided 'DatePickerRange' component's 'minDate' prop is being ignored because it is invalid.`);
		}
	}, [minDateProp, minDateValue]);

	useEffect(() => {
		if (maxDateProp && !maxDateValue) {
			console.warn(`The provided 'DatePickerRange' component's 'maxDate' prop is being ignored because it is invalid.`);
		}
	}, [maxDateProp, maxDateValue]);

	const endInputRef = endInputProps.ref || refEndInput;
	const startInputRef = startInputProps.ref || refStartInput;

	if (endDisabled && startDisabled) {
		disabled = true;
	}

	calendarSelectionMode = startDisabled || endDisabled ? 'focusedInput' : calendarSelectionMode;

	useEffect(() => {
		window.addEventListener('click', tryClose);

		return () => {
			window.removeEventListener('click', tryClose);
		};

		function tryClose(e: MouseEvent) {
			const target = e.target as Element;

			if (
				(refPopoverRoot.current && refPopoverRoot.current.contains(target)) ||
				(refRangeInputRoot.current && refRangeInputRoot.current.contains(target))
			) {
				return;
			}

			tryHandleClose();
		}
	});

	useEffect(() => {
		handleError(startValue, endValue, refLastChangedStartInputValue.current, refLastChangedEndInputValue.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		utils,
		startValue,
		endValue,
		required,
		minDate,
		maxDate,
		maxRangeSpanYears,
		validateDisabledDatesWithinRange,
		getDateDisabled,
		refLastChangedStartInputValue.current,
		refLastChangedEndInputValue.current,
	]);

	const _open = open || isOpen;

	/*
	 * This is a workaround for the FocusTrap component. It too aggressively memoizes functions you pass to the component
	 * so any external values referenced by the function go stale.
	 */
	const focusedInputTypeRef = useRef<typeof focusedInputType>();
	focusedInputTypeRef.current = focusedInputType;

	let arrowLeft = 0;
	if (endInputRef?.current && startInputRef?.current) {
		if (focusedInputType === 'end') {
			arrowLeft = Math.round(endInputRef.current.getBoundingClientRect().x - startInputRef.current.getBoundingClientRect().x);
		}
	}

	const calendarId = useMemo(() => {
		return typeof id === 'string' ? id.replaceAll(' ', '-') : uniqueId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className={cx(className, classes.root, styles.root)} ref={ref}>
			<InputDateRange
				{...InputDateRangeProps}
				biId={biId ? `${biId}_date-picker-range_input-date-range` : biId}
				calendarId={calendarId}
				disabled={disabled}
				endBiId={endBiId}
				endCalendarButtonRef={refEndPopupToggleButton}
				endDisabled={endDisabled}
				endInputProps={{
					...endInputProps,
					'aria-live': 'assertive',
					ref: endInputRef,
					onFocus: handleEndInputFocus,
				}}
				endLabel={endLabel}
				endRequired={endRequired}
				endStatus={endStatus}
				endValue={endValue}
				id={id}
				isPopoverOpen={isOpen}
				note={note}
				onChange={handleInputChange}
				onPopupToggle={handlePopupToggle}
				ref={refRangeInputRoot}
				required={required}
				size={size}
				startBiId={startBiId}
				startCalendarButtonRef={setStartPopupToggleButton}
				startDisabled={startDisabled}
				startInputProps={{
					...startInputProps,
					'aria-live': 'assertive',
					ref: startInputRef,
					onFocus: handleStartInputFocus,
				}}
				startLabel={startLabel}
				startRequired={startRequired}
				startStatus={startStatus}
				startValue={startValue}
				variant={variant}
				viewMode={viewMode}
				width={width}
			/>
			{startPopupToggleButton && (
				<Popover
					anchorEl={startPopupToggleButton}
					arrowFill={fillColor}
					autofocus={false}
					classes={{ arrow: cx({ [styles.popoverArrow]: styleProps.shouldTransitionArrow }) }}
					disableOuterClickOnClose={true}
					hasCloseButton={false}
					hasMaxWidth={false}
					id={`date-picker-control-${calendarId}`}
					isRounded={true}
					modifiers={[
						{
							name: 'arrow',
							enabled: true,
							fn: ({ state }) => {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								state.modifiersData.arrow.x = arrowLeft + 4;
							},
						},
					]}
					noPadding={true}
					onFlip={placement => handleFlip(placement)}
					open={open === undefined ? isOpen : open}
					placement="bottom-start"
					ref={refPopoverRoot}
					variant="bordered"
				>
					<FocusTrap
						active={_open}
						focusTrapOptions={{
							clickOutsideDeactivates: true,
							delayInitialFocus: true,
							escapeDeactivates: false,
							initialFocus: false,
							setReturnFocus: () => {
								const focusedInputType = focusedInputTypeRef.current;
								if (focusedInputType === 'end' && refEndPopupToggleButton.current) {
									return refEndPopupToggleButton.current;
								}
								if (focusedInputType === 'start' && startPopupToggleButton) {
									return startPopupToggleButton;
								}
								return false;
							},
							tabbableOptions: { displayCheck: 'none' },
						}}
						paused={menuControlsOpen}
					>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div onKeyDown={handleEscape}>
							<DatePickerCalendarRange
								{...DatePickerCalendarRangeProps}
								autofocus={_open}
								disabled={disabled}
								endDate={endValue}
								getDateDisabled={handleGetDateDisabled}
								maxDate={maxDate}
								maxRangeSpanYears={maxRangeSpanYears}
								minDate={minDate}
								onChange={handleCalendarChange}
								onMenuClose={handleMenuClose}
								onMenuOpen={handleMenuOpen}
								renderDate={renderDate}
								startDate={startValue}
								targetSelectionType={getCalendarTargetSelectionType(utils, calendarSelectionMode, focusedInputType)}
								validateDisabledDatesWithinRange={validateDisabledDatesWithinRange}
							/>
						</div>
					</FocusTrap>
				</Popover>
			)}
			<ScreenReaderOnlyText ariaAtomic={true} ariaLive="polite">
				{getRangeCompletedText(utils, startValue, endValue)}
			</ScreenReaderOnlyText>
		</div>
	);

	function focusInitialInput(type: DatePickerRangeInputType): void {
		if (type === 'end') {
			tryFocusEndInput();
		} else {
			tryFocusStartInput();
		}
	}

	function focusNextInput(start: DatePickerDate, end: DatePickerDate): void {
		const completionStatus = getRangeCompletion(utils, start, end);

		if (completionStatus === 'onlyStart') {
			tryFocusEndInput();
		} else {
			tryFocusStartInput();
		}
	}

	function handleCalendarChange(param: DatePickerRangeOnChangeParamDate): void {
		const completionStatus = getRangeCompletion(utils, param.startValue, param.endValue);

		let { startValue: startDate, endValue: endDate } = param;

		const isValidDateRange = isValidDateRangeSelection({
			endDate,
			getDateDisabled: (date: DatePickerDate) => {
				return typeof getDateDisabled === 'function' ? getDateDisabled(getDateInfo(utils, date)) : false;
			},
			maxDate,
			minDate,
			startDate,
			utils,
		});

		if (completionStatus === 'complete' && isValidDateRange) {
			tryHandleClose();
		} else if (!isValidDateRange) {
			if (focusedInputType === 'start') {
				endDate = undefined;
				tryFocusEndInput();
			} else {
				startDate = undefined;
				tryFocusStartInput();
			}
		} else {
			focusNextInput(startDate, endDate);
		}

		const lastChangedStartInputValue = refLastChangedStartInputValue.current;
		const lastChangedEndInputValue = refLastChangedEndInputValue.current;
		if (startDate !== startValue) {
			// set based on if startValue change was due to a range date swap or not
			refLastChangedStartInputValue.current = startDate === endValue ? lastChangedEndInputValue : '';
		}
		if (endDate !== endValue) {
			// set based on if endValue change was due to a range date swap or not
			refLastChangedEndInputValue.current = endDate === startValue ? lastChangedStartInputValue : '';
		}

		onChange(getDatePickerRangeOnChangeParam(utils, startDate, endDate));
		handleError(startDate, endDate, refLastChangedStartInputValue.current, refLastChangedEndInputValue.current);
	}

	function handleClose() {
		setIsOpen(false);
		setShouldTransitionArrow(false);
		if (onClose) {
			onClose();
		}
	}

	function handleEndInputFocus(e: React.FocusEvent<HTMLInputElement>) {
		handleInputFocus('end');

		if (endInputProps.onFocus) {
			endInputProps.onFocus(e);
		}
	}

	function handleError(start: DatePickerDate, end: DatePickerDate, startInputValue: string, endInputValue: string): void {
		if (onError) {
			const error = getRangeError(
				utils,
				start,
				end,
				startInputValue,
				endInputValue,
				required,
				startRequired || false,
				endRequired || false,
				validateDisabledDatesWithinRange,
				minDate,
				maxDate,
				maxRangeSpanYears,
				handleGetDateDisabled
			);

			onError(error);
		}
	}

	function handleEscape(event: React.KeyboardEvent) {
		if (event.key === 'Escape' && !menuControlsOpen) {
			event.preventDefault();
			tryHandleClose();
		}
	}

	function handleGetDateDisabled(date: DatePickerDate) {
		return getDateDisabled ? getDateDisabled(getDateInfo(utils, date)) : false;
	}

	function handleInputChange(param: InputDateRangeOnChangeParam): void {
		const { inputValue, type, value } = param;

		if (type === 'start') {
			refLastChangedStartInputValue.current = inputValue;
			onChange(getDatePickerRangeOnChangeParam(utils, value, endValue));
			handleError(value, endValue, refLastChangedStartInputValue.current, refLastChangedEndInputValue.current);
		} else {
			refLastChangedEndInputValue.current = inputValue;
			onChange(getDatePickerRangeOnChangeParam(utils, startValue, value));
			handleError(startValue, value, refLastChangedStartInputValue.current, refLastChangedEndInputValue.current);
		}
	}

	function handleInputFocus(type: DatePickerRangeInputType): void {
		setFocusedInputType(type);
	}

	function handleMenuClose() {
		setMenuControlsOpen(false);
	}

	function handleMenuOpen() {
		setMenuControlsOpen(true);
	}

	function handleOpen() {
		setIsOpen(true);
		if (onOpen) {
			onOpen();
		}

		// Allows the initial popper open to occur before setting the transition state to true.
		setTimeout(() => {
			if (refIsUnmounted.current) {
				return;
			}

			setShouldTransitionArrow(true);
		}, 0);
	}

	function handlePopupToggle(type: DatePickerRangeInputType): void {
		focusInitialInput(type);
		if (isOpen) {
			// Only close the popup when the same type triggers the toggle, otherwise leave it open
			if (type === focusedInputType) {
				tryHandleClose();
			}
		} else {
			tryHandleOpen();
		}
		if (onPopupToggle) {
			onPopupToggle(type);
		}
	}

	function handleFlip(placement: string): void {
		setFillColor(placement.includes('bottom') ? palette.gray[100] : palette.common.white);
	}

	function handleStartInputFocus(e: React.FocusEvent<HTMLInputElement>) {
		handleInputFocus('start');

		if (startInputProps.onFocus) {
			startInputProps.onFocus(e);
		}
	}

	function tryFocusEndInput() {
		if (disabled) {
			return;
		}

		if (endDisabled) {
			tryFocusStartInput();
			return;
		}

		if (refEndPopupToggleButton?.current) {
			refEndPopupToggleButton.current.focus();
		}
		setFocusedInputType('end');
	}

	function tryFocusStartInput() {
		if (disabled) {
			return;
		}

		if (startDisabled) {
			tryFocusEndInput();
			return;
		}

		if (startPopupToggleButton) {
			startPopupToggleButton.focus();
		}
		setFocusedInputType('start');
	}

	function tryHandleClose() {
		if (open !== undefined) {
			return;
		}

		if (!isOpen) {
			return;
		}

		handleClose();
	}

	function tryHandleOpen() {
		if (open !== undefined) {
			return;
		}

		if (isOpen) {
			return;
		}

		handleOpen();
	}
});
