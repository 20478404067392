import { widths } from '@fabric/definitions/json/sizes.json';

export const widthStyles = {
	width1: {
		width: widths?.[1],
	},
	width2: {
		width: widths?.[2],
	},
	width3: {
		width: widths?.[3],
	},
	width4: {
		width: widths?.[4],
	},
	width5: {
		width: widths?.[5],
	},
	width6: {
		width: widths?.[6],
	},
	width7: {
		width: widths?.[7],
	},
	width8: {
		width: widths?.[8],
	},
	width9: {
		width: widths?.[9],
	},
	width10: {
		width: widths?.[10],
	},
	width100: {
		width: widths?.[100],
	},
};
