import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import { formatBaseProps } from '~utils';
import { useStyles } from './styled-box-v2.styles';
import { StyledBoxV2Component, StyledBoxV2Props } from './styled-box-v2.types';

export const StyledBoxV2 = forwardRef<HTMLElement, StyledBoxV2Props>((props, ref) => {
	const {
		backgroundColor = 'none',
		borderColor = 'none',
		borderRadius = 'none',
		borderStyle = 'none',
		boxShadow = 'none',
		objectFit = 'fill',
		...rest
	} = props;

	const { classes } = useStyles({
		backgroundColor,
		borderColor,
		borderRadius,
		borderStyle,
		boxShadow,
		objectFit,
		...rest,
	});

	return <Box {...formatBaseProps(rest)} className={classes.root} ref={ref} />;
}) as StyledBoxV2Component;
