import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme, alpha } from '@mui/material';
import { makeStyles, createStyles } from '~mui';
import { dyeColors, ModeOptions } from '~styles';
import { TooltipStyleProps } from './types';

export const useStyles = makeStyles(({ mode, palette, typography, spacing }: Theme) => {
	const arrowHeightRatio = '0.71em';
	return createStyles({
		root: {
			pointerEvents: 'none',
			zIndex: ({ zIndex }: TooltipStyleProps) => zIndex,
			'&[data-popper-placement*="bottom"] $arrow': {
				top: 0,
				left: 0,
				marginTop: `-${arrowHeightRatio}`,
				'&::before': {
					transformOrigin: '0 100%',
				},
			},
			'&[data-popper-placement*="top"] $arrow': {
				bottom: 0,
				left: 0,
				marginBottom: `-${arrowHeightRatio}`,
				'&:before': {
					transformOrigin: '100% 0',
				},
			},
			'&[data-popper-placement*="right"] $arrow': {
				left: 0,
				marginLeft: `-${arrowHeightRatio}`,
				height: '1em',
				width: arrowHeightRatio,
				'&::before': {
					transformOrigin: '100% 100%',
				},
			},
			'&[data-popper-placement*="left"] $arrow': {
				right: 0,
				marginRight: `-${arrowHeightRatio}`,
				height: '1em',
				width: arrowHeightRatio,
				'&::before': {
					transformOrigin: '0 0',
				},
			},
		},
		popperInteractive: {
			pointerEvents: 'auto',
		},
		tooltip: {
			backgroundColor: ifFeature('encore', palette.grey[900], palette.grey[800]),
			borderRadius: ifFeature('encore', '8px', '2px'),
			boxShadow: ifFeature('encore', `4px 4px 6x 2px ${alpha(palette.grey[900], 0.12)}`, '0 1px 3px rgba(0,0,0,0.20)'),
			color: palette.common.white,
			fontSize: '0.866rem', // 13px: typography.bodyTeenie
			fontWeight: typography.fontWeightMedium,
			lineHeight: '1.2rem', // 18px: typography.bodyTeenie
			maxWidth: '263px',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			position: 'relative',
		},
		/* Styles applied to the tooltip (label wrapper) element if `placement` contains "left". */
		tooltipPlacementLeft: {
			transformOrigin: 'right center',
			margin: ifFeature('encore', '0 8px', '0 11px'),
		},
		/* Styles applied to the tooltip (label wrapper) element if `placement` contains "right". */
		tooltipPlacementRight: {
			transformOrigin: 'left center',
			margin: ifFeature('encore', '0 8px', '0 11px'),
		},
		/* Styles applied to the tooltip (label wrapper) element if `placement` contains "top". */
		tooltipPlacementTop: {
			transformOrigin: 'center bottom',
			margin: ifFeature('encore', '8px 0', '11px 0'),
		},
		/* Styles applied to the tooltip (label wrapper) element if `placement` contains "bottom". */
		tooltipPlacementBottom: {
			transformOrigin: 'center top',
			margin: ifFeature('encore', '8px 0', '11px 0'),
		},
		content: {
			backgroundColor: 'inherit',
			borderRadius: 'inherit',
			padding: ifFeature('encore', spacing(1.5), '11px 15px'),
			position: 'relative',
			textAlign: 'center',
			zIndex: 1,
		},
		arrow: {
			filter: 'drop-shadow(0 3px 2px rgba(0,0,0,0.20))',
			boxSizing: 'border-box',
			color: palette.grey[800],
			height: arrowHeightRatio /* = width / sqrt(2) = (length of the hypotenuse) */,
			overflow: 'hidden',
			position: 'absolute',
			fontSize: '13px',
			width: '1em',
			zIndex: 0,
			'&::before': {
				content: '""',
				margin: 'auto',
				display: 'block',
				width: '100%',
				height: '100%',
				backgroundColor: 'currentColor',
				transform: 'rotate(45deg)',
			},
		},
		title: {
			display: 'block',
			fontSize: ifFeature('encore', typography.large.fontSize, typography.body2.fontSize),
			fontWeight: typography.fontWeightBold,
			lineHeight: ifFeature('encore', '24px', '1.133rem'), // 17px
			marginBottom: 2,
		},
	});
});
