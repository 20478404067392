import { makeStyles } from '~styles';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Flex } from '../../flex';
import { GradientLegendProps } from '../types';
import { useGradientLegendColors } from './use-gradient-legend-colors';

interface StyleProps {
	border: string;
}

export const useStyles = makeStyles<Partial<StyleProps>>()(({ palette, typography }, { border }) => {
	const gradientStyle = () => ({
		border: `1px solid ${border}`,
		height: '18px',
		width: '405px',
	});
	return {
		gradient: gradientStyle(),
		root: {
			color: palette.gray[800],
			fontSize: typography.teenie.fontSize,
			gap: '4px',
			lineHeight: typography.teenie.lineHeight,
		},
	};
});

function getLabelIfPossible(label?: ReactNode): string | number | undefined {
	return typeof label === 'number' || typeof label === 'string' ? label : undefined;
}

export const GradientLegend: FC<PropsWithChildren<GradientLegendProps>> = ({
	customColors,
	leftLabel,
	rightLabel,
	title = window.jQuery ? $.__('Legend') : 'Legend',
}) => {
	const { start, mid, end, border } = useGradientLegendColors(customColors);
	const { classes } = useStyles({ border });

	const { legendStart, legendEnd } = useMemo(() => {
		const potentialLeftLabel = getLabelIfPossible(leftLabel);
		const potentialRightLabel = getLabelIfPossible(rightLabel);

		if (potentialLeftLabel && potentialRightLabel) {
			return {
				legendStart: potentialLeftLabel,
				legendEnd: potentialRightLabel,
			};
		}

		return {
			legendStart: window.jQuery ? $.__('left') : 'left',
			legendEnd: window.jQuery ? $.__('right') : 'right',
		};
	}, [leftLabel, rightLabel]);

	// Uses SVG so that it shows up, when printing and background graphics is off.
	return (
		<Flex alignItems="center" className={classes.root}>
			{leftLabel}
			<svg aria-describedby="legendDescription" aria-labelledby="legendTitle" className={classes.gradient} role="img">
				<title id="legendTitle">{title}</title>
				<desc id="legendDescription">
					{window.jQuery
						? $.__('A legend is shown that spans from %1 to %2', legendStart, legendEnd)
						: `A legend is shown that spans from ${legendStart} to ${legendEnd}`}
				</desc>
				<linearGradient id="myGradient">
					<stop offset="0%" stopColor={start} />
					<stop offset="49.5%" stopColor={mid} />
					<stop offset="100%" stopColor={end} />
				</linearGradient>
				<rect fill="url('#myGradient')" height={18} width={405} />
			</svg>
			{rightLabel}
		</Flex>
	);
};
