import { errorToNote, FieldError, FieldValues, Path, UseFormProps } from '../../hook-form';
import { hasKey } from '@fabric/utils';
import { ControlledRenderOptions } from '../types';

export function getFieldDefinition(
	formController: UseFormProps<Record<string, unknown>>,
	getErrorState: ControlledRenderOptions['getErrorState'],
	name: string
) {
	const { formState, fields } = formController;
	const { errors } = formState;

	let fieldDefinition = fields[name];

	let errorState: FieldError | undefined = hasKey(errors, name) ? errors[name] : undefined;
	if (typeof getErrorState === 'function') {
		errorState = getErrorState(name, formState);
	}

	if (errorState) {
		fieldDefinition = { ...fieldDefinition, ...errorToNote(errorState || errors[name]) };
	}

	if (!fieldDefinition.isControlled) {
		fieldDefinition = { ...fieldDefinition, ...formController.register(name, fieldDefinition.rules) };
	}

	return fieldDefinition;
}
