import React from 'react';
import { IconV2Props } from './icon-v2.types';
import { useStyles } from './icon-v2.styles';
import { BaseIconV2 } from './base-icon-v2';

export const IconV2 = (props: IconV2Props) => {
	const { ariaLabel, containerRef, name } = props;
	const { classes } = useStyles(props);

	return <BaseIconV2 ariaLabel={ariaLabel} className={classes.svg} containerRef={containerRef} name={name} />;
};
