/* eslint-disable sort-keys */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';
import { getEncoreValue } from '~utils';
import { SlatProps } from './slat.types';

export const useStyles = makeStyles<SlatProps>()((theme, props) => {
	const { constructs, palette } = theme;
	const { backgroundColor, borderColor, borderPlacement, v2 } = props;

	const borderColorValue = getEncoreValue(borderColor, {
		none: 'transparent',
		'neutral-extra-extra-weak': undefined,
		'neutral-extra-weak': undefined,
		'neutral-forced-white': undefined,
		'neutral-inverted': undefined,
		...ifFeature(
			'encore',
			{
				'neutral-weak': constructs.border.neutral.weak,
				'neutral-medium': constructs.border.neutral.medium,
				'neutral-strong': constructs.border.neutral.strong,
				'neutral-extra-strong': constructs.border.neutral.xStrong,
				...(v2 && {
					'neutral-extra-extra-weak': constructs.border.neutral.xxWeak,
					'neutral-extra-weak': constructs.border.neutral.xWeak,
					'neutral-inverted': constructs.border.neutral.inverted,
					'neutral-forced-white': constructs.border.neutral.forcedWhite,
				}),
			},
			{
				...(v2
					? {
							'neutral-weak': palette.gray[300],
							'neutral-medium': palette.gray[400],
							'neutral-strong': palette.gray[500],
							'neutral-extra-strong': palette.gray[700],
						}
					: {
							'neutral-weak': palette.gray[200],
							'neutral-medium': palette.gray[300],
							'neutral-strong': palette.gray[400],
							'neutral-extra-strong': palette.gray[500],
						}),
			}
		),
	});
	const border = borderColorValue && `1px solid ${borderColorValue}`;

	return {
		root: {
			backgroundColor: getEncoreValue(backgroundColor, {
				none: 'transparent',
				'neutral-forced-white': constructs.surface.neutral.forcedWhite,
				'neutral-extra-weak': constructs.surface.neutral.xWeak,
				...ifFeature(
					'encore',
					{
						'neutral-weak': constructs.surface.neutral.weak,
						'neutral-medium': constructs.surface.neutral.medium,
						'neutral-strong': constructs.surface.neutral.strong,
						'neutral-extra-strong': constructs.surface.neutral.xStrong,
					},
					{
						...(v2
							? {
									'neutral-extra-weak': palette.gray[100],
									'neutral-weak': palette.gray[200],
									'neutral-medium': palette.gray[400],
									'neutral-strong': palette.gray[600],
									'neutral-extra-strong': palette.gray[800],
								}
							: {
									'neutral-weak': palette.common.white,
									'neutral-medium': palette.gray[100],
									'neutral-strong': palette.gray[200],
									'neutral-extra-strong': palette.gray[300],
								}),
					}
				),
			}),
			borderBottom: borderPlacement === 'bottom' ? border : '',
			borderTop: borderPlacement === 'top' ? border : '',
		},
	};
});
