import { Components, Palette } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';
import { Breakpoints, Spacing } from '@mui/system';

interface ThemeArgs {
	palette: Palette;
	spacing: Spacing;
	breakpoints: Breakpoints;
	typography: Typography;
}

export const getComponents = ({ palette, typography, breakpoints, spacing }: ThemeArgs): Components => {
	return {
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
				disableElevation: true,
			},
			styleOverrides: {
				disableElevation: {
					boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
				},
				root: {
					minWidth: 0,
					textTransform: 'none',
				},
				startIcon: {
					marginLeft: 0,
					marginRight: 8,
				},
				endIcon: {
					marginLeft: 8,
					marginRight: 0,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					fontFamily: 'Lato',
					fontSize: typography.fabricFontSize('medium'),
				},
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					'& .Mui-disabled': {
						'&, &:not(:last-of-type)': {
							borderColor: palette.grey[300],
						},
					},
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					backgroundColor: 'unset',
					height: 'auto',
					padding: 0,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					backgroundColor: palette.grey[300],
				},
				deleteIcon: {
					margin: spacing(0, 1, 0, 0),
				},
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined',
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					'&.Mui-disabled': {
						color: palette.grey[500],
					},
				},
				root: {
					marginLeft: 0,
					marginRight: 0,
				},
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				component: 'span',
			},
			styleOverrides: {
				root: {
					color: palette.grey[700],
					fontSize: 13,
					lineHeight: '18px',
					marginLeft: 12,
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					height: 34,
					borderRadius: 2,
					backgroundColor: palette.primary.main,
					color: palette.primary.contrastText,
					'&:hover': {
						backgroundColor: palette.primary.lighter,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					maxWidth: 'unset',
					overflow: 'unset',
					color: palette.text?.secondary,
					'&.Mui-focused': {
						color: palette.text?.secondary,
					},
				},
				outlined: {
					'&.MuiInputLabel-shrink': {
						transform: 'translate(0px, 2px)',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 2,
					color: palette.text?.primary,
				},
				input: {
					padding: '8px 10px',
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					backgroundColor: 'unset',
					height: 'auto',
					padding: 0,
				},
			},
		},
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					padding: 0,
					textTransform: 'none',
					'&:hover': {
						color: palette.primary.main,
						fill: palette.primary.main,
					},
					[breakpoints.up('sm')]: {
						minWidth: 'unset',
						padding: 0,
					},
				},
				textColorSecondary: {
					opacity: 'unset',
					'&.Mui-selected': {
						color: palette.primary.main,
						fill: palette.primary.main,
						fontWeight: 700,
					},
				},
				// Wrapper was removed https://github.com/mui/material-ui/pull/26926
				// wrapper: {
				// 	flexDirection: 'row',
				// 	margin: spacing(1.375, 2),
				// 	'& > svg': {
				// 		width: 21,
				// 		height: 21,
				// 	},
				// },
				labelIcon: {
					minHeight: 'unset',
					paddingTop: 'unset',
					'& .MuiTab-wrapper': {
						'& > svg': {
							width: 14,
							height: 14,
						},
					},
					'& .MuiTab-wrapper > *:first-child': {
						marginBottom: 'unset',
						marginRight: 8,
					},
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				textColor: 'secondary',
				indicatorColor: 'primary',
			},
			styleOverrides: {
				root: {
					color: palette.grey[700],
					fill: palette.grey[700],
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
			},
		},
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
				},
			},
			styleOverrides: {
				root: {
					color: palette.grey[1000],
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					width: 'unset',
					height: 'unset',
					flexShrink: 'unset',
					fontSize: 'unset',
					transition: 'unset',
				},
			},
		},
	};
};
