import React from 'react';
import { useExtractBreakpoints } from '~utils';
import { GridLayoutProps } from './grid-layout.types';
import { Item } from './item';
import { useGridLayoutStyles } from './grid-layout.styles';

export function GridLayout(props: GridLayoutProps) {
	const { children, component = 'div', display = 'grid', id, ...rest } = props;
	const { classes } = useGridLayoutStyles(useExtractBreakpoints({ display, ...rest }) as GridLayoutProps);
	const Component = component;

	return (
		<Component className={classes.root} id={id}>
			{children}
		</Component>
	);
}

GridLayout.Item = Item;
