import { includes } from 'lodash';

const uids: Array<string> = [];
/**
 * generate a random and unique string of a given length (defaults to 8 characters)
 *
 * @method makeUid
 *
 * @param  {Number} [length] the length of the resulting string
 *
 * @return {String}            the random, unique string
 */
export function makeUid(length = 8): string {
	let uid = '';
	while (uid === '' || !includes(uids, uid)) {
		uid = (Math.random() + 1).toString(36).substr(2, length);
		uids.push(uid);
	}

	while (uid.length < length) {
		uid += makeUid(length - uid.length);
	}

	return uid;
}
