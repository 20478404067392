import React from 'react';
import { Collapse } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';
import { AccordionProps } from './accordion.types';
import { useAccordionHeaderContentStyles } from './accordion-header-content.styles';

export function AccordionHeaderContent({
	disabled,
	headerIcon,
	title,
	description,
	expanded,
	headerRight,
	hideDescriptionOnCollapse,
	id,
}: AccordionProps): JSX.Element {
	const { classes } = useAccordionHeaderContentStyles({ disabled, expanded, hideDescriptionOnCollapse });
	return (
		<div aria-level={1} className={classes.headerContent} id={id} role="heading">
			{ifFeature(
				'encore',
				headerIcon && typeof headerIcon === 'string' && (
					<div className={classes.headerIcon}>
						<IconV2 name={headerIcon} size={20} />
					</div>
				),
				headerIcon && <div className={classes.headerIcon}>{headerIcon}</div>
			)}
			{title && (
				<div className={classes.title}>
					{typeof title === 'string' ? <div className={classes.defaultTitle}>{title}</div> : title}
				</div>
			)}
			{description && (
				<div className={classes.description}>
					{ifFeature(
						'encore',
						<Collapse
							collapsedSize={0}
							id={`accordion-description-${id}`}
							in={!hideDescriptionOnCollapse || expanded}
							timeout="auto"
						>
							{description}
						</Collapse>,
						description
					)}
				</div>
			)}
			{headerRight && <div className={classes.headerRight}>{headerRight}</div>}
		</div>
	);
}
