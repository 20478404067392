import React, { FunctionComponent, PropsWithChildren } from 'react';
import { SpinnerProps } from './spinner.types';
import { useStyles } from './spinner.styles';

export const Spinner: FunctionComponent<PropsWithChildren<SpinnerProps>> = ({ size = 20 }) => {
	const { classes } = useStyles({ size });

	return (
		<div
			aria-busy="true"
			aria-label={window.jQuery ? $.__('loading') : 'loading'}
			aria-live="polite"
			className={classes.root}
			role="status"
		/>
	);
};

Spinner.displayName = 'Spinner';

export default Spinner;
