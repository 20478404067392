import { DatePickerDate, DatePickerDateType, DatePickerRangeTargetSelectionType, DatePickerUtils } from '../../../types';
import { getDefaultMinDate, getIsDateValid } from '../../../utils';

/**
 * Returns a min date that is derived from other settings, such as: maxRangeSpanYears and targetSelectionType.
 * Consolidating these settings into a single min date simplifies the logic in the DatePickerCalendarRange
 * component as well as in the other utilities the DatePickerCalendarRange component uses.
 *
 * For example: if an end date is selected and maxRangeSpanYears is set, the DatePickerCalendarRange
 * now must enforce a min date of the end date minus the number of years indicated in maxRangeSpanYears.
 *
 * @param utils The date utils used all over the Date Picker components
 * @param settings An object containing settings that affect the min date
 * @returns A new min date derived from the original min date settings and other DatePickerCalendarRange settings.
 */
export function getDerivedMinDate(
	utils: DatePickerUtils,
	{
		endDate,
		maxRangeSpanYears,
		minDate,
		startDate,
		targetSelectionType,
	}: {
		endDate?: DatePickerDate;
		maxRangeSpanYears?: number;
		minDate?: DatePickerDate;
		startDate?: DatePickerDate;
		targetSelectionType?: DatePickerRangeTargetSelectionType;
	} = {}
): DatePickerDate | undefined {
	minDate = (getIsDateValid(utils, minDate) ? minDate : getDefaultMinDate(utils)) as DatePickerDateType;

	if (targetSelectionType === 'end' && startDate && getIsDateValid(utils, startDate) && utils.isAfter(startDate, minDate)) {
		return startDate;
	}

	const referenceDate = targetSelectionType === 'start' || targetSelectionType === 'start-ish' ? endDate : startDate;
	if (maxRangeSpanYears && !!referenceDate && getIsDateValid(utils, referenceDate)) {
		const minDateFromMaxYearSpan = utils.addMonths(referenceDate, -12 * maxRangeSpanYears);

		if (utils.isAfter(minDateFromMaxYearSpan, minDate)) {
			return minDateFromMaxYearSpan;
		}
	}

	return minDate;
}
