import { AllCapsHeadingProps } from './types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

const getLineHeight = size => {
	switch (size) {
		case 'large':
			return '22px';

		case 'medium':
			return '20px';

		case 'small':
			return '17px';

		case 'teenie':
		default:
			return '15px';
	}
};

const getLetterSpacing = size => {
	switch (size) {
		case 'large':
			return '1px';

		case 'medium':
			return '0.8px';

		case 'small':
			return '0.6px';

		case 'teenie':
		default:
			return '0.4px';
	}
};

export const useStyles = makeStyles<AllCapsHeadingProps>()(
	({ mode, palette, typography }, { color, gray, size, letterSpacing, lineHeight, margin }) => ({
		root: {
			color: mode === ModeOptions.Dye ? dyeColors.main : color || palette.gray[gray],
			fontSize: typography[size]?.fontSize,
			fontWeight: typography.fontWeightSemibold,
			letterSpacing: letterSpacing || getLetterSpacing(size),
			lineHeight: lineHeight || getLineHeight(size),
			margin,
			textTransform: 'uppercase',
		},
	})
);
