import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { SearchInputProps } from '../types';

export const useStyles = makeStyles<Partial<SearchInputProps>, 'clearAdornment' | 'focus' | 'searchAdornment' | 'searchIcon'>()((
	{ breakpoints, mixins, mode, palette, spacing },
	{ value },
	classes
) => {
	return {
		root: {
			width: ifFeature('encore', '288px'),
			// @startCleanup encore
			boxShadow: ifFeature('encore', undefined, 'none'),
			// @endCleanup encore
			borderRadius: 18,
			borderColor: ifFeature('encore', palette.grey[400]),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			overflow: 'hidden',
			transition: 'all 0.2s ease-in-out',
			// @startCleanup encore
			maxWidth: ifFeature('encore', undefined, 160),
			// @endCleanup encore
			flexGrow: ifFeature('encore', 1),
			padding: ifFeature('encore', spacing(1, 1, 1, 2)),
			[`&:focus-within, &.${classes.focus}`]: {
				// @startCleanup encore
				maxWidth: ifFeature('encore', undefined, 228),

				[`& .${classes.searchAdornment}`]: {
					backgroundColor: ifFeature('encore', undefined, palette.gray[100]),
				},
				// @endCleanup encore

				[`& .${classes.searchIcon}`]: {
					fill: palette.primary.main,
				},
			},

			[`& .${classes.clearAdornment}`]: {
				backgroundColor: 'transparent',
			},

			[`& .${classes.searchAdornment}`]: {
				backgroundColor: 'transparent',
			},
			// @startCleanup encore
			[breakpoints.down('md')]: {
				maxWidth: ifFeature('encore', undefined, 46),

				[`&:focus-within, &.${classes.focus}`]: {
					maxWidth: ifFeature('encore', undefined, 228),
				},

				[`&:not(:focus-within):not(&.${classes.focus}) .${classes.searchAdornment}`]: {
					marginLeft: ifFeature('encore', undefined, '-4px'),
				},
			},
			// @endCleanup encore
		},

		clearAdornment: {
			opacity: value ? 1 : 0,
			transition: 'opacity 0.2s ease-in-out',
		},

		focus: {
			'&, &:hover': {
				...mixins.focusRing(palette.primary),
			},
		},

		searchAdornment: {},

		searchIcon: {
			fill: palette.gray[600],
			width: ifFeature('encore', '12px'),
		},
		decoy: {
			width: 0,
			height: 0,
			visibility: 'hidden',
			position: 'absolute',
			left: 0,
			top: 0,
			padding: 0,
			border: 'none',
			margin: 0,
		},
	};
});
