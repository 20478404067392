import React from 'react';
import { TitleProps } from './action-banner.types';
import { useStyles } from './title.styles';

export function Title({ subTitle, superTitle, title }: TitleProps) {
	const { classes } = useStyles();

	return (
		<div className={classes.root}>
			{superTitle ? <div className={classes.subSuperTitle}>{superTitle}</div> : null}
			<h2 className={classes.title}>{title}</h2>
			{subTitle ? <div className={classes.subSuperTitle}>{subTitle}</div> : null}
		</div>
	);
}
