import { PersonCircle100x100, PersonCircle144x144, PersonCircle24x24, PersonCircle40x40, TrashCan10x12 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Avatar } from '~components/avatar';
import { Button, IconButton, TextButton } from '~components/button';
import { CropBoxCoords, Cropper } from '~components/cropper';
import { Divider } from '~components/divider';
import { FileDropper } from '~components/file-dropper';
import { Flex } from '~components/flex';
import { Headline } from '~components/headline';
import { Loader } from '~components/loader';
import { SlidedownPortal } from '~components/slidedown';
import { StandardModal } from '~components/standard-modal';
import { allowedFileTypes } from './constants';
import { usePhotoUploadStyles } from './photo-upload-modal.styles';
import { PhotoUploadModalProps } from './photo-upload-modal.types';
import { handleFileInputChange, handleFileSelection } from './utils';

export function PhotoUploadModal({
	biId,
	imageAlt,
	imageSrc: initialImageSrc,
	initialCropBoxCoords,
	loading,
	open,
	onCancel,
	onDelete,
	onSave,
	processing,
}: PhotoUploadModalProps): ReactElement {
	const { classes, cx } = usePhotoUploadStyles();

	const [cropBoxCoords, setCropBoxCoords] = useState<CropBoxCoords | undefined>(initialCropBoxCoords);
	const [imageSrc, setImageSrc] = useState<string | undefined>(initialImageSrc);
	const [errorMessage, setErrorMessage] = useState('');
	const formRef = useRef<HTMLFormElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setImageSrc(initialImageSrc);
	}, [initialImageSrc]);

	useEffect(() => {
		setCropBoxCoords(initialCropBoxCoords);
	}, [initialCropBoxCoords]);

	const handleCropperChange = (coords: CropBoxCoords) => {
		setCropBoxCoords(coords);
	};

	const handleRemoveFile = () => {
		setImageSrc(undefined);
		setCropBoxCoords(undefined);
		formRef.current?.reset();
	};

	const handleSubmit = () => {
		if (imageSrc) {
			onSave(imageSrc, cropBoxCoords as CropBoxCoords);
		} else {
			onDelete();
		}
	};

	const handleCancel = () => {
		setCropBoxCoords(undefined);
		setImageSrc(undefined);
		onCancel();
	};

	const choosePhotoText = window.jQuery ? $.__('Choose Photo') : 'Choose Photo';
	const changePhotoText = window.jQuery ? $.__('Change Photo') : 'Change Photo';

	const addTitle = window.jQuery ? $.__('Add Photo') : 'Add Photo';
	const editTitle = window.jQuery ? $.__('Edit Photo') : 'Edit Photo';
	const title = initialImageSrc ? editTitle : addTitle;

	return (
		<StandardModal isOpen={open} isProcessing={processing} onRequestClose={handleCancel}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={ifFeature(
							'encore',
							[
								<TextButton
									data-bi-id={biId ? `${biId}-cancel` : undefined}
									key="cancel"
									onClick={handleCancel}
									type="button"
								>
									{window.jQuery ? $.__('Cancel') : 'Cancel'}
								</TextButton>,
								<Button data-bi-id={biId ? `${biId}-save` : undefined} key="save" onClick={handleSubmit} type="button">
									{window.jQuery ? $.__('Save') : 'Save'}
								</Button>,
							],
							[
								<Button data-bi-id={biId ? `${biId}-save` : undefined} key="save" onClick={handleSubmit} type="button">
									{window.jQuery ? $.__('Save') : 'Save'}
								</Button>,
								<TextButton
									data-bi-id={biId ? `${biId}-cancel` : undefined}
									key="cancel"
									onClick={handleCancel}
									type="button"
								>
									{window.jQuery ? $.__('Cancel') : 'Cancel'}
								</TextButton>,
							]
						)}
					/>
				}
				renderHeader={<StandardModal.Header title={title} />}
				size={ifFeature('encore', 'medium', 'small')}
			>
				<StandardModal.Constraint
					spacingOverrides={{
						top: StandardModal.Constraint.Spacing.LARGE,
						bottom: StandardModal.Constraint.Spacing.LARGE,
					}}
				>
					{loading ? (
						<Loader />
					) : (
						<FileDropper
							acceptedTypes={allowedFileTypes}
							onFileDrop={files => handleFileSelection(files, setCropBoxCoords, setImageSrc)}
						>
							<form className={classes.wrapper} ref={formRef}>
								<Flex alignItems={ifFeature('encore', 'center')} flex={ifFeature('encore', 1)} flexDirection="column">
									<div className={classes.cropperWrapper}>
										{imageSrc ? (
											<Cropper
												biId={biId ? `${biId}-cropper` : undefined}
												imageAlt={imageAlt}
												imageSrc={imageSrc}
												initialCropBoxCoords={cropBoxCoords}
												onChange={handleCropperChange}
												previewSelector={`.${classes.previews} > div`}
											/>
										) : (
											<div className={classes.blankState}>
												{ifFeature('encore', <Avatar size={128} />, <PersonCircle144x144 />)}
												<p className={classes.blankStateText}>
													{window.jQuery
														? $.__('Drop file here to upload...')
														: 'Drop file here to upload...'}
												</p>
											</div>
										)}
									</div>
									<div className={classes.fileActions}>
										<input
											accept={allowedFileTypes.join(',')}
											className={classes.hiddenFileInput}
											onChange={event =>
												handleFileInputChange(event, setCropBoxCoords, setImageSrc, setErrorMessage)
											}
											ref={fileInputRef}
											type="file"
										/>
										<Button
											color={ifFeature('encore', 'secondary', 'primary')}
											data-bi-id={biId ? `${biId}-choose-photo` : undefined}
											onClick={() => fileInputRef.current?.click()}
											processing={processing}
											type="button"
											variant={ifFeature('encore', 'contained', 'outlined')}
										>
											{imageSrc ? changePhotoText : choosePhotoText}
										</Button>
										{imageSrc && (
											<IconButton
												ariaLabel={window.jQuery ? $.__('Remove photo') : 'Remove photo'}
												color={ifFeature('encore', 'primary', 'secondary')}
												data-bi-id={biId ? `${biId}-remove-photo` : undefined}
												icon={ifFeature('encore', 'trash-can-regular', <TrashCan10x12 />)}
												onClick={handleRemoveFile}
												processing={processing}
												type="button"
												variant="outlined"
											/>
										)}
									</div>
								</Flex>
								{ifFeature(
									'encore',
									<>
										<Divider color="neutral-extra-weak" marginLeft={5} marginRight={5} orientation="vertical" />
										<Flex flex={1} flexDirection="column" rowGap={2}>
											<Headline color="neutral-strong" size="small">
												{window.jQuery ? $.__('Preview') : 'Preview'}
											</Headline>
											<div className={classes.previews}>
												{imageSrc ? (
													<>
														<div className={cx(classes.preview, classes.previewLarge)} />
														<div className={cx(classes.preview, classes.previewMedium)} />
														<div className={cx(classes.preview, classes.previewSmall)} />
													</>
												) : (
													<>
														<Avatar size={96} />
														<Avatar size={56} />
														<Avatar size={32} />
													</>
												)}
											</div>
										</Flex>
									</>,
									<div className={classes.previews}>
										<p className={classes.previewTitle}>{window.jQuery ? $.__('Preview') : 'Preview'}</p>
										{imageSrc ? (
											<>
												<div className={cx(classes.preview, classes.previewLarge)} />
												<div className={cx(classes.preview, classes.previewMedium)} />
												<div className={cx(classes.preview, classes.previewSmall)} />
											</>
										) : (
											<>
												<PersonCircle100x100 />
												<PersonCircle40x40 />
												<PersonCircle24x24 />
											</>
										)}
									</div>
								)}
							</form>
						</FileDropper>
					)}
					<SlidedownPortal message={errorMessage} onDismiss={() => setErrorMessage('')} show={!!errorMessage} type="error" />
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
