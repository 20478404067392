import { useRef } from 'react';
import { ListItem } from '../types';
import { parseSelectedItems } from '../etc';
import { cloneDeep } from 'lodash';

export const useSelectRows = () => {
	const lastSelectedRef = useRef<number | string>();
	return (listItems: Array<ListItem>, rowKey: number | string, event: PointerEvent, isSubcategory = false) => {
		const updatedSelectedRows = cloneDeep(listItems);
		const selectedRowIndex = listItems.findIndex(item => {
			return item.rowKey === rowKey;
		});
		let lastSubcategoryKey;
		const lastSelectedKey = lastSelectedRef?.current;

		if (event.shiftKey && lastSelectedKey !== undefined && lastSelectedKey !== null && lastSelectedKey !== rowKey) {
			const lastSelectedIndex = listItems.findIndex(item => {
				return item.rowKey === lastSelectedKey;
			});
			const changed = parseSelectedItems(listItems, lastSelectedIndex, selectedRowIndex);

			const updatedSelected = !updatedSelectedRows[selectedRowIndex].selected;
			changed.forEach(change => {
				updatedSelectedRows[change].selected = updatedSelected;
			});
		} else if (event.metaKey || event.altKey || event.ctrlKey) {
			updatedSelectedRows[selectedRowIndex].selected = !updatedSelectedRows[selectedRowIndex].selected;
		} else if (isSubcategory) {
			updatedSelectedRows.forEach(item => {
				if (item.subcategory === rowKey) {
					item.selected = true;
					lastSubcategoryKey = item.rowKey;
				} else {
					item.selected = false;
				}
			});
		} else {
			updatedSelectedRows.forEach(item => {
				item.selected = false;
			});
			updatedSelectedRows[selectedRowIndex].selected = true;
		}

		if (lastSelectedRef) {
			lastSelectedRef.current = isSubcategory ? lastSubcategoryKey : rowKey;
		}

		return updatedSelectedRows;
	};
};
