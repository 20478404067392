// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { CircleX12x12 } from '@bamboohr/grim';
import { IconV2 } from '~components/icon-v2';
import { getTabIndex } from '../../etc';
import { ListItem, ListItemClickData, TransferListItemProps, TransferListType, TransferListSide } from '../../types';
import { getSubcategorizedData } from './get-subcategorized-item-data';
import { TransferListCategoryList } from '../transfer-list-category-list';
import { TransferListHoverItem } from '../transfer-list-item';
import { TransferListSubcategory } from '../transfer-list-subcategory';

export function renderCategorizedList(
	listItems: ListItem[],
	onClick: (customEventData: ListItemClickData, event: PointerEvent) => ListItem[],
	props: Partial<TransferListItemProps>,
	focusedIndex: number,
	type?: TransferListType,
	side?: TransferListSide,
	reorderSubcategories?: (dragName: string, hoverName: string) => void
): Array<ReactElement> | undefined {
	if (Array.isArray(listItems)) {
		if (side === TransferListSide.LEFT) {
			return [
				<TransferListCategoryList
					focusedIndex={focusedIndex}
					key={0}
					listItems={listItems}
					onClick={onClick}
					reorderSubcategories={reorderSubcategories}
					transferListItemProps={props}
					transferListSide={side}
				/>,
			];
		}

		return getSubcategorizedData(listItems).map(subcategory => {
			if (subcategory.name !== '') {
				const { name, grouped = false, items, index, displayOrderIndex } = subcategory;
				return (
					<TransferListSubcategory
						focusedIndex={focusedIndex}
						grouped={grouped}
						icon={ifFeature('encore', <IconV2 name="circle-xmark-solid" size={20} />, <CircleX12x12 />)}
						index={index}
						isFocused={focusedIndex === displayOrderIndex}
						items={items}
						key={name}
						name={name}
						onClick={onClick}
						reorderSubcategories={reorderSubcategories}
						tabIndex={getTabIndex(focusedIndex, displayOrderIndex)}
						transferListItemProps={props}
						transferListSide={side}
					/>
				);
			}

			const item = subcategory.items[0];
			const { title, children, value, displayOrderIndex } = item;
			return (
				<TransferListHoverItem
					{...props}
					disabled={item.disabled}
					icon={ifFeature('encore', <IconV2 name="circle-xmark-solid" size={20} />, <CircleX12x12 />)}
					isFocused={focusedIndex === displayOrderIndex}
					key={item.rowKey}
					listIndex={subcategory.index}
					onClick={onClick}
					reorderSubcategories={reorderSubcategories}
					rightSide={side === TransferListSide.RIGHT}
					rowKey={item.rowKey}
					tabIndex={getTabIndex(focusedIndex, displayOrderIndex)}
					title={title}
					value={value}
				>
					{children}
				</TransferListHoverItem>
			);
		});
	}
}
