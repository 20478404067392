import { makeStyles } from '~styles';
import { animationDuration } from '../global-navigation.constants';

export const useStyles = makeStyles<void, 'active' | 'label'>()((theme, props, classes) => {
	const { constructs, mixins, palette, spacing, typography } = theme;
	return {
		active: {
			fontWeight: typography.fontWeightBold,
		},
		hideLabel: {
			display: 'none',
		},
		icon: {
			display: 'inline-block',
			flex: '0 0 auto',
			height: spacing(3),
			width: spacing(3),
		},
		label: {
			display: 'block',
			opacity: 0,
			transition: `opacity ${animationDuration}ms ease-in-out`,
			whiteSpace: 'nowrap',
		},
		link: {
			alignItems: 'center',
			borderRadius: '16px',
			boxSizing: 'border-box',
			color: palette.gray[800],
			display: 'flex',
			fill: palette.gray[800],
			flexWrap: 'nowrap',
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightMedium,
			gap: spacing(2),
			height: spacing(7),
			lineHeight: typography.large.lineHeight,
			padding: spacing(0, 2),
			textDecoration: 'none',
			transition: `width ${animationDuration}ms ease-in-out`,
			width: spacing(7),
			[`&:hover, &.${classes.active}`]: {
				backgroundColor: palette.gray[100],
				color: palette.primary.main,
				fill: palette.primary.main,
				textDecoration: 'none',
			},
			'&:focus-visible': {
				outline: `2px solid ${mixins.alpha(constructs.border.primary.strong, 0.8)}`,
				outlineOffset: '-2px',
			},
		},
		open: {
			width: '200px',
			[`& .${classes.label}`]: {
				opacity: 1,
			},
		},
		root: {
			listStyleType: 'none',
		},
	};
});
