import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import { renderProp } from '@fabric/utils';
import { IconTile } from '~components/icon-tile';
import { IconV2Name } from '~components/icon-v2';
import { useTileContentStyles } from './tile-content.styles';
import { TileContentProps } from './tile-content.types';
import { uniqueId } from 'lodash';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

/**
 * @deprecated Use `Tile V2` instead.
 */
export const TileContent = (props: TileContentProps) => {
	const { title, subtitle, icon, actionsDropdown, primaryAction, variant = 'primary', isActive, ...rootProps } = props;
	const { classes, cx } = useTileContentStyles();
	const titleId = uniqueId();

	return (
		<Stack aria-labelledby={titleId} className={cx([classes.root, isActive && classes.active])} role="region" {...rootProps}>
			{actionsDropdown && (
				<Box
					className={classes.actionContainer}
					position="absolute"
					right={ifFeature('encore', 16, 8)}
					top={ifFeature('encore', 16, 8)}
				>
					{renderProp(actionsDropdown)}
				</Box>
			)}
			{/* We render the icon container here to correctly center the 'title' regardless of whether or not there is an icon */}
			<Box className={cx([classes.iconContainer, variant === 'secondary' && classes.iconContainer__secondary])}>
				{ifFeature(
					'encore',
					typeof icon === 'string' ? (
						<IconTile icon={icon as IconV2Name} size={64} variant={variant === 'primary' ? 'primary' : undefined} />
					) : null,
					renderProp(icon)
				)}
			</Box>
			<Typography
				className={classes.typographyContainer}
				color={ifFeature('encore', 'primary.main', variant === 'primary' ? 'gray.900' : 'gray.700')}
				fontWeight={ifFeature('encore', 600, variant === 'primary' ? 700 : 600)}
				id={titleId}
				mb={ifFeature('encore', 0, 0.25)}
				variant={ifFeature('encore', 'medium', 'large')}
			>
				{title}
			</Typography>
			{subtitle && (
				<Typography
					className={classes.typographyContainer}
					color={ifFeature('encore', 'gray.800', 'gray.700')}
					variant={ifFeature('encore', 'teenie', 'small')}
				>
					{subtitle}
				</Typography>
			)}
			{primaryAction && (
				<Box className={classes.actionContainer} justifySelf="flex-end" mt={ifFeature('encore', '4px', 'auto')}>
					{renderProp(primaryAction)}
				</Box>
			)}
		</Stack>
	);
};
