import { FieldError } from 'react-hook-form';
import { NotedError } from '../types/noted-error';

const cleanMessage = (message?: string) => {
	if (message === 'Required') return '';
	return message;
};

export const errorToNote = (error?: FieldError): NotedError | undefined => {
	return {
		note: error ? cleanMessage(error.message) : '',
		status: error ? 'error' : undefined,
	};
};
