// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Select } from '@fabric/select';
import { IconButton } from '@fabric/button';
import { CirclePlus14x14 } from '@bamboohr/grim';
import { IconV2 } from '~components/icon-v2';
import { useStyles } from './styles';

import { useFilter } from '../../hooks';

import { FilterItem, FilterSelectProps } from '../../types';

export function FilterSelect(props: FilterSelectProps): ReactElement {
	const {
		biId,
		classes: classNames,
		filterListItems: allFilterItems = [],
		onChange = () => {
			return {};
		},
		placeholder,
		renderFirstFilter = props => {
			return (
				<Select
					ariaLabel={window.jQuery ? $.__('Item filter') : 'Item filter'}
					biId={biId && `${biId}-first-filter`}
					{...props}
				/>
			);
		},
		renderSecondFilter = props => {
			return (
				<Select
					ariaLabel={window.jQuery ? $.__('Additional item filter') : 'Additional item filter'}
					biId={biId && `${biId}-second-filter`}
					{...props}
				/>
			);
		},
		useSecondFilter = false,
	} = props;

	const [showSecondFilter, setShowSecondFilter] = useState(false);

	const { classes } = useStyles(undefined, { props: { classes: classNames } });

	const { clearFirstFilter, clearSecondFilter, selectFirstFilter, selectSecondFilter, state } = useFilter(allFilterItems, onChange);

	const { firstFilterItems, secondFilterItems, firstSelectedFilter, secondSelectedFilter } = state;

	return (
		<div className={classes.root}>
			<>
				{typeof renderFirstFilter === 'function' &&
					renderFirstFilter({
						items: firstFilterItems.length > 0 ? firstFilterItems : allFilterItems,
						onClear: () => {
							clearFirstFilter();
							setShowSecondFilter(false);
						},
						onSelect: (value, selected, selectedObject: { item: FilterItem; parentItem: FilterItem }) => {
							selectFirstFilter(selectedObject);
						},
						placeholder,
						selectedValues: firstSelectedFilter ? [firstSelectedFilter.value] : [],
						showSearch: 'never',
						size: 'small',
						width: showSecondFilter && firstFilterItems ? 4 : ifFeature('encore', 100, 6),
					})}
			</>
			{useSecondFilter && typeof renderSecondFilter === 'function' && !!firstSelectedFilter && (
				<>
					{!showSecondFilter && (
						<div className={classes.addFilterButtonContainer}>
							<IconButton
								classes={{
									noBoundingBox: classes.floatingIcon,
								}}
								icon={ifFeature('encore', 'circle-plus-regular', <CirclePlus14x14 />)}
								noBoundingBox={true}
								onClick={() => {
									setShowSecondFilter(true);
								}}
								type="button"
							/>
						</div>
					)}
					{showSecondFilter && (
						<>
							{ifFeature(
								'encore',
								<IconV2 color="neutral-medium" name="plus-regular" size={16} />,
								<div className={classes.plusSelectIcon}>+</div>
							)}
							<>
								{renderSecondFilter({
									items: secondFilterItems,
									onClear: () => {
										setShowSecondFilter(false);
										clearSecondFilter();
									},
									onSelect: (value, selected, selectedObject: { item: FilterItem; parentItem: FilterItem }) => {
										selectSecondFilter(selectedObject);
									},
									selectedValues: secondSelectedFilter ? [secondSelectedFilter.value] : [],
									showSearch: 'never',
									size: 'small',
									width: 4,
								})}
							</>
						</>
					)}
				</>
			)}
		</div>
	);
}
