import { CheckboxGroupItem, CheckboxOnChangeParam } from './types';

export function getBiId(biId = '', name = ''): string | undefined {
	if (biId) {
		const basePart = `${biId}-checkbox-group`;
		const namePart = name ? `-${name}` : '';

		return `${basePart}${namePart}`;
	}
}

export function getValue(items: Array<CheckboxGroupItem>) {
	return items.filter(item => item.checked).map(item => item.value);
}

export function updateItems(items: Array<CheckboxGroupItem>, param: CheckboxOnChangeParam, value?: Array<string | number>) {
	return items.map(item => {
		const checked = item.value === param.value ? param.checked : !!(item.checked || value?.includes(item.value));

		return {
			...item,
			checked,
		};
	});
}
