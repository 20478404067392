import { Typography } from '@fabric/typography';
import { TypographyProps } from '@fabric/typography/types';
import { Icon } from '@fabric/icon';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { HeroHeadlineSize } from '../enums';
import { HeroHeadlineProps } from '../base-modal.types';

import { StyledIcon, StyledGrimIcon, StyledRoot, StyledSubtext } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '~components/flex';
import { LayoutBox } from '~components/layout-box';
import { Headline } from '~components/headline';
import { BodyText } from '~components/body-text';
import { IconTile } from '~components/icon-tile';
import { IconV2, IconV2Name } from '~components/icon-v2';

const SIZE_TO_HEADING_TYPE_MAP: Record<HeroHeadlineSize, TypographyProps['variant']> = {
	[HeroHeadlineSize.LARGE]: 'h2',
	[HeroHeadlineSize.MEDIUM]: 'h3',
	[HeroHeadlineSize.SMALL]: 'h4',
};

export const HeroHeadline: FunctionComponent<PropsWithChildren<HeroHeadlineProps>> = ({
	children,
	hasSeparator,
	icon,
	iconColor = 'primary-strong',
	size = HeroHeadlineSize.SMALL,
	subtext,
	text,
	...rest
}) => {
	const jadeIconComponent =
		typeof icon === 'string' ? (
			<StyledIcon>
				<Icon brand={true} name={icon} />
			</StyledIcon>
		) : (
			<StyledGrimIcon>{icon}</StyledGrimIcon>
		);

	const encoreIconComponent = icon ? (
		<Flex justifyContent="center">
			<IconTile icon={<IconV2 color={iconColor} name={icon as IconV2Name} size={32} />} size={64} />
		</Flex>
	) : null;

	const jadeHeader = text && (
		<Typography sx={icon ? { mb: 0, mt: '20px' } : {}} variant={SIZE_TO_HEADING_TYPE_MAP[size]}>
			{text}
		</Typography>
	);

	const encoreHeader = text && (
		<Flex justifyContent="center">
			<LayoutBox paddingBottom={subtext ? 1 : undefined} paddingTop="24px">
				<Headline color="neutral-strong" size="small">
					{text}
				</Headline>
			</LayoutBox>
		</Flex>
	);

	const jadeSubtext = subtext ? <StyledSubtext>{subtext}</StyledSubtext> : null;
	const encoreSubtext = subtext ? (
		<Flex justifyContent="center">
			<BodyText>{subtext}</BodyText>
		</Flex>
	) : null;

	return (
		<StyledRoot hasSeparator={hasSeparator} {...rest}>
			{ifFeature('encore', encoreIconComponent, jadeIconComponent)}
			{ifFeature('encore', encoreHeader, jadeHeader)}
			{ifFeature('encore', encoreSubtext, jadeSubtext)}
			{children}
		</StyledRoot>
	);
};
