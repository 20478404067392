import React from 'react';
import { Paper } from '@mui/material';
import { RoundedBoxProps } from './types';
import { useRoundedBoxStyles } from './rounded-box.styles';

/**
 * @deprecated RoundedBox should no longer be used. Instead, StyledBox is the preferred option.
 */
export const RoundedBox = ({
	backgroundColor,
	border,
	borderRadius,
	children,
	className,
	margin,
	padding,
	...rest
}: RoundedBoxProps) => {
	const { classes, cx } = useRoundedBoxStyles({
		backgroundColor,
		border,
		borderRadius,
		margin,
		padding,
	});

	return (
		<Paper className={cx(classes.root, className)} elevation={0} {...rest}>
			{children}
		</Paper>
	);
};
