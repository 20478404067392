import { FilterItem } from '@fabric/transfer-list/types';

interface State {
	firstFilterItems: FilterItem[];
	secondFilterItems: FilterItem[];
	firstSelectedFilter?: FilterItem;
	secondSelectedFilter?: FilterItem;
}

interface Payload {
	allFilterItems: FilterItem[];
	onChange: (data: { filters: FilterItem[] }) => void;
	selectedFilterItem: { item: FilterItem; parentItem: FilterItem };
}

export enum FilterActions {
	CLEAR_FIRST_FILTER = 'clearFirstFilter',
	CLEAR_SECOND_FILTER = 'clearSecondFilter',
	SELECT_FIRST_FILTER = 'selectFirstFilter',
	SELECT_SECOND_FILTER = 'selectSecondFilter',
}

type Action =
	| {
			type: 'clearFirstFilter' | 'clearSecondFilter';
			payload: Pick<Payload, 'allFilterItems' | 'onChange'>;
	  }
	| {
			type: 'selectFirstFilter' | 'selectSecondFilter';
			payload: Payload;
	  };

export type { Action, Payload, State };
