import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { PageHeaderProps } from './types';
import { useStyles } from './styles';
import { BackButton } from './back-button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { Flex } from '~components/flex';
import { Headline } from '~components/headline';
import { LayoutBox } from '~components/layout-box';

export type PageHeaderComponent = FC<PropsWithChildren<PageHeaderProps>> & { BackButton: typeof BackButton };

export const PageHeader: PageHeaderComponent = forwardRef<HTMLDivElement, PageHeaderProps>(
	({ back, biId, children, divider = true, icon, left, noMargin = divider, onBack, right, subtitle, title }, ref) => {
		const { classes } = useStyles({ back, children, divider, left, noMargin, right, title });

		return (
			<div className={classes.root} ref={ref}>
				{typeof back === 'string' && back !== '' ? (
					<BackButton biId={biId} onClick={onBack}>
						{back}
					</BackButton>
				) : (
					back
				)}
				{ifFeature(
					'encore',
					<div className={classes.titlesOuter}>
						<div className={classes.titlesInner}>
							<Flex alignItems="end" columnGap={2} flexWrap="wrap">
								{typeof title === 'string' ? (
									<div className={classes.title}>
										<Headline component="h1" size="extra-large">
											{title}
										</Headline>
									</div>
								) : (
									title
								)}
								{subtitle ? (
									<LayoutBox marginBottom="6px">
										<div className={classes.subtitle}>
											<Headline color="neutral-weak" component="h3" size="medium">
												{subtitle}
											</Headline>
										</div>
									</LayoutBox>
								) : null}
							</Flex>
						</div>
					</div>,

					<div className={classes.titlesOuter}>
						<div className={classes.titlesInner}>
							<div className={classes.icon}>{icon}</div>
							{typeof title === 'string' ? <h2 className={classes.title}>{title}</h2> : title}
							{subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
						</div>
					</div>
				)}

				{left || left === 0 ? <div className={classes.left}>{left}</div> : null}
				{right || right === 0 ? <div className={classes.right}>{right}</div> : null}
				{children ? <div className={classes.children}>{children}</div> : null}
			</div>
		);
	}
) as PageHeaderComponent;
PageHeader.BackButton = BackButton;

PageHeader.defaultProps = {
	icon: undefined,
	subtitle: '',
};
