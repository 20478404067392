import React from 'react';
import { type ActionTileElement, type ActionTileProps, type ActionTileComponent } from './action-tile.types';
import { Base as TileV2Base, type BaseProps as TileV2BaseProps } from '~components/tile-v2/base';
import { formatBaseProps } from '~utils';
import { useActionTileStyles } from './action-tile.styles';
import { LinkUnstyled } from '~components/link/unstyled';
import { Avatar } from '~components/tile-v2/avatar';

export const ActionTile = (<C extends ActionTileElement>(props: ActionTileProps<C>) => {
	const { classes } = useActionTileStyles();
	const { component = 'button' } = props;

	return (
		<TileV2Base
			{...(formatBaseProps(props) as TileV2BaseProps)}
			classes={classes}
			component={component === 'a' || props.href ? LinkUnstyled : component}
		/>
	);
}) as ActionTileComponent;

ActionTile.Avatar = Avatar;
