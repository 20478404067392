import { BasicPathTraverser } from './basic-path-traverser';
import { traverseWithKey } from './traverse-with-key';
import { UpdatePathData, MenuListPath } from '../../types';

export function getUpdatedPath({ activePath, getDisabled, getTotal, key }: UpdatePathData): MenuListPath {
	const pathTraverser = new BasicPathTraverser({
		getTotal,
		getDisabled,
		initialPath: activePath || [],
	});

	traverseWithKey(pathTraverser, key);

	return pathTraverser.path;
}
