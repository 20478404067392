import React, { Fragment, ReactElement } from 'react';
import classnames from 'classnames';
import useStyles from './step-indicator.style';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText } from '~components/body-text';

interface StepIndicatorProps {
	/*
	 * The step number that should be displayed as active/current
	 */
	stepNumber: number;
	/*
	 * The total number of steps
	 */
	totalSteps: number;
}

/*
 * StepIndicator
 * Shows the current and total number of steps for the multi-step modal
 */

export function StepIndicator({ stepNumber, totalSteps, ...rest }: StepIndicatorProps): ReactElement {
	const { classes } = useStyles();
	const currentStep = stepNumber + 1;

	return (
		<div className={classes.stepCounter} {...rest}>
			{ifFeature(
				'encore',
				<BodyText size="extra-small" weight="medium" whiteSpace="nowrap">
					{window.jQuery ? $.__('Step %1 of %2', currentStep, totalSteps) : `Step ${currentStep} of ${totalSteps}`}
				</BodyText>,
				window.jQuery ? $.__('Step %1 of %2', currentStep, totalSteps) : `Step ${currentStep} of ${totalSteps}`
			)}
			{Array.from(Array(totalSteps).keys()).map(index => (
				<Fragment key={`step-indicator-${index}`}>
					<span
						className={classnames({
							[classes.dot]: true,
							[classes.dotCurrent]: index === stepNumber,
							[classes.dotCompleted]: index < stepNumber,
						})}
					/>
					{index !== totalSteps - 1 && <span className={classes.separator} />}
				</Fragment>
			))}
		</div>
	);
}
