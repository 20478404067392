// @startCleanup encore
import {
	FileTypeEsig16x20,
	FileTypeEsig20x24,
	FileTypeEsig23x28,
	FileTypeEsig30x36,
	FileTypeExcel16x20,
	FileTypeExcel20x24,
	FileTypeExcel23x28,
	FileTypeExcel30x36,
	FileTypeImage16x20,
	FileTypeImage20x24,
	FileTypeImage23x28,
	FileTypeImage30x36,
	FileTypeLink16x20,
	FileTypeLink20x24,
	FileTypeLink23x28,
	FileTypeLink30x36,
	FileTypeMail16x20,
	FileTypeMail20x24,
	FileTypeMail23x28,
	FileTypeMail30x36,
	FileTypePowerpoint12x16,
	FileTypePowerpoint16x20,
	FileTypePowerpoint20x24,
	FileTypePowerpoint23x28,
	FileTypePowerpoint30x36,
	FileTypeText16x20,
	FileTypeText20x24,
	FileTypeText23x28,
	FileTypeText30x36,
	FileTypeUnknown16x20,
	FileTypeUnknown20x24,
	FileTypeUnknown23x28,
	FileTypeUnknown30x36,
	FileTypeZip16x20,
	FileTypeZip20x24,
	FileTypeZip23x28,
	FileTypeZip30x36,
} from '@bamboohr/grim';

export default {
	'fab-file-type-esig-16x20': FileTypeEsig16x20,
	'fab-file-type-esig-20x24': FileTypeEsig20x24,
	'fab-file-type-esig-23x28': FileTypeEsig23x28,
	'fab-file-type-esig-30x36': FileTypeEsig30x36,
	'fab-file-type-excel-16x20': FileTypeExcel16x20,
	'fab-file-type-excel-20x24': FileTypeExcel20x24,
	'fab-file-type-excel-23x28': FileTypeExcel23x28,
	'fab-file-type-excel-30x36': FileTypeExcel30x36,
	'fab-file-type-image-16x20': FileTypeImage16x20,
	'fab-file-type-image-20x24': FileTypeImage20x24,
	'fab-file-type-image-23x28': FileTypeImage23x28,
	'fab-file-type-image-30x36': FileTypeImage30x36,
	'fab-file-type-link-16x20': FileTypeLink16x20,
	'fab-file-type-link-20x24': FileTypeLink20x24,
	'fab-file-type-link-23x28': FileTypeLink23x28,
	'fab-file-type-link-30x36': FileTypeLink30x36,
	'fab-file-type-mail-16x20': FileTypeMail16x20,
	'fab-file-type-mail-20x24': FileTypeMail20x24,
	'fab-file-type-mail-23x28': FileTypeMail23x28,
	'fab-file-type-mail-30x36': FileTypeMail30x36,
	'fab-file-type-powerpoint-12x16': FileTypePowerpoint12x16,
	'fab-file-type-powerpoint-16x20': FileTypePowerpoint16x20,
	'fab-file-type-powerpoint-20x24': FileTypePowerpoint20x24,
	'fab-file-type-powerpoint-23x28': FileTypePowerpoint23x28,
	'fab-file-type-powerpoint-30x36': FileTypePowerpoint30x36,
	'fab-file-type-text-16x20': FileTypeText16x20,
	'fab-file-type-text-20x24': FileTypeText20x24,
	'fab-file-type-text-23x28': FileTypeText23x28,
	'fab-file-type-text-30x36': FileTypeText30x36,
	'fab-file-type-unknown-16x20': FileTypeUnknown16x20,
	'fab-file-type-unknown-20x24': FileTypeUnknown20x24,
	'fab-file-type-unknown-23x28': FileTypeUnknown23x28,
	'fab-file-type-unknown-30x36': FileTypeUnknown30x36,
	'fab-file-type-zip-16x20': FileTypeZip16x20,
	'fab-file-type-zip-20x24': FileTypeZip20x24,
	'fab-file-type-zip-23x28': FileTypeZip23x28,
	'fab-file-type-zip-30x36': FileTypeZip30x36,
};
// @endCleanup encore
