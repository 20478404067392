import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ constructs, mode, palette, spacing, typography }) => ({
	root: ifFeature(
		'encore',
		{
			alignItems: 'center',
			fill: constructs.text.neutral.medium,
			display: 'flex',
			gap: ifFeature('encore', spacing(1), spacing(0.5)),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		{
			backgroundColor: palette.gray[100],
			boxSizing: 'border-box',
			display: 'flex',
			height: 58,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: 12,
		}
	),
	addFilterButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginLeft: ifFeature('encore', 0, 9),
	},
	floatingIcon: {
		fill: palette.gray[700],
		'&:hover': {
			fill: palette.primary.main,
		},
	},
	plusSelectIcon: {
		alignItems: 'center',
		display: 'flex',
		fontSize: typography.biggie?.fontSize,
		height: '28px',
		justifyContent: 'center',
		width: '30px',
	},
}));
