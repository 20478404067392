import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<{ disabled?: boolean; large?: boolean }, 'label'>()((theme, _params, classes) => {
	const { disabled, large } = _params;
	const { palette, mode, mixins } = theme;
	const { label } = classes;

	let handleBackgroundColor = palette.primary.main;
	let labelColor = ifFeature('encore', palette.grey[400], palette.gray[500]);
	if (disabled) {
		handleBackgroundColor = palette.gray[300];
		labelColor = palette.gray[300];
	}
	if (mode === ModeOptions.Dye) {
		handleBackgroundColor = dyeColors.main;
		labelColor = dyeColors.lighter;
	}

	return {
		toggle: {
			boxSizing: 'border-box',
			cursor: 'pointer',
			display: 'inline-flex',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			position: 'relative',
			':has(> input:disabled)': {
				cursor: 'default',
			},
		},
		label: ifFeature(
			'encore',
			{
				backgroundColor: labelColor,
				boxShadow: mixins.boxShadow('100', palette.grey[900]),
				border: 'none',
				borderRadius: '25px',
				height: large ? '32px' : '20px',
				width: large ? '56px' : '36px',
				// rounded handle
				'&:before': {
					backgroundColor: palette.common.white,
					borderRadius: '25px',
					content: '""',
					height: large ? '24px' : '16px',
					width: large ? '24px' : '16px',
					left: large ? '4px' : '2px',
					opacity: 1,
					position: 'absolute',
					top: large ? '4px' : '2px',
					transition: 'left 200ms cubic-bezier(0.25, 0.1, 0.25, 1)',
					boxShadow: `1px 1px 0 0 ${mixins.alpha(palette.common.black, 0.1)}`,
					backgroundImage: mixins.inlineSvg(
						'<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#000"><path xmlns="http://www.w3.org/2000/svg" d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>',
						{ fill: palette.gray[400] }
					),
					backgroundSize: large ? '12px' : '8px',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				},
			},
			{
				backgroundColor: labelColor,
				border: 'none',
				borderRadius: '25px',
				height: large ? '20px' : '18px',
				width: large ? '36px' : '32px',
				// rounded handle
				'&:before': {
					backgroundColor: palette.common.white,
					borderRadius: '25px',
					content: '""',
					height: large ? '18px' : '16px',
					width: large ? '18px' : '15px',
					left: '1px',
					opacity: 1,
					position: 'absolute',
					top: '1px',
					transition: 'left 200ms cubic-bezier(0.25, 0.1, 0.25, 1)',
				},
			}
		),
		input: {
			height: '1px',
			opacity: 0,
			position: 'absolute',
			width: '1px',
			[`&:checked + .${label}`]: ifFeature(
				'encore',
				{
					backgroundColor: handleBackgroundColor,
					boxShadow: `inset 2px 2px 0 1px ${mixins.alpha(palette.grey[900], 0.1)}`,
					'&:before': {
						left: large ? '28px' : '18px',
						backgroundImage: mixins.inlineSvg(
							'<svg viewBox="0 0 64 512" xmlns="http://www.w3.org/2000/svg" fill="#000"><path xmlns="http://www.w3.org/2000/svg" d="M32 0C49.7 0 64 14.3 64 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V32C0 14.3 14.3 0 32 0z"></path></svg>',
							{ fill: palette.primary.main }
						),
						backgroundSize: large ? '2px 12px' : '2px 8px',
					},
				},
				{
					backgroundColor: handleBackgroundColor,
					boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
					'&:before': {
						left: large ? '17px' : '16px',
					},
				}
			),
			[`&:focus + .${label}`]: ifFeature('encore', {
				boxShadow: `0 0 0 2px ${palette.primary.light}, 0 0 0 4px ${
					palette.primary.lightest
				}, inset 2px 2px 0 1px ${mixins.alpha(palette.grey[900], 0.1)}`,
			}),
		},
	};
});
