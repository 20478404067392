// eslint-disable-next-line no-use-before-define
import React, { useEffect, useRef, useState, FC, PropsWithChildren } from 'react';
import { animate } from 'framer-motion';
import { Typography } from '@mui/material';
import { Flex, FlexProps } from '../flex';
import { MotionTypographyProps } from './types';
import { useStyles } from './motion-typography.styles';

export const MotionTypography: FC<PropsWithChildren<MotionTypographyProps & FlexProps>> = ({
	from: initial = 0,
	to,
	prefix,
	suffix,
	TypographyProps = {},
	PrefixProps = {},
	SuffixProps = {},
	formatter = v => v,
	animateDuration = 1,
	...rest
}) => {
	const nodeRef = useRef<HTMLSpanElement>(null);
	const [from, setFrom] = useState(initial);
	const { classes, cx } = useStyles();

	useEffect(() => {
		const node = nodeRef.current;
		if (node) {
			const controls = animate(from, to, {
				duration: animateDuration,
				onUpdate: value => (node.textContent = formatter(value.toFixed(0))),
				onComplete: () => setFrom(to),
			});
			return () => controls.stop();
		}
	}, [from, to, animateDuration, formatter]);

	return (
		<Flex alignItems="baseline" {...rest}>
			{prefix && (
				<Typography {...TypographyProps} {...PrefixProps} className={cx(classes.text, TypographyProps.className)}>
					{prefix}
				</Typography>
			)}
			<Typography {...TypographyProps} className={cx(classes.text, TypographyProps.className)} ref={nodeRef} />
			{suffix && (
				<Typography {...TypographyProps} {...SuffixProps} className={cx(classes.text, TypographyProps.className)}>
					{suffix}
				</Typography>
			)}
		</Flex>
	);
};
