import { FloatingIconButton } from '@fabric/button';
import { FileIcon } from '@fabric/file-icon';
import { IconV2 } from '~components/icon-v2';
import Toggle from '@fabric/toggle';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * GOTCHA: Need to assess if Tooltip is still useful for this component and add it in if so
 */
// import {
// 	MAKE_SIGNATURE_OPTIONAL,
// 	MAKE_SIGNATURE_REQUIRED,
// 	SIGNATURE_OPTIONAL,
// 	SIGNATURE_REQUIRED,
// } from './constants';
import {
	AttachmentStyled,
	/**
	 * GOTCHA: Need to assess if Tooltip is still useful for this component and add it in if so
	 */
	// bhrToggleStyles,
	DetailStyled,
	FileStyled,
	IconStyled,
	InfoStyled,
	RemoveButtonStyled,
	TitleStyled,
	TypeStyled,
} from './styles';
import { AttachmentFileCardProps } from '@fabric/file-card';
import { FileType } from '@fabric/file-icon/types';
import { isFileTypeEsig } from '../../utils';
import { CircleX12x12 } from '@bamboohr/grim';

export const AttachmentFileCard: FunctionComponent<PropsWithChildren<AttachmentFileCardProps>> = props => {
	const { children, fileId, fileName = '', fileType = '', onRemove, onToggle, theme = 'default', toggleState = false } = props;
	const fileNameDisplay = children || fileName;
	const fileNameTitle = typeof children === 'string' ? children : fileName;
	let fileTypeDisplay = fileType.toUpperCase();
	const isEsig = isFileTypeEsig(fileType);
	if (isEsig) {
		fileTypeDisplay = 'Esignature';
	}

	function renderEsig() {
		if (!onToggle || !isEsig) {
			return null;
		}
		const toggleFile = () => {
			onToggle(fileId);
		};
		return <Toggle isChecked={toggleState} onChange={toggleFile} size="small" type="required" />;
	}

	function renderRemoveButton() {
		if (!onRemove) {
			return null;
		}
		const removeFile = () => {
			onRemove(fileId);
		};
		return (
			<RemoveButtonStyled>
				<FloatingIconButton
					aria-label={`${window.jQuery ? $.__('Remove File') : 'Remove File'}`}
					clickAction={removeFile}
					hasBounding={false}
					icon={ifFeature('encore', <IconV2 name="circle-xmark-solid" size={12} />, <CircleX12x12 />)}
					secondary={true}
					type="button"
				/>
			</RemoveButtonStyled>
		);
	}
	// function renderTooltip() {
	// 	return null;
	/*
	GOTCHA: Need to assess if Tooltip is still useful for this component and add it in if so
	const toolTip = {
		'data-tip-class': 'bhrTooltip--autoWidth',
		'data-tip-content': toggleState ? MAKE_SIGNATURE_OPTIONAL : MAKE_SIGNATURE_REQUIRED,
		'data-tip-lead': toggleState ? SIGNATURE_OPTIONAL : SIGNATURE_REQUIRED,
		'data-tip-delay': 800,
	};
	const inputClassName = classNames('bhrToggle__input', 'js-fc-toggle-required');
	const inputName = `fc-toggle-${ fileId }`;
	return (
		<label className="bhrToggle bhrToggle--require" { ...toolTip }>
			<input
				checked={ toggleState }
				className="bhrToggle__input js-fc-toggle-required"
				data-file-id={ fileId }
				name={ inputName }
				readOnly
				type="checkbox"
			/>
			<div className="bhrToggle__label"></div>
			<div className="bhrToggle__handle"></div>
		</label>
	);
	*/
	// }

	return (
		<AttachmentStyled aria-labelledby={`filecard-${fileId}`} role="region" variant={theme}>
			<FileStyled>
				<DetailStyled>
					<TitleStyled id={`filecard-${fileId}`} title={fileNameTitle}>
						{fileNameDisplay}
					</TitleStyled>
					{renderRemoveButton()}
				</DetailStyled>
				<InfoStyled>
					<TypeStyled>
						<IconStyled>
							<FileIcon esig={isEsig} type={fileType as FileType} />
						</IconStyled>
						{fileTypeDisplay}
					</TypeStyled>
					<div className="FileCard__esig">{renderEsig()}</div>
				</InfoStyled>
			</FileStyled>
		</AttachmentStyled>
	);
};
