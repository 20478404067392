import React, { Fragment } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { RequiredToggleProps } from '@fabric/toggle';
import { makeUid } from '../../../../utils';
import { useStyles } from './required-toggle.styles';
import { BaseIconV2 } from '~components/icon-v2/base-icon-v2';

/**
 * ### `@fabric/toggle/Required`
 * @property {string}	[inputRef]		The Name to be passed as an Ref (for Validation)
 * @property {function}	[onChange]		Callback Function
 * @property {false}	[isDisabled]	Is the Toggle disabled
 * @property {false}	[isRequired]	Is the Toggle required
 * @property {false}	[isChecked]		Is the Toggle Checked
 * @property {string}	[name]			The Name for the Toggle
 * @property {string}	[size]			The Size of the Toggle
 *
 * @returns {JSX}
 * */
export const RequiredToggle = (props: RequiredToggleProps) => {
	const { controlled = false, onChange, inputRef, isChecked, isDisabled, isRequired, name, id, size = 'medium' } = props;
	const { classes } = useStyles({ size });
	const uniqueId = makeUid();
	const toggleName = name || 'fab-requiredToggle';
	const toggleId = id || `fab-required-Toggle--${uniqueId}`;
	let iconSize = 10;

	if (size === 'small') {
		iconSize = 8;
	} else if (size === 'large') {
		iconSize = 14;
	}

	return (
		<Fragment>
			<label className={classes.toggle} htmlFor={toggleId}>
				<input
					aria-checked={isChecked}
					checked={controlled ? isChecked || false : undefined}
					className={classes.input}
					defaultChecked={isChecked || false}
					disabled={isDisabled}
					id={toggleId}
					name={toggleName}
					onChange={onChange}
					ref={inputRef}
					required={isRequired}
					role="switch"
					type="checkbox"
				/>
				<span className={classes.label}>
					<span className={classes.value}>
						{ifFeature(
							'encore',
							<BaseIconV2 className={classes.icon} height={iconSize} name="asterisk-solid" width={iconSize} />,
							'*'
						)}
					</span>
				</span>
			</label>
		</Fragment>
	);
};
