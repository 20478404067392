import React, { type ElementType } from 'react';

import { SideSubNavigationLinkProps } from './link.types';
import { useStyles } from './link.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import { LinkUnstyled } from '~components/link/unstyled';

export function Link<C extends ElementType = typeof LinkUnstyled>(props: SideSubNavigationLinkProps<C>) {
	const { active, activeIcon, children, disabled, icon, ...rest } = props;
	const { classes } = useStyles({ active });
	const currentIcon = active && activeIcon ? activeIcon : icon;

	return (
		<LinkUnstyled {...(rest as any)} className={disabled ? classes.disabled : classes.root}>
			{ifFeature(
				'encore',
				typeof currentIcon === 'string' ? <IconV2 name={currentIcon as IconV2Name} size={16} /> : null,
				currentIcon
			)}
			{children}
		</LinkUnstyled>
	);
}
