/* eslint-disable react/forbid-component-props */
// eslint-disable-next-line no-use-before-define
import React, { useState, ReactElement, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { X8x8, X10x10 } from '@bamboohr/grim';
import { FormControl, InputBase, InputLabel } from '@mui/material';
import { TextField } from '~components/text-field';
import { Autocomplete } from './autocomplete';
import {
	DEFAULT_CLEAR_TEXT,
	DEFAULT_CLOSE_TEXT,
	DEFAULT_LOADING_TEXT,
	DEFAULT_OPEN_TEXT,
	defaultGetTagsLimitLabel,
} from './autocomplete.domain';
import { getHasSelection, getInputBiId } from './autocomplete-multiple.domain';
import {
	useStyles,
	/* @startCleanup encore */
	useStylesJade,
	/* @endCleanup encore */
} from './autocomplete-multiple.styles';
import {
	AutocompleteMultipleProps,
	/* @startCleanup encore */
	AutocompleteMultipleStyleProps,
	/* @endCleanup encore */
	AutocompleteMultipleOnChangeParam,
} from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { type IconV2Size } from '~components/icon-v2';
import { BaseIconV2 } from '~components/icon-v2/base-icon-v2';

function AutocompleteMultipleComponent<T>(
	{
		biId,
		/* @startCleanup encore */
		classes: classesJade = {},
		className,
		/* @endCleanup encore */
		closeOnSelect = true,
		disabled = false,
		filterSelectedOptions = true,
		getTagsLimitLabel = defaultGetTagsLimitLabel,
		hasClearButton = false,
		hasPopupToggle = false,
		id,
		inputRef,
		inputProps = {},
		inputValue,
		isInputTextField = false,
		label,
		loading = false,
		loadingText = DEFAULT_LOADING_TEXT,
		onChange,
		placeholder,
		renderInput,
		required = false,
		size,
		status,
		tagsLimit = -1,
		value,
		variant,
		...restAutocompleteMultipleProps
	}: AutocompleteMultipleProps<T>,
	ref: Ref<HTMLInputElement>
): ReactElement {
	const { classes } = useStyles({ size, variant });
	/* @startCleanup encore */
	const styleProps: AutocompleteMultipleStyleProps = {
		label,
	};
	const { classes: stylesJade } = useStylesJade(styleProps);
	/* @endCleanup encore */

	const [defaultHasSelection, setDefaultHasSelection] = useState(() => getHasSelection(value));

	const hasSelection = value ? getHasSelection(value) : defaultHasSelection;

	return (
		<Autocomplete
			{...restAutocompleteMultipleProps}
			autoHighlight={false}
			autoSelect={false}
			blurOnSelect={false}
			ChipProps={{
				deleteIcon: ifFeature(
					'encore',
					<BaseIconV2
						ariaLabel={window.jQuery ? $.__('Clear') : 'Clear'}
						height={12 satisfies IconV2Size}
						name="circle-x-solid"
						width={12 satisfies IconV2Size}
					/>,
					<div>
						<X8x8 />
					</div>
				),
			}}
			classes={ifFeature('encore', classes, classesJade)}
			/* @startCleanup encore */
			className={ifFeature(
				'encore',
				undefined,
				classNames(
					{
						[stylesJade.root]: isInputTextField,
						[stylesJade.rootOld]: !isInputTextField,
					},
					className
				)
			)}
			clearIcon={ifFeature(
				'encore',
				undefined,
				<div className={classNames(stylesJade.defaultClearIcon, classesJade.defaultClearIcon)}>
					<X10x10 />
				</div>
			)}
			/* @endCleanup encore */
			clearOnBlur={true}
			clearOnEscape={false}
			clearText={DEFAULT_CLEAR_TEXT}
			closeOnSelect={closeOnSelect}
			closeText={DEFAULT_CLOSE_TEXT}
			disabled={disabled}
			disableListWrap={false}
			disablePortal={false}
			filterSelectedOptions={filterSelectedOptions}
			fullWidth={true}
			getTagsLimitLabel={getTagsLimitLabel}
			handleHomeEndKeys={true}
			hasClearButton={hasClearButton}
			hasPopupToggle={hasPopupToggle}
			id={id}
			includeInputInList={false}
			inputValue={inputValue}
			isAnyInputValueAllowed={false}
			loading={loading}
			loadingText={loadingText}
			multiple={true}
			onChange={handleChange}
			openOnFocus={false}
			openText={DEFAULT_OPEN_TEXT}
			ref={ref}
			renderInput={params => {
				params.inputProps['data-bi-id'] = getInputBiId(biId);
				params.placeholder = placeholder;

				if (renderInput) {
					return renderInput(params);
				}

				/* @startCleanup encore */
				// Note: The below 'if (!isInputTextField)' conditional is kept for backwards compatibility while we upgrade all usages of AutocompleteMultiple in the app to use the TextField as the input.
				// TODO: Remove this if statement and its contents once all existing usages in the app use the TextField as the input.
				if (!isInputTextField && ifFeature('encore', false, true)) {
					return (
						<React.Fragment>
							<FormControl fullWidth={true} ref={params.InputProps.ref}>
								{label && (
									<InputLabel {...params.InputLabelProps} disabled={params.disabled} shrink={true}>
										{label}
									</InputLabel>
								)}
								<InputBase
									{...params.InputProps}
									disabled={params.disabled}
									fullWidth={true}
									id={params.id}
									inputProps={{ ...inputProps, ...params.inputProps }}
									inputRef={inputRef}
									placeholder={hasSelection ? undefined : params.placeholder}
								/>
							</FormControl>
						</React.Fragment>
					);
				}
				/* @endCleanup encore */

				return (
					<div ref={params.InputProps.ref}>
						<TextField
							biId={getInputBiId(biId)}
							disabled={params.disabled}
							id={params.id}
							InputLabelProps={params.InputLabelProps}
							inputProps={{
								...params.inputProps,
								...inputProps,
							}}
							// eslint-disable-next-line react/jsx-no-duplicate-props
							InputProps={ifFeature('encore', params.InputProps, {
								...params.InputProps,
								className: stylesJade.textFieldInputRoot,
							})}
							label={label}
							placeholder={hasSelection ? undefined : params.placeholder}
							ref={inputRef}
							required={required}
							size={size}
							status={status}
							variant={variant}
							width={100}
						/>
					</div>
				);
			}}
			selectOnFocus={false}
			size={ifFeature('encore', size, 'small')}
			tagsLimit={tagsLimit}
			value={value}
			variant={variant}
		/>
	);

	function handleChange(param: AutocompleteMultipleOnChangeParam<T>, event: React.SyntheticEvent): void {
		setDefaultHasSelection(getHasSelection(param.value));

		onChange(param, event);
	}
}

export const AutocompleteMultiple = forwardRef(AutocompleteMultipleComponent);
