import { renderProp } from '@fabric/utils';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { BaseModal } from '~components/base-modal';
import { FullScreenModalBodyProps } from '../full-screen-modal.types';
import { useBodyStyles } from './body.styles';

export const Body: FunctionComponent<PropsWithChildren<FullScreenModalBodyProps>> = props => {
	const { children, noPadding = false, renderFooter, renderHeader, ...rest } = props;
	const { classes } = useBodyStyles({ noPadding });

	const header = renderProp(renderHeader);
	const footer = renderProp(renderFooter);

	return (
		<BaseModal.Body {...rest}>
			<div className={classes.body}>
				<div className={classes.roundTopCorners}>{header}</div>
				<div className={classes.content}>{children}</div>
				<div className={classes.roundBottomCorners}>{footer}</div>
			</div>
		</BaseModal.Body>
	);
};
