import { makeStyles } from '~styles';
import type { InterstitialProps } from './types';
import { cardSizes } from '~components/card/card.styles';
import type { CardSize } from '../card';

export const useStyles = makeStyles<Pick<InterstitialProps, 'cardSize'>>()((theme, props) => {
	const {
		constructs,
		mixins: { boxShadow },
		spacing,
	} = theme;
	const { cardSize } = props;

	return {
		card: {
			backgroundColor: constructs.surface.neutral.inverted,
			borderRadius: spacing(2),
			boxShadow: boxShadow('500', constructs.surface.neutral.xxStrong),
			boxSizing: 'border-box',
			display: 'inline-grid',
			gap: spacing(2),
			padding: spacing(2.5),
			width: cardSizes[cardSize as CardSize],
		},
	};
});
