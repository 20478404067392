// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { makeStyles } from '~styles';
import { Box, Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { NoteInputContainerProps } from './note-input-container-props';

const useStyles = makeStyles<NoteInputContainerProps>()(({ spacing }: Theme, { inputHasLabel, notePlacement }) => {
	return ifFeature(
		'encore',
		{
			root: {
				alignItems: notePlacement === 'block' ? 'start' : 'center',
				display: 'flex',
				flexDirection: notePlacement === 'block' ? 'column' : 'row',
				flexGrow: 1,
				gap: spacing(1),
				width: '100%',
			},
		},
		{
			root: {
				marginTop: inputHasLabel ? spacing(2.5) : undefined,
			},
		}
	);
});

export const NoteInputContainer: FC<PropsWithChildren<NoteInputContainerProps>> = ({ children, inputHasLabel, notePlacement }) => {
	const { classes } = useStyles({ inputHasLabel, notePlacement });

	return (
		<Box alignItems="center" className={classes.root} display="flex">
			{children}
		</Box>
	);
};
