import classNames from 'classnames';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import { Icon } from '@fabric/icon';
import React from 'react';

import { useStyles } from './banner-icon.styles';
import { BannerIconProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function BannerIcon({ classes = {}, className, icon }: BannerIconProps): JSX.Element {
	const { classes: styles } = useStyles();

	return (
		<div className={classNames(styles.root, className, classes.root)}>
			{ifFeature(
				'encore',
				typeof icon === 'string' ? <IconV2 name={icon as IconV2Name} size={20} /> : null,
				typeof icon === 'string' ? <Icon aria-hidden={true} name={icon} /> : icon
			)}
		</div>
	);
}
