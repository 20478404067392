import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StandardHeadline as BaseModalStandardHeadline } from '~components/base-modal';
import { SheetModalStandardHeadlineProps } from '../sheet-modal.types';
import { useStyles } from './standard-headline.styles';

export const StandardHeadline: FunctionComponent<PropsWithChildren<SheetModalStandardHeadlineProps>> = props => {
	const { classes } = useStyles();

	return (
		<div className={classes.root}>
			<BaseModalStandardHeadline {...props} />
		</div>
	);
};
