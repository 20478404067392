import { makeStyles } from '~styles';
import { InputSize, PickerButtonProps } from './picker-button.types';

export const usePickerButtonStyles = makeStyles<PickerButtonProps>()((theme, props) => {
	const { constructs, mixins, borderRadiuses, palette, transitions, typography } = theme;
	const { disabled, focused, size = 'medium', status, variant = 'form' } = props;

	const heights = {
		small: 30,
		medium: 38,
		large: 46,
	};

	const getHeight = (size: InputSize): number => heights[size];

	const focusVisible = {
		borderLeft: '1px solid transparent',
		borderRadius:
			variant === 'single'
				? `${borderRadiuses[200]} ${borderRadiuses[1000]} ${borderRadiuses[1000]} ${borderRadiuses[200]}`
				: `${borderRadiuses[200]}`,
		boxShadow: `0 0 0 6px ${mixins.alpha(mixins.fieldStatusColor(status, palette, constructs.border.primary.medium), 0.1)}`,
		color: mixins.fieldStatusColor(status, palette, constructs.text.primary.strong),
		fill: constructs.icon.primary.strong,
		height: getHeight(size),
		outline: `2px solid ${mixins.fieldStatusColor(status, palette, constructs.border.primary.medium)}`,
	};

	return {
		root: {
			alignItems: 'center',
			display: 'flex',
		},
		divider: {
			borderLeft: `1px solid ${constructs.border.neutral.weak}`,
			height: getHeight(size) - 14,
		},
		button: {
			...{
				alignItems: 'center',
				backgroundColor: disabled ? constructs.surface.neutral.xxWeak : constructs.surface.neutral.forcedWhite,
				border: 'unset',
				borderLeft: '1px solid transparent',

				borderRadius:
					variant === 'single'
						? `${borderRadiuses[200]} ${borderRadiuses[1000]} ${borderRadiuses[1000]} ${borderRadiuses[200]}`
						: `0 ${borderRadiuses[200]} ${borderRadiuses[200]} 0`,

				color: constructs.text.neutral.weak,
				cursor: disabled ? 'auto' : 'pointer',
				display: 'flex',
				fill: constructs.icon.neutral.medium,
				flex: '0 0 auto',
				fontFamily: typography.fontFamily,
				fontSize: typography.xsmall?.fontSize,
				fontWeight: typography.fontWeightSemibold,
				height: getHeight(size),
				margin: 0,
				padding: '0 13px',
				transition: transitions.create('outline', { duration: 50 }),

				// hover state: only show if hovered and not also focused
				'&:hover:not(:focus):not(:focus-within):not(:focus-visible)':
					!focused && !disabled
						? {
								backgroundColor: constructs.surface.neutral.xWeak,
								borderLeft: '1px solid transparent',
								borderRadius:
									variant === 'single'
										? `${borderRadiuses[200]} ${borderRadiuses[1000]} ${borderRadiuses[1000]} ${borderRadiuses[200]}`
										: borderRadiuses[200],
								fill: constructs.icon.neutral.medium,
								outline: `1px solid ${mixins.fieldStatusColor(status, palette, constructs.border.neutral.medium)}`,
								// input is focused and this button is hovered
								'.Mui-focused &': {
									outlineColor: mixins.fieldStatusColor(status, palette, constructs.border.primary.medium),
								},
							}
						: {},
				'&:focus, &:focus-within, &:focus-visible': focusVisible, // this handles the CSS focus states
			},
			// if the input has the "focus" prop set, add the focusVisible overrides
			...(focused ? focusVisible : {}),
		},
	};
});
