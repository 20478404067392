import React, { useState, ReactElement, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { TransferListSide, TransferListSubcategoryProps } from '../../types';
import { getTabIndex } from '../../etc';
import { useSubcategoryStyles } from './styles';
import { TransferListHoverItem } from '../transfer-list-item';

export const TransferListSubcategory = (props: TransferListSubcategoryProps): ReactElement => {
	const {
		focusedIndex,
		grouped = false,
		onClick,
		onFocus,
		icon,
		index,
		isFocused,
		items,
		disabled,
		name,
		reorderSubcategories,
		subcategoryClasses,
		tabIndex,
		transferListSide,
		transferListItemProps,
	} = props;
	const [isHovering, setIsHovering] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const { classes, cx } = useSubcategoryStyles(props, { props: { classes: subcategoryClasses } });

	const [, drop] = useDrop({
		accept: 'Subcategory',
		hover(item: { name: string; firstIndex: number; lastIndex: number; index: number }, monitor) {
			if (!ref.current || transferListSide !== TransferListSide.RIGHT) return;
			const dragName = item.name;
			const dragIndex = item.index;
			const hoverIndex = index;
			const hoverName = name;

			if (dragName === hoverName) return;

			const monitorOffset = monitor.getClientOffset();
			const hoverBoundingRect: { bottom: number; top: number } = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverActualY = monitorOffset !== null ? monitorOffset.y - hoverBoundingRect.top : 0;

			// if dragging down, continue only when hover is smaller than middle Y
			if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

			// if dragging up, continue only when hover is bigger than middle Y
			if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

			if (reorderSubcategories && typeof reorderSubcategories === 'function') {
				reorderSubcategories(dragName, hoverName);
			}
		},
	});

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'Subcategory',
		item: { name, index },
		collect: monitor => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
	}));

	drag(drop(ref));

	useEffect(() => {
		if (ref?.current && isFocused) {
			ref.current.focus();
		}
	}, [ref, isFocused]);

	const containerClasses = classNames(classes.root, {
		[classes.rootHover]: isHovering,
		[classes.draggingStyles]: isDragging,
	});

	const handleClick = useCallback(
		(event: SyntheticEvent) => {
			if (onClick && typeof onClick === 'function' && !disabled) {
				onClick({ value: { key: 'subcategory' }, rowKey: name, listIndex: index }, event.nativeEvent as PointerEvent, true);
			}
		},
		[disabled, index, onClick, name]
	);

	const handleKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === ' ') {
				event.preventDefault();
				handleClick({ nativeEvent: event } as unknown as SyntheticEvent);
			}
		},
		[handleClick]
	);

	function handleFocus(focused: boolean) {
		if (onFocus && typeof onFocus === 'function') {
			onFocus(focused);
		}
	}

	const renderItems = () => {
		return items.map(item => {
			const { title, children, value } = item;
			return (
				<TransferListHoverItem
					{...transferListItemProps}
					disabled={grouped ? true : item.disabled}
					icon={icon}
					isFocused={focusedIndex === item.displayOrderIndex}
					key={item.rowKey}
					listIndex={item.displayOrderIndex || -2}
					onClick={onClick}
					rightSide={transferListSide === TransferListSide.RIGHT}
					rowKey={item.rowKey}
					tabIndex={getTabIndex(focusedIndex, item.displayOrderIndex)}
					title={title}
					value={value}
				>
					{children}
				</TransferListHoverItem>
			);
		});
	};

	return (
		<div
			aria-label={name}
			className={containerClasses}
			onBlur={() => {
				handleFocus(false);
				setIsHovering(false);
			}}
			onFocus={() => {
				handleFocus(true);
				if (!isDisabled) {
					setIsHovering(true);
				}
			}}
			onMouseOut={() => {
				setIsHovering(false);
			}}
			onMouseOver={() => {
				if (!isDisabled) {
					setIsHovering(true);
				}
			}}
			role="group"
		>
			<div
				aria-selected={false}
				className={classes.subcategoryOption}
				onBlur={() => {
					handleFocus(false);
					setIsHovering(false);
				}}
				onClick={e => {
					if (!isDisabled) {
						handleClick(e);
					}
				}}
				onFocus={() => {
					handleFocus(true);
					if (!isDisabled) {
						setIsHovering(true);
					}
				}}
				onKeyDown={handleKeydown}
				ref={ref}
				role="option"
				tabIndex={tabIndex}
			>
				<div className={classes.container}>
					<div className={cx(classes.leftContent, classes.centerHorizontal)}>
						<div className={classes.title}>{name}</div>
					</div>
					<div className={cx(classes.rightContent, classes.centerHorizontal)}>
						<div className={cx(classes.icon, { [classes.hover]: isHovering })}>{icon}</div>
					</div>
				</div>
			</div>
			<div
				className={classes.childrenContainer}
				onBlur={evt => {
					evt.stopPropagation();
				}}
				onFocus={evt => {
					evt.stopPropagation();
				}}
				onKeyPress={() => null}
				onMouseEnter={() => {
					if (!grouped) {
						setIsDisabled(true);
						setIsHovering(false);
					}
				}}
				onMouseLeave={() => {
					if (!grouped) {
						setIsDisabled(false);
						setIsHovering(true);
					}
				}}
				role="group"
			>
				<div className={classes.children}>{renderItems()}</div>
			</div>
		</div>
	);
};
