import { Chat, ChatSessionResponse } from 'src/types/global';
import api from '../../services/api';
import { reportError } from '../../utils/rollbar';

export const getChatSessions = async (
	CHAT_SESSIONS_ENDPOINT: string,
): Promise<Chat[]> => {
	let chatSessions: Chat[] = [];
	await api
		.get<ChatSessionResponse[]>(CHAT_SESSIONS_ENDPOINT)
		.then((response) => {
			const newChatSessions = response.data.map((chatSession) => {
				if (typeof chatSession.session_name !== 'string') {
					reportError(
						'Invalid session name type. Session name must be a string.',
						new Error(
							`Invalid session name type for session ID: ${chatSession.id}. Session name is of type ${typeof chatSession.session_name}.`,
						),
					);
				}

				const sessionName =
					chatSession.session_name === '' ||
					typeof chatSession.session_name !== 'string'
						? 'Blank Chat Name'
						: chatSession.session_name;

				return {
					name: sessionName,
					id: chatSession.id,
					llmId: chatSession.function_id,
					llmLabel: chatSession.llm_label,
					llmConfig: chatSession.llm_config,
					time_created: new Date(chatSession.time_created),
				};
			});
			chatSessions = newChatSessions;
		})
		.catch((error) => {
			reportError('Error in AI Sandbox: Get chat sessions', error);
		});

	return chatSessions;
};

export function hasCustomLLMConfig(config, llmId) {
	let functionIds = [
		config.QUERY_OPENAI_LLM_FUNCTION_ID,
		config.QUERY_COHERE_LLM_FUNCTION_ID,
		config.QUERY_ANTHROPIC_LLM_FUNCTION_ID,
		config.QUERY_GOOGLE_LLM_FUNCTION_ID,
	];
	return functionIds.includes(llmId);
}

export function buildChatRequest(chatLog, recentPrompt) {
	const mappedMessages = chatLog.map((item) => {
		return [
			{
				role: 'user',
				content: item.chatPrompt,
			},
			{
				role: 'assistant',
				content: item.botMessage,
			},
		];
	});

	const flatMessages = mappedMessages.flat();

	flatMessages.push({
		role: 'user',
		content: recentPrompt,
	});

	return flatMessages;
}
