import { normalizeKey } from '../../../dom-util';

export function moveCursor(keyBoardEvent, inputElement) {
	const { key, shiftKey } = keyBoardEvent;

	let { selectionEnd, selectionStart } = inputElement;

	const normalizedKey = normalizeKey(key);

	if (normalizedKey === 'Home' || normalizedKey === 'End') {
		if (normalizedKey === 'Home') {
			selectionStart = 0;
			selectionEnd = shiftKey ? selectionEnd : selectionStart;
		} else if (normalizedKey === 'End') {
			selectionEnd = inputElement.value.length;
			selectionStart = shiftKey ? selectionStart : selectionEnd;
		}

		inputElement.setSelectionRange(selectionStart, selectionEnd);
	}
}
