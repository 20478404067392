import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ReactNode } from 'react';
import { makeStyles } from '~styles';
import { InPageMessagingType } from './enums';

export const useStyles = makeStyles<{
	body: ReactNode;
	hasAction: boolean;
	hasClose: boolean;
	header: ReactNode;
	isStacked: boolean;
	type: InPageMessagingType;
}>()(({ constructs, palette, spacing, typography }, { body, hasAction, hasClose, header, isStacked, type }) => {
	const typeColors = {
		error: palette.error.main,
		info: palette.info.main,
		neutral: palette.gray[900],
		success: palette.success.main,
		warning: palette.warning.main,
	};

	const borderColor = {
		discovery: constructs.border.discovery.medium,
		error: constructs.border.error.medium,
		info: constructs.border.info.medium,
		neutral: constructs.border.neutral.medium,
		success: constructs.border.success.medium,
		warning: constructs.border.warning.medium,
	};

	// This will test whether the header or body is present, but not both.
	// This is used to determine the position of the icon.
	const bodyXorHeader = (a, b) => (a || b) && !(a && b);

	return ifFeature(
		'encore',
		{
			action: {
				alignItems: 'center',
				display: 'flex',
				height: '32px',
			},
			body: {
				color: palette.gray[800],
				fontSize: typography.medium.fontSize,
				lineHeight: typography.medium.lineHeight,
			},
			close: {
				alignItems: 'center',
				display: 'flex',
				height: '32px',
				marginLeft: hasAction ? spacing(0.5) : undefined,
				'&>.MuiButtonBase-root': {
					fill: type === InPageMessagingType.neutral ? palette.gray[800] : palette[type].dark,
					minWidth: 'unset',
					'&:hover': {
						fill: type === InPageMessagingType.neutral ? palette.gray[600] : palette.gray[800],
					},
				},
			},
			container: {
				backgroundColor: type === InPageMessagingType.neutral ? palette.gray[100] : palette[type].light,
				border: `1px solid ${borderColor[type]}`,
				borderRadius: spacing(2),
				display: 'flex',
				gap: spacing(1.5),
				lineHeight: typography.large.lineHeight,
				padding: spacing(2, 2.5),
			},
			content: {
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				fontWeight: typography.fontWeightMedium,
				justifyContent: 'center',
				marginRight: hasAction || hasClose ? spacing(1.5) : undefined,
				overflow: 'clip',
				padding: spacing(0.5, 0),
				textAlign: 'left',
			},
			header: {
				color: type === InPageMessagingType.neutral ? palette.gray[800] : palette[type].dark,
				fontSize: typography.large.fontSize,
				fontWeight: typography.fontWeightSemibold,
				lineHeight: typography.large.lineHeight,
			},
			iconWrapper: {
				flexShrink: 0,
				padding: '7px 0',
				position: 'relative',
				top: bodyXorHeader(body, header) ? '2px' : '0',
			},
			icon: {
				fill: type === InPageMessagingType.neutral ? palette.gray[800] : palette[type].dark,
			},
		},
		{
			action: {
				alignItems: 'center',
				display: 'flex',
				marginLeft: '30px',
				marginRight: '13px',
			},
			body: {
				color: palette.gray[900],
				fontSize: typography.medium.fontSize,
				lineHeight: typography.medium.lineHeight,
			},
			close: {
				margin: isStacked ? '-9px 0 0 auto' : '-9px -15px 0 auto',
			},
			container: {
				display: 'flex',
				marginBottom: '18px',
				position: 'relative',
				'& + &': {
					borderTop: `solid 1px ${palette.gray[400]}`,
					paddingTop: '18px',
				},
				'&:last-of-type': {
					marginBottom: 0,
				},
			},
			content: {
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				justifyContent: 'center',
				overflow: 'clip',
				textAlign: 'left',
			},
			header: {
				fontSize: typography.biggie.fontSize,
				fontWeight: typography.fontWeightSemibold,
				lineHeight: typography.biggie.lineHeight,
				color: typeColors[type],
			},
			iconWrapper: {
				marginTop: '6px',
				paddingRight: '15px',
			},
			icon: {
				fill: typeColors[type],
			},
		}
	);
});
