// eslint-disable-next-line no-use-before-define
import React, { forwardRef, FC, PropsWithChildren } from 'react';
import { TextareaAutosize } from '@mui/material';
import { TextAreaProps } from './types';
import { InputWrapper } from '~components/form/components/input-wrapper';
import { useReadableContext } from '~components/readable';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './text-area.styles';

/**
 *
 * Text input for all forms and such.
 * The `ref` is forwarded to the Input.
 */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
	let appliedProps = props;
	const { viewMode = false } = props;
	const { isReadable } = useReadableContext();

	const { classes, cx } = useStyles(props);

	if (isReadable || viewMode) {
		const { placeholder, ...rest } = props;
		appliedProps = rest;
	}

	const {
		autoFocus,
		className,
		disabled = false,
		id,
		InputLabelProps,
		InputProps,
		inputProps = {},
		label,
		name,
		minRows = ifFeature('encore', 3),
		width,
		note,
		onBlur,
		onChange,
		onFocus,
		onKeyDown,
		notePlacement = 'inline',
		placeholder,
		required = false,
		status,
		value,
		...rest
	} = appliedProps;

	return (
		<InputWrapper
			classes={{
				label: classes.label,
				width: classes.width,
			}}
			className={className}
			disabled={disabled}
			id={id}
			InputLabelProps={InputLabelProps}
			label={label}
			note={note}
			notePlacement={notePlacement}
			required={required}
			status={status}
			width={width}
		>
			<TextareaAutosize
				{...InputProps}
				{...rest}
				{...inputProps}
				autoFocus={autoFocus}
				className={cx(classes.textAreaRoot, classes.size, classes.width, classes.denseAdornment)}
				disabled={disabled}
				id={id}
				minRows={minRows}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				ref={ref}
				value={value}
			/>
		</InputWrapper>
	);
}) as FC<PropsWithChildren<TextAreaProps>>;

TextArea.defaultProps = {
	disabled: false,
	size: 'medium',
	width: 100,
};
