import { omit } from 'lodash';

import { shouldFlipHorizontally, flipHorizontally } from './horizontally';
import { shouldFlipVertically, flipVertically } from './vertically';
import { getContainerCollisions } from '../container';
import { calculateVesselOffsets } from '../../offsets';

export function tryFlip(collisionData, locationFlipper, additionalVesselOffsets) {
	const { collisions, offsets, locations } = collisionData;

	const flippedLocations = locationFlipper(locations);
	const flippedVesselOffsets = calculateVesselOffsets(offsets, flippedLocations, additionalVesselOffsets);
	const remainingCollisions = getContainerCollisions(offsets.container, flippedVesselOffsets);
	const collisionWasFixed = remainingCollisions.length < collisions.length;

	return {
		collisions: remainingCollisions,
		offsets: {
			...offsets,
			vessel: collisionWasFixed ? flippedVesselOffsets : offsets.vessel,
		},
		locations: collisionWasFixed ? flippedLocations : locations,
	};
}

export function flip(collisionData) {
	const { additionalVesselOffsets, collisions, offsets, locations } = collisionData;

	let result = {
		offsets: {
			anchor: offsets.anchor,
			vessel: offsets.vessel,
		},
		locations,
	};

	if (collisions.length > 0) {
		if (shouldFlipHorizontally(collisions)) {
			collisionData = tryFlip(collisionData, flipHorizontally, additionalVesselOffsets);
		}

		if (shouldFlipVertically(collisions)) {
			collisionData = tryFlip(collisionData, flipVertically, additionalVesselOffsets);
		}

		result = {
			offsets: omit(collisionData.offsets, ['container']),
			locations: collisionData.locations,
		};
	}

	return result;
}
