import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ spacing }) => ({
	checkbox: {
		'&:nth-of-type(n+2)': {
			marginTop: ifFeature('encore', spacing(1.5), 8),
		},
	},
	root: {},
}));
