import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { BadgeStyleProps } from './types';

export const useStyles = makeStyles<Partial<BadgeStyleProps>>()(({ palette, spacing, mode }, props) => {
	const { alignTop, marginLarge, iconColor } = props;

	return {
		root: {
			display: 'flex',
			alignItems: alignTop ? 'top' : 'center',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		asset: {
			marginRight: marginLarge ? '12px' : '8px',
			marginTop: spacing(0.5),
		},
		iconWrapper: {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			fill: iconColor ? palette[iconColor]?.main || palette[iconColor]?.active || iconColor : null,
		},
		image: {
			borderRadius: '50%',
		},
	};
});
