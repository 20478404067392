import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// @startCleanup encore
// These styles need to use a callback function so that the `ifFeature` check is run after the body has rendered.
// Otherwise, `ifFeature` will throw an error or always return false (depending on the environment).
// @endCleanup encore
export const useStandardHeadlineStyles = makeStyles()(() => ({
	root: {
		paddingLeft: ifFeature('encore', undefined, '16px'),
		paddingRight: ifFeature('encore', undefined, '16px'),
	},
}));
