import { styled, Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { widths } from '~definitions/json/encore-sizes.json';

import { ThemeVariant } from '@fabric/file-card';

export const AttachmentStyled = styled('div')<{ variant: ThemeVariant; theme: Theme }>(({ variant = 'default', theme }) => {
	const { borderRadiuses, constructs, palette, spacing, typography } = theme as Theme;
	return {
		backgroundColor:
			variant === 'light'
				? ifFeature('encore', constructs.surface.neutral.xWeak, palette.gray[100])
				: ifFeature('encore', constructs.surface.neutral.weak, palette.gray[200]),
		borderRadius: ifFeature('encore', borderRadiuses[400], '2px'),
		boxSizing: 'border-box',
		color: palette.gray[1000],
		display: 'flex',
		flexDirection: 'row',
		fontSize: typography.teenie?.fontSize,
		height: ifFeature('encore', '128px', '112px'),
		lineHeight: typography.teenie?.lineHeight,
		padding: ifFeature('encore', spacing(2), '9px 12px 12px'),
		position: 'relative',
		width: ifFeature('encore', widths[7], '176px'),
		'.fab-FloatingIconButton--secondary, .fab-FloatingIconButton--secondary:hover': {
			backgroundColor: 'transparent',
		},
	};
});

export const DetailStyled = styled('div')({
	alignItems: 'flex-start',
	display: 'flex',
	flexDirection: 'row',
	flexGrow: 1,
	justifyContent: 'space-between',
});

export const FileStyled = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
});

export const IconStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { spacing } = theme as Theme;
	return {
		display: ifFeature('encore', 'flex'),
		marginRight: ifFeature('encore', spacing(1), '6px'),
	};
});

export const InfoStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { palette, typography } = theme as Theme;
	return {
		alignItems: 'center',
		color: palette.gray[700],
		display: 'flex',
		flexDirection: 'row',
		fontSize: typography.teenie?.fontSize,
		height: 'auto',
		justifyContent: 'space-between',
		lineHeight: 1,
	};
});

export const RemoveButtonStyled = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginTop: '3px',
});

export const TitleStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { constructs, typography } = theme as Theme;
	return {
		color: ifFeature('encore', constructs.text.neutral.xStrong),
		display: 'inline-block',
		fontSize: ifFeature('encore', typography.medium.fontSize, typography.small?.fontSize),
		fontWeight: ifFeature('encore', typography.fontWeightMedium),
		lineHeight: ifFeature('encore', typography.medium.lineHeight, typography.small?.lineHeight),
		maxHeight: ifFeature('encore', '42px', '38px'),
		maxWidth: '100%',
		minHeight: '34px',
		overflow: 'hidden',
		wordBreak: 'break-all',
	};
});

export const TypeStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { constructs, palette, typography } = theme as Theme;
	return {
		alignItems: 'center',
		color: ifFeature('encore', constructs.text.neutral.medium, palette.gray[700]),
		display: 'flex',
		flexDirection: 'row',
		fontSize: ifFeature('encore', typography.small.fontSize, typography.teenie?.fontSize),
		height: 'auto',
		lineHeight: ifFeature('encore', typography.small.lineHeight, 1),
	};
});
