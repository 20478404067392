import { DAY_MEMOIZE_MODE_FULL_THRESHOLD_DAYS } from '../../shared.domain';
import {
	DatePickerCalendarDayMemoizeMode,
	DatePickerDate,
	DatePickerDatePredicate,
	DatePickerRangeObjectDate,
	DatePickerRangeOnChangeParamDate,
	DatePickerRangeTargetSelectionType,
	DatePickerUtils,
} from '../../types';
import {
	getIsDateBetweenRange,
	getIsDateToday,
	getIsDateValid,
	getIsNextMonthFirstDayAfterDate,
	getIsPreviousMonthLastDayBeforeDate,
	getIsRangeTooLarge,
	getRangeDateDisabled,
	getRangeMatchSome,
	getRangeOrdered,
	getToday,
} from '../../utils';

export function getCalendarDayShowErrored(
	utils: DatePickerUtils,
	date: DatePickerDate,
	renderStartDate: DatePickerDate,
	renderEndDate: DatePickerDate,
	validateDisabledDatesWithinRange: boolean,
	memoizeMode: DatePickerCalendarDayMemoizeMode,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	maxRangeSpanYears?: number,
	disableBeforeStartDate?: boolean,
	disableAfterEndDate?: boolean,
	getDateDisabled?: DatePickerDatePredicate
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (
		!!renderStartDate &&
		getIsDateValid(utils, renderStartDate) &&
		!!minDate &&
		getIsDateValid(utils, minDate) &&
		utils.isBeforeDay(renderStartDate, minDate)
	) {
		return true;
	}

	if (
		!!renderEndDate &&
		getIsDateValid(utils, renderEndDate) &&
		!!maxDate &&
		getIsDateValid(utils, maxDate) &&
		utils.isAfterDay(renderEndDate, maxDate)
	) {
		return true;
	}

	if (getDateDisabled && getIsDateValid(utils, renderStartDate) && getDateDisabled(renderStartDate)) {
		return true;
	}

	if (getDateDisabled && getIsDateValid(utils, renderEndDate) && getDateDisabled(renderEndDate)) {
		return true;
	}

	if (!renderStartDate || !getIsDateValid(utils, renderStartDate) || !renderEndDate || !getIsDateValid(utils, renderEndDate)) {
		return false;
	}

	if (utils.isBeforeDay(renderEndDate, renderStartDate)) {
		return true;
	}

	if (maxRangeSpanYears) {
		if (getIsRangeTooLarge(utils, renderStartDate, date, maxRangeSpanYears)) {
			return true;
		}

		if (getIsRangeTooLarge(utils, renderEndDate, date, maxRangeSpanYears)) {
			return true;
		}
	}

	if (!validateDisabledDatesWithinRange) {
		return false;
	}

	const memoizeModeFullThresholdDate = utils.addDays(renderStartDate, DAY_MEMOIZE_MODE_FULL_THRESHOLD_DAYS);
	if (utils.isAfterDay(date, memoizeModeFullThresholdDate) && memoizeMode === 'quick') {
		return false;
	}

	return getRangeMatchSome(utils, renderStartDate, date, (date: DatePickerDate) => {
		return getRangeDateDisabled(
			utils,
			date,
			renderStartDate,
			renderEndDate,
			minDate,
			maxDate,
			getDateDisabled,
			disableBeforeStartDate,
			disableAfterEndDate
		);
	});
}

export function getCalendarDayIsHighlighted(
	utils: DatePickerUtils,
	date: DatePickerDate,
	renderStartDate: DatePickerDate,
	renderEndDate: DatePickerDate
): boolean {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return (
		getIsDateValid(utils, renderStartDate) &&
		getIsDateValid(utils, renderEndDate) &&
		getIsDateBetweenRange(utils, date, renderStartDate, renderEndDate)
	);
}

export function getCalendarDayIsMarked(
	utils: DatePickerUtils,
	date: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	focusedDate?: DatePickerDate,
	startDate?: DatePickerDate,
	endDate?: DatePickerDate
): boolean {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return getCalendarDayIsPreviousTargetedSelection(utils, date, targetSelectionType, focusedDate, startDate, endDate);
}

export function getCalendarDayIsPreviousTargetedSelection(
	utils: DatePickerUtils,
	date: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	focusedDate?: DatePickerDate,
	startDate?: DatePickerDate,
	endDate?: DatePickerDate
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (focusedDate) {
		if (!!startDate && getIsDateValid(utils, startDate) && utils.isSameDay(date, startDate)) {
			return targetSelectionType === 'start' || targetSelectionType === 'start-ish';
		}

		if (!!endDate && getIsDateValid(utils, endDate) && utils.isSameDay(date, endDate)) {
			return targetSelectionType === 'end' || targetSelectionType === 'end-ish';
		}
	}

	return false;
}

export function getCalendarDayIsSelected(
	utils: DatePickerUtils,
	date: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	focusedDate?: DatePickerDate,
	startDate?: DatePickerDate,
	endDate?: DatePickerDate,
	justSelected?: boolean
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (!!startDate && getIsDateValid(utils, startDate) && utils.isSameDay(date, startDate)) {
		if (!justSelected && focusedDate) {
			return targetSelectionType === 'end' || targetSelectionType === 'end-ish';
		}
		return true;
	}

	if (!!endDate && getIsDateValid(utils, endDate) && utils.isSameDay(date, endDate)) {
		if (!justSelected && focusedDate) {
			return targetSelectionType === 'start' || targetSelectionType === 'start-ish';
		}
		return true;
	}

	return false;
}

export function getCalendarDayState(
	utils: DatePickerUtils,
	date: DatePickerDate,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	focusedDate: DatePickerDate,
	hoveredDate: DatePickerDate,
	disabled: boolean,
	justSelected: boolean,
	dayInCurrentMonth: boolean,
	validateDisabledDatesWithinRange: boolean,
	memoizeMode: DatePickerCalendarDayMemoizeMode,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	hasFocus: boolean,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	maxRangeSpanYears?: number,
	getDateDisabled?: DatePickerDatePredicate,
	disableBeforeStartDate?: boolean,
	disableAfterEndDate?: boolean
): {
	active: boolean;
	disabled: boolean;
	errored: boolean;
	focused: boolean;
	hidden: boolean;
	highlighted: boolean;
	marked: boolean;
	selected: boolean;
} {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	const { startDate: renderStartDate, endDate: renderEndDate } = getRenderRange(
		utils,
		startDate,
		endDate,
		hoveredDate,
		targetSelectionType
	);

	const isDisabled = !getIsDateSelectable(
		utils,
		date,
		disabled,
		startDate,
		endDate,
		targetSelectionType,
		minDate,
		maxDate,
		maxRangeSpanYears,
		getDateDisabled,
		disableBeforeStartDate,
		disableAfterEndDate
	);

	const errored = getCalendarDayShowErrored(
		utils,
		date,
		renderStartDate,
		renderEndDate,
		validateDisabledDatesWithinRange,
		memoizeMode,
		minDate,
		maxDate,
		maxRangeSpanYears,
		disableBeforeStartDate,
		disableAfterEndDate,
		getDateDisabled
	);

	const active = getIsDateFocused(utils, date, hoveredDate);
	const focused = hasFocus && active;
	const hidden = !dayInCurrentMonth;
	const highlighted = getCalendarDayIsHighlighted(utils, date, renderStartDate, renderEndDate);
	const marked = getCalendarDayIsMarked(utils, date, targetSelectionType, hoveredDate, startDate, endDate);
	const selected = getCalendarDayIsSelected(utils, date, targetSelectionType, focusedDate, startDate, endDate, justSelected);

	return {
		active,
		disabled: isDisabled,
		errored,
		focused,
		hidden,
		highlighted,
		marked,
		selected,
	};
}

export function getDatePickerCalendarRangeOnChangeParam(
	startValue: DatePickerDate,
	endValue: DatePickerDate
): DatePickerRangeOnChangeParamDate {
	return {
		startValue,
		endValue,
	};
}

export function getIsDateFocused(utils: DatePickerUtils, date: DatePickerDate, focusedDate: DatePickerDate): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (!focusedDate) {
		return false;
	}

	return utils.isSameDay(date, focusedDate);
}

export function getIsDateSelectable(
	utils: DatePickerUtils,
	date: DatePickerDate,
	disabled: boolean,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	maxRangeSpanYears?: number,
	getDateDisabled?: DatePickerDatePredicate,
	disableBeforeStartDate?: boolean,
	disableAfterEndDate?: boolean
): boolean {
	if (!utils || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	if (disabled) {
		return false;
	}

	if (maxRangeSpanYears) {
		if (targetSelectionType === 'start' || targetSelectionType === 'start-ish') {
			if (getIsRangeTooLarge(utils, endDate, date, maxRangeSpanYears)) {
				return false;
			}
		} else if (getIsRangeTooLarge(utils, startDate, date, maxRangeSpanYears)) {
			return false;
		}
	}

	return !getRangeDateDisabled(
		utils,
		date,
		startDate,
		endDate,
		minDate,
		maxDate,
		getDateDisabled,
		disableBeforeStartDate,
		disableAfterEndDate
	);
}

export function getIsLeftArrowButtonDisabled(
	utils: DatePickerUtils,
	viewDate: DatePickerDate,
	disabled: boolean,
	minDate?: DatePickerDate,
	startDate?: DatePickerDate,
	disableBeforeStartDate?: boolean
): boolean {
	if (!utils || !viewDate) {
		throw new Error('Expected arguments are missing.');
	}

	return (
		disabled ||
		(getIsDateValid(utils, minDate) && getIsPreviousMonthLastDayBeforeDate(utils, viewDate, minDate)) ||
		(getIsDateValid(utils, startDate) &&
			!!disableBeforeStartDate &&
			getIsPreviousMonthLastDayBeforeDate(utils, viewDate, startDate))
	);
}

export function getIsRightArrowButtonDisabled(
	utils: DatePickerUtils,
	viewDateSecondary: DatePickerDate,
	disabled: boolean,
	maxDate?: DatePickerDate,
	endDate?: DatePickerDate,
	disableAfterEndDate?: boolean
): boolean {
	if (!utils || !viewDateSecondary) {
		throw new Error('Expected arguments are missing.');
	}

	return (
		disabled ||
		(getIsDateValid(utils, maxDate) && getIsNextMonthFirstDayAfterDate(utils, viewDateSecondary, maxDate)) ||
		(getIsDateValid(utils, endDate) && !!disableAfterEndDate && getIsNextMonthFirstDayAfterDate(utils, viewDateSecondary, endDate))
	);
}

export function getKeyboardFocusedDate(
	utils: DatePickerUtils,
	focusedDate: DatePickerDate,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType
): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (focusedDate) {
		return focusedDate;
	}

	if (targetSelectionType === 'end' || targetSelectionType === 'end-ish') {
		if (!!endDate && getIsDateValid(utils, endDate)) {
			return utils.startOfDay(endDate);
		}

		if (!!startDate && getIsDateValid(utils, startDate)) {
			return utils.startOfDay(startDate);
		}
	}

	if (!!startDate && getIsDateValid(utils, startDate)) {
		return utils.startOfDay(startDate);
	}

	if (!!endDate && getIsDateValid(utils, endDate)) {
		return utils.startOfDay(endDate);
	}

	return getToday(utils);
}

export function getRenderRange(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	focusedDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType
): DatePickerRangeObjectDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!focusedDate) {
		return {
			startDate,
			endDate,
		};
	}

	return getUpdatedRange(utils, focusedDate, startDate, endDate, targetSelectionType);
}

export function getUpdatedRange(
	utils: DatePickerUtils,
	date: DatePickerDate,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType
): DatePickerRangeObjectDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (targetSelectionType === 'end') {
		return {
			startDate,
			endDate: date,
		};
	}

	if (targetSelectionType === 'start') {
		return {
			startDate: date,
			endDate,
		};
	}

	if (targetSelectionType === 'end-ish') {
		return getRangeOrdered(utils, startDate, date);
	}

	return getRangeOrdered(utils, date, endDate);
}

export function getViewDate(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType
): DatePickerDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const isStartDateValid = !!startDate && getIsDateValid(utils, startDate);
	const isEndDateValid = !!endDate && getIsDateValid(utils, endDate);
	const areDatesSameMonth =
		isStartDateValid && isEndDateValid && utils.isSameDay(utils.startOfMonth(startDate), utils.startOfMonth(endDate));

	if (areDatesSameMonth) {
		return utils.startOfMonth(startDate);
	}

	if (targetSelectionType === 'end' || targetSelectionType === 'end-ish') {
		if (isEndDateValid) {
			return utils.startOfMonth(utils.getPreviousMonth(endDate));
		}
	}

	if (isStartDateValid) {
		return utils.startOfMonth(startDate);
	}

	if (isEndDateValid) {
		return utils.startOfMonth(utils.getPreviousMonth(endDate));
	}

	return utils.startOfMonth(getToday(utils));
}

/**
 * Returns the default start date. If the start date is defined then it will be used.
 * If the start date isn't defined then the end date will returned.
 * If the end date isn't defined then the current date will be returned.
 *
 * @param startDate The current start date.
 * @param endDate The current end date.
 * @returns The default start date.
 */
export function getInitialDateFromRange(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	targetSelectionType: DatePickerRangeTargetSelectionType
) {
	const defaultDate = getToday(utils);
	const startInputIsTargeted = targetSelectionType === 'start' || targetSelectionType === 'start-ish';
	const endInputIsTargeted = targetSelectionType === 'end' || targetSelectionType === 'end-ish';

	if (startDate && !utils.isValid(startDate)) {
		startDate = undefined;
	}
	if (endDate && !utils.isValid(endDate)) {
		endDate = undefined;
	}

	if (startInputIsTargeted) {
		return startDate || endDate || defaultDate;
	}

	if (endInputIsTargeted) {
		return endDate || startDate || defaultDate;
	}

	return defaultDate;
}
