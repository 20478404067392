import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TransferListItemProps } from '../../types';

export const useStyles = makeStyles<Partial<TransferListItemProps>, 'container' | 'leftContent' | 'rightContent' | 'title'>()((
	{ constructs, mode, palette, spacing, typography },
	{ disabled },
	classes
) => {
	return {
		root: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[200]),
			boxSizing: 'border-box',
			'&:hover, &:focus': {
				[`& .${classes.container}`]: {
					backgroundColor: ifFeature('encore', constructs.surface.neutral.weak, palette.gray[300]),
					[`& .${classes.leftContent}`]: {
						fill: palette.primary.main,
					},
					[`& .${classes.title}`]: {
						color: palette.primary.main,
					},
				},
			},
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		container: {
			borderBottom: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`, `1px solid ${palette.gray[400]}`),
			display: 'flex',
			// @startCleanup encore
			height: ifFeature('encore', undefined, 40),
			// @endCleanup encore
			padding: ifFeature('encore', spacing(1.5, 2.5), '0 10px'),
		},
		selected: {
			backgroundColor: ifFeature('encore', constructs.surface.primary.strong, palette.primary.main),
			[`& .${classes.leftContent}, & .${classes.rightContent}`]: {
				color: palette.common.white,
			},
			'&:hover, &:focus': {
				backgroundColor: ifFeature('encore', constructs.surface.primary.strong, palette.primary.main),
			},
		},
		centerHorizontal: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
		},
		leftContent: {
			color: ifFeature(
				'encore',
				disabled ? constructs.text.neutral.medium : constructs.text.neutral.xStrong,
				disabled ? palette.gray[600] : palette.gray[1000]
			),
			fill: ifFeature('encore', constructs.text.primary.strong),
			flex: 2,
		},
		title: {
			color: ifFeature('encore', constructs.text.neutral.xStrong, palette.gray[1000]),
			fontSize: ifFeature('encore', typography.large.fontSize, typography.large?.fontSize),
			fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
			lineHeight: ifFeature('encore', typography.large.lineHeight, typography.large?.lineHeight),
			marginLeft: ifFeature('encore', spacing(1.5), '7px'),
			maxWidth: ifFeature('encore', 190, 210),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			userSelect: 'none',
			whiteSpace: 'nowrap',
		},
		rightContent: {
			color: palette.gray[600],
			fontSize: ifFeature('encore', typography.medium.fontSize, typography.small?.fontSize),
			lineHeight: ifFeature('encore', typography.medium.lineHeight, typography.small.lineHeight),
			userSelect: 'none',
		},
		expandedDropdown: {
			fill: palette.primary.main,
		},
		items: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});
