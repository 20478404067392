import { first, last } from 'lodash';
import {
	hasChildren,
	isAddType,
	isAnchored,
	isDisabled,
	isGroup,
	isSelected,
	shouldInclude,
	shouldIncludeBecauseItemStartsWith,
} from '../assertion';
import { findPath, getItemFromPath } from '../path';
import { cloneObject } from '../../util';
import { Directions } from '../../enums';

export function flattenGroups(items) {
	if (!Array.isArray(items)) {
		throw new Error('Invalid argument. "items" must be an Array of items');
	}

	items = cloneObject(items);

	return items.reduce((memo, item) => {
		const _isGroup = isGroup(item);

		if (!_isGroup && hasChildren(item)) {
			item.items = flattenGroups(item.items);
		}

		if (_isGroup && hasChildren(item)) {
			memo = [...memo, ...flattenGroups(item.items)];
		} else if (!_isGroup) {
			memo.push(item);
		}

		return memo;
	}, []);
}

export function flatten(items = []) {
	return items.reduce((memo, item) => {
		memo.push(item);

		if (hasChildren(item)) {
			memo = [...memo, ...flatten(item.items)];
		}

		return memo;
	}, []);
}

export function getFromValues(items, values) {
	return flatten(items).filter(item => values.some(value => JSON.stringify(value) === JSON.stringify(item.value)));
}

export function getParent(items, item) {
	const path = findPath(items, item);
	path.pop();

	return getItemFromPath(items, path);
}

export function getParents(items = [], item) {
	const parent = getParent(items, item);
	const parents = parent ? getParents(items, parent) : [];

	return [parent, ...parents];
}

export function getSelected(items, selected = []) {
	return flatten(items).filter(item => isSelected(item, selected));
}

export function getSelectedValues(items) {
	return getSelected(items).map(item => item.value);
}

export function getSortedInteractiveItems(items) {
	if (!Array.isArray(items)) {
		throw new Error('Invalid argument. "items" must be an Array of items');
	}

	items = [...items].sort((a, b) => {
		let flag = 0;

		if (a.anchor === 'top' || b.anchor === 'bottom') {
			flag = -1;
		} else if (b.anchor === 'top' || a.anchor === 'bottom') {
			flag = 1;
		}

		return flag;
	});

	return flattenGroups(removeDisabledItems(items));
}

export function getAddTypeItems(items) {
	return flatten(items).filter(isAddType);
}

export function hasAddTypeItems(items) {
	return !!getAddTypeItems(items).length;
}

export function getFirstInteractiveItem(items) {
	return first(getSortedInteractiveItems(items));
}

export function getFirstInteractiveParent(items, item) {
	return getParents(items, item).find(parent => !isDisabled(items, parent) && !isGroup(parent));
}

export function getFirstInteractiveParentPath(items, item) {
	const firstInteractiveParent = getFirstInteractiveParent(items, item);
	return findPath(firstInteractiveParent);
}

export function getLastInteractiveItem(items) {
	return last(getSortedInteractiveItems(items));
}

export function getItemsStartingAt(items, index) {
	return [...items.slice(index), ...items.slice(0, index)];
}

export function getNextItemStartingWith(items, currentItem, text) {
	let interactiveItems = getSortedInteractiveItems(items);
	const path = findPath(interactiveItems, currentItem);

	if (path.length > 1) {
		const parent = getParent(interactiveItems, currentItem);

		return getNextItemStartingWith(getSortedInteractiveItems(parent.items), currentItem, text);
	}

	if (path && path.length === 1) {
		const firstPathIndex = path[0];
		interactiveItems = getItemsStartingAt(interactiveItems, firstPathIndex);
	}

	const matchingItems = searchForItemsStartingWith(interactiveItems, text);

	return matchingItems.length ? traverseDirection(matchingItems, currentItem, Directions.DOWN) : currentItem;
}

export function getValues(items) {
	return flatten(items).map(item => item.value);
}

export function hasExclusiveItemSelected(items = []) {
	return items.some(item => item.exclusive) || false;
}

export function removeDisabledItems(items) {
	if (!Array.isArray(items)) {
		throw new Error('Invalid argument. "items" must be an Array of items');
	}

	items = cloneObject(items);

	return items.reduce((memo, item) => {
		if (isDisabled(item)) {
			return memo;
		}

		if (hasChildren(item)) {
			item.items = removeDisabledItems(item.items);
		}

		memo.push(item);
		return memo;
	}, []);
}

export function searchForItemsStartingWith(items = [], searchText = '') {
	return searchItems(items, searchText, shouldIncludeBecauseItemStartsWith);
}

export function searchItems(items = [], searchText = '', test = shouldInclude) {
	if (!Array.isArray(items)) {
		throw new Error('Invalid argument. "items" must be an Array of items');
	}

	items = cloneObject(items);

	return items.reduce((memo, item) => {
		if (test(item, searchText)) {
			if (isGroup(item) && !isAnchored(item)) {
				item.items = searchItems(item.items, searchText, test);
			}
			memo.push(item);
		}
		return memo;
	}, []);
}

export function traverseDirection(items, currentItem, direction = Directions.DOWN) {
	if (!Array.isArray(items)) {
		throw new Error(
			`Cannot traverse items because the items argument must be an array. Invalid type: ${typeof items} was supplied instead.`
		);
	}

	if (!currentItem) {
		return direction === Directions.DOWN ? getFirstInteractiveItem(items) : getLastInteractiveItem(items);
	}

	const interactiveItems = getSortedInteractiveItems(items);
	const currentItemPath = findPath(interactiveItems, currentItem);

	if (direction === Directions.DOWN || direction === Directions.UP) {
		return traverseVertically(interactiveItems, currentItemPath, direction);
	}

	if (direction === Directions.LEFT || direction === Directions.RIGHT) {
		return traverseHorizontally(interactiveItems, currentItemPath, direction);
	}
}

export function traverseVertically(items, path = [], direction = Directions.DOWN) {
	const parentPath = path.slice(0, -1);
	const siblings = parentPath.length ? getItemFromPath(items, parentPath).items : items;

	const index = last(path);

	let newIndex = 0;

	if (!isNaN(index)) {
		newIndex = direction === Directions.UP ? index - 1 : index + 1;
	}

	if (newIndex > siblings.length - 1) {
		newIndex = 0;
	}

	if (newIndex < 0) {
		newIndex = siblings.length - 1;
	}

	const newPath = [...path.slice(0, -1), newIndex];

	return getItemFromPath(items, newPath);
}

export function traverseHorizontally(items, path, direction) {
	const newPath = direction === Directions.RIGHT ? [...path, 0] : [path[0], ...path.slice(1, -1)];

	return getItemFromPath(items, newPath);
}
