import React, { ReactElement } from 'react';
import { PageCapsuleProps } from './page-capsule.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './page-capsule.styles';

export function PageCapsule(props: PageCapsuleProps): ReactElement {
	const { classes } = useStyles();
	const { id = 'page-capsule', children } = props;

	return (
		<>
			{ifFeature(
				'encore',
				<div className={classes.root} id={id}>
					{children}
				</div>,
				'PageCapsule component is only available with Encore enabled.'
			)}
		</>
	);
}
