import React from 'react';
import { Button } from '~components/button';
import { CheckCircle36x36, CircleAlert36x36, Compass36x36, DeathStar36x36, TriangleExclamation36x36 } from '@bamboohr/grim';
import { IconV2Name } from '~components/icon-v2';
import { InPageMessagingType } from './enums';

export const messagingTypes: InPageMessagingType[] = [
	InPageMessagingType.discovery,
	InPageMessagingType.error,
	InPageMessagingType.info,
	InPageMessagingType.neutral,
	InPageMessagingType.success,
	InPageMessagingType.warning,
];

export const typeMap = {
	[InPageMessagingType.discovery]: {
		icon: <DeathStar36x36 aria-hidden={true} />,
	},
	[InPageMessagingType.error]: {
		icon: <CircleAlert36x36 aria-hidden={true} />,
	},
	[InPageMessagingType.info]: {
		icon: <CircleAlert36x36 aria-hidden={true} />,
	},
	[InPageMessagingType.neutral]: {
		icon: <DeathStar36x36 aria-hidden={true} />,
	},
	[InPageMessagingType.success]: {
		icon: <CheckCircle36x36 aria-hidden={true} />,
	},
	[InPageMessagingType.warning]: {
		icon: <TriangleExclamation36x36 aria-hidden={true} />,
	},
};

export const encoreStatusIcon: Record<string, IconV2Name> = {
	discovery: 'gift-solid',
	error: 'circle-exclamation-solid',
	info: 'circle-info-solid',
	neutral: 'face-smile-solid',
	success: 'circle-check-solid',
	warning: 'triangle-exclamation-solid',
};

export const mockStackProps = {
	info: true,
	stack: [
		{
			body: 'I also have the default styling',
			header: 'I have the default icon',
		},
		{
			body: 'I also have a dismiss button',
			header: 'I have a custom icon',
			icon: {
				name: 'fab-pen-signing-36x30',
			},
			onClose: () => null,
		},
		{
			body: 'Hmmm... looks a little weird, but it is doable',
			header: 'I have a branded BambooHR icon',
			icon: {
				brand: true,
				name: 'fab-bamboohr-logo-113x18',
			},
		},
		{
			body: 'I have a custom action as text',
			customAction: 'Hi, I am text in a customAction',
			header: 'I do not have an icon',
			icon: {
				brand: true,
				name: 'none',
			},
		},
		{
			body: 'I have a custom action as a button, and I have a dismiss button',
			customAction: <Button variant="outlined">I&apos;m a button customAction</Button>,
			header: 'I have a few different things',
			icon: 'none',
			onClose: () => null,
		},
		{
			body: 'I use <Compass36x36 /> as my icon',
			header: 'I use an icon component',
			icon: <Compass36x36 aria-hidden={true} />,
		},
	],
};
