import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useRatingStyles = makeStyles()(({ spacing, mode }) => ({
	root: {
		marginLeft: `-${spacing(0.5)}`, // Takes care of the extra padding on the first icon in the rating component
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
	},
	large: {
		'& > label': {
			marginLeft: '1px',
		},
	},
}));
