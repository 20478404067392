import { Button, FloatingIconButton, IconButton, TextButton } from '@fabric/button';
import { DropdownProps, DropdownTypes } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function getButtonFromType(type: DropdownProps['type']) {
	switch (type) {
		case DropdownTypes.BUTTON:
			return Button;
		case DropdownTypes.ICON:
			return ifFeature('encore', IconButton, FloatingIconButton);
		case DropdownTypes.TEXT:
			return TextButton;
		default:
			return Button;
	}
}
