import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ConstraintSpacing } from '~components/base-modal';

export const useStyles = makeStyles()(theme => {
	const { palette, mode } = theme;

	return {
		root: {
			backgroundColor: ifFeature('encore', palette.grey[50], palette.grey[100]),
			padding: ConstraintSpacing.MEDIUM,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			borderRadius: 2,
		},
	};
});
