import React, { ReactElement } from 'react';
import { IconTileProps } from './icon-tile.types';
import { useIconTileStyles } from './icon-tile.styles';
import { IconV2 } from '~components/icon-v2';
import { getIconColor, getIconSize } from './utils';

/**
 * Wrapper for the IconV2 to add background and color scheme
 * @note IconTile comes built into several components (such as BadgeV2) so only an icon name needs to be provided to BadgeV2
 */
export function IconTile(props: IconTileProps): ReactElement {
	const { icon, id, size, variant } = props;

	const { classes } = useIconTileStyles(props);

	return (
		<div className={classes.root} id={id}>
			{icon && typeof icon === 'string' ? <IconV2 color={getIconColor(variant)} name={icon} size={getIconSize(size)} /> : icon}
		</div>
	);
}
