import React, { FC, PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './grid-row.styles';
import { GridRowProps } from './types';

/**
 * @deprecated GridRow should no longer be used. Instead, Grid is the preferred option.
 */
export const GridRow: FC<PropsWithChildren<GridRowProps>> = ({ children, className, margin = 'normal', ...rest }) => {
	const { classes, cx } = useStyles({ margin });

	return (
		<Grid className={cx(className, classes.root)} item xs={12} {...rest}>
			{children}
		</Grid>
	);
};
