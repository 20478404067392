import React from 'react';

import { getMonthDisabled, getSelectMonthYearOnChangeParam, getYearDisabled } from './select-month-year.domain';
import { useStyles } from './select-month-year.styles';
import { SelectMonth, SelectYear } from '../index';
import { useDatePickerUtils } from '../../hooks';
import { MonthValue, SelectMonthOnChangeParam, SelectMonthYearProps, SelectYearOnChangeParam } from '../../types';

export const SelectMonthYear = ({
	disabled = false,
	getDateDisabled,
	maxDate,
	minDate,
	months,
	monthsDisabled,
	monthValue,
	onChange,
	onMenuClose,
	onMenuOpen,
	years,
	yearsDisabled,
	yearValue,
}: SelectMonthYearProps): JSX.Element => {
	const { classes } = useStyles();
	const utils = useDatePickerUtils();

	return (
		<div className={classes.root}>
			<div className={classes.months}>
				<SelectMonth
					disabled={disabled || monthsDisabled}
					getMonthDisabled={month => getMonthDisabled(utils, month, yearValue, getDateDisabled, maxDate, minDate)}
					months={months}
					onChange={handleMonthChange}
					onClose={onMenuClose}
					onOpen={onMenuOpen}
					value={monthValue}
				/>
			</div>
			<div className={classes.years}>
				<SelectYear
					disabled={disabled || yearsDisabled}
					getYearDisabled={year => getYearDisabled(utils, year, getDateDisabled)}
					onChange={handleYearChange}
					onClose={onMenuClose}
					onOpen={onMenuOpen}
					value={yearValue}
					years={years}
				/>
			</div>
		</div>
	);

	function handleMonthChange(param: SelectMonthOnChangeParam): void {
		handleChange(param.value, yearValue);
	}

	function handleYearChange(param: SelectYearOnChangeParam): void {
		handleChange(monthValue, param.value);
	}

	function handleChange(month: MonthValue, year: number): void {
		const param = getSelectMonthYearOnChangeParam(month, year);
		onChange(param);
	}
};
