import {
	/* @startCleanup encore */
	AutocompleteMultipleStyleProps,
	/* @endCleanup encore */
	type AutocompleteMultipleProps,
} from './types';
import { makeStyles } from '~styles';

export const useStyles = makeStyles<Pick<AutocompleteMultipleProps<unknown>, 'size' | 'variant'>>()((theme, props) => {
	const {
		borderRadiuses,
		constructs: { icon, surface },
		spacing,
		typography,
	} = theme;
	const { size, variant } = props;

	// prevent too much roundness when wrapping to multiple lines
	let borderRadius;
	if (variant === 'single') {
		borderRadius = size === 'small' ? borderRadiuses[400] : borderRadiuses[500];
	}

	return {
		root: {
			'& .MuiInputBase-root': {
				borderRadius,
				flexWrap: 'wrap',
				gap: spacing(0, 1),
				maxHeight: 'unset', // allow wrapping to multiple lines
			},
			'& .MuiChip-root': {
				backgroundColor: surface.neutral.xWeak,
				borderRadius: borderRadiuses[100],
				fontWeight: typography.fontWeightMedium,
				gap: spacing(1),
				padding: spacing(size === 'small' ? '2.5px' : 0.5, 1),
				height: 'unset',
				margin: spacing(size === 'small' ? '3px' : 0.5, 0),
				'&.Mui-focusVisible': {
					backgroundColor: surface.neutral.weak,
				},
			},
			'& .MuiChip-label': {
				...typography.xsmall,
				padding: 0,
				margin: 0,
			},
			'& .MuiAutocomplete-input': {
				flex: 1,
				flexBasis: 48,
				padding: size === 'small' ? spacing(0.75, 0) : undefined,
			},
			'& .MuiChip-deleteIcon': {
				fill: icon.neutral.medium,
				margin: 0,
			},
		},
	};
});

/* @startCleanup encore */
export const useStylesJade = makeStyles<AutocompleteMultipleStyleProps>()((theme, props) => {
	const { mixins, palette } = theme;
	const { label } = props;

	return {
		defaultClearIcon: {
			alignItems: 'center',
			display: 'flex',
			fill: palette.grey[500],
			justifyContent: 'center',
			'&:hover': {
				fill: palette.grey[700],
			},
		},
		root: {
			'& .MuiChip-root': {
				alignItems: 'center',
				borderRadius: 2,
				color: palette.grey[1000],
				display: 'flex',
				fontSize: 14,
				lineHeight: '17px',
				margin: 2,
			},
			'& .MuiChip-deleteIcon': {
				alignItems: 'center',
				display: 'flex',
				fill: palette.grey[700],
				justifyContent: 'center',
			},
		},
		rootOld: {
			'& .MuiFormLabel-root': {
				color: palette.grey[800],
				fontSize: 14,
				lineHeight: '19px',
				'&.Mui-disabled': {
					color: palette.grey[600],
				},
			},
			'& .MuiInputBase-root': {
				border: `1px solid ${palette.grey[400]}`,
				borderRadius: '2px',
				boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.05)',
				display: 'flex',
				flexWrap: 'wrap',
				marginTop: label ? 26 : 0,
				padding: '0 6px',
				'&.Mui-disabled': {
					border: `1px solid ${palette.grey[300]}`,
				},
				'&.Mui-focused': {
					border: `1px solid ${palette.primary.main}`,
					boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`,
				},
			},
			'& .MuiChip-root': {
				alignItems: 'center',
				borderRadius: 2,
				color: palette.grey[1000],
				display: 'flex',
				fontSize: 14,
				lineHeight: '17px',
				margin: 2,
			},
			'& .MuiChip-deleteIcon': {
				alignItems: 'center',
				display: 'flex',
				fill: palette.grey[700],
				justifyContent: 'center',
			},
		},
		textFieldInputRoot: {
			alignItems: 'flex-start',
			display: 'flex',
			flexWrap: 'wrap',
			lineHeight: '21px',
			maxHeight: 'unset',
		},
	};
});
/* @endCleanup encore */
