import React from 'react';
import { useTheme } from '@mui/styles';
/* @startCleanup encore */
import { RoundedBox } from '../rounded-box';
/* @endCleanup encore */
import { InlineMessageProps, InlineMessageStatusType, InlineMessageVariant } from './inline-message.types';
import {
	getIcon,
	/* @startCleanup encore */
	getBgFill,
	/* @endCleanup encore */
} from './utils';
import { useInlineMessageStyles } from './inline-message.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText } from '../body-text';

export function InlineMessage(props: InlineMessageProps) {
	const {
		action,
		ariaLabel,
		biId,
		icon,
		title,
		description,
		status = InlineMessageStatusType.neutral,
		variant = InlineMessageVariant.default,
	} = props;
	const { palette } = useTheme();
	const { classes } = useInlineMessageStyles({ description, isFullSize: Boolean(title && description), status, variant });

	/* @startCleanup encore */
	// Title needs to be present for icon to show, and the icon prop needs to be anything but 'false'
	const shouldShowIcon = icon !== false && title;
	/* @endCleanup encore */

	const iconElement = icon === false ? null : getIcon(props, palette);

	return (
		<div aria-label={ariaLabel} className={classes.root} data-bi-id={biId} role="region">
			{ifFeature(
				'encore',
				<div className={classes.container}>
					{/* Icon */}
					{iconElement ? <div className={classes.icon}>{iconElement}</div> : null}

					{/* Title & Description */}
					<div className={classes.content}>
						{title ? (
							<BodyText color={status ? `${status}-strong` : undefined} size="small" weight="semibold">
								{title}
							</BodyText>
						) : null}
						{description ? <BodyText size="extra-small">{description}</BodyText> : null}
					</div>
					{/* Action */}
					{action ? <div className={classes.action}>{action}</div> : null}
				</div>,
				<RoundedBox backgroundColor={getBgFill(variant, status, palette)} borderRadius={4} padding="0">
					<div className={classes.container}>
						{/* Icon */}
						{shouldShowIcon ? <div className={classes.icon}>{getIcon(props, palette)}</div> : null}

						{/* Title & Description */}
						<div className={classes.content}>
							{title ? <div className={classes.title}>{title}</div> : null}
							{description ? <div className={classes.description}>{description}</div> : null}
						</div>
					</div>
				</RoundedBox>
			)}
		</div>
	);
}
