import React, { useContext } from 'react';
import { Avatar as FabAvatar } from '~components/avatar';
import type { AvatarProps } from './avatar.types';
import { BadgeV2Context } from '../badge-v2-context';
import { SIZES } from '~components/avatar/avatar.constants';

export function Avatar(props: AvatarProps) {
	const { size } = useContext(BadgeV2Context);

	let avatarSize: (typeof SIZES)[number] = 48;
	if (size === 'small') {
		avatarSize = 40;
	} else if (size === 'large') {
		avatarSize = 56;
	}

	return <FabAvatar {...props} size={avatarSize} />;
}
