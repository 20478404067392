import React, { ReactNode, useMemo } from 'react';

import { DonutChartProps } from '../../types';
import { CenteredPercentage } from '../centered-percentage';

interface UseLabelArguments<Datum> extends Pick<DonutChartProps<Datum>, 'LabelProps' | 'pieKey' | 'pieValue'> {
	total: number;
	activeData: Datum | null;
}

interface UseLabel {
	label: string;
	labelContent: ReactNode;
}

export const useLabel = <Datum = unknown,>({
	LabelProps,
	pieKey,
	pieValue,
	activeData,
	total,
}: UseLabelArguments<Datum>): UseLabel => {
	const labelIcon = LabelProps ? LabelProps.icon : null;
	const labelText = LabelProps ? LabelProps.label : null;
	const labelContent = useMemo(() => {
		if (activeData && pieValue) {
			return <CenteredPercentage total={total} value={pieValue(activeData)} />;
		}
		return labelIcon ?? null;
	}, [labelIcon, activeData, pieValue, total]);

	const label = useMemo(() => {
		if (activeData) {
			return pieKey(activeData);
		}
		return labelText ?? '';
	}, [labelText, activeData, pieKey]);

	return {
		label,
		labelContent,
	};
};
