import { isRecord } from '~utils';

export function getValue(...args: unknown[]) {
	const [e] = args;

	if (!isRecord(e)) {
		return undefined;
	}

	const { currentTarget } = e;

	if (!isRecord(currentTarget)) {
		return undefined;
	}

	if (currentTarget.checked) {
		return currentTarget.checked;
	}

	if (currentTarget.value) {
		return currentTarget.value;
	}
}
