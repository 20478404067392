import composeRefs from '@seznam/compose-react-refs';
import React, { forwardRef, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { IMask, useIMask } from 'react-imask';

import { TextField } from '~components/text-field';
import { useReadableContext } from '~components/readable';
import { MaskedInputProps } from './types';

function getValueAsString(value: unknown): string {
	if (typeof value === 'string' || typeof value === 'number') {
		return value.toString();
	}

	return '';
}

export const MaskedInput = forwardRef((props: MaskedInputProps, ref: Ref<HTMLInputElement>): ReactElement => {
	let appliedProps = props;
	const { viewMode = false } = props;
	const { isReadable } = useReadableContext();

	if (isReadable || viewMode) {
		const { placeholder, ...rest } = props;
		appliedProps = rest;
	}

	const {
		MaskOptions,
		submitUnmaskedValue = false,
		conformInitialValue = false,
		name,
		onChange,
		value = props.defaultValue || '',
		...remainingInputProps
	} = appliedProps;

	const typedValue = getValueAsString(value);
	const [inputValue, setInputValue] = useState(typedValue);

	const additionalOptions: Record<string, unknown> = {};

	const { onAccept, onComplete, ...iMaskOptions } = MaskOptions;

	additionalOptions.onAccept = function handleChange(value: string | number, inputMask: IMask.InputMask<IMask.AnyMaskedOptions>) {
		const newValue = submitUnmaskedValue ? inputMask.unmaskedValue : inputMask.value;
		setInputValue(newValue);
		if (typeof onChange === 'function') {
			onChange({ currentTarget: { value: newValue }, target: { value: newValue } });
		}
		if (typeof onAccept === 'function') {
			onAccept(value);
		}
	};

	if (typeof onComplete === 'function') {
		additionalOptions.onComplete = onComplete;
	}

	const { ref: innerRef, maskRef } = useIMask(iMaskOptions, additionalOptions);
	const masked = maskRef.current?.masked;

	/**
	 * This is a workaround for a bug in the imask package. The package performs an invalid equality check
	 * to see if options have changed. In this check it uses `.toString()` on the function to determine if
	 * it has changed. This works if the function isn't inside of another closer that references values in
	 * the parent scope (which happens often with `onChange()`).
	 *
	 * Removing this workaround will cause the values referenced in the `commit` function to become stale.
	 */
	useEffect(() => {
		if (masked && typeof iMaskOptions.commit === 'function') {
			masked.commit = iMaskOptions.commit;
		}
	}, [iMaskOptions.commit, masked]);

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (innerRef) {
			if (isFirstRender.current) {
				isFirstRender.current = false;
				if (!conformInitialValue) {
					innerRef.current.value = typedValue;
					return;
				}
			}
			// Avoid updating the value unnecessarily. Also fixes an issue
			// where a decimal cannot be typed when using a Number mask.
			if (typedValue !== maskRef.current.value) {
				// When submitUnmaskedValue is false, typedValue is the masked value and includes, e.g., a currency symbol or dashes
				if (submitUnmaskedValue) {
					maskRef.current.unmaskedValue = typedValue;
				} else {
					maskRef.current.value = typedValue;
				}
			}
		}
	}, [conformInitialValue, innerRef, isFirstRender, maskRef, submitUnmaskedValue, typedValue]);

	return (
		<>
			<TextField {...remainingInputProps} ref={composeRefs(ref, innerRef) as Ref<HTMLInputElement>} />
			<input name={name} type="hidden" value={inputValue} />
		</>
	);
});
