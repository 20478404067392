import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { CARET_ICON_NAME } from '../constants';
import { IconV2 } from '../../icon-v2';

function getCaretIconSize(size) {
	switch (size) {
		case 'extra-small':
			return 12;
		default:
			return 16;
	}
}

export function getDerivedButtonSettings(buttonSettings, children, showCaret = true) {
	const derivedButtonSettings = { ...buttonSettings };
	if (showCaret) {
		const iconPlacement = getCaretIconPlacement(buttonSettings, children);
		const icon = ifFeature(
			'encore',
			<IconV2 name="caret-down-solid" size={getCaretIconSize(derivedButtonSettings.size)} />,
			CARET_ICON_NAME
		);

		derivedButtonSettings[iconPlacement] = icon;
	}
	return derivedButtonSettings;
}

export function getCaretIconPlacement(buttonSettings, children) {
	return !hasContent(buttonSettings, children) ? 'icon' : ifFeature('encore', 'endIcon', 'iconAfter');
}

export function hasContent(buttonSettings = {}, children) {
	return !!(buttonSettings.icon || buttonSettings.iconBefore || buttonSettings.text || children);
}

export function hasFullWidthToggle(width = 0) {
	return width == 100;
}
