// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { FileImporterContext } from './use-file-importer-context';
import { FileImporterProviderProps } from './types';

/**
 * @deprecated FileImporter is deprecated and no longer functions. Use the DataGrid component instead.
 */
export const FileImporterProvider: FC<PropsWithChildren<FileImporterProviderProps>> = ({
	licenseKey,
	labels,
	validateColumn,
	validateRecord,
	children,
}) => {
	return (
		<FileImporterContext.Provider value={{ licenseKey, labels, validateColumn, validateRecord }}>
			{children}
		</FileImporterContext.Provider>
	);
};
