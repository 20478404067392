import { Components } from '@mui/material';

export const getEncoreComponents = (): Components => {
	return {
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
				disableElevation: true,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
				disableElevation: true,
			},
		},
		MuiCheckbox: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined',
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				component: 'span',
			},
		},
		MuiIconButton: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiRadio: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTabs: {
			defaultProps: {
				textColor: 'secondary',
				indicatorColor: 'primary',
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
			},
		},
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
				},
			},
		},
	};
};
