// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '~mui';
import { GridletShowOnHoverProps } from './types';

const useStyles = makeStyles(() => ({
	root: {
		display: 'none',
	},
}));

export const ShowOnHover = ({ children, className, ...rest }: GridletShowOnHoverProps) => {
	const classes = useStyles();
	return (
		<div className={classnames(classes.root, 'showOnHover', className)} {...rest}>
			{children}
		</div>
	);
};
