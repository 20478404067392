import React, { FC, Fragment, memo, PropsWithChildren, RefObject, useRef } from 'react';
import composeRefs from '@seznam/compose-react-refs';

import { useTriggerChange } from './hooks';
import { FileUploadHiddenProps } from '../../types';

export const Hidden: FC<PropsWithChildren<FileUploadHiddenProps>> = memo(props => {
	const { fileIds, inputRef, isDisabled = false, name } = props;

	const ref = useRef<HTMLInputElement>();

	useTriggerChange(ref, [JSON.stringify(fileIds)]);

	return (
		<Fragment>
			<input
				disabled={isDisabled}
				name={name}
				ref={composeRefs<HTMLInputElement>(ref as RefObject<HTMLInputElement>, inputRef)}
				type="hidden"
				value={fileIds[0] ? fileIds[0] : ''}
			/>
			{fileIds.slice(1).map(fileId => (
				<input disabled={isDisabled} key={fileId} name={name} type="hidden" value={fileId} />
			))}
		</Fragment>
	);
});
