import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BaseButton } from '../base-button';
import { TextButtonProps } from '../../types';
import { useStyles } from './styles';
import { BaseButtonSize } from '../../types/base-button.types';

export const TextButton = forwardRef<HTMLElement, TextButtonProps>(
	({ classes, component, dark, inline, status: statusProp, condition, maxWidth, muted, style, size, ...rest }, ref) => {
		const { classes: styles, cx } = useStyles({ size });
		const status = statusProp || condition;

		if (inline && !component && !rest.href) {
			component = ifFeature('encore', 'span');
		}

		const {
			root,
			content: contentClass,
			endIcon: endIconClass,
			inlineIcon: inlineIconClass,
			startIcon: startIconClass,
			...restClasses
		} = styles;

		const altStylingClass = cx(styles.altStyling, classes?.altStyling);
		const errorClass = cx(styles.error, classes?.error);
		const infoClass = cx(styles.info, classes?.info);
		const inlineClass = cx(styles.inline, classes?.inline);
		const warningClass = cx(styles.warning, classes?.warning);

		return (
			<BaseButton
				classes={{
					content: ifFeature('encore', inline ? contentClass : undefined, contentClass),
					endIcon: ifFeature('encore', inline ? cx(inlineIconClass, endIconClass) : endIconClass, endIconClass),
					root: cx(root, {
						[altStylingClass]: dark,
						[errorClass]: status === 'error',
						[infoClass]: status === 'info',
						[inlineClass]: inline,
						[warningClass]: status === 'warning',
					}),
					startIcon: ifFeature('encore', inline ? cx(inlineIconClass, startIconClass) : startIconClass, startIconClass),
					...restClasses,
				}}
				color={muted ? 'secondary' : undefined}
				component={component}
				dark={dark}
				ref={ref}
				size={size as BaseButtonSize}
				// eslint-disable-next-line react/forbid-component-props
				style={{ maxWidth, minWidth: ifFeature('encore', 0), ...style }}
				{...rest}
				variant="text"
			/>
		);
	}
);

export const TextButtonStorybook = TextButton as FC<PropsWithChildren<TextButtonProps>>;
