import { CSSProperties } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ARROW_WIDTH, ARROW_HEIGHT } from './constants';
import { PopoverProps } from './types';

export const useStyles = makeStyles<Partial<PopoverProps> & { zIndex?: number }, 'arrow' | 'popover'>()((theme, params, classes) => {
	const { mixins, mode, palette, spacing, transitions, typography } = theme;
	const { arrowFill, hasCloseButton, hasMaxWidth, noPadding, title, zIndex } = params;

	function getContentPadding(props: Partial<PopoverProps>): string | undefined {
		const { title, hasCloseButton, noPadding } = props;
		let contentPadding: string | undefined = spacing(2.5, 1.5, 2.5, 2.5);

		if (noPadding) {
			contentPadding = undefined;
		} else if (title || hasCloseButton) {
			contentPadding = spacing(0, 1.5, 2.5, 2.5);
		}

		return contentPadding;
	}

	return ifFeature(
		'encore',
		{
			root: {
				zIndex,
			},
			arrow: {},
			arrowBordered: {},
			arrowFocused: {},
			closeButton: {
				marginLeft: 'auto',
			},
			constraint: {},
			content: {
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				padding: getContentPadding({ title, hasCloseButton, noPadding }),
			},
			paper: {},
			paperBordered: {},
			paperFocused: {},
			popover: {
				backgroundColor: palette.common.white,
				border: `1px solid ${palette.gray[300]}`,
				borderRadius: spacing(1),
				boxShadow: `3px 3px 10px 2px ${mixins.alpha(palette.gray[900], 0.1)}`,
				boxSizing: 'border-box',
				lineHeight: typography.medium.lineHeight,
				maxWidth: hasMaxWidth ? '324px' : undefined,
				zIndex: 1,
			},
			popoverNoMaxWidth: {},
			title: {
				display: 'inline',
			},
			titleContainer: {},
		},
		{
			root: {
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				zIndex,
				[`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
					top: 0,
					left: 0,
					marginTop: `-${ARROW_HEIGHT}`,
					'&::before': {
						transformOrigin: '0 100%',
					},
				},
				[`&[data-popper-placement*="left"] .${classes.arrow}`]: {
					right: 0,
					marginRight: `-${ARROW_HEIGHT}`,
					height: '1em',
					width: ARROW_HEIGHT,
					'&::before': {
						transformOrigin: '0 0',
					},
				},
				[`&[data-popper-placement*="right"] .${classes.arrow}`]: {
					left: 0,
					marginLeft: `-${ARROW_HEIGHT}`,
					height: '1em',
					width: ARROW_HEIGHT,
					'&::before': {
						transformOrigin: '100% 100%',
					},
				},
				[`&[data-popper-placement*="top"] .${classes.arrow}`]: {
					bottom: 0,
					left: 0,
					marginBottom: `-${ARROW_HEIGHT}`,
					'&::before': {
						transformOrigin: '100% 0',
					},
				},
				[`&[data-popper-placement*="bottom"] .${classes.popover}`]: {
					transformOrigin: 'center top',
				},
				[`&[data-popper-placement*="left"] .${classes.popover}`]: {
					transformOrigin: 'right center',
				},
				[`&[data-popper-placement*="right"] .${classes.popover}`]: {
					transformOrigin: 'left center',
				},
				[`&[data-popper-placement*="top"] .${classes.popover}`]: {
					transformOrigin: 'center bottom',
				},
			},
			popover: {
				maxWidth: '294px',
			},
			popoverNoMaxWidth: {
				maxWidth: 'none',
			},
			arrow: {
				filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.1))',
				fontSize: '18px',
				boxSizing: 'border-box',
				color: arrowFill || palette.common.white,
				height: ARROW_HEIGHT,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				overflow: 'hidden',
				pointerEvents: 'none',
				position: 'absolute',
				width: ARROW_WIDTH,
				zIndex: 0,
				'&:before': {
					border: mixins.border(1, palette.grey[300]),
					content: '""',
					margin: 'auto',
					display: 'block',
					width: '100%',
					height: '100%',
					backgroundColor: 'currentColor',
					transform: 'rotate(45deg)',
				},
			},
			arrowBordered: {
				'&&': {
					'&:before': {
						...mixins.popoverSurface(palette),
					},
				},
			},
			arrowFocused: {
				'&&': {
					'&:before': {
						border: mixins.border(1, palette.primary.lighter),
						boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lightest, 0.35)}`,
					},
				},
			},
			titleContainer: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			},
			closeButton: {
				fill: palette.grey[600],
				padding: '12px',
				transition: transitions.create('fill', { duration: 200 }),
				...mixins.buttonReset,
				'&:active, &:focus': {
					outline: 0,
				},
				'&:hover': {
					fill: palette.grey[800],
				},
			},
			content: {
				backgroundColor: 'inherit',
				borderRadius: 'inherit',
				overflowX: 'hidden',
				overflowY: 'auto',
				position: 'relative',
				zIndex: 1,
			},
			constraint: {
				padding: noPadding ? 0 : ' 0px 24px 20px 24px',
			},
			paper: {
				backgroundColor: palette.common.white,
				border: mixins.border(1, palette.grey[300]),
				boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
				color: palette.grey[900],
				fontWeight: typography.fontWeightRegular,
				overflowX: 'visible',
				overflowY: 'visible',
				pointerEvents: 'auto',
				position: 'relative',
				...(typography.body2 as CSSProperties),

				'&.MuiPaper-rounded': {
					borderRadius: '2px',
				},
			},
			paperBordered: {
				...mixins.popoverSurface(palette),
			},
			paperFocused: {
				'&&': {
					border: mixins.border(1, palette.primary.lighter),
					boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lightest, 0.35)}`,
				},
			},
			title: {
				padding: '9px 0px 5px 24px',
				marginBottom: '0',
			},
		}
	);
});
