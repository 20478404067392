import React, { forwardRef, Ref } from 'react';
import { Grid as MUIGrid } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { GridComponent, GridProps } from './grid.types';
import { formatBaseProps } from '~utils';

const useStyles = makeStyles()(({ mode }) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});

function GridFunction<C extends keyof JSX.IntrinsicElements = 'div'>(props: GridProps<C>, ref: Ref<HTMLElement>) {
	const { children, className, item = true, ...rest } = props;
	const { classes, cx } = useStyles();

	return (
		<MUIGrid className={cx(classes.root, className)} item={item} ref={ref as Ref<HTMLDivElement>} {...formatBaseProps(rest)}>
			{children}
		</MUIGrid>
	);
}

export const Grid = forwardRef(GridFunction) as GridComponent;
