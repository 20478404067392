import { CategorizedListItems, TransferListItemProps, Subcategory } from '../../types';

export const getDisplayOrderIndexLeft = (
	categorizedData: CategorizedListItems,
	categories: string[],
	expandedCategories: boolean[]
): CategorizedListItems => {
	let displayOrderIndex = 0;
	return categories.reduce((acc, category, i) => {
		categorizedData[category].displayOrderIndex = displayOrderIndex++;
		const subcategories = categorizedData[category].subcategories.map((subcategory: Subcategory) => {
			if (expandedCategories[i]) {
				subcategory.displayOrderIndex = displayOrderIndex++;
			}
			if (!subcategory.grouped) {
				const items = subcategory.items.map(item => {
					if (expandedCategories[i]) {
						item.displayOrderIndex = displayOrderIndex++;
					}
					return item;
				});
				subcategory.items = items;
			}
			return subcategory;
		});
		const looseItems = categorizedData[category].items.map((item: TransferListItemProps) => {
			if (expandedCategories[i]) {
				item.displayOrderIndex = displayOrderIndex++;
			}
			return item;
		});
		acc[category] = {
			...categorizedData[category],
			items: looseItems,
			subcategories,
		};
		return acc;
	}, {});
};
