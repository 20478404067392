import { ActionProps } from '@bamboohr/fabric';
import { ReactNode } from 'react';
import { create } from 'zustand';

export type Message = {
	action?: ActionProps | ReactNode;
	description?: string;
	message?: string;
	visible: boolean;
	type?: string;
};

interface MessageState {
	message: Message | null;
	clearMessage: () => void;
	setMessage: (message: Message) => void;
}

export const useMessageStore = create<MessageState>((set) => ({
	message: { message: '', visible: false },
	clearMessage: () => set({ message: { message: '', visible: false } }),
	setMessage: (message: Message) => {
		set({ message: message });
	},
}));
