import React, { ReactElement, ReactNode } from 'react';
import { StandardModal } from '~components/standard-modal';
import { StepIndicator } from './step-indicator';
import { useScreenSizes } from '~utils';
import { Headline } from '~components/headline';
import useStyles from './multi-step-header.style';

export interface HeaderProps {
	/*
	 * A biId value for the modal's close button.
	 */
	biId?: string;

	/*
	 * Whether to display the close button in the header.
	 * Use this to set the same value for all steps in the MultiStepModal.
	 * This can be overridden by a hasCloseButton prop on renderHeader.
	 */
	hasCloseButton?: boolean;

	/**
	 * Should match `MultiStepModal.ariaLabelledby` when this step is active.
	 */
	id?: string;

	/*
	 * Optional prop from the current step
	 * Its props override the hasCloseButton and title props,
	 * and its children display in lieu of the title.
	 * This should typically be a Fabric StandardModal.Header component.
	 */
	renderHeader?: ReactElement<{
		hasCloseButton?: boolean;
		title?: string;
		children?: ReactNode;
	}>;

	/*
	 * The number of the current step, used in the StepIndicator.
	 */
	stepNumber: number;

	/*
	 * Optional title for the modal.
	 * Use this to set the same value for all steps in the MultiStepModal.
	 * This can be overridden by a title prop on renderHeader.
	 */
	title?: string;

	/*
	 * The total number of steps in the MultiStepModal, used in the StepIndicator.
	 */
	totalSteps: number;
}

/*
 * MultiStepHeader
 * Renders a StandardModal.Header component with a StepIndicator
 */

export function MultiStepHeader({ biId, hasCloseButton, id, renderHeader, stepNumber, title, totalSteps }: HeaderProps): ReactElement {
	const { classes } = useStyles();
	const { isMediumScreen } = useScreenSizes(true);

	return (
		<StandardModal.Header biId={biId} hasCloseButton={hasCloseButton} id={id} {...renderHeader?.props}>
			<div className={classes.header}>
				{renderHeader?.props?.children || isMediumScreen ? (
					<Headline component="h3" size="small">
						{renderHeader?.props?.title || title}
					</Headline>
				) : (
					<h3 className={classes.title}>{renderHeader?.props?.title || title}</h3>
				)}
				{totalSteps > 1 && <StepIndicator stepNumber={stepNumber} totalSteps={totalSteps} />}
			</div>
		</StandardModal.Header>
	);
}
