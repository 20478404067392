import React, { ReactElement } from 'react';
import { VerticalWizardBaseProps } from './base.types';
import { useStyles } from './base.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Divider } from '~components/divider';
import { LayoutBox } from '~components/layout-box';

export function Base({ biId, children, steps }: VerticalWizardBaseProps): ReactElement {
	const { classes } = useStyles();

	return ifFeature(
		'encore',
		<LayoutBox biId={biId} boxSizing="border-box" width="330px">
			<ol className={classes.steps}>{steps}</ol>
			<Divider color="neutral-extra-weak" margin={3} />
			<div className={classes.navigation}>{children}</div>
		</LayoutBox>,
		<div className={classes.root} data-bi-id={biId}>
			<ol className={classes.steps}>{steps}</ol>
			<div className={classes.navigation}>{children}</div>
		</div>
	);
}
