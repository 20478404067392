import { Button, Flex, IconV2 } from '@bamboohr/fabric';
import { FC, RefObject } from 'react';
import { useChatInput } from 'src/hooks/useChatInput';

interface ChatPromptInputProps {
	handleSubmit: (target: HTMLTextAreaElement) => void;
	isPrinting: boolean;
	setIsPrinting: (isPrinting: boolean) => void;
	chatLogRef: RefObject<HTMLDivElement>;
}

const ChatPromptInput: FC<ChatPromptInputProps> = ({
	handleSubmit,
	isPrinting,
	setIsPrinting,
	chatLogRef,
}) => {
	const {
		textAreaRef,
		prompt,
		onChangeHandler,
		handleKeyDown,
		onSubmit,
		stopPrinting,
	} = useChatInput({ handleSubmit, setIsPrinting, chatLogRef });

	return (
		<Flex flex={1} alignItems="center" gap="10px" position="relative">
			<form className="intro-form">
				<Flex
					flexDirection={'column'}
					justifyContent={'center'}
					paddingX="16px"
					paddingY="9px"
					gap="8px"
				>
					<textarea
						name="inputPrompt"
						className="intro-form-textarea"
						ref={textAreaRef}
						rows={1}
						onKeyDown={handleKeyDown}
						value={prompt}
						autoFocus
						onChange={onChangeHandler}
						placeholder="Ask a question..."
					/>
				</Flex>
			</form>
			<button
				disabled={!prompt || !textAreaRef}
				className="submit-prompt-btn"
				onClick={onSubmit}
			>
				<IconV2 name="paper-plane-solid" size={24} color="success-strong" />
			</button>
			{isPrinting && (
				<Flex position="absolute" right="-50px" top="-60px">
					<Button onClick={stopPrinting}>Finish Printing</Button>
				</Flex>
			)}
		</Flex>
	);
};

export default ChatPromptInput;
