import React, { forwardRef, isValidElement } from 'react';
import { useStyles } from './styles';
import { getGroupAtNextIndex } from '../etc/get-group-at-next-index';
import { getGroupAtPreviousIndex } from '../etc/get-group-at-previous-index';
import { VirtualItemProps } from '../types';
import { MenuItem, MenuItemText } from '../../menu-item';
import { Divider } from '~components/divider';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const defaultProps = {
	getDisabled: () => false,
};

export const VirtualItem = forwardRef<HTMLLIElement, VirtualItemProps>((props, ref) => {
	const { classes, cx } = useStyles();
	const { active, className, getDisabled, getGroup, index, onActive, onSelect, renderGroup, renderItem, size, style, total } = {
		...defaultProps,
		...props,
	};

	const disabled = getDisabled(index);

	const group = typeof getGroup === 'function' ? getGroup(index) : undefined;
	const nextItemGroup = getGroupAtNextIndex({ getGroup, index, total });
	const previousItemGroup = getGroupAtPreviousIndex({ getGroup, index });

	const onClick = () => {
		if (!disabled && typeof onSelect === 'function') {
			onSelect(index);
		}
	};
	const onMouseEnter = () => {
		if (!disabled && typeof onActive === 'function') {
			onActive(index);
		}
	};

	const _props = { active, disabled, onClick, onMouseEnter, size };
	const menuItem = renderItem(index, _props);

	return (
		<li
			className={cx(
				classes.root,
				{
					[classes.groupEnd]: !!group && group !== nextItemGroup,
					[classes.groupSeparator]: ifFeature(
						'encore',
						false,
						!!group && !!previousItemGroup && group !== previousItemGroup
					),
					[classes.groupStart]: !!group && group !== previousItemGroup,
				},
				className
			)}
			ref={ref}
			style={style}
		>
			{!!group &&
				!!previousItemGroup &&
				group !== previousItemGroup &&
				ifFeature(
					'encore',
					<div className={classes.groupSeparator}>
						<Divider color="neutral-extra-weak" />
					</div>
				)}
			{group && group !== previousItemGroup ? (
				<div className={classes.groupLabel}>{typeof renderGroup === 'function' ? renderGroup(group) : null}</div>
			) : null}
			{isValidElement(menuItem) ? (
				menuItem
			) : (
				<MenuItem {..._props}>
					<MenuItemText>{menuItem}</MenuItemText>
				</MenuItem>
			)}
		</li>
	);
});
