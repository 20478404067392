import { DebugRenderer } from '../renderers';
import { FormElement, Renderer, RenderOptions } from '../types';
import { getRendererByType } from './get-renderer-by-type';
import { getRendererFromOptions } from './get-renderer-from-options';
import { isFormItemType } from './is-form-item-type';

export function getRenderer(element: FormElement, renderOptions?: RenderOptions): Renderer {
	let defaultRenderer = isFormItemType(element.type) ? getRendererByType(element.type) : undefined;
	if (!defaultRenderer) {
		defaultRenderer = DebugRenderer;
	}
	const renderer = renderOptions ? getRendererFromOptions(element, renderOptions, defaultRenderer) : defaultRenderer;

	if (!renderer) {
		throw new Error(`Could not find renderer for type: "${element.type}".`);
	}

	return renderer;
}
