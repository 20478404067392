import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BASE_BOX_SHADOW, TRANSPARENT_BOX_SHADOW } from '../../button/constants';

export const darkStyles = ({ palette, mixins }: Theme): Record<'altStyling', any> => ({
	altStyling: {
		'&$root': {
			boxShadow: ifFeature('encore', `1px 1px 0 1px ${mixins.alpha?.(palette.gray[300], 0.1)}`),
			'&:hover': {
				boxShadow: ifFeature('encore', `1px 1px 0 1px ${mixins.alpha?.(palette.gray[300], 0.2)}`),
			},
			'&:active, &$active': {
				boxShadow: ifFeature('encore', `2px 2px 0 1px ${mixins.alpha?.(palette.gray[900], 0.1)}`),
			},
			'&$focusVisible, &:focus': {
				borderColor: ifFeature('encore', palette.common.white),
				boxShadow: ifFeature('encore', `0 0 0 4px ${mixins.alpha?.(palette.gray[700], 0.4)}`),
				outlineColor: ifFeature('encore', palette.common.white),
				'&&.MuiButtonGroup-grouped': {
					borderColor: ifFeature('encore', palette.common.white),
				},
			},
			'&$disabled': {
				backgroundColor: ifFeature('encore', palette.gray[600], 'transparent'),
				borderColor: ifFeature('encore', 'transparent'),
				boxShadow: ifFeature(
					'encore',
					`1px 1px 0 1px ${mixins.alpha?.(palette.gray[900], 0.06)}`,
					'0 1px 1px 0 rgba(0, 0, 0, 0.05)'
				),
				color: ifFeature('encore', palette.gray[900], mixins.alpha?.(palette.common.white, 0.35)),
				fill: ifFeature('encore', palette.gray[900], mixins.alpha?.(palette.common.white, 0.35)),
			},
			'&$processing': {
				'&:not($text)': {
					background:
						'linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(34, 34, 34, 0.2) 50%, rgba(255, 255, 255, 0.1) 100%)',
					backgroundSize: '400% 100%',
				},
			},
		},
		'&$containedPrimary': {
			backgroundColor: ifFeature('encore', mixins.alpha?.(palette.common.white, 0.8), palette.primary.contrastText),
			border: ifFeature('encore', '1px solid transparent'),
			color: palette.primary.main,
			fill: palette.primary.main,
			'&:hover': {
				backgroundColor: ifFeature('encore', palette.common.white, palette.primary.contrastText),
			},
			'&:active, &$active': {
				backgroundColor: ifFeature('encore', palette.common.white, palette.primary.contrastText),
				border: ifFeature('encore', '1px solid transparent'),
			},
			'&$focusVisible, &:focus': {
				backgroundColor: ifFeature('encore', palette.common.white),
			},
			'&.MuiButtonGroup-grouped:not(:focus):not($focusVisible)': ifFeature(
				'encore',
				{
					borderColor: 'transparent',
				},
				{}
			),
		},
		...ifFeature(
			'encore',
			{
				'&$containedSecondary, &$outlined': {
					backgroundColor: 'transparent',
					borderColor: palette.common.white,
					color: palette.common.white,
					fill: palette.common.white,
					'&:hover': {
						backgroundColor: mixins.alpha?.(palette.common.white, 0.1),
					},
					'&:active, &$active': {
						backgroundColor: mixins.alpha?.(palette.common.white, 0.1),
					},
					'&$focusVisible, &:focus': {
						backgroundColor: mixins.alpha?.(palette.common.white, 0.1),
					},
					'&&&.MuiButtonGroup-grouped': {
						borderColor: palette.common.white,
					},
				},
			},
			// Jade styles
			{
				'&$containedPrimary, &$containedSecondary': {
					backgroundColor: palette.primary.contrastText,
					color: palette.primary.main,
					fill: palette.primary.main,
					'&:hover': {
						backgroundColor: palette.primary.contrastText,
						color: palette.primary.lighter,
						fill: palette.primary.lighter,
					},
					'&:active, &$active': {
						backgroundColor: palette.primary.contrastText,
						boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.25)',
					},
					'&$focusVisible, &:focus': {
						boxShadow: `${BASE_BOX_SHADOW}, 0 0 0 2px ${palette.primary.lighter}`,
					},
					'&$disabled': {
						backgroundColor: 'transparent',
						borderColor: mixins.alpha?.(palette.common.white, 0.5),
						boxShadow: TRANSPARENT_BOX_SHADOW,
					},
				},
				'&$outlined': {
					backgroundColor: 'transparent',
					borderColor: palette.grey[200],
					boxShadow: BASE_BOX_SHADOW,
					color: palette.grey[200],
					fill: palette.grey[200],
					'&:hover': {
						backgroundColor: 'transparent',
						borderColor: palette.background.default,
						color: palette.background.default,
						fill: palette.background.default,
					},
					'&:active, &$active': {
						borderColor: palette.background.default,
						boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.15)',
					},
					'&$focusVisible, &:focus': {
						borderColor: palette.background.default,
						boxShadow: `${BASE_BOX_SHADOW}, 0 0 0 2px rgba(255, 255, 255, 0.25)`,
					},
					'&$disabled': {
						borderColor: mixins.alpha?.(palette.background.default, 0.35),
						boxShadow: TRANSPARENT_BOX_SHADOW,
					},
				},
			}
		),
	},
});
