import React, { ReactElement, useState } from 'react';
import { uniqueId } from 'lodash';
import { X14x14 } from '@bamboohr/grim';
import { TextToggle, ToggleData } from '@fabric/toggle';
import { useStyles } from './signature.styles';
import { SignatureInputMode, SignatureProps } from './signature.types';
import { SignatureDraw } from './draw';
import { SignatureType } from './type';
import { IconV2 } from '~components/icon-v2';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { StyledBox } from '~components/styled-box';
import { LayoutBox } from '~components/layout-box';
import { Flex } from '~components/flex';
import { Divider } from '~components/divider';

const TOGGLE_DRAW_TEXT = window.jQuery ? $.__('Draw') : 'Draw';
const TOGGLE_TYPE_TEXT = window.jQuery ? $.__('Type') : 'Type';

export const Signature = (props: SignatureProps): ReactElement => {
	const { biId, initialInputMode = 'draw', onChange, placeholderDrawMode, placeholderTypeMode, variant } = props;
	const { classes, cx } = useStyles();

	const [inputMode, setInputMode] = useState<SignatureInputMode>(initialInputMode);

	function handleChange(data: string, text?: string) {
		onChange(data, inputMode, text);
	}

	function textToggleChangeHandler(data: ToggleData) {
		const newInputMode = data.value === TOGGLE_DRAW_TEXT ? 'draw' : 'type';
		setInputMode(newInputMode);
		onChange('', newInputMode); // Clear the saved image
	}

	return (
		<>
			{ifFeature(
				'encore',
				<StyledBox
					borderColor="neutral-weak"
					borderRadius="medium"
					borderStyle="solid"
					data-bi-id={biId}
					height="188px"
					maxWidth="100%"
					position="relative"
					width="600px"
				>
					<StyledBox bottom="32px" left="27px" paddingBottom="4px" position="absolute" width="calc(100% - 54px)">
						<IconV2 color="neutral-extra-strong" name="xmark-regular" size={20} />
						<Divider color="neutral-weak" />
					</StyledBox>
					<LayoutBox height="100%" position="absolute" width="100%">
						<Flex justifyContent="center" position="absolute" top="-22px" width="100%">
							<TextToggle
								isChecked={inputMode === 'draw'}
								isView={false}
								name={`fab-Signature-Toggle-${uniqueId()}`}
								onChange={(_, data: ToggleData) => textToggleChangeHandler(data)}
								size="large"
								value={[TOGGLE_DRAW_TEXT, TOGGLE_TYPE_TEXT]}
							/>
						</Flex>

						{inputMode === 'draw' ? (
							<SignatureDraw biId={biId} onChange={handleChange} placeholder={placeholderDrawMode} variant={variant} />
						) : (
							<SignatureType biId={biId} onChange={handleChange} placeholder={placeholderTypeMode} variant={variant} />
						)}
					</LayoutBox>
				</StyledBox>,
				<div className={cx(classes.root, { [classes.initials]: variant === 'initials' })} data-bi-id={biId}>
					<div className={cx(classes.endorse, classes.icon)}>
						<X14x14 />
					</div>
					<div className={classes.container}>
						<div className={classes.toggle}>
							<TextToggle
								isChecked={inputMode === 'draw'}
								isView={false}
								name={`fab-Signature-Toggle-${uniqueId()}`}
								onChange={(_, data: ToggleData) => textToggleChangeHandler(data)}
								size="regular"
								value={[TOGGLE_DRAW_TEXT, TOGGLE_TYPE_TEXT]}
							/>
						</div>
						{inputMode === 'draw' ? (
							<SignatureDraw biId={biId} onChange={handleChange} placeholder={placeholderDrawMode} variant={variant} />
						) : (
							<SignatureType biId={biId} onChange={handleChange} placeholder={placeholderTypeMode} variant={variant} />
						)}
					</div>
				</div>
			)}
		</>
	);
};
