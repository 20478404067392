import { makeStyles } from '~styles';
import { TruncateV2Props } from './truncate-v2.types';

export const useStyles = makeStyles<Pick<TruncateV2Props, 'verticalAlign' | 'width'>>()((_, props) => {
	const { verticalAlign, width } = props;
	return {
		root: {
			display: 'inline-block',
			maxWidth: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			verticalAlign,
			whiteSpace: 'nowrap',
			width,
		},
	};
});
