import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { BASE_BOX_SHADOW, TRANSPARENT_BOX_SHADOW } from '../../button/constants';

import { containedStyles } from './contained.styles';
import { outlinedStyles } from './outlined.styles';
import { darkStyles } from './dark.styles';
import { textStyles } from './text.styles';
import { sizeStyles } from './sizes.styles';
import { widthStyles } from './widths.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * Note: All Buttons with a bounding box ("contained", and "outlined" variants so far) will have a 1px border that's either transparent or colored.
 *
 * This decision was made to help prevent the button from resizing when toggling between its default state (no visible border for "contained" buttons) and the disabled state (colored border).
 *
 * Because of this, any padding needed on a Button with a bounding box should take into account the 1px border value.
 */
export const styles = (theme: Theme) => {
	const { mixins, palette, transitions, typography, mode } = theme;
	const transitionOpts = { duration: 200, easing: 'ease' };

	return createStyles({
		'@keyframes processingAnimation': {
			'0%': { backgroundPosition: '0 0' },
			'100%': { backgroundPosition: '133% 0' },
		},
		root: {
			border: '1px solid transparent',
			borderRadius: ifFeature('encore', 100, 2),
			boxShadow: ifFeature('encore', `1px 1px 0px 1px ${mixins.alpha(palette.gray[900], 0.03)}`, BASE_BOX_SHADOW),
			fontSize: ifFeature('encore', typography.medium.fontSize, typography.fabricFontSize?.('medium')),
			fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightHeavy),
			height: ifFeature('encore', '40px', '34px'),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', '0 20px', '0 15px'),
			textTransform: ifFeature('encore', 'none'),
			transition: transitions.create(
				['background-color', 'border', 'borderColor', 'box-shadow', 'color', 'fill'],
				transitionOpts
			),
			verticalAlign: 'bottom',
			'&&$focusVisible, &&:focus': {
				border: ifFeature('encore', `1px solid ${palette.primary.lighter}`),
				boxShadow: ifFeature('encore', `0px 0px 0px 4px ${palette.primary.lightest}`),
				outline: ifFeature('encore', `1px solid ${palette.primary.lighter}`),
				'&&.MuiButtonGroup-grouped': ifFeature(
					'encore',
					{
						borderRightColor: palette.primary.lighter,
						zIndex: 3,
					},
					{
						zIndex: 2,
					}
				),
			},
			'&&:active, &&$active': {
				'&&': {
					boxShadow: ifFeature('encore', `2px 2px 0px 1px ${mixins.alpha(palette.gray[900], 0.1)} inset`),
					outline: mode === ModeOptions.Dye ? undefined : 0,
				},
				outline: ifFeature('encore', 0),
				'&.MuiButtonGroup-grouped': ifFeature(
					'encore',
					{
						zIndex: 2,
					},
					{}
				),
			},
			'&:hover': {
				boxShadow: ifFeature(
					'encore',
					`1px 1px 0px 2px ${mixins.alpha(palette.gray[900], 0.03)}`,
					'0 2px 2px 0 rgba(0, 0, 0, 0.1)'
				),
				'&.MuiButtonGroup-grouped': ifFeature(
					'encore',
					{
						zIndex: 2,
					},
					{}
				),
			},
			// Need to add disabled styles here for added specificty
			'&$disabled': {
				backgroundColor: ifFeature('encore', palette.grey[200], palette.common.white),
				border: ifFeature('encore', undefined, `1px solid ${palette.grey[300]}`),
				boxShadow: ifFeature('encore', undefined, TRANSPARENT_BOX_SHADOW),
				color: ifFeature('encore', palette.grey[600], palette.grey[500]),
				cursor: 'default',
				fill: ifFeature('encore', palette.grey[600], palette.grey[500]),
			},
			'&:not(.MuiButtonGroup-grouped) + &:not($noSpacing)': {
				marginLeft: ifFeature('encore', undefined, '12px'),
			},
			// counter browser anchor styles
			'a&': {
				outlineOffset: 0,
			},
		},
		noSpacing: {
			marginLeft: 0,
		},
		textOnly: {},
		iconOnly: {
			padding: ifFeature('encore', undefined, '0 11px'),
		},
		/**
		 * Button state styles
		 */
		active: {},
		disabled: {},
		focusVisible: {},
		processing: {
			'&:not($text)': {
				animation: '$processingAnimation 1.5s linear infinite reverse',
				background: 'linear-gradient(90deg, transparent 0%, rgba(173, 173, 173, 0.2) 50%, transparent 100%)',
				backgroundSize: '400% 100%',
			},
		},
		/**
		 * Button variant styles
		 */
		...containedStyles(theme),
		...outlinedStyles(theme),
		...darkStyles(theme),
		...textStyles(theme),
		...sizeStyles(theme),
		...widthStyles,
		/**
		 * Child component styles
		 */
		label: {
			alignItems: 'center',
			display: 'flex',
			height: 'inherit',
			overflow: 'hidden',
		},
		alignContentLeft: {
			justifyContent: 'flex-start',
		},
		alignContentRight: {
			justifyContent: 'flex-end',
		},
		startIcon: {},
		endIcon: {},
		content: {
			display: 'flex',
			'$textOnly &': {
				display: 'inline-block',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		},
	});
};
