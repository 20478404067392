import { isNumber, isObject } from 'lodash';

export function calculateVesselOffsets(offsets, locations, additionalOffset = {}) {
	return {
		...calculateHorizontalVesselOffsets(offsets, locations, additionalOffset),
		...calculateVerticalVesselOffsets(offsets, locations, additionalOffset),
	};
}

export function calculateHorizontalVesselOffsets(offsets, locations, additionalOffset = {}) {
	const newOffsets = {};

	const { anchor: anchorPlacement, vessel: vesselPlacement } = locations;

	const { anchor: anchorOffsets, vessel: vesselOffsets } = offsets;

	const anchorWidth = anchorOffsets.right - anchorOffsets.left;
	const vesselWidth = vesselOffsets.right - vesselOffsets.left;

	if (anchorPlacement.horizontal === 'left') {
		newOffsets.left = anchorOffsets.left;
	} else if (anchorPlacement.horizontal === 'center') {
		newOffsets.left = anchorOffsets.left + anchorWidth / 2;
	} else {
		newOffsets.left = anchorOffsets.right;
		if (typeof additionalOffset?.left === 'number') {
			newOffsets.left += additionalOffset.left;
		}
	}

	if (vesselPlacement.horizontal === 'center') {
		newOffsets.left -= vesselWidth / 2;
	} else if (vesselPlacement.horizontal === 'right') {
		newOffsets.left -= vesselWidth;
	}

	newOffsets.right = newOffsets.left + vesselWidth;

	return newOffsets;
}

export function calculateVerticalVesselOffsets(offsets, locations) {
	const newOffsets = {};

	const { anchor: anchorPlacement, vessel: vesselPlacement } = locations;

	const { anchor: anchorOffsets, vessel: vesselOffsets } = offsets;

	const anchorHeight = anchorOffsets.bottom - anchorOffsets.top;
	const vesselHeight = vesselOffsets.bottom - vesselOffsets.top;

	if (anchorPlacement.vertical === 'top') {
		newOffsets.top = anchorOffsets.top;
	} else if (anchorPlacement.vertical === 'middle') {
		newOffsets.top = anchorOffsets.top + anchorHeight / 2;
	} else {
		newOffsets.top = anchorOffsets.bottom;
	}

	if (vesselPlacement.vertical === 'middle') {
		newOffsets.top -= vesselHeight / 2;
	} else if (vesselPlacement.vertical === 'bottom') {
		newOffsets.top -= vesselHeight;
	}

	newOffsets.bottom = newOffsets.top + vesselHeight;

	return newOffsets;
}

export function verifyOffsets(offsets) {
	if (
		!isObject(offsets) ||
		!isNumber(offsets.bottom) ||
		!isNumber(offsets.left) ||
		!isNumber(offsets.right) ||
		!isNumber(offsets.top)
	) {
		throw new Error('Offsets must be an object containing numeric: "bottom", "left", "right", and "top" properties');
	}
}
