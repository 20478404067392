import { ChangeEvent } from 'react';
import { TextArea } from '~components/text-area';
import { FormFactoryTextAreaModel } from '../types/builtin-models.types';
import { adapted } from '../utils/adapted';

export const TextareaField = adapted(TextArea)((model: FormFactoryTextAreaModel) => {
	const { id, label, onBlur, onChange, required, status, value, width = 10 } = model;

	return {
		label,
		minRows: 3,
		onBlur: typeof onBlur === 'function' ? onBlur : undefined,
		onChange: (event: ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				onChange({ id, value: event.currentTarget.value });
			}
		},
		required,
		status,
		value,
		width,
	};
});
