import { ifFeature } from '@bamboohr/utils/lib/feature';
import { PillType, PillProps } from './types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<Pick<PillProps, 'muted' | 'type'>>()((
	{ palette, spacing, typography, borderRadiuses, constructs, mode },
	{ muted, type }
) => {
	const getPillColor = (type: PillType | undefined): string | undefined => {
		const getColorByPillType: Record<PillType, string> = {
			[PillType.Brand]: palette.primary.main,
			[PillType.Discovery]: '#6A4197',
			[PillType.Error]: palette.error.main,
			[PillType.Greenhouse]: '#6A4197',
			[PillType.Inactive]: palette.gray[700],
			[PillType.Info]: palette.info.main,
			[PillType.Inverted]: palette.common.white,
			[PillType.Neutral]: palette.gray[600],
			[PillType.Success]: palette.success.main,
			[PillType.Warning]: palette.warning.main,
		};

		return type ? getColorByPillType[type] : undefined;
	};

	const getEncorePillColor = (type: PillType | undefined, muted: boolean | undefined) => {
		const getColorByPillType: Record<PillType, string | undefined> = {
			[PillType.Brand]: constructs.surface.primary?.[muted ? 'weak' : 'strong'],
			[PillType.Discovery]: constructs.surface.discovery?.[muted ? 'weak' : 'strong'],
			[PillType.Error]: constructs.surface.error?.[muted ? 'weak' : 'strong'],
			[PillType.Greenhouse]: constructs.surface.discovery?.[muted ? 'weak' : 'strong'],
			[PillType.Inactive]: constructs.surface.neutral?.[muted ? 'weak' : 'strong'],
			[PillType.Info]: constructs.surface.info?.[muted ? 'weak' : 'strong'],
			[PillType.Inverted]: constructs.surface.neutral?.inverted,
			[PillType.Neutral]: constructs.surface.neutral?.[muted ? 'weak' : 'strong'],
			[PillType.Success]: constructs.surface.success?.[muted ? 'weak' : 'strong'],
			[PillType.Warning]: constructs.surface.warning?.[muted ? 'weak' : 'strong'],
		};

		return type ? getColorByPillType[type] : undefined;
	};

	const getIconTextColor = (type: PillType | undefined, component: 'icon' | 'text') => {
		const getColorByPillType: Record<PillType, string | undefined> = {
			[PillType.Brand]: constructs?.[component].primary?.strong,
			[PillType.Discovery]: constructs?.[component].discovery?.strong,
			[PillType.Error]: constructs?.[component].error?.strong,
			[PillType.Greenhouse]: constructs?.[component].discovery?.strong,
			[PillType.Inactive]: constructs?.[component].neutral?.strong,
			[PillType.Info]: constructs?.[component].info?.strong,
			[PillType.Inverted]: constructs?.[component].primary?.strong,
			[PillType.Neutral]: constructs?.[component].neutral?.strong,
			[PillType.Success]: constructs?.[component].success?.strong,
			[PillType.Warning]: constructs?.[component].warning?.strong,
		};

		return type ? getColorByPillType[type] : undefined;
	};

	return {
		root: ifFeature(
			'encore',
			{
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : getEncorePillColor(type, muted),
				borderRadius: borderRadiuses[100],
				color: type === PillType.Inverted || muted ? getIconTextColor(type, 'text') : constructs.text.neutral.inverted,
				display: 'inline-block',
				fontSize: typography.xsmall?.fontSize,
				fontWeight: typography.fontWeightMedium,
				lineHeight: typography.xsmall?.lineHeight,
				padding: spacing(0.5, 1),
			},
			{
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : getPillColor(type),
				borderRadius: '4px',
				color: palette.primary.contrastText,
				display: 'inline-block',
				fontSize: '11px',
				fontWeight: typography.fontWeightBold,
				letterSpacing: '0.5px',
				lineHeight: '8px',
				padding: '5px',
				textTransform: 'uppercase',
			}
		),
		icon: {
			fill: type === PillType.Inverted || muted ? getIconTextColor(type, 'icon') : constructs.text.neutral.inverted,
			marginRight: spacing(0.5),
			position: 'relative',
			top: '1px',
		},
	};
});
