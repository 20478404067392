import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { FlexProps } from './flex.types';

export const useStyles = makeStyles<Pick<FlexProps, 'overflowWrap'>>()(({ mode }, { overflowWrap }) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			overflowWrap,
		},
	};
});
