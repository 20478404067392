import composeRefs from '@seznam/compose-react-refs';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useIsMounted } from 'usehooks-ts';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Menu } from '~components/menu';
import { IconV2 } from '~components/icon-v2';
import { ItemType, ToggleButtonProps } from '~components/menu/types';
import { CellRenderer } from '../cell-renderer';
import { useDropdownCellEditorStyles } from './dropdown-cell-editor.styles';
import { InternalDropdownCellEditorParams } from '../data-grid.types';

export const DropdownCellEditor = forwardRef((props: InternalDropdownCellEditorParams, ref) => {
	const {
		colDef,
		data,
		eGridCell,
		formatValue,
		parseValue,
		value,
		options: initialOptions,
		onSearch,
		showSearch,
		stopEditing,
	} = props;

	const buttonRef = useRef<HTMLButtonElement>(null);

	const [options, setOptions] = useState(initialOptions);
	const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(options.find(option => option.value === value));
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [searchText, setSearchText] = useState('');

	const { classes } = useDropdownCellEditorStyles();

	const isMounted = useIsMounted();

	useImperativeHandle(
		ref,
		() => {
			return {
				getValue() {
					return parseValue(selectedItem ? selectedItem.value : value);
				},
			};
		},
		[parseValue, selectedItem, value]
	);

	const _onSearch =
		onSearch &&
		(async (query: string) => {
			setSearchText(query);
			const filteredOptions = await Promise.resolve(onSearch(query, options));
			setOptions(filteredOptions);
		});

	const onSelect = (selectedItem: ItemType) => {
		setSelectedItem(selectedItem);
		setIsOpen(false);

		setTimeout(() => {
			if (isMounted()) {
				stopEditing();
			}
		});
	};

	useEffect(() => {
		buttonRef.current?.focus();
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsOpen(true);
		});

		return () => clearTimeout(timeoutId);
	}, []);
	return (
		<div className={classes.menuWrapper}>
			<Menu
				isFullWidth={true}
				isOpen={isOpen}
				items={options}
				onClose={() => {
					setIsOpen(false);
				}}
				onOpen={() => setIsOpen(true)}
				onSearch={_onSearch}
				onSelect={onSelect}
				renderToggle={(_, buttonProps: ToggleButtonProps) => {
					const { ref, style, ...rest } = buttonProps;
					return (
						<button {...rest} className={classes.root} ref={composeRefs(buttonRef, ref)} type="button">
							{ifFeature(
								'encore',
								<>
									<span className={classes.formattedValue}> {formatValue(value) ?? value}</span>
									<IconV2 color="neutral-strong" name="caret-up-solid" size={16} />
								</>,
								<CellRenderer
									{...colDef.cellRendererParams}
									colDef={colDef}
									data={data}
									eGridCell={eGridCell}
									value={value}
									valueFormatted={formatValue(value)}
								/>
							)}
						</button>
					);
				}}
				searchText={onSearch ? searchText : undefined}
				selectedItems={selectedItem ? [selectedItem] : []}
				showSearch={showSearch}
			/>
		</div>
	);
});
