// eslint-disable-next-line no-use-before-define
import React from 'react';
import { InputAdornment } from '@mui/material';
import { NubProps } from './nub.types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

const useStyles = makeStyles()(theme => {
	const { mode } = theme;
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});

export const Nub = ({ children, position = 'start', ...rest }: NubProps) => {
	const { classes } = useStyles();
	return (
		<InputAdornment className={classes.root} {...rest} position={position}>
			{children}
		</InputAdornment>
	);
};
