import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { SideSubNavigationLinkProps } from './link.types';
import type { ElementType } from 'react';

export const useStyles = makeStyles<Partial<SideSubNavigationLinkProps>>()((
	{ borderRadiuses, mode, palette, spacing, typography },
	{ active }
) => {
	const color = active ? palette.primary.main : palette.gray[800];

	return ifFeature(
		'encore',
		{
			root: {
				alignItems: 'center',
				backgroundColor: active ? palette.gray[100] : 'transparent',
				borderRadius: '8px',
				color: mode === ModeOptions.Dye ? dyeColors.main : color,
				display: 'flex',
				fill: mode === ModeOptions.Dye ? dyeColors.main : color,
				fontSize: typography.small.fontSize,
				fontWeight: active ? typography.fontWeightHeavy : '',
				gap: spacing(1.5),
				lineHeight: typography.small.lineHeight,
				padding: `${spacing(1)} ${spacing(1.5)}`,
				textDecoration: 'none',
				'&:hover': {
					backgroundColor: palette.gray[100],
					color: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					fill: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					textDecoration: 'none',
				},
				...ifFeature(
					'encore',
					{
						'&:focus-visible': {
							borderRadius: borderRadiuses[200],
							boxShadow: `0 0 0 2px color-mix(in srgb, ${palette.primary.light} 80%, transparent)`,
							color: palette.primary.main,
							fill: palette.primary.main,
						},
					},
					{}
				),
			},
			disabled: {
				alignItems: 'center',
				backgroundColor: 'transparent',
				borderRadius: '8px',
				color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[600],
				cursor: 'default',
				display: 'flex',
				fill: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[600],
				fontSize: typography.small.fontSize,
				fontWeight: active ? typography.fontWeightHeavy : '',
				gap: spacing(1.5),
				lineHeight: typography.small.lineHeight,
				padding: `${spacing(1)} ${spacing(1.5)}`,
				pointerEvents: 'none',
				textDecoration: 'none',
			},
		},
		{
			root: {
				alignItems: 'center',
				backgroundColor: active ? palette.gray[100] : 'transparent',
				border: 'none',
				borderRadius: '2px',
				boxSizing: 'border-box',
				color: mode === ModeOptions.Dye ? dyeColors.main : color,
				display: 'flex',
				fill: mode === ModeOptions.Dye ? dyeColors.main : color,
				fontWeight: active ? typography.fontWeightHeavy : '',
				gap: spacing(1),
				marginLeft: '0',
				overflowWrap: 'anywhere',
				padding: '9px 10px',
				textDecoration: 'none',
				textIndent: '0',
				'&:hover': {
					backgroundColor: palette.gray[100],
					borderRadius: '4px',
					color: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					fill: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
					textDecoration: 'none',
				},
			},
			disabled: {
				alignItems: 'center',
				backgroundColor: 'transparent',
				border: 'none',
				borderRadius: '2px',
				boxSizing: 'border-box',
				color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[500],
				cursor: 'default',
				display: 'flex',
				fill: mode === ModeOptions.Dye ? dyeColors.lighter : palette.gray[500],
				gap: spacing(1),
				marginLeft: '0',
				padding: '9px 10px',
				pointerEvents: 'none',
				textDecoration: 'none',
				textIndent: '0',
			},
		}
	);
});
