import React, { ReactElement, ReactNode } from 'react';
import { FabricIcon } from '@fabric/icon';
import { tableDev, _tooltipAdapter } from './table';

import { TableActionsCellProps } from './types';
import { IconButton } from '~components/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isExternalUrl } from '~utils';

export function ActionsCell<T>(props: TableActionsCellProps<T>): ReactElement {
	const { actions, classes, row } = props;

	const allActions = actions(row);

	return (
		<div className={classes.cellActions}>
			{allActions.length > 0 &&
				allActions.map((action): ReactElement => {
					const { icon, action: onClick, href = '', key, newTab, tooltipTitle, tooltipContent, biId = null } = action;
					let render: ReactNode = '';
					const hasHref = href && typeof href === 'string';
					const hasIcon = icon;
					const iconString = typeof icon === 'string' ? icon : undefined;
					const hasPlaceholder = typeof icon === 'string' ? icon.indexOf('PLACEHOLDER') > -1 : false;
					const classNames = 'fab-FloatingIconButton fab-FloatingIconButton--secondary fab-FloatingIconButton--teenie';
					const ariaLabel = `${tooltipTitle || ''} ${tooltipContent || ''}`.trim() || undefined;

					if (hasPlaceholder) {
						return (
							<div
								className="fab-IconButtonPlaceholder fab-IconButtonPlaceholder--teenie"
								key={key || iconString}
								style={{ width: `${getPlaceholderWidth(iconString)}px` }}
							/>
						);
					}
					if (hasHref && hasIcon) {
						const shouldOpenInNewTab = isExternalUrl(href) || newTab;
						render = ifFeature(
							'encore',
							<IconButton
								ariaLabel={ariaLabel}
								data-bi-id={biId}
								disabled={!onClick && !href}
								floatingIcon={true}
								href={href}
								icon={icon}
								onClick={onClick}
								size="small"
								tabIndex={0}
								target={shouldOpenInNewTab ? '_blank' : undefined}
								type="button"
								variant="outlined"
							/>,
							<a
								aria-label={ariaLabel}
								className={classNames}
								data-bi-id={biId}
								href={href}
								rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
								tabIndex={0}
								target={shouldOpenInNewTab ? '_blank' : undefined}
							>
								{typeof icon === 'string' ? <FabricIcon aria-hidden={true} name={icon} /> : icon}
							</a>
						);
					} else if (hasIcon) {
						render = ifFeature(
							'encore',
							<IconButton
								ariaLabel={ariaLabel}
								data-bi-id={biId}
								disabled={!onClick}
								floatingIcon={true}
								icon={icon}
								onClick={onClick}
								size="small"
								tabIndex={0}
								type="button"
								variant="outlined"
							/>,
							<button
								aria-label={ariaLabel}
								className={classNames}
								data-bi-id={biId}
								disabled={!onClick}
								onClick={onClick}
								tabIndex={0}
								type="button"
							>
								{typeof icon === 'string' ? <FabricIcon aria-hidden={true} name={icon} /> : icon}
							</button>
						);
					} else if (!key) {
						tableDev.warn('You need to supply a "key" when you have not provided an "icon" when declaring actions.');
					}

					return (
						<div
							key={iconString || key}
							onMouseEnter={({ currentTarget }) => {
								return _tooltipAdapter.show(currentTarget, tooltipTitle, tooltipContent);
							}}
						>
							{render}
						</div>
					);
				})}
		</div>
	);
}

export function getPlaceholderWidth(placeholderString = ''): string {
	const match = /PLACEHOLDER-(\d+)/.exec(placeholderString);
	return match ? match[1] : '0';
}
