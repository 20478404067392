import { TabsListUnstyled, TabsListUnstyledProps } from '@mui/base';
import { TabsListUnstyledTypeMap } from '@mui/base/TabsListUnstyled/TabsListUnstyledProps';
import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MUIStyledCommonProps } from '@mui/system';
import React, { ElementType, forwardRef, ReactNode, Ref } from 'react';
import { sideTabsLayoutPaddingY } from '../constants';
import { SideTabListHeader } from './side-tab-list-header';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabListOwnProps extends MUIStyledCommonProps<Theme> {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	className?: string;
	/** A string for a simple header, or any other valid `ReactNode`. */
	header?: ReactNode;
	/**
	 * @deprecated sx should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	sx?: MUIStyledCommonProps['sx'];
}

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export type SideTabListProps<Component extends ElementType = TabsListUnstyledTypeMap['defaultComponent']> = TabsListUnstyledProps<
	Component,
	SideTabListOwnProps
>;

const TabList = styled(TabsListUnstyled)(({ theme }) => ({
	borderRight: theme.mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : `1px solid ${theme.palette.primary.lightest}`,
	padding: `${theme.spacing(2)} var(${sideTabsLayoutPaddingY})`,
	flex: '225px 0 0',
	boxSizing: 'border-box',
}));

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The vertical tabs that appear to the left of the content/panels by default.
 */
export const SideTabList = forwardRef(function SideTabList(
	{ children, header, ...props }: SideTabListProps,
	ref: Ref<HTMLDivElement>
) {
	return (
		<TabList {...props} ref={ref}>
			{typeof header === 'string' ? <SideTabListHeader>{header}</SideTabListHeader> : header}
			{children}
		</TabList>
	);
	// Generics are lost with `forwardRef`
	// https://github.com/mui/material-ui/issues/32420#issuecomment-1146757121
}) as OverridableComponent<TabsListUnstyledTypeMap<SideTabListOwnProps>>;
