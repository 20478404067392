// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button, IconButton } from '~components/button';
import { ChevronLeft8x13, ChevronRight8x13, DoubleChevronLeft14x11, DoubleChevronRight14x11 } from '@bamboohr/grim';
import { ButtonControlProps } from '../../types';

import { useStyles } from './styles';

export function ButtonControls(props: ButtonControlProps): ReactElement {
	const { onClickAddAll, onClickAddHighlighted, onClickRemoveHighlighted, onClickRemoveAll, classes: classNames, biId } = props;

	const { classes } = ifFeature('encore', useStyles(), useStyles(undefined, { props: { classes: classNames } }));
	return ifFeature(
		'encore',
		<div className={classes.buttonControlContainer}>
			<IconButton
				aria-label={window.jQuery ? $.__('Select all items') : 'Select all items'}
				data-bi-id={biId && `${biId}-add-all-button`}
				icon="chevrons-right-solid"
				onClick={onClickAddAll}
				size="small"
				type="button"
				variant="outlined"
			/>
			<IconButton
				aria-label={window.jQuery ? $.__('Select highlighted items') : 'Select highlighted items'}
				data-bi-id={biId && `${biId}-add-selected-button`}
				icon="chevron-right-solid"
				onClick={onClickAddHighlighted}
				size="small"
				type="button"
				variant="outlined"
			/>
			<IconButton
				aria-label={window.jQuery ? $.__('Remove all items') : 'Remove all items'}
				data-bi-id={biId && `${biId}-remove-all-button`}
				icon="chevron-left-solid"
				onClick={onClickRemoveHighlighted}
				size="small"
				type="button"
				variant="outlined"
			/>
			<IconButton
				aria-label={window.jQuery ? $.__('Remove highlighted items') : 'Remove highlighted items'}
				data-bi-id={biId && `${biId}-remove-selected-button`}
				icon="chevrons-left-solid"
				onClick={onClickRemoveAll}
				size="small"
				type="button"
				variant="outlined"
			/>
		</div>,
		<div className={classes.buttonControlContainer}>
			<div className={classes.buttonContainer}>
				<Button
					aria-label={window.jQuery ? $.__('Select all items') : 'Select all items'}
					clickAction={onClickAddAll}
					data-bi-id={biId && `${biId}-add-all-button`}
					icon={<DoubleChevronRight14x11 />}
					outline={true}
					secondary={true}
					size="teenie"
					type="button"
					width={1}
				/>
			</div>
			<div className={classes.buttonContainer}>
				<Button
					aria-label={window.jQuery ? $.__('Select highlighted items') : 'Select highlighted items'}
					clickAction={onClickAddHighlighted}
					data-bi-id={biId && `${biId}-add-selected-button`}
					icon={<ChevronRight8x13 />}
					outline={true}
					secondary={true}
					size="teenie"
					type="button"
					width={1}
				/>
			</div>
			<div className={classes.buttonContainer}>
				<Button
					aria-label={window.jQuery ? $.__('Remove all items') : 'Remove all items'}
					clickAction={onClickRemoveHighlighted}
					data-bi-id={biId && `${biId}-remove-all-button`}
					icon={<ChevronLeft8x13 />}
					outline={true}
					secondary={true}
					size="teenie"
					type="button"
					width={1}
				/>
			</div>
			<div className={classes.buttonContainer}>
				<Button
					aria-label={window.jQuery ? $.__('Remove highlighted items') : 'Remove highlighted items'}
					clickAction={onClickRemoveAll}
					data-bi-id={biId && `${biId}-remove-selected-button`}
					icon={<DoubleChevronLeft14x11 />}
					outline={true}
					secondary={true}
					size="teenie"
					type="button"
					width={1}
				/>
			</div>
		</div>
	);
}
