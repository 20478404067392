/**
 * Creates a CSS calc() function that sets the width of the container holding all the items in the carousel
 *
 * In the following example, a {n} represents an item in the carousel and the [] represents the visible container
 * [ {1} {2} {3} ] {4} {5} {6}
 *  gap ↑
 * ↑          width          ↑     createWidth creates a calc() that determines this width
 *
 * @param gap The gap you want between the elements (i.e. 10px)
 * @param show How many elements you want to show in the carousel
 * @param total Total number of elements in the carousel (total showing + those not showing)
 * @returns CSS calc function
 */
export function createWidth(gap: string, show: number, total: number): string {
	// Given the following params: ('10px', 4, 6)
	// you probably would have a carousel that looked like the following:
	// [ {1} {2} {3} {4} ] {5} {6}

	// and would need a calc function that looked like the following
	// calc(((100% - (3 * 10px)) / 4) * 6 + (10px * 5));

	// Step through

	// (100% - (3 * 10px))
	// The visible container (100%) minus the gaps between each VISIBLE element
	// If you have 4 elements, there would be 3 gaps present at 10px each (30px)
	// 100% - 30px
	// there are 4 elements visible so we can divide the previous value by 4.
	// (100 - 30px) / 4
	// This gives you the true width of an individual item
	// It is then multiplied by the total elements (6)
	// (true value of a single item) * 6
	// (100 - 30px) / 4 * 6
	// but now you need to add the gaps back in for ALL total elements (6 - 1) * the gap (10px)
	// (width of a single item * total items) + the gaps between all items

	const width = `calc(((100% - (${show - 1} * ${gap})) / ${show}) * ${total} + (${gap} * ${total - 1}))`;
	return width;
}

/**
 * Creates the relative left position of the container holding all the items in the carousel
 *
 * In the following example, a |n| represents an item in the carousel and the {} represents the visible container
 * {1} {2} [ {3} {4} {5} ] {6}
 *    index(2)↑  gap↑
 *
 * @param gap The gap you want between the elements (i.e. 10px)
 * @param show How many elements you want to show in the carousel
 * @param index The index of the leftmost visible element
 * (if you have elements 1,2,3,4, are showing 2 at a time, and want to show 2,3, the index would be 1 1[23]4)
 * @returns CSS calc function
 */
export function createLeft(gap: string, show: number, index: number): string {
	// Given the following params: ('10px', 4, 2)
	// you would have a carousel that looked like the following:
	// {1} {2} [ {3} {4} {5} {6} ]

	// and would need a calc function that looked like the following
	// calc((-100% - 10px) / 4 * 3);

	// Step through
	// This one is easier to understand in terms of fixed dimensions.
	// Let's say that that the visible container is 400px (i.e. 100% = 400px)
	// -400px - 10px = -410px
	// -410px / 4 = -127.5. That is the value of each item PLUS the gap
	// -127.5 * 2 (to show the 3rd) = -255px
	return `calc((-100% - ${gap}) / ${show} * ${index})`;
}
