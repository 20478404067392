import { CursorType, MaskConfig, Part, PartFinalized } from '../types';
import { finalizeParts } from '../utils';

function adjustPositionForDelimiter(part: Part | undefined, finalValue: string, position: number, delimiter: string): number {
	const matchComplete = part ? part.matchComplete : true;
	if (matchComplete && finalValue[position] === delimiter) {
		position += 1;
	}

	return position;
}

export function getCursorType(keydownStartPosition: number, keydownEndPosition: number): CursorType {
	return keydownStartPosition === keydownEndPosition ? 'point' : 'selection';
}

export function getFinalPosition(
	config: MaskConfig,
	parts: Array<Part>,
	keydownInputValue: string,
	keydownStartPosition: number,
	keydownEndPosition: number,
	inputValue: string,
	startPosition: number,
	finalValue: string
): number {
	const delimiter = config.delimiterChar;
	const lengthChange = inputValue.length - keydownInputValue.length;
	const cursorType = getCursorType(keydownStartPosition, keydownEndPosition);

	// if single backspace/delete pressed
	if (cursorType === 'point' && lengthChange === -1) {
		return startPosition;
	}

	const finalParts = finalizeParts(config, parts, finalValue);

	// if part changes were attempted
	const attemptedChangedParts = finalParts.filter(part => part.changeAttempted);
	if (attemptedChangedParts.length) {
		const positionPart = getPositionPart(attemptedChangedParts);

		let finalPosition;
		if (shouldSetToPartMatchLength(positionPart)) {
			finalPosition = (positionPart.finalValueIndexes?.startIndex || 0) + positionPart.matchedValue.length;
		} else {
			finalPosition = startPosition;
		}

		return adjustPositionForDelimiter(positionPart, finalValue, finalPosition, delimiter);
	}

	// if no part changes were attempted
	return adjustPositionForDelimiter(undefined, finalValue, keydownStartPosition, delimiter);
}

function getPositionPart(parts: Array<PartFinalized>) {
	// default set the position part as the last part
	let part = parts[parts.length - 1];

	// if non-complete part exists, set the position part as the first non-complete part
	const partsNotComplete = parts.filter(part => !part.matchComplete);
	if (partsNotComplete.length) {
		[part] = partsNotComplete;
	}

	return part;
}

function shouldSetToPartMatchLength(part: Part): boolean {
	if (part.changedByPreMatchHook) {
		return true;
	}

	if (part.keydownMatchComplete && !part.matchComplete) {
		return true;
	}

	if (part.rejectedLength) {
		return true;
	}

	if (part.matchedValue && part.keydownMatchedValue) {
		return part.matchedValue.length < part.keydownMatchedValue.length;
	}

	return !!(part.matchedValue || part.keydownMatchedValue);
}
