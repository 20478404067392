import React from 'react';
import { useStyles } from './slat.styles';
import { SlatProps } from './slat.types';

export function Slat({ backgroundColor, borderColor = 'neutral-medium', borderPlacement = 'top', children, v2, ...rest }: SlatProps) {
	const { classes } = useStyles({ backgroundColor, borderColor, borderPlacement, v2 });

	return (
		<div {...rest} className={classes.root}>
			{children}
		</div>
	);
}
