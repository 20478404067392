import { getRangeMissingDateError } from './date-range/get-range-missing-date-error';
import { DAY_MILLISECONDS } from '../shared.domain';
import {
	DatePickerDate,
	DatePickerDatePredicate,
	DatePickerRangeCompletionStatus,
	DatePickerRangeObjectDate,
	DatePickerRangeError,
	DatePickerUtils,
} from '../types';
import { getDateError, getIsDateValid } from '../utils';

export function getIsDateBetweenRange(
	utils: DatePickerUtils,
	date: DatePickerDate,
	startDate: DatePickerDate,
	endDate: DatePickerDate
): boolean {
	if (
		!utils ||
		!date ||
		!getIsDateValid(utils, date) ||
		!startDate ||
		!getIsDateValid(utils, startDate) ||
		!endDate ||
		!getIsDateValid(utils, endDate)
	) {
		throw new Error('Expected arguments are missing.');
	}

	return utils.isAfterDay(date, startDate) && utils.isBeforeDay(date, endDate);
}

export function getIsRangeTooLarge(
	utils: DatePickerUtils,
	date1: DatePickerDate,
	date2: DatePickerDate,
	maxRangeSpanYears: undefined | number
): boolean {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!maxRangeSpanYears) {
		return false;
	}

	if (!date1 || !getIsDateValid(utils, date1) || !date2 || !getIsDateValid(utils, date2)) {
		return false;
	}

	const date1Year = utils.getYear(date1);
	const date2RelativeMinDate = utils.setYear(date1, date1Year - maxRangeSpanYears);
	const date2RelativeMaxDate = utils.setYear(date1, date1Year + maxRangeSpanYears);

	return utils.isBeforeDay(date2, date2RelativeMinDate) || utils.isAfterDay(date2, date2RelativeMaxDate);
}

export function getRangeCompletion(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate
): DatePickerRangeCompletionStatus {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (getIsDateValid(utils, startDate) && getIsDateValid(utils, endDate)) {
		return 'complete';
	}

	if (getIsDateValid(utils, startDate)) {
		return 'onlyStart';
	}

	if (getIsDateValid(utils, endDate)) {
		return 'onlyEnd';
	}

	return 'empty';
}

export function getRangeDateDisabled(
	utils: DatePickerUtils,
	date: DatePickerDate,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	getDateDisabled?: DatePickerDatePredicate,
	disableBeforeStartDate?: boolean,
	disableAfterEndDate?: boolean
): boolean {
	if (!utils || !date || !getIsDateValid(utils, date)) {
		throw new Error('Expected arguments are missing.');
	}

	return (
		(!!minDate && getIsDateValid(utils, minDate) && utils.isBeforeDay(date, minDate)) ||
		(!!maxDate && getIsDateValid(utils, maxDate) && utils.isAfterDay(date, maxDate)) ||
		(disableAfterEndDate && !!endDate && getIsDateValid(utils, endDate) && utils.isAfterDay(date, endDate)) ||
		(disableBeforeStartDate && !!startDate && getIsDateValid(utils, startDate) && utils.isBeforeDay(date, startDate)) ||
		(!!getDateDisabled && getDateDisabled(date))
	);
}

export function getRangeError(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	startInputValue: string,
	endInputValue: string,
	required: boolean,
	startRequired: boolean,
	endRequired: boolean,
	validateDisabledDatesWithinRange: boolean,
	minDate?: DatePickerDate,
	maxDate?: DatePickerDate,
	maxRangeSpanYears?: number,
	getDateDisabled?: DatePickerDatePredicate,
	disableBeforeStartDate?: boolean,
	disableAfterEndDate?: boolean
): DatePickerRangeError {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!startDate || !endDate) {
		return getRangeMissingDateError(startDate, endDate, startInputValue, endInputValue, required, startRequired, endRequired);
	}

	if (!getIsDateValid(utils, startDate) && !getIsDateValid(utils, endDate)) {
		return {
			error: 'invalid',
			type: 'range',
		};
	}

	if (getIsDateValid(utils, startDate) && getIsDateValid(utils, endDate)) {
		if (utils.isAfterDay(startDate, endDate) || utils.isBeforeDay(endDate, startDate)) {
			return {
				error: 'invalid',
				type: 'range',
			};
		}

		if (maxRangeSpanYears && getIsRangeTooLarge(utils, startDate, endDate, maxRangeSpanYears)) {
			return {
				error: 'tooLarge',
				type: 'range',
			};
		}

		if (
			validateDisabledDatesWithinRange &&
			getRangeMatchSome(utils, startDate, endDate, (date: DatePickerDate) => {
				return getRangeDateDisabled(
					utils,
					date,
					startDate,
					endDate,
					minDate,
					maxDate,
					getDateDisabled,
					disableBeforeStartDate,
					disableAfterEndDate
				);
			})
		) {
			return {
				error: 'includesDisabled',
				type: 'range',
			};
		}
	}

	const startError = getDateError(utils, startDate, startInputValue, true, minDate, maxDate, getDateDisabled);
	if (startError) {
		return {
			error: startError,
			type: 'start',
		};
	}

	const endError = getDateError(utils, endDate, endInputValue, true, minDate, maxDate, getDateDisabled);
	if (endError) {
		return {
			error: endError,
			type: 'end',
		};
	}
}

export function getRangeMatchSome(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	predicate: DatePickerDatePredicate
): boolean {
	if (!utils || !startDate || !getIsDateValid(utils, startDate) || !endDate || !getIsDateValid(utils, endDate) || !predicate) {
		throw new Error('Expected arguments are missing.');
	}

	const startDay = utils.startOfDay(startDate);
	const endDay = utils.startOfDay(endDate);
	const daysDiff = utils.getDiff(endDay, startDay) / DAY_MILLISECONDS;
	for (let i = 0; i <= daysDiff; i++) {
		const day = utils.addDays(startDay, i);
		if (predicate(day)) {
			return true;
		}
	}

	return false;
}

export function getRangeOrdered(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate
): DatePickerRangeObjectDate {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	let start = startDate;
	let end = endDate;

	if (
		!!startDate &&
		getIsDateValid(utils, startDate) &&
		!!endDate &&
		getIsDateValid(utils, endDate) &&
		utils.isBeforeDay(endDate, startDate)
	) {
		start = endDate;
		end = startDate;
	}

	return {
		startDate: start,
		endDate: end,
	};
}

export function getRangeYears(utils: DatePickerUtils, startDate: DatePickerDate, endDate: DatePickerDate): Array<number> {
	if (!utils || !startDate || !getIsDateValid(utils, startDate) || !endDate || !getIsDateValid(utils, endDate)) {
		throw new Error('Expected arguments are missing.');
	}

	const years: Array<number> = [];
	const startYear = utils.getYear(startDate);
	const endYear = utils.getYear(endDate);
	const yearsSpan = endYear - startYear + 1;
	for (let i = 0; i < yearsSpan; i++) {
		years.push(startYear + i);
	}

	return years;
}
