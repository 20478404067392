// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, ReactElement, SyntheticEvent } from 'react';
import { Rating as MuiRating } from '@mui/material';
import { useRatingStyles } from './rating.styles';
import { RatingProps } from './types';
import { IconV2 } from '~components/icon-v2';
import { LayoutBox } from '~components/layout-box';

export const Rating = (props: RatingProps): ReactElement => {
	const {
		classes: classesProp = {},
		max = 5,
		onChange: externalOnChange,
		icon = 'star-solid',
		emptyIcon,
		size = 'medium',
		precision = 1,
		value,
	} = props;

	const { classes, cx } = useRatingStyles();

	const getIconSize = size => {
		switch (size) {
			case 'small':
				return 12;
			case 'medium':
				return 16;
			case 'large':
				return 40;
			default:
				return 16;
		}
	};

	const [selectedValue, setSelectedValue] = useState<number | null | undefined>(value);
	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	// LayoutBox used below is to add margin to the icon
	const ICON =
		typeof icon === 'string' ? (
			<LayoutBox marginLeft={0.5}>
				<IconV2 color="notice-strong" name={icon} size={getIconSize(size)} />
			</LayoutBox>
		) : (
			icon
		);

	// LayoutBox used below is to add margin to the icon
	const EMPTY_ICON =
		typeof icon === 'string' ? (
			<LayoutBox marginLeft={0.5}>
				<IconV2 color="neutral-extra-weak" name={icon} size={getIconSize(size)} />
			</LayoutBox>
		) : (
			icon
		);

	return (
		<MuiRating
			{...props}
			classes={classesProp}
			className={cx(classes.root, classesProp.root, {
				[classes.large]: size === 'large',
			})}
			emptyIcon={emptyIcon || EMPTY_ICON}
			icon={ICON}
			max={max}
			onChange={(event: SyntheticEvent, newValue: number | null) => {
				setSelectedValue(newValue);
				if (typeof externalOnChange === 'function') {
					externalOnChange(event, newValue);
				}
			}}
			precision={precision || 1}
			size={size}
			value={selectedValue}
		/>
	);
};
