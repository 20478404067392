import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement } from 'react';
import { Headline } from '~components/headline';
import { IconTile } from '~components/icon-tile';
import { useHeaderStyles } from './header.styles';
import { HeaderProps } from './header.types';
import { getHeadlineSizeFromHeaderSize } from './utils';

export const Header = (props: HeaderProps): ReactElement => {
	const { actions, biId, description = '', divider = false, icon, id, size = 'medium', title, titleComponent } = props;
	const { classes } = useHeaderStyles(props);

	return (
		<div data-bi-id={biId} id={id}>
			{ifFeature(
				'encore',
				<>
					{divider !== 'bottom' && divider && <hr className={classes.dividersTop} />}
					<div className={classes.content}>
						{icon && (
							<div className={classes.iconContainer}>
								{typeof icon === 'string' && <IconTile height="100%" icon={icon} size={40} />}
								{/* We will come back to this Avatar work ASAP. See https://bamboohr.atlassian.net/browse/FAB-2487 for more details */}
								{/* {typeof icon !== 'string' &&
									cloneElement(icon as ReactElement, { size: getAvatarSize(size) })} */}
							</div>
						)}
						<div>
							<Headline component={titleComponent} size={getHeadlineSizeFromHeaderSize(size)}>
								{title}
							</Headline>
							{description && <div className={classes.description}>{description}</div>}
						</div>
						{actions && <div className={classes.actions}>{actions}</div>}
					</div>
					{divider === 'bottom' && <hr className={classes.dividersBottom} />}
				</>,
				'Section.Header component is only available with Encore enabled.'
			)}
		</div>
	);
};
