import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { RoundedBoxProps } from '../rounded-box/types/rounded-box-props';
import { SummaryBlockProps } from './types/summary-block-props';

// Defaults
const MARGIN = 0;
const PADDING = 2;
const ICON_HEIGHT = '36px';
const ICON_WIDTH = '36px';
const HEIGHT = '75px';
const FLEX = 'none';

export const useStyles = makeStyles<SummaryBlockProps & RoundedBoxProps>()(({ mode, palette, spacing, typography }, params) => {
	const COLOR = palette.gray[700];
	const BG_COLOR = palette.gray[200];

	const { backgroundColor, color, flex, height, iconColor, iconHeight, iconWidth, margin, padding } = params;

	return {
		root: {
			alignItems: 'center',
			backgroundColor: backgroundColor || BG_COLOR,
			borderRadius: spacing(1),
			color: color || COLOR,
			display: 'flex',
			flex: flex || FLEX,
			gap: '10px',
			height: height || HEIGHT,
			margin: margin || spacing(MARGIN),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: padding || spacing(PADDING),
		},
		value: {
			fontSize: typography.biggie?.fontSize,
			fontWeight: typography.fontWeightBold,
			lineHeight: typography.biggie?.lineHeight,
		},
		label: {
			fontSize: typography.small?.fontSize,
			lineHeight: typography.small?.lineHeight,
		},
		icon: {
			fill: iconColor || color || COLOR, // Defaults to text color
			height: iconHeight || ICON_HEIGHT,
			width: iconWidth || ICON_WIDTH,
		},
		warning: {
			color: palette.warning.main,
		},
		info: {
			color: palette.info.main,
		},
		error: {
			color: palette.error.main,
		},
	};
});
