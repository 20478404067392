import { CollisionStrategies, Directions } from './enums';

export const OPPOSITE_DIRECTIONS = Object.freeze({
	[Directions.BOTTOM]: Directions.TOP,
	[Directions.CENTER]: Directions.CENTER,
	[Directions.LEFT]: Directions.RIGHT,
	[Directions.RIGHT]: Directions.LEFT,
	[Directions.MIDDLE]: Directions.MIDDLE,
	[Directions.TOP]: Directions.BOTTOM,
});

export const DEFAULT_PLACEMENT = {
	align: 'start',
	side: 'bottom',
};

export const DEFAULT_COLLISION_STRATEGIES = [CollisionStrategies.FLIP, CollisionStrategies.CONSTRAIN];

export const VESSEL_DISPLAY_VALUE_WHEN_VISIBLE = 'flex';
export const VESSEL_DISPLAY_VALUE_WHEN_HIDDEN = 'none';
