import { makeStyles } from '~styles';
import { CardFlex, CardSize } from '@fabric/card';

export const cardSizes = {
	small: '608px',
	medium: '800px',
	full: '100%',
};

export const useStyles = makeStyles<{ flex: CardFlex; hasHover?: boolean; noPadding?: boolean; size: CardSize }>()((theme, params) => {
	const { palette, mixins } = theme;
	const { flex, hasHover, size } = params;

	return {
		root: {
			boxSizing: 'border-box',
			backgroundColor: palette.common.white,
			border: `1px solid ${palette.grey[200]}`,
			borderRadius: '16px',
			boxShadow: `1px 1px 0px 2px ${mixins.alpha(palette.grey[900], 0.03)}`,
			overflow: 'auto',
			position: 'relative',
			textAlign: 'left',
			flexDirection: flex || undefined,
			transition: 'box-shadow 0.2s ease-in-out',
			width: cardSizes[size],
			'&:hover': hasHover
				? {
						boxShadow: `3px 3px 10px 2px ${mixins.alpha(palette.grey[900], 0.1)}`,
					}
				: {},
		},
	};
});
