// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useRef, useState } from 'react';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { BarStack, BarStackHorizontal } from '@visx/shape';
import { BarList, BarsProps } from '../types';
import { AnimatedBar } from './animated-bar';
import { BarValues } from './bar-values';
import { filterOutBarsWithValueOfZeroInBarStacks } from './show-bar';
import { getStacksByBar } from './get-stack-by-bar';
import { useBarVariants } from './use-bar-variants';
import { useRoundedPath } from './use-rounded-path';
import { motion } from 'framer-motion';
import { getBarStackDimensions } from './get-bar-stack-dimensions';

let barGroupRender = 0;

const BarGroup = <Datum = unknown,>({
	barStack,
	barStackIndex,
	hoverEffect,
	keys,
	onMouseMove,
	orientation,
	getSeriesValue,
	xScale,
	yScale,
	animate,
	getBarValueFormat,
	getBandTotal,
	getBarValue,
	barValues,
	data,
	xMax,
	yMax,
	renderOverlay: Overlay,
	...rest
}: {
	barStack: BarList<Datum>;
	barStackIndex: number;
} & BarsProps<Datum>) => {
	const barGroupRef = useRef<SVGGElement>(null);
	const [segments, setSegments] = useState<Record<string, SVGPathElement | null>>({});
	const [segmentKey, setSegmentKey] = useState<string | null>(null);
	const [selected, setSelected] = useState<boolean>(false);
	const selectedSegment = segments[segmentKey ?? ''];
	const { x: barGroupX, y: barGroupY, width: barGroupWidth, height: barGroupHeight } = getBarStackDimensions(barStack);
	const isVertical = orientation === 'vertical';
	const barGroupPath = useRoundedPath(barGroupX, barGroupY, barGroupWidth, barGroupHeight, isVertical, true, true, true, true);
	const barGroupVariants = useBarVariants(isVertical, barGroupPath, barGroupWidth, barGroupHeight, yMax, barGroupX, barGroupY);
	barGroupRender++;
	const clipPathId = `barGroup-${barGroupRender}`;
	return (
		<g
			{...{
				title: window.jQuery ? $.__('Data series for %s', barStack.series) : `Data series for ${barStack.series}`,
			}}
			onMouseEnter={() => setSelected(true)}
			onMouseLeave={() => setSelected(false)}
			ref={barGroupRef}
			style={hoverEffect && selected ? { cursor: 'pointer' } : {}}
		>
			<clipPath id={clipPathId}>
				<motion.path animate="idle" d={barGroupPath} initial={animate ? 'initial' : 'idle'} variants={barGroupVariants} />
			</clipPath>
			{barStack.bars.map((bar, i) => (
				<AnimatedBar<Datum>
					animate={animate}
					bar={bar}
					clipPath={clipPathId}
					getBarValue={getBarValue}
					isFirstSegment={i === 0}
					isLastSegment={i === barStack.bars.length - 1}
					isOnlyBar={barStack.bars.length === 1}
					key={`bar-animated-${barStack.index}-${bar.index}-${bar.key}`}
					onMouseMove={e => {
						if (onMouseMove) onMouseMove(e);
						setSegmentKey(bar.key);
					}}
					orientation={orientation}
					ref={segment =>
						setSegments(prev => {
							prev[bar.key] = segment;
							return prev;
						})
					}
					selected={!!hoverEffect && selected}
					xMax={xMax}
					yMax={yMax}
					{...rest}
				/>
			))}
			{barValues && (
				<BarValues<Datum>
					animate={animate}
					data={data[barStackIndex]}
					getBandTotal={getBandTotal}
					getBarValue={getBarValue}
					getBarValueFormat={getBarValueFormat}
					getSeriesValue={getSeriesValue}
					keys={keys}
					orientation={orientation}
					xMax={xMax}
					xScale={xScale as ScaleBand<string> | ScaleLinear<number, number>}
					yMax={yMax}
					yScale={yScale as ScaleBand<string> | ScaleLinear<number, number>}
				/>
			)}
			{Overlay && segmentKey !== null && selectedSegment && barGroupRef.current && (
				<Overlay
					anchorEl={barGroupRef.current}
					data={data[barStack.index]}
					open={selected}
					segmentEl={selectedSegment}
					segmentKey={segmentKey}
					unSelect={() => setSelected(false)}
				/>
			)}
		</g>
	);
};

export const Bars = <Datum = unknown,>({
	barValues,
	data,
	getBarValue,
	getSeriesValue,
	orientation,
	xScale,
	yScale,
	...rest
}: BarsProps<Datum>): ReactElement => {
	let BarStackComponent;
	if (orientation === 'vertical') {
		BarStackComponent = BarStack;
	} else {
		BarStackComponent = BarStackHorizontal;
	}

	return (
		<BarStackComponent<Datum, string>
			{...rest}
			data={data}
			value={getBarValue}
			x={getSeriesValue}
			xScale={xScale}
			y={getSeriesValue}
			yScale={yScale}
		>
			{barStacks => {
				const barStacksWithNonZeroBars = filterOutBarsWithValueOfZeroInBarStacks(barStacks, getBarValue);
				const barStacksByBar = getStacksByBar(barStacksWithNonZeroBars, getSeriesValue);

				return (
					<>
						{barStacksByBar.map((barStack, barStackIndex) => (
							<BarGroup
								barStack={barStack}
								barStackIndex={barStackIndex}
								barValues={barValues}
								data={data}
								getBarValue={getBarValue}
								getSeriesValue={getSeriesValue}
								key={`bar-animated-${barStack.index}`}
								orientation={orientation}
								xScale={xScale}
								yScale={yScale}
								{...rest}
							/>
						))}
					</>
				);
			}}
		</BarStackComponent>
	);
};
