import { Color } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { headlineSizes, textSizes, textWeights } from '../../definitions/json/encore-text.json';
import { fabricFontSize, fabricLineHeight } from '../../definitions/js/text';
import { px } from '../mixins/fabricMixins';

export const getEncoreTypography = (palette: PaletteOptions): TypographyOptions => {
	const gray = palette.gray as Color;
	const interFontFamily = 'Inter, sans-serif';
	const fieldsFontFamily = 'Fields, serif';

	return {
		fabricFontSize,
		fabricLineHeight,
		fontFamily: interFontFamily,
		fontSize: textSizes.medium.size,
		fontWeightLight: textWeights.regular,
		fontWeightRegular: textWeights.regular,
		fontWeightMedium: textWeights.medium,
		fontWeightSemibold: textWeights.semibold,
		fontWeightBold: textWeights.bold,
		fontWeightHeavy: textWeights.bold,
		h1: {
			fontFamily: fieldsFontFamily,
			fontSize: headlineSizes.h1.size,
			fontWeight: textWeights.bold,
			lineHeight: px(headlineSizes.h1.line),
			margin: '0',
		},
		h2: {
			fontFamily: fieldsFontFamily,
			fontSize: headlineSizes.h2.size,
			fontWeight: textWeights.semibold,
			lineHeight: px(headlineSizes.h2.line),
			margin: '0',
		},
		h3: {
			fontFamily: fieldsFontFamily,
			fontSize: headlineSizes.h3.size,
			fontWeight: textWeights.semibold,
			lineHeight: px(headlineSizes.h3.line),
			margin: '0',
		},
		h4: {
			fontFamily: fieldsFontFamily,
			fontSize: headlineSizes.h4.size,
			fontWeight: textWeights.semibold,
			lineHeight: px(headlineSizes.h4.line),
			margin: '0',
		},
		h5: {
			fontSize: headlineSizes.h5.size,
			fontWeight: textWeights.semibold,
			lineHeight: px(headlineSizes.h5.line),
			margin: '0',
		},
		h6: {
			fontSize: headlineSizes.h5.size,
			fontWeight: textWeights.semibold,
			lineHeight: px(headlineSizes.h5.line),
			margin: '0',
		},
		body1: {
			fontSize: textSizes.medium.size,
			lineHeight: px(textSizes.medium.line),
		},
		body2: {
			fontSize: textSizes.small.size,
			lineHeight: px(textSizes.small.line),
		},
		caption: {
			color: gray[500],
		},
		biggie: {
			fontSize: textSizes.large.size,
			lineHeight: px(textSizes.large.line),
		},
		large: {
			fontSize: textSizes.large.size,
			lineHeight: px(textSizes.large.line),
		},
		medium: {
			fontSize: textSizes.medium.size,
			lineHeight: px(textSizes.medium.line),
		},
		small: {
			fontSize: textSizes.small.size,
			lineHeight: px(textSizes.small.line),
		},
		xsmall: {
			fontSize: textSizes.xsmall.size,
			lineHeight: px(textSizes.xsmall.line),
		},
		teenie: {
			fontSize: textSizes.xsmall.size,
			lineHeight: px(textSizes.xsmall.line),
		},
	};
};
