import { makeStyles } from '~styles';

// This is needed to allow specifying styles via the `classes` prop in Jade or the `variant` prop
export const useAccordionStyles = makeStyles()(() => {
	return {
		body: {},
		description: {},
		disabled: {},
		expanded: {},
		expandIcon: {},
		header: {},
		headerContent: {},
		headerIcon: {},
		headerRight: {},
		hideDescriptionOnCollapse: {},
		root: {},
		title: {},
	};
});
