import { FormFactoryElement } from '../types/element.types';
import { isFormField } from '../utils';
import { Validation } from '../validation';

export function validationMessageMiddleware<T extends FormFactoryElement>(validation: Validation) {
	return (model: T) => {
		if (!isFormField(model)) {
			return model;
		}

		const { name } = model;

		let _model = model;

		const { errors, fields } = validation;
		const field = fields[name];

		if (field) {
			const elementErrors = errors[name];

			if (Array.isArray(elementErrors) && elementErrors.length) {
				_model = { ..._model, status: 'error', note: elementErrors[0] };
			}

			if (fields[name]?.required) {
				_model = { ..._model, required: true };
			}
		}

		return _model;
	};
}
