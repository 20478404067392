import { Box as MUIBox, BoxProps as MUIBoxProps } from '@mui/material';
import composeRefs from '@seznam/compose-react-refs';
import React, { ElementType, ReactElement, useContext, useMemo, useRef } from 'react';
import { DayContext } from './day-context';
import { useDayStyles } from './day.styles';
import { DayProps } from './day.types';

export function Day<C extends 'button' | 'div' = 'div'>({ biId, component, containerRef, variant }: DayProps<C>): ReactElement {
	const { day, modifiers, ...rest } = useContext(DayContext);
	const { classes, cx } = useDayStyles();
	const internalRef = useRef<HTMLElement>();

	const { disabled, selected, today } = modifiers;

	const boxProps = {
		...rest,
		'data-bi-id': biId,
		type: component === 'button' ? 'button' : undefined,
	} as MUIBoxProps<ElementType, { component?: ElementType }>;

	// MUIBox doesn't allow an undefined component prop
	if (component) {
		boxProps.component = component;
	}

	React.useEffect(() => {
		if (modifiers.focused) {
			internalRef?.current?.focus();
		}
	}, [internalRef, modifiers.focused]);

	const displayVariant = useMemo(() => {
		if (disabled || selected) return null;
		return variant;
	}, [disabled, selected, variant]);

	return (
		<MUIBox
			{...boxProps}
			className={cx(classes.root, {
				[classes.highlight]: displayVariant === 'highlight',
				[classes.prominent]: selected || displayVariant === 'prominent',
				[classes.today]: today,
				[classes.hoverable]: component === 'button',
				[classes.disabled]: disabled,
			})}
			ref={composeRefs(containerRef, internalRef)}
		/>
	);
}
