import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './DropdownPortal.css';
import {
	NavPromptMenuPosition,
	NavPromptMenuDropdownOption,
} from 'src/types/global';

interface DropdownPortalProps {
	options: NavPromptMenuDropdownOption[];
	position: NavPromptMenuPosition;
	onClose: () => void;
	onClickOption: (option: NavPromptMenuDropdownOption) => void;
}

const DropdownPortal = ({
	options,
	position,
	onClose,
	onClickOption,
}: DropdownPortalProps) => {
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				onClose();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef, onClose]);

	return ReactDOM.createPortal(
		<div
			ref={dropdownRef}
			className="dropdownMenu"
			style={{
				top: `${position.top + position.height}px`,
				left: `${position.left}px`,
			}}
		>
			{options.map((option) => (
				<div
					key={option.value}
					onClick={(event) => {
						event.stopPropagation();
						onClickOption(option);
					}}
					className="dropdownOption"
					style={option.style}
				>
					{option.icon}
					{option.label}
				</div>
			))}
		</div>,
		document.body,
	);
};

export default DropdownPortal;
