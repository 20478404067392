import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';
import { TimelineProps } from '../types';

export const useTimelineEventStyles = makeStyles<
	Pick<TimelineProps, 'eventGap'>,
	'icon' | 'iconWrapper' | 'invert' | 'number' | 'sizeSmall' | 'sizeMedium' | 'sizeTeenie' | 'contentPrimary' | 'numberTimeline'
>()(({ palette, typography, constructs, spacing }, { eventGap }, classes) => {
	const parsedGap = typeof eventGap === 'number' ? spacing(eventGap) : eventGap;
	const gapValue = `max(${spacing(2.5)}, ${parsedGap || spacing(4)})`;

	return {
		itemRoot: {
			boxSizing: 'border-box',
			display: 'flex',
			position: 'relative',
			zIndex: 0,
		},
		content: {
			boxSizing: 'border-box',
			display: 'flex',
			flex: ifFeature('encore', 1),
			flexDirection: 'column',
			gap: ifFeature('encore', spacing(0.25)),
			margin: ifFeature('encore', '0 0 0 16px', '-2px 0 0 17px'),
			paddingBottom: ifFeature('encore', gapValue, 26),
		},
		contentLast: {
			paddingBottom: 0,
		},
		contentPrimary: {
			boxSizing: 'border-box',
			fontSize: typography.medium.fontSize,
			lineHeight: typography.medium.lineHeight,
			color: ifFeature('encore', constructs.text.neutral.strong, palette.gray[1000]),
		},
		contentSecondary: {
			boxSizing: 'border-box',
			fontSize: ifFeature('encore', typography.teenie.fontSize, typography.small.fontSize),
			lineHeight: ifFeature('encore', typography.teenie.lineHeight, typography.small.lineHeight),
			color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[700]),
		},
		iconWrapper: {
			position: 'relative',
			paddingBottom: ifFeature('encore', gapValue),
			[`&:after`]: {
				boxSizing: 'border-box',
				content: '""',
				display: 'block',
				width: ifFeature('encore', '1px', '2px'),
				left: ifFeature('encore', 'calc(50%)', 'calc(50% - 1px)'), // half of width of circle minus half of width of gray line
				top: ifFeature('encore', 70, 0),
				bottom: 0,
				position: 'absolute',
				height: ifFeature('encore', 'calc(100% - 76px)', 'calc(100% + 3px)'),
				zIndex: -1,
				background: palette.gray[300],
			},
		},
		iconWrapperLast: {
			[`&:after`]: {
				content: 'none',
			},
			'&&&': ifFeature('encore', {
				paddingBottom: 0,
			}),
		},
		/* @startCleanup encore */
		icon: ifFeature(
			'encore',
			{},
			{
				boxSizing: 'border-box',
				width: 48,
				minWidth: 48,
				height: 48,
				border: '2px solid',
				borderColor: palette.gray[300],
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: palette.common.white,
				fill: palette.primary.main,
			}
		),
		/* @endCleanup encore */
		number: ifFeature(
			'encore',
			{
				height: '32px',
				width: '32px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '8px',
				color: constructs.text.primary.strong,
				backgroundColor: constructs.surface.neutral.xWeak,
				fontSize: typography.small.fontSize,
				lineHeight: typography.small.lineHeight,
				fontWeight: typography.fontWeightSemibold,
			},
			{
				color: palette.gray[600],
				fill: palette.gray[300],
				borderColor: palette.gray[300],
				fontSize: '22px',
				fontWeight: typography.fontWeightBold,
			}
		),
		line: {
			display: 'none',
		},
		invert: {
			/* @startCleanup encore */
			[`& .${classes.icon}`]: ifFeature(
				'encore',
				{},
				{
					backgroundColor: palette.primary.main,
					borderColor: palette.primary.main,
					fill: palette.common.white,
				}
			),
			/* @endCleanup encore */
			[`& .${classes.iconWrapper}:after`]: ifFeature(
				'encore',
				{
					backgroundColor: constructs.border.primary.medium,
				},
				{}
			),
			[`& .${classes.number}`]: ifFeature(
				'encore',
				{
					backgroundColor: constructs.surface.primary.strong,
					color: constructs.text.neutral.forcedWhite,
				},
				{
					backgroundColor: palette.gray[300],
					color: palette.gray[600],
					borderColor: palette.gray[300],
				}
			),
			[`& .${classes.contentPrimary}`]: ifFeature(
				'encore',
				{
					color: constructs.surface.primary.strong,
					fontWeight: typography.fontWeightSemibold,
				},
				{}
			),
		},
		colorSecondary: {
			[`& .${classes.icon}`]: {
				borderColor: palette.gray[700],
				fill: palette.gray[700],
			},
			[`&.${classes.invert} .${classes.icon}`]: {
				borderColor: palette.gray[700],
				backgroundColor: palette.gray[700],
				fill: palette.common.white,
			},
		},
		numberTimeline: {
			[`&& .${classes.iconWrapper}`]: ifFeature(
				'encore',
				{
					[`&:after`]: {
						top: 38,
						height: 'calc(100% - 44px)',
					},
				},
				{}
			),
		},
		sizeMedium: {
			[`& .${classes.icon}`]: {
				width: 36,
				minWidth: 36,
				height: 36,
			},
			[`& .${classes.iconWrapper}`]: ifFeature(
				'encore',
				{
					paddingBottom: spacing(8),
					'&:after': {
						top: 54,
						height: 'calc(100% - 60px)',
					},
				},
				{}
			),
			/* @startCleanup encore */
			[`& .${classes.number}`]: ifFeature(
				'encore',
				{},
				{
					fontSize: '20px',
				}
			),
			/* @endCleanup encore */
		},
		sizeSmall: {
			[`& .${classes.icon}`]: {
				width: 24,
				minWidth: 24,
				height: 24,
			},
			[`& .${classes.iconWrapper}`]: ifFeature(
				'encore',
				{
					paddingBottom: gapValue,
					'&:after': {
						top: 46,
						height: 'calc(100% - 52px)',
					},
				},
				{}
			),
			/* @startCleanup encore */
			[`& .${classes.number}`]: ifFeature(
				'encore',
				{},
				{
					fontSize: typography.teenie?.fontSize,
					fontWeight: typography.fontWeightBold,
				}
			),
			/* @endCleanup encore */
		},
		sizeTeenie: {
			[`&:not(:last-of-type) .${classes.iconWrapper}`]: ifFeature(
				'encore',
				{
					paddingBottom: gapValue,
					'&:after': {
						top: 22,
						height: 'calc(100% - 28px)',
					},
				},
				{
					'&:after': {
						top: 3,
					},
				}
			),
			[`& .${classes.icon}`]: {
				width: 14,
				minWidth: 14,
				height: 14,
				marginTop: 3,
			},
			/* @startCleanup encore */
			[`& .${classes.number}`]: ifFeature(
				'encore',
				{},
				{
					fontSize: '0', // Step is too small to display a font, so hide it
				}
			),
			/* @endCleanup encore */
		},
		timelineSizeLarge: {
			[`&.${classes.sizeMedium} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 ${spacing(1)} ${gapValue} ${spacing(1)}`, '0 6px'),
			},
			[`&.${classes.sizeSmall} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 ${spacing(1.5)} ${gapValue} ${spacing(1.5)}`, '0 12px'),
			},
			[`&.${classes.sizeTeenie} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 ${spacing(3)} 86px ${spacing(3)}`, '0 17px'),
			},
			[`&.${classes.numberTimeline} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', '0 16px'),
			},
		},
		timelineSizeMedium: {
			[`&.${classes.sizeSmall} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 10px ${gapValue} 10px`),
			},
			[`&.${classes.sizeMedium} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 6px ${gapValue} 6px`, '0 6px'),
			},
			[`&.${classes.sizeTeenie} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 22px 86px 22px`),
			},
			[`&.${classes.numberTimeline} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', '0 14px'),
			},
		},
		timelineSizeSmall: {
			[`&.${classes.sizeSmall} .${classes.iconWrapper}`]: {
				paddingBottom: ifFeature('encore', gapValue),
			},
			[`&.${classes.sizeTeenie} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', `0 12px ${gapValue} 12px`, '0 5px'),
			},
			[`&.${classes.numberTimeline} .${classes.iconWrapper}`]: {
				padding: ifFeature('encore', '0 4px'),
			},
		},
	};
});
