import { Stack } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { SectionHeader } from '~components/section-header';

export type SectionProps = { children?: ReactNode; icon?: ReactElement; label: string; subtitle?: string };

export function Section({ children, icon, label, subtitle }: SectionProps) {
	return (
		<Stack direction="column" spacing={2}>
			<SectionHeader icon={icon} label={label} subtitle={subtitle} />
			{children}
		</Stack>
	);
}
