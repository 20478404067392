import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ palette }) => {
	return {
		root: {
			display: 'flex',
			fill: palette.common.white,
		},
	};
});
