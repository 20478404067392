import React, { FC, PropsWithChildren, useMemo } from 'react';
import { getClassName, getSpacingStyles } from './util';
import { CardFlex, SpacingStyle } from '../../enums';

export interface ContentProps {
	/**
	 * Determines whether content can overflow. When `false`, sets `overflow: hidden`
	 */
	canOverflow?: boolean;

	/**
	 * Sets `flex-direction` for the card content.
	 *
	 * Possible values are `'row'` and `'column'`.
	 */
	flex?: CardFlex;

	/**
	 * Override the default padding.
	 * Expects an object containing one or more of the following keys:
	 * `bottom`, `left`, `right`, `top`
	 *
	 * Use the `CardContentSpacing` enum for each property's value.
	 * The available enum values are
	 * `CardContentSpacing.NONE` ('0')
	 *
	 * `CardContentSpacing.CUSTOM` ('custom')
	 *
	 * `CardContentSpacing.TEENIE` ('8px')
	 *
	 * `CardContentSpacing.SMALL` ('16px')
	 *
	 * `CardContentSpacing.MEDIUM` ('24px')
	 *
	 * `CardContentSpacing.LARGE` ('32px')
	 *
	 * `CardContentSpacing.BIGGIE` ('48px')
	 *
	 */
	spacingOverrides?: SpacingStyle;
}

/**
 * Sets standard padding around its content. Intended to be used inside a `Card` component.
 */

export const Content: FC<PropsWithChildren<ContentProps>> = ({ canOverflow = true, children, flex, spacingOverrides }) => {
	const { className, style } = useMemo(
		() => ({
			className: getClassName('fab-CardContent', { canOverflow, flex, spacingOverrides }),
			style: getSpacingStyles(spacingOverrides),
		}),
		[canOverflow, flex, spacingOverrides]
	);

	return (
		<div className={className} style={style}>
			{children}
		</div>
	);
};

Content.displayName = 'Content';

export default Content;
