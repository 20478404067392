import { ValueFormatterFunc } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { DatePickerUtils } from '~components/date-picker';
import { getFormattedValue } from '../../date-picker/components/input-date/input-date.domain';

export const createDefaultDateValueFormatter = (datePickerUtils: DatePickerUtils): ValueFormatterFunc => {
	return ({ value }) => {
		const date = DateTime.fromISO(value);

		if (!date.isValid) {
			return value as string;
		}

		return getFormattedValue(datePickerUtils, date) ?? (value as string);
	};
};
