import React from 'react';
import { IconV2 } from '~components/icon-v2';
import { Button, TextButton } from '~components/button';
import { StandardModal } from '~components/standard-modal';
import { useDeletionModalStyles } from './deletion-modal.styles';
import { DataGridDeletionModalProps } from './deletion-modal.types';

// @startCleanup encore
import { TrashCan49x54 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

export function DeletionModal({ biId, isOpen, onCancel, onSubmit }: DataGridDeletionModalProps) {
	const { classes } = useDeletionModalStyles();

	return (
		<StandardModal data-bi-id={biId} isOpen={isOpen} onRequestClose={onCancel}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={ifFeature(
							'encore',
							[
								<TextButton
									data-bi-id={biId ? `${biId}-cancel-button` : undefined}
									key="cancel-button"
									onClick={onCancel}
								>
									Cancel
								</TextButton>,
								<Button data-bi-id={biId ? `${biId}-submit-button` : undefined} key="submit-button" onClick={onSubmit}>
									{window.jQuery ? $.__('Delete Row') : 'Delete Row'}
								</Button>,
							],
							[
								<Button data-bi-id={biId ? `${biId}-submit-button` : undefined} key="submit-button" onClick={onSubmit}>
									{window.jQuery ? $.__('Delete Row') : 'Delete Row'}
								</Button>,
								<TextButton
									data-bi-id={biId ? `${biId}-cancel-button` : undefined}
									key="cancel-button"
									onClick={onCancel}
								>
									Cancel
								</TextButton>,
							]
						)}
					/>
				}
				renderHeader={<StandardModal.Header title={window.jQuery ? $.__('Just Checking...') : 'Just Checking...'} />}
			>
				<StandardModal.Constraint
					spacingOverrides={{
						top: StandardModal.Constraint.Spacing.LARGE,
						bottom: StandardModal.Constraint.Spacing.LARGE,
					}}
				>
					<StandardModal.HeroHeadline
						icon={ifFeature('encore', 'trash-can-solid', <TrashCan49x54 className={classes.icon} />)}
						iconColor="error-strong"
						text={
							window.jQuery
								? $.__('Are you sure you want to delete this row?')
								: 'Are you sure you want to delete this row?'
						}
					>
						<p className={classes.subtitle}>
							{window.jQuery
								? $.__("Once deleted, we can't bring it back. However, you can always start over!")
								: "Once deleted, we can't bring it back. However, you can always start over!"}
						</p>
					</StandardModal.HeroHeadline>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
