import { Layout } from '../types';

export function fillHolesInLayout(layout: Layout): Layout {
	const longestLength = Math.max(...layout.map(arr => arr.length));

	layout = layout.map((row, i) => {
		const currentLength = row.length;
		if (currentLength < longestLength) {
			row.length = longestLength;
		}
		return [...row];
	});

	layout = layout.map(row => {
		return row.map(layoutKey => {
			if (layoutKey === undefined) {
				return `.`;
			}
			return layoutKey;
		});
	});

	return layout;
}
