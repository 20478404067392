import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ borderRadiuses }) => {
	return {
		footer: {
			borderBottomLeftRadius: ifFeature('encore', borderRadiuses['300'], undefined),
			borderBottomRightRadius: ifFeature('encore', borderRadiuses['300'], undefined),
			overflow: 'hidden',
		},
	};
});
