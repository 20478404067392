import { TypographyProps } from '~components/typography';
import composeRefs from '@seznam/compose-react-refs';
import React, { forwardRef, Ref } from 'react';
import { sideTabPaddingX } from '../constants';
import { disableKeyboardFocus } from '../utils/disable-keyboard-focus';
import { SideTabPanelHeader } from './side-tab-panel-header';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The title that appears atop the `SideTabList`.
 *
 * You likely won't need to use this directly as it's included in `SideTabList`.
 *
 * The only difference between this and `SideTabPanelHeader` is some padding to
 * align with the tabs.
 */
export const SideTabListHeader = forwardRef(function SideTabListHeader(props: TypographyProps, ref: Ref<HTMLSpanElement>) {
	return <SideTabPanelHeader px={`var(${sideTabPaddingX})`} ref={composeRefs(ref, disableKeyboardFocus)} {...props} />;
});
