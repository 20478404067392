import { first } from 'lodash';

import { slideHorizontally, shouldSlideHorizontally } from './horizontally';
import { slideVertically, shouldSlideVertically } from './vertically';
import { offsetsOverlap } from '../../offsets';

export function slide({ collisions, offsets, locations }) {
	const collision = first(collisions);

	if (collisions.length === 1) {
		let { vessel } = offsets;

		if (shouldSlideHorizontally(collision, offsets, locations)) {
			vessel = slideHorizontally(collision, offsets);
		} else if (shouldSlideVertically(collision, offsets, locations)) {
			vessel = slideVertically(collision, offsets);
		}

		const newOffsets = { ...offsets, vessel };
		if (!offsetsOverlap(newOffsets.anchor, newOffsets.vessel)) {
			offsets = newOffsets;
		}
	}

	return {
		offsets,
		locations,
	};
}
