import React, { ReactElement } from 'react';
import { COMPONENTS } from './constants';
import { useBaseHeadlineStyles } from './base-headline.styles';
import { BaseHeadlineProps } from './base-headline.types';
import { IconV2, type IconV2Size } from '~components/icon-v2';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getEncoreValue } from '~utils';
import { Link } from '~components/link';

/**
 * Internal Fabric use only!
 */
export function BaseHeadline(props: BaseHeadlineProps): ReactElement {
	const { ariaLabel, children, color = 'primary', component, icon, id, justify, size, url, classes: propClasses } = props;
	const Component = component ?? COMPONENTS[size];
	const shouldFlex = !!(justify || icon);
	const { classes } = useBaseHeadlineStyles({ color, justify, shouldFlex, size, url }, { props: { classes: propClasses } });

	const innerContent = (
		<>
			{icon && (
				<span className={classes.icon}>
					{ifFeature(
						'encore',
						typeof icon === 'string' ? (
							<IconV2
								name={icon}
								size={getEncoreValue<typeof size, Record<typeof size, IconV2Size>>(size, {
									'extra-small': 20,
									small: 20,
									medium: 24,
									large: 32,
									'extra-large': 48,
								})}
							/>
						) : null,
						icon
					)}
				</span>
			)}
			{/* if we need a flex layout, wrap `children` in `span` to avoid layout issues with multiple children */}
			{ifFeature('encore', shouldFlex ? <span>{children}</span> : children, children)}
		</>
	);

	return (
		<Component className={classes.root} id={id}>
			{url
				? ifFeature(
						'encore',
						<Link ariaLabel={ariaLabel} href={url}>
							<span className={classes.link}>{innerContent}</span>
						</Link>,
						<a aria-label={ariaLabel} className={classes.link} href={url}>
							{innerContent}
						</a>
					)
				: innerContent}
		</Component>
	);
}
