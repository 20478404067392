import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const sizeStyles = ({ typography, spacing }: Theme): Record<string, any> => {
	return {
		sizeTeenie: {
			padding: ifFeature('encore', '0 12px', '0 11px'),
			height: ifFeature('encore', '32px', '28px'),
			fontSize: ifFeature('encore', '13px', typography.fabricFontSize?.('teenie')),
			'&$iconOnly': {
				padding: ifFeature('encore', '8px 12px', '0 7px'),
			},
			'&:not($text)': {
				fontSize: ifFeature('encore', '13px', typography.fabricFontSize?.('small')),
			},
			'&$text': {
				padding: 0,
				height: ifFeature('encore', '27px'),
				lineHeight: ifFeature('encore', typography.teenie?.lineHeight),
			},
		},
		sizeSmall: {
			padding: ifFeature('encore', '0 12px', '0 11px'),
			height: '32px',
			fontSize: ifFeature('encore', '13px', typography.fabricFontSize?.('small')),
			'&$text': {
				padding: 0,
				height: ifFeature('encore', '27px'),
				lineHeight: ifFeature('encore', typography.teenie?.lineHeight),
			},
		},
		sizeLarge: {
			height: ifFeature('encore', '48px', '36px'),
			padding: ifFeature('encore', '0 24px', undefined),
			fontSize: ifFeature('encore', '18px', typography.fabricFontSize?.('large')),
			'&$text': {
				padding: 0,
				height: ifFeature('encore', '34px'),
				lineHeight: ifFeature('encore', typography.h6?.lineHeight),
			},
			'& $startIcon': {
				marginRight: ifFeature('encore', spacing(1.5)),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', spacing(1.5)),
			},
		},
		sizeBiggie: {
			padding: ifFeature('encore', '0 24px', '0 23px'),
			height: ifFeature('encore', '48px', '42px'),
			fontSize: ifFeature('encore', '18px', typography.fabricFontSize?.('biggie')),
			'&$iconOnly': {
				padding: '0 15px',
			},
			'&$text': {
				padding: 0,
				height: ifFeature('encore', '34px'),
				lineHeight: ifFeature('encore', typography.h5?.lineHeight),
			},
		},
		sizeHuge: {
			'&:not($text)': {
				fontSize: typography.fabricFontSize?.('small'),
				height: '64px',
				padding: '0 23px',
				'& $label': {
					flexDirection: 'column',
					justifyContent: 'center',
					lineHeight: 1,
				},
				'& $startIcon, & $endIcon': {
					display: 'flex',
					height: '26px',
					margin: '0 0 4px',
				},
			},
			'&$text': {
				height: '34px', // Use default medium height
				padding: 0,
			},
		},
	};
};
