import React, { useContext, useEffect, useState } from 'react';
import { GlobalNavigationLinkProps } from '../global-navigation.types';
import { GlobalNavigationContext } from '../global-navigation-context';
import { useStyles } from './link.styles';
import { animationDuration } from '../global-navigation.constants';
import { BodyText } from '~components/body-text';
import { Divider } from '~components/divider';
import { LinkUnstyled } from '~components/link/unstyled';

export function Link({
	active,
	activeIcon,
	ariaLabel,
	biId,
	divider = false,
	external = false,
	href,
	icon,
	label,
	note,
	containerRef,
}: GlobalNavigationLinkProps) {
	const { open } = useContext(GlobalNavigationContext);
	const { classes, cx } = useStyles();
	const [hideLabel, setHideLabel] = useState(open);
	const [openLabel, setOpenLabel] = useState(open);
	let ariaLabelText = ariaLabel || '';
	if (typeof label === 'string') {
		/**
		 * Normally a link that contains text shouldn't use a separate aria-label, but the links in
		 * this component are displayed without text when the nav is closed. Additionally, it's
		 * possible for the label prop value to be a ReactNode other than a plain text string, and
		 * the ariaLabel prop accommodates such scenarios.
		 */
		ariaLabelText = label;
	}

	/**
	 * The label shouldn't display when opacity is 0 to avoid accessibility errors
	 * In order for the label's opacity to transition,
	 * display: none needs to be removed before the `open` class is added
	 */
	useEffect(() => {
		if (open) {
			setHideLabel(false);
			setTimeout(() => {
				setOpenLabel(true);
			}, 5);
		} else {
			setOpenLabel(false);
			setTimeout(() => {
				setHideLabel(true);
			}, animationDuration);
		}
	}, [open]);

	return (
		<li className={classes.root}>
			{divider ? <Divider color="neutral-extra-weak" marginBottom={2} /> : null}
			<LinkUnstyled
				aria-label={hideLabel ? ariaLabelText : undefined}
				biId={biId}
				className={cx(classes.link, { [classes.active]: active, [classes.open]: openLabel })}
				containerRef={containerRef}
				href={href}
				target={external ? '_blank' : undefined}
			>
				<span className={classes.icon}>{active ? activeIcon : icon}</span>
				<div className={cx(classes.label, { [classes.hideLabel]: hideLabel })}>
					{label}
					{note ? (
						<BodyText color="neutral-weak" size="extra-extra-small" weight="regular">
							{note}
						</BodyText>
					) : null}
				</div>
			</LinkUnstyled>
		</li>
	);
}
