import { makeStyles } from '~styles';

export const useCalendarViewStyles = makeStyles()(({ borderRadiuses, constructs, mixins }) => {
	return {
		root: {
			border: `1px solid ${constructs.border.neutral.xWeak}`,
			borderRadius: borderRadiuses[400],
			boxShadow: `${mixins.boxShadow('300', constructs.surface.neutral.xxStrong)}`,
			width: 'fit-content',
		},
	};
});
