import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles, dyeColors, ModeOptions } from '~styles';
import { getCheckboxToLabelMargin, getPixelsFromSize, getSelectedIconScaleFromSize } from './checkbox.domain';
import { CheckboxStyleProps } from './types';

const boxShadowInset = 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.15)';

export const useStyles = makeStyles<CheckboxStyleProps, 'error' | 'warning' | 'root'>()((theme, props, classes) => {
	const { constructs, palette, mixins, typography, mode } = theme;
	const encoreFocusInsetShadow = `inset 1px 1px 0px 1px ${mixins.alpha(palette.gray[900], 0.05)}`;
	const { checked, indeterminate, disabled, status, size } = props;

	const getLabelColor = () => {
		// Calculates the label color for the label class based on the current state of the checkbox
		if (mode === ModeOptions.Dye) {
			return dyeColors.main;
		}
		if (disabled) {
			return ifFeature('encore', constructs.text.neutral.weak, palette.gray[500]);
		}
		if (status === 'error') {
			return palette.error.main;
		}
		if (status === 'warning') {
			return palette.warning.main;
		}
		return ifFeature('encore', constructs.text.neutral.strong, palette.gray[1000]);
	};

	const getSelectedBoxBackgroundColor = () => {
		// Calculates the background color for the selectedBox class based on the current state of the checkbox
		if (mode === ModeOptions.Dye) {
			return dyeColors.main;
		}
		if (status === 'error') {
			return palette.error.main;
		}
		if (status === 'warning') {
			return palette.warning.main;
		}
		return palette.primary.main;
	};

	const getSelectedBorderColor = () => {
		// Calculates the border color for the selectedBox class based on the current state of the checkbox
		let color = palette.primary.main;
		if (status === 'warning') {
			color = palette.warning.main;
		}
		if (status === 'error') {
			color = palette.error.main;
		}
		return `1px solid ${color}`;
	};

	const getSelectedIconFillColor = () => {
		// Calculates the fill color for the selectedIcon class based on the current state of the checkbox
		if (indeterminate) {
			if (disabled) {
				return ifFeature('encore', palette.primary.contrastText, palette.gray[500]);
			}
			if (status === 'error') {
				return ifFeature('encore', palette.primary.contrastText, palette.error.main);
			}
			if (status === 'warning') {
				return ifFeature('encore', palette.primary.contrastText, palette.warning.main);
			}
			return ifFeature('encore', palette.primary.contrastText, palette.primary.main);
		}
		return palette.primary.contrastText;
	};

	const getStatusIconFillColor = () => {
		// Calculates the fill color for the statusIcon class based on the current state of the checkbox
		if (disabled) {
			return palette.gray[500];
		}
		if (status === 'error') {
			return palette.error.main;
		}
		if (status === 'warning') {
			return palette.warning.main;
		}
		return palette.gray[1000];
	};

	return {
		asterisk: {
			color: disabled ? palette.gray[600] : palette.primary.main,
			marginLeft: 4,
		},
		error: {
			color: theme.palette.error.main,
		},
		label: {
			alignItems: 'center',
			display: 'flex',
			marginLeft: getCheckboxToLabelMargin(size),
		},
		labelContainer: {
			display: 'flex',
		},
		labelContent: {
			color: getLabelColor(),
			fontSize: typography.fabricFontSize(size),
			fontStyle: status === 'pending' ? 'italic' : 'normal',
			fontWeight: checked || indeterminate ? typography.fontWeightSemibold : typography.fontWeightRegular,
			lineHeight: typography.fabricLineHeight(size),
		},
		labelRoot: {
			marginLeft: ifFeature('encore', 0),
			marginRight: ifFeature('encore', 0),
		},
		note: {
			color: palette.gray[700],
			fontSize: typography.fabricFontSize('teenie'),
			lineHeight: typography.fabricLineHeight('teenie'),
			marginLeft: getPixelsFromSize(size) + getCheckboxToLabelMargin(size),
		},
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			// MUI should use the base color we are using
			'& .MuiCheckbox-root': {
				alignSelf: ifFeature('encore', 'start'),
				backgroundColor: ifFeature('encore', 'unset'),
				color: 'inherit',
				height: ifFeature('encore', 'auto'),
				padding: ifFeature('encore', 0),
				position: ifFeature('encore', 'relative'),
				top: ifFeature('encore', size === 'small' || size === 'medium' ? '3px' : '2px'),
			},
			// MUI provides a default asterisk that we don't want to show
			'& .MuiFormControlLabel-asterisk': {
				display: 'none',
			},
		},
		selectedBox: {
			alignItems: 'center',
			backgroundColor: getSelectedBoxBackgroundColor(),
			border: getSelectedBorderColor(),
			borderRadius: 4,
			boxShadow: 'none',
			boxSizing: 'border-box',
			display: 'flex',
			height: getPixelsFromSize(size),
			justifyContent: 'center',
			opacity: 0,
			position: 'absolute',
			transform: 'scale(0)',
			transition: 'transform 200ms ease, opacity 200ms ease, border-radius 200ms ease',
			width: getPixelsFromSize(size),
			'.MuiCheckbox-root.Mui-checked &': {
				borderRadius: ifFeature('encore', 4, 2),
				boxShadow: ifFeature(
					'encore',
					`1px 1px 0px 0px ${mixins.alpha(palette.gray[900], 0.06)}`,
					'0 1px 0 0 rgba(0, 0, 0, 0.05)'
				),
				opacity: 1,
				transform: 'scale(1)',
			},
			'.MuiCheckbox-root.MuiCheckbox-indeterminate &': {
				opacity: ifFeature('encore', 1, 0),
				transform: ifFeature('encore', 'scale(1)', 'scale(0)'),
			},
			'.MuiCheckbox-root.Mui-disabled &': {
				backgroundColor: ifFeature('encore', palette.gray[400], palette.gray[500]),
				border: `1px solid ${ifFeature('encore', palette.gray[400], palette.gray[500])}`,
				boxShadow: 'none',
			},
			'.MuiCheckbox-root.Mui-disabled.Mui-checked &': {
				boxShadow: ifFeature('encore', `1px 1px 0px 0px ${mixins.alpha(palette.gray[900], 0.06)}`),
			},
			[`.${classes.root}:focus-within &`]: {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.primary.lightest}`),
			},
			[`.${classes.warning}:focus-within &`]: {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.warning.light}`),
			},
			[`.${classes.error}:focus-within &`]: {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.error.light}`),
			},
		},
		selectedIcon: {
			display: 'flex',
			fill: getSelectedIconFillColor(),
			filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))',
			position: 'absolute',
			transform: checked || indeterminate ? `scale(${getSelectedIconScaleFromSize(size)})` : 'scale(0)',
			transition: 'transform 100ms ease 100ms',
		},
		statusIcon: {
			display: 'flex',
			fill: getStatusIconFillColor(),
			marginRight: 6,
		},
		unselectedBox: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', palette.gray[100], palette.common.white),
			border: `1px solid ${ifFeature('encore', palette.gray[400], palette.gray[500])}`,
			borderRadius: ifFeature('encore', 4, 2),
			boxShadow: ifFeature('encore', `inset 1px 1px 0px 1px ${mixins.alpha(palette.gray[900], 0.05)}`, boxShadowInset),
			boxSizing: 'border-box',
			display: 'flex',
			height: getPixelsFromSize(size),
			justifyContent: 'center',
			position: 'relative',
			transition: 'border-radius 200ms ease',
			width: getPixelsFromSize(size),
			'.MuiCheckbox-root.Mui-disabled &': {
				border: `1px solid ${ifFeature('encore', palette.gray[200], palette.gray[300])}`,
				boxShadow: 'none',
			},
			[`.${classes.warning} &, .${classes.error} &, .${classes.root}:focus-within &`]: {
				borderColor: 'currentColor',
			},
			[`.${classes.root}:focus-within &`]: {
				border: `1px solid ${ifFeature('encore', palette.gray[400])}`,
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.primary.lightest}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`
				),
			},
			[`.${classes.warning}:focus-within &`]: {
				border: ifFeature('encore', `1px solid ${palette.warning.main}`),
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.warning.light}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.warning.light, 0.15)}`
				),
			},
			[`.${classes.error}:focus-within &`]: {
				border: ifFeature('encore', `1px solid ${palette.error.main}`),
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.error.light}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.error.light, 0.15)}`
				),
			},
		},
		warning: {
			color: theme.palette.warning.main,
		},
	};
});
