import { Directions } from '../enums';
import { OPPOSITE_DIRECTIONS } from '../constants';

const LOCATION_TO_ALIGN = {
	bottom: 'end',
	center: 'center',
	left: 'start',
	middle: 'center',
	top: 'start',
	right: 'end',
};

export function isLeftOrRightLocation(location) {
	return location.horizontal === Directions.LEFT || location.horizontal === Directions.RIGHT;
}

export function isTopOrBottomLocation(location) {
	return location.vertical === Directions.TOP || location.vertical === Directions.BOTTOM;
}

export function toPlacement(locations = {}) {
	const { anchor, vessel } = locations;

	let placement = null;

	if (anchor.horizontal === OPPOSITE_DIRECTIONS[vessel.horizontal]) {
		placement = {
			align: LOCATION_TO_ALIGN[vessel.vertical],
			side: anchor.horizontal,
		};
	}

	if (anchor.vertical === OPPOSITE_DIRECTIONS[vessel.vertical]) {
		placement = {
			align: LOCATION_TO_ALIGN[vessel.horizontal],
			side: anchor.vertical,
		};
	}

	if (placement === null || !placement.align || !placement.side) {
		throw new Error('An invalid location was supplied.');
	}

	return placement;
}
