import React, { cloneElement } from 'react';
import { ActionBannerProps } from './action-banner.types';
import { useStyles } from './action-banner.styles';
import { Title } from './title';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function ActionBanner({ actions, ariaLabel, biId, children, image }: ActionBannerProps) {
	const { classes } = useStyles();

	return (
		<div aria-label={ariaLabel} className={classes.root} data-bi-id={biId} role="region">
			{image ? <div className={classes.image}>{image}</div> : null}
			{children}
			{actions ? (
				<div className={classes.actions}>
					{actions.map(action =>
						cloneElement(action, {
							dark: true,
							size: ifFeature('encore', 'medium', 'large'),
						})
					)}
				</div>
			) : null}
		</div>
	);
}

ActionBanner.Title = Title;
