// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './styles';
import { getFilteredList, getAvailableFilterSelected, mergeSelectedItemLists, searchByTitle } from '../../etc';
import { useMultiSelectControls } from '../../hooks';
import { ListItem, FilterItem, MultiSelectTransferListProps } from '../../types';
import { TransferList } from '../transfer-list';
import { FilterSelect } from '../filter-select';
import { SearchBar } from '../search-bar';
import { ButtonControls } from '../button-controls';
import { isEqual } from 'lodash';

export function MultiSelectTransferList(props: MultiSelectTransferListProps): ReactElement {
	const {
		biId,
		LeftListProps = {},
		RightListProps = {},
		SearchProps,
		TransferListProps = {},
		handleFilterItems,
		FilterProps,
		onChange,
		sortList = array => array,
		emptyFilterText,
	} = props;

	const { classes } = useStyles();

	const { listItems: leftListItemsProp = [], ...restLeftListProps } = LeftListProps;

	const { listItems: rightListItemsProp = [], ...restRightListProps } = RightListProps;

	const transferListStyles = { transferListCenterColumn: classes.transferListCenterColumn, ...TransferListProps?.classes };

	// TODO: Remove this. It conflates props with state and controlled with uncontrolled.
	const [leftItems, setLeftItems] = useState<Array<ListItem>>([...leftListItemsProp]);
	const [rightItems, setRightItems] = useState<Array<ListItem>>([...rightListItemsProp]);

	const [searchTerm, setSearchTerm] = useState('');
	const [listFilters, setListFilters] = useState<FilterItem[]>([]);

	const {
		availableOptions,
		selectedOptions,
		onClickAddAll,
		onClickAddHighlighted,
		onClickRemoveHighlighted,
		onClickRemoveAll,
		useOnAvailableSelect,
		useOnSelectedSelect,
	} = useMultiSelectControls({
		availableOptions: leftItems,
		selectedOptions: rightItems,
		sortList,
		setAvailableOptions: setLeftItems,
		setSelectedOptions: setRightItems,
	});

	function renderButtonControls() {
		return (
			<ButtonControls
				biId={biId}
				onClickAddAll={onClickAddAll}
				onClickAddHighlighted={onClickAddHighlighted}
				onClickRemoveAll={onClickRemoveAll}
				onClickRemoveHighlighted={onClickRemoveHighlighted}
			/>
		);
	}

	const filterItems = (filters: FilterItem[], searchTerm: string) => {
		let filteredAvailableItems: ListItem[];
		if (typeof handleFilterItems === 'function') {
			filteredAvailableItems = handleFilterItems(filters, searchTerm);
		} else {
			const selectedMerge = mergeSelectedItemLists(leftListItemsProp, availableOptions);
			const selectedAvailable = getAvailableFilterSelected(selectedOptions, selectedMerge);
			const filteredList = getFilteredList(filters, selectedAvailable);
			filteredAvailableItems = searchByTitle(searchTerm, filteredList);
		}

		setLeftItems(filteredAvailableItems);
		setListFilters(filters);
		setSearchTerm(searchTerm);
	};

	const previouslySelectedOptionsRef = useRef(selectedOptions);

	useEffect(() => {
		if (typeof onChange !== 'function') {
			return;
		}
		filterItems(listFilters, searchTerm);
		// Temporary solution to prevent onChange from being called every time RightListProps.listItems changes.
		// Ultimately, we need to rework this component to better handle controlled and uncontrolled scenarios.
		if (!isEqual(selectedOptions, previouslySelectedOptionsRef.current)) {
			previouslySelectedOptionsRef.current = selectedOptions;
			onChange({ selected: selectedOptions });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions, onChange]);

	// TODO: Remove this. It conflates props with state and controlled with uncontrolled.
	useEffect(() => {
		if (RightListProps.listItems) {
			setRightItems(RightListProps.listItems);
		}
	}, [RightListProps.listItems]);

	return (
		<TransferList
			{...TransferListProps}
			classes={ifFeature('encore', undefined, transferListStyles)}
			leftListItems={availableOptions}
			LeftTransferListBaseProps={{
				...restLeftListProps,
				blankState:
					restLeftListProps.blankState ??
					(() => {
						return availableOptions.length === 0 && (searchTerm.trim().length > 0 || listFilters.length > 0) ? (
							<div className={classes.noMatchFilter}>{emptyFilterText}</div>
						) : undefined;
					}),
				listHeaderChildren:
					restLeftListProps.listHeaderChildren ??
					(() => {
						return (
							<>
								{FilterProps && (
									<FilterSelect
										{...FilterProps}
										onChange={changeData => {
											filterItems(changeData.filters || [], searchTerm);
										}}
									/>
								)}
								{SearchProps && (
									<SearchBar
										{...SearchProps}
										onChange={term => {
											filterItems(listFilters, term);
										}}
									/>
								)}
							</>
						);
					}),
			}}
			onLeftRowSelect={useOnAvailableSelect}
			onRightRowSelect={useOnSelectedSelect}
			renderButtonControls={renderButtonControls}
			rightListItems={selectedOptions}
			RightTransferListBaseProps={{ ...restRightListProps }}
		/>
	);
}
