import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(theme => {
	const { constructs, mixins, mode, palette, borderRadiuses } = theme;

	return {
		hidden: {
			visibility: 'hidden',
		},
		picker: {
			backgroundColor:
				mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.surface.neutral.xxWeak, palette.gray[100]),
			borderRadius: ifFeature('encore', borderRadiuses[200]),
			overflow: 'hidden',
			position: 'relative',
			'& .MuiCalendarPicker-root > div > div > div:first-of-type': {
				marginBottom: 0,
				padding: 0,
			},

			// @startCleanup encore
			width: ifFeature('encore', undefined, 308),
			// @endCleanup encore
		},
		root: {
			background: ifFeature('encore', constructs.surface.neutral.forcedWhite),
			borderBottom: mixins.border(1, 'transparent'),
			borderRadius: ifFeature('encore', borderRadiuses[200]),
			margin: 0,
			padding: ifFeature('encore', '4px'),
			width: ifFeature('encore', '320px', '308px'),
		},
	};
});
