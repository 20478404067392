import React, { FC, isValidElement, PropsWithChildren } from 'react';
import { ListItemButton } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { Context } from './context';
import { Text } from './text';
import { MenuItemProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<MenuItemProps>()(
	({ constructs, palette, spacing, mode }, { alignItems = 'start' }, classes: Record<string, string>) => ({
		root: {
			alignItems,
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: ifFeature('encore', 'row'),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', `10px ${spacing(2)}`, '5px 10px'),
			textDecoration: 'none',
			transition: 'none',
			userSelect: 'none',

			'&:hover': {
				backgroundColor: 'unset',
			},

			[`&.${classes.selected}`]: {
				backgroundColor: ifFeature('encore', constructs.surface.neutral.xWeak, palette.primary.main),

				'&:hover': {
					backgroundColor:
						mode === ModeOptions.Dye
							? dyeColors.lighter
							: ifFeature('encore', constructs.surface.neutral.xWeak, palette.primary.main),
				},
			},
		},
		disabled: {
			backgroundColor: 'unset',
			pointerEvents: 'none',
		},
		mediumSize: {
			minHeight: 32,
		},
		selected: {},
		smallSize: {
			minHeight: 30,
		},
	})
);

export const MenuItem: FC<PropsWithChildren<MenuItemProps>> = props => {
	const { active, alignItems, children, disabled, muted, size, ...rest } = props;
	const { classes, cx } = useStyles(props);

	return (
		<Context.Provider value={{ active, disabled, muted, size }}>
			<ListItemButton
				classes={{
					root: classes.root,
					disabled: classes.disabled,
					selected: classes.selected,
				}}
				className={cx({
					[classes.mediumSize]: !(size === 'small' || size === 'teenie'),
					[classes.smallSize]: size === 'small' || size === 'teenie',
				})}
				disabled={disabled}
				selected={active}
				{...rest}
			>
				{/* @startCleanup encore */}
				{/* Previously, we used isValidElement, but this doesn't work for multiple children, which are passed as an array that returns false for isValidElement. */}
				{/* Changed to check if children is a simple text type, since that seems to be the original intention. */}
				{/* @endCleanup encore */}
				{ifFeature(
					'encore',
					['string', 'number'].includes(typeof children) ? <Text primary={children} /> : children,
					isValidElement(children) ? children : <Text primary={children} />
				)}
			</ListItemButton>
		</Context.Provider>
	);
};
