import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';
import { DialogProps } from './dialog.types';

type DialogStyleProps = Required<Pick<DialogProps, 'height' | 'offsetRight' | 'offsetTop' | 'open' | 'width'>> & {
	zIndex?: number;
};

export const useStyles = makeStyles<DialogStyleProps>()((theme, props) => {
	const { borderRadiuses, constructs, mixins, palette, spacing } = theme;
	const { height, offsetRight, offsetTop, open, width, zIndex } = props;

	return {
		root: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.inverted, palette.background.default),
			border: 'none',
			borderRadius: ifFeature('encore', borderRadiuses['400'], 'inherit'),
			boxShadow: ifFeature('encore', mixins.boxShadow('700', constructs.surface.neutral.xxStrong), '0px 8px 16px 0px #00000026'),
			display: open ? 'flex' : 'none',
			flexDirection: 'column',
			height,
			marginRight: offsetRight,
			padding: ifFeature('encore', spacing(0.5), 0),
			position: 'fixed',
			textAlign: 'left',
			top: offsetTop,
			width,
			zIndex,
		},
	};
});
