import { IDataHookRecord } from '@flatfile/adapter/build/main/interfaces';

export const normalizeColumnValidationResults = (results: Record<number, string>, startIndex: number): [IDataHookRecord, number][] => {
	if (!results || Object.values(results).length === 0) {
		return [];
	}

	return Object.entries(results).map(([index, message]) => {
		return [
			{
				info: [{ message, level: 'error' }],
			},
			Number(index) + startIndex,
		];
	});
};
