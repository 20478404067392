import React from 'react';
import Pagination from './pagination';
import Infinite from './infinite';
import { extendURL } from '@bamboohr/utils/lib/url';
import { ajax } from '@bamboohr/utils/lib/ajax';
import { noop } from 'lodash';
import { ProgressiveLoaderProps, ProgresiveLoaderState, AjaxFetchMoreResponse } from './types';

const BASE_PARAMS = {
	startsAt: 0, // starts at first item
	limit: 25, // give me 25 results
};

export class ProgressiveLoader extends React.Component<ProgressiveLoaderProps, ProgresiveLoaderState> {
	constructor(props: ProgressiveLoaderProps) {
		super(props);
		const { totalItemsCount } = props;

		this.state = {
			totalItemsCount: totalItemsCount || 1,
		};
	}

	static displayName = 'ProgressiveLoader';

	fetchMore = (paramsObject = BASE_PARAMS): Promise<unknown> => {
		const { endpoint, onFetch } = this.props;
		const { totalItemsCount } = this.state;
		const url = extendURL(paramsObject, endpoint);
		return ajax
			.get(url)
			.then(({ data }: AjaxFetchMoreResponse) => {
				if (onFetch) {
					onFetch(data);
				}
				if (data.total !== totalItemsCount) {
					this.setState({
						totalItemsCount: data.total,
					});
				}
				return data;
			})
			.catch(err => {
				console.log(err);
			});
	};

	updateTotal = (propTotal: number): void => {
		this.setState({
			totalItemsCount: propTotal,
		});
	};

	componentDidMount(): void {
		const { preload, endpoint } = this.props;
		if (preload && endpoint) {
			this.fetchMore(preload).then(noop, noop);
		}
	}

	componentDidUpdate(): void {
		const { totalItemsCount: propTotal } = this.props;
		const { totalItemsCount: stateTotal } = this.state;
		if (propTotal && propTotal !== stateTotal) {
			this.updateTotal(propTotal);
		}
	}

	render(): JSX.Element {
		const { children, innerRef, pageSize, pagination } = this.props;
		const { totalItemsCount } = this.state;
		return pagination ? (
			<Pagination {...this.props} fetchMore={this.fetchMore} ref={innerRef} totalItemsCount={totalItemsCount}>
				{children}
			</Pagination>
		) : (
			<Infinite {...this.props} fetchMore={this.fetchMore} pageSize={pageSize || BASE_PARAMS.limit} ref={innerRef}>
				{children}
			</Infinite>
		);
	}
}

export default ProgressiveLoader;
