/* eslint-disable sort-keys */
import { EncoreShade, EncoreSize } from '~utils';

export const COLORS = [
	'primary',
	'neutral-weak',
	'neutral-medium',
	'neutral-strong',
	'neutral-inverted',
] as const satisfies EncoreShade[];

export const SIZES = ['extra-small', 'small', 'medium', 'large', 'extra-large'] as const satisfies EncoreSize[];

export const COMPONENTS = {
	'extra-small': 'h5',
	small: 'h4',
	medium: 'h3',
	large: 'h2',
	'extra-large': 'h1',
} as const satisfies Partial<Record<EncoreSize, string>>;

export const COMPONENT_LEVELS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

export const JUSTIFICATIONS = ['start', 'center', 'end'] as const;
