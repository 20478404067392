import { makeStyles } from '~styles';
import { AdornedTextProps } from './types';

export const useAdornedTextStyles = makeStyles<Partial<AdornedTextProps>>()((
	{ palette, typography, spacing },
	{ AdornedProps, ContentProps, verticalLayout }
) => {
	let adornmentMarginRight = AdornedProps ? spacing(AdornedProps.spacing) : spacing(1);
	if (verticalLayout) {
		adornmentMarginRight = '0';
	}

	return {
		root: {
			color: palette.gray[1000],
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: typography.medium.lineHeight,
			margin: '0 0 20px',
		},
		noMargin: {
			marginBottom: 0,
		},
		adornment: {
			display: 'flex', // prevents inline child from having gap at bottom
			fill: palette.primary.main,
			marginRight: adornmentMarginRight,
		},
		content: {
			marginLeft: ContentProps ? spacing(ContentProps.spacing) : spacing(0),
		},
	};
});
