import { Theme } from '@mui/material';
import { BASE_BOX_SHADOW, TRANSPARENT_BOX_SHADOW } from '../../button/constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const outlinedStyles = ({
	palette,
	mixins,
	constructs,
}: Theme): Record<'outlined' | 'outlinedPrimary' | 'outlinedSecondary' | 'toggled', any> => ({
	outlined: {
		backgroundColor: ifFeature('encore', palette.common.white, palette.background.default),
		borderColor: ifFeature('encore', palette.gray[400], palette.primary.lighter),
		color: ifFeature('encore', palette.gray[800]),
		'&:active, &$active': {
			borderColor: ifFeature('encore', palette.gray[700]),
			backgroundColor: ifFeature('encore', palette.grey[50], palette.background.default),
			'&&.MuiButtonGroup-grouped': ifFeature('encore', { borderRightColor: palette.gray[700] }, {}),
		},
		'&:hover': {
			borderColor: ifFeature('encore', palette.gray[700]),
			backgroundColor: ifFeature('encore', palette.common.white, palette.background.default),
		},
	},
	outlinedPrimary: {
		borderColor: ifFeature('encore', undefined, palette.primary.light),
		fill: ifFeature('encore', constructs.text.neutral.strong, palette.primary.main),
		'&:hover': {
			borderColor: ifFeature('encore', undefined, palette.primary.lighter),
			color: ifFeature('encore', undefined, palette.primary.light),
			fill: ifFeature('encore', undefined, palette.primary.light),
			boxShadow: ifFeature('encore', `box-shadow: 1px 1px 0px 2px ${mixins.alpha(palette.gray[900], 0.03)}`),
		},
		'&:active, &$active': {
			borderColor: ifFeature('encore', palette.primary.main, palette.primary.light),
			boxShadow: ifFeature('encore', undefined, 'inset 0 1px 1px rgba(0, 0, 0, 0.12)'),
			color: ifFeature('encore', palette.primary.main),
			fill: ifFeature('encore', palette.primary.main),
			'&&': {
				outline: ifFeature('encore', 0),
			},
			'&&.MuiButtonGroup-grouped:not(:focus):not($focusVisible)': ifFeature(
				'encore',
				{
					borderRightColor: palette.primary.main,
				},
				{}
			),
		},
		'&&$focusVisible, &&:focus': {
			borderColor: ifFeature('encore', palette.primary.lighter, palette.primary.light),
			boxShadow: ifFeature(
				'encore',
				`0px 0px 0px 4px ${mixins.alpha?.(constructs.border.primary.medium, 0.1)}`,
				`${BASE_BOX_SHADOW}, 0 0 0 2px ${mixins.alpha?.(palette.primary.lightest, 0.35)}`
			),
			'&&.MuiButtonGroup-grouped': ifFeature(
				'encore',
				{
					borderRightColor: palette.primary.lighter,
				},
				{}
			),
		},
		'&$disabled': {
			backgroundColor: ifFeature('encore', undefined, palette.background.default),
			borderColor: ifFeature('encore', 'transparent', palette.grey[300]),
			boxShadow: ifFeature('encore', undefined, TRANSPARENT_BOX_SHADOW),
		},
	},
	outlinedSecondary: {
		borderColor: ifFeature('encore', undefined, palette.secondary.dark),
		color: ifFeature('encore', undefined, palette.secondary.contrastText),
		fill: ifFeature('encore', undefined, palette.grey[800]),
		'&:hover': {
			borderColor: ifFeature('encore', undefined, palette.secondary.dark),
			color: ifFeature('encore', undefined, palette.grey[900]),
			fill: ifFeature('encore', undefined, palette.grey[900]),
			boxShadow: ifFeature('encore', `box-shadow: 1px 1px 0px 2px ${mixins.alpha(palette.gray[900], 0.03)}`),
		},
		'&:active, &$active': {
			color: ifFeature('encore', palette.primary.main),
			fill: ifFeature('encore', palette.primary.main),
			borderColor: ifFeature('encore', undefined, palette.secondary.dark),
			boxShadow: ifFeature('encore', undefined, `inset 0 1px 1px rgba(0, 0, 0, 0.15)`),
			'&&': {
				outline: ifFeature('encore', 0),
			},
			'&&.MuiButtonGroup-grouped:not(:focus):not($focusVisible)': ifFeature(
				'encore',
				{
					borderRightColor: palette.secondary.dark,
				},
				{}
			),
		},
		'&&$focusVisible, &&:focus': {
			borderColor: ifFeature('encore', palette.primary.lighter, palette.secondary.dark),
			boxShadow: ifFeature(
				'encore',
				`0px 0px 0px 4px ${mixins.alpha?.(constructs.border.primary.medium, 0.1)}`,
				`${BASE_BOX_SHADOW}, 0 0 0 2px ${palette.grey[300]}`
			),
			outline: ifFeature('encore', `1px solid ${palette.primary.lighter}`),
			'&&.MuiButtonGroup-grouped': ifFeature(
				'encore',
				{
					borderRightColor: palette.primary.lighter,
				},
				{}
			),
		},
		'&$disabled': {
			backgroundColor: ifFeature('encore', undefined, palette.background.default),
			borderColor: ifFeature('encore', 'transparent', palette.grey[300]),
			boxShadow: ifFeature('encore', undefined, TRANSPARENT_BOX_SHADOW),
		},
	},
	toggled: ifFeature('encore', {
		backgroundColor: constructs.surface.neutral.xWeak,
		color: constructs.text.primary.strong,
		fill: constructs.icon.primary.strong,
	}),
});
