import { LineChartProps } from '../types';

export class CirclePropsGenerator<Datum> {
	_getLineColor: (key: string) => string;

	_circleRadius: LineChartProps<Datum>['circleRadius'];

	_circleOpenRadius: LineChartProps<Datum>['circleOpenRadius'];

	_circleStrokeWidth: LineChartProps<Datum>['circleStrokeWidth'];

	constructor(
		getLineColor: (key: string) => string,
		circleRadius: LineChartProps<Datum>['circleRadius'],
		circleOpenRadius: LineChartProps<Datum>['circleRadius'],
		circleStokeWidth: LineChartProps<Datum>['circleStrokeWidth']
	) {
		this._getLineColor = getLineColor;
		this._circleRadius = circleRadius;
		this._circleOpenRadius = circleOpenRadius;
		this._circleStrokeWidth = circleStokeWidth;
	}

	_getValue = <T extends number | string>(datum: Datum, key: string, accessorOrValue?: ((datum: Datum, key: string) => T) | T) => {
		if (!accessorOrValue) {
			return accessorOrValue;
		}
		if (typeof accessorOrValue === 'function') {
			return accessorOrValue(datum, key);
		}
		return accessorOrValue;
	};

	_getCircleFillColor = (key: string, isOpen: boolean, showCircle: boolean) => {
		if (!showCircle) {
			return 'transparent';
		}

		if (isOpen) {
			return '#fff';
		}

		return this._getLineColor(key);
	};

	_getCircleRadius = (datum: Datum, key: string, isOpen: boolean) => {
		if (isOpen) {
			return this._getValue(datum, key, this._circleOpenRadius);
		}
		return this._getValue(datum, key, this._circleRadius);
	};

	_getStrokeColor = (key: string, isOpen: boolean, showCircle: boolean) => {
		if (isOpen && showCircle) {
			return this._getLineColor(key);
		}
	};

	getProps = (datum: Datum, key: string, isOpen: boolean, showCircle: boolean) => ({
		fill: this._getCircleFillColor(key, isOpen, showCircle),
		r: this._getCircleRadius(datum, key, isOpen),
		stroke: this._getStrokeColor(key, isOpen, showCircle),
		strokeWidth: isOpen ? this._getValue(datum, key, this._circleStrokeWidth) : undefined,
	});
}
