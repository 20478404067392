import { RefObject, useCallback, useEffect, useRef } from 'react';

import { elementContainsSingleSubmittableInput } from '../utils/dom';

export function useKeydown(ref: RefObject<HTMLElement>, callback: EventListener, shouldRegister: boolean): void {
	const previousCallbackRef = useRef<EventListener>();

	useEffect(() => {
		const previousCallback = previousCallbackRef.current;
		const element = ref.current;

		if (element && previousCallback !== callback && previousCallback) {
			element.removeEventListener('keydown', previousCallback);
		}

		if (element) {
			if (shouldRegister) {
				element.addEventListener('keydown', callback);
			} else {
				element.removeEventListener('keydown', callback);
			}
		}

		previousCallbackRef.current = callback;

		return (): void => {
			if (element) {
				element.removeEventListener('keydown', callback);
			}
		};
	}, [callback, ref, shouldRegister]);
}

export function useActionHotKeys(
	hotkeys: Array<string>,
	actionCallbacks: Array<React.ReactEventHandler | undefined>,
	canUseActions: boolean,
	modalContentRef: RefObject<HTMLElement>
): void {
	const handleKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLElement>) => {
			const hasFirstActionCallback = typeof actionCallbacks[0] === 'function';
			const index = hotkeys.indexOf(event.key);
			let callback = actionCallbacks[index];

			if (
				!callback &&
				event.key === 'Enter' &&
				hasFirstActionCallback &&
				elementContainsSingleSubmittableInput(event.currentTarget)
			) {
				[callback] = actionCallbacks;
			}

			if (callback && typeof callback === 'function') {
				callback(event);
			}
		},
		[actionCallbacks, hotkeys]
	);

	useKeydown(modalContentRef, handleKeydown as unknown as EventListener, canUseActions);
}
