import { isValidElement } from 'react';
import { isBoolean, isEmpty, isString, isPlainObject } from 'lodash';
import { InPageMessagingIcon } from './types';
import { typeMap, messagingTypes } from './const';
import { InPageMessagingType } from './enums';

import { createLogger } from '@bamboohr/utils/lib/dev-logger';
const InPageMessagingLogger = createLogger('@fabric/in-page-messaging | <InPageMessaging />');

export const log = (message: string, type: string) => {
	if (type === 'error') {
		InPageMessagingLogger.error(message);
	} else if (type === 'warning') {
		InPageMessagingLogger.warn(message);
	}
};

export const getStack = props => {
	const { stack } = props;
	if (!stack || !Array.isArray(stack)) {
		return [{ ...props }];
	}
	return stack;
};

export const getType = props => {
	let type = InPageMessagingType.neutral;
	const { info, error, neutral, success, warning } = props;
	const types = messagingTypes.filter((messagingType: InPageMessagingType) => {
		switch (messagingType) {
			case InPageMessagingType.error:
				return error === true;
			case InPageMessagingType.info:
				return info === true;
			case InPageMessagingType.neutral:
				return neutral === true;
			case InPageMessagingType.success:
				return success === true;
			case InPageMessagingType.warning:
				return warning === true;
		}
	});

	if (types.length === 1 && types[0]) {
		[type] = types;
	} else if (types.length > 1) {
		log('Multiple types were supplied. Defaulting to "neutral".', 'error');
	} else {
		log('No type, or an invalid type, was supplied. Defaulting to "neutral".', 'warning');
	}

	return type;
};

export const getFabricIcon = (icon: any = null, type = InPageMessagingType.neutral) => {
	if (icon) {
		if (isString(icon)) {
			return {
				brand: false,
				name: icon,
			};
		}
		if (isPlainObject(icon) && isString((icon as InPageMessagingIcon).name)) {
			return icon as InPageMessagingIcon;
		}
		if (isValidElement(icon)) {
			return icon;
		}
	}

	return typeMap[type].icon;
};

export const getIconName = (icon: any = null, type = InPageMessagingType.neutral) => {
	const fabricIcon = getFabricIcon(icon, type);
	if (isValidElement(fabricIcon)) {
		return '';
	}

	const { name = '' } = fabricIcon as InPageMessagingIcon;
	if (!isString(name) || isEmpty(name)) {
		return '';
	}
	return name;
};

export const getIconBrand = (icon: any = null, type = InPageMessagingType.neutral) => {
	const fabricIcon = getFabricIcon(icon, type);
	if (isValidElement(fabricIcon)) {
		return false;
	}

	const { brand = false } = fabricIcon as InPageMessagingIcon;
	if (!isBoolean(brand)) {
		return false;
	}
	return brand;
};

export const shouldRenderIcon = (icon: any = null, type = InPageMessagingType.neutral) => {
	const fabricIcon = getFabricIcon(icon, type);
	const iconName = getIconName(icon, type);
	if (isValidElement(fabricIcon)) {
		return true;
	}

	if (isEmpty(iconName) || iconName === 'none') {
		return false;
	}
	return true;
};
