import { Theme } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { px, border } from './mixins';

export const getThemedButton = (theme: Theme, paletteName: string, outlined = false): Record<string, unknown> => {
	const paletteColor = theme.palette[paletteName] as PaletteColor;

	const style = {
		boxShadow: 'none',
		borderRadius: px(2),
		padding: '7px 15px',
		fontSize: px(15),
		fontWeight: 'bold',
	};
	return outlined
		? {
				...style,
				color: paletteColor.contrastText,
				backgroundColor: theme.palette.background.default,
				border: border(1, paletteName === 'primary' ? paletteColor.light : paletteColor.dark),
				':hover': {
					boxShadow: '0 2px 2px 0 rgb(0 0 0 / 10%)',
				},
			}
		: {
				...style,
				color: paletteColor.contrastText,
				backgroundColor: paletteColor.main,
				border: border(1, paletteColor.main),
				':hover': {
					backgroundColor: paletteColor.light,
					color: paletteColor.contrastText,
				},
			};
};
