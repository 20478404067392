import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const sizeStyles = (theme: Theme): Record<string, any> => {
	const { typography } = theme;

	return {
		sizeTeenie: {
			height: '28px',
			fontSize: ifFeature('encore', typography.small.fontSize, typography.fabricFontSize?.('teenie')),
			padding: ifFeature('encore', undefined, '0 11px'),
			'&$iconOnly': {
				padding: '0 7px',
			},
			'&:not($text)': {
				fontSize: typography.fabricFontSize?.('small'),
			},
			'&$text': {
				padding: 0,
			},
			'&&': {
				padding: ifFeature('encore', '8px 12px', undefined),
				minWidth: ifFeature('encore', '36px', undefined),
			},
			'&$noBoundingBox, &$hoverOnly': {
				height: ifFeature('encore', 'unset'),
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '6px'),
				marginRight: ifFeature('encore', '-2px'),
			},
		},
		'sizeExtra-small': {
			padding: ifFeature('encore', '0 12px', '0 11px'),
			height: '28px',
			fontSize: ifFeature('encore', '13px', typography.fabricFontSize?.('teenie')),
			minWidth: ifFeature('encore', '36px'),
			'&$iconOnly': {
				padding: '0 7px',
			},
			'&:not($text)': {
				fontSize: ifFeature('encore', '13px', typography.fabricFontSize?.('small')),
			},
			'&$noBoundingBox, &$hoverOnly': {
				height: ifFeature('encore', 'unset'),
				padding: ifFeature('encore', '0'),
				minWidth: ifFeature('encore', '0'),
			},
			'&$text': {
				padding: 0,
				height: ifFeature('encore', '27px'),
				lineHeight: ifFeature('encore', typography.teenie?.lineHeight),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '6px'),
				marginRight: ifFeature('encore', '-2px'),
			},
		},
		sizeSmall: {
			height: '32px',
			fontSize: ifFeature('encore', typography.small.fontSize, typography.fabricFontSize?.('small')),
			padding: ifFeature('encore', undefined, '0 11px'),
			'&$text': {
				padding: 0,
			},
			'&&': {
				padding: ifFeature('encore', '0 12px', undefined),
				minWidth: ifFeature('encore', '40px', undefined),
			},
			'&$noBoundingBox, &$hoverOnly': {
				height: ifFeature('encore', 'unset'),
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '5px'),
				marginRight: ifFeature('encore', '-3px'),
			},
		},
		sizeMedium: {
			'&&': {
				padding: ifFeature('encore', '0 16px', undefined),
				minWidth: ifFeature('encore', '48px', undefined),
			},
			'&$noBoundingBox, &$hoverOnly': {
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'&$hoverOnly': {
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '5px'),
				marginRight: ifFeature('encore', '-3px'),
			},
		},
		sizeLarge: {
			height: ifFeature('encore', '48px', '36px'),
			fontSize: ifFeature('encore', typography.large.fontSize, typography.fabricFontSize?.('large')),
			'&&': {
				padding: ifFeature('encore', '0 20px', undefined),
				minWidth: ifFeature('encore', '48px', undefined),
			},
			'&$text': {
				padding: 0,
			},
			'&$noBoundingBox, &$hoverOnly': {
				height: ifFeature('encore', 'unset'),
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '9px'),
				marginRight: ifFeature('encore', '-3px'),
			},
		},
		sizeBiggie: {
			fontSize: ifFeature('encore', typography.large.fontSize, typography.fabricFontSize?.('biggie')),
			minWidth: ifFeature('encore', '48px'),
			'&&': {
				padding: ifFeature('encore', '0 20px', '0 15px'),
				height: ifFeature('encore', '48px', '42px'),
			},
			'&$iconOnly': {
				padding: '0 15px',
			},
			'&$text': {
				padding: 0,
			},
			'&$noBoundingBox, &$hoverOnly': {
				height: ifFeature('encore', 'unset'),
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '9px'),
				marginRight: ifFeature('encore', '-3px'),
			},
		},
		sizeHuge: {
			'&:not($text)': {
				fontSize: typography.fabricFontSize?.('small'),
				height: '64px',
				padding: '0 23px',
				'& $label': {
					flexDirection: 'column',
					justifyContent: 'center',
					lineHeight: 1,
				},
				'& $startIcon, & $endIcon': {
					display: 'flex',
					height: '26px',
					margin: '0 0 4px',
				},
			},
			'&$text': {
				height: '34px', // Use default medium height
				padding: 0,
			},
			'&$noBoundingBox, &$hoverOnly': {
				padding: ifFeature('encore', '0', undefined),
				minWidth: ifFeature('encore', '0', undefined),
			},
			'& $endIcon': {
				marginLeft: ifFeature('encore', '9px'),
				marginRight: ifFeature('encore', '-3px'),
			},
		},
	};
};
