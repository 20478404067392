// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { useStyles } from './footer.styles';
import { GridletFooterProps } from '../types';

export const Footer = ({ children, className, hasDivider, ...rest }: GridletFooterProps) => {
	const { classes } = useStyles({ hasDivider });
	return (
		<footer className={classnames(classes.root, className)} {...rest}>
			{children}
		</footer>
	);
};
