import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import classNames from 'classnames';

import { CloseButton } from '../close-button.react';

import { HeaderProps } from '../base-modal.types';

import { useStyles } from './header.style';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BaseModalContext } from '../context';

export const Header: FunctionComponent<PropsWithChildren<HeaderProps>> = props => {
	const { biId, children, className, hasCloseButton = true, inverted, ...rest } = props;
	const { classes } = useStyles({ inverted });
	const { ariaLabelledby } = useContext(BaseModalContext);

	const closeButtonComponent = ifFeature(
		'encore',
		<div className={classes.baseModalCloseButton}>
			<CloseButton biId={biId} />
		</div>,
		<CloseButton biId={biId} />
	);

	return (
		<div className={classNames(classes.baseModalHeader, className)} {...rest}>
			<div className={classes.baseModalHeaderContent} id={ariaLabelledby}>
				{children}
			</div>
			{hasCloseButton ? closeButtonComponent : null}
		</div>
	);
};
