import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import type { BlankStateProps } from './types';

export const useStyles = makeStyles<Pick<BlankStateProps, 'level'>>()((theme, props) => {
	const { mode, spacing } = theme;
	const { level } = props;

	return {
		actions: {
			alignItems: 'center',
			display: 'flex',
			gap: spacing(level === 'page' ? 2 : 1.5),
		},
		root: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(level === 'page' ? 3 : 2),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		text: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(1),
			textAlign: 'center',
		},
	};
});

/* @startCleanup encore */
export const useJadeStyles = makeStyles()(theme => {
	const { palette, mode, typography } = theme;

	return {
		footer: {
			marginTop: 22,
		},
		icon: {
			display: 'flex',
			fill: palette.gray[400],
		},
		root: {
			alignItems: 'center',
			cursor: 'default',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			textAlign: 'center',
			userSelect: 'none',
		},
		subtitle: {
			color: palette.gray[700],
			fontSize: typography.large.fontSize,
			marginTop: '6px',
			maxWidth: 512,
		},
		title: {
			color: palette.gray[700],
			margin: '20px 0 0 0',
		},
	};
});
/* @endCleanup encore */
