import {
	CursorType,
	MaskConfig,
	MaskMatcher,
	MatcherResult,
	Part,
	PartConformed,
	PartDemasked,
	PartEnriched,
	PartFinalized,
	PartIndexes,
	PartMatched,
	PartOvertyped,
	PartPreMatchHook,
	PartRelativeCursorIndexes,
	PartResolved,
	PartSetup,
} from '../types';

import { backfillString, getCursorType, getFullMaskStr } from '../utils';

export function getParts(
	config: MaskConfig,
	keydownInputValue: string,
	keydownStartPosition: number,
	keydownEndPosition: number,
	inputValue: string,
	startPosition: number,
	endPosition: number
): Array<Part> {
	const cursorType = getCursorType(keydownStartPosition, keydownEndPosition);
	const lengthChange = inputValue.length - keydownInputValue.length;
	const delimiter = config.delimiterChar;

	const parts = setupParts(
		config,
		keydownInputValue,
		keydownStartPosition,
		keydownEndPosition,
		inputValue,
		startPosition,
		endPosition
	);
	const demaskedParts = demaskParts(parts);
	const overtypedParts = overtypeParts(demaskedParts, cursorType, lengthChange);
	const resolvedParts = resolveParts(overtypedParts, delimiter);
	const preMatchHookParts = getPreMatchHookParts(resolvedParts);
	const matchedParts = matchParts(preMatchHookParts);
	const conformedParts = conformParts(matchedParts);
	const enrichedParts = enrichParts(conformedParts);

	return enrichedParts;
}

export function setupParts(
	config: MaskConfig,
	keydownInputValue: string,
	keydownStartPosition: number,
	keydownEndPosition: number,
	inputValue: string,
	startPosition: number,
	endPosition: number
): Array<PartSetup> {
	const mask = getFullMaskStr(config);
	const delimiter = config.delimiterChar;
	const inputValueParts = inputValue.split(delimiter);
	const maskParts = mask.split(delimiter);
	const keydownValuePartIndexes = getPartIndexes(keydownInputValue, delimiter);
	const inputValuePartIndexes = getPartIndexes(inputValue, delimiter);
	const keydownValues = keydownInputValue.split(delimiter);

	return config.order.map((prop, index) => {
		const partConfig = config.parts[prop];
		const maskValue = maskParts[index];
		const keydownValue = keydownValues[index];

		const keydownIndexes = keydownValuePartIndexes[index];
		const keydownRelativeCursorIndexes = getPartRelativeCursorIndexes(keydownIndexes, keydownStartPosition, keydownEndPosition);
		const inputIndexes = inputValuePartIndexes[index];
		const inputRelativeCursorIndexes = getPartRelativeCursorIndexes(inputIndexes, startPosition, endPosition);

		const { match, matcher, matchComplete } = getBestMatch(keydownValue, keydownValue, maskValue, partConfig.matchers);
		const matchedValue = match ? (match as Array<string>)[0] : '';

		return {
			config: partConfig,
			inputIndexes,
			inputRelativeCursorIndexes,
			inputValue: inputValueParts[index],
			keydownIndexes,
			keydownMatch: match,
			keydownMatchComplete: matchComplete,
			keydownMatchedValue: matchedValue,
			keydownMatcher: matcher,
			keydownRelativeCursorIndexes,
			keydownValue,
			maskValue,
		};
	});
}

export function demaskParts(parts: Array<PartSetup>): Array<PartDemasked> {
	return parts.map(part => {
		const { inputValue, keydownValue, maskValue } = part;

		if (inputValue && keydownValue === maskValue) {
			return {
				...part,
				demaskedValue: inputValue.replace(maskValue, ''),
			};
		}

		return {
			...part,
			demaskedValue: inputValue,
		};
	});
}

export function overtypeParts(parts: Array<PartDemasked>, cursorType: CursorType, lengthChange: number): Array<PartOvertyped> {
	return parts.map(part => {
		const { demaskedValue, inputRelativeCursorIndexes } = part;

		if (inputRelativeCursorIndexes?.startIndex !== undefined) {
			if (cursorType === 'point' && lengthChange === 1) {
				// overtype instead of insert when one character added
				if (demaskedValue) {
					const arr = demaskedValue.split('');
					arr.splice(inputRelativeCursorIndexes.startIndex, 1);
					const overtypedValue = arr.join('');

					return {
						...part,
						isOvertyped: true,
						overtypedValue,
					};
				}
			}
		}

		return {
			...part,
			isOvertyped: false,
			overtypedValue: demaskedValue,
		};
	});
}

export function resolveParts(parts: Array<PartOvertyped>, delimiter: string): Array<PartResolved> {
	const overtypedValueParts = parts.map(part => part.overtypedValue).filter(value => value !== undefined);

	if (overtypedValueParts.length === parts.length) {
		// if the number of overtypedValueParts is already as expected, no need to go further
		return parts.map(part => ({
			...part,
			resolvedValue: part.overtypedValue,
		}));
	}

	// extract previous known parts first where possible
	let remainingStr = overtypedValueParts.join(delimiter);
	const potentialResolvedParts = parts.map(part => {
		const { keydownValue } = part;

		if (remainingStr.includes(keydownValue)) {
			remainingStr = remainingStr.replace(keydownValue, '');
			return keydownValue;
		}
	});

	if (potentialResolvedParts.every(v => v !== undefined)) {
		// if all parts have been resolved
		return parts.map((part, index) => ({
			...part,
			resolvedValue: potentialResolvedParts[index],
		}));
	}

	// remove empty remaining parts that might have been created due to the delimiter
	let remainingParts = remainingStr.split(delimiter);
	remainingParts = remainingParts.filter(v => v !== '');

	const resolvedParts = potentialResolvedParts.map(potentialResolvedPart => {
		if (potentialResolvedPart !== undefined) {
			return potentialResolvedPart;
		}

		if (remainingParts.length) {
			return remainingParts.shift();
		}

		return '';
	});

	return parts.map((part, index) => ({
		...part,
		resolvedValue: resolvedParts[index] as string,
	}));
}

export function getPreMatchHookParts(parts: Array<PartResolved>): Array<PartPreMatchHook> {
	return parts.map(part => {
		const { config, resolvedValue } = part;
		const { preMatchHook } = config;

		let preMatchHookValue = resolvedValue;
		if (preMatchHook) {
			preMatchHookValue = preMatchHook(preMatchHookValue);
		}

		return {
			...part,
			preMatchHookValue,
			changedByPreMatchHook: resolvedValue !== preMatchHookValue,
		};
	});
}

export function matchParts(parts: Array<PartPreMatchHook>): Array<PartMatched> {
	return parts.map(part => {
		const { config, preMatchHookValue, keydownValue, maskValue } = part;

		const { match, matcher, matchComplete } = getBestMatch(preMatchHookValue, keydownValue, maskValue, config.matchers);
		const matchedValue = match ? (match as Array<string>)[0] : '';

		return {
			...part,
			matchedValue,
			match,
			matcher,
			matchComplete,
			rejectedLength: preMatchHookValue.length - matchedValue.length,
		};
	});
}

export function conformParts(parts: Array<PartMatched>): Array<PartConformed> {
	return parts.map(part => {
		let { matchedValue } = part;
		const { config, maskValue } = part;
		const { mask } = config;

		if (part.matcher) {
			if (part.matcher.fixedLength) {
				matchedValue = backfillString(matchedValue, mask);
			}
		}

		if (!matchedValue) {
			matchedValue = maskValue;
		}

		return {
			...part,
			conformedValue: matchedValue,
		};
	});
}

export function enrichParts(parts: Array<PartConformed>): Array<PartEnriched> {
	return parts.map(part => ({
		...part,
		changed: part.keydownValue !== part.conformedValue,
		changeAttempted: part.keydownValue !== part.inputValue,
	}));
}

export function finalizeParts(config: MaskConfig, parts: Array<Part>, finalValue: string): Array<PartFinalized> {
	const delimiter = config.delimiterChar;
	const finalValuePartIndexes = getPartIndexes(finalValue, delimiter);

	return parts.map((part, index) => {
		return {
			...part,
			finalValueIndexes: finalValuePartIndexes[index],
		};
	});
}

export function getPartIndexes(value: string, delimiter: string): Array<PartIndexes> {
	const valueArray = value.split('');
	const indexes: Array<PartIndexes> = [];

	let currentPart: PartIndexes = {
		endIndex: 0, // temporary placeholder
		startIndex: 0,
	};
	valueArray.forEach((char, index) => {
		if (index === valueArray.length - 1) {
			currentPart.endIndex = index;
			indexes.push(currentPart);
		} else if (char === delimiter) {
			currentPart.endIndex = index - 1;
			indexes.push(currentPart);

			currentPart = {
				endIndex: 0, // temporary placeholder
				startIndex: index + 1,
			};
		}
	});

	return indexes;
}

export function getPartRelativeCursorIndexes(
	inputIndexes: PartIndexes | undefined,
	cursorStartPosition: number,
	cursorEndPosition: number
): PartRelativeCursorIndexes | undefined {
	let startIndex;
	let endIndex;

	if (inputIndexes) {
		if (cursorStartPosition >= inputIndexes.startIndex && cursorStartPosition <= inputIndexes.endIndex) {
			startIndex = cursorStartPosition - inputIndexes.startIndex;
		}

		if (cursorEndPosition >= inputIndexes.startIndex && cursorEndPosition <= inputIndexes.endIndex) {
			endIndex = cursorEndPosition - inputIndexes.startIndex;
		}
	}

	if (startIndex !== undefined || endIndex !== undefined) {
		return {
			startIndex,
			endIndex,
		};
	}
}

export function getDelimiterIndexes(value: string, delimiter: string): Array<number> {
	const valueArray = value.split('');
	const indexes: Array<number> = [];

	valueArray.forEach((char, index) => {
		if (char === delimiter) {
			indexes.push(index);
		}
	});

	return indexes;
}

export function getBestMatch(value: string, keydownValue: string, maskValue: string, matchers: Array<MaskMatcher>): MatcherResult {
	if (keydownValue === maskValue) {
		// accounts for instances when the mask might match in some way (ex. 'mon' will match 'm' in 'mar').
		value = value.replace(maskValue, '');
	}

	let bestMatch: any;
	let bestMatcher: MaskMatcher | undefined;
	let bestMatchComplete = false;
	matchers.forEach(matcher => {
		const match = matcher.regex.exec(value);
		if (match) {
			if (
				!bestMatch ||
				(match[0].length > (bestMatch as Array<string>)[0].length && match.index <= (bestMatch as { index: number }).index)
			) {
				bestMatch = match;
				bestMatcher = matcher;

				const bestMatcherLength = bestMatcher.fixedLength || bestMatcher.maxLength;
				bestMatchComplete = (bestMatch as Array<string>)[0].length === bestMatcherLength;
			}
		}
	});

	return {
		match: bestMatch,
		matcher: bestMatcher,
		matchComplete: bestMatchComplete,
	};
}
