import { useFormWatch } from '.';
import { FieldValues, UseFormProps } from '../hook-form';
import { FormConfig, FormElement } from '../render-form/types';
import { getValuesByToken } from './etc/get-values-by-token';

export type UseFormElementWatchCallback = (element: FormElement, value: unknown, data: Record<string, unknown>) => void;

export function useFormElementWatch<T extends FieldValues>(
	callback: UseFormElementWatchCallback,
	controller: UseFormProps<T>,
	formConfig?: FormConfig
) {
	useFormWatch(controller, (name, value, data) => {
		if (!formConfig) {
			return;
		}

		const { formElements = {} } = formConfig;
		const element = Object.values(formElements).find(element => element.name === name);

		if (element) {
			callback(element, value, getValuesByToken(formConfig, data));
		}
	});
}
