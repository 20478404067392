import { Subcategory } from '../../types';

export const getDisplayOrderIndexRight = (subcategories: Subcategory[]): Subcategory[] => {
	let displayOrderIndex = 0;
	return subcategories.map((subcategory: Subcategory) => {
		if (subcategory.name) {
			subcategory.displayOrderIndex = displayOrderIndex++;
		}
		if (!subcategory.grouped) {
			const items = subcategory.items.map(item => {
				item.displayOrderIndex = displayOrderIndex++;
				return item;
			});
			subcategory.items = items;
		}
		return subcategory;
	});
};
