import { dyeColors, makeStyles, ModeOptions } from '~styles';
export const useStyles = makeStyles()(({ mode, typography }) => {
	return {
		root: {
			color: mode === ModeOptions.Dye ? dyeColors.main : undefined,
			fontSize: typography.biggie.fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: typography.biggie.lineHeight,
			padding: '9px 10px',
		},
	};
});
