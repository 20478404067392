/* eslint-disable sort-keys */
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from '~mui';
import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// The resets below, while not ideal, are copied from our Main repo for consistency, so that what we see locally is what users will see in prod
const useStyles = makeStyles(({ palette }: Theme) => ({
	'@global': {
		'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td':
			{
				margin: 0,
				padding: 0,
				border: 0,
				outline: 0,
				verticalAlign: 'baseline',
			},
		html: {
			fontSize: 15,
			height: '100%',
			width: '100%',
		},
		body: {
			lineHeight: 1,
			height: '100%',
			width: '100%',
		},
		a: ifFeature('encore', undefined, {
			borderRadius: '2px',
			color: palette.info.main,
			textDecoration: 'none',
			'&:hover': {
				color: palette.primary.main,
				cursor: 'pointer',
				textDecoration: 'underline',
			},
			'&:focus': {
				outline: '0',
			},
			'&:focus-visible, &:-moz-focusring': {
				outline: '0',
				boxShadow: `0 0 0 2px ${palette.primary.lightest}`,
			},
		}),
		'input, button, textarea, select': ifFeature('encore', {
			font: 'inherit',
		}),
		div: {
			verticalAlign: 'middle',
		},
		'ol, ul': {
			listStyle: 'none',
		},
		'blockquote, q': {
			quotes: 'none',
		},
		'blockquote:before, blockquote:after, q:before, q:after': {
			content: 'none',
		},
		/* remember to define focus styles! */
		':focus': {
			outline: 0,
		},
		/* remember to highlight inserts somehow! */
		ins: {
			textDecoration: 'none',
		},
		del: {
			textDecoration: 'line-through',
		},
		/* tables still need 'cellspacing="0"' in the markup */
		table: {
			borderCollapse: 'collapse',
			borderSpacing: 0,
		},
	},
}));

export const StyleReset: FC<PropsWithChildren<unknown>> = ({ children }: { children: ReactNode }) => {
	useStyles();

	return <>{children}</>;
};
