// eslint-disable-next-line no-use-before-define
import React, { forwardRef, RefObject } from 'react';
import { InputAdornment } from '@mui/material';
import { MaskedInput } from '~components/masked-input';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { useStyles } from './styles';
import { ControlledAmountInputProps, SYMBOL_POSITION } from '../types';
import { useMaskCommit } from './use-mask-commit';

/**
 * This component requires a parent to manage the state of its input
 */

export const ControlledAmountInput = forwardRef((props: ControlledAmountInputProps, ref: RefObject<HTMLInputElement>) => {
	const {
		allowNegativeValues = true,
		biId,
		conformInitialValue = true,
		currencyCode,
		currencySymbol = '$',
		menuButton,
		minValue,
		normalizeZeros = true,
		onBlur,
		onChange,
		padFractionalZeros = true,
		radix = '.',
		scale = 2,
		size = ifFeature('encore', undefined, 'small'),
		submitUnmaskedValue = true,
		symbolPosition = 0,
		thousandsSeparator = ',',
		value,
		width = 5,
		...rest
	} = props;

	const { status, viewMode, disabled } = rest;

	const { classes } = useStyles({ disabled, status, viewMode });

	const blockMask = {
		// This keeps the input and output formats in sync
		// so they're both using a period or both using the company settings for the decimal character
		mapToRadix: [submitUnmaskedValue ? '.' : radix],
		mask: Number,
		min: minValue,
		normalizeZeros,
		padFractionalZeros,
		radix,
		scale,
		signed: allowNegativeValues,
		thousandsSeparator,
	};
	const baseMask = symbolPosition === SYMBOL_POSITION.PREFIXED ? `${currencySymbol}#` : `#${currencySymbol}`;
	const handleCommit = useMaskCommit(baseMask, value, onChange, submitUnmaskedValue);

	return (
		<MaskedInput
			biId={biId}
			classes={{ root: classes.root }}
			conformInitialValue={conformInitialValue}
			InputProps={{
				endAdornment: ifFeature(
					'encore',
					menuButton,
					<InputAdornment className={classes.adornment} position="end">
						{menuButton}
					</InputAdornment>
				),
				classes: {
					focused: classes.focused,
					input: classes.input,
				},
			}}
			{...rest}
			MaskOptions={{
				lazy: false,
				mask: baseMask,
				blocks: {
					'#': {
						...blockMask,
					},
				},
				commit: handleCommit,
			}}
			onBlur={onBlur}
			ref={ref}
			size={size}
			submitUnmaskedValue={submitUnmaskedValue}
			value={value || ''}
			width={width}
		/>
	);
});
