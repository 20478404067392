import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { mediumScreenWidth } from '@fabric/definitions/json/mobile.json';

export const useStyles = makeStyles()(({ palette, breakpoints, mode }) => ({
	root: {
		borderColor: palette.gray[300],
		borderTop: `1px solid ${palette.gray[300]}`,
		outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		width: '100%',
	},
	guts: {
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: 1348,
		marginLeft: 'auto',
		marginRight: 'auto',
		[breakpoints.down(mediumScreenWidth)]: {
			flexDirection: 'column-reverse',
			alignItems: 'center',
		},
	},
	left: {
		color: palette.gray[700],
		padding: '26px 0 51px 32px',
		fontSize: 13,
		fontWeight: 400,
		textAlign: 'left',
		fontFamily: 'Lato',
		'& a': {
			color: palette.gray[700],
		},
		[breakpoints.down(mediumScreenWidth)]: {
			padding: '10px 0 51px',
		},
	},
	defaultChildren: {
		display: 'flex',
		[breakpoints.down(mediumScreenWidth)]: {
			flexDirection: 'column',
			alignItems: 'center',
			'& div': {
				padding: '10px 0',
			},
			'& span': {
				display: 'none',
			},
		},
	},
	right: {
		padding: '31px 32px 45px 0',
		[breakpoints.down(mediumScreenWidth)]: {
			padding: '31px 0 0',
		},
	},
}));
