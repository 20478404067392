import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { CropperProps } from './cropper.types';

export const useStyles = makeStyles<Partial<CropperProps>>()((theme, params) => {
	const { palette, mode } = theme;
	const { shape } = params;

	function getCropperShape(): string {
		if (shape === 'circle') {
			return '100%';
		}
		return '0%';
	}

	return {
		root: {
			display: 'flex',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			/** Modification to default cropper styles
			 */
			'.cropper-view-box, .cropper-face': {
				borderRadius: getCropperShape(),
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			},
			'.cropper-crop-box:focus .cropper-view-box': {
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : `solid 3px ${palette.primary.main}`,
			},
			'.cropper-point:focus': {
				outline: `solid 3px ${palette.primary.main}`,
			},
		},
		image: {
			display: 'block',
			margin: '0 auto',
			maxHeight: '100%',
			maxWidth: '100%',
			width: 'auto',
			'&:not([src])': {
				visibility: 'hidden',
			},
		},
	};
});
