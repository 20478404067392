import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { CarouselBaseProps } from './types';

export const useStyles = makeStyles<Partial<CarouselBaseProps>>()((theme, params) => {
	const { mode } = theme;
	const { singlePageSlideAlignment = 'center' } = params;

	const leftMarginByAlignment = {
		center: 'auto',
		left: '0',
		right: 'auto',
	};

	const rightMarginByAlignment = {
		center: 'auto',
		left: 'auto',
		right: '0',
	};

	return {
		container: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			overflowX: 'hidden',
		},
		hideSlide: {
			transitionDuration: '0.3s',
			transitionProperty: 'visibility',
			transitionTimingFunction: 'ease-in-out',
			visibility: 'hidden',
		},
		slider: {
			display: 'grid',
			gridAutoFlow: 'column',
			gridAutoColumns: 'minmax(0, 1fr)',
			marginLeft: leftMarginByAlignment[singlePageSlideAlignment],
			marginRight: rightMarginByAlignment[singlePageSlideAlignment],
			position: 'relative',
			transitionDuration: '0.3s',
			transitionProperty: 'left',
			transitionTimingFunction: 'ease-in-out',
		},
	};
});
