import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(() => {
	return {
		root: {
			padding: ifFeature('encore', '18px 24px', '13px 24px 12px'),
		},
		title: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	};
});
