import { useEffect, useRef, useState } from 'react';
import { Cx } from 'tss-react';

export function useStepClasses(totalSteps: number, currentStep: number, classes: Record<string, string>, cx: Cx): string[] {
	const [stepClasses, setStepClasses] = useState<Array<string>>([]);
	const prevStepRef = useRef(-1);
	const boundedCurrentStep = Math.min(Math.max(currentStep, 0), totalSteps - 1);

	useEffect(() => {
		const ANIMATION_DELAY = 20; // ms; allows time for new step to render as transparent before it animates
		const ANIMATION_DURATION = 400; // ms; matches the css transition time
		let animationTimeout;
		let animationDelayTimeout;

		const getClassName = (stepNumber: number, current: number, isAnimating: boolean) => {
			return cx({
				[classes.step]: true,
				[classes.earlier]: stepNumber < current,
				[classes.current]: stepNumber === current,
				[classes.later]: stepNumber > current,
				[classes.animating]: isAnimating,
			});
		};

		const createClassNames = (current: number, isAnimating: boolean) => {
			setStepClasses(
				Array.from(Array(totalSteps).keys()).map(stepNumber => {
					return getClassName(stepNumber, current, isAnimating);
				})
			);
		};

		if (prevStepRef.current === -1) {
			createClassNames(boundedCurrentStep, false);
		} else {
			createClassNames(prevStepRef.current, true);

			animationDelayTimeout = window.setTimeout(() => {
				createClassNames(boundedCurrentStep, true);
			}, ANIMATION_DELAY);

			animationTimeout = window.setTimeout(() => {
				createClassNames(boundedCurrentStep, false);
			}, ANIMATION_DURATION + ANIMATION_DELAY);
		}

		prevStepRef.current = boundedCurrentStep;

		return () => {
			window.clearTimeout(animationDelayTimeout);
			window.clearTimeout(animationTimeout);
		};
	}, [boundedCurrentStep, JSON.stringify(classes), totalSteps]);

	return stepClasses;
}
