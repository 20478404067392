import { Exact } from '../types/exact';
import { EncoreToken } from './types';

/**
 * Converts an Encore token into a value (probably a CSS value).
 *
 * @example
 * ```
 * declare const color = 'none' | 'neutral-weak' | 'neutral-strong';
 *
 * getEncoreValue(color, {
 * 	none: 'transparent',
 * 	'neutral-weak': 'lightgrey',
 * 	'neutral-strong': 'darkgrey',
 * });
 * ```
 *
 * You may be wondering... why not just do `myObject[myToken]`? This function provides the following benefits:
 *
 * - handles `undefined` tokens for you (TS throws an error at `myObject[undefined]`)
 * - ensures the map has every possible value for your token and *only* those values, which avoids accidentally adding a value, thinking it's available, and forgetting to update the props def
 *
 * @param token An Encore token
 * @param map An object that maps tokens to values
 * @returns The value in the map that corresponds with the token
 */
export function getEncoreValue<Token extends keyof any | undefined, Map extends Exact<Record<NonNullable<Token>, unknown>, Map>>(
	token: Token,
	map: Map
) {
	return token && map[token];
}
