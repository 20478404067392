import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { InPageMessagingType } from './enums';

export const useStyles = makeStyles<{ type: InPageMessagingType }>()(({ mode, palette, spacing, typography }, { type }) => {
	const typeColors = {
		error: palette.error.main,
		info: palette.info.main,
		neutral: palette.gray[500],
		success: palette.success.main,
		warning: palette.warning.main,
	};

	return ifFeature(
		'encore',
		{
			main: {
				display: 'flex',
				flexDirection: 'column',
				gap: spacing(2),
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			},
		},
		{
			main: {
				backgroundColor: 'white',
				borderRadius: '2px',
				border: `1px solid ${typeColors[type]}`,
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
				display: 'flex',
				flexDirection: 'column',
				fontSize: typography.teenie.fontSize,
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				padding: '18px 24px',
			},
		}
	);
});
