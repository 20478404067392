import React, { ComponentProps, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { DURATION } from '../slidedown/constants';
import { useStyles } from './transition.styles';

type TransitionProps = ComponentProps<typeof Transition>;
type SlidedownTransitionProps = Partial<TransitionProps> & {
	children: ReactNode;
	show: boolean;
	timeout?: number;
};

const SlidedownTransition = (props: SlidedownTransitionProps) => {
	const { children, show, timeout = DURATION, ...otherProps } = props;
	const { classes, cx } = useStyles();

	return (
		<Transition in={show} timeout={timeout} {...otherProps}>
			{state => <div className={cx(classes.default, classes[state])}>{children}</div>}
		</Transition>
	);
};

export default SlidedownTransition;
