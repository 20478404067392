import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import clsx from 'classnames';
import { capitalize, isBoolean } from 'lodash';
import { BaseButton } from '../base-button';
import { ButtonProps, IconButtonProps } from '../../types';
import { styles } from './icon-button.styles';

export const IconButton = withStyles(styles)(
	forwardRef<HTMLElement, IconButtonProps & WithStyles<typeof styles>>(
		(
			{
				active: activeProp,
				biId,
				classes,
				component,
				dark,
				floatingIcon,
				focused: focusedProp,
				hoverOnly,
				noBoundingBox: noBoundingBoxProp,
				onClick,
				variant: variantProp,

				// deprecated props below
				clickAction,
				isActive,
				isFocused,
				hasBounding,
				size = 'medium',
				...rest
			},
			ref
		) => {
			const active = activeProp || isActive;
			const focused = focusedProp || isFocused;
			const noBoundingBox = isBoolean(hasBounding) ? !hasBounding : noBoundingBoxProp;

			let variant: ButtonProps['variant'] = variantProp;
			if (noBoundingBox || hoverOnly) {
				variant = 'text';
			} else if (floatingIcon) {
				variant = 'outlined';
			}

			return (
				<BaseButton
					active={active}
					classes={{
						root: clsx(classes[`size${capitalize(size)}`], {
							[classes.active]: active,
							// We don't need to apply `floatingIcon` styles if `noBoundingBox` or `hoverOnly` is `true`.
							[classes.floatingIcon]: floatingIcon && !noBoundingBox && !hoverOnly,
							[classes.focusVisible]: focused,
							[classes.noBoundingBox]: noBoundingBox,
							[classes.hoverOnly]: hoverOnly,
							[classes.altStyling]: dark,
						}),
						disabled: classes.disabled,
						focusVisible: classes.focusVisible,
						processing: classes.processing,
						outlinedPrimary: classes.outlinedPrimary,
						outlinedSecondary: classes.outlinedSecondary,
						endIcon: classes.endIcon,
					}}
					// `hoverOnly` buttons have no click action and should not default to using a `button` element.
					component={hoverOnly ? component || 'div' : component}
					dark={dark}
					data-bi-id={biId}
					focused={focused}
					onClick={hoverOnly ? undefined : onClick || clickAction}
					ref={ref}
					size={size}
					{...rest}
					variant={variant}
				/>
			);
		}
	)
);

export const IconButtonStorybook = IconButton as FC<PropsWithChildren<IconButtonProps>>;
