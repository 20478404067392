// eslint-disable-next-line no-use-before-define
import React, { forwardRef, RefObject, useCallback, useEffect, useState } from 'react';
import { ControlledAmountInput } from '~components/currency-field';
import { AmountInputProps } from '../types';

export const AmountInput = forwardRef((props: AmountInputProps, ref: RefObject<HTMLInputElement>) => {
	const {
		biId,
		currencyPicker,
		decimalCharacter,
		name,
		onBlur,
		onChange,
		selectedCurrencyItem,
		thousandsSeparator = ',',
		useUnmaskedValue = true,
		value = '',
		...rest
	} = props;
	const [_value, setValue] = useState<string>(value || '');

	useEffect(() => {
		setValue(value);
	}, [value]);

	const handleOnChange = useCallback(
		(newValue: string) => {
			if (onChange && typeof onChange === 'function') {
				onChange(newValue);
			}
		},
		[onChange]
	);

	return (
		<ControlledAmountInput
			biId={biId}
			currencyCode={selectedCurrencyItem.code}
			currencySymbol={selectedCurrencyItem.symbol}
			menuButton={currencyPicker}
			name={name}
			onBlur={onBlur}
			onChange={handleOnChange}
			radix={decimalCharacter || '.'}
			ref={ref}
			submitUnmaskedValue={useUnmaskedValue}
			symbolPosition={selectedCurrencyItem.symbolPosition}
			thousandsSeparator={thousandsSeparator}
			value={_value}
			{...rest}
		/>
	);
});
