import { Transition, animate, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';

type Interpolator = (fromPath: string, toPath: string) => (progress: number) => string;

export const _computeInitialPathToAnimateFrom = (path: string, startingYVal: number) => {
	const regex = /(?=,).*?(?=L)|(?=,).*?(?=$)/g;
	return path.replaceAll(regex, `,${startingYVal}`);
};

/**
 * Hook used to animate SVG line paths
 * @param path the svg path as a string
 * @param startingYVal the starting y position for the line
 * @param interpolator the SVG interpolator to use
 * @param transition the Framer Motion transition to use
 * @returns an animated SVG line path
 */
export const useAnimatedLinePath = (path: string, startingYVal: number, interpolator: Interpolator, transition: Transition) => {
	const progress = useMotionValue(0);
	const startPathRef = useRef<string>();
	const endPathRef = useRef<string>();
	const lineDestroyedRef = useRef<boolean>(false);

	useEffect(() => {
		// Compute the path to animate from
		const isANewLine = endPathRef.current === undefined || path === '' || lineDestroyedRef.current;
		startPathRef.current = isANewLine ? _computeInitialPathToAnimateFrom(path, startingYVal) : endPathRef.current;
		// Set the end path to the current path and reset the animation
		lineDestroyedRef.current = !path;
		if (path) {
			endPathRef.current = path;
		} else {
			startPathRef.current = endPathRef.current;
		}
		progress.set(0);
		const controls = animate(progress, 1, { ...transition, type: 'spring' });
		return controls.stop;
	}, [path, progress, startingYVal, transition]);

	return useTransform(progress, progress => {
		// Creating interpolator within transform callback as we want to dereference the latest values of the refs
		return interpolator(startPathRef.current ?? '', endPathRef.current ?? '')(progress);
	});
};
