import { Typography, TypographyProps } from '@fabric/typography';
import React, { forwardRef, Ref } from 'react';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The title that appears atop a `SideTabPanel`.
 *
 * This is designed to accept an icon and text as children, which will
 * automatically be laid out with flexbox. If you're not providing an icon,
 * it will work fine, but you can also just pass the title to `SideTabPanel`'s
 * `header` prop for the same result.
 */
export const SideTabPanelHeader = forwardRef(function SideTabPanelHeader(props: TypographyProps, ref: Ref<HTMLElement>) {
	return (
		<Typography
			alignItems="center"
			display="flex"
			fontWeight="semibold"
			gap={1}
			mb={3}
			ref={ref}
			// set `fill` for default icon color
			sx={({ palette }) => ({ fill: palette.primary.main })}
			variant="biggie"
			{...props}
		/>
	);
	// Generics are lost with `forwardRef`
	// https://github.com/mui/material-ui/issues/32420#issuecomment-1146757121
}) as typeof Typography;
