import useId from '@mui/utils/useId';
import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import { interpolatePath } from 'd3-interpolate-path';

import { LineChartAreaProps } from '../../types/line-chart-area-props';
import { useAnimatedLinePath } from '../use-animated-line-path';

export const makeLinearGradientArea = (startColor: string, endColor: string) => {
	const LinearGradientArea: FC<PropsWithChildren<Omit<LineChartAreaProps, 'color'>>> = ({ path, animate, yMax, transition }) => {
		const animatedPath = useAnimatedLinePath(path, yMax, interpolatePath, transition);
		const id = useId() as string;
		// The id generated by useId is guaranteed to be unique across all components
		const areaGradientId = `areaGradient-${id}`;
		if (yMax === 0) {
			return null;
		}
		return (
			<>
				<defs>
					<linearGradient gradientTransform="rotate(90)" id={areaGradientId}>
						<stop offset="0%" stopColor={startColor} />
						<stop offset="100%" stopColor={endColor} />
					</linearGradient>
				</defs>
				<motion.path
					animate={animate ? 'idle' : false}
					d={animatedPath}
					fill={`url(#${areaGradientId})`}
					initial={animate ? 'initial' : 'idle'}
				/>
			</>
		);
	};
	return LinearGradientArea;
};
