export const HASH = '7128ba14';
/**
 * IS_DEV is only for the Storybook environment
 */
export const BASE_URL = 'https://staticfe.bamboohr.com/assets/icons';

export const SPRITE = `sprite_${HASH}.svg`;
export const SPRITE_URL = `${BASE_URL}/${SPRITE}`;

export const ICON_MANFIEST = `icon-manifest_${HASH}.json`;
export const ICON_MANIFEST_URL = `${BASE_URL}/${ICON_MANFIEST}`;
