import { Layout, LayoutPosition } from '../types';

export function calculatePositionInLayout(layout: Layout, layoutKey: string): LayoutPosition {
	const row = layout.findIndex(arr => arr.includes(layoutKey));
	const rowSpan = layout.filter(arr => arr.includes(layoutKey)).length;

	const containedInFirstFoundRow = layout[row] || [];

	const column = containedInFirstFoundRow.indexOf(layoutKey);
	const columnSpan = containedInFirstFoundRow.filter(key => key === layoutKey).length;

	return {
		row,
		rowSpan,
		column,
		columnSpan,
	};
}
