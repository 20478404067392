import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, spacing }) => {
	return {
		/* @startCleanup encore */
		root: {
			backgroundColor: palette.gray[100],
			borderRadius: '8px',
			boxSizing: 'border-box',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			width: '330px',
		},
		/* @endCleanup encore */
		steps: {
			display: 'grid',
			/* @startCleanup encore */
			padding: ifFeature('encore', undefined, `${spacing(2.5)} ${spacing(3)} ${spacing(2.5)} ${spacing(1.5)}`),
			/* @endCleanup encore */
			rowGap: ifFeature('encore', spacing(0.5), '2px'),
		},
		navigation: {
			borderTop: ifFeature('encore', undefined, `1px solid ${palette.gray[300]}`),
			margin: `0 ${spacing(3)}`,
			padding: ifFeature('encore', undefined, `${spacing(2.5)} 0`),
		},
	};
});
