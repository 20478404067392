import React, { FunctionComponent, PropsWithChildren } from 'react';
import { UpperContent as BaseModalUpperContent } from '~components/base-modal';
import { useStyles } from './upper-content.styles';

export const UpperContent: FunctionComponent<PropsWithChildren<unknown>> = props => {
	const { classes } = useStyles();

	return (
		<div className={classes.root}>
			<BaseModalUpperContent {...props} />
		</div>
	);
};
