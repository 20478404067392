import { RadioValue } from './types';
export function getBiId(biId = '', name = '', value: RadioValue): string | undefined {
	if (biId) {
		const basePart = `${biId}-radio`;
		const namePart = name ? `-${name}` : '';
		const valuePart = `-${value}`;

		return `${basePart}${namePart}${valuePart}`;
	}
}
