import React from 'react';
import { CalendarBaseNav } from '../nav';
import { CalendarBaseLabelProps } from './calendar-base-label.types';

export const CalendarBaseLabel = (props: CalendarBaseLabelProps) => {
	const { children, navigation, endMonth, startMonth, ...htmlProps } = props;

	if (navigation === 'none') {
		return <span {...htmlProps}>{children}</span>;
	}

	return <CalendarBaseNav {...props}>{children}</CalendarBaseNav>;
};
