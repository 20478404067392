import { BuiltinFormElementType } from '../enums';
import { FormFactoryField } from '../types/field.types';
import { FormFactoryBuiltinElementsUnion } from '../form-factory.types';

export function getValue<T extends FormFactoryField | Extract<FormFactoryBuiltinElementsUnion, FormFactoryField>>([model]: [T]) {
	if ((model.type as BuiltinFormElementType) === BuiltinFormElementType.CheckboxField && 'checked' in model) {
		return model.checked;
	}

	return model.value;
}
