import { makeStyles } from '~styles';

const LIST_PADDING_Y = 1.5;

export const useStyles = makeStyles()(theme => {
	const {
		borderRadiuses,
		constructs: { border, surface },
		mixins: { boxShadow },
		palette,
		spacing,
		typography,
	} = theme;

	return {
		root: {
			'& .MuiPaper-root': {
				background: surface.neutral.forcedWhite,
				border: `1px solid ${border.neutral.weak}`,
				borderRadius: borderRadiuses[200],
				boxShadow: boxShadow('700', palette.gray[900]),
				'&:empty': {
					display: 'none',
				},
			},
			'& .MuiAutocomplete-listbox': {
				padding: spacing(LIST_PADDING_Y, 0),
				'&:has(.MuiListSubheader-root) > li:not(:first-child)': {
					borderTop: `1px solid ${surface.neutral.weak}`,
					marginTop: spacing(1.5),
				},
			},
			'& .MuiAutocomplete-option': {
				minHeight: spacing(5.25),
				padding: spacing(1.25, 2),
				'&:hover, &.Mui-focused': {
					backgroundColor: surface.neutral.xWeak,
				},
			},
			'& .MuiListSubheader-root': {
				...typography.xsmall,
				background: surface.neutral.forcedWhite,
				color: palette.gray[800],
				fontWeight: 600,
				padding: spacing(1.5, 2),
				top: spacing(-LIST_PADDING_Y),
			},
		},
	};
});
