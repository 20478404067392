import { DatePickerDate, DatePickerDatePredicate, DatePickerDateType, DatePickerUtils } from '../types';
import { getIsDateDisabled } from './date.utils';

/**
 * Returns the closest enabled date that occurs after the reference date. If no date is found then it returns `undefined`.
 * @param utils The date utils used all over the Date Picker components
 * @param date The reference date
 * @param constraints The constraints that control if a date is enabled or not.
 * @returns The first enabled date (or undefined if there isn't one)
 */
export function getNextEnabledDate(
	utils: DatePickerUtils,
	date: DatePickerDate,
	constraints: { getDateDisabled?: DatePickerDatePredicate; maxDate: DatePickerDate; minDate?: DatePickerDate }
) {
	const { getDateDisabled, maxDate, minDate } = constraints;

	let currentDate = date as DatePickerDateType;

	while (utils.isBefore(currentDate, maxDate as DatePickerDateType) || utils.isSameDay(currentDate, maxDate as DatePickerDateType)) {
		currentDate = utils.addDays(currentDate, 1);

		if (!getIsDateDisabled(utils, currentDate, minDate, maxDate, getDateDisabled)) {
			return currentDate;
		}
	}
}
