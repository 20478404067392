import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { cloneDeep } from 'lodash';
import React, { forwardRef, Ref, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDataGridStyles } from './data-grid.styles';
import { DataGridProps, DataGridRef } from './data-grid.types';
import { DeletionModal } from './deletion-modal';
import {
	addDefaultColumnDef,
	addDefaultColumns,
	getAllRowData,
	getRowId as defaultGetRowId,
	getRowNumber as defaultGetRowNumber,
} from './utils';
import { createDefaultDateValueFormatter } from './utils/create-default-date-value-formatter';
import { useDatePickerUtils } from '../date-picker/hooks';
import { createDefaultDropdownValueFormatter } from './utils/create-default-dropdown-value-formatter';

// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

function InnerDataGrid<TData>(props: DataGridProps<TData>, ref: Ref<DataGridRef<TData>>) {
	const {
		columnDefs,
		columnTypes,
		infiniteLoadingChunkSize,
		infinite = false,
		defaultColDef = {},
		domLayout,
		getRowId = defaultGetRowId,
		getRowNumber = defaultGetRowNumber,
		hideRowNumbers = false,
		initialRowData = [],
		onCellClicked,
		onCellEditingStarted,
		onCellEditingStopped,
		onDeleteRow,
		onGridReady,
	} = props;

	// initialRowData is copied to preserve the initial data since the data is mutated by ag-grid
	const [rowData, setRowData] = useState<TData[]>(infinite ? [] : () => cloneDeep(initialRowData));

	const { classes } = useDataGridStyles({ rowCount: rowData.length });

	const modifiedDefaultColDef = useMemo(() => addDefaultColumnDef(defaultColDef), [defaultColDef]);

	const modifiedColumnDefs = useMemo(
		() =>
			addDefaultColumns({
				columnDefs,
				deletionColumnClass: classes.delete,
				getRowNumber,
				hideRowNumbers,
				onDeleteRow,
				rowNumberColumnClass: classes.rowCol,
			}),
		[classes.delete, classes.rowCol, columnDefs, hideRowNumbers, onDeleteRow, getRowNumber]
	);

	const gridRef = useRef<AgGridReact<TData>>(null);

	useImperativeHandle(ref, () => {
		return {
			getAllRowData: getAllRowData<TData>(gridRef),
			getEditingCells: () => gridRef.current?.api?.getEditingCells() || [],
			setRowData: (rows: Parameters<typeof setRowData>[0]) =>
				// rows is copied to preserve the initial data since the data is mutated by ag-grid
				setRowData(cloneDeep(typeof rows === 'function' ? rows(rowData) : rows)),
		};
	});

	const datePickerUtils = useDatePickerUtils();

	return (
		<div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
			{columnDefs && rowData && (
				<AgGridReact
					cacheBlockSize={infiniteLoadingChunkSize}
					className={classes.root}
					columnDefs={modifiedColumnDefs}
					/*
					 * `columnTypes` must include keys for custom column types or AG-Grid will generate warnings.
					 * These objects can contain additional configuration, but we're not currently using any of that functionality.
					 * i.e. text: {}
					 */
					columnTypes={{
						text: {},
						dropdown: { valueFormatter: createDefaultDropdownValueFormatter() },
						date: {
							valueFormatter: createDefaultDateValueFormatter(datePickerUtils),
							cellEditorPopup: ifFeature('encore', true, false),
						},
						...columnTypes,
					}}
					defaultColDef={modifiedDefaultColDef} // Default Column Properties
					domLayout={domLayout}
					getRowId={getRowId}
					headerHeight={34}
					onCellClicked={onCellClicked}
					onCellEditingStarted={onCellEditingStarted}
					onCellEditingStopped={onCellEditingStopped}
					onGridReady={onGridReady}
					overlayNoRowsTemplate={window.jQuery ? $.__('No Rows To Show') : 'No Rows To Show'}
					ref={gridRef}
					rowData={rowData} // Row Data for Rows
					rowHeight={ifFeature('encore', 34)}
					rowModelType={infinite ? 'infinite' : 'clientSide'}
					singleClickEdit={true}
					tooltipHideDelay={15000}
					tooltipShowDelay={500}
				/>
			)}
		</div>
	);
}

const DataGridWithForwardRef = forwardRef(InnerDataGrid);

type DataGrid = typeof DataGridWithForwardRef & {
	DeletionModal: typeof DeletionModal;
};

export const DataGrid = DataGridWithForwardRef as DataGrid;

DataGrid.DeletionModal = DeletionModal;
