import { useRef } from 'react';

import { useKeydown } from '../use-keydown';
import { getUpdatedPath } from './get-updated-path';
import { UpdatePathData, UseKeyboardNavigationConfig } from '../../types';

/**
 * Uses keyboard events to traverse through available paths.
 *
 * A Path represents a specific location as an array of indexes.
 *
 * For example in the following array:
 * ```
 * 	const items = ['Captain Ahab', 'Captain Jack Sparrow'];
 * ```
 *
 * the path `[1]` refers to the item at index `1` or "Captain Jack Sparrow"
 *
 * Similarly, in the following collection:
 * ```
 * 	const items = [{ name: 'Departments', values: ['Engineering', 'Sales']}];
 * ```
 * the path `[0, 1]` refers to the item at index `1` whose parent is at index `0` or, in other terms, the second item inside of the first item.
 *
 * An empty path `[]` indicates no location.
 */
export const useKeyboardNavigation = ({
	activePath = [],
	getDisabled,
	getTotal,
	onChange,
	targetElement = document,
}: UseKeyboardNavigationConfig): void => {
	const updatePathData = {
		activePath,
		getDisabled,
		getTotal,
		targetElement,
	};

	const updatePathDataRef = useRef<Omit<UpdatePathData, 'key'>>(updatePathData);
	updatePathDataRef.current = updatePathData;

	useKeydown(targetElement, event => {
		const updatePathData = updatePathDataRef.current;
		const updatedPath = getUpdatedPath({ ...updatePathData, key: event.key });

		if (updatePathData.activePath !== updatedPath) {
			onChange(updatedPath);
		}
	});
};
