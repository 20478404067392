import { hasCollisionWithAnchor } from '../container';
import { Directions } from '../../enums';
import { getDimensions } from '../../offsets';
import { isTopOrBottomLocation } from '../../locations';

export function shouldSlideHorizontally(collision, offsets, locations) {
	const isHorizontalCollision = collision === Directions.LEFT || collision === Directions.RIGHT;

	return (
		isHorizontalCollision &&
		!hasCollisionWithAnchor(offsets) &&
		isTopOrBottomLocation(locations.anchor) &&
		isTopOrBottomLocation(locations.vessel)
	);
}

export function slideHorizontally(collision, offsets) {
	const { container, vessel } = offsets;

	const vesselDimensions = getDimensions(vessel);

	const newOffsets = { ...vessel };
	if (collision === Directions.LEFT) {
		newOffsets.left = container.left;
		newOffsets.right = container.left + vesselDimensions.width;
	} else if (collision === Directions.RIGHT) {
		newOffsets.left = container.right - vesselDimensions.width;
		newOffsets.right = container.right;
	}
	return newOffsets;
}
