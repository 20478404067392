import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { PillProps } from './types';
import { useStyles } from './pill.styles';
import { IconV2 } from '~components/icon-v2';

export const Pill = forwardRef<HTMLElement, PillProps>(({ children, classes: classesProp, className, icon, muted, type }, ref) => {
	const { classes, cx } = useStyles({ muted, type }, { props: { classes: classesProp } });
	return (
		<Box className={cx(className, classes.root)} ref={ref}>
			{icon?.endsWith('-solid') ? (
				<span className={classes.icon}>
					<IconV2 name={icon} size={12} />
				</span>
			) : null}
			{children}
		</Box>
	);
});
