import React, { FC, PropsWithChildren } from 'react';
import { useStyles } from './styles';
import { GutterGroupProps } from '../../types';

export const GutterGroup: FC<PropsWithChildren<GutterGroupProps>> = props => {
	const { classes, cx } = useStyles();
	const { className, end, name, renderGroup, size = 'medium', start } = props;

	return (
		<div
			className={cx(
				classes.root,
				{
					[classes.smallSize]: size === 'small' || size === 'teenie',
				},
				className
			)}
			key={name}
			style={{
				top: `${start}px`,
				height: `${end - start}px`,
			}}
		>
			{typeof renderGroup === 'function' ? renderGroup(name) : name}
		</div>
	);
};
