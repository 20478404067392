// eslint-disable-next-line no-use-before-define
import React from 'react';
import { TickRendererProps } from '@visx/axis';
import { makeStyles } from '~styles';
import { TickSecondaryLabel, TickSecondaryLabelSettings } from '../types';

const useSecondaryLabelsStyles = makeStyles()(({ palette }) => ({
	label: {
		color: palette.gray[800],
	},
}));
export const useSecondaryLabels = (props: TickSecondaryLabel & TickSecondaryLabelSettings = {}) => {
	const { divider = '', inline = false, isVisible = true } = props;

	const { classes } = useSecondaryLabelsStyles();

	return ({ formattedValue, x, y, ...rest }: TickRendererProps) => {
		const yOffset = 12;
		const labelYOffset = y - yOffset / 2;
		const secondaryLabelYOffset = y + yOffset;
		const value = (formattedValue as string).split('|');
		const primaryValue = value[0];
		const secondaryValue = value[1];

		return inline ? (
			<g>
				<text x={x} y={y} {...rest}>
					{primaryValue}
					{isVisible && divider && <tspan className={classes.label}>{divider}</tspan>}
					{isVisible && <tspan className={classes.label}>{secondaryValue}</tspan>}
				</text>
			</g>
		) : (
			<g>
				<text x={x} y={labelYOffset} {...rest}>
					{primaryValue}
				</text>
				{isVisible && (
					<text x={x} y={secondaryLabelYOffset} {...rest} className={classes.label}>
						{secondaryValue}
					</text>
				)}
			</g>
		);
	};
};
