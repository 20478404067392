import { makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { mediumScreenWidth } from '~definitions/json/mobile.json';

export const useBodyStyles = makeStyles<{ noPadding: boolean }>()((theme, props) => {
	const { breakpoints, constructs, palette, spacing, mode } = theme;
	const { noPadding } = props;

	return {
		body: {
			backgroundColor: ifFeature('encore', palette.gray[50], palette.common.white),
			borderRadius: ifFeature('encore', '16px'),
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			overflow: ifFeature('encore', 'hidden'),
			width: ifFeature('encore', 'auto', '100%'),

			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				backgroundColor: constructs.surface.neutral.inverted,
				padding: spacing(0.5),
			}),
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: '1',
			overflow: 'auto',
			padding: noPadding && mode !== ModeOptions.Dye ? '0' : '16px',
			[breakpoints.down('md')]: {
				padding: noPadding ? '0' : '8px',
			},
		},
		roundBottomCorners: ifFeature(
			'encore',
			{
				// This is done so custom footers have rounded bottom corners when the body has padding
				borderBottomLeftRadius: ifFeature('encore', constructs.radius.xSmall),
				borderBottomRightRadius: ifFeature('encore', constructs.radius.xSmall),
				minHeight: 'fit-content', // Keeps the footer from being cut off when the body has a lot of content
				overflow: 'hidden',
			},
			{}
		),
		roundTopCorners: ifFeature(
			'encore',
			{
				// This is done so custom headers such as the ActionBanner can have rounded top corners when the body has padding
				borderTopLeftRadius: constructs.radius.xSmall,
				borderTopRightRadius: constructs.radius.xSmall,
				minHeight: 'fit-content', // Keeps the header from being cut off when the body has a lot of content
				overflow: 'hidden',
			},
			{}
		),
	};
});
