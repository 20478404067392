export function cursorIsAtBeginningOf(element) {
	if (!element) {
		return false;
	}

	const { selectionStart, selectionEnd } = element;

	return selectionStart === 0 && selectionEnd === 0;
}

export function cursorIsAtEndOf(element) {
	if (!element) {
		return false;
	}

	const {
		value: { length },
		selectionStart,
		selectionEnd,
	} = element;

	return selectionStart === length && selectionEnd === length;
}

export function focusElement(element) {
	element.focus();
}

export function hasKeyModifier(keyEvent = {}) {
	return keyEvent.ctrlKey || keyEvent.metaKey;
}

export function insideViewport(element, viewport = window) {
	const windowViewport = {
		bottom: window.innerHeight,
		left: 0,
		right: window.outerHeight,
		top: 0,
	};

	const elementRect = element.getBoundingClientRect();
	const viewportRect = viewport === window ? windowViewport : viewport.getBoundingClientRect();

	return (
		elementRect.bottom <= viewportRect.bottom &&
		elementRect.left >= viewportRect.left &&
		elementRect.right <= viewportRect.right &&
		elementRect.top >= viewportRect.top
	);
}

export function isFocused(element) {
	return !!element && (element === document.activeElement || element.contains(document.activeElement));
}

export function isMenuDescendant(menuId) {
	return element => {
		return !!(element && element.closest(`[data-menu-id="${menuId}"]`));
	};
}

export function isMenuFocused(menuId) {
	return document.activeElement && isMenuDescendant(menuId)(document.activeElement);
}

export function waitForPaint(cb) {
	window.requestAnimationFrame(() => {
		window.requestAnimationFrame(() => {
			cb();
		});
	});
}
