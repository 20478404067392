import { ifFeature } from '@bamboohr/utils/lib/feature';
import clsx from 'classnames';
import React from 'react';
import { IconV2 } from '~components/icon-v2';
import { useStyles } from './hidden-page.styles';

/**
 * HiddenPage - a hidden page node
 *
 * @return {node}  returns the ... in a pagination list
 */
export default function HiddenPage({ className }) {
	const { classes } = useStyles({ isHidden: true });

	return (
		<li className={clsx(className, classes.root)}>{ifFeature('encore', <IconV2 name="ellipsis-regular" size={20} />, '...')}</li>
	);
}
