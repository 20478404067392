import { FieldValues, UseFormRegister } from 'react-hook-form';
import { getFields } from './get-fields';
import { ControlledFormField, FormFieldDefinition } from './types';
import { get } from 'lodash';

export const registerControlledField = <T extends FieldValues>(
	fieldDefinition: FormFieldDefinition<T>,
	register: UseFormRegister<T>
): ControlledFormField<T> => {
	const fields = getFields([fieldDefinition], register);
	return get(fields, fieldDefinition.name);
};
