import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { useContext } from 'react';

import { DatePickerContext } from '../date-picker-provider';
import { DatePickerUtilsLuxon } from '../date-picker-utils';
import { DatePickerContextValue, DatePickerUtilsProviderProps } from '../types';

export function DatePickerUtilsProvider({ children }: DatePickerUtilsProviderProps): JSX.Element {
	const { formatType, locale } = useContext<DatePickerContextValue>(DatePickerContext);

	return (
		<LocalizationProvider
			adapterLocale={locale}
			dateAdapter={DatePickerUtilsLuxon}
			dateFormats={{ fullDate: formatType, isoDate: 'yyyy-MM-dd' }}
		>
			{children}
		</LocalizationProvider>
	);
}
