import { modelTypesThatRevalidateOnChange, modelTypesThatValidateOnBlur } from '../constants';
import { FormFactoryField } from '../types/field.types';
import { ValidationField } from '../validation';

export function getFieldFromModel<T extends FormFactoryField>(model: T): ValidationField {
	const { pattern, required, type } = model;

	const validateOn: 'blur' | 'submit' = modelTypesThatValidateOnBlur.includes(type) ? 'blur' : 'submit';
	const revalidateOn: 'change' | 'blur' = modelTypesThatRevalidateOnChange.includes(type) ? 'change' : 'blur';

	return {
		getValue: ([{ value }]) => value as unknown,
		pattern,
		required,
		revalidateOn,
		validateOn,
	};
}
