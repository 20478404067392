import { cloneDeepWith, isNumber, last, startsWith } from 'lodash';
import { cloneElement, isValidElement } from 'react';

import { getFirstInteractiveItem, hasItem, isAnchored, flattenGroups, searchItems } from '../item';

/**
 * @param {Object} params
 * @param {any} params.activeItem
 * @param {any[]} [params.items]
 * @param {string} params.searchText
 * @param {any[]} [params.selectedItems]
 */
export function getActiveItem({ activeItem, items = [], searchText, selectedItems = [] }) {
	const remainingItems = searchItems(items || [], searchText);

	let actuallyActiveItem = null;

	if (hasItem(remainingItems, activeItem)) {
		actuallyActiveItem = activeItem;
	} else if (searchText) {
		actuallyActiveItem = getFirstInteractiveItem(remainingItems);
	} else if (activeItem === null && selectedItems.length) {
		actuallyActiveItem = last(selectedItems);
	}

	return actuallyActiveItem;
}

export function shouldShowSearch(showSearch = 'auto', searchThreshold, items) {
	if (showSearch === 'auto') {
		const nonAnchorItems = (items || []).filter(item => !isAnchored(item));
		const flattenedItems = flattenGroups(nonAnchorItems);
		return isNumber(searchThreshold) && flattenedItems.length >= searchThreshold;
	}

	return showSearch === 'always';
}

export function startsWithCaseInsensitive(haystack = '', needle = '') {
	return startsWith(haystack.toLowerCase(), needle.toLowerCase());
}

export function cloneObject(value) {
	return cloneDeepWith(value, _value => {
		if (isValidElement(_value)) {
			return cloneElement(_value);
		}
	});
}
