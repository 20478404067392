import { allowedFileTypes } from '../constants';
import { SetCropBoxCoords, SetImageSrc, SetErrorMessage } from './utils.types';
import { handleFileSelection } from './handle-file-selection';

export const handleFileInputChange = (
	event: React.SyntheticEvent<HTMLInputElement>,
	setCropBoxCoords: SetCropBoxCoords,
	setImageSrc: SetImageSrc,
	setErrorMessage: SetErrorMessage
) => {
	const input = event.target as HTMLInputElement;
	if (input.files) {
		const file = input.files[0];
		const extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
		if (allowedFileTypes.includes(extension)) {
			handleFileSelection([file], setCropBoxCoords, setImageSrc);
		} else {
			const disallowedFileTypeMessage = window.jQuery
				? $.__('This file type is not allowed:')
				: 'This file type is not allowed:';
			setErrorMessage(`${disallowedFileTypeMessage} ${extension}`);
		}
	}
};
