import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, spacing }) => {
	return {
		root: {
			backgroundColor: ifFeature('encore', undefined, palette.gray[100]),
			boxSizing: 'border-box',
			display: ifFeature('encore', 'flex', 'block'),
			flexDirection: ifFeature('encore', 'column'),
			gap: ifFeature('encore', spacing(0.25)),
			minHeight: '100%',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			padding: ifFeature('encore', undefined, '14px 24px'),
			width: ifFeature('encore', 264, 262),
		},
	};
});
