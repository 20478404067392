import { difference, uniqueId } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FileUploadFailure, FileUploadFileId, FileUploadFileMetadata } from './types';

export function convertInitialFilesToFilesById(initialFiles: FileUploadFileMetadata[] = []): Record<string, FileUploadFileMetadata> {
	return initialFiles.reduce((memo, file) => {
		const temporaryId = uniqueId();
		memo[temporaryId] = file;
		return memo;
	}, {});
}

export function getPlaceholderText(canSelectMultiple = false): string {
	if (window.jQuery) {
		return canSelectMultiple ? $.__('No files selected') : $.__('No file selected');
	}

	return canSelectMultiple ? 'No files selected' : 'No file selected';
}

export function getToggleText(canSelectMultiple = false): string {
	if (window.jQuery) {
		return canSelectMultiple ? ifFeature('encore', $.__('Choose Files'), $.__('Choose File(s)')) : $.__('Choose File');
	}

	return canSelectMultiple ? ifFeature('encore', 'Choose Files', 'Choose File(s)') : 'Choose File';
}

export function getMetadataFromFile(file: File): FileUploadFileMetadata {
	return {
		name: file.name,
		type: file.type,
		size: file.size,
	};
}

export function getPromiseRejections(promises: Promise<void>[]): Promise<Array<FileUploadFailure>> {
	return new Promise<FileUploadFailure[]>((resolve, reject) => {
		const rejections: Array<FileUploadFailure> = [];

		Promise.all(promises.map(promise => promise.catch(rejection => rejections.push(rejection)))).then(() => {
			resolve(rejections);
		}, reject);
	});
}

export function getTemporaryIds(nativeFiles: File[], temporaryIdsByNativeFile: WeakMap<File, string>): Array<string | undefined> {
	return nativeFiles.map<string | undefined>(nativeFile => temporaryIdsByNativeFile.get(nativeFile));
}

export function getUploadErrorMessage(): string {
	return window.jQuery && $.__
		? $.__('One or more files failed to upload successfully.')
		: 'One or more files failed to upload successfully.';
}

export function mapMetadataByTemporaryId(
	nativeFiles: File[],
	temporaryIdsByNativeFile: WeakMap<File, string>
): Record<string, FileUploadFileMetadata> {
	return nativeFiles.reduce<Record<string, FileUploadFileMetadata>>((memo, nativeFile) => {
		const temporaryId = temporaryIdsByNativeFile.get(nativeFile);
		if (temporaryId) {
			memo[temporaryId] = getMetadataFromFile(nativeFile);
		}
		return memo;
	}, {});
}

export function mapTemporaryIdsByNativeFile(nativeFiles: File[] = []): WeakMap<File, string> {
	return nativeFiles.reduce<WeakMap<File, string>>((memo, nativeFile) => {
		const temporaryId = uniqueId();
		memo.set(nativeFile, temporaryId);
		return memo;
	}, new WeakMap());
}

export function omit(keys: string[] = [], obj: Record<string, unknown>): Record<string, unknown> {
	const copy = { ...obj };

	keys.forEach(key => delete copy[key]);

	return copy;
}

export function getUploadedFileIds(
	filesById: Record<string, FileUploadFileMetadata>,
	allFileIds: string[],
	pendingFileIds: string[]
): Array<FileUploadFileId> {
	const differentIds = difference(allFileIds, pendingFileIds);

	return differentIds
		.map<FileUploadFileId | undefined>(id => {
			const file = filesById[id];

			return file && file.id ? file.id : undefined;
		})
		.filter((id): id is FileUploadFileId => {
			return typeof id === 'string' || typeof id === 'number';
		});
}

export function checkIfPdfFileIsEncrypted(file: File): Promise<boolean> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = function () {
			const fileBlob = new Blob([reader.result as BlobPart], { type: 'application/pdf' });
			fileBlob
				.text()
				.then(x => {
					const isEncrypted =
						x.includes('Encrypt') || x.substring(x.lastIndexOf('<<'), x.lastIndexOf('>>')).includes('/Encrypt');
					resolve(isEncrypted);
				})
				.catch(error => {
					reject(new DOMException('Problem parsing input file: ', error));
				});
		};

		reader.onerror = () => {
			reader.abort();
			reject(new DOMException('Problem reading input file.'));
		};
	});
}
