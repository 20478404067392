/* @startCleanup encore */
import { Tabs as MuiTabs, TabScrollButtonProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import React, { FC, isValidElement, PropsWithChildren, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { Dropdown } from '~components/dropdown';
import { ItemType } from '../types';
import { styles } from './tabs.styles';
import { TabsProps } from './types/tabs-props';
import { computeOverflowIndex, extractTextFromChildren } from './utils';

/**
 * Tabs organize and allow navigation between groups of content that are related and at the same level of hierarchy.
 * This component is a wrapper around Material-UI Tabs @link https://material-ui.com/components/tabs/#tabs
 */
export const TabsJade = withStyles(styles)(
	({
		ariaLabel = '',
		classes = {},
		value,
		variant = 'scrollable',
		onChange,
		size = 'small',
		labelSize = 'standard',
		scrollButtons = true,
		dark = false,
		onChangeOverflowIndex,
		children,
		overflowTabsDropdown = false,
		dropdownLabel = window.jQuery ? $.__('More') : 'More',
		fixedDropdownItems,
		fixedDropdownPosition = false,
	}: PropsWithChildren<TabsProps>) => {
		const handleChange = (event, value, isDropdownValue = false) => {
			setIsSelectedValueFromDropdown(isDropdownValue);
			onChange(value, event);
		};
		const scrollButtonProps = {
			disableRipple: true,
		} as unknown as TabScrollButtonProps;
		const isContained = size === 'medium' || size === 'large';
		const {
			contained,
			darkContained,
			darkStandard,
			labelSizeLarge,
			modeFill,
			modeLine,
			root,
			rootWithDropdown,
			rootWithFixedDropdown,
			rootContained,
			sizeCompact,
			sizeLarge,
			standard,
			...restClasses
		} = classes;
		const [overflowIndex, setOverflowIndex] = useState<number | undefined>(undefined);
		const [isSelectedValueFromDropdown, setIsSelectedValueFromDropdown] = useState<boolean>(false);

		const visibleTabsList: ReactNode[] = Array.isArray(children) ? children?.slice(0, overflowIndex) : [];
		const overflowTabsList: ReactNode[] = overflowIndex && Array.isArray(children) ? children?.slice(overflowIndex) : [];

		const dropdownRef = useRef<HTMLDivElement>(null);
		const rootElementRef = useRef<HTMLDivElement>(null);
		const tabsContainerRef = useRef<HTMLElement | null>();
		const tabsContainerCloneRef = useRef<HTMLElement | null>();

		const [tabsWrapperScrollWidth, setTabsWrapperScrollWidth] = useState<number>(0);
		// List of scroll width values of all tabs
		const [tabElementsScrollWidthList, setTabElementsScrollWidthList] = useState<number[]>([]);
		// Gap between each tab in pixels
		const [tabElementsGap, setTabElementsGap] = useState<number>(0);
		const [shouldCheckForOverflowTabs, setShouldCheckForOverflowTabs] = useState<boolean>(false);

		const displayDropdown = (overflowTabsDropdown && !isEmpty(overflowTabsList)) || !isEmpty(fixedDropdownItems);
		const debouncedResizeCallback = debounce(() => setShouldCheckForOverflowTabs(true), 100);

		useEventListener('resize', debouncedResizeCallback);

		useLayoutEffect(() => {
			tabsContainerRef.current = rootElementRef.current?.querySelector('.MuiTabs-flexContainer');
			tabsContainerCloneRef.current = tabsContainerRef.current?.cloneNode(true) as HTMLElement;
			tabsContainerCloneRef.current.style.visibility = 'hidden';
			tabsContainerCloneRef.current.style.position = 'absolute';
			tabsContainerRef.current?.parentElement?.appendChild(tabsContainerCloneRef.current);
		}, []);

		useLayoutEffect(() => {
			const tabsContainerClone = tabsContainerCloneRef.current;
			if (overflowTabsDropdown && tabsContainerClone) {
				if (tabsContainerClone?.children?.length) {
					const measureTabs = () => {
						setTabElementsScrollWidthList(Array.from(tabsContainerClone.children)?.map(element => element?.scrollWidth));
						setTabElementsGap(parseInt(window.getComputedStyle(tabsContainerClone)?.gap, 10) || 0);
						setTabsWrapperScrollWidth(tabsContainerClone.scrollWidth ?? Infinity);
						setShouldCheckForOverflowTabs(true);
					};
					measureTabs();
					// If fonts load after we measure, our measurements may be off.
					document.fonts.ready.then(() => measureTabs()).catch(console.error);
				}
			}
		}, [overflowTabsDropdown]);

		useLayoutEffect(() => {
			if (overflowTabsDropdown && shouldCheckForOverflowTabs && tabsWrapperScrollWidth && tabElementsScrollWidthList) {
				const computedOverflowIndex = computeOverflowIndex(
					tabsContainerRef?.current?.offsetWidth || 0,
					tabsWrapperScrollWidth,
					tabElementsScrollWidthList,
					tabElementsGap
				);
				setOverflowIndex(computedOverflowIndex);
				setShouldCheckForOverflowTabs(false);
				onChangeOverflowIndex?.(computedOverflowIndex);
			}
		}, [
			onChangeOverflowIndex,
			overflowTabsDropdown,
			setShouldCheckForOverflowTabs,
			shouldCheckForOverflowTabs,
			tabElementsGap,
			tabElementsScrollWidthList,
			tabsWrapperScrollWidth,
		]);

		return (
			<MuiTabs
				aria-label={ariaLabel}
				classes={restClasses}
				className={classnames({
					[root as string]: true,
					[rootWithDropdown as string]: displayDropdown,
					[rootWithFixedDropdown as string]: fixedDropdownPosition,
					[sizeLarge as string]: size === 'large',
					[rootContained as string]: isContained,
					[standard as string]: variant === 'standard',
					[labelSizeLarge as string]: labelSize === 'large' || size === 'medium' || size === 'large',
					[contained as string]: isContained,
					[darkContained as string]: dark && isContained,
					[darkStandard as string]: dark && !isContained,
					[sizeCompact as string]: size === 'compact',
				})}
				onChange={handleChange}
				ref={rootElementRef}
				scrollButtons={scrollButtons}
				selectionFollowsFocus={false}
				TabScrollButtonProps={scrollButtonProps}
				value={isSelectedValueFromDropdown ? false : (value as unknown)}
				variant={variant}
			>
				{scrollButtons ? children : visibleTabsList}
				<div ref={dropdownRef} style={{ padding: '0 7px 0 20px', visibility: displayDropdown ? undefined : 'hidden' }}>
					<Dropdown
						ButtonProps={{
							color: 'secondary',
							size: 'small',
						}}
						items={[
							...(overflowTabsList?.map((tab: ReactNode, index) => {
								if (isValidElement(tab) && !!tab?.props) {
									const { disabled, label: tabLabel, icon: tabIcon, value: tabValue } = tab.props;
									const labelText = extractTextFromChildren(tabLabel);
									return {
										text: labelText || '',
										...(tabIcon ? { icon: tabIcon } : {}),
										id: String(tabValue)?.replace(' ', '-') || index,
										key: tabValue || index,
										value: tabValue || index,
										isDisabled: disabled || false,
									};
								}
								return {};
							}) as ItemType[]),
							...(fixedDropdownItems && !isEmpty(fixedDropdownItems) ? [...fixedDropdownItems] : []),
						]}
						onSelect={value => handleChange(null, value, true)}
						type="text"
					>
						{dropdownLabel}
					</Dropdown>
				</div>
			</MuiTabs>
		);
	}
) as FC<PropsWithChildren<TabsProps>>;
/* @endCleanup encore */
