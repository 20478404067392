import React from 'react';
import { CalendarBaseButtonNav } from './calendar-base-button-nav';
import { CalendarBaseNavProps } from './calendar-base-nav.types';

export const CalendarBaseNav = (props: CalendarBaseNavProps) => {
	const { navigation } = props;

	switch (navigation) {
		case 'button':
			return <CalendarBaseButtonNav {...props} />;
		default:
			return null;
	}
};
