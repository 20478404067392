// eslint-disable-next-line no-use-before-define
import React, { ReactNode, useState } from 'react';
import { readableContext, ReadableModes } from './readable-context';

interface ReadableProviderProps {
	initialMode?: ReadableModes;
	children: (({ isReadable, setMode }: { isReadable: boolean; setMode: (value: ReadableModes) => void }) => ReactNode) | ReactNode;
}

export const ReadableProvider = ({ children, initialMode = 'readable' }: ReadableProviderProps) => {
	const [mode, setMode] = useState<ReadableModes>(initialMode);

	const value = {
		isReadable: mode === 'readable',
		setMode,
	};

	return (
		<readableContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</readableContext.Provider>
	);
};
