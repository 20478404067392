import { useRef, useEffect } from 'react';
import { Resizer } from './resizer';
import { UseResizerArgs } from '../types';

export function useResizer({ elementRef, breakpoints, onResize, carouselButtonWidth }: UseResizerArgs) {
	const instanceRef = useRef(new Resizer());

	instanceRef.current.setButtonWidth(carouselButtonWidth);
	instanceRef.current.setOnResize(onResize);
	instanceRef.current.setBreakpoints(breakpoints);

	useEffect(() => {
		const resizerInstance = instanceRef.current;
		resizerInstance.setElement(elementRef.current);

		return () => {
			resizerInstance.disconnect();
		};
	}, [elementRef]);

	return instanceRef.current;
}
