import { FixCollisionResult, CollisionDetails } from '../../types';

/**
 * Provides padding between the vessel and the container.
 */
export const ADDITIONAL_CONTAINER_SPACE = 4;

export function constrain(collisionDetails: CollisionDetails): FixCollisionResult {
	const { collisions, locations, offsets: ogOffsets } = collisionDetails;
	const offsets = { ...ogOffsets };

	// Only constrains height due to min-width on vessel that I don't want to mess with for backwards-compatibility reasons, but could potentially constrain width as well.
	if (locations.vessel.vertical === 'top' && collisions.includes('bottom')) {
		offsets.vessel.bottom = offsets.container.bottom - ADDITIONAL_CONTAINER_SPACE;
	} else if (locations.vessel.vertical === 'bottom' && collisions.includes('top')) {
		offsets.vessel.top = Math.min(offsets.anchor.top, offsets.container.top + ADDITIONAL_CONTAINER_SPACE);
		offsets.vessel.bottom = offsets.anchor.top;
	}

	return { offsets };
}
