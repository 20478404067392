import { DatePickerRangeError } from '../../types';

export const getBothDatesMissingError = (
	startInputValue: string,
	endInputValue: string,
	required: boolean
): DatePickerRangeError | undefined => {
	if (startInputValue && endInputValue) {
		return {
			error: 'invalid',
			type: 'range',
		};
	}

	if (startInputValue) {
		return {
			error: 'invalid',
			type: 'start',
		};
	}

	if (endInputValue) {
		return {
			error: 'invalid',
			type: 'end',
		};
	}

	if (required) {
		return {
			error: 'required',
			type: 'range',
		};
	}
};
