import { createStyles } from '@mui/styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { dyeColors, ModeOptions } from '~styles';

const getStyles = theme => {
	const { borderRadiuses, mixins, palette, spacing, typography, mode } = theme;

	const sharedHeaderAndCell = {
		boxSizing: 'border-box',
		position: 'relative',
		textAlign: 'left',
		verticalAlign: 'top',
	};

	const sharedHeaderAndCellStickyLeft = {
		position: 'sticky',
		left: 0,
	};

	const sharedHeaderAndCellStickyLeftBefore = {
		backgroundColor: palette.gray[200],
		content: '""',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: '-1px',
		width: '2px',
	};

	const sharedHeaderAndCellStickyLeftScreenAfter = {
		background: `linear-gradient(90deg, ${palette.common.white} 0%, transparent 100%)`,
		boxSizing: 'border-box',
		content: '""',
		position: 'absolute',
		top: 0,
		right: '-46px',
		bottom: '-1px',
		width: '46px',
		pointerEvents: 'none',
		opacity: 1,
	};

	const showStickyRightContentOnRowHover = {
		position: 'sticky',
		right: 0,
		zIndex: 100,
		'&:before': {
			background: `linear-gradient(90deg, transparent 0, ${palette.common.white} 40px)`,
			content: '""',
			position: 'absolute',
			width: 'calc(100% + 40px)',
			top: 0,
			bottom: 0,
			right: 0,
		},
	};

	return createStyles({
		'@keyframes opacityIn': {
			from: { opacity: 0 },
			to: { opacity: 1 },
		},
		root: {
			...typography.medium,
			'--fab-table-sticky-group-header-top': '44px',
			boxSizing: 'border-box',
			width: '100%',
			borderSpacing: 0,
			gridArea: 'table',
			isolation: 'isolate',
			fontVariantNumeric: props => (props.tabularNums === true ? 'tabular-nums' : 'inherit'),
			'&$fixedLayout': {
				tableLayout: 'fixed',
			},
			'& > *:not(&$tbodyForLoader)': {
				transition: 'opacity 0.15s ease-in-out',
			},
			'&$tableLoading': {
				position: 'relative',
				'& > *:not(&$tbodyForLoader)': {
					opacity: 0.4,
				},
			},
			'&$tableWithRowHover $row:hover, &$tableWithRowHover $row:focus-within': {
				backgroundColor: palette.gray[100],
			},

			'&$tableWithZebraStripes $row:nth-child(even):not($rowSelected):not(:hover)': {
				backgroundColor: mixins.alpha(palette.grey[50], 0.5),
			},
		},
		fixedLayout: {},
		tbodyForLoader: {},
		tableLoading: {},
		tableWithRowHover: {},
		tableWithZebraStripes: {},
		caption: {
			fontSize: 0,
			height: 0,
			overflow: 'hidden',
		},
		wrap: {
			borderRadius: spacing(1),
			display: 'grid',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'&:focus': {
				boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`,
			},
		},
		wrapOverflow: {
			overflow: 'auto',
		},
		innerWrap: {
			display: 'grid',
			gridTemplateColumns: '0 auto 0',
			gridTemplateRows: 'auto',
			// Yes... the " inside ' is necessary...
			gridTemplateAreas: '"left-scroll-screen table right-scroll-screen"',
		},
		stickyIndicators: {
			position: 'sticky',
			display: 'flex',
			justifyContent: 'space-between',
			top: 0,
			height: 0,
			opacity: 0,
			pointerEvents: 'none',
			// Enable z-index when deving on intersection observer. Also comment out opacity property
			// zIndex: 1000,
		},
		stickyIndicator: {
			top: 0,
			width: '10px',
			height: '10px',
			backgroundColor: 'red',
			position: 'sticky',
		},
		loaderCell: {
			padding: 0,
		},
		loader: {
			position: 'absolute',
			top: 0,
			right: 0,
			left: 0,
			bottom: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		leftScrollIndicator: {
			gridArea: 'left-scroll-screen',
			alignSelf: 'stretch',
			position: 'sticky',
			left: 0,
			pointerEvents: 'none',
			zIndex: 200,
			'&:before': {
				// borderLeft: `2px solid ${ palette.gray[400] }`,
				content: '""',
				height: '100%',
				position: 'absolute',
				top: 0,
				bottom: 0,
				width: '48px',
			},
		},
		leftScrollIndicatorWithScreen: {
			'&:before': {
				background: `linear-gradient(90deg, ${palette.common.white} 0%, transparent 100%)`,
			},
		},
		rightScrollIndicator: {
			gridArea: 'right-scroll-screen',
			alignSelf: 'stretch',
			position: 'sticky',
			right: 0,
			pointerEvents: 'none',
			zIndex: 200,
			'&:before': {
				// borderRight: `2px solid ${ palette.gray[400] }`,
				content: '""',
				height: '100%',
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				width: '48px',
			},
		},
		rightScrollIndicatorWithScreen: {
			'&:before': {
				background: `linear-gradient(270deg, ${palette.common.white} 0%, transparent 100%)`,
			},
		},
		row: {
			borderBottom: `1px solid ${palette.grey[100]}`,
			transition: 'background-color 0.15s ease-in-out',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'&:hover $cellMask': {
				opacity: 1,
			},
			// START FF Fix
			// The following two rules have to be split up because otherwise FF chokes on the :has and ruins the entire selector (including the selector before the comma)
			// if you make changes to one, you have to make changes to the other
			'&:hover $stickyRight': showStickyRightContentOnRowHover,
			'&:has([aria-expanded="true"]) $stickyRight': showStickyRightContentOnRowHover,
			// End FF Fix

			'&$rowEmphasized': {
				fontWeight: typography.fontWeightSemibold,
			},
			'&$rowHighlighted, &$rowSelected, &$rowHighlighted > $stickyLeft, &$rowSelected > $stickyLeft': {
				backgroundColor: palette.primary.lightest,
			},
			'&$rowCollapsible td:first-child': {
				paddingLeft: '34px', // line up with text of collapsible parent, which has 16px left padding + 10px icon + 8px gap
			},
			'&$removeBottomBorder': {
				borderBottom: 'none',
			},
		},
		removeBottomBorder: {
			'&:last-of-type': {
				borderBottom: 'none',
			},
		},
		rowEmphasized: {},
		rowHighlighted: {},
		rowSelected: {},
		rowCollapsible: {},
		header: {
			...sharedHeaderAndCell,
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.lighter : palette.grey[100],
			color: palette.grey[800],
			fontWeight: typography.fontWeightSemibold,
			padding: spacing(1.5, 2),
			transition: 'background-color 0.15s ease-in-out',
			borderColor: 'transparent',
			'&:first-of-type': {
				borderRadius: `${borderRadiuses[200]} 0 0 ${borderRadiuses[200]}`,
			},
			'&:last-of-type': {
				borderRadius: `0 ${borderRadiuses[200]} ${borderRadiuses[200]} 0`,
			},
			'&:only-of-type': {
				// If there is only one column, the border radius should be applied to all corners
				borderRadius: borderRadiuses[200],
			},
			'&$minimalRightPadding': {
				paddingRight: spacing(0.5),
			},
			'&$noVerticalPadding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'&$noHorizontalPadding': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'&$alignCenter': {
				textAlign: 'center',
			},
			'&$alignRight': {
				textAlign: 'right',
			},
			'$headless &': {
				borderBottom: `1px solid ${palette.grey[100]}`,
				fontSize: 0,
				height: 0,
				lineHeight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				pointerEvents: 'none',
			},
			'$headless:has(tr:first-child > $cellGroup) &': {
				borderBottom: 'none',
			},
			'&$stickyTop': {
				position: 'sticky',
				top: 0,
				zIndex: 120,
			},
			'&$stickyLeft': {
				...sharedHeaderAndCellStickyLeft,
				zIndex: 130,
			},
			'&$stickyLeftBorder:before': {
				...sharedHeaderAndCellStickyLeftBefore,
			},
			'&$stickyLeftScreen:after': {
				...sharedHeaderAndCellStickyLeftScreenAfter,
				bottom: 0,
			},
			'&$sortableHeader': {
				paddingRight: spacing(3),
				'&:hover': {
					cursor: 'pointer',
				},
				'&:not(&$whiteHeaderVariant):hover': {
					zIndex: 1,
					'&:before': {
						animation: '$opacityIn 0.15s',
						backgroundColor: palette.gray[200],
						borderRadius: borderRadiuses[200],
						content: '""',
						display: 'block',
						height: '100%',
						left: 0,
						pointerEvents: 'none',
						position: 'absolute',
						top: 0,
						width: '100%',
						zIndex: -1,
					},
				},
			},
			'&$whiteHeaderVariant': {
				borderBottom: `1px solid ${palette.grey[300]}`,
				backgroundColor: palette.common.white,
			},
		},
		headless: {
			'$root&': {
				'--fab-table-sticky-group-header-top': '0px',
			},
		},
		whiteHeaderVariant: {},
		// @startCleanup encore
		noBottomBorderRadius: {},
		// @endCleanup encore
		sortableHeader: {},
		sortIcon: {
			display: 'inline-block',
			position: 'relative',
			'& > svg:last-child': {
				position: 'absolute',
				right: 0,
				top: '50%',
				transform: 'translate(calc(100% + 8px), -50%)',
			},
		},
		cell: {
			...sharedHeaderAndCell,
			color: palette.grey[1000],
			padding: spacing(2),
			'&$minimalRightPadding': {
				paddingRight: spacing(0.5),
			},
			'&$noVerticalPadding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'&$noHorizontalPadding': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'&$alignCenter': {
				textAlign: 'center',
			},
			'&$alignRight': {
				textAlign: 'right',
			},
			'&$verticalAlign': {
				verticalAlign: 'middle',
			},
			'&$stickyLeft': {
				...sharedHeaderAndCellStickyLeft,
				background: palette.common.white,
				backgroundClip: 'padding-box',
				transition: 'background-color 0.15s ease-in-out', // animation for background when selected/highlighted
				zIndex: 90,
			},
			'&$stickyLeftBorder:before': {
				...sharedHeaderAndCellStickyLeftBefore,
			},
			'&$stickyLeftScreen:after': {
				...sharedHeaderAndCellStickyLeftScreenAfter,
				bottom: 0,
			},
			'&$cellGroup': {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : undefined,
				borderBottom: 'none',
				color: palette.grey[800],
				fontWeight: typography.fontWeightSemibold,
				padding: 0,
				paddingTop: spacing(0.5),
			},
			'&$noBottomBorder': {
				borderBottom: 'none',
			},
		},
		collapsibleRowIcon: {
			position: 'absolute',
			left: 0,
			top: '50%',
			transform: 'translateY(-50%)',
		},
		minimalRightPadding: {},
		noVerticalPadding: {},
		noHorizontalPadding: {},
		alignCenter: {},
		alignRight: {},
		stickyTop: {},
		stickyLeft: {},
		stickyLeftBorder: {},
		stickyLeftScreen: {},
		verticalAlign: {},
		cellGroup: {
			'$headless tr:first-child > &': {
				paddingTop: 0,
			},
		},
		cellGroupSticky: {
			position: 'sticky',
			top: props =>
				props.stickyGroupHeaderTop !== undefined ? props.stickyGroupHeaderTop : 'var(--fab-table-sticky-group-header-top)',
			zIndex: 110,
			left: props => (props.stickyColumn !== undefined ? 0 : undefined),
		},
		noBottomBorder: {},
		stickyRight: {},
		mutedSubCell: {
			opacity: 0.8,
		},
		cellActions: {
			alignItems: 'center',
			display: 'flex',
			height: '14px',
			justifyContent: 'flex-end',
			position: 'relative',
			top: '4px',
		},
		cellMask: {
			transition: `opacity 0.15s ease-in-out`,
			'&:not(:focus-within)': {
				opacity: 0,
			},
		},
		group: {
			...typography.small,
			alignItems: 'center',
			backgroundColor: palette.gray[100],
			borderRadius: borderRadiuses[200],
			color: palette.gray[700],
			display: 'flex',
			padding: spacing(0.75, 2),
		},
		groupIcon: {
			alignItems: 'center',
			display: 'inline-flex',
			fill: palette.grey[600],
			height: 0,
			justifyContent: 'center',
			marginRight: spacing(1),
		},
		groupContent: {
			flex: 1,
		},
		tableExpandIcon: {
			alignItems: 'center',
			display: 'flex',
			fill: palette.grey[800],
			'& > svg': {
				width: 10, // <IconV2 size={10}> looks too small, so we do size={16} and constrain the width here instead
			},
		},
		tableExpandIconExpanded: {
			transform: 'rotate(90deg)',
		},
	});
};

// @startCleanup encore
const getJadeStyles = theme => {
	const { mixins, palette, typography, mode } = theme;

	const sharedHeaderAndCell = {
		boxSizing: 'border-box',
		fontSize: typography.fabricFontSize?.('medium'),
		position: 'relative',
		textAlign: 'left',
		verticalAlign: 'top',
	};

	const sharedHeaderAndCellStickyLeft = {
		position: 'sticky',
		left: 0,
	};

	const sharedHeaderAndCellStickyLeftBefore = {
		backgroundColor: palette.gray[400],
		content: '""',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: '-1px',
		width: '2px',
	};

	const sharedHeaderAndCellStickyLeftScreenAfter = {
		background: `linear-gradient(90deg, ${palette.common.white} 0%, transparent 100%)`,
		boxSizing: 'border-box',
		content: '""',
		position: 'absolute',
		top: 0,
		right: '-46px',
		bottom: '-1px',
		width: '46px',
		pointerEvents: 'none',
		opacity: 1,
	};

	const showStickyRightContentOnRowHover = {
		position: 'sticky',
		right: 0,
		zIndex: 100,
		'&:before': {
			background: `linear-gradient(90deg, transparent 0, ${palette.common.white} 40px)`,
			content: '""',
			position: 'absolute',
			width: 'calc(100% + 40px)',
			top: 0,
			bottom: 0,
			right: 0,
		},
	};

	return createStyles({
		'@keyframes opacityIn': {
			from: { opacity: 0 },
			to: { opacity: 1 },
		},
		root: {
			'--fab-table-sticky-group-header-top': '44px',
			boxSizing: 'border-box',
			width: '100%',
			borderSpacing: 0,
			lineHeight: 'normal',
			gridArea: 'table',
			'&$fixedLayout': {
				tableLayout: 'fixed',
			},
			'& > *:not(&$tbodyForLoader)': {
				transition: 'opacity 0.15s ease-in-out',
			},
			'&$tableLoading': {
				position: 'relative',
				'& > *:not(&$tbodyForLoader)': {
					opacity: 0.4,
				},
			},
			'&$tableWithRowHover $row:hover, &$tableWithRowHover $row:focus-within': {
				backgroundColor: palette.gray[100],
			},

			'&$tableWithZebraStripes $row:nth-child(even):not($rowSelected)': {
				backgroundColor: `rgba(${mixins.hexToRgb(palette.gray[100])}, 0.5)`,
			},
		},
		fixedLayout: {},
		tbodyForLoader: {},
		tableLoading: {},
		tableWithRowHover: {},
		tableWithZebraStripes: {},
		caption: {
			fontSize: 0,
			height: 0,
			overflow: 'hidden',
		},
		wrap: {
			display: 'grid',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'&:focus': {
				boxShadow: `0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`,
			},
		},
		wrapOverflow: {
			overflow: 'auto',
		},
		innerWrap: {
			display: 'grid',
			gridTemplateColumns: '0 auto 0',
			gridTemplateRows: 'auto',
			// Yes... the " inside ' is necessary...
			gridTemplateAreas: '"left-scroll-screen table right-scroll-screen"',
		},
		stickyIndicators: {
			position: 'sticky',
			display: 'flex',
			justifyContent: 'space-between',
			top: 0,
			height: 0,
			opacity: 0,
			pointerEvents: 'none',
			// Enable z-index when deving on intersection observer. Also comment out opacity property
			// zIndex: 1000,
		},
		stickyIndicator: {
			top: 0,
			width: '10px',
			height: '10px',
			backgroundColor: 'red',
			position: 'sticky',
		},
		loaderCell: {
			padding: 0,
		},
		loader: {
			position: 'absolute',
			top: '44px',
			right: 0,
			left: 0,
			bottom: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		leftScrollIndicator: {
			gridArea: 'left-scroll-screen',
			alignSelf: 'stretch',
			position: 'sticky',
			left: 0,
			pointerEvents: 'none',
			zIndex: 200,
			'&:before': {
				// borderLeft: `2px solid ${ palette.gray[400] }`,
				content: '""',
				height: '100%',
				position: 'absolute',
				top: 0,
				bottom: 0,
				width: '48px',
			},
		},
		leftScrollIndicatorWithScreen: {
			'&:before': {
				background: `linear-gradient(90deg, ${palette.common.white} 0%, transparent 100%)`,
			},
		},
		rightScrollIndicator: {
			gridArea: 'right-scroll-screen',
			alignSelf: 'stretch',
			position: 'sticky',
			right: 0,
			pointerEvents: 'none',
			zIndex: 200,
			'&:before': {
				// borderRight: `2px solid ${ palette.gray[400] }`,
				content: '""',
				height: '100%',
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				width: '48px',
			},
		},
		rightScrollIndicatorWithScreen: {
			'&:before': {
				background: `linear-gradient(270deg, ${palette.common.white} 0%, transparent 100%)`,
			},
		},
		row: {
			transition: 'background-color 0.15s ease-in-out',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			'&:hover $cellMask': {
				opacity: 1,
			},
			// START FF Fix
			// The following two rules have to be split up because otherwise FF chokes on the :has and ruins the entire selector (including the selector before the comma)
			// if you make changes to one, you have to make changes to the other
			'&:hover $stickyRight': showStickyRightContentOnRowHover,
			'&:has([aria-expanded="true"]) $stickyRight': showStickyRightContentOnRowHover,
			// End FF Fix

			'&$rowEmphasized': {
				fontWeight: typography.fontWeightSemibold,
			},
			'&$rowHighlighted, &$rowSelected': {
				backgroundColor: `rgba(${mixins.hexToRgb(palette.primary.lightest)}, 0.1)`,
				'& > $stickyLeft': {
					// the highlight/selected color is expecting to be overlaid on top of a solid white background
					// if we replace the solid white from sticky columns, we see the content underneath when it scrolls horizontally
					// So in order to keep the `palette.primary.lightest` with the `0.1` opacity we do this linear gradient trick
					// to layer the highlighted/selected color on top of white.
					// We cannot use pseudo before or after elements because they are already used for borders and shadows.
					background: `linear-gradient(rgba(${mixins.hexToRgb(palette.primary.lightest)}, 0.1), rgba(${mixins.hexToRgb(
						palette.primary.lightest
					)}, 0.1)), linear-gradient(${palette.common.white}, ${palette.common.white})`,
				},
			},
			'&$rowCollapsible td:first-child': {
				paddingLeft: '30px',
			},
		},
		removeBottomBorder: {},
		rowEmphasized: {},
		rowHighlighted: {},
		rowSelected: {},
		rowCollapsible: {},
		header: {
			...sharedHeaderAndCell,
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.lighter : palette.grey[200],
			color: palette.grey[800],
			fontWeight: typography.fontWeightBold,
			padding: '12px 16px 11px',
			transition: 'background-color 0.15s ease-in-out',
			borderColor: 'transparent',
			'&:first-of-type': {
				borderRadius: '2px 0 0 2px',
			},
			'&:last-of-type': {
				borderRadius: '0 2px 2px 0',
			},
			'&$minimalRightPadding': {
				paddingRight: '4px',
			},
			'&$noVerticalPadding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'&$noHorizontalPadding': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'&$alignCenter': {
				textAlign: 'center',
			},
			'&$alignRight': {
				textAlign: 'right',
			},
			'$headless &': {
				borderBottom: `1px solid ${palette.grey[300]}`,
				fontSize: 0,
				height: 0,
				lineHeight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				pointerEvents: 'none',
			},
			'&$noBottomBorderRadius': {
				'&:first-of-type': {
					borderBottomLeftRadius: 0,
				},
				'&:last-of-type': {
					borderBottomRightRadius: 0,
				},
			},
			'&$stickyTop': {
				position: 'sticky',
				top: 0,
				zIndex: 120,
			},
			'&$stickyLeft': {
				...sharedHeaderAndCellStickyLeft,
				zIndex: 130,
			},
			'&$stickyLeftBorder:before': {
				...sharedHeaderAndCellStickyLeftBefore,
			},
			'&$stickyLeftScreen:after': {
				...sharedHeaderAndCellStickyLeftScreenAfter,
				bottom: 0,
			},
			'&$sortableHeader': {
				'&:hover': {
					cursor: 'pointer',
				},
				'&:not(&$whiteHeaderVariant):hover': {
					backgroundColor: palette.gray[300],
					'&:not(:first-of-type):before, &:not(:last-of-type):after': {
						animation: '$opacityIn 0.15s',
						backgroundColor: palette.gray[400],
						content: '""',
						height: '100%',
						opacity: 1,
						position: 'absolute',
						top: 0,
						width: '1px',
					},
					'&:before': {
						left: 0,
					},
					'&:after': {
						right: 0,
					},
				},
			},
			'&$whiteHeaderVariant': {
				borderBottom: `1px solid ${palette.grey[300]}`,
				backgroundColor: palette.common.white,
			},
		},
		headless: {
			'$root&': {
				'--fab-table-sticky-group-header-top': '0px',
			},
		},
		whiteHeaderVariant: {},
		noBottomBorderRadius: {},
		sortableHeader: {},
		sortIcon: {
			position: 'relative',
			'&:after': {
				backgroundImage: mixins.inlineSvg(
					'<svg width="7" height="9" xmlns="http://www.w3.org/2000/svg"><path d="M7 4.031v1.097c0 .198-.11.474-.246.616L3.746 8.893a.336.336 0 01-.492 0L.246 5.744A1.02 1.02 0 010 5.128V4.031c0-.197.11-.243.246-.1l2.395 2.507c.137.143.389.069.389-.13V.332c0-.2.013-.332.205-.332h.53c.187 0 .197.098.197.297v5.975c0 .201.26.308.397.166L6.754 3.93c.136-.143.246-.098.246.1z" fill="#000" fill-rule="evenodd"/></svg>',
					{ fill: palette.gray[700] }
				),
				bottom: '5px',
				content: '""',
				height: '9px',
				position: 'absolute',
				right: '-11px',
				width: '7px',
			},
			'&$sortIconAscending:after': {
				transform: 'rotate(180deg)',
				transformOrigin: 'center center',
			},
		},
		sortIconAscending: {},
		cell: {
			...sharedHeaderAndCell,
			color: palette.grey[1000],
			borderBottom: `1px solid ${palette.grey[300]}`,
			padding: '13px 16px 15px',
			'&$minimalRightPadding': {
				paddingRight: '4px',
			},
			'&$noVerticalPadding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'&$noHorizontalPadding': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'&$alignCenter': {
				textAlign: 'center',
			},
			'&$alignRight': {
				textAlign: 'right',
			},
			'&$verticalAlign': {
				verticalAlign: 'middle',
			},
			'&$stickyLeft': {
				...sharedHeaderAndCellStickyLeft,
				background: palette.common.white,
				backgroundClip: 'padding-box',
				transition: 'background 0.15s ease-in-out', // animation for background when selected/highlighted
				zIndex: 90,
			},
			'&$stickyLeftBorder:before': {
				...sharedHeaderAndCellStickyLeftBefore,
			},
			'&$stickyLeftScreen:after': {
				...sharedHeaderAndCellStickyLeftScreenAfter,
				bottom: 0,
			},
			'&$cellGroup': {
				backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : palette.grey[100],
				borderTop: `1px solid ${palette.gray[300]}`,
				color: palette.grey[800],
				fontSize: typography.fabricFontSize?.('small'),
				fontWeight: typography.fontWeightBold,
				'&:not(&$noVerticalPadding)': {
					paddingTop: '9px',
					paddingBottom: '9px',
				},
			},
			'&$noBottomBorder': {
				borderBottom: 'none',
			},
			'&$triangleIcon': {
				paddingLeft: '5px',
			},
			'&$triangleIcon svg': {
				marginRight: '7px',
			},
		},
		triangleIcon: {},
		minimalRightPadding: {},
		noVerticalPadding: {},
		noHorizontalPadding: {},
		alignCenter: {},
		alignRight: {},
		stickyTop: {},
		stickyLeft: {},
		stickyLeftBorder: {},
		stickyLeftScreen: {},
		verticalAlign: {},
		cellGroup: {},
		cellGroupSticky: {
			position: 'sticky',
			top: props =>
				props.stickyGroupHeaderTop !== undefined ? props.stickyGroupHeaderTop : 'var(--fab-table-sticky-group-header-top)',
			zIndex: 110,
			left: props => (props.stickyColumn !== undefined ? 0 : undefined),
		},
		noBottomBorder: {},
		stickyRight: {},
		mutedSubCell: {
			opacity: 0.6,
		},
		cellActions: {
			alignItems: 'center',
			display: 'flex',
			height: '14px',
			justifyContent: 'flex-end',
			position: 'relative',
			top: '4px',
		},
		cellMask: {
			transition: `opacity 0.15s ease-in-out`,
			'&:not(:focus-within)': {
				opacity: 0,
			},
		},
		group: {
			display: 'flex',
			alignItems: 'center',
		},
		groupIcon: {
			alignItems: 'center',
			display: 'inline-flex',
			fill: palette.grey[600],
			height: 0,
			justifyContent: 'center',
			marginRight: '7px',
		},
		groupContent: {
			flex: 1,
		},
		tableExpandIcon: {
			fill: palette.grey[800],
			transition: '300ms',
		},
		tableExpandIconExpanded: {
			transform: 'rotate(90deg)',
		},
	});
};
// @endCleanup encore

export const styles = theme => ifFeature('encore', getStyles(theme), getJadeStyles(theme));
