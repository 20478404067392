import React, { FunctionComponent, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

import { UpperContent as BaseModalUpperContent, useScreenSizes } from '~components/base-modal';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const StyledDiv = styled('div')({ marginTop: 20 });
export const UpperContent: FunctionComponent<PropsWithChildren<unknown>> = props => {
	const { isMediumScreen } = useScreenSizes();

	const _baseModalUpperContent = <BaseModalUpperContent {...props} />;

	return isMediumScreen
		? _baseModalUpperContent
		: ifFeature('encore', _baseModalUpperContent, <StyledDiv>{_baseModalUpperContent}</StyledDiv>);
};
