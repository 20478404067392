import { StatusOptions } from '../data-grid.types';
import { IconV2Props } from '../../icon-v2';

/* @startCleanup encore */
import { Question14x14, TriangleExclamation14x12, InfoCircle14x14 } from '@bamboohr/grim';

export const getStatusIcon = (status: StatusOptions) => {
	switch (status) {
		case 'error':
			return TriangleExclamation14x12;
		case 'warning':
			return Question14x14;
		case 'info':
			return InfoCircle14x14;
		default:
			return null;
	}
};
/* @endCleanup encore */

export const getStatusIconNameAndColor = (status: StatusOptions): Pick<IconV2Props, 'color' | 'name'> | null => {
	switch (status) {
		case 'error':
			return { name: 'circle-exclamation-solid', color: 'error-medium' };
		case 'warning':
			return { name: 'triangle-exclamation-solid', color: 'warning-medium' };
		case 'info':
			return { name: 'circle-info-solid', color: 'info-medium' };
		default:
			return null;
	}
};
