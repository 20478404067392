import { useCallback, useState } from 'react';

export const useMenuState = (onOpen?: () => void, onClose?: () => void) => {
	const [open, setIsOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setIsOpen(true);

		if (onOpen && typeof onOpen === 'function') {
			onOpen();
		}
	}, [onOpen]);

	const handleClose = useCallback(() => {
		setIsOpen(false);

		if (onClose && typeof onClose === 'function') {
			onClose();
		}
	}, [onClose]);

	return { open, handleOpen, handleClose };
};
