import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ScreenReaderOnlyText } from '../../screen-reader-only-text';
import { DELETION_COLUMN_NAME, ROW_ID_COLUMN_NAME } from '../constants';
import { DataGridProps } from '../data-grid.types';
import { RowDeletionCellRenderer } from '../row-deletion-cell-renderer';

interface AddDefaultColumnsProps {
	columnDefs: DataGridProps['columnDefs'];
	deletionColumnClass: string;
	getRowNumber: NonNullable<DataGridProps['getRowNumber']>;
	hideRowNumbers: boolean;
	onDeleteRow: DataGridProps['onDeleteRow'];
	rowNumberColumnClass: string;
}

/**
 * Prepends a column definition for a row number indicator column and, when onDeleteRow exists, a column of X buttons to trigger row deletion
 * @param columnDefs the original columnDefs array
 * @param deletionColumnClass the class name for the row deletion cells
 * @param onDeleteRow the function to call when the row deletion buttons are clicked
 * @param rowNumberColumnClass the class name for the row number cells
 * @returns a columnDefs array
 */

export const addDefaultColumns = ({
	columnDefs,
	deletionColumnClass,
	getRowNumber,
	hideRowNumbers,
	onDeleteRow,
	rowNumberColumnClass,
}: AddDefaultColumnsProps): DataGridProps['columnDefs'] => {
	const rowNumberColumnDef: ColDef = {
		cellClass: rowNumberColumnClass,
		cellRenderer: (params: ICellRendererParams) => getRowNumber(params),
		editable: false,
		field: ROW_ID_COLUMN_NAME,
		headerComponent: ScreenReaderOnlyText,
		headerComponentParams: {
			children: window.jQuery ? $.__('Row number') : 'Row number',
		},
		resizable: false,
		suppressAutoSize: true,
		width: 70,
	};

	const deletionColumnDef: ColDef = {
		cellClass: deletionColumnClass,
		cellRenderer: RowDeletionCellRenderer,
		cellRendererParams: {
			onDeleteRow,
		},
		editable: false,
		field: DELETION_COLUMN_NAME,
		headerComponent: ScreenReaderOnlyText,
		headerComponentParams: {
			children: window.jQuery ? $.__('Row deletion') : 'Row deletion',
		},
		resizable: false,
		suppressAutoSize: true,
		width: 34,
	};

	let resultingColumnDefs = [...columnDefs];
	if (!hideRowNumbers) {
		resultingColumnDefs = [rowNumberColumnDef, ...resultingColumnDefs];
	}
	if (onDeleteRow) {
		resultingColumnDefs = [deletionColumnDef, ...resultingColumnDefs];
	}
	return resultingColumnDefs;
};
