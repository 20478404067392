import { makeStyles } from '~styles';

// @startCleanup encore
import { StatusOptions } from '../data-grid.types';
export const useCellRendererStylesJade = makeStyles<{ status: StatusOptions }>()((theme, params) => {
	const { palette } = theme;

	const { status } = params;

	let iconColor: string;
	switch (status) {
		case 'error':
			iconColor = palette.error.main;
			break;
		case 'warning':
			iconColor = palette.warning.main;
			break;
		case 'info':
			iconColor = palette.info.main;
			break;
		default:
			iconColor = palette.primary.main;
			break;
	}

	return {
		text: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		icon: {
			alignSelf: 'center',
			fill: iconColor,
			flex: '0 0 auto',
			marginLeft: '2px',
		},
	};
});
// @endCleanup encore

export const useCellRendererStyles = makeStyles()(({ typography, constructs }) => {
	return {
		dataGridCellValue: {
			color: constructs.text.neutral.strong,
			flex: 1,
			fontSize: typography.medium.fontSize,
			fontWeight: typography.fontWeightRegular,
			lineHeight: typography.medium.lineHeight,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	};
});
