// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AreaClosed } from '@visx/shape';

import { AnimatedLine } from './animated-line';
import { AnimatedCircle } from './animated-circle';
import { AnimatedPointGroup } from './animated-point-group';
import { AnimatedPointValue } from './animated-point-value';
import { LinesProps } from '../types';
import { CirclePropsGenerator } from './circle-props-generator';

export const Lines = <Datum = unknown,>({
	animate,
	areaColorsByKey,
	color,
	circleRadius = 4,
	circleOpenRadius = 4,
	circleStrokeWidth = 3,
	curve,
	data,
	getDataValue,
	getPointValueFormat,
	getSeriesValue,
	hideInnerSeriesCircles = false,
	keys,
	lineStrokeWidth = 2,
	pointValues,
	x,
	xMax,
	xScale,
	y,
	yMax,
	yScale,
	renderArea: Area,
	renderOpenCircles,
	renderOverlay: Overlay,
	transition,
	...restProps
}: LinesProps<Datum>): ReactElement => {
	const [keepSelected, setKeepSelected] = useState<boolean>(false);
	const [hasLeftPoint, setHasLeftPoint] = useState<boolean>(false);
	const [pointDataIndex, setPointDataIndex] = useState<number | null>(null);
	const [selectedKey, setSelectedKey] = useState<string | null>(null);
	const [circleKey, setCircleKey] = useState<string | null>(null);
	const [points, setPoints] = useState<Record<string, SVGElement | null>>({});

	const genKey = (key: string, element: string, index: number) => `${key}-${element}-${index}`;
	const getX = (d: Datum) => (xScale(x(d)) as number) + xScale.bandwidth() / 2;
	const getY = (d: Datum, key: string): number => yScale(y(d, key)) ?? 0;
	let selectedPoint = points[circleKey ?? ''];

	const circlePropsGenerator = new CirclePropsGenerator(color, circleRadius, circleOpenRadius, circleStrokeWidth);

	useEffect(() => {
		// Close the popup only if we've left the point/circle and the user hasn't
		// clicked on it (enabling it to stay open (keepSelected))
		if (!keepSelected && hasLeftPoint) {
			setSelectedKey(null);
			setPointDataIndex(null);
			setCircleKey(null);
		}
	}, [hasLeftPoint, keepSelected]);

	return (
		<>
			{keys.map((key, index) => {
				const defined = (d: Datum) => {
					const dataValue = getDataValue(d, key);
					return dataValue !== undefined && dataValue !== null && !Number.isNaN(dataValue);
				};
				const areaColor = areaColorsByKey?.[key] ?? color(key);

				return (
					<g
						key={genKey(key, 'line', index)}
						{...{ title: window.jQuery ? $.__('Data series for %s', key) : `Data series for ${key}` }}
					>
						{Area && (
							<AreaClosed curve={curve} data={data} defined={defined} x={getX} y={d => getY(d, key)} yScale={yScale}>
								{({ path }) =>
									yMax === 0 && xMax === 0 ? (
										<></>
									) : (
										<Area
											animate={animate}
											color={areaColor}
											path={path(data) ?? ''}
											transition={transition}
											yMax={yMax}
										/>
									)
								}
							</AreaClosed>
						)}
						<AnimatedLine
							animate={animate}
							curve={curve}
							data={data}
							defined={defined}
							pointerEvents="none"
							stroke={color(key)}
							strokeWidth={lineStrokeWidth}
							transition={transition}
							x={getX}
							xMax={xMax}
							y={d => getY(d, key)}
							yMax={yMax}
						/>
						<AnimatePresence>
							{data.map((datum, originalIndex) => {
								const pointValue = getSeriesValue(datum);
								const pointValueDisplayText = getPointValueFormat
									? getPointValueFormat(pointValue, datum, key)
									: String(pointValue);
								const dataValue = getDataValue(datum, key);

								const hidePoint =
									dataValue === undefined ||
									dataValue === null ||
									Number.isNaN(dataValue) ||
									Number.isNaN(getY(datum, key));
								if (hidePoint) {
									return null;
								}
								const circleTitle = `${pointValue} ${dataValue}`;
								let showCircle = !hideInnerSeriesCircles;
								let showPointValue = !hideInnerSeriesCircles;
								if (originalIndex === 0 || originalIndex === data.length - 1) {
									showCircle = true;
									showPointValue = true;
								}
								if (selectedKey === key && pointDataIndex === originalIndex && Overlay) {
									showCircle = true;
								}

								const circleKey = genKey(key, 'circle', originalIndex);

								const adjacentYValues = {
									prevY: originalIndex ? getY(data[originalIndex - 1], key) : null,
									nextY: originalIndex < data.length - 1 ? getY(data[originalIndex + 1], key) : null,
								};

								const isOpenCircle = renderOpenCircles ? renderOpenCircles(key) : originalIndex === data.length - 1;

								return (
									<AnimatedPointGroup
										animate={!!animate}
										key={circleKey}
										onClick={() => {
											setKeepSelected(!keepSelected);
										}}
										onMouseEnter={() => {
											setKeepSelected(false);
											setHasLeftPoint(false);
											setSelectedKey(key);
											setPointDataIndex(originalIndex);
											setCircleKey(circleKey);
											selectedPoint = points[circleKey ?? ''];
										}}
										onMouseLeave={() => {
											setHasLeftPoint(true);
										}}
									>
										<AnimatedCircle
											animate={animate}
											circleTitle={circleTitle}
											cursor={Overlay ? 'pointer' : undefined}
											cx={getX(datum)}
											cy={getY(datum, key)}
											data={{ d: datum, key }}
											ref={point => {
												setPoints(prev => {
													prev[circleKey] = point;
													return prev;
												});
											}}
											xMax={xMax}
											yMax={yMax}
											{...circlePropsGenerator.getProps(datum, key, isOpenCircle, showCircle)}
											{...restProps}
										/>
										{pointValues && showPointValue && pointValueDisplayText && (
											<AnimatedPointValue
												adjacentYValues={adjacentYValues}
												animate={animate}
												displayText={pointValueDisplayText}
												dx={getX(datum)}
												dy={getY(datum, key)}
												xMax={xMax}
												yMax={yMax}
											/>
										)}
									</AnimatedPointGroup>
								);
							})}
						</AnimatePresence>
					</g>
				);
			})}
			{Overlay && pointDataIndex !== null && selectedPoint && selectedKey && (
				<Overlay
					anchorEl={selectedPoint}
					data={data[pointDataIndex]}
					onClose={() => {
						// Don't prematurely close the popup if we haven't left the point yet
						if (hasLeftPoint) {
							// Close the overlay/popup
							setKeepSelected(false);
						}
					}}
					open={true}
					selectedKey={selectedKey}
				/>
			)}
		</>
	);
};
