import React, { forwardRef, Ref } from 'react';
import { Box } from '@mui/system';
import { FlexComponent, FlexProps } from './flex.types';
import { useStyles } from './flex.styles';
import { formatBaseProps } from '~utils';

function FlexFunction<C extends keyof JSX.IntrinsicElements = 'div'>(
	{ children, className, display = 'flex', overflowWrap, ...rest }: FlexProps<C>,
	ref: Ref<HTMLElement>
) {
	const { classes, cx } = useStyles({ overflowWrap });
	return (
		<Box
			className={cx(className, classes.root)}
			display={display}
			ref={ref as Ref<HTMLDivElement>}
			// casting for performance
			{...formatBaseProps(rest as object)}
		>
			{children}
		</Box>
	);
}

// passing generic type params for performance
export const Flex = forwardRef<Element, FlexProps>(FlexFunction) as FlexComponent;
