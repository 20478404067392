import { Loader } from '~components/loader';
import React, { FunctionComponent, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import ReactModal from 'react-modal';

import { useOverlayStack } from '../../utils';
import { Body } from './body.react';
import { CloseButton } from './close-button.react';
import { MODAL_CLOSE_TIMEOUT, OverlayTheme, Transition } from './constants';
import { BaseModalContext } from './context';
import { Footer } from './footer.react';
import { Header } from './header.react';
import { useContentRef, useMaxZIndex, usePreventScroll, usePreventSubmit } from './hooks';
import { focusFirstFocusableElement } from './utils/dom';
import { getOverlayClassNameObject, getPortalClassName } from './utils/styles';
import { BaseModalProps } from './base-modal.types';

import { useStyles } from './base-modal.style';
import { uniqueId } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const { setAppElement } = ReactModal;

export const BaseModal: FunctionComponent<PropsWithChildren<BaseModalProps>> & {
	Body: typeof Body;
	CloseButton: typeof CloseButton;
	Header: typeof Header;
	Footer: typeof Footer;
} = props => {
	const {
		ariaDescribedby,
		ariaLabelledby: ariaLabelledbyProp,
		children,
		className, // not used; prevents passing to ReactModal
		contentStyles = {},
		id,
		isLoading = false,
		isOpen = false,
		isProcessing = false,
		onCloseComplete = (): void => undefined,
		onOpenComplete = (): void => undefined,
		onRequestClose = (): void => undefined,
		onlyPrintModal = false,
		overlayStyles = {},
		overlayTheme = OverlayTheme.FULLY_OPAQUE,
		parentSelector,
		shouldFocusAfterRender = true,
		shouldReturnFocusAfterClose = true,
		transition = Transition.VERTICAL_FADE,
		...rest
	} = props;

	const ariaLabelledby = useMemo(() => ariaLabelledbyProp || uniqueId('modal-header'), [ariaLabelledbyProp]);

	const contentRef = useContentRef();
	const maxZIndex = useMaxZIndex(isOpen);
	const preventSubmit = usePreventSubmit();
	const [backdropElement, setBackdropElement] = useState<HTMLElement>();
	const { classes, cx } = useStyles();

	useOverlayStack(parentSelector ? 'INLINE_MODAL' : 'MODAL', isOpen);
	usePreventScroll(typeof parentSelector !== 'function', isOpen, backdropElement);

	const _overlayStyles = {
		zIndex: maxZIndex,
		...overlayStyles,
	};

	/* This is a third-party component so we don't have control over its prop names. */
	/* eslint-disable react/forbid-component-props */
	return (
		<BaseModalContext.Provider
			value={{
				...props,
				// if an ariaLabelledby id is provided, assume some unknown element will have that ID and do not use it internally
				ariaLabelledby: ariaLabelledbyProp ? undefined : ariaLabelledby,
				contentRef,
				close: onRequestClose,
			}}
		>
			<ReactModal
				aria={{
					describedby: ariaDescribedby,
					labelledby: ariaLabelledby,
				}}
				className={ifFeature(
					'encore',
					{
						base: classes.content,
						afterOpen: classes.contentAfterOpen,
						beforeClose: classes.contentBeforeClose,
					},
					className || {
						base: classes.content,
						afterOpen: classes.contentAfterOpen,
						beforeClose: classes.contentBeforeClose,
					}
				)}
				closeTimeoutMS={MODAL_CLOSE_TIMEOUT}
				contentRef={(node): void => {
					focusFirstFocusableElement(node);
					preventSubmit(node);

					contentRef.current = node;
				}}
				id={id}
				isOpen={isOpen}
				onAfterClose={
					onlyPrintModal
						? () => {
								const style = document.getElementById('hideBackgroundForPrint');
								if (style) {
									style.remove();
								}

								onCloseComplete();
							}
						: onCloseComplete
				}
				onAfterOpen={
					onlyPrintModal
						? () => {
								const style = document.createElement('style');
								style.id = 'hideBackgroundForPrint';
								style.innerHTML = `@media print { body:has([role="dialog"]) > * { display: none; } body > div:has([role="dialog"]) { display: block; } }`;
								document.head.appendChild(style);

								onOpenComplete();
							}
						: onOpenComplete
				}
				onRequestClose={onRequestClose}
				overlayClassName={getOverlayClassNameObject({ classes, cx, overlayTheme, parentSelector })}
				overlayRef={useCallback((node): void => setBackdropElement(node), [])}
				parentSelector={parentSelector}
				portalClassName={getPortalClassName({ classes, cx, transition })}
				shouldCloseOnEsc={!isProcessing}
				shouldCloseOnOverlayClick={false}
				shouldFocusAfterRender={shouldFocusAfterRender}
				shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
				style={{
					content: contentStyles,
					overlay: _overlayStyles,
				}}
				{...rest}
			>
				{isOpen && isLoading && (
					<div className={classes.loader}>
						<Loader />
					</div>
				)}
				{children}
			</ReactModal>
		</BaseModalContext.Provider>
	);
	/* eslint-enable react/forbid-component-props */
};

BaseModal.Body = Body;
BaseModal.CloseButton = CloseButton;
BaseModal.Header = Header;
BaseModal.Footer = Footer;
