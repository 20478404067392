import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getEncoreValue } from '~utils';
import type { SvgProps } from './svg.types';

export const useSvgStyles = makeStyles<SvgProps>()(({ constructs }, props) => {
	const { color, height, maxHeight, maxWidth, minHeight, minWidth, width } = props;

	return {
		root: {
			fill: ifFeature(
				'encore',
				getEncoreValue(color, {
					/* eslint-disable sort-keys */
					'bamboo-brand-medium': constructs.icon.bambooBrand.medium,
					'discovery-weak': constructs.icon.discovery.weak,
					'discovery-medium': constructs.icon.discovery.medium,
					'discovery-strong': constructs.icon.discovery.strong,
					'error-weak': constructs.icon.error.weak,
					'error-medium': constructs.icon.error.medium,
					'error-strong': constructs.icon.error.strong,
					'info-weak': constructs.icon.info.weak,
					'info-medium': constructs.icon.info.medium,
					'info-strong': constructs.icon.info.strong,
					link: constructs.icon.link.idle,
					'neutral-extra-weak': constructs.icon.neutral.xWeak,
					'neutral-weak': constructs.icon.neutral.weak,
					'neutral-medium': constructs.icon.neutral.medium,
					'neutral-strong': constructs.icon.neutral.strong,
					'neutral-extra-strong': constructs.icon.neutral.xStrong,
					'neutral-extra-extra-strong': constructs.icon.neutral.xxStrong,
					'neutral-forced-white': constructs.icon.neutral.inverted,
					'neutral-inverted': constructs.icon.neutral.inverted,
					'notice-strong': constructs.icon.notice.strong,
					'primary-medium': constructs.icon.primary.medium,
					'primary-strong': constructs.icon.primary.strong,
					'success-weak': constructs.icon.success.weak,
					'success-medium': constructs.icon.success.medium,
					'success-strong': constructs.icon.success.strong,
					'warning-weak': constructs.icon.warning.weak,
					'warning-medium': constructs.icon.warning.medium,
					'warning-strong': constructs.icon.warning.strong,
					/* eslint-enable sort-keys */
				})
			),
			flexShrink: 0,
			height,
			maxHeight,
			maxWidth,
			minHeight,
			minWidth,
			width,
		},
	};
});
