import { Ref } from 'react';
import { ValidationField } from './validation.types';
import { focusFirstFieldWithError } from './focus-first-field-with-error';
import { Validation } from './validation';

type ValidationRegistrationOptions<C, B> = ValidationField & {
	onChange?: C;
	onBlur?: B;
};

export class ComponentValidation extends Validation {
	elementsByName: Record<string, HTMLElement> = {};

	refs: Record<string, Ref<any>> = {};

	register<C extends (...args: any) => any, B extends (...args: any) => any>(
		name: string,
		registrationOptions: ValidationRegistrationOptions<C, B>
	) {
		if (typeof name !== 'string') {
			throw new Error('Name must be supplied when registering a field.');
		}

		const { onChange, onBlur, ...field } = registrationOptions;

		const ref =
			this.refs[name] ||
			(element => {
				if (element) {
					this.elementsByName[name] = element;
					this.addField(name, field);
				} else if (this.refs[name]) {
					delete this.elementsByName[name];
					this.removeField(name);
				}
			});

		this.refs[name] = ref;

		return {
			onChange: (...args: Parameters<C>) => {
				if (name in this.fields) {
					const field = this.fields[name];
					this.trigger(name, this.getFieldValue(field, args), 'change');
					this.setFieldDirty(name);
				}

				if (typeof onChange === 'function') {
					onChange(...args);
				}
			},
			onBlur: (...args: any) => {
				if (name in this.fields) {
					this.trigger(name, this.values[name], 'blur');
				}

				if (typeof onBlur === 'function') {
					onBlur(...args);
				}
			},
			ref,
		};
	}

	async validate(): Promise<boolean> {
		const valid = await super.validate();

		if (!this.valid) {
			focusFirstFieldWithError(this.elementsByName, this.errors);
		}

		return valid;
	}
}
