import React, { useState, ReactElement } from 'react';
import { TransferListCategoryListProps, CategorizedListItems } from '../../types';
import { getCategorizedItemData } from './get-categorized-item-data';
import { getDisplayOrderIndexLeft } from './get-display-order-index-left';
import { TransferListCategory } from '../transfer-list-category';

export function TransferListCategoryList(props: TransferListCategoryListProps): ReactElement {
	const { listItems } = props;
	const [categories, setCategories] = useState<string[]>([]);
	const [expandedCategories, setExpandedCategories] = useState<boolean[]>([]);

	let categorizedData = getCategorizedItemData(listItems);

	const categoryNames = Object.keys(categorizedData);
	if (categories.length === 0 && categoryNames.length !== 0) {
		setCategories(categoryNames);
		setExpandedCategories(categoryNames.map(() => false));
	} else {
		const updatedCategories = categories.reduce((acc, category, index): CategorizedListItems => {
			// This keeps the category showing on the left even when it's empty
			if (categorizedData[category] !== undefined) {
				acc[category] = categorizedData[category];
				acc[category].index = index;
			} else {
				acc[category] = { category, subcategories: [], items: [], index };
			}
			return acc;
		}, {} as CategorizedListItems);
		categorizedData = updatedCategories;
	}

	categorizedData = getDisplayOrderIndexLeft(categorizedData, categories, expandedCategories);

	const toggleExpandedCategory = (index: number, expanded: boolean) => {
		setExpandedCategories(prev => {
			const updated = [...prev];
			updated[index] = expanded;
			return updated;
		});
	};

	return (
		<>
			{Object.values(categorizedData).map((category): ReactElement => {
				return (
					<TransferListCategory
						{...props}
						displayOrderIndex={category.displayOrderIndex}
						expanded={expandedCategories[category.index]}
						index={category.index}
						items={category.items}
						key={category.category}
						name={category.category}
						onToggleExpand={expanded => toggleExpandedCategory(category.index, expanded)}
						subcategories={category.subcategories}
						transferListItemProps={props}
					/>
				);
			})}
		</>
	);
}
