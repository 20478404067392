import { FormFactoryElement } from '../types/element.types';
import { getFieldFromModel } from '../utils/get-field-from-model';
import { isFormField } from '../utils/is-form-field';
import { ComponentValidation } from '../validation';

export function validationTriggerMiddleware(validation: ComponentValidation) {
	return (model: FormFactoryElement) => {
		if (!isFormField(model)) {
			return model;
		}

		return {
			...model,
			...validation.register(model.name, {
				...getFieldFromModel(model),
				onBlur: model.onBlur,
				onChange: model.onChange,
			}),
		};
	};
}
