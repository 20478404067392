import { Typography } from '@mui/material';
import React, { isValidElement } from 'react';
import { RendererProps } from '../types';

export function TextRenderer({ element }: RendererProps) {
	const { value } = element;

	if (!(typeof value === 'string' || (typeof value === 'object' && isValidElement(value)))) {
		throw new Error('Text renderer received a bad setting for `value`.');
	}

	return <Typography>{value}</Typography>;
}
