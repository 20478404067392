import { alpha } from '@mui/material';
import { makeStyles } from '~styles';
import { InputStatus } from '~components/form';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<{
	status: InputStatus | undefined;
	viewMode: boolean | undefined;
	disabled: boolean | undefined;
}>()((theme, params) => {
	const { palette, mixins } = theme;
	const { status, disabled, viewMode } = params;

	return {
		adornment: {
			backgroundColor: 'none !important',
			padding: '0 !important',
		},
		focused: ifFeature(
			'encore',
			{
				'&&&': {
					...mixins.inputOutline(theme, { disabled, focused: true, status, viewMode }),
				},
			},
			{
				borderColor: `${status ? palette[status].main : palette.primary.lighter}`,
				boxShadow: `${alpha(status ? palette[status].main : palette.primary.lighter, 0.25)} 0 0 0 2px`,
			}
		),
		input: ifFeature('encore', { paddingRight: 0 }, {}),
		root: {
			'& .MuiInputBase-root': {
				overflow: ifFeature('encore', 'unset'),
				'&:focus-within': ifFeature(
					'encore',
					{
						...mixins.inputOutline(theme, { disabled, status, viewMode }),
					},
					{}
				),
			},
		},
	};
});
