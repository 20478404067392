import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ palette, typography, mode }) => {
	return {
		root: {
			flexDirection: 'column',
			flexGrow: 1,
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		},
		subSuperTitle: {
			color: palette.common.white,
			fontSize: ifFeature('encore', typography.teenie.fontSize, typography.medium.fontSize),
			lineHeight: ifFeature('encore', typography.teenie.lineHeight, typography.medium.lineHeight),
		},
		title: {
			color: palette.common.white,
			fontSize: ifFeature('encore', typography.h2.fontSize),
			fontWeight: ifFeature('encore', typography.fontWeightSemibold),
			margin: 0,
		},
	};
});
