import { makeStyles } from '~styles';
import { InputDateStyleProps } from '../../types';
import { widths } from '~definitions/json/sizes.json';

// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

export const useStyles = makeStyles<InputDateStyleProps>()((theme, props) => {
	const { mixins, palette, typography } = theme;
	const { disabled, noteStatus } = props;

	const noteColor = () => {
		if (noteStatus === 'error') {
			return palette.error.main;
		}
		if (noteStatus === 'warning') {
			return palette.warning.main;
		}
		if (noteStatus === 'info') {
			return palette.info.main;
		}
		return palette.gray[700];
	};

	return {
		iconButton: {
			fill: disabled ? palette.gray[400] : palette.primary.main,
			height: '100%',
			width: 27,
			// @startCleanup encore
			'&:focus': {
				border: ifFeature('encore', undefined, mixins.border(1, palette.primary.lighter)),
				borderRadius: ifFeature('encore', undefined, 2),
				boxShadow: ifFeature('encore', undefined, `0 0 0 2px ${mixins.alpha(palette.primary.lightest, 0.35)}`),
			},
			// @endCleanup encore
		},
		note: {
			alignItems: 'center',
			color: noteColor(),
			display: 'flex',
			fontSize: typography.fabricFontSize('teenie'),
			height: 34,
			lineHeight: typography.fabricLineHeight('teenie'),
			marginLeft: 8,
		},
		root: {
			alignItems: 'flex-end',
			display: 'flex',
			minWidth: ifFeature('encore', undefined, widths[5]),
		},
	};
});
