import { FileUploadItemProps } from '../../types';
import { includes, noop } from 'lodash';
import React, { FC, PropsWithChildren } from 'react';
import { ModeOptions, dyeColors, makeStyles } from '~styles';
import { FilesContext } from '../../context';
import { Item } from './components/item.react';

export interface ListProps {
	itemProps?: Partial<FileUploadItemProps>;
}

const useStyles = makeStyles()(theme => {
	const { mode } = theme;
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		},
	};
});

export const List: FC<PropsWithChildren<ListProps>> = ({ itemProps }) => {
	const { classes } = useStyles();
	return (
		<FilesContext.Consumer>
			{({
				allFileIds = [],
				biId,
				canRename,
				filesById = {},
				isDisabled = false,
				pendingFileIds = [],
				progressByFileId = {},
				removeFile = noop,
				renameFile = noop,
			}) => {
				if (allFileIds.length === 0) {
					return null;
				}

				return (
					<div className={classes.root}>
						{allFileIds.map(temporaryId => {
							const file = filesById[temporaryId];
							const isPending = includes(pendingFileIds, temporaryId);
							const progress = progressByFileId[temporaryId];

							return (
								<Item
									biId={biId}
									canRemove={!isDisabled}
									canRename={canRename}
									file={file}
									isPending={isPending}
									{...itemProps}
									key={temporaryId}
									onRemove={removeFile}
									onRename={renameFile}
									progress={progress}
									temporaryId={temporaryId}
								/>
							);
						})}
					</div>
				);
			}}
		</FilesContext.Consumer>
	);
};
