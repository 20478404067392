import React from 'react';
import { motion, SVGMotionProps } from 'framer-motion';

const variants = {
	initial: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};

interface AnimatedPointGroupProps extends SVGMotionProps<SVGGElement> {
	animate: boolean;
}

export const AnimatedPointGroup = ({ animate, children, ...groupProps }: AnimatedPointGroupProps) => {
	return (
		<motion.g
			{...groupProps}
			animate={animate ? 'initial' : undefined}
			exit={animate ? 'exit' : undefined}
			initial={animate ? 'initial' : undefined}
			variants={variants}
		>
			{children}
		</motion.g>
	);
};
