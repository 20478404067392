import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { BodyProps } from '../base-modal.types';

import { BaseModalContext } from '../context';

import { useStyles } from './body.style';

export const Body: FunctionComponent<PropsWithChildren<BodyProps>> = props => {
	const { children, className, ...rest } = props;
	const { isLoading } = useContext(BaseModalContext);
	const { classes, cx } = useStyles();

	return (
		<div
			className={cx(classes.baseModalBody, className, {
				[classes.baseModalBodyHidden]: isLoading,
			})}
			{...rest}
		>
			{children}
		</div>
	);
};
