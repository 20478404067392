import { makeStyles, ModeOptions, dyeColors } from '~styles';

export const useTimelineStyles = makeStyles()(({ mode }) => {
	return {
		root: {
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		horizontal: {
			flexDirection: 'row',
		},
	};
});
