import { isFormField } from './is-form-field';
import { FormConfig } from '../form-factory.types';
import { BuiltinFormElementType } from '../enums';
import { FormFactoryCheckboxFieldModel } from '../types';

export function getModelValues(models: FormConfig['models']) {
	const values: Record<string, unknown> = Object.values(models).reduce((memo, model) => {
		if (isFormField(model)) {
			const { name, hidden, type } = model;
			if (hidden) {
				return memo;
			}

			if ((type as BuiltinFormElementType) === BuiltinFormElementType.CheckboxField) {
				memo[name] = (model as unknown as FormFactoryCheckboxFieldModel).checked;
			} else {
				memo[name] = model.value;
			}
		}

		return memo;
	}, {});

	return values;
}
