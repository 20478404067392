/* eslint-disable react/forbid-component-props */
import { PickersDayProps } from '@mui/x-date-pickers';
import composeRefs from '@seznam/compose-react-refs';
import classNames from 'classnames';
import { findLast } from 'lodash';
import { DateTime } from 'luxon';
import React, { forwardRef, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { tabbable } from 'tabbable';
import { GridRow } from '~components/grid-row';
import { ScreenReaderOnlyText } from '~components/screen-reader-only-text';
import { useDatePickerUtils, useWeeksInMonth } from '../../hooks';
import { navigateWithKeyboard } from '../../shared.domain';
import {
	DatePickerCalendarSingleProps,
	DatePickerDate,
	DatePickerDateType,
	MonthValue,
	SelectMonthYearOnChangeParam,
} from '../../types';
import {
	changeMonthYear,
	getDateFromYearMonth,
	getDateInfo,
	getDefaultMaxDate,
	getDefaultMinDate,
	getDisplayMonthAbbreviations,
	getIsDateValid,
	getRangeYears,
	getToday,
	isNavigationKey,
} from '../../utils';
import { Calendar, DatePickerCalendarDayMemoized } from '../date-picker-calendar-day';
import { DatePickerHeader } from '../date-picker-header';
import {
	getDatePickerCalendarSingleOnChangeParam,
	getIsDayDisabled,
	getIsDayErrored,
	getIsDayFocused,
	getIsLeftArrowButtonDisabled,
	getIsRightArrowButtonDisabled,
	getViewDate,
} from './date-picker-calendar-single.domain';
import { useStyles } from './date-picker-calendar-single.styles';
import { getClosestEnabledAndVisibleDate } from './domain';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '~components/flex';

function DatePickerCalendarSingleComponent(
	{
		autofocus = false,
		classes: classesProp = {},
		className,
		disabled = false,
		getDateDisabled,
		maxDate,
		minDate,
		onChange,
		onMenuClose,
		onMenuOpen,
		renderDate,
		value = DateTime.local(),
	}: DatePickerCalendarSingleProps,
	ref: RefObject<HTMLDivElement>
): JSX.Element {
	const utils = useDatePickerUtils();
	const [viewDate, setViewDate] = useState<DatePickerDate>(() => getViewDate(utils, value));
	const refRoot = useRef<HTMLDivElement>(null);
	const [focusedDate, setFocusedDate] = useState<DatePickerDate>(getInitialFocusDate);
	const [justSelected, setJustSelected] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);

	function getInitialFocusDate() {
		const initialDate = value || getToday(utils);

		return getClosestEnabledAndVisibleDate(utils, viewDate, { getDateDisabled, maxDate, minDate }, initialDate);
	}

	function focus() {
		if (!refRoot.current) {
			return;
		}

		const tabbableElements = tabbable(refRoot.current);
		const calendarDayElement = findLast(tabbableElements, element => element.hasAttribute('data-calendar-day'));

		if (calendarDayElement) {
			setHasFocus(true);
			return;
		}

		tabbableElements[0]?.focus();
	}

	useEffect(() => {
		if (autofocus) {
			focus();
		}
	}, [autofocus]);

	const { classes } = useStyles();
	minDate = getIsDateValid(utils, minDate) ? minDate : getDefaultMinDate(utils);
	maxDate = getIsDateValid(utils, maxDate) ? maxDate : getDefaultMaxDate(utils);
	const years = getRangeYears(utils, minDate, maxDate);

	const leftArrowButtonDisabled = getIsLeftArrowButtonDisabled(utils, viewDate, disabled, minDate);
	const rightArrowButtonDisabled = getIsRightArrowButtonDisabled(utils, viewDate, disabled, maxDate);
	const displayMonths = getDisplayMonthAbbreviations(utils);
	const viewDateYear = utils.getYear(viewDate as DatePickerDateType);
	const viewDateMonth = (utils.getMonth(viewDate as DatePickerDateType) + 1) as MonthValue;

	function handleMonthChange(newViewDate: DatePickerDate) {
		const sameDayNewMonth =
			focusedDate &&
			changeMonthYear(
				utils,
				focusedDate,
				utils.getMonth(newViewDate as DatePickerDateType),
				utils.getYear(newViewDate as DatePickerDateType)
			);

		const closestFocusedDate = getClosestEnabledAndVisibleDate(
			utils,
			newViewDate,
			{
				getDateDisabled,
				maxDate,
				minDate,
			},
			sameDayNewMonth
		);

		setFocusedDate(closestFocusedDate);
		setViewDate(newViewDate);
	}

	function handleArrowClick(event: string): void {
		if (event === 'next-month') {
			handleMonthChange(utils.addMonths(viewDate as DatePickerDateType, 1));
			return;
		}
		handleMonthChange(utils.addMonths(viewDate as DatePickerDateType, -1));
	}

	function handleDateChanged(date, state?: string | boolean): void {
		const isFinish = state === 'finish' || state === true;
		if (!isFinish) {
			return;
		}

		if (getIsDayDisabled(utils, date as DatePickerDate, disabled, minDate, maxDate, getDateDisabled)) {
			return;
		}

		setFocusedDate(date as DatePickerDate);
		onChange(getDatePickerCalendarSingleOnChangeParam(date as DatePickerDate));
		setJustSelected(true);
	}

	function handleSelectMonthYearChange({ month, year }: SelectMonthYearOnChangeParam): void {
		const newViewDate = getDateFromYearMonth(utils, year, month);
		const sameDayNewMonth =
			focusedDate &&
			changeMonthYear(
				utils,
				focusedDate,
				utils.getMonth(newViewDate as DatePickerDateType),
				utils.getYear(newViewDate as DatePickerDateType)
			);

		const newFocusedDate = getClosestEnabledAndVisibleDate(
			utils,
			newViewDate,
			{
				getDateDisabled,
				maxDate,
				minDate,
			},
			sameDayNewMonth
		);

		setFocusedDate(newFocusedDate);
		setViewDate(newViewDate);
	}

	function handleRenderDate(date, selectedDates, dayProps: PickersDayProps<DatePickerDate>): React.ReactElement {
		const { outsideCurrentMonth, disabled: dayDisabled, ...other } = dayProps;
		if (renderDate) {
			const dateInfo = getDateInfo(utils, date);
			return renderDate(dateInfo, selectedDates, dayProps);
		}

		return (
			<DatePickerCalendarDayMemoized
				{...other}
				active={getIsDayFocused(utils, date, focusedDate, justSelected)}
				disabled={getIsDayDisabled(utils, date, disabled || dayDisabled, minDate, maxDate, getDateDisabled)}
				errored={getIsDayErrored(utils, date, value, minDate, maxDate, getDateDisabled)}
				focused={hasFocus && getIsDayFocused(utils, date, focusedDate, justSelected)}
				outsideCurrentMonth={outsideCurrentMonth}
				selected={utils.isEqual(value, date)}
			/>
		);
	}

	function handleKeydown(event: React.KeyboardEvent) {
		if (event.key === ' ' || event.key === 'Enter') {
			if (focusedDate) {
				event.preventDefault();
				handleDateChanged(focusedDate, true);
			}
			return;
		}

		if (isNavigationKey(event)) {
			event.preventDefault();

			const newDate = navigateWithKeyboard({
				activeDate: focusedDate,
				getDateDisabled,
				keyboardKey: event.key,
				maxDate,
				minDate,
				shiftKeyPressed: event.shiftKey,
				utils,
			});

			if (!utils.isSameMonth(viewDate as DatePickerDateType, newDate as DatePickerDateType)) {
				setViewDate(utils.startOfMonth(newDate as DatePickerDateType));
			}

			setFocusedDate(newDate);
			setJustSelected(false);
		}
	}

	function handleClick({ currentTarget }: MouseEvent<HTMLDivElement>) {
		if (!currentTarget.contains(document.activeElement)) {
			focus();
		}
	}

	const weeksToShow = useWeeksInMonth(utils, viewDate);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div className={classNames(classes.root, className, classesProp.root)} onClick={handleClick} ref={composeRefs(ref, refRoot)}>
			<div className={classNames(classes.picker)}>
				{ifFeature(
					'encore',
					<Flex justifyContent="center" paddingTop={1} paddingX={1}>
						<DatePickerHeader
							disabled={disabled}
							leftArrowDisabled={leftArrowButtonDisabled}
							maxDate={maxDate}
							minDate={minDate}
							months={displayMonths}
							monthValue={viewDateMonth}
							onArrowClick={buttonClick => handleArrowClick(buttonClick)}
							onChange={handleSelectMonthYearChange}
							onMenuClose={onMenuClose}
							onMenuOpen={onMenuOpen}
							rightArrowDisabled={rightArrowButtonDisabled}
							years={years}
							yearValue={viewDateYear}
						/>
					</Flex>,
					<GridRow justifyContent="center" margin="none" marginTop={1} marginX={1}>
						<DatePickerHeader
							disabled={disabled}
							leftArrowDisabled={leftArrowButtonDisabled}
							maxDate={maxDate}
							minDate={minDate}
							months={displayMonths}
							monthValue={viewDateMonth}
							onArrowClick={buttonClick => handleArrowClick(buttonClick)}
							onChange={handleSelectMonthYearChange}
							onMenuClose={onMenuClose}
							onMenuOpen={onMenuOpen}
							rightArrowDisabled={rightArrowButtonDisabled}
							years={years}
							yearValue={viewDateYear}
						/>
					</GridRow>
				)}
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div onBlur={() => setHasFocus(false)} onFocus={() => setHasFocus(true)} onKeyDown={handleKeydown}>
					<Calendar
						date={viewDate || null}
						maxDate={maxDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
						minDate={minDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
						onChange={handleDateChanged}
						onMonthChange={date => setViewDate(date)}
						renderDay={handleRenderDate}
						weeksToShow={weeksToShow}
					/>
				</div>
			</div>
			<ScreenReaderOnlyText ariaAtomic={true} ariaLive="assertive">
				{window.jQuery
					? $.__('Showing %1$s', utils.format(viewDate as DatePickerDateType, 'monthAndYear'))
					: `Showing ${utils.format(viewDate as DatePickerDateType, 'monthAndYear')}`}
			</ScreenReaderOnlyText>
		</div>
	);
}

export const DatePickerCalendarSingle = forwardRef(DatePickerCalendarSingleComponent);
