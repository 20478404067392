/* eslint-disable no-use-before-define */
import React, { forwardRef } from 'react';
import FabricIcon from '@fabric/icon';
import { Typography } from '@mui/material';
import clsx from 'classnames';
import { useStyles } from './badge.styles';

import { BadgeProps } from './types';

/**
 * @deprecated Use `BadgeV2` instead.
 */
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
	(
		{
			className,
			classes = {},
			children,
			iconName,
			icon,
			iconColor = 'primary',
			imageSrc,
			imageAlt = '',
			imageWidth = 'auto',
			imageHeight = 'auto',
			title,
			TitleProps = {},
			...rest
		},
		ref
	) => {
		const hasAlternativeStyles = !!children;
		const { classes: styles } = useStyles({
			alignTop: hasAlternativeStyles,
			iconColor,
			marginLarge: hasAlternativeStyles,
		});
		const renderedIcon = icon || iconName;

		return (
			<div className={clsx(className, styles.root, classes.root)} ref={ref} {...rest}>
				<div className={clsx(styles.asset, classes.asset)}>
					{renderedIcon && (
						<div className={clsx(styles.iconWrapper, classes.iconWrapper)}>
							{typeof renderedIcon === 'string' ? <FabricIcon name={renderedIcon} /> : renderedIcon}
						</div>
					)}
					{imageSrc && !iconName && (
						<img
							alt={imageAlt}
							className={clsx(styles.image, classes.image)}
							height={imageHeight}
							src={imageSrc}
							width={imageWidth}
						/>
					)}
				</div>

				<div className={classes.content}>
					<Typography color="primary" component="span" display="block" variant="h4" {...TitleProps}>
						{title}
					</Typography>
					{children}
				</div>
			</div>
		);
	}
);
