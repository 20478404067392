import { ajax } from '@bamboohr/utils/lib/ajax';

import { DEFAULT_UPLOAD_ENDPOINT_URL, DEFAULT_UPLOAD_NAME, UPLOAD_INFO_URL } from './constants';
import { FileUploadFile, FileUploadServiceOptions, FileUploadServiceResponse, UploadInfoResponse } from './types';

export class FileUploadServiceError extends Error {
	errorType: string;

	constructor({ message, errorType }: { message: string; errorType: string }) {
		super(message);

		this.errorType = errorType;
	}
}

export async function getUploadInfo(): Promise<UploadInfoResponse> {
	const { data } = await ajax.get(UPLOAD_INFO_URL);
	return data;
}

export async function uploadFile(file: File, options: FileUploadServiceOptions): Promise<FileUploadFile> {
	try {
		const { url, fileInfo, formInputs } = await getUploadInfo();
		const { additionalValues = {}, onUploadProgress } = options;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('x-amz-meta-file-name', file.name);

		Object.entries(formInputs).forEach(([key, value]) => formData.append(key, value));
		Object.entries(additionalValues).forEach(([key, value]) => formData.append(key, value));

		await ajax.post(url, formData, {
			onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
				const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				onUploadProgress?.(progress);
			},
		});

		return {
			id: fileInfo,
			name: file.name,
			type: file.type,
			size: file.size,
		};
	} catch (error) {
		throw new FileUploadServiceError({ errorType: 'UPLOAD_FAILED', message: 'Upload failed' });
	}
}

export function uploadFileV1(file: File, options: FileUploadServiceOptions): Promise<FileUploadFile> {
	const { additionalValues = {}, name = DEFAULT_UPLOAD_NAME, onUploadProgress, url = DEFAULT_UPLOAD_ENDPOINT_URL } = options || {};

	const formData = new FormData();
	formData.append(name, file);

	Object.keys(additionalValues).forEach(key => formData.append(key, additionalValues[key]));

	return ajax
		.post(url, formData, {
			onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
				onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			},
		})
		.then(({ data }: FileUploadServiceResponse): FileUploadFile => {
			const { errorType, fid, message, name: _name, type, size, status } = data || {};

			if (status !== 'OK') {
				throw new FileUploadServiceError({ message, errorType });
			}

			return {
				id: fid,
				name: _name,
				type,
				size,
			};
		});
}
