import { DatePickerDate, DatePickerDatePredicate, DatePickerDateType, DatePickerUtils } from '../types';

/**
 * Changes constraints so that they don't extend beyond visible range. For example,
 * if `minDate` occurs before the first day in the first calendar, then the first date in the first calendar becomes the new `minDate`.
 * @param utils The date utils used all over the Date Picker components
 * @param startDate The first visible date
 * @param endDate The the last visible date
 * @param constraints The constraints that control if a date is enabled or not.
 * @returns New constraints that don't extend beyond the currently visible calendars
 */
export function getUpdatedConstraintsForRange(
	utils: DatePickerUtils,
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	constraints: { getDateDisabled?: DatePickerDatePredicate; maxDate?: DatePickerDate; minDate?: DatePickerDate } = {}
): { getDateDisabled?: DatePickerDatePredicate; maxDate: DatePickerDate; minDate: DatePickerDate } {
	const { getDateDisabled, maxDate: maxDateConstraint, minDate: minDateConstraint } = constraints;

	let minDate = minDateConstraint as DatePickerDateType;
	if (!minDate || utils.isBeforeDay(minDateConstraint as DatePickerDateType, startDate as DatePickerDateType)) {
		minDate = startDate as DatePickerDateType;
	}

	let maxDate = maxDateConstraint;
	if (!maxDate || utils.isAfterDay(maxDateConstraint as DatePickerDateType, endDate as DatePickerDateType)) {
		maxDate = utils.startOfDay(utils.endOfMonth(endDate as DatePickerDateType));
	}

	return { getDateDisabled, maxDate, minDate };
}
