import React from 'react';

import { NO_RESULTS_PLACEHOLDER_TEXT } from './constants';
import { getCSSClasses, getInnerFacadeCSSClasses } from './util';
import { List } from '../list.react';
import { Search } from '../search.react';

import './vessel.scss';

export function Vessel(props) {
	const {
		activeItem,
		biId,
		canSelectMultiple = false,
		children,
		condition,
		isAttached = false,
		items = [],
		isOpen,
		listRole,
		menuId,
		menuPlacement,
		onActiveItemChange,
		onAdd,
		onSearch,
		onSelect,
		renderOptionContent,
		searchRef,
		searchPlaceholder,
		searchText,
		selectedItems,
		shouldShowSearch,
		size,
		width,
	} = props;

	const onClickCapture = /^16/.test(React.version) ? { onClickCapture: e => e.nativeEvent.stopImmediatePropagation() } : {};

	return (
		/* eslint-disable jsx-a11y/click-events-have-key-events */
		/* eslint-disable jsx-a11y/no-static-element-interactions */
		<div
			className={getCSSClasses({ isAttached, condition, menuPlacement, width })}
			data-menu-id={menuId}
			onClick={e => e.stopPropagation()}
			{...onClickCapture}
		>
			<div className="fab-MenuVessel__outerFacade" key="outerFacade">
				<div className={getInnerFacadeCSSClasses({ condition })} key="innerFacade"></div>
			</div>
			<div className="fab-MenuVessel__list">
				{children}

				{shouldShowSearch ? (
					<div className="fab-MenuVessel__search">
						<Search
							activeItem={activeItem}
							onSearch={onSearch}
							ref={searchRef}
							searchPlaceholder={searchPlaceholder}
							searchText={searchText}
						/>
					</div>
				) : null}

				<List
					activeItem={activeItem}
					biId={biId}
					canSelectMultiple={canSelectMultiple}
					condition={condition}
					isVisible={isOpen}
					items={items}
					menuId={menuId}
					menuPlacement={menuPlacement}
					onActiveItemChange={onActiveItemChange}
					onAdd={onAdd}
					onSelect={onSelect}
					placeholder={shouldShowSearch ? NO_RESULTS_PLACEHOLDER_TEXT : ''}
					renderOptionContent={renderOptionContent}
					role={listRole}
					selectedItems={selectedItems}
					size={size}
				/>
			</div>
		</div>
		/* eslint-disable jsx-a11y/click-events-have-key-events */
		/* eslint-disable jsx-a11y/no-static-element-interactions */
	);
}
