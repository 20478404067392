import React from 'react';
import { IconV2, IconV2Name } from '../icon-v2';
import { useStyles } from './wizard-step-indicator.styles';
import { WizardStep } from './types';

export interface WizardStepProps extends Pick<WizardStep, 'name'> {
	stepNumber: number;
	active: boolean;
	completed: boolean;
	ariaLabel: string;
}
export function WizardStepIndicator({ stepNumber, name, active, completed, ariaLabel }: WizardStepProps) {
	const { classes } = useStyles({ active, completed });

	const icon = (completed ? 'circle-check-solid' : `circle-${stepNumber}-${active ? 'solid' : 'regular'}`) as IconV2Name;

	return (
		<li aria-label={ariaLabel} className={classes.root}>
			<IconV2 color={active || completed ? 'primary-strong' : 'neutral-extra-strong'} name={icon} size={24} />
			<p className={classes.stepLabel}>{name}</p>
		</li>
	);
}
