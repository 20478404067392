import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Title } from './title.react';
import { BaseModal, useScreenSizes } from '~components/base-modal';
import { FullScreenModal } from '~components/full-screen-modal';
import { StandardModalHeaderProps } from '../standard-modal.types';

export const Header: FunctionComponent<PropsWithChildren<StandardModalHeaderProps>> & {
	Title: typeof Title;
} = props => {
	const { children, title, ...restProps } = props;

	const { isMediumScreen } = useScreenSizes();

	return isMediumScreen ? (
		<FullScreenModal.Header {...props} />
	) : (
		<BaseModal.Header {...restProps}>{children || <Title text={title} />}</BaseModal.Header>
	);
};

Header.Title = Title;
