import { DatePickerDate, DatePickerUtils, InputDateOnChangeParam } from '../../types';
import { getDisplayMonthAbbreviations, getIsDateValid, getMonthAbbreviations, parseDate } from '../../utils';

export function getDisplayValue(utils: DatePickerUtils, date: DatePickerDate, editedInputValue: string, isEditing: boolean): string {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (isEditing) {
		return editedInputValue;
	}

	return getFormattedValue(utils, date) || editedInputValue;
}

export function getFormattedValue(utils: DatePickerUtils, date: DatePickerDate): undefined | string {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	if (!date || !getIsDateValid(utils, date)) {
		return undefined;
	}

	const formattedValue = utils.format(date, 'fullDate');
	const formatType = utils.formats.fullDate as string;

	if (formatType === 'dd MMM yyyy') {
		if (formattedValue) {
			const formattedValueArray = formattedValue.split(' ');
			const monthAbbreviation = formattedValueArray[1].toLowerCase();
			const monthAbbreviations = getMonthAbbreviations(utils);
			const searchableAbbreviations = monthAbbreviations.map(abbreviation => abbreviation.toLowerCase());
			const index = searchableAbbreviations.indexOf(monthAbbreviation);

			if (index > -1) {
				const displayMonthAbbreviations = getDisplayMonthAbbreviations(utils);
				const replaceValue = displayMonthAbbreviations[index];
				if (replaceValue) {
					formattedValueArray.splice(1, 1, replaceValue);
				}
			}
			return formattedValueArray.join(' ');
		}
	}

	return formattedValue;
}

export function getInputDateOnChangeParam(date: DatePickerDate, inputValue: string): InputDateOnChangeParam {
	return {
		inputValue,
		value: date,
	};
}

export function getParsedDate(utils: DatePickerUtils, inputValue: string): DatePickerDate | null {
	if (!utils) {
		throw new Error('Expected arguments are missing.');
	}

	const formatType = utils.formats.fullDate as string;

	if (formatType === 'dd MMM yyyy') {
		if (inputValue) {
			const inputValueArray = inputValue.split(' ');
			const displayMonthAbbreviation = inputValueArray[1].toLowerCase();
			const displayMonthAbbreviations = getDisplayMonthAbbreviations(utils);
			const searchableAbbreviations = displayMonthAbbreviations.map(abbreviation => abbreviation.toLowerCase());
			const index = searchableAbbreviations.indexOf(displayMonthAbbreviation);

			if (index > -1) {
				const monthAbbreviations = getMonthAbbreviations(utils);
				const replaceValue = monthAbbreviations[index];
				if (replaceValue) {
					inputValueArray.splice(1, 1, replaceValue);
				}
			}
			inputValue = inputValueArray.join(' ');
		}
	}

	return parseDate(utils, formatType, inputValue);
}

export function getCalendarButtonLabel(utils: DatePickerUtils, value: DatePickerDate) {
	const formattedValue = getFormattedValue(utils, value);

	if (!formattedValue) {
		return window.jQuery ? $.__('Pick a date.') : 'Pick a date.';
	}

	return window.jQuery
		? $.__('Pick a date. The current value is %1$s.', formattedValue)
		: `Pick a date. The current value is ${formattedValue}.`;
}
