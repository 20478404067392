// eslint-disable-next-line no-use-before-define
import React, { forwardRef, ReactElement, Ref } from 'react';
import { motion } from 'framer-motion';
import { AnimatedCircleProps } from '../types/animated-circle-props';
import { useCircleVariants } from './use-circle-variants';

const AnimatedCircleFunction = <Datum = unknown,>(
	{
		animate,
		circleTitle,
		cx,
		cy,
		data,
		onClick,
		onMouseEnter,
		onMouseLeave,
		onMouseMove,
		xMax = 0,
		yMax = 0,
		...restProps
	}: AnimatedCircleProps<Datum>,
	ref: Ref<SVGCircleElement>
): ReactElement => {
	const variants = useCircleVariants(cx, cy, yMax);

	const handleClick = () => onClick && onClick(data);
	const handleMouseEnter = () => onMouseEnter && onMouseEnter(data);
	const handleMouseLeave = () => onMouseLeave && onMouseLeave(data);
	const handleMouseMove = () => onMouseMove && onMouseMove(data);

	const mouseProps = {
		onClick: handleClick,
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
		onMouseMove: handleMouseMove,
	};

	return animate ? (
		<motion.circle
			animate="idle"
			cx={cx}
			cy={cy}
			initial="initial"
			ref={ref}
			variants={variants}
			{...mouseProps}
			{...{ title: circleTitle }}
			{...restProps}
		/>
	) : (
		<circle cx={cx} cy={cy} ref={ref} {...mouseProps} {...restProps} />
	);
};

export const AnimatedCircle = forwardRef(AnimatedCircleFunction);
