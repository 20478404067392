import { ISettings, IDictionary } from '@flatfile/adapter/build/main/interfaces';
import { FileImporterLabels } from '../types';

export const generateI18nOverrides = (labels: FileImporterLabels): ISettings['i18nOverrides'] => {
	return {
		en: {
			otherLocales: ['en-US'],
			overrides: labels as unknown as IDictionary<string>,
		},
	};
};
