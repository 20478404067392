import { AttachmentFileCardProps, DisplayFileCardProps, EmptyFileCardProps, FileCardProps, View } from '@fabric/file-card';

export function isFileTypeEsig(fileType: string): boolean {
	return fileType.toLowerCase() === 'esig';
}

export function isAttachmentCard(card: FileCardProps): card is AttachmentFileCardProps & { view: View } {
	return card.view === 'attachment';
}

export function isDisplayCard(card: FileCardProps): card is DisplayFileCardProps & { view: View } {
	return card.view === 'display';
}

export function isEmptyCard(card: FileCardProps): card is EmptyFileCardProps & { view: View } {
	return !card.view || card.view === 'empty';
}
