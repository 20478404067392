import React, { FC, PropsWithChildren, useEffect } from 'react';
import { IconDimension, IconProps } from '@fabric/icon';
import { handleError } from '@bamboohr/utils/lib/handle-error';
import { bootstrap } from './bootstrap';
import { StyledSvg } from './styles';

/**
 * Extract the width and height from the given icon name
 *
 * @param name The name of the icon
 */
export function calculateDimensions(name: string): IconDimension {
	const pattern = /-(\d+)x(\d+)$/;
	const [match, width, height] = pattern.exec(name) || [false];

	if (!match) {
		handleError(`${name} is an invalid icon name. Icon names must end with dimensions. Example: "-1x1".`);
	}

	return {
		width: Number(width),
		height: Number(height),
	};
}

/**
 * @deprecated Use IconV2 instead.
 */
const Icon: FC<PropsWithChildren<IconProps>> = ({ name, brand }) => {
	useEffect(() => bootstrap(), []);

	return (
		<StyledSvg aria-hidden={true} brand={brand} focusable={false} pointerEvents="none" {...calculateDimensions(name)}>
			<use xlinkHref={`#${name}`} />
		</StyledSvg>
	);
};
export { Icon };
export default Icon;
