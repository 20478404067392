import { CircleX16x16 } from '@bamboohr/grim';
import { IconButton } from '@fabric/button';
import { makeStyles } from '~styles';
import React, { FC, MouseEvent, PropsWithChildren } from 'react';
import { SearchInputClearButtonProps } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles()(({ palette }) => ({
	clearIcon: {
		fill: palette.gray[600],
	},
}));

export const ClearButton: FC<PropsWithChildren<SearchInputClearButtonProps>> = ({ onClear }) => {
	const { classes } = useStyles();

	return (
		<IconButton
			aria-label={window.jQuery ? $.__('Clear Search') : 'Clear Search'}
			floatingIcon={true}
			hasBounding={false}
			icon={ifFeature('encore', 'circle-xmark-solid', <CircleX16x16 className={classes.clearIcon} />)}
			onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();
				event.preventDefault();

				if (typeof onClear === 'function') {
					onClear();
				}
			}}
			type="button"
		/>
	);
};
