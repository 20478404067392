import React, { useState } from 'react';
import { IconV2 } from '~components/icon-v2';
import { Tooltip } from '~components/tooltip';
import { GlobalNavigationProps } from './global-navigation.types';
import { useStyles } from './global-navigation.styles';
import { GlobalNavigationContext } from './global-navigation-context';
import { FooterItem } from './footer-item';
import { FooterItemButton } from './footer-item-button';
import { Link } from './link';

export function GlobalNavigation({ footer, links, open, onToggle }: GlobalNavigationProps) {
	const { classes } = useStyles({ open });
	const [toggleRef, setToggleRef] = useState<HTMLLIElement | null>();
	const openToggleLabel = window.jQuery ? $.__('Collapse Menu') : 'Collapse Menu';
	const closedToggleLabel = window.jQuery ? $.__('Expand Menu') : 'Expand Menu';

	return (
		<GlobalNavigationContext.Provider value={{ open }}>
			<nav className={classes.root}>
				<ul className={classes.links}>{links}</ul>
				<ul className={classes.footer}>
					{footer}
					<Tooltip
						anchorEl={toggleRef}
						content={open ? openToggleLabel : closedToggleLabel}
						enterDelay={150}
						placement="right"
					/>
					<FooterItem
						ariaLabel={open ? openToggleLabel : closedToggleLabel}
						biId="main-nav-toggle-btn"
						containerRef={setToggleRef}
						onClick={onToggle}
					>
						<IconV2 name="bars-regular" size={24} />
					</FooterItem>
				</ul>
			</nav>
		</GlobalNavigationContext.Provider>
	);
}

GlobalNavigation.FooterItemButton = FooterItemButton;
GlobalNavigation.FooterItem = FooterItem;
GlobalNavigation.Link = Link;
