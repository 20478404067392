import React, { ElementType, forwardRef } from 'react';
import { Stack as MUIStack } from '@mui/material';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { StackProps } from './stack.types';

const useStyles = makeStyles()(({ mode }) => {
	return {
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
	};
});

/**
 * @deprecated Stack should no longer be used. Instead, Flex is the preferred option.
 */
export const Stack = forwardRef((props: StackProps<ElementType, { component?: ElementType }>, ref) => {
	const { children, className, component = 'div', ...rest } = props;
	const { classes, cx } = useStyles();

	return (
		<MUIStack className={cx(classes.root, className)} component={component} {...rest} ref={ref}>
			{children}
		</MUIStack>
	);
});
