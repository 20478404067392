import React from 'react';
import { ButtonProps } from '../types';
import { BaseButtonProps } from './types/base-button.types';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';

const logger = createLogger('@fabric/button | AllowedButtonTypes');
const ALLOWED_TYPES = ['submit', 'button', 'reset'];

export function getAllowedButtonTypes(props: Partial<BaseButtonProps<any>>): string | undefined {
	/* GOTCHA: Defaults to "submit" as unintended behaviors were seen when not specified. Warns devs in console  */
	const { component, href, type } = props;

	if ((component === 'button' || !component) && (!type || !ALLOWED_TYPES.includes(type)) && !href) {
		logger.warn(
			`Invalid type passed to button element${getButtonLoggerHelpText(
				props
			)}. Must be one of the following: ${ALLOWED_TYPES.join(', ')}. Defaulted to 'submit'`
		);
		return 'submit';
	}

	return type;
}

export function getButtonLoggerHelpText(props: Partial<ButtonProps>): string {
	const list = ['children', 'endIcon', 'iconAfter', 'iconBefore', 'icon', 'startIcon', 'text', 'type'].reduce((acc, cur, idx) => {
		if (props[cur] && typeof props[cur] === 'string') {
			return `${acc}${acc.length ? ', ' : ''}${cur}: "${String(props[cur])}"`;
		}
		return acc;
	}, '');
	return list ? ` (${list})` : ``;
}

export function sanitizeTabIndex(tabindex?: React.Key, defaultValue = 0): number {
	if (typeof tabindex === 'string') {
		tabindex = parseInt(tabindex);
	}

	return !tabindex || isNaN(tabindex) ? defaultValue : tabindex;
}

export default {
	getAllowedButtonTypes,
	sanitizeTabIndex,
};
