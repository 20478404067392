import { OverlayTheme, Transition } from '../constants';
import { useStyles } from '../base-modal.style';
import { Cx } from 'tss-react';
export type OverlayClassNames = {
	afterOpen: string;
	base: string;
	beforeClose: string;
};

export function getOverlayClassNameObject({
	classes,
	cx,
	overlayTheme,
	parentSelector,
}: {
	classes: ReturnType<typeof useStyles>['classes'];
	cx: Cx;
	overlayTheme?: OverlayTheme;
	parentSelector?: Function;
}): OverlayClassNames {
	return {
		afterOpen: classes.backDropAfterOpen,
		base: cx(classes.backDrop, {
			[classes.backDropPartiallyOpaque]: overlayTheme === OverlayTheme.PARTIALLY_OPAQUE,
			[classes.backDropInline]: !!parentSelector,
		}),
		beforeClose: classes.backDropBeforeClose,
	};
}

export function getPortalClassName({
	classes,
	cx,
	transition,
}: {
	classes: ReturnType<typeof useStyles>['classes'];
	cx: Cx;
	transition: Transition;
}): string {
	return cx({
		[classes.verticalFade]: transition === Transition.VERTICAL_FADE,
		[classes.zoomFade]: transition === Transition.ZOOM_FADE,
	});
}
