import React, { FC, forwardRef, useRef, RefObject, ReactElement, PropsWithChildren } from 'react';
import { BamboohrLogo115x17 } from '@bamboohr/grim';
import { useScreenSizes as useFabricScreenSizes } from '@fabric/utils/hooks';
import { ActionFooterProps } from './types';
import { useStyles } from './action-footer.styles';
import { ButtonProps } from '~components/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ActionFooter = forwardRef<HTMLDivElement, ActionFooterProps>((props, ref: RefObject<HTMLDivElement>) => {
	const { classes: styles, cx } = useStyles(props);
	const localRef = useRef<HTMLDivElement>(null);
	ref = ref ?? localRef;

	const { isMediumScreen } = useFabricScreenSizes(true);
	const defaultRight = ifFeature(
		'encore',
		null,
		<div className={styles.icon}>
			<BamboohrLogo115x17 />
		</div>
	);

	const childElement = props.children ? <div className={styles.children}>{props.children}</div> : null;

	const actions = props.actions.map(button => {
		const { children } = button.props as ButtonProps;
		return React.cloneElement(
			button as ReactElement<ButtonProps>,
			{ dark: props.dark, size: 'large', noSpacing: isMediumScreen, ...button.props } as ButtonProps,
			children
		);
	});

	return (
		<div className={cx(styles.root, props.classes?.root, { [styles.rootBorder]: props.borderTop })} ref={ref}>
			<div className={cx(styles.content, props.classes?.content)}>
				<div className={cx(styles.left, props.classes?.left)}>
					<div className={cx(styles.actions, props.classes?.actions)}>{actions}</div>
					{childElement}
				</div>
				<div className={cx(styles.right, props.classes?.right)}>
					{typeof props.right !== 'undefined' ? props.right : defaultRight}
				</div>
			</div>
		</div>
	);
});

export const ActionFooterStorybook = ActionFooter as FC<PropsWithChildren<ActionFooterProps>>;
