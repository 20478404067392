import React, { ReactElement, cloneElement } from 'react';
import { SlatGroupProps } from './slat-group.types';

export function SlatGroup({ items, backgroundColor, borderColor }: SlatGroupProps): ReactElement {
	// get slat components via map and cloneElement
	function getSlatComponents(items: ReactElement[]): ReactElement[] {
		const slatComponents = items.map((item, index): ReactElement => {
			const isFirstSlat = index === 0;
			const borderPlacement = isFirstSlat ? 'none' : 'top';

			return cloneElement(item, { backgroundColor, borderColor, borderPlacement });
		});

		return slatComponents;
	}

	return <div>{getSlatComponents(items)}</div>;
}
