import { darken, Theme } from '@mui/material';
import { BASE_BOX_SHADOW, TRANSPARENT_BOX_SHADOW } from '../../button/constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const containedStyles = ({
	constructs,
	palette,
	mixins,
}: Theme): Record<'containedPrimary' | 'containedSecondary' | 'contained', any> => ({
	contained: {
		'&.MuiButtonGroup-grouped:not(:focus):not($focusVisible)': ifFeature(
			'encore',
			{
				borderColor: 'transparent',
			},
			{}
		),
	},
	containedPrimary: {
		fill: palette.primary.contrastText,
		'&:hover': {
			backgroundColor: ifFeature('encore', palette.primary.dark, darken(palette.primary.main, 0.1)),
			color: ifFeature('encore', undefined, palette.primary.contrastText),
		},
		'&:active, &$active': {
			backgroundColor: ifFeature('encore', palette.primary.dark, darken(palette.primary.main, 0.1)),
			boxShadow: ifFeature('encore', undefined, 'inset 0 1px 2px 0 rgba(0, 0, 0, 0.15)'),
			'&&': {
				outline: undefined,
			},
		},
		'&&$focusVisible, &&:focus': {
			border: ifFeature('encore', `1px solid ${palette.primary.lighter}`),
			boxShadow: ifFeature(
				'encore',
				`0px 0px 0px 4px ${mixins.alpha?.(constructs.border.primary.medium, 0.1)}`,
				`${BASE_BOX_SHADOW}, 0 0 0 2px ${mixins.alpha?.(palette.primary.lightest, 0.35)}`
			),
		},
		'&$disabled': {
			backgroundColor: ifFeature('encore', undefined, palette.common.white),
			boxShadow: ifFeature('encore', undefined, TRANSPARENT_BOX_SHADOW),
		},
	},
	containedSecondary: {
		fill: ifFeature('encore', palette.primary.main, palette.grey[800]),
		boxShadow: ifFeature('encore', undefined, '0 1px rgba(0, 0, 0, 0.03)'),
		backgroundColor: ifFeature('encore', palette.common.white),
		border: ifFeature('encore', `1px solid ${palette.primary.main}`),
		color: ifFeature('encore', palette.primary.main),
		'&:hover': {
			backgroundColor: ifFeature('encore', palette.primary.lightest, palette.grey[300]),
			color: ifFeature('encore', undefined, palette.grey[900]),
		},
		'&:active, &$active': {
			backgroundColor: ifFeature('encore', palette.primary.lightest, palette.grey[300]),
			boxShadow: ifFeature('encore', undefined, 'inset 0 1px 2px rgba(0, 0, 0, 0.15)'),
		},
		'&&$focusVisible, &&:focus': {
			border: ifFeature('encore', `1px solid ${palette.primary.lighter}`),
			boxShadow: ifFeature(
				'encore',
				`0px 0px 0px 4px ${mixins.alpha?.(constructs.border.primary.medium, 0.1)}`,
				`${BASE_BOX_SHADOW}, 0 0 0 2px ${palette.grey[400]}`
			),
		},
		'&.MuiButtonGroup-grouped:not(:focus):not($focusVisible)': ifFeature(
			'encore',
			{
				borderColor: palette.primary.main,
			},
			{}
		),
		'&$disabled': {
			borderColor: ifFeature('encore', 'transparent'),
			backgroundColor: ifFeature('encore', undefined, palette.common.white),
			boxShadow: ifFeature('encore', undefined, TRANSPARENT_BOX_SHADOW),
		},
	},
});
