import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { mediumScreenWidth } from '~definitions/json/mobile.json';

/* @startCleanup encore */
const jadeStyles = {
	'&:before': {
		content: '""',
		flex: 45,
	},
	'&:after': {
		content: '""',
		flex: 55,
	},
};
/* @endCleanup encore */

export const useStyles = makeStyles<
	void,
	| 'backDrop'
	| 'backDropAfterOpen'
	| 'backDropBeforeClose'
	| 'backDropInline'
	| 'backDropPartiallyOpaque'
	| 'content'
	| 'contentAfterOpen'
	| 'contentBeforeClose'
	| 'loader'
	| 'verticalFade'
	| 'zoomFade'
>()(({ breakpoints, mixins, palette, spacing }, _theme, classes) => {
	return {
		backDrop: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', mixins.alpha(palette.grey[700], 0.9), palette.gray[100]),
			bottom: 0,
			display: 'flex',
			flexDirection: 'column',
			left: 0,
			marginBottom: 'auto',
			marginTop: 'auto',
			opacity: 0,
			position: 'fixed',
			right: 0,
			top: 0,
			...ifFeature('encore', { justifyContent: 'center' }, jadeStyles),
			'@media print': {
				background: 'none', // Hides the backdrop when printing
			},
		},
		backDropAfterOpen: {
			[`&.${classes.backDrop}`]: {
				opacity: '1',
			},
			[`&.${classes.backDropPartiallyOpaque}`]: {
				backgroundColor: ifFeature(
					'encore',
					`rgba(${mixins.hexToRgb(palette.gray[400])},0.95)`,
					`rgba(${mixins.hexToRgb(palette.gray[200])},0.95)`
				),
			},
		},
		backDropBeforeClose: {
			[`&.${classes.backDrop}`]: {
				opacity: 0,
				transitionDuration: '250ms',
			},
		},
		backDropInline: {
			position: 'absolute',
		},
		backDropPartiallyOpaque: {
			backgroundColor: palette.gray[200],
		},
		content: {
			boxSizing: ifFeature('encore', 'border-box'),
			padding: ifFeature('encore', spacing(5)),
			position: 'relative',
			width: '100%',
			[breakpoints.down(mediumScreenWidth)]: ifFeature('encore', {
				padding: spacing(1.5),
			}),
			'@media print': {
				padding: 0,
			},
		},
		contentAfterOpen: {},
		contentBeforeClose: {},
		loader: {
			alignItems: 'center',
			bottom: '0',
			display: 'flex',
			justifycontent: 'center',
			left: '0',
			position: 'absolute',
			right: '0',
			top: '0',
		},
		verticalFade: {
			[`& .${classes.backDrop}`]: {
				transition: `opacity 350ms ease`,
			},
			[`& .${classes.content}`]: {
				transform: 'translateY(-20px)',
				transition: 'transform 350ms ease',
			},
			[`&& .${classes.contentAfterOpen}`]: {
				transform: 'none',
			},
			[`&&& .${classes.contentBeforeClose}`]: {
				transform: 'translateY(20px)',
				transition: 'transform 250ms ease',
			},
		},
		zoomFade: {
			[`.${classes.backDrop}`]: {
				transition: `opacity 200ms linear`,
			},
			[`.${classes.content}`]: {
				transform: 'scale(0)',
				transition: 'transform 200ms ease 50ms',
			},
			[`.${classes.contentAfterOpen}`]: {
				transform: 'scale(1)',
			},
			[`.${classes.contentBeforeClose}`]: {
				transform: 'scale(0)',
				transitionDuration: '250ms',
			},
		},
	};
});
