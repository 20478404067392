import React, { ReactElement, Ref } from 'react';
import { BodyTextProps } from './body-text.types';
import { useStyles } from './body-text.styles';
import { COMPONENTS } from './constants';
import { type IconV2Size } from '~components/icon-v2';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BaseIconV2 } from '~components/icon-v2/base-icon-v2';

function getIconSize(size: BodyTextProps['size']): IconV2Size {
	if (!size) {
		return 16;
	}

	const sizeToLineHeightMap: Partial<Record<typeof size, IconV2Size>> = {
		'emphasis-extra-small': 20,
		'emphasis-small': 20,
		'emphasis-medium': 24,
		'extra-extra-small': 12,
		'extra-small': 12,
	};

	return sizeToLineHeightMap[size] || 16;
}

/**
 * A component for displaying body text.
 *
 * By default, this components renders text inside of a paragraph element. **Special care needs to be taken when
 * nesting two instances of this component as it will result in invalid HTML.** When nesting this component,
 * use the `component` prop to change the element type to an inline element.
 */
export function BodyText<C extends (typeof COMPONENTS)[number] = 'p'>({
	children,
	color,
	component,
	containerRef,
	icon,
	id,
	inline,
	italic,
	justify,
	size,
	tabularNums = false,
	weight,
	whiteSpace,
}: BodyTextProps<C>): ReactElement {
	const { classes } = useStyles({ color, icon, inline, italic, justify, size, tabularNums, weight, whiteSpace });
	const iconSize = getIconSize(size);
	const Component = component || ('p' as (typeof COMPONENTS)[number]);

	return (
		<Component className={classes.root} id={id} ref={containerRef as Ref<any>}>
			{icon && (
				<span className={classes.icon}>
					{ifFeature(
						'encore',
						typeof icon === 'string' ? (
							<BaseIconV2 fill="currentColor" height={iconSize} name={icon} width={iconSize} />
						) : (
							icon
						),
						icon
					)}
				</span>
			)}
			{icon && !inline ? <span>{children}</span> : children}
		</Component>
	);
}
