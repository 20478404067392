import { Select } from '@fabric/select';
import React from 'react';

import { getSelectMonthItems, getSelectMonthOnChangeParam } from './select-month.domain';
import { MonthValue, SelectMonthProps } from '../../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SelectMonth({
	disabled = false,
	getMonthDisabled,
	months,
	onChange,
	onClose,
	onOpen,
	value,
}: SelectMonthProps): JSX.Element {
	const items = getSelectMonthItems(months, getMonthDisabled);

	return (
		<Select
			aria-label={window.jQuery ? $.__('Select Month') : 'Select Month'}
			isClearable={false}
			isDisabled={disabled}
			items={items}
			onChange={handleChange}
			onClose={onClose}
			onOpen={onOpen}
			selectedValues={value ? [value] : undefined}
			showSearch="never"
			size="small"
			variant={ifFeature('encore', 'single')}
			width={1}
		/>
	);

	function handleChange(values: Array<MonthValue>): void {
		const param = getSelectMonthOnChangeParam(values);
		onChange(param);
	}
}
