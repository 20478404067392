import { Payload, State } from './types';
import { FilterItem } from '../../types';
export class ReducerActions {
	allFilterItems: FilterItem[] = [];

	onChange: (data: { filters: FilterItem[] }) => void;

	constructor(allFilterItems: FilterItem[], onChange?: (data: { filters: FilterItem[] }) => void) {
		if (!Array.isArray(allFilterItems)) {
			throw new Error(`Array expected for allFilterItems. Received: ${typeof allFilterItems}`);
		}

		this.allFilterItems = allFilterItems;
		if (onChange) {
			this.onChange = onChange;
		}
	}

	getAvailableFilterItems(filter?: FilterItem): FilterItem[] {
		if (typeof filter?.filterId !== 'string') {
			return [...this.allFilterItems];
		}
		return this.allFilterItems.filter(item => {
			return item.filterId !== filter?.filterId;
		});
	}

	clearFirstFilter(state: State) {
		const updatedState = { ...state };
		if (state.secondSelectedFilter) {
			updatedState.firstSelectedFilter = { ...state.secondSelectedFilter };
			updatedState.firstFilterItems = [...state.secondFilterItems];
			updatedState.secondFilterItems = this.getAvailableFilterItems(updatedState?.firstSelectedFilter);
			updatedState.secondSelectedFilter = undefined;
		} else {
			updatedState.firstFilterItems = [...this.allFilterItems];
			updatedState.firstSelectedFilter = undefined;
		}

		try {
			if (typeof this.onChange === 'function') {
				const selectedFilter = updatedState?.firstSelectedFilter ? [{ ...updatedState?.firstSelectedFilter }] : [];
				this.onChange({ filters: selectedFilter });
			}
		} catch (e) {
			console.error(e);
		}
		return updatedState;
	}

	clearSecondFilter(state: State) {
		const updatedState = { ...state };
		updatedState.secondSelectedFilter = undefined;
		updatedState.secondFilterItems = this.getAvailableFilterItems(updatedState?.firstSelectedFilter);
		try {
			if (typeof this.onChange === 'function') {
				const selectedFilter = updatedState?.firstSelectedFilter ? [{ ...updatedState?.firstSelectedFilter }] : [];
				this.onChange({ filters: selectedFilter });
			}
		} catch (e) {
			console.error(e);
		}
		return updatedState;
	}

	selectFirstFilter(state: State, payload: Payload) {
		const updatedState = { ...state };
		const { selectedFilterItem } = payload;
		if (selectedFilterItem) {
			const updatedFilters = { ...selectedFilterItem.item };
			if (selectedFilterItem?.parentItem?.items) {
				updatedState.firstFilterItems = selectedFilterItem.parentItem.items;
				updatedState.secondFilterItems = this.getAvailableFilterItems(updatedFilters);
			}
			updatedState.firstSelectedFilter = updatedFilters;
			try {
				if (typeof this.onChange === 'function') {
					this.onChange({ filters: [{ ...updatedState.firstSelectedFilter }] });
				}
			} catch (e) {
				console.error(e);
			}
		}
		return updatedState;
	}

	selectSecondFilter(state: State, payload: Payload) {
		const updatedState = { ...state };
		const { selectedFilterItem } = payload;
		if (selectedFilterItem) {
			const updatedFilters = { ...selectedFilterItem.item };
			if (selectedFilterItem?.parentItem?.items) {
				updatedState.secondFilterItems = selectedFilterItem.parentItem.items;
			}
			updatedState.secondSelectedFilter = updatedFilters;
			try {
				if (typeof this.onChange === 'function') {
					const selectedFilter = updatedState?.firstSelectedFilter
						? [{ ...updatedState?.firstSelectedFilter }, { ...updatedState.secondSelectedFilter }]
						: [{ ...updatedState.secondSelectedFilter }];
					this.onChange({ filters: selectedFilter });
				}
			} catch (e) {
				console.error(e);
			}
		}
		return updatedState;
	}
}
