import React, { FC, PropsWithChildren } from 'react';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';

import Icon, { FabricIconProps } from '@fabric/icon';

const PREFIX = 'fab-';
const iconLogger = createLogger('<FabricIcon />');

/**
 * Checks for "fab-" icon name prefix, adds it if not present, and warns if added.
 */
export function applyPrefix(name: string): string {
	if (name.startsWith(PREFIX)) {
		return name;
	}

	iconLogger.warn(`"${PREFIX}" prefix automatically added to icon name: "${name}"`);

	return PREFIX + name;
}

/**
 * @deprecated Use Grim icons instead.
 * Wrapper component for Icon which enforces "fab-" prefix for name prop,
 * intended for use for all icons in all other Fabric components
 */
export const FabricIcon: FC<PropsWithChildren<FabricIconProps>> = ({ name, ...props }) => <Icon {...props} name={applyPrefix(name)} />;

FabricIcon.displayName = 'FabricIcon';

export default FabricIcon;
