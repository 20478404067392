import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { getWeekArray, getWeekdays } from './date-picker-utils-shared';

export class DatePickerUtilsLuxon extends LuxonUtils {
	getWeekArray = (date: DateTime): Array<Array<DateTime>> => {
		const weeks = getWeekArray(this, date);
		return weeks as Array<Array<DateTime>>;
	};

	getWeekdays = (): Array<string> => {
		const weekdays = getWeekdays(this.locale);
		return weekdays;
	};
}
