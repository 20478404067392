import { ModeOptions, dyeColors, makeStyles } from '~styles';

export const useStyles = makeStyles()(({ mode, palette, typography }) => ({
	root: {
		boxSizing: 'border-box',
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		position: 'relative',
	},
	searchInput: {
		backgroundColor: 'white',
		border: '0',
		borderBottom: `1px solid ${palette.gray[300]}`,
		boxShadow: '0 1px 2px -1px rgba(0, 0, 0, 0.1)',
		boxSizing: 'border-box',
		fontSize: typography.fabricFontSize('small'),
		height: '42px',
		paddingLeft: 10,
		paddingTop: 1,
		width: '100%',
		'&:focus': {
			outline: `2px solid ${palette.primary.main}`,
		},
	},
	searchInputWithIcon: {
		paddingLeft: '33px',
	},
	searchInputIconContainer: {
		left: 10,
		position: 'absolute',
		top: 12,
	},
	searchInputIcon: {
		'& > svg': {
			fill: palette.gray[500],
		},
	},
	searchInputIconFocused: {
		'& > svg': {
			fill: palette.primary.main,
		},
	},
}));
