/* eslint-disable max-classes-per-file */
import React, {
	Component,
	createRef,
	ExoticComponent,
	isValidElement as isReactObject,
	PropsWithoutRef,
	RefAttributes,
	ReactElement,
	RefObject,
	ReactNode,
} from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { has, isEqual, isFunction, uniqueId } from 'lodash';
import Icon from '@fabric/icon';
import { Loader } from '~components/loader';
import co from 'classnames';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import { Triangle9x5 } from '@bamboohr/grim';
import { Flex } from '~components/flex';
import { defaultSorter } from './default-sorter';
import { CheckboxCell } from './checkbox-cell';
import { CheckboxHeader } from './checkbox-header';
import { ActionsCell } from './actions-cell';
import { styles } from './styles';
import {
	CollapsibleRow,
	TableActionCell,
	TableCellFunction,
	TableCellObject,
	TableCheckboxCell,
	TableColumn,
	TableGroup,
	TableMembers,
	TableProps,
	TableRow,
	TableRowKey,
	TableSort,
	TableState,
	TableStyleClasses,
	TableTooltipAdapter,
	TableGroupedRows,
} from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2, IconV2Name } from '~components/icon-v2';
import composeRefs from '@seznam/compose-react-refs';
import { ScreenReaderOnlyText } from '~components/screen-reader-only-text';

/**
 * IconButtons in table need tooltips. Since the table component could be used in various
 * environments, we exposed a simple API to configure the table on how to create a tooltip.
 * The tooltip should be configured to destroy itself automatically when hidden, otherwise
 * you can end up with multiple tooltips on the same element
 *
 * used in actions-cell.js
 *
 * import Table from '@fabric/table';
 * Table.tooltipAdapter = {
 *     show(element, title, content) {
 *         // show your tooltip implementation and destroy when no longer needed
 *     }
 * }
 */
export let isTooltipAdapterSet = false;
export let _tooltipAdapter: TableTooltipAdapter = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	show(): void {},
};

export const tableDev = createLogger('<Table>');

export { defaultSorter };

export function GenericTable<T>(): ExoticComponent<PropsWithoutRef<TableProps<T>> & RefAttributes<TableMembers>> {
	class Table extends Component<TableProps<T> & WithStyles<typeof styles>, TableState> {
		constructor(props: TableProps<T> & WithStyles<typeof styles>) {
			super(props);

			const { initialSort, onSort, preSorted, rows, rowSelectedBy, onRowSelection, stickyColumn } = props;

			if (rowSelectedBy) {
				tableDev.warn(
					'Table prop `rowSelectedBy` is deprecated in favor of using the `selected` property in the cell.type: checkbox object.'
				);
			}

			if (onRowSelection) {
				tableDev.warn(
					'Table prop `onRowSelection` is deprecated in favor of `onRowSelect`. See the docs for more information.'
				);
			}

			if (stickyColumn) {
				tableDev.warn(
					'Table prop `stickyColumn` is deprecated in favor of specifying the total number of columns you want stickied with the `stickyColumns` property.'
				);
			}

			this._uiRows = rows;
			/**
			 * When dealing with a checkbox table, you can shift click to select rows.
			 * Keep track of what the last row was in case of a shift-click
			 */
			this._lastClickedRow = null;

			/**
			 * A basic list of elements that should not trigger a row selection on click
			 * Used when dealing with a table using the built-in checkboxes
			 */
			this.rowSelectionSelectorBlacklist = [
				'a',
				'button',
				'select',
				'textarea',
				'input:not(.js-fab-table-checkbox-input)',
				'.fab-Checkbox:not(.js-fab-table-checkbox)',
			];

			/**
			 * If onSort is specified, the dev is planning on custom sorting their table
			 * initialSort, an object, is described in the table docs
			 */
			const startingSortState = onSort ? preSorted : initialSort;

			// eslint-disable-next-line react/state-in-constructor
			this.state = {
				columnIndex: null,
				isAsc: true,
				isScrolledLeftHorizontally: false,
				isScrolledRightHorizontally: false,
				collapsibleRowsState: [],
				stickyColumnLeftOffsets: [],
				...startingSortState,
			};

			/**
			 * Detects if the table is horizontally scrollable,
			 * and when it is scrolled to lock the first column
			 */
			this.intersectionObserver = null;
			this.tableWrapRef = createRef();
			this.leftScrollIndicatorRef = createRef<HTMLDivElement>();
			this.rightScrollIndicatorRef = createRef<HTMLDivElement>();
			this.uuid = props.uuid ? props.uuid : uniqueId();
			this.collapsibleRowButtonRef = createRef<HTMLDivElement>();
		}

		static set tooltipAdapter(adapter: { show: () => void }) {
			if (!isTooltipAdapterSet && typeof adapter === 'object' && has(adapter, 'show')) {
				isTooltipAdapterSet = true;
				_tooltipAdapter = adapter;
			} else {
				tableDev.warn('A tooltip adapter must be an object with a property called "show" that is a function.');
			}
		}

		static defaultProps = {
			headerVariant: '',
			isLoading: false,
			groupBy: (r: TableRow<T & { group: string }>) => r.group,
			groups: [],
			rowsHaveHoverEffect: undefined,
			rowKey: (r: TableRow<T & { id: string }>) => r.id,
			rows: [],
			stickyHeader: false,
			stickyColumn: false,
			stickyColumns: 0,
			horizontalScrollContainer: undefined,
			stickyGroupHeaders: false,
			removeBottomBorder: false,
			tabularNums: true,
		};

		/* eslint-disable-next-line class-methods-use-this */
		_createDefinedCellFunction(
			cell: TableCellObject,
			props: {
				biId?: string;
				rowIsSelected: boolean;
				rowSelectionIsDisabled: boolean;
				classes: TableStyleClasses;
				rowKey: TableRowKey;
			}
		) {
			switch (cell.type) {
				case 'checkbox': {
					const { biId, rowIsSelected, rowSelectionIsDisabled, rowKey } = props;
					return (row: TableRow<T>) => CheckboxCell({ biId, rowIsSelected, rowKey, rowSelectionIsDisabled }, row);
				}
				case 'actions': {
					const { actions } = cell as TableActionCell<T>;
					const { classes } = props;
					return (row: TableRow<T>) => ActionsCell({ actions, classes, row });
				}
				default: {
					tableDev.warn(`Unknown cell type "${cell.type}" was used. Empty <td> was rendered.`);
					return () => undefined;
				}
			}
		}

		removeSortState() {
			this.setState({
				columnIndex: null,
				isAsc: true,
			});
		}

		_getCheckboxTypeCell(): TableCheckboxCell<TableRow<T>> | undefined {
			const { columns } = this.props;
			const checkboxTypeColumn = columns.find(col => typeof col.cell === 'object' && col.cell.type === 'checkbox');
			return checkboxTypeColumn ? (checkboxTypeColumn.cell as TableCheckboxCell<TableRow<T>>) : undefined;
		}

		/**
		 * Gets the number of columns that should be stickied. If `stickyColumns` isn't provided but the deprecated prop `stickyColumn` is, then return 1.
		 * @returns the number of columns to be stickied.
		 */
		_getStickyColumns() {
			const { stickyColumns, stickyColumn } = this.props;
			if (!stickyColumns && stickyColumn) {
				return 1;
			}
			return stickyColumns ?? 0;
		}

		/**
		 * Gets what classes and inline styles to apply to a cell if sticky columns are enabled.
		 * @param index The index of the column
		 * @returns An object containing what `classes` to apply and any inline styles to use.
		 */
		_getStickyColumnStyles(index: number) {
			const { isScrolledLeftHorizontally, isScrolledRightHorizontally, stickyColumnLeftOffsets } = this.state;
			const columns = this._getStickyColumns();

			const isStickyColumn = columns > index;
			const isLastStickyColumn = columns - 1 === index;

			return {
				classes: {
					stickyLeft: isStickyColumn && (isScrolledLeftHorizontally || isScrolledRightHorizontally),
					stickyLeftBorder: isLastStickyColumn && (isScrolledLeftHorizontally || isScrolledRightHorizontally),
					stickyLeftScreen: isLastStickyColumn && isScrolledLeftHorizontally,
				},
				inlineStyles: {
					left:
						isStickyColumn && (isScrolledLeftHorizontally || isScrolledRightHorizontally)
							? stickyColumnLeftOffsets[index] ?? 0
							: undefined,
				},
			};
		}

		/**
		 * If `stickyColumns` is set to 2 or more, we need to set the `left` css property on our sticky columns to the previous cells width.
		 * This function looks for the first row that contains all of our cells so we can calculate the left offset for each sticky column.
		 *
		 * This does not reset state if our left offsets didn't change.
		 */
		_resyncStickyColumnWidths() {
			const { stickyColumnLeftOffsets } = this.state;
			const stickyColumns = this._getStickyColumns();

			// if we have 1 or fewer sticky columns, abort early, we don't need left offsets
			if (stickyColumns <= 1) return;

			const table = this.tableWrapRef.current?.querySelector('table');

			// if we can't find a table, abort
			if (!table) return;

			// look for the first table row where the first cells that need to be stickied have no column spans
			const tableRow = Array.from(table.rows).find(row => {
				// only check the first columns that need to be stickied
				for (let i = 0; i < row.cells.length && i < stickyColumns; i++) {
					const cell = row.cells[i];
					// if the cell spans more than 1 column, check the next row
					if (cell.colSpan > 1) {
						return false;
					}
				}
				return true;
			});
			if (!tableRow) return;

			// get the offsetWidth for each column in our table
			const columnWidths = Array.from(tableRow.cells, p => p.offsetWidth);

			// loop through the number of sticky columns we have and create an array with the sum of all previous column widths
			// the first index should always be zero because we don't need to offset it, but each additional after 1 needs to be offset
			const nextLeftOffsets: Array<number> = [];
			let sumOfColumnWidths = 0;
			for (let i = 0; i < columnWidths.length && i < stickyColumns; i++) {
				nextLeftOffsets.push(sumOfColumnWidths);
				sumOfColumnWidths += columnWidths[i];
			}

			// if our existing stored offsets, match the length of new ones, check if all offsets match
			if (stickyColumnLeftOffsets.length === nextLeftOffsets.length) {
				let allMatch = true;
				for (let i = 0; i < stickyColumnLeftOffsets.length; i++) {
					if (stickyColumnLeftOffsets[i] !== nextLeftOffsets[i]) {
						allMatch = false;
						break;
					}
				}

				// if all values match, then abort, we don't need to update state and cause unnecessary re-renders
				if (allMatch) return;
			}

			// if we've gotten here, it means we have new/different offsets to update
			this.setState({
				stickyColumnLeftOffsets: nextLeftOffsets,
			});
		}

		_getSorter(column: TableColumn<TableRow<T>>) {
			const { isAsc } = this.state;
			const { sort, sortBy } = column;
			if (typeof sort === 'function') {
				return (rows: TableRow<T>[]) => [...rows].sort(isAsc ? sort : (a, b) => sort(b, a));
			}
			if (typeof sortBy === 'function') {
				return (rows: TableRow<T>[]) => defaultSorter(rows, sortBy, isAsc);
			}

			throw new Error(`Table column object ${JSON.stringify(column)} is missing a required \`sort\` or \`sortBy\` function.`);
		}

		_makeGetRowSelection() {
			const { rowSelectedBy = (row: { selected?: boolean }) => !!row.selected } = this.props;
			let finder = rowSelectedBy;

			const checkboxCell = this._getCheckboxTypeCell();
			if (checkboxCell && checkboxCell.selected) {
				finder = checkboxCell.selected;
			}

			return finder as (row: TableRow<T>) => boolean;
		}

		_makeCheckRowSelectionIsDisabled() {
			let finder: (row: TableRow<T>) => boolean = () => false;

			const checkboxCell = this._getCheckboxTypeCell();
			if (checkboxCell && checkboxCell.disabled) {
				finder = checkboxCell.disabled;
			}

			return finder;
		}

		_renderDefinedHeaderType(type: string, index: number) {
			const { biId, rows, classes, headerVariant, stickyHeader, stickyHeaderTop } = this.props;
			const { uuid } = this;

			const ifRowIsSelected = this._makeGetRowSelection();
			const ifRowSelectionIsDisabled = this._makeCheckRowSelectionIsDisabled();

			const stickyColumn = this._getStickyColumnStyles(index);

			switch (type) {
				case 'checkbox': {
					const allRowsAreDisabled = rows.filter(ifRowSelectionIsDisabled).length === rows.length;

					return CheckboxHeader({
						biId,
						checked: !!(
							rows.length &&
							!allRowsAreDisabled &&
							rows.filter(not(ifRowSelectionIsDisabled)).every(ifRowIsSelected)
						),
						classes,
						headerVariant,
						left: stickyColumn.inlineStyles.left,
						onToggleAll: this._handleSelectingAllRows,
						stickyHeader,
						stickyHeaderTop,
						stickyLeft: stickyColumn.classes.stickyLeft,
						stickyLeftBorder: stickyColumn.classes.stickyLeftBorder,
						stickyLeftScreen: stickyColumn.classes.stickyLeftScreen,
						uuid,
					});
				}
				default:
					return null;
			}
		}

		_getSort(): TableSort {
			const { manualSort } = this.props;
			if (manualSort) {
				return manualSort;
			}
			const { columnIndex, isAsc } = this.state;
			return {
				columnIndex,
				isAsc,
			};
		}

		_renderTableHeaderRow(noBottomBorderRadius: boolean) {
			const { classes, columns, stickyHeader, stickyHeaderTop, headerVariant } = this.props;
			const { columnIndex: sortedColumnIndex, isAsc } = this._getSort();
			const isSorted = sortedColumnIndex !== null;
			const headless = isHeadless(columns);

			return (
				<tr>
					{(() => {
						const tableHeaderCells: Array<ReactNode> = [];

						for (let i = 0; i < columns.length; i++) {
							const { align, headerPaddingReset, cell, key, sort, sortBy, width, headerColSpan } = columns[i];
							const canSort = !!(sort || sortBy);

							const { header, headerAriaLabel } = columns[i];

							if (typeof header === 'object' && !isReactObject(header) && header.type) {
								tableHeaderCells.push(this._renderDefinedHeaderType(header.type as string, i));
							} else {
								const stickyColumn = this._getStickyColumnStyles(i);

								if (headerColSpan && headerColSpan > 1) {
									i += headerColSpan - 1;
								}

								if (!header && !headerAriaLabel) {
									tableDev.warn(
										'When a column does not have a header, it should use the headerAriaLabel property to describe the content of the table for accessibility purposes.'
									);
								}

								const reactKey = key || (cell && (cell as string).toString());
								const columnIsSorted = isSorted && sortedColumnIndex === i;

								const classNames = co(classes.header, {
									[classes.sortableHeader]: !headless && canSort,
									[classes.alignCenter]: align === 'center',
									[classes.alignRight]: align === 'right',
									[classes.noVerticalPadding]: headerPaddingReset === 'all' || headerPaddingReset === 'vertical',
									[classes.noHorizontalPadding]: headerPaddingReset === 'all' || headerPaddingReset === 'horizontal',
									[classes.stickyTop]: stickyHeader,
									[classes.stickyLeft]: stickyColumn.classes.stickyLeft,
									[classes.stickyLeftBorder]: stickyColumn.classes.stickyLeftBorder,
									[classes.stickyLeftScreen]: stickyColumn.classes.stickyLeftScreen,
									// @startCleanup encore
									[classes.noBottomBorderRadius]: ifFeature('encore', false, noBottomBorderRadius),
									// @endCleanup encore
									[classes.whiteHeaderVariant]: headerVariant === 'white',
								});

								const inlineStyles = {
									left: stickyColumn.inlineStyles.left,
									minWidth: width,
									top: stickyHeaderTop || undefined,
									width,
								};

								// @startCleanup encore
								const sortedClassNames = co([classes.sortIcon], {
									[classes.sortIconAscending]: columnIsSorted && isAsc,
								});
								// @endCleanup encore

								tableHeaderCells.push(
									<th
										aria-label={header ? undefined : headerAriaLabel}
										className={classNames}
										colSpan={headerColSpan && headerColSpan > 1 ? headerColSpan : undefined}
										key={reactKey}
										onClick={!headless && canSort ? () => this._handleSort(i) : undefined}
										scope="col"
										style={inlineStyles}
									>
										{(() => {
											if (!header && headerAriaLabel) {
												// a11y checks require content in the header
												return <ScreenReaderOnlyText>{headerAriaLabel}</ScreenReaderOnlyText>;
											}
											if (columnIsSorted) {
												return ifFeature(
													'encore',
													<span className={classes.sortIcon}>
														{header}
														<IconV2
															color="neutral-strong"
															name={isAsc ? 'arrow-up-regular' : 'arrow-down-regular'}
															size={12}
														/>
													</span>,
													<span className={sortedClassNames}>{header}</span>
												);
											}
											return header;
										})()}
									</th>
								);
							}
						}
						return tableHeaderCells;
					})()}
				</tr>
			);
		}

		_renderRows(rows: Array<TableRow<T>> = [], hasVisibleGroupNext = false, isCollapsibleRow = false) {
			const { collapsibleRowsState } = this.state;
			const { rowKey: rowKeyFinder = Table.defaultProps.rowKey } = this.props;

			return rows.map((row, rowI) => {
				const rowKey = rowKeyFinder(row);
				const mainRowElement = this._renderRow(rows, hasVisibleGroupNext, row, rowI, isCollapsibleRow);
				if (this._isCollapsibleTableRow(row)) {
					const isRowExpanded = collapsibleRowsState?.find(collapsibleRow => collapsibleRow.tableRowKey === rowKey)
						?.isExpanded;
					if (isRowExpanded) {
						const rowWithCollapsibleRows = row as T & { collapsibleRows: Array<TableRow<T>> };
						const collapsibleRowsElements = this._renderRows(
							rowWithCollapsibleRows.collapsibleRows,
							hasVisibleGroupNext,
							true
						);

						return [mainRowElement, ...collapsibleRowsElements];
					}
				}
				return mainRowElement;
			});
		}

		_renderRow(
			rows: Array<TableRow<T>> = [],
			hasVisibleGroupNext = false,
			row: TableRow<T>,
			rowI: number,
			isCollapsibleRow = false
		) {
			const {
				biId,
				classes,
				columns,
				rowKey: rowKeyFinder = Table.defaultProps.rowKey,
				rowEmphasizedBy,
				rowHighlightedBy,
				rowMutedBy,
				onRowMouseEnter,
				onRowMouseLeave,
				removeBottomBorder = false,
			} = this.props;

			const { isScrolledRightHorizontally, collapsibleRowsState } = this.state;

			const ifRowIsSelected = this._makeGetRowSelection();
			const ifRowSelectionIsDisabled = this._makeCheckRowSelectionIsDisabled();

			const rowIsSelected = ifRowIsSelected(row);
			const rowSelectionIsDisabled = ifRowSelectionIsDisabled(row);
			const rowKey = rowKeyFinder(row);
			const rowIsMuted = typeof rowMutedBy === 'function' ? rowMutedBy(row) : false;
			const isLastRow = rowI === rows.length - 1;
			const hasCollapsibleRows = this._isCollapsibleTableRow(row);
			const rowClasses = co(classes.row, {
				[classes.removeBottomBorder]: (removeBottomBorder && isLastRow) || (isLastRow && hasVisibleGroupNext),
				[classes.rowEmphasized]: rowEmphasizedBy ? rowEmphasizedBy(row) : false,
				[classes.rowHighlighted]: rowHighlightedBy ? rowHighlightedBy(row) : false,
				[classes.rowSelected]: rowIsSelected && !rowSelectionIsDisabled,
				[classes.rowCollapsible]: isCollapsibleRow,
			});

			return (
				<tr
					className={rowClasses}
					key={rowKey}
					onClick={this._handleRowClick(rowKey)}
					onMouseEnter={
						isFunction(onRowMouseEnter)
							? e => {
									onRowMouseEnter(e.currentTarget, row);
								}
							: undefined
					}
					onMouseLeave={
						isFunction(onRowMouseLeave)
							? e => {
									onRowMouseLeave(e.currentTarget, row);
								}
							: undefined
					}
				>
					{(() => {
						const tds: Array<ReactNode> = [];

						for (let i = 0; i < columns.length; i++) {
							const { align, header, mute, cell, cellPaddingReset, colSpan, verticalAlign, width } = columns[i];

							let { showOnHover, keepInView = null } = columns[i];

							const isFirstColumn = i === 0;
							const isLastColumn = i === columns.length - 1;
							let spanCount: boolean | number = 1;

							if (typeof colSpan === 'function') {
								spanCount = colSpan(row);
								if (spanCount === true) {
									spanCount = columns.length - i;
								} else if (typeof spanCount !== 'number') {
									spanCount = 1;
								}
								if (spanCount > 1) {
									i += spanCount - 1;
								}
							}

							showOnHover = isFunction(showOnHover) ? showOnHover(row) : showOnHover;

							let definedType = '';
							let cellAsFunction: TableCellFunction<TableRow<T>> = cell as TableCellFunction<TableRow<T>>;
							if (typeof cell === 'object' && !isReactObject(cell) && cell.type) {
								definedType = cell.type;
								cellAsFunction = this._createDefinedCellFunction(cell, {
									biId,
									classes,
									rowIsSelected,
									rowKey,
									rowSelectionIsDisabled,
								});
							}
							const cellContent = typeof cell === 'string' ? cell : cellAsFunction(row, rowI, rows);

							let { key } = columns[i];
							if (!key) {
								if (typeof header === 'string') {
									key = header;
								} else if (typeof cell === 'string') {
									key = cell;
								}
							}

							let cellIsMuted = rowIsMuted;
							if (definedType === 'checkbox' && !rowIsSelected && rowSelectionIsDisabled) {
								cellIsMuted = false;
							} else if (typeof mute === 'boolean') {
								cellIsMuted = mute;
							} else if (isFunction(mute)) {
								cellIsMuted = mute(row);
							}

							const potentiallyMuted: ReactNode = cellIsMuted ? (
								<div className={classes.mutedSubCell}>{cellContent}</div>
							) : (
								cellContent
							);
							const potentiallyMaskedAndMuted: ReactNode = showOnHover ? (
								<div className={classes.cellMask}>{potentiallyMuted}</div>
							) : (
								potentiallyMuted
							);

							if (isFunction(keepInView)) {
								keepInView = !!keepInView(row);
							} else if (keepInView !== null) {
								keepInView = !!keepInView;
							}

							const shouldKeepCellContentsInView =
								isLastColumn &&
								keepInView !== false &&
								isScrolledRightHorizontally &&
								(definedType === 'actions' || keepInView === true);

							const stickyColumn = this._getStickyColumnStyles(i);

							const cellClasses = co(classes.cell, {
								[classes.stickyRight]: shouldKeepCellContentsInView,
								[classes.alignCenter]: align === 'center',
								[classes.alignRight]: align === 'right',
								[classes.verticalAlign]: verticalAlign,
								[classes.minimalRightPadding]: definedType === 'checkbox',
								[classes.noVerticalPadding]: cellPaddingReset === 'all' || cellPaddingReset === 'vertical',
								[classes.noHorizontalPadding]: cellPaddingReset === 'all' || cellPaddingReset === 'horizontal',
								[classes.stickyLeft]: stickyColumn.classes.stickyLeft,
								[classes.stickyLeftBorder]: stickyColumn.classes.stickyLeftBorder,
								[classes.stickyLeftScreen]: stickyColumn.classes.stickyLeftScreen,
								[classes.noBottomBorder]: isLastRow && hasVisibleGroupNext,
								// @startCleanup encore
								[classes.triangleIcon]: ifFeature('encore', undefined, hasCollapsibleRows && isFirstColumn),
								// @endCleanup encore
							});
							const isRowExpanded = collapsibleRowsState?.find(collapsibleRow => collapsibleRow.tableRowKey === rowKey)
								?.isExpanded;
							const iconAriaLabelExpanded = window.jQuery ? $.__('Collapse row') : 'Collapse row';
							const iconAriaLabelCollapsed = window.jQuery ? $.__('Expand row') : 'Expand row';

							const inlineStyles = {
								left: stickyColumn.inlineStyles.left,
							};

							tds.push(
								<td
									className={cellClasses}
									colSpan={spanCount > 1 ? spanCount : undefined}
									key={key}
									style={inlineStyles}
									width={width}
								>
									{hasCollapsibleRows && isFirstColumn ? (
										<Flex
											alignItems="center"
											aria-expanded={!!isRowExpanded}
											aria-label={isRowExpanded ? iconAriaLabelExpanded : iconAriaLabelCollapsed}
											gap={ifFeature('encore', 1)}
											onKeyPress={e => {
												if (e.code === 'Enter' || e.code === 'Space') {
													e.preventDefault();
													this._handleCollapsibleRowKeyPress(rowKey, e);
												}
											}}
											ref={this.collapsibleRowButtonRef}
											role="button"
											tabIndex={0}
										>
											{ifFeature(
												'encore',
												<div
													className={co(classes.tableExpandIcon, {
														[classes.tableExpandIconExpanded]: isRowExpanded,
													})}
												>
													<IconV2 name="caret-right-solid" size={16} />
												</div>,
												<Triangle9x5
													className={co(classes.tableExpandIcon, {
														[classes.tableExpandIconExpanded]: isRowExpanded,
													})}
												/>
											)}
											{potentiallyMaskedAndMuted}
										</Flex>
									) : (
										potentiallyMaskedAndMuted
									)}
								</td>
							);
						}

						return tds;
					})()}
				</tr>
			);
		}

		_renderGroupHeader({ id, title, icon, content, paddingReset }: TableGroup) {
			const { columns, classes, stickyGroupHeaders } = this.props;
			const groupIcon = icon ? (
				<div className={classes.groupIcon}>
					{typeof icon === 'string'
						? ifFeature('encore', <IconV2 name={icon as IconV2Name} size={12} />, <Icon name={icon} />)
						: icon}
				</div>
			) : null;

			// @startCleanup encore
			const groupTDClasses = co([classes.cell, classes.cellGroup].join(' '), {
				[classes.noVerticalPadding]: paddingReset === 'all' || paddingReset === 'vertical',
				[classes.noHorizontalPadding]: paddingReset === 'all' || paddingReset === 'horizontal',
				[classes.cellGroupSticky]: stickyGroupHeaders,
			});
			// @endCleanup encore

			const groupTHClasses = co([classes.cell, classes.cellGroup].join(' '), {
				[classes.cellGroupSticky]: stickyGroupHeaders,
			});

			const groupInnerClasses = co(classes.group, {
				[classes.noVerticalPadding]: paddingReset === 'all' || paddingReset === 'vertical',
				[classes.noHorizontalPadding]: paddingReset === 'all' || paddingReset === 'horizontal',
			});

			const columnsStickied = this._getStickyColumns() > 0;

			/* *note
				This provides at least 50% of the width of the table for the group header if being used with stickyColumns
				If not using stickyColumns, it should revert to the existing column length and leave everything the same.
			*/
			const columnSpan = columnsStickied ? Math.ceil(columns.length / 2) : columns.length;
			const columnSiblingSpan = columns.length - columnSpan;

			return (
				<tr key={id}>
					<th className={ifFeature('encore', groupTHClasses, groupTDClasses)} colSpan={columnSpan}>
						<div className={ifFeature('encore', groupInnerClasses, classes.group)}>
							{groupIcon}
							{content && <div className={classes.groupContent}>{content}</div>}
							{!content && title}
						</div>
					</th>
					{columnsStickied && (
						<th className={ifFeature('encore', groupTHClasses, groupTDClasses)} colSpan={columnSiblingSpan}></th>
					)}
				</tr>
			);
		}

		/**
		 * returns something like
		 * [
		 *     [GroupHeader, [GroupRow, GroupRow]],
		 *     [GroupHeader, [GroupRow, GroupRow]]
		 * ]
		 */
		_renderVisibleGroups(groups: TableGroup[], grouped: TableGroupedRows<T>): (ReactElement | ReactElement[])[][] {
			return groups
				.filter(group => {
					const { id, showIfEmpty } = group;
					const groupedRows = grouped && grouped[id];
					return groupedRows || showIfEmpty;
				})
				.map((group, i, allGroups) => {
					const { id } = group;
					const groupedRows = grouped && grouped[id];

					const hasGroupNext = i !== allGroups.length - 1 && !!allGroups[i + 1];

					return [this._renderGroupHeader(group), this._renderRows(groupedRows || [], hasGroupNext)];
				});
		}

		_isCollapsibleTableRow = (row: TableRow<T>): boolean => {
			if (typeof row === 'object' && row !== null) {
				return 'collapsibleRows' in (row as { collapsibleRows?: Array<TableRow<T>> });
			}
			return false;
		};

		_handleCollapsibleRowKeyPress = (clickedRowKey: TableRowKey, event: React.KeyboardEvent<HTMLDivElement>) => {
			const { rowKey = Table.defaultProps.rowKey, rowSelectedBlacklist = [] } = this.props;

			const selector = this.rowSelectionSelectorBlacklist.concat(rowSelectedBlacklist).join(',');

			if (event.target && (event.target as HTMLElement).closest(selector)) {
				return;
			}

			const uiRowKeys = (this._uiRows || []).map(rowKey);
			const rowIndex = uiRowKeys.findIndex(uiRowKey => {
				return uiRowKey === clickedRowKey;
			});
			this._handleSelectingRow(rowIndex, event.shiftKey);
			this._lastClickedRow = rowIndex;
		};

		_handleRowClick = (clickedRowKey: TableRowKey) => {
			const { rowKey = Table.defaultProps.rowKey, rowSelectedBlacklist = [] } = this.props;

			return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				// The following ifs deal with text selection
				const selection = window.getSelection();

				// In IE11 and Edge, when pressing shift while clicking on a new row, it highlights all the text in between
				// Anytime shift is held when clicking on a row, prevent text selection
				if (event.shiftKey) {
					const _selection = window.getSelection();
					if (_selection) {
						_selection.removeAllRanges();
					}
				}

				// If not using shift... check to see if the user is selecting text in the table row
				// This prevents the row from being selected if indeed the user is trying to select text
				if (selection && selection.toString().trim() !== '') {
					return;
				}

				const selector = this.rowSelectionSelectorBlacklist.concat(rowSelectedBlacklist).join(',');

				if (event.target && (event.target as HTMLElement).closest(selector)) {
					return;
				}

				// *note: Grouped rows have their indices shifted. We need to use the rowKey to find the real index
				const uiRowKeys = (this._uiRows || []).map(rowKey);
				const rowIndex = uiRowKeys.findIndex(uiRowKey => {
					return uiRowKey === clickedRowKey;
				});
				this._handleSelectingRow(rowIndex, event.shiftKey);
				this._lastClickedRow = rowIndex;
			};
		};

		_handleSelectingAllRows = () => {
			const { onRowSelection, onRowSelect, rows, rowKey = Table.defaultProps.rowKey } = this.props;

			if (!onRowSelect && !onRowSelection) {
				return;
			}

			const ifRowIsSelected = this._makeGetRowSelection();
			const ifRowSelectionIsDisabled = this._makeCheckRowSelectionIsDisabled();
			const selectableRows = rows.filter(not(ifRowSelectionIsDisabled));

			if (onRowSelect) {
				const rowsCurrentlyUnchecked = selectableRows.filter(not(ifRowIsSelected));
				const selecting = !!rowsCurrentlyUnchecked.length;
				const changedRows = selecting ? rowsCurrentlyUnchecked : selectableRows.filter(ifRowIsSelected);

				onRowSelect(changedRows.map(rowKey), selecting);
			} else {
				const someUnchecked = selectableRows.some(not(ifRowIsSelected));
				let changedKeys = new Set<TableRowKey>();

				if (someUnchecked) {
					changedKeys = new Set<TableRowKey>(selectableRows.map(rowKey));
				}

				if (typeof onRowSelection === 'function') {
					onRowSelection(changedKeys);
				}
			}
		};

		_handleSelectingRow = (clickedIndex: number, shiftKeyPressed: boolean) => {
			const { onRowSelection, onRowSelect, rowKey = Table.defaultProps.rowKey, rows } = this.props;
			const { collapsibleRowsState } = this.state;
			const clickedRow = this._uiRows[clickedIndex];
			const clickedRowKey = rowKey(clickedRow);

			if (clickedRow && this._isCollapsibleTableRow(clickedRow)) {
				const clickedRowState = collapsibleRowsState?.find(row => row.tableRowKey === clickedRowKey);
				let updatedCollapsibleRowsState: CollapsibleRow[] = [];
				if (clickedRowState) {
					updatedCollapsibleRowsState = collapsibleRowsState?.map(row => {
						if (row.tableRowKey === clickedRowKey) {
							return {
								...row,
								isExpanded: !row.isExpanded,
							};
						}
						return row;
					});
				} else {
					updatedCollapsibleRowsState = [...collapsibleRowsState, { tableRowKey: clickedRowKey, isExpanded: true }];
				}

				this.setState({
					collapsibleRowsState: updatedCollapsibleRowsState,
				});
			}

			if (!onRowSelection && !onRowSelect) {
				return;
			}

			const ifRowIsSelected = this._makeGetRowSelection();
			const ifRowSelectionIsDisabled = this._makeCheckRowSelectionIsDisabled();
			const selecting = !ifRowIsSelected(clickedRow);

			if (onRowSelect) {
				const changedRows: Array<TableRow<T>> = [];

				if (shiftKeyPressed && this._lastClickedRow !== null) {
					// determine what other indexes to toggle besides clickedIndex
					const start = Math.min(this._lastClickedRow, clickedIndex);
					const length = Math.abs(this._lastClickedRow - clickedIndex);
					// inclusive of _lastClickedRow index
					for (let i = 0; i <= length; i++) {
						const row = this._uiRows[i + start];
						if (!ifRowSelectionIsDisabled(row) && ifRowIsSelected(row) !== selecting) {
							changedRows.push(row);
						}
					}
				} else if (!ifRowSelectionIsDisabled(clickedRow)) {
					changedRows.push(clickedRow);
				}

				if (!changedRows.length) {
					return;
				}

				onRowSelect(changedRows.map(rowKey), selecting);
			} else {
				const keysOfSelectedRows = new Set(rows.filter(ifRowIsSelected).map(rowKey));
				const addOrDelete = selecting ? 'add' : 'delete';

				keysOfSelectedRows[addOrDelete](rowKey(clickedRow));

				if (shiftKeyPressed && this._lastClickedRow !== null) {
					// determine what other indexes to toggle besides clickedIndex
					const start = Math.min(this._lastClickedRow, clickedIndex);
					const length = Math.abs(this._lastClickedRow - clickedIndex);
					// inclusive of _lastClickedRow index
					for (let i = 0; i <= length; i++) {
						const row = this._uiRows[i + start];
						keysOfSelectedRows[addOrDelete](rowKey(row));
					}
				}

				if (onRowSelection) {
					onRowSelection(keysOfSelectedRows);
				}
			}
		};

		_handleSort = (columnIndex: number) => {
			const { columns, onSort } = this.props;
			const { columnIndex: sortedColumnIndex, isAsc } = this._getSort();

			const column = columns[columnIndex];

			let asc = true;

			if (columnIndex === sortedColumnIndex && isAsc) {
				asc = false;
			}

			this._lastClickedRow = null;

			this.setState({
				columnIndex,
				isAsc: asc,
			});

			if (onSort && typeof column.sortBy !== 'undefined') {
				onSort(columnIndex, column.sortBy, asc);
			}
		};

		_setupIntersectionObserver = () => {
			if (!window.IntersectionObserver) {
				return;
			}

			const { horizontalScrollContainer } = this.props;

			const observerOptions = {
				root: horizontalScrollContainer === undefined ? this.tableWrapRef.current : horizontalScrollContainer,
				rootMargin: '0px',
				// Why isn't this 1? Well because FireFox is 💩 and triggers on the right side between (0.95 and 1)
				threshold: 0.95,
			};

			this.intersectionObserver = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					const isFullyVisible = entry.isIntersecting && entry.intersectionRatio >= 0.95;
					if (entry.target === this.leftScrollIndicatorRef.current) {
						this.setState({
							isScrolledLeftHorizontally: !isFullyVisible,
						});
					}
					if (entry.target === this.rightScrollIndicatorRef.current) {
						this.setState({
							isScrolledRightHorizontally: !isFullyVisible,
						});
					}
				});
			}, observerOptions);

			if (this.leftScrollIndicatorRef && this.leftScrollIndicatorRef.current) {
				this.intersectionObserver.observe(this.leftScrollIndicatorRef.current);
			}

			if (this.rightScrollIndicatorRef && this.rightScrollIndicatorRef.current) {
				this.intersectionObserver.observe(this.rightScrollIndicatorRef.current);
			}
		};

		_destroyIntersectionObserver = () => {
			if (this.intersectionObserver) {
				this.intersectionObserver.disconnect();
				this.intersectionObserver = null;
			}
		};

		_getLastColumnCellType = (columns: Array<TableColumn<T>>): string | undefined => {
			const lastColumn = columns && columns[columns.length - 1];
			const lastColumnCell = typeof lastColumn === 'object' ? lastColumn.cell : undefined;

			return typeof lastColumnCell === 'object' ? lastColumnCell.type : undefined;
		};

		_lastClickedRow: null | number;

		_uiRows: Array<TableRow<T>>;

		intersectionObserver: IntersectionObserver | null;

		leftScrollIndicatorRef: RefObject<HTMLDivElement>;

		rightScrollIndicatorRef: RefObject<HTMLDivElement>;

		rowSelectionSelectorBlacklist: string[];

		tableWrapRef: RefObject<HTMLDivElement>;

		uuid: string;

		collapsibleRowButtonRef: RefObject<HTMLDivElement>;

		componentDidMount() {
			this._setupIntersectionObserver();
		}

		componentDidUpdate(prevProps, prevState: TableState) {
			const { collapsibleRowsState: prevCollapsibleRowsState } = prevState;
			const { collapsibleRowsState: currentCollapsibleRowsState } = this.state;

			if (!isEqual(currentCollapsibleRowsState, prevCollapsibleRowsState) && this.collapsibleRowButtonRef?.current) {
				this.collapsibleRowButtonRef?.current?.focus();
			}

			this._resyncStickyColumnWidths();
		}

		componentWillUnmount() {
			this._destroyIntersectionObserver();
		}

		render() {
			const {
				classes,
				biId,
				caption,
				columns,
				containerRef,
				id,
				isFixedLayout,
				isLoading,
				isZebra,
				note,
				onSort,
				rowKey,
				groups = [],
				groupBy: groupKey = Table.defaultProps.groupBy,
				maxHeight,
				maxWidth,
				horizontalScrollContainer,
			} = this.props;

			let { rows, rowsHaveHoverEffect } = this.props;

			const { columnIndex, isScrolledLeftHorizontally, isScrolledRightHorizontally } = this.state;

			const isUsingGroups = groups && groups.length > 0;
			let grouped: TableGroupedRows<T> = null;

			/**
			 * If not using a custom sorter, and a column is selected, sort the data.
			 */
			if (!onSort && columnIndex !== null) {
				rows = this._getSorter(columns[columnIndex])(rows);
			}

			// Needed to track the changes in rowSelection
			this._uiRows = rows;

			if (isUsingGroups) {
				const groupIds = groups.map(g => g.id);
				grouped = groupBy(rows, groupKey);
				rows = rows.filter(row => !groupIds.includes(groupKey(row as TableRow<T & { group: string }>)));
				// *note: groups change the indices of the rows, we need account for it in _uiRows
				if (Array.isArray(groupIds) && groupIds.length > 0) {
					this._uiRows = groupIds.reduce((memoRows, name) => {
						// *note: groups with "name" of undefined are not matched into a group and will be in "rows"
						if (name === 'undefined') {
							return memoRows;
						}
						let groupRows = grouped && grouped[name] ? grouped[name] : [];
						if (!Array.isArray(groupRows)) {
							groupRows = [];
						}
						return [...memoRows, ...groupRows];
					}, rows || []);
				}
			}
			rows?.forEach((row: TableRow<T>) => {
				if (this._isCollapsibleTableRow(row)) {
					const rowWithCollapsibleRows = row as T & { collapsibleRows: Array<TableRow<T>> };
					this._uiRows = [...this._uiRows, ...rowWithCollapsibleRows.collapsibleRows];
				}
			});

			if (!caption) {
				tableDev.warn(`Tables should have a caption. Please see the docs for more information.`);
			}

			if (!columns && rows && rows.length) {
				tableDev.error('Rows cannot be rendered without first defining columns.');
			}

			if (rows && rows.length && rowKey === undefined) {
				tableDev.error('Prop "rowKey" must be supplied when rendering rows.');
			}

			if (rowsHaveHoverEffect !== undefined) {
				rowsHaveHoverEffect = !!rowsHaveHoverEffect;
			}

			const rowShouldGetHoverEffect =
				rowsHaveHoverEffect === false
					? false
					: [
							rowsHaveHoverEffect === true,
							columns && columns.some(col => col.showOnHover),
							columns && this._getLastColumnCellType(columns) === 'actions',
						].some(_ => _);

			const tableClasses = co(classes.root, {
				[classes.fixedLayout]: isFixedLayout,
				[classes.headless]: isHeadless(columns),
				[classes.tableLoading]: isLoading,
				[classes.tableWithRowHover]: rowShouldGetHoverEffect,
				[classes.tableWithZebraStripes]: isZebra,
			});

			const wrapClasses = co(classes.wrap, {
				[classes.wrapOverflow]: horizontalScrollContainer === undefined,
			});

			const wrapInlineStyles = {
				maxHeight,
				maxWidth,
			};

			const firstRowIsGroup = isUsingGroups && rows.length === 0;

			const scrollLeftIndicatorClasses = co(classes.leftScrollIndicator, {
				[classes.leftScrollIndicatorWithScreen]: isScrolledLeftHorizontally,
			});

			const scrollRightIndicatorClasses = co(classes.rightScrollIndicator, {
				[classes.rightScrollIndicatorWithScreen]: isScrolledRightHorizontally,
			});

			const renderedVisibleGroups = this._renderVisibleGroups(groups, grouped);

			const tabProp = {
				tabIndex: horizontalScrollContainer === undefined ? 0 : -1,
			};

			const columnsStickied = this._getStickyColumns() > 0;

			return (
				<div
					className={wrapClasses}
					id={id}
					ref={composeRefs(this.tableWrapRef, containerRef)}
					style={wrapInlineStyles}
					{...tabProp}
				>
					<div className={classes.stickyIndicators}>
						<div className={classes.stickyIndicator} ref={this.leftScrollIndicatorRef} />
						<div className={classes.stickyIndicator} ref={this.rightScrollIndicatorRef} />
					</div>

					<div className={classes.innerWrap}>
						{!columnsStickied && (isScrolledLeftHorizontally || isScrolledRightHorizontally) && (
							// grid: right-left-screen
							<div className={scrollLeftIndicatorClasses} />
						)}

						<table className={tableClasses} data-bi-id={biId}>
							{caption && <caption className={classes.caption}>{caption}</caption>}

							{columns && <thead>{this._renderTableHeaderRow(firstRowIsGroup)}</thead>}

							{note && (
								<tbody>
									<tr>
										<td className={classes.cell} colSpan={columns.length}>
											{note}
										</td>
									</tr>
								</tbody>
							)}

							{columns && (rows || isUsingGroups) && (
								<tbody>
									{rows && this._renderRows(rows, renderedVisibleGroups.length > 0)}

									{isUsingGroups && renderedVisibleGroups}
								</tbody>
							)}

							{isLoading && (
								<tbody className={classes.tbodyForLoader}>
									<tr>
										<td className={classes.loaderCell}>
											<div className={classes.loader}>
												<Loader small={true} />
											</div>
										</td>
									</tr>
								</tbody>
							)}
						</table>

						{(isScrolledLeftHorizontally || isScrolledRightHorizontally) && (
							// grid: right-scroll-screen
							<div className={scrollRightIndicatorClasses} />
						)}
					</div>
				</div>
			);
		}
	}

	return withStyles(styles)(Table) as ExoticComponent<PropsWithoutRef<TableProps<T>> & RefAttributes<TableMembers>>;
}

export const Table = GenericTable<unknown>();

function groupBy<T>(array: Array<T>, identifier: string | ((item: T) => string)) {
	return array.reduce<Record<string, T[]>>((map, item) => {
		const value = typeof identifier === 'function' ? identifier(item) : (item[identifier] as string);

		if (!map[value]) {
			map[value] = [];
		}

		map[value].push(item);

		return map;
	}, {});
}

function not(func: (args?: unknown) => boolean) {
	return (...args: unknown[]): boolean => !func(...args);
}

function isHeadless(columns: TableProps['columns']) {
	return columns.every(col => !col.header);
}
