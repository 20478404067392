import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import React, { FC, PropsWithChildren, useState } from 'react';
import { themeColors } from '../definitions/json/theme-colors.json';
import { ModeOptions, ThemeNameOptions } from './constants';
import { getMuiTheme } from './get-mui-theme';
import { BrandColorContext } from './hooks';
import { TssProvider } from './tss-provider';
import { ThemeProviderProps } from './types';

const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
	children,
	classNamePrefix,
	mode = ModeOptions.Normal,
	themeName = ThemeNameOptions.Jade,
}) => {
	const initialName = document.querySelector('body')?.getAttribute('ba-theme') || themeColors[0].name;
	const [brandColor, setBrandColor] = useState(initialName);
	const theme = getMuiTheme(brandColor, mode, themeName);

	const stylesProps = {
		...(classNamePrefix && {
			generateClassName: createGenerateClassName({
				productionPrefix: classNamePrefix,
			}),
		}),
		injectFirst: true,
	};

	return (
		<StyledEngineProvider injectFirst>
			<StylesProvider {...stylesProps}>
				<TssProvider>
					<BrandColorContext.Provider value={{ brandColor, setBrandColor }}>
						<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
					</BrandColorContext.Provider>
				</TssProvider>
			</StylesProvider>
		</StyledEngineProvider>
	);
};

export default ThemeProvider;
