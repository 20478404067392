import React, { ReactElement } from 'react';
import { GroupedList } from './grouped-list';
import { useConditionallyControlled, useKeyboardSelection, useFlatKeyboardNavigation } from './hooks';
import { MenuListCore } from './menu-list-core';
import { MenuListProps } from './types';

export const MenuList = GroupedList((props: MenuListProps): ReactElement => {
	const {
		activeIndex: activeIndexProp,
		defaultActiveIndex,
		getDisabled,
		keyboardTarget,
		onActive,
		onSelect,
		total,
		...rest
	} = props;

	const [activeIndex, updateActiveIndex] = useConditionallyControlled<number>(activeIndexProp, onActive, defaultActiveIndex);

	useFlatKeyboardNavigation({
		activeIndex,
		getDisabled,
		keyboardTarget,
		onChange: updateActiveIndex,
		total,
	});

	useKeyboardSelection({
		activeIndex,
		keyboardTarget,
		onSelect,
	});

	return (
		<MenuListCore
			activeIndex={activeIndex}
			getDisabled={getDisabled}
			onActive={updateActiveIndex}
			onSelect={onSelect}
			total={total}
			{...rest}
		/>
	);
});

MenuList.defaultProps = {
	getDisabled: () => false,
};
