import { makeStyles } from '~styles';

export const useSearchFilterStyles = makeStyles<{ value: string }>()((theme, { value }, classes) => ({
	clearAdornment: {
		opacity: value ? 1 : 0,
		backgroundColor: 'transparent',
		'&&': {
			backgroundColor: 'transparent',
		},
	},
}));
