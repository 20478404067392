import { useCallback, useEffect, useRef } from 'react';
import { Masked } from 'imask';

export const useMaskCommit = (mask: string, value?: string, onChange?: (value: string) => void, submitUnmaskedValue = true) => {
	const maskRef = useRef(mask);
	const initialized = useRef(false);

	useEffect(() => {
		initialized.current = true;
		maskRef.current = mask;
	}, [mask]);

	const handleCommit = useCallback(
		(_: string, masked: Masked<string>) => {
			const changed = submitUnmaskedValue ? value !== masked.unmaskedValue : value !== masked.value;
			const maskEqual = maskRef.current === masked.mask;
			if (initialized.current && changed && maskEqual && onChange) {
				onChange(submitUnmaskedValue ? masked.unmaskedValue : masked.value);
			}
		},
		[submitUnmaskedValue, value, onChange]
	);

	return handleCommit;
};
