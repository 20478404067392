import { TextField } from '~components/text-field';
import React from 'react';
import { RendererProps } from '../types';
import { getValidInputWidthValue } from '../etc/get-valid-input-width-value';

export function TextFieldRenderer({ element }: RendererProps) {
	const { id, type, width: elementWidth, ...rest } = element;

	const width = getValidInputWidthValue(elementWidth);
	const defaultWidth = 4; // Default as defined here https://design.bamboohr.net/3fb00d017/p/50a7f2-sizes-and-widths/b/31f4f4

	return <TextField {...rest} width={width || defaultWidth} />;
}
