import { Side } from './types';

export enum ContentSpacing {
	NONE = '0',
	CUSTOM = 'custom',
	TEENIE = '8px',
	SMALL = '16px',
	MEDIUM = '24px',
	LARGE = '32px',
	BIGGIE = '48px',
}

export type SpacingStyle = {
	[key in Side]?: ContentSpacing;
};

export enum CardSize {
	CUSTOM = 'custom',
	FULL = 'full',
	MEDIUM = 'medium',
	SMALL = 'small',
}

export enum CardFlex {
	COLUMN = 'column',
	ROW = 'row',
}
