import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { HoverWrapper } from '../hover-wrapper';
import { Envelope16x12 } from '@bamboohr/grim';
import { TextButton } from '~components/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export interface HoverEmailProps {
	value: string;
	children: ReactElement;
}

export const HoverEmail: FunctionComponent<PropsWithChildren<HoverEmailProps>> = props => {
	const { children, value } = props;

	return (
		<HoverWrapper component={children} hasIcon={false}>
			<TextButton href={`mailto:${value}`} startIcon={ifFeature('encore', 'envelope-solid', <Envelope16x12 />)}>
				{window.jQuery ? $.__('Email') : 'Email'}
			</TextButton>
		</HoverWrapper>
	);
};
