import { noop } from 'lodash';
import { createContext } from 'react';

export type ReadableModes = 'readable' | 'writable';

export interface ReadableContext {
	isReadable: boolean;
	setMode: (mode: ReadableModes) => void;
}

const defaultContext = {
	isReadable: false,
	setMode: noop,
};

export const readableContext = createContext<ReadableContext>(defaultContext);
