import { X10x10 } from '@bamboohr/grim';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { IconButton } from '~components/button';
import { DELETION_COLUMN_NAME } from '../constants';
import { DataGridProps } from '../data-grid.types';
// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

interface RowDeletionCellRendererProps extends ICellRendererParams {
	onDeleteRow: DataGridProps['onDeleteRow'];
}

export const RowDeletionCellRenderer = ({ api, data, node, onDeleteRow, rowIndex, eGridCell }: RowDeletionCellRendererProps) => {
	const buttonRef = useRef<HTMLLinkElement>(null);
	const ariaLabel = window.jQuery ? $.__('Delete row %s', rowIndex + 1) : `Delete row ${rowIndex + 1}`;

	const handleDeleteRow = () => {
		const afterDelete = () => {
			const lastRowIndex = api.getModel().getRowCount() - 1;
			const rowIndexToFocus = rowIndex === lastRowIndex ? lastRowIndex - 1 : rowIndex;

			api.applyTransaction({ remove: [node] });
			if (rowIndexToFocus > -1) {
				// For some strange reason, if the grid layout is set to `autoHeight` and you only have 2 rows, deleting the first one causes the remaining row to be invisible (without the rAF wrapper below). The second row is still there, and you can press the tab key to make it appear again, but it's blank. This does not seem to happen with a normal grid layout or any other number of rows. Even deleting the second of 2 rows is fine, even with `autoHeight`; it's only deleting the first of 2 that's an issue.
				requestAnimationFrame(() => {
					api.setFocusedCell(rowIndexToFocus, DELETION_COLUMN_NAME);
				});
			}
		};
		onDeleteRow?.(data, afterDelete);
	};

	useEffect(() => {
		const focus = () => buttonRef.current?.focus();
		eGridCell.addEventListener('focus', focus);
		return () => eGridCell.removeEventListener('focus', focus);
	}, [eGridCell]);

	return ifFeature(
		'encore',
		<IconButton
			ariaLabel={ariaLabel}
			color="secondary"
			floatingIcon
			icon="xmark-solid"
			noBoundingBox={true}
			onClick={handleDeleteRow}
			ref={buttonRef}
			size="small"
			tabindex={-1}
		/>,
		<IconButton
			ariaLabel={ariaLabel}
			color="secondary"
			floatingIcon
			icon={<X10x10 />}
			onClick={handleDeleteRow}
			ref={buttonRef}
			tabindex={-1}
			variant="outlined"
		/>
	);
};
