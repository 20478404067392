import { FileDropperValidatedFiles, FileDropperUploadErrors } from '../file-dropper.types';

export function applyError(files: FileDropperValidatedFiles, error: FileDropperUploadErrors, data: string): FileDropperValidatedFiles {
	let message: string;
	switch (error) {
		case FileDropperUploadErrors.InvalidExtension: {
			message = window.jQuery
				? $.__('The following file types are not allowed: ')
				: 'The following file types are not allowed: ';
			break;
		}
		case FileDropperUploadErrors.FileTooLarge: {
			message = window.jQuery ? $.__('The following files are too large: ') : 'The following files are too large: ';
			break;
		}
		default:
			message = '';
			break;
	}

	const index = files.errors.findIndex(e => e.error === error);
	if (index === -1) {
		files.errors.push({
			error: FileDropperUploadErrors.InvalidExtension,
			message,
			data: [data],
		});
	} else if (!files.errors[index].data.includes(data)) {
		files.errors[index].data.push(data);
	}

	return files;
}
