import { Theme } from '@mui/material';
import { makeStyles } from '~styles';
import { ChipProps } from './chip.types';

const getBackgroundColor = (status: ChipProps['status'], theme: Theme) => {
	switch (status) {
		case 'error':
			return theme.constructs.surface.error.weak;
		case 'warning':
			return theme.constructs.surface.warning.weak;
		case 'info':
			return theme.constructs.surface.info.weak;
		case 'success':
			return theme.constructs.surface.success.weak;
		case 'discovery':
			return theme.constructs.surface.discovery.weak;
		default:
			return theme.constructs.surface.neutral.xWeak;
	}
};

const getHoverBackgroundColor = (status: ChipProps['status'], theme: Theme) => {
	switch (status) {
		case 'error':
			return theme.constructs.surface.error.medium;
		case 'warning':
			return theme.constructs.surface.warning.medium;
		case 'info':
			return theme.constructs.surface.info.medium;
		case 'success':
			return theme.constructs.surface.success.medium;
		case 'discovery':
			return theme.constructs.surface.discovery.medium;
		default:
			return theme.constructs.surface.neutral.strong;
	}
};

const getFocusBackgroundColor = (status: ChipProps['status'], theme: Theme) => {
	switch (status) {
		case 'error':
			return theme.constructs.surface.error.strong;
		case 'warning':
			return theme.constructs.surface.warning.strong;
		case 'info':
			return theme.constructs.surface.info.strong;
		case 'success':
			return theme.constructs.surface.success.strong;
		case 'discovery':
			return theme.constructs.surface.discovery.strong;
		default:
			return theme.constructs.surface.neutral.xStrong;
	}
};

const getIconColor = (status: ChipProps['status'], theme: Theme) => {
	switch (status) {
		case 'error':
			return theme.constructs.surface.error.strong;
		case 'warning':
			return theme.constructs.surface.warning.strong;
		case 'info':
			return theme.constructs.surface.info.strong;
		case 'success':
			return theme.constructs.surface.success.strong;
		case 'discovery':
			return theme.constructs.surface.discovery.strong;
		default:
	}
};

const getBorderColor = (status: ChipProps['status'], theme: Theme) => {
	if (status && theme.constructs.surface[status].weak) {
		return theme.constructs.surface[status].weak;
	}
	return theme.constructs.surface.neutral.xWeak;
};

export const useStyles = makeStyles<Pick<ChipProps, 'onRemove' | 'status'>, 'icon'>()((theme, props, classes) => {
	const { borderRadiuses, constructs, spacing, typography } = theme;
	const { onRemove, status } = props;
	return {
		root: {
			backgroundColor: getBackgroundColor(status, theme),
			borderRadius: borderRadiuses[1000],
			color: constructs.text.neutral.xStrong,
			display: 'inline-flex',
			fontSize: typography.xsmall?.fontSize,
			fontWeight: typography.fontWeightMedium,
			lineHeight: typography.xsmall?.lineHeight,
			maxHeight: typography.xsmall?.lineHeight,
			maxWidth: '300px',
			overflow: 'hidden',
			padding: onRemove ? `3px 0 3px ${spacing(1)}` : `3px ${spacing(1)}`,
			width: 'fit-content',

			'&:focus-within': {
				backgroundColor: getFocusBackgroundColor(status, theme),
				border: `3px solid ${getBorderColor(status, theme)}`,
				color: constructs.text.neutral.forcedWhite,
				margin: '-3px',
				[`& > .${classes.icon}`]: {
					fill: constructs.text.neutral.forcedWhite,
				},
			},
		},

		deleteIcon: {
			backgroundColor: getBackgroundColor(status, theme),
			border: 'none',
			display: 'flex',
			fill: getIconColor(status, theme),
			lineHeight: typography.xsmall?.lineHeight,
			marginBottom: 'auto',
			marginTop: 'auto',
			padding: `3px ${spacing(1)}`,

			'&:active, &:focus': {
				backgroundColor: getFocusBackgroundColor(status, theme),
				cursor: 'pointer',
				fill: constructs.surface.neutral.forcedWhite,
			},

			'&:focus-visible': {
				outline: 'none',
			},

			'&:hover:not(:focus, :active)': {
				backgroundColor: getHoverBackgroundColor(status, theme),
				fill: constructs.text.neutral.forcedWhite,
				cursor: 'pointer',
			},
		},

		icon: {
			fill: getIconColor(status, theme),
			marginRight: '6px',
			paddingTop: '2px',
		},

		label: {},

		hover: {
			backgroundColor: getHoverBackgroundColor(status, theme),
			cursor: 'pointer',
			borderRadius: borderRadiuses[1000],
			color: constructs.text.neutral.forcedWhite,
			display: 'flex',
			fontSize: typography.xsmall?.fontSize,
			fontWeight: typography.fontWeightMedium,
			lineHeight: typography.xsmall?.lineHeight,
			maxHeight: typography.xsmall?.lineHeight,
			maxWidth: '300px',
			overflow: 'hidden',
			padding: onRemove ? `3px 0 3px ${spacing(1)}` : `3px ${spacing(1)}`,
			width: 'fit-content',
			[`& > .${classes.icon}`]: {
				fill: constructs.text.neutral.forcedWhite,
			},

			'&:focus-within, &:active': {
				backgroundColor: getFocusBackgroundColor(status, theme),
				border: `3px solid ${getBorderColor(status, theme)}`,
				color: constructs.text.neutral.forcedWhite,
				margin: '-3px',
			},
		},
	};
});
