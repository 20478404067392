// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { FormHelperText } from '@mui/material';
import clsx from 'classnames';
import { NoteProps } from './note-props';
import { useStyles } from './note.styles';

export const Note: FC<PropsWithChildren<NoteProps>> = (props: NoteProps) => {
	const { classes, note, disabled, status } = props;

	const { classes: styles } = useStyles();

	return (
		<FormHelperText
			className={clsx({
				[styles.root]: true,
				[styles.error]: status === 'error',
				[styles.warning]: status === 'warning',
				[styles.info]: status === 'info',
				...classes,
			})}
			disabled={disabled}
			error={status === 'error'}
			variant="standard"
		>
			{note}
		</FormHelperText>
	);
};
