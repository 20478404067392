import { useState, useRef, useEffect } from 'react';
import DropdownPortal from '../dropdownPortal/DropdownPortal';
import config from '../../config';
import api from '../../services/api';
import EditIcon from '../icons/EditIcon';
import TrashIcon from '../icons/TrashIcon';
import { useNavigate } from 'react-router-dom';
import { IconV2 } from '@bamboohr/fabric';
import {
	Chat,
	LlmConfig,
	NavPromptMenuDropdownOption,
	NavPromptMenuPosition,
} from 'src/types/global';

const CHAT_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + 'chat-session';

interface NavPromptProps {
	chat: Chat;
	loadChatLogs: (
		chatID: string,
		chatName: string,
		label: string,
		id: string,
		newConfig: LlmConfig,
	) => void;
	setChatName: (name: string) => void;
	chatNames: Chat[];
	setChatNames: (chats: Chat[]) => void;
	isActive: boolean;
}

const NavPrompt = ({
	chat,
	loadChatLogs,
	setChatName,
	chatNames,
	setChatNames,
	isActive,
}: NavPromptProps) => {
	const { id: chatID, name: chatName, llmLabel, llmId, llmConfig } = chat;

	const [hovered, setHovered] = useState(false);
	const [editing, setEditing] = useState(false);
	const [chatNameCharacters, setChatNameCharacters] = useState(
		chatName.split(''),
	);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [buttonPosition, setButtonPosition] = useState<NavPromptMenuPosition>({
		top: 0,
		left: 0,
		height: 0,
	});
	const chatNameContainer = useRef<HTMLParagraphElement>(null);
	const editButtonRef = useRef<HTMLButtonElement>(null);
	const editInputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	// Scroll to the beginning of the container when editing mode is exited
	useEffect(() => {
		if (editInputRef.current) {
			editInputRef.current.value = chatName;
		}
	}, [chatName, editing]);

	const updateChatName = () => {
		setEditing(false);
		if (editInputRef.current) {
			const chatPromptName = editInputRef.current.value;
			if (chatPromptName !== chatName) {
				api
					.put(CHAT_SESSIONS_ENDPOINT + '/' + chatID, {
						session_name: chatPromptName,
					})
					.then(() => {
						setChatName(chatPromptName);
						setChatNameCharacters(chatPromptName.split(''));
						setChatNames(
							chatNames.map((chat) => {
								if (chat.id === chatID) {
									return { ...chat, name: chatPromptName };
								}
								return chat;
							}),
						);
					})
					.catch((error) => {
						console.error('Error occurred:', error);
					});
			}
		}
	};

	// Function to load past chat logs from localStorage
	const handleLoadChatLogs = async () => {
		loadChatLogs(chatID, chatName, llmLabel, llmId, llmConfig);
	};

	const handleButtonClick = (event) => {
		event.stopPropagation();
		const rect = editButtonRef.current?.getBoundingClientRect();
		if (!rect) return;
		setButtonPosition({ top: rect.top, left: rect.left, height: rect.height });
		setDropdownVisible(true);
	};

	const options: NavPromptMenuDropdownOption[] = [
		{ value: 'rename', label: 'Rename', icon: <EditIcon fill="gray" /> },
		{
			value: 'delete',
			label: 'Delete',
			icon: <TrashIcon fill="red" />,
			style: { color: 'red' },
		},
	];

	const onClickOptionHandler = (option) => {
		setDropdownVisible(false);
		if (option.value === options[0].value) {
			setEditing(true);
		} else if (option.value === options[1].value) {
			if (
				window.confirm('Are you sure you wish to delete this conversation?')
			) {
				// Delete chat session
				api.delete(CHAT_SESSIONS_ENDPOINT + '/' + chatID).then(() => {
					setChatNames(chatNames.filter((chat) => chat.id !== chatID));
				});

				// Navigate to new chat page if the current chat is deleted
				if (isActive) {
					navigate('/');
					handleLoadChatLogs();
				}
			}
		}
	};

	const displayActive = isActive || dropdownVisible || hovered;

	const handleKeyDown = (e) => {
		// Check if the Enter key is pressed without any modifiers
		if (e.key === 'Enter') {
			e.preventDefault();
			updateChatName();
		}
		if (e.key === 'Escape') {
			setEditing(false);
		}
	};

	return (
		<div
			className={`navPrompt ${displayActive ? 'active' : ''}`}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<button
				disabled={editing}
				className={`navPrompt-item ${displayActive ? 'active' : ''}`}
				onClick={() => handleLoadChatLogs()}
			>
				{isActive || hovered ? (
					<IconV2 name="comment-solid" size={16} color="neutral-forced-white" />
				) : (
					<IconV2
						name="comment-regular"
						size={16}
						color="neutral-extra-strong"
					/>
				)}
				<p ref={chatNameContainer}>
					{editing ? (
						<input
							id="navPrompt-input"
							type="text"
							ref={editInputRef}
							autoFocus
							onKeyDown={handleKeyDown}
							onBlur={updateChatName}
						/>
					) : (
						chatNameCharacters.map((char, idx) => (
							<span key={idx} style={{ animationDelay: `${idx * 0.02}s` }}>
								{char}
							</span>
						))
					)}
				</p>
			</button>

			<button
				ref={editButtonRef}
				onClick={handleButtonClick}
				className={
					!editing && (hovered || dropdownVisible || isActive)
						? 'visible'
						: 'hidden'
				}
			>
				<IconV2
					name={'square-ellipsis-regular'}
					size={16}
					color="neutral-forced-white"
				/>
				{dropdownVisible && (
					<DropdownPortal
						options={options}
						position={buttonPosition}
						onClose={() => setDropdownVisible(false)}
						onClickOption={onClickOptionHandler}
					/>
				)}
			</button>
		</div>
	);
};

export default NavPrompt;
