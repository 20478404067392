import { BodyText, Button, LayoutBox, SheetModal } from '@bamboohr/fabric';
import React from 'react';

interface ConfirmDeleteChatsModalProps {
	isOpen: boolean;
	onCancel: () => void;
	onConfirm: () => void;
}

const ConfirmDeleteChatsModal: React.FC<ConfirmDeleteChatsModalProps> = ({
	isOpen,
	onCancel,
	onConfirm,
}) => {
	return (
		<SheetModal isOpen={isOpen}>
			<SheetModal.Body
				renderFooter={
					<SheetModal.Footer
						actions={[
							<Button color="secondary" key="button-1" onClick={onCancel}>
								Cancel
							</Button>,
							<Button key="button-2" onClick={onConfirm}>
								Yes, delete all chats
							</Button>,
						]}
					/>
				}
				renderHeader={<SheetModal.Header title="Just checking..." />}
			>
				<SheetModal.Constraint>
					<SheetModal.HeroHeadline
						icon="triangle-exclamation-regular"
						iconColor="warning-medium"
						text="Are you sure you want to delete all of your chats?"
					/>
					<LayoutBox marginTop={2}>
						<BodyText justify="center">This cannot be undone.</BodyText>
					</LayoutBox>
				</SheetModal.Constraint>
			</SheetModal.Body>
		</SheetModal>
	);
};

export default ConfirmDeleteChatsModal;
