import { styles } from './styles';
import { CheckIcon } from '../icons';

interface ModalCheckboxProps {
	checked: boolean;
	label: string;
	onChange: (field: string, checked: boolean) => void;
	field: string;
}

const ModalCheckbox = ({
	checked,
	label,
	onChange,
	field,
}: ModalCheckboxProps) => {
	return (
		<div style={styles.checkboxContainer}>
			<button
				style={{
					...styles.checkbox,
					border: checked ? '0px' : '1px solid rgba(178, 178, 178)',
				}}
				onClick={() => onChange(field, !checked)}
			>
				{checked ? (
					<CheckIcon fill={'white'} backgroundFill={'#5A9917'} />
				) : null}
			</button>
			<span className="checbox__visualCheckbox">{label}</span>
		</div>
	);
};

export default ModalCheckbox;
