/* eslint-disable sort-keys */
import { GetModeConstructProps } from '../../types/constructs';
import type { ConstructMap } from './get-constructs-light';

export function getConstructsDark({ borderRadiuses, primitives, spacing }: GetModeConstructProps): ConstructMap {
	const { functional, neutral, primary, sentiment } = primitives;

	return {
		text: {
			link: {
				idle: functional.link,
				hover: neutral[0],
				muted: neutral[0],
				disabled: neutral[0],
			},
			neutral: {
				xWeak: neutral[300],
				weak: neutral[300],
				medium: neutral[300],
				strong: neutral[100],
				xStrong: neutral[50],
				inverted: neutral[900],
				forcedWhite: neutral[0],
			},
			primary: {
				strong: primary[900],
			},
			error: {
				medium: sentiment.error[500],
				strong: sentiment.error[900],
			},
			warning: {
				medium: sentiment.warning[500],
				strong: sentiment.warning[900],
			},
			info: {
				medium: sentiment.info[500],
				strong: sentiment.info[900],
			},
			success: {
				medium: sentiment.success[500],
				strong: sentiment.success[900],
			},
			discovery: {
				medium: sentiment.discovery[500],
				strong: sentiment.discovery[900],
			},
		},
		icon: {
			bambooBrand: {
				medium: functional.brandColor,
			},
			link: {
				idle: functional.link,
				hover: neutral[0],
				muted: neutral[0],
				disabled: neutral[0],
			},
			neutral: {
				xWeak: neutral[600],
				weak: neutral[500],
				medium: neutral[400],
				strong: neutral[300],
				xStrong: neutral[50],
				xxStrong: neutral[50],
				inverted: neutral[900],
			},
			notice: {
				strong: sentiment.notice[900],
			},
			primary: {
				weak: primary[100],
				medium: primary[500],
				strong: primary[900],
			},
			error: {
				weak: sentiment.error[100],
				medium: sentiment.error[500],
				strong: sentiment.error[900],
			},
			warning: {
				weak: sentiment.warning[100],
				medium: sentiment.warning[500],
				strong: sentiment.warning[900],
			},
			info: {
				weak: sentiment.info[100],
				medium: sentiment.info[500],
				strong: sentiment.info[900],
			},
			success: {
				weak: sentiment.success[100],
				medium: sentiment.success[500],
				strong: sentiment.success[900],
			},
			discovery: {
				weak: sentiment.discovery[100],
				medium: sentiment.discovery[500],
				strong: sentiment.discovery[900],
			},
			files: {
				doc: '#2076BB',
				esig: '#87328F',
				excel: '#3AA837',
				pdf: '#C32A2F',
				ppt: '#C26C1A',
			},
		},
		border: {
			neutral: {
				xxWeak: neutral[700],
				xWeak: neutral[700],
				weak: neutral[600],
				medium: neutral[600],
				strong: neutral[400],
				xStrong: neutral[200],
				inverted: neutral[700],
				forcedWhite: neutral[700],
			},
			primary: {
				weak: primary[100],
				medium: primary[500],
				strong: primary[900],
				xStrong: primary[1000],
			},
			error: {
				weak: sentiment.error[100],
				medium: sentiment.error[500],
				strong: sentiment.error[900],
			},
			warning: {
				weak: sentiment.warning[100],
				medium: sentiment.warning[500],
				strong: sentiment.warning[900],
			},
			info: {
				weak: sentiment.info[100],
				medium: sentiment.info[500],
				strong: sentiment.info[900],
			},
			success: {
				weak: sentiment.success[100],
				medium: sentiment.success[500],
				strong: sentiment.success[900],
			},
			discovery: {
				weak: sentiment.discovery[100],
				medium: sentiment.discovery[500],
				strong: sentiment.discovery[900],
			},
		},
		surface: {
			neutral: {
				xxWeak: neutral[800],
				xWeak: neutral[700],
				weak: neutral[700],
				medium: neutral[500],
				strong: neutral[300],
				xStrong: neutral[50],
				xxStrong: neutral[50],
				inverted: neutral[900],
				forcedWhite: neutral[900],
			},
			primary: {
				weak: primary[100],
				medium: primary[500],
				strong: primary[900],
				xStrong: primary[1000],
			},
			notice: {
				strong: sentiment.notice[900],
			},
			error: {
				weak: sentiment.error[100],
				medium: sentiment.error[500],
				strong: sentiment.error[900],
			},
			warning: {
				weak: sentiment.warning[100],
				medium: sentiment.warning[500],
				strong: sentiment.warning[900],
			},
			info: {
				weak: sentiment.info[100],
				medium: sentiment.info[500],
				strong: sentiment.info[900],
			},
			success: {
				weak: sentiment.success[100],
				medium: sentiment.success[500],
				strong: sentiment.success[900],
			},
			discovery: {
				weak: sentiment.discovery[100],
				medium: sentiment.discovery[500],
				strong: sentiment.discovery[900],
			},
		},
		radius: {
			none: '0',
			xxxSmall: borderRadiuses['100'],
			xxSmall: borderRadiuses['200'],
			xSmall: borderRadiuses['300'],
			small: borderRadiuses['400'],
			medium: borderRadiuses['500'],
			large: borderRadiuses['600'],
			xLarge: borderRadiuses['700'],
			xxLarge: borderRadiuses['800'],
			xxxLarge: borderRadiuses['900'],
			full: borderRadiuses['1000'],
		},
		space: {
			none: spacing(0),
			xxxs: spacing(0.25),
			xxs: spacing(0.5),
			xs: spacing(1),
			x: spacing(1.5),
			m: spacing(2),
			l: spacing(2.5),
			xl: spacing(3),
			xxl: spacing(4),
			xxxl: spacing(5),
		},
	};
}
