import { AvatarSizes } from '~components/avatar';
import { BaseHeadlineProps } from '~components/headline/base-headline';
import { HeaderSize } from './header.types';

export const getAvatarSize = (size: HeaderSize): AvatarSizes => {
	const map: Record<HeaderSize, AvatarSizes> = {
		small: 40,
		medium: 48,
		large: 64,
	};
	return map[size];
};

export const getHeadlineSizeFromHeaderSize = (size: HeaderSize): BaseHeadlineProps['size'] => {
	const map: Record<HeaderSize, BaseHeadlineProps['size']> = {
		small: 'extra-small',
		medium: 'small',
		large: 'medium',
	};
	return map[size] ?? 'small';
};
