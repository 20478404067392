import React, { FC, forwardRef, PropsWithChildren, useContext } from 'react';
import clsx from 'classnames';
import { ButtonBase, capitalize, ButtonBaseProps } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { styles } from './tab.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { TabsContext } from './tabs-context';
import { IconV2 } from '~components/icon-v2';
import type { TabBaseProps } from './types/tab-base-props';
import { getIcon } from './utils';

type InternalTabProps = {
	selectionFollowsFocus: boolean;
	indicator: React.ReactNode;
	disableRipple: boolean;
	disableTouchRipple: boolean;
};

export const TabBase = withStyles(styles, { name: 'MuiTab' })(
	forwardRef<HTMLButtonElement, TabBaseProps & InternalTabProps & WithStyles<typeof styles>>(
		(
			{
				classes = {},
				className,
				description,
				disabled = false,
				fullWidth = false,
				icon,
				indicator,
				isDropdown,
				label,
				onChange,
				onClick,
				onFocus,
				overflowProps, // do not forward to ButtonBase
				selected = false,
				selectionFollowsFocus,
				tabPanelId,
				textColor = 'secondary',
				value,
				wrapped = false,
				...rest
			},
			ref
		) => {
			const handleClick = event => {
				if (!selected && onChange) {
					onChange(event, value);
				}

				if (onClick) {
					onClick(event);
				}
			};

			const handleFocus = event => {
				if (selectionFollowsFocus && !selected && onChange) {
					onChange(event, value);
				}

				if (onFocus) {
					onFocus(event);
				}
			};
			const { dark, mode, size } = useContext(TabsContext);
			const text = typeof label === 'string' ? label : '';

			return ifFeature(
				'encore',
				<ButtonBase
					aria-controls={tabPanelId}
					aria-selected={selected}
					className={clsx(
						classes.root,
						{
							[classes.disabled as string]: disabled,
							[classes.selected as string]: selected,
							[classes.isDropdown as string]: isDropdown,
							[classes.fullWidth as string]: fullWidth,
							[classes.wrapped as string]: wrapped,
							[classes.dark as string]: dark,
							[classes.modeFill as string]: mode === 'fill',
							[classes.modeLine as string]: mode === 'line',
							[classes.sizeLarge as string]: size === 'large',
						},
						className
					)}
					disabled={disabled}
					focusRipple={true}
					id={`tab-${value as string}`}
					onClick={handleClick}
					onFocus={handleFocus}
					ref={ref}
					role="tab"
					tabIndex={selected ? 0 : -1}
					{...(rest as Partial<ButtonBaseProps>)}
				>
					<span className={classes.wrapper}>
						{icon ? <span className={classes.icon}>{getIcon(icon, size)}</span> : null}
						{label ? (
							<span className={classes.label} data-text={text}>
								{label}
							</span>
						) : null}
						{isDropdown ? (
							<span className={classes.dropdownIcon}>
								<IconV2 name="caret-down-solid" size={16} />
							</span>
						) : null}
						{description ? <span className={classes.description}>{description}</span> : null}
					</span>
					{indicator}
				</ButtonBase>,
				<ButtonBase
					aria-controls={tabPanelId}
					aria-selected={selected}
					className={clsx(
						classes.root,
						classes[`textColor${capitalize(textColor)}`],
						{
							[classes.disabled as string]: disabled,
							[classes.selected as string]: selected,
							[classes.labelIcon as string]: label && icon,
							[classes.fullWidth as string]: fullWidth,
							[classes.wrapped as string]: wrapped,
						},
						className
					)}
					disabled={disabled}
					focusRipple={true}
					id={`tab-${value as string}`}
					onClick={handleClick}
					onFocus={handleFocus}
					ref={ref}
					role="tab"
					tabIndex={selected ? 0 : -1}
					{...(rest as Partial<ButtonBaseProps>)}
				>
					<span className={classes.wrapper}>
						{icon}
						<span className={classes.label as string} data-text={text}>
							{label}
						</span>
					</span>
					{indicator}
				</ButtonBase>
			);
		}
	)
) as FC<PropsWithChildren<TabBaseProps>>;
