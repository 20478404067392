import { Box } from '@mui/material';
import React from 'react';
import { Label } from '../../label';
import { RendererProps } from '../../types';
import { hasOnlyGroups } from './has-only-groups';
import { hasSomeGroups } from './has-some-groups';

export function GroupRenderer({ children, element, formConfig }: RendererProps) {
	const { layout } = formConfig;
	const { id } = element;
	const childIds = layout[id]?.childIds || ([] as Array<string>);
	const label = typeof element.label === 'string' ? element.label : '';

	const containsOnlyGroups = hasOnlyGroups(id, formConfig);
	const containsSomeGroups = containsOnlyGroups || hasSomeGroups(id, formConfig);

	const grid = (
		<Box columnGap={containsSomeGroups ? 1.25 : 1} display="flex" flexDirection={containsOnlyGroups ? 'column' : 'row'} rowGap={1}>
			{children}
		</Box>
	);

	if (!childIds || childIds.length < 1) {
		console.error(`An empty group with the id: "${id}" was found in your form layout.`);
	}

	return label ? (
		<label>
			<Label>{label}</Label>
			{grid}
		</label>
	) : (
		grid
	);
}
