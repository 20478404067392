import { PaletteColor } from '@mui/material/styles/createPalette';
import { CSSObject } from 'tss-react';

export const modalGradientFooter = (paletteColor: PaletteColor): CSSObject => {
	return {
		alignItems: 'center',
		display: 'flex',
		paddingBottom: '16px', // 16px + Footer Constraint.spacingOverrides.bottom @ ContentSpacing.small (16px) = 32px;
		paddingTop: '14px', // 2px divider + 12px = 14px. 14px + 12px footer action margin = 26px (24px negative space)
		position: 'relative', // for divider pseudo-element

		'&:before': {
			background: `linear-gradient(90deg, ${paletteColor.lightest} 0, ${paletteColor.lighter} 100%)`,
			content: '""',
			display: 'block',
			height: '2px',
			left: 0,
			position: 'absolute',
			top: 0,
			width: '100%',
		},
	};
};
