import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Title } from './title.react';
import { BaseModal } from '~components/base-modal';
import { FullScreenModalHeaderProps } from '../full-screen-modal.types';
import { useHeaderStyles } from './header.styles';

export const Header: FunctionComponent<PropsWithChildren<FullScreenModalHeaderProps>> & {
	Title: typeof Title;
} = props => {
	const { children, icon, title, ...restProps } = props;
	const { classes } = useHeaderStyles();

	const _title = title ? <Title icon={icon} text={title} /> : null;

	return (
		<div className={classes.header}>
			<BaseModal.Header inverted={true} {...restProps}>
				{children || _title}
			</BaseModal.Header>
		</div>
	);
};

Header.Title = Title;
