import React, { ReactElement } from 'react';
import { useStyles } from './progress-bar.styles';
import { ProgressBarProps } from './progress-bar.types';

export const ProgressBar = ({ current, height = 12, total, width = '100%' }: ProgressBarProps): ReactElement => {
	const { classes } = useStyles({ current, height, total, width });
	return (
		<div
			aria-label="progress-bar"
			aria-valuemax={total}
			aria-valuemin={0}
			aria-valuenow={current}
			className={classes.outerBar}
			role="progressbar"
		>
			<div className={classes.innerBar} />
		</div>
	);
};

export default ProgressBar;
