import { DatePickerDate, DatePickerDateType, DatePickerUtils } from '../types';

export function isOutsideConstraints(
	utils: DatePickerUtils,
	{ maxDate, minDate }: { maxDate?: DatePickerDate; minDate?: DatePickerDate },
	date: DatePickerDate
) {
	return (
		(!!minDate && utils.isBefore(date as DatePickerDateType, minDate)) ||
		(!!maxDate && utils.isAfter(date as DatePickerDateType, maxDate))
	);
}
