import { BarStack } from '@visx/shape/lib/types';
import { BarList } from '../types';

/**
 * Reorganize the data by bar instead of by data series so that screen readers will read out by bar
 * @param barStacks An array of BarStacks. It is assumed that all unwanted bars are filtered out prior to being passed into this function
 * @param getSeriesValue A function that returns the name of the bar
 * @returns BarStack-shaped object organized by bar instead of by data series
 */
export const getStacksByBar = <Datum>(barStacks: BarStack<Datum, string>[], getSeriesValue: (Datum) => string): BarList<Datum>[] => {
	const sparseStacks = barStacks.reduce((acc, barStack, stackIndex) => {
		barStack.bars.forEach(bar => {
			if (!acc[bar.index]) {
				acc[bar.index] = {
					series: getSeriesValue(bar.bar.data),
				} as BarList<Datum>;
			}
			if (!acc[bar.index].bars) {
				acc[bar.index].bars = [];
			}
			// swapping the indices for bars and stacks because we're swapping their positions in the data
			acc[bar.index].index = bar.index;
			acc[bar.index].bars[stackIndex] = { ...bar, index: stackIndex };
		});
		return acc;
	}, [] as BarList<Datum>[]);
	return sparseStacks.map(stack => {
		stack.bars = Object.values(stack.bars);
		return stack;
	});
};
