import { DatePickerYearPredicate, SelectItem, SelectYearOnChangeParam } from '../../types';

export function getSelectYearItems(years: Array<number> = [], getYearDisabled?: DatePickerYearPredicate): Array<SelectItem> {
	return years.map(year => {
		return {
			isDisabled: getYearDisabled ? getYearDisabled(year) : false,
			text: year.toString(),
			value: year,
		};
	});
}

export function getSelectYearOnChangeParam(values: Array<number>): SelectYearOnChangeParam {
	return {
		value: values[0],
	};
}
