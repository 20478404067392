import React, { forwardRef } from 'react';
import { IconButton } from '../icon-button';
import { IconButtonProps } from '../../types';

/**
 * @deprecated Use IconButton component instead.
 */
export const FloatingIconButton = forwardRef<HTMLElement, IconButtonProps>(({ ...rest }, ref) => {
	return <IconButton floatingIcon={true} ref={ref} {...rest} />;
});
