import { useState } from 'react';

/**
 * This hook makes it possible for a value to be optionally controlled outside of a component.
 * If the value is not controlled outside of the component this hook will perform the required
 * state updates.
 * @param controlledValue The optionally controlled value
 * @param changeHandler A handler to update the optionally controlled value
 * @param defaultValue The default value to use when there is no controlled value.
 * @returns An array containing the current value and the update function to change the value
 */
export function useConditionallyControlled<T>(
	controlledValue?: T,
	changeHandler?: (value: T) => void,
	defaultValue?: T
): [T | undefined, (value: T) => void] {
	const state = useState<typeof controlledValue>(defaultValue);

	return typeof controlledValue !== 'undefined' && typeof changeHandler === 'function' ? [controlledValue, changeHandler] : state;
}
