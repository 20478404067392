import React, { ReactElement } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Divider as FabDivider } from '~components/divider';
import { LayoutBox } from '~components/layout-box';

export const Divider = (): ReactElement => {
	return ifFeature(
		'encore',
		<LayoutBox paddingY="12px">
			<FabDivider color="neutral-extra-weak" noGap />
		</LayoutBox>,
		<FabDivider light />
	);
};
