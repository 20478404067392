import React, { type ElementType, useState } from 'react';
import { HeaderActionProps } from './action.types';
import { useStyles } from './action.styles';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import { LinkUnstyled } from '~components/link/unstyled';
import { Tooltip } from '~components/tooltip';

export function Action<C extends ElementType = 'a'>(props: HeaderActionProps<C>) {
	const { active, activeIcon, children, component = 'a', disabled = false, focused = false, icon, label, ...rest } = props;
	const { classes } = useStyles({ active, disabled, focused });
	const currentIcon = active && activeIcon ? activeIcon : icon;
	// eslint-disable-next-line react/destructuring-assignment
	const href = 'href' in props && !disabled ? props.href : undefined;
	const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

	return (
		<>
			{label ? <Tooltip anchorEl={anchorEl} content={label} enterDelay={2000} placement="bottom" /> : null}
			<LinkUnstyled
				aria-label={label || (window.jQuery ? $.__('action link') : 'action link')}
				className={classes.root}
				component={component}
				containerRef={setAnchorEl}
				href={href}
				{...rest}
			>
				{typeof currentIcon === 'string' && <IconV2 name={currentIcon as IconV2Name} size={24} />}
				{children}
			</LinkUnstyled>
		</>
	);
}
