import { isSameItem } from '../assertion';

export function findPath(items, item) {
	const isSame = isSameItem(item);

	if (!Array.isArray(items)) {
		return [];
	}

	return items.reduce((memo, current, index) => {
		if (!memo.length) {
			if (isSame(current)) {
				return [index];
			}

			if (current && Array.isArray(current.items)) {
				const childPath = findPath(current.items, item);

				if (childPath.length) {
					return [...memo, index, ...childPath];
				}
			}
		}
		return memo;
	}, []);
}

export function getItemFromPath(items, path = []) {
	const remainingPath = [...path];
	const index = remainingPath.length ? remainingPath.shift() : -1;

	if (index < 0 || !path.length) {
		return;
	}

	const item = items[index];

	if (remainingPath.length && item && Array.isArray(item.items)) {
		return getItemFromPath(item.items, remainingPath);
	}
	return item;
}
