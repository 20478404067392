import { cloneDeep, isEmpty } from 'lodash';

/**
 * Pulled from current classes used from GH to allow the customer to set width.
 *
.xxxshort, { width: 32px; } - width 1 (48)
.xxshort, { width: 60px; } - width 2 (64)
.xshort, { width: 85px; } - width 3 (96)
.short, { width: 100px; } - width 4 (128)
.long, { width: 150px; } - width 5 (160)
.xlong, { width: 196px; } - width 6 (192)
.xxlong, { width: 265px; } - width 8 (288)
.xxxlong, { width: 334px; } - width 9 (352)
.xxxxlong, { width: 420px; } - width 10 (448)
.fullWidth, { width: 100%; } - width 100
.autoWidth { width: auto; } - width 100
 */

const classToWidthMap = {
	xxxshort: 1,
	xxshort: 2,
	xshort: 3,
	short: 4,
	long: 5,
	xlong: 6,
	xxlong: 8,
	xxxlong: 9,
	xxxxlong: 10,
	fullWidth: 100,
	autoWidth: 100,
};

export function qfJsonTestAdapter(jsonData) {
	const qfData = cloneDeep(jsonData);
	const updatedJson = {};
	const parseTokenById = !qfData.idsByToken;
	const parseTokenByName = !qfData.idsByName;
	updatedJson.idsByToken = parseTokenById ? {} : { ...qfData.idsByToken };
	updatedJson.idsByName = parseTokenByName ? {} : { ...qfData.idsByName };

	updatedJson.formElements = qfData.formElements?.reduce((accumulator, { value, ...element }) => {
		if (Array.isArray(value) && value.length === 1) {
			value = value[0];
		}

		if (element?.className?.includes('Width') || element?.className?.includes('long') || element?.className?.includes('short')) {
			const classArray = element.className.split(' ');
			let index = 0;
			while (index < classArray.length) {
				if (classToWidthMap[classArray[index]]) {
					element.width = classToWidthMap[classArray[index]];
					break; // we found what we are looking for now bail!
				}
				index += 1;
			}
		}

		if (qfData.layout[element.id]?.type === 'CheckboxGroup') {
			value = element.items?.reduce((valueArray, item) => {
				if (item.checked === true) {
					valueArray.push(item.value);
					delete item.checked;
				}
				return valueArray;
			}, []);
		}

		if (qfData.layout[element.name]?.type === 'Checkbox') {
			value = !!element?.checked;
			delete element.checked;
		}

		if (qfData.layout[element.name]?.type === 'SensitiveTextField') {
			value = undefined;
		}

		if (qfData.layout[element.name]?.type === 'CurrencyPickerField') {
			value = isEmpty(element.amount) || isEmpty(element.currency) ? undefined : [element.amount, element.currency.code];
		}

		if (qfData.layout[element.name]?.type === 'PercentageField' && element.note === '%') {
			delete element.note;
		}

		if (
			['SelectField', 'Select'].includes(qfData.layout[element.name]?.type) &&
			Array.isArray(value) &&
			!element.canSelectMultiple
		) {
			value = value[0];
		}

		if (element.name) {
			element = { ...element, name: element.name.replace(/\[\d*\]/, '') };

			if (parseTokenByName) {
				updatedJson.idsByName[element.name] = element.id;
			}
		}

		accumulator[element.id] = { ...element, defaultValue: value };

		if (parseTokenById && element.token) {
			updatedJson.idsByToken[element.token] = element.id;
		}
		return accumulator;
	}, {});

	const formElementsByKey = qfData.formElements?.reduce((accumulator, element) => {
		if (accumulator[element.name]) {
			console.log(`collision for ${element.name}, id: ${element.id}`);
		}
		accumulator[element.name] = { ...element };
		return accumulator;
	}, {});

	const layoutValues = Object.values(qfData.layout);

	updatedJson.layout = layoutValues?.reduce((accumulator, layout) => {
		let id = layout.key === 'root' ? 'root' : formElementsByKey[layout.key]?.id;
		if (!id || id === 'root') {
			id = layout.key;
			if (!updatedJson.formElements[id]) {
				updatedJson.formElements[id] = layout.settings;
			}
			if (parseTokenById && layout.settings.token) {
				updatedJson.idsByToken[layout.settings.token] = id;
			}
			if (id === 'root') {
				updatedJson.formElements[id].id = 'root';
			}
		}
		accumulator[id] = { id, childIds: [] };
		if (layout.childKeys.length > 0) {
			accumulator[id].childIds = layout.childKeys.reduce((childIdsArray, childKey) => {
				if (formElementsByKey[childKey]?.id === id || childKey === id) {
					return childIdsArray;
				}
				childIdsArray.push(formElementsByKey[childKey]?.id || childKey);
				return childIdsArray;
			}, []);

			accumulator[id].childIds.forEach(childId => {
				if (accumulator[childId]) {
					accumulator[childId].parentId = id;
				}
			});
		}

		if (layout.type) {
			updatedJson.formElements[id].type = layout.type;
		}

		return accumulator;
	}, {});

	// if (qfData.elementMetaSettings) {
	// 	for (const key in qfData.elementMetaSettings) {
	// 		updatedJson.formElements[key] = { ...updatedJson.formElements[key], ...qfData.elementMetaSettings[key] };
	// 	}
	// }

	// for (const token in qfData.tokenMetadata) {
	// 	if (updatedJson.idsByToken[token]) {
	// 		updatedJson.formElements[updatedJson.idsByToken[token]] = {
	// 			...updatedJson.formElements[token],
	// 			...qfData.tokenMetadata[token],
	// 		};
	// 	} else {
	// 		console.error(`Token mismatch for ${token}`);
	// 	}
	// }

	return updatedJson;
}
