import React, { ReactElement, isValidElement, ReactNode } from 'react';
import classnames from 'classnames';
import { isFunction } from 'lodash';
import { makeStyles, createStyles } from '~mui';
import { motion } from 'framer-motion';
import { ItemProps } from '../types';

const useStyles = makeStyles(() => {
	return createStyles({
		root: {
			display: 'grid',
		},
	});
});

function actualChild(child: ReactNode, propsToApply: Partial<ItemProps>) {
	if (!isValidElement(child) && isFunction(child)) {
		return child({ ...propsToApply }) as ReactNode;
	}
	return child;
}

export function Item(props: ItemProps): ReactElement {
	const { animate = true, className, layoutKey, children, transition = {}, ...layoutPosition } = props;
	const classes = useStyles();
	const childToRender = actualChild(children, layoutPosition);

	const commonProps = {
		className: classnames(classes.root, className),
		style: {
			gridArea: layoutKey,
		},
	};

	if (animate) {
		return (
			<motion.div
				aria-live="polite"
				layout
				transition={{
					type: 'easeInOut',
					...transition,
				}}
				{...commonProps}
			>
				{childToRender}
			</motion.div>
		);
	}

	return <div {...commonProps}>{childToRender}</div>;
}
