import React from 'react';
import { Typography } from '@mui/material';
import { AllCapsHeadingProps } from './types';
import { useStyles } from './all-caps-heading.styles';

/**
 * @deprecated Please use `Headline` or `BodyText` instead.
 */
export const AllCapsHeading = ({
	children,
	className,
	color,
	gray = 800,
	margin,
	level = 'h2',
	letterSpacing,
	lineHeight,
	size = 'teenie',
	...rest
}: AllCapsHeadingProps) => {
	const { classes, cx } = useStyles({ color, gray, letterSpacing, lineHeight, margin, size });
	return (
		<Typography className={cx(classes.root, className)} component={level} {...rest}>
			{children}
		</Typography>
	);
};
