import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ palette, spacing, typography }) => {
	return {
		root: {
			color: ifFeature('encore', palette.gray[600]),
			fontSize: ifFeature('encore', typography.xsmall?.fontSize),
			lineHeight: ifFeature('encore', typography.xsmall?.lineHeight),
			marginTop: ifFeature('encore', 0, 2),
			marginLeft: ifFeature('encore', 0, spacing(1)),
			'&.Mui-disabled, &.Mui-error': {
				color: ifFeature('encore', palette.gray[600]),
			},
		},
		error: {
			// @startCleanup encore
			color: ifFeature('encore', undefined, palette.error.main),
			fill: ifFeature('encore', undefined, palette.error.main),
			// @endCleanup encore
		},
		warning: {
			// @startCleanup encore
			color: ifFeature('encore', undefined, palette.warning.main),
			fill: ifFeature('encore', undefined, palette.warning.main),
			// @endCleanup encore
		},
		info: {
			// @startCleanup encore
			color: ifFeature('encore', undefined, palette.info.main),
			fill: ifFeature('encore', undefined, palette.info.main),
			// @endCleanup encore
		},
	};
});
