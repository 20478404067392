import { Flex } from '@bamboohr/fabric';
import { RefObject, useState } from 'react';
import { ChatLogRow } from './chatLogRow.react';
import ChatPromptInput from './ChatPromptInput';
import { ChatLog } from 'src/pages/home/home.types';

interface ChatLogsProps {
	chatLog: ChatLog[];
	chatLogRef: RefObject<HTMLDivElement>;
	handleSubmit: (target: HTMLTextAreaElement) => void;
	hasPrinted: boolean;
	setHasPrinted: (hasPrinted: boolean) => void;
}

export const ChatLogs = ({
	chatLog,
	chatLogRef,
	handleSubmit,
	hasPrinted,
	setHasPrinted,
}: ChatLogsProps) => {
	const [isPrinting, setIsPrinting] = useState(false);

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="space-between"
			overflow="hidden"
			height="100%"
		>
			<div className="chatLogRowWrapper">
				{chatLog.length > 0 &&
					chatLog.map((chat, idx) => (
						<ChatLogRow
							chat={chat}
							chatLogRef={chatLogRef}
							key={`${idx}-navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, '-')}`}
							isLast={idx === chatLog.length - 1}
							hasPrinted={hasPrinted}
							setHasPrinted={setHasPrinted}
							isPrinting={isPrinting}
							setIsPrinting={setIsPrinting}
						/>
					))}
			</div>

			<Flex
				marginY="20px"
				justifyContent="center"
				maxWidth={'800px'}
				width={'80%'}
			>
				<ChatPromptInput
					handleSubmit={handleSubmit}
					isPrinting={isPrinting}
					setIsPrinting={setIsPrinting}
					chatLogRef={chatLogRef}
				/>
			</Flex>
		</Flex>
	);
};
