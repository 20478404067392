import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';

export const styles = (theme: Theme) => {
	const {
		typography,
		breakpoints,
		palette,
		spacing,
		mixins: { alpha },
		mode,
		transitions,
	} = theme;

	return ifFeature(
		'encore',
		createStyles({
			root: {
				...typography.button,
				boxSizing: 'border-box',
				color: palette.grey[800],
				fill: palette.grey[800],
				maxWidth: 264,
				minWidth: 72,
				position: 'relative',
				flexShrink: 0,
				fontWeight: typography.fontWeightMedium,
				overflow: 'hidden',
				textAlign: 'left',
				textTransform: 'unset',
				transition: transitions.create(['color', 'fill']),
				whiteSpace: 'normal',
				// override MUI specificity
				'&&': {
					padding: spacing(0, 1.5, 2),
					'&$sizeLarge': {
						padding: spacing(0, 2, 2.5),
					},
					'&$modeFill': {
						padding: spacing(2),
					},
					'&$modeFill$sizeLarge': {
						padding: spacing(2.5, 3),
					},
				},
				'&:hover, &:focus-visible': {
					color: palette.primary.main,
					fill: palette.primary.main,
					textDecoration: 'none',
				},
			},
			dark: {
				color: palette.common.white,
				fill: palette.common.white,
				'&$disabled': {
					color: alpha(palette.common.white, 0.6),
					fill: alpha(palette.common.white, 0.6),
				},
				'&:not($selected):not($disabled)': {
					color: palette.common.white,
					fill: palette.common.white,
					'&:hover, &:focus-visible': {
						backgroundColor: alpha(palette.common.white, 0.1),
						color: palette.common.white,
						fill: palette.common.white,
					},
				},
			},
			description: {
				color: palette.gray[700],
				display: 'none',
				fontSize: typography.small.fontSize,
				fontWeight: typography.fontWeightRegular,
				lineHeight: typography.small.lineHeight,
				gridArea: 'description',
				'$modeFill$sizeLarge &': {
					display: 'unset',
				},
				'$dark &, $disabled &': {
					color: 'inherit',
				},
			},
			disabled: {
				color: palette.gray[600],
				fill: palette.gray[600],
			},
			dropdownIcon: {
				alignItems: 'center',
				display: 'inline-flex',
				gridArea: 'dropdownIcon',
				height: 16,
				width: 16,
			},
			fixedDropdown: {
				marginLeft: 'auto',
			},
			fullWidth: {
				flexShrink: 1,
				flexGrow: 1,
				flexBasis: 0,
				maxWidth: 'none',
				minWidth: 'min-content',
			},
			icon: {
				gridArea: 'icon',
				'& > svg': {
					display: 'block',
				},
			},
			isDropdown: {
				'&$disabled': {
					cursor: 'auto',
					pointerEvents: 'auto',
				},
			},
			label: {
				// retained from Jade to make transition to Encore easier
				display: 'inline-flex',
				color: mode === ModeOptions.Dye ? dyeColors.light : undefined,
				flexDirection: 'column',
				'&:after': {
					content: 'attr(data-text)',
					height: 0,
					visibility: 'hidden',
					overflow: 'hidden',
					userSelect: 'none',
					pointerEvents: 'none',
					fontWeight: 700,
				},
				fontSize: typography.large.fontSize,
				lineHeight: typography.large.lineHeight,

				gridArea: 'label',
				'$modeFill &': {
					fontSize: typography.medium.fontSize,
					lineHeight: typography.medium.lineHeight,
				},
				'$sizeLarge &': {
					fontSize: typography.h5.fontSize,
					lineHeight: typography.h5.lineHeight,
				},
				'$modeFill$sizeLarge &': {
					fontSize: typography.large.fontSize,
					lineHeight: typography.large.lineHeight,
				},
				'$dark$disabled &': {
					color: alpha(palette.common.white, 0.6),
				},
			},
			modeLine: {
				'&:hover:after, &:focus-visible:after': {
					background: palette.gray[600],
					borderRadius: 4,
					bottom: 0,
					content: '""',
					display: 'block',
					height: 3,
					position: 'absolute',
					width: '100%',
				},
			},
			modeFill: {
				borderTopLeftRadius: 9,
				borderTopRightRadius: 9,
				padding: spacing(2),
				'&$selected': {
					backgroundColor: palette.gray[100],
					boxShadow: 'none',
				},
				'&$sizeLarge': {
					padding: spacing(2.5, 3),
				},
				'&:hover:not($selected), &:focus-visible:not($selected)': {
					backgroundColor: alpha(palette.gray[50], 0.9),
				},
			},
			selected: {
				color: palette.primary.main,
				fill: palette.primary.main,
				fontWeight: 700,
			},
			sizeLarge: {
				maxWidth: 296,
				// retained from Jade to make transition to Encore easier
				'& .MuiTab-wrapper .MuiTab-label .MuiTypography-caption': {
					color: palette.gray[700],
				},
			},
			wrapper: {
				alignItems: 'center',
				// using grid because the label needs to be centered with the icon AND the description needs to align with the label
				display: 'grid',
				gridTemplateAreas: `
					"icon label       dropdownIcon"
					".    description .           "
				`,
				// would prefer to use column-gap here, but it inserts a gap between every column, even if those columns are empty
				// thus, even if we only have an icon, there would be a gap for label and dropdown icon as well
				'& $icon + $label, $dropdownIcon, $description': {
					marginLeft: 12,
				},
				'&:has($description)': {
					gridRowGap: spacing(0.25),
				},
			},
		}),
		createStyles({
			root: {
				...typography.button,
				maxWidth: 264,
				minWidth: 72,
				position: 'relative',
				minHeight: 48,
				flexShrink: 0,
				padding: '6px 12px',
				[breakpoints.up('sm')]: {
					padding: '6px 24px',
				},
				overflow: 'hidden',
				whiteSpace: 'normal',
				textAlign: 'center',
				[breakpoints.up('sm')]: {
					minWidth: 160,
				},
				'&:hover': {
					textDecoration: 'none',
				},
			},
			labelIcon: {
				minHeight: 72,
				paddingTop: 9,
				fill: mode === ModeOptions.Dye ? dyeColors.lighter : undefined,
				'& $wrapper > *:first-child': {
					marginBottom: 6,
				},
			},
			textColorInherit: {
				color: 'inherit',
				opacity: 0.7,
				'&$selected': {
					opacity: 1,
				},
				'&$disabled': {
					opacity: palette.action.disabledOpacity,
				},
			},
			textColorPrimary: {
				color: mode === ModeOptions.Dye ? dyeColors.main : palette.text.secondary,
				'&$selected': {
					color: palette.primary.main,
				},
				'&$disabled': {
					color: palette.text.disabled,
				},
			},
			textColorSecondary: {
				color: mode === ModeOptions.Dye ? dyeColors.lighter : palette.text.secondary,
				fill: mode === ModeOptions.Dye ? dyeColors.lighter : undefined,
				'&$selected': {
					color: palette.secondary.main,
				},
				'&$disabled': {
					color: palette.text.disabled,
				},
			},
			/* Pseudo-class applied to the root element if `selected={true}` (controlled by the Tabs component). */
			selected: {},
			/* Pseudo-class applied to the root element if `disabled={true}` (controlled by the Tabs component). */
			disabled: {},
			fullWidth: {
				flexShrink: 1,
				flexGrow: 1,
				flexBasis: 0,
				maxWidth: 'none',
			},
			wrapped: {
				fontSize: typography.pxToRem(12),
				lineHeight: 1.5,
			},
			wrapper: {
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				flexDirection: 'row',
				margin: spacing(1.375, 2),
			},
			label: {
				display: 'inline-flex',
				color: mode === ModeOptions.Dye ? dyeColors.light : undefined,
				flexDirection: 'column',
				'&:after': {
					content: 'attr(data-text)',
					height: 0,
					visibility: 'hidden',
					overflow: 'hidden',
					userSelect: 'none',
					pointerEvents: 'none',
					fontWeight: 700,
				},
			},
		})
	);
};
