import { useEffect, useState } from 'react';

import { DonutChartProps } from '../../types';

type UseMouseInteractionArguments<Datum> = Pick<DonutChartProps<Datum>, 'onMouseMove' | 'onMouseOut' | 'enableMouseInteraction'>;

interface UseMouseInteraction<Datum> {
	activeData: Datum | null;
	activeIndex: number;
	handleActive: (e: React.MouseEvent<SVGPathElement, MouseEvent>, data: Datum | null, index: number) => void;
	handleMouseOut: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
	onSvgMouseEnter: () => void;
	onSvgMouseLeave: React.MouseEventHandler<SVGSVGElement>;
}

export const useMouseInteraction = <Datum = unknown>({
	onMouseMove,
	onMouseOut,
	enableMouseInteraction,
}: UseMouseInteractionArguments<Datum>): UseMouseInteraction<Datum> => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const [activeData, setActiveData] = useState<Datum | null>(null);
	const [svgHovered, setSvgHovered] = useState(false);
	const [sectionHovered, setSectionHovered] = useState(false);

	useEffect(() => {
		if (enableMouseInteraction && !svgHovered && !sectionHovered) {
			setActiveIndex(-1);
			setActiveData(null);
		}
	}, [enableMouseInteraction, svgHovered, sectionHovered]);

	const escEventListener = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			setSvgHovered(false);
			setSectionHovered(false);
		}
	};
	useEffect(() => {
		document.addEventListener('keyup', escEventListener);
		return () => document.removeEventListener('keyup', escEventListener);
	}, []);

	const handleActive = (e, d, index) => {
		if (enableMouseInteraction) {
			setSectionHovered(true);
			setSvgHovered(true);
			setActiveIndex(index);
			setActiveData(d);

			if (onMouseMove) onMouseMove(e, d);
		}
	};

	const handleMouseOut = e => {
		if (enableMouseInteraction) {
			setSectionHovered(false);
			if (onMouseOut) onMouseOut(e);
		}
	};

	const onSvgMouseEnter = () => {
		setSvgHovered(true);
	};

	const onSvgMouseLeave = () => {
		setSvgHovered(false);
	};

	return {
		activeData,
		activeIndex,
		handleActive,
		handleMouseOut,
		onSvgMouseEnter,
		onSvgMouseLeave,
	};
};
