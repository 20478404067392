import React, { ComponentType, PropsWithChildren, ReactElement } from 'react';
import { Layout, LayoutFactoryProps, Model } from './layout-factory.types';
import { composeMiddleware } from './utils/compose-middleware';

export function LayoutFactory<C extends ComponentType<PropsWithChildren<M>>, M extends Model = Model>(
	props: LayoutFactoryProps<C, M>
) {
	const renderLayoutTree = (id: keyof Layout, layoutFactoryProps: LayoutFactoryProps<C, M>): ReactElement | null => {
		const { getComponent, middleware = [], models, layout } = layoutFactoryProps;

		const layoutNode = layout[id];
		const model = models[id];

		const children = layoutNode.children?.map(childId => renderLayoutTree(childId, layoutFactoryProps)) || [];

		const Component = getComponent(model) as ComponentType<PropsWithChildren<M>>;

		if (typeof Component === 'undefined') {
			throw new Error(`Could not find component to render for model: ${JSON.stringify(model)}`);
		}

		if (Component === null) {
			return null;
		}

		const props = composeMiddleware(middleware)({ ...model, children });

		return <Component key={model.id} {...props} />;
	};

	return renderLayoutTree('root', props);
}
