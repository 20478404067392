import { ChangeEvent } from 'react';
import { TextField } from '~components/text-field';
import { FormFactoryTextFieldModel } from '../types/builtin-models.types';
import { adapted } from '../utils/adapted';

const AdaptedTextField = adapted(TextField)((model: FormFactoryTextFieldModel) => {
	const { label, id, onBlur, onChange, note, required, status, value, width = 4 } = model;

	return {
		status,
		label,
		note,
		onChange: (event: ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				const { value } = event.currentTarget;

				onChange({ id, value });
			}
		},
		onBlur,
		required,
		value,
		width,
	};
});

export { AdaptedTextField as TextField };
