import { GetGroup } from '../types';

/**
 * Returns the name of the group at the previous index if the current index is
 * greater than zero. If there is no previous index (the current index is at
 * the beginning of the array) or `getGroup()` is undefined, the return value
 * will be undefined.
 */
export const getGroupAtPreviousIndex = ({ getGroup, index }: { getGroup?: GetGroup; index: number }): string | undefined => {
	const previousIndex = index - 1;
	return typeof getGroup === 'function' && previousIndex >= 0 ? getGroup(previousIndex) : undefined;
};
