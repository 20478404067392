import { getMaxZIndex, remove } from '@bamboohr/utils/lib/dom';
import { isEqual, isFunction } from 'lodash';
import React, { Component, Context, ContextType, createRef, Fragment } from 'react';
import { createPortal } from 'react-dom';

import { modifyAttachment } from './attachment';
import { CollisionLocker } from './components/collision-locker.react';
import { calculatePosition, modifyPosition } from './position';
import { CollisionStrategies, HeliumContextValue, HeliumProps, HeliumState, PlacementType, PositionType } from '../types/helium.types';
import {
	DEFAULT_COLLISION_STRATEGIES,
	DEFAULT_PLACEMENT,
	VESSEL_DISPLAY_VALUE_WHEN_VISIBLE,
	VESSEL_DISPLAY_VALUE_WHEN_HIDDEN,
} from './constants';
import { HeliumContext } from './helium-context';

export class Helium extends Component<HeliumProps, HeliumState> {
	constructor(props: HeliumProps) {
		super(props);

		const { isVisible } = props;

		this.state = {
			hasBeenVisible: isVisible,
		};

		this._vesselElement = document.createElement('div');
		this._vesselElement.style.visibility = 'hidden';

		this._elements = {};
	}

	static defaultProps = {
		collisionContainer: window,
		isAttached: false,
		isInline: true,
		isVisible: false,
		placement: DEFAULT_PLACEMENT,
		useCollisionLock: true,
	};

	static contextType: Context<HeliumContextValue> = HeliumContext;
	/* eslint-disable-next-line react/sort-comp, react/static-property-placement */
	context!: ContextType<typeof HeliumContext>;

	_insertVesselElement(): void {
		const { id = '', parentId } = this.props;

		const parentVessel = parentId ? document.querySelector(`[data-helium-id="${parentId}"]`) : null;
		const firstChildVessel: null | Element = parentId ? null : document.querySelector(`[data-helium-parent-id="${id}"]`);

		if (parentVessel) {
			document.body.insertBefore(this._vesselElement, parentVessel.nextElementSibling);
		} else if (firstChildVessel) {
			document.body.insertBefore(this._vesselElement, firstChildVessel);
		} else {
			document.body.appendChild(this._vesselElement);
		}
	}

	_makeVesselVisible(): void {
		window.requestAnimationFrame(() => {
			this._vesselElement.style.visibility = 'visible';
		});
	}

	_getCollisionStrategies(lockedPlacement?: PlacementType | null): CollisionStrategies {
		const { collisionStrategies: collisionStrategiesProp } = this.props;

		let collisionStrategies = collisionStrategiesProp;

		const { collisionStrategies: contextCollisionStrategies } = this.context;

		if (!collisionStrategies || collisionStrategies.length === 0) {
			collisionStrategies = contextCollisionStrategies || DEFAULT_COLLISION_STRATEGIES;
		}

		return lockedPlacement ? collisionStrategies.filter(strategy => strategy === 'constrain') : collisionStrategies;
	}

	_calculatePosition(): PositionType {
		const { isAttached, offset, placement } = this.props;

		const { lockedPlacement } = this.state;

		const _placement = lockedPlacement || placement;

		return calculatePosition(this._elements, _placement, {
			collisionStrategies: this._getCollisionStrategies(lockedPlacement),
			isAttached,
			offset,
		});
	}

	_attemptReposition(): void {
		const { isVisible } = this.props;

		window.requestAnimationFrame(() => {
			const canReposition = this._mounted && isVisible && this._vesselElement.style.display !== 'none';

			if (canReposition) {
				const oldPosition = this._oldPosition;
				const newPosition = (this._oldPosition = this._calculatePosition());

				if (!isEqual(newPosition, oldPosition)) {
					this._reposition(newPosition);
				} else if (this._vesselElement.style.visibility === 'hidden') {
					this._makeVesselVisible();
				}

				this._attemptReposition();
			}
		});
	}

	_reposition(position: PositionType): void {
		const { isAttached } = this.props;

		const { offsets, placement } = position;

		if (isAttached) {
			modifyAttachment(this._elements.vessel, offsets.anchor, placement);
		}

		modifyPosition(this._elements.vessel, offsets.vessel);

		this.setState(
			{
				appliedPlacement: placement,
				appliedOffsets: offsets,
			},
			() => {
				this._makeVesselVisible();
			}
		);
	}

	_show(): void {
		this._attemptReposition();
		this._updateVesselZIndex();
	}

	_hide(): void {
		this._vesselElement.style.visibility = 'hidden';
		this._vesselElement.style.height = '';
		this._vesselElement.style.width = '';
		this._oldPosition = undefined;
		this.setState({ lockedPlacement: null });
	}

	_updateElements(): void {
		const { collisionContainer } = this.props;

		this._elements = {
			anchor: this._anchorRef.current,
			container: collisionContainer,
			vessel: this._vesselElement,
		};
	}

	_updateVesselElement(): void {
		const { id, parentId, isVisible } = this.props;
		const element = this._vesselElement;

		if (!element) {
			return;
		}

		if (id && !element.dataset.heliumId) {
			element.dataset.heliumId = id;
		}

		if (parentId && !element.dataset.heliumParentId) {
			element.dataset.heliumParentId = parentId;
		}

		if (isVisible && element.style?.display !== VESSEL_DISPLAY_VALUE_WHEN_VISIBLE) {
			element.style.display = VESSEL_DISPLAY_VALUE_WHEN_VISIBLE;
			element.style.flexDirection = 'column';
		} else if (!isVisible && element.style?.display !== VESSEL_DISPLAY_VALUE_WHEN_HIDDEN) {
			element.style.display = VESSEL_DISPLAY_VALUE_WHEN_HIDDEN;
		}
	}

	_updateVesselZIndex(): void {
		const element = this._vesselElement;

		const maxZIndex = getMaxZIndex();
		const newZIndex = maxZIndex + 10;

		if (maxZIndex !== this._lastZIndex) {
			element.style.zIndex = (this._lastZIndex = newZIndex) as unknown as string;
		}
	}

	_handleCollisionLock = (): void => {
		this.setState((state: HeliumState) => {
			const { appliedPlacement } = state;

			return {
				lockedPlacement: appliedPlacement,
			};
		});
	};

	_anchorRef: React.RefObject<HTMLDivElement> = createRef();

	_elements:
		| Record<string, unknown>
		| {
				anchor: React.RefObject<unknown>;
				container: HTMLElement;
				vessel: HTMLElement;
		  };

	_lastZIndex: number;

	_mounted: boolean;

	_oldPosition?: PositionType;

	_vesselElement: HTMLElement;

	componentDidMount(): void {
		const { isVisible } = this.props;

		this._mounted = true;

		if (isVisible) {
			this._insertVesselElement();
		}

		this._updateElements();
		this._updateVesselElement();

		if (isVisible) {
			this._show();
		}
	}

	componentDidUpdate(prevProps: HeliumProps): void {
		const { hasBeenVisible } = this.state;

		const { isVisible: newIsShowing } = this.props;

		const { isVisible: oldIsShowing } = prevProps;

		this._updateElements();
		this._updateVesselElement();

		if (newIsShowing) {
			if (!hasBeenVisible) {
				this._insertVesselElement();

				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ hasBeenVisible: true });
			}

			if (!oldIsShowing) {
				this._show();
			}
		} else if (oldIsShowing && !newIsShowing) {
			this._hide();
		}
	}

	componentWillUnmount(): void {
		this._mounted = false;
		remove(this._vesselElement);
	}

	render(): JSX.Element {
		const { isFullWidth, isInline, placement, renderAnchor, renderVessel, useCollisionLock, isVisible } = this.props;

		const { lockedPlacement, hasBeenVisible, appliedOffsets, appliedPlacement: _appliedPlacement } = this.state;

		const appliedPlacement = _appliedPlacement || placement;
		const shouldUsePortal = isFunction(renderVessel) && (hasBeenVisible || isVisible === true);

		const collisionLockerEnabled = useCollisionLock && isVisible && !lockedPlacement;

		const renderPortal = createPortal as unknown as (children: React.ReactNode, container: Element) => Element;

		return (
			<CollisionLocker isEnabled={collisionLockerEnabled} onLock={this._handleCollisionLock}>
				<div
					key="anchor"
					ref={this._anchorRef}
					style={{
						display: isInline ? 'inline-block' : 'block',
						width: isFullWidth ? '100%' : undefined,
					}}
				>
					{renderAnchor({ appliedPlacement, appliedOffsets })}
				</div>

				{shouldUsePortal &&
					renderPortal(<Fragment>{renderVessel({ appliedPlacement, appliedOffsets })}</Fragment>, this._vesselElement)}
			</CollisionLocker>
		);
	}
}
