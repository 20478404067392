import { X16x16 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { FC, PropsWithChildren } from 'react';
import { IconButton } from '~components/button';
import { Flex } from '~components/flex';
import { Headline } from '~components/headline';
import { useStyles } from './header.styles';
import { HeaderProps } from './header.types';

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ onClose, icon, title, biId }) => {
	const { classes } = useStyles();

	return (
		<Flex flexDirection="column" flexGrow={0}>
			<div className={classes.header}>
				<Flex alignItems="center" gap={1} justifyContent="space-between">
					<Headline color={ifFeature('encore', 'primary', 'neutral-strong')} icon={icon} size="small">
						{title}
					</Headline>
					{ifFeature(
						'encore',
						<IconButton
							aria-label={window.jQuery ? $.__('close modal') : 'close modal'}
							biId={biId}
							icon="xmark-solid"
							onClick={onClose}
							size="extra-small"
							type="button"
							variant="outlined"
						/>,
						<IconButton
							aria-label={window.jQuery ? $.__('close modal') : 'close modal'}
							biId={biId}
							icon={<X16x16 aria-hidden="true" />}
							noBoundingBox={true}
							onClick={onClose}
							type="button"
						/>
					)}
				</Flex>
			</div>
		</Flex>
	);
};
