import { ChangeEvent } from 'react';
import { Checkbox, CheckboxOnChangeParam } from '~components/checkbox';
import { FormFactoryCheckboxFieldModel } from '../types/builtin-models.types';
import { adapted } from '../utils/adapted';

export const CheckboxField = adapted(Checkbox)((model: FormFactoryCheckboxFieldModel) => {
	const { checked = false, id, label, onBlur, onChange, required, status, value = '' } = model;

	return {
		checked,
		label,
		onChange: (param: CheckboxOnChangeParam, event: ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				onChange({ id, checked: event.currentTarget.checked });
			}
		},
		onBlur,
		required,
		status,
		value,
	};
});
