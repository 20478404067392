import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { HoverWrapper } from '../hover-wrapper';
import { Location9x12 } from '@bamboohr/grim';
import { HoverSettings } from '../templates/types';
import { TextButton } from '~components/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export interface HoverMapProps {
	hover: HoverSettings;
	children: ReactElement;
}

export const HoverMap: FunctionComponent<PropsWithChildren<HoverMapProps>> = props => {
	const { children, hover } = props;

	return (
		<HoverWrapper component={children} hasIcon={false}>
			<TextButton href={hover.link} startIcon={ifFeature('encore', 'location-dot-solid', <Location9x12 />)}>
				{window.jQuery ? $.__('Map') : 'Map'}
			</TextButton>
		</HoverWrapper>
	);
};
