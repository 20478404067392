import { ReactNode, DragEventHandler } from 'react';
import { PortalProps } from '~components/slidedown';

export interface UseFileDropperProps {
	/**
	 * Array of the allowed file extensions
	 *
	 * Can include the string `'documents'` to allow document types and the string `'images'` to include image types
	 *
	 * Defaults to the full standard list of document and image types
	 */
	acceptedTypes?: string[];
	/**
	 * Whether to allow dropping multiple files
	 */
	canSelectMultiple?: boolean;
	/**
	 * Maximum allowed size of a dropped file in bytes
	 */
	maxByteSize?: number;
	/**
	 * Function that gets called when files are dropped. It is passed an array of file objects.
	 */
	onFileDrop: (files: File[]) => void;
	/**
	 * Whether the user has permission to drag and drop files
	 * @default true
	 */
	permission?: boolean;
}

export interface FileDropperProps extends UseFileDropperProps {
	/**
	 * Children should include a file input element
	 */
	children: ReactNode;
}

export interface FileDropperDragDropListeners {
	onDragEnter?: DragEventHandler<HTMLDivElement>;
	onDragLeave?: DragEventHandler<HTMLDivElement>;
	onDragOver?: DragEventHandler<HTMLDivElement>;
	onDrop?: DragEventHandler<HTMLDivElement>;
}

export interface UseFileDropperType {
	fileDropAttributes: FileDropperDragDropListeners;
	isDragging: boolean;
	messageProps?: PortalProps;
}

export enum FileDropperUploadErrors {
	InvalidExtension = 'INVALID_EXTENSION',
	FileTooLarge = 'FILE_TOO_LARGE',
}
interface UploadError {
	message: string;
	data: string[];
	error: FileDropperUploadErrors;
}

export interface FileDropperValidatedFiles {
	files: File[];
	errors: UploadError[];
}
