// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classnames from 'classnames';
import { Divider } from '~components/divider';
import { Flex } from '~components/flex';
import {
	TransferListBaseProps,
	ListItem,
	TransferListItemProps,
	ListItemClickData,
	TransferListType,
	TransferListSide,
} from '../../types';
import { handleListNavigation } from './handle-list-navigation';
import { renderCategorizedList } from './render-categorized-list';
import { renderUncategorizedList } from './render-uncategorized-list';
import { useStyles } from './styles';

export function TransferListBase(props: TransferListBaseProps): ReactElement {
	const {
		ariaLabel,
		categorized,
		listItems = [],
		aboveListChildren,
		classes: classesOverride = {},
		listHeaderChildren,
		listRenderer = categorized ? renderCategorizedList : renderUncategorizedList,
		onRowSelect,
		title,
		blankState,
		reorderSubcategories,
		TransferListItemProps = {} as TransferListItemProps,
		transferListSide = TransferListSide.LEFT,
		transferListType = TransferListType.MULTI_SELECT,
	} = props;

	const { classes } = useStyles(undefined, { props: { classes: classesOverride } });

	const [focusedIndex, setFocusedIndex] = useState<number>(-1);

	function onItemClick(customEventData: ListItemClickData, event: PointerEvent, isSubcategory = false): Array<ListItem> {
		let updatedList: Array<ListItem> = [];
		if (typeof TransferListItemProps?.onClick === 'function') {
			try {
				TransferListItemProps.onClick(customEventData, event);
			} catch (error) {
				console.error('Error occurred in transfer list item click handler');
				console.error(error);
			}
		}
		if (typeof onRowSelect === 'function') {
			try {
				updatedList = onRowSelect({ value: listItems, key: customEventData.rowKey }, event, isSubcategory);
			} catch (error) {
				console.error(error);
			}
		}
		if (transferListType === TransferListType.SINGLE_SELECT || categorized) {
			setFocusedIndex(customEventData.listIndex - 1);
		}
		return updatedList;
	}

	function isListEmpty(list: Array<unknown>): boolean {
		if (!Array.isArray(list)) {
			return true;
		}

		if (list.length === 0) {
			return true;
		}

		return false;
	}

	const listContainerStyles = classnames(classes.rootDimensions, classes.root, classes.rootDisplay);

	const shouldRenderList = () => {
		if (categorized && transferListSide === TransferListSide.LEFT) {
			return true;
		}
		return listItems.length > 0;
	};

	return (
		<>
			{typeof aboveListChildren === 'function' && <div>{aboveListChildren()}</div>}
			{title && <div className={classes.title}>{title}</div>}
			<div className={listContainerStyles}>
				{typeof listHeaderChildren === 'function' &&
					ifFeature(
						'encore',
						<>
							<Flex flexDirection="column" gap={1} padding={2}>
								{listHeaderChildren()}
							</Flex>
							<Divider color="neutral-extra-weak" />
						</>,
						<div>{listHeaderChildren()}</div>
					)}
				{Array.isArray(listItems) && shouldRenderList() && typeof listRenderer === 'function' && (
					<DndProvider backend={HTML5Backend}>
						<div
							aria-label={ariaLabel}
							aria-multiselectable={(transferListType === TransferListType.MULTI_SELECT && !categorized) || undefined}
							className={classes.itemList}
							onKeyDown={evt => handleListNavigation(evt, focusedIndex, setFocusedIndex)}
							role={categorized && transferListSide === TransferListSide.LEFT ? 'group' : 'listbox'}
						>
							{listRenderer(
								listItems,
								onItemClick,
								TransferListItemProps,
								focusedIndex,
								transferListType,
								transferListSide,
								reorderSubcategories
							)}
						</div>
					</DndProvider>
				)}
				{isListEmpty(listItems) && typeof blankState === 'function' && blankState()}
			</div>
		</>
	);
}
