import { FormItemType } from '../enums';
import { FormConfig, FormLayout } from '../types';

export function isActingGroup(id: keyof FormLayout, { formElements, layout }: FormConfig) {
	const element = formElements[id];
	const layoutItem = layout[id];

	const defaultActingGroups: Array<string> = [FormItemType.Group, FormItemType.Section];
	const hasChildren = Array.isArray(layoutItem?.childIds) && layoutItem?.childIds.length;

	return !!(defaultActingGroups.includes(element?.type) || hasChildren || element?.groupable);
}
