import { makeStyles } from '~styles';
import { modalGradientFooter } from '~utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ breakpoints, palette, spacing }) => {
	return {
		root: ifFeature(
			'encore',
			{
				backgroundColor: palette.gray[50],
				borderBottomLeftRadius: '12px',
				borderBottomRightRadius: '12px',
				padding: '16px 20px',
				[breakpoints.down('sm')]: {
					padding: spacing(2),
				},
			},
			modalGradientFooter(palette.primary)
		),
		mobile: {
			alignItems: 'stretch',
			flexDirection: 'column',
		},
	};
});
