import React, { forwardRef } from 'react';
import { GlobalNavigationFooterItemButtonProps } from '../global-navigation.types';
import { useStyles } from './footer-item-button.styles';

export const FooterItemButton = forwardRef<HTMLButtonElement, GlobalNavigationFooterItemButtonProps>(({ children, ...rest }, ref) => {
	const { classes } = useStyles();
	return (
		<button type="button" {...rest} className={classes.root} ref={ref}>
			{children}
		</button>
	);
});
