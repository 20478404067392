import React, { ReactElement, useEffect, useState, useRef } from 'react';
import SignaturePad from 'signature_pad';
import { TextButton } from '@fabric/button';
import { useStyles } from './draw.styles';
import { SignatureDrawProps } from './draw.types';
import { trimAndConvertToImage } from '../utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox } from '~components/layout-box';

const clearText = window.jQuery ? $.__('Clear') : 'Clear';
const signaturePlaceholder = window.jQuery ? $.__('Draw your signature...') : 'Draw your signature...';
const initialsPlaceholder = window.jQuery ? $.__('Draw your initials...') : 'Draw your initials...';

export const SignatureDraw = (props: SignatureDrawProps): ReactElement => {
	const { biId, onChange, placeholder, variant } = props;
	const { classes } = useStyles();

	const [hasData, setHasData] = useState(false);
	const container = useRef<HTMLDivElement>(null);
	const canvas = useRef<HTMLCanvasElement>(null);
	const signaturePad = useRef<SignaturePad | null>(null);

	const placeholderText = placeholder || (variant === 'initials' ? initialsPlaceholder : signaturePlaceholder);

	function canvasOnChange() {
		if (canvas.current !== null) {
			onChange(trimAndConvertToImage(canvas.current));
		}
	}

	function clearCanvas() {
		signaturePad.current?.clear();
		setHasData(false);
		onChange('');
	}

	useEffect(() => {
		if (canvas.current !== null) {
			if (container.current !== null) {
				// Resize the canvas to its parent's dimensions so it doesn't skew the drawing
				canvas.current.height = container.current.clientHeight;
				canvas.current.width = container.current.clientWidth;
			}

			signaturePad.current = new SignaturePad(canvas.current);
			signaturePad.current?.addEventListener('beginStroke', () => {
				if (!hasData) {
					setHasData(true);
				}
			});
			signaturePad.current?.addEventListener('endStroke', () => canvasOnChange());
		}
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.container} ref={container}>
				<canvas ref={canvas}></canvas>
			</div>
			{hasData ? (
				<>
					{ifFeature(
						'encore',
						<LayoutBox margin="12px" position="absolute" right={0} top={0}>
							<TextButton
								color="inherit"
								data-bi-id={biId ? `${biId}-type-clear-button` : undefined}
								onClick={clearCanvas}
								size="small"
							>
								{clearText}
							</TextButton>
						</LayoutBox>,
						<div className={classes.clear}>
							<TextButton
								color="secondary"
								data-bi-id={biId ? `${biId}-draw-clear-button` : undefined}
								onClick={clearCanvas}
								size="small"
							>
								{clearText}
							</TextButton>
						</div>
					)}
				</>
			) : (
				<span className={classes.placeholder}>{placeholderText}</span>
			)}
		</div>
	);
};
