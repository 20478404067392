import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { FormHelperText } from '@mui/material';
import { useFormNoteStyles } from './form-note.styles';
import { NoteProps } from './types';
import { FieldContext } from '@fabric/form/context';

export const FormNote: FunctionComponent<PropsWithChildren<NoteProps>> = ({ id, ...props }) => {
	const { classes } = useFormNoteStyles();
	const { noteId, disabled } = useContext(FieldContext) ?? {};

	if (noteId) {
		id = noteId;
	}

	return <FormHelperText classes={{ root: classes.root, disabled: classes.disabled }} disabled={disabled} id={id} {...props} />;
};
