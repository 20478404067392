import React, { FC, PropsWithChildren } from 'react';
import { Popper as MUIPopper, PopperProps, useTheme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { PopperUnstyled } from '@mui/base';
import { useStyles } from './popper.styles';
import { useMaxZIndex } from '~components/base-modal/hooks';

export const Popper: FC<PropsWithChildren<PopperProps>> = props => {
	const { className, modifiers, style, ...rest } = props;
	const { spacing } = useTheme();
	const { classes, cx } = useStyles();
	const zIndex = useMaxZIndex(rest.open);

	return ifFeature(
		'encore',
		<PopperUnstyled
			className={cx(classes.root, className)}
			modifiers={[
				// add gap between popper and reference (anchor)
				{
					name: 'offset',
					options: {
						// [parallel (skid), perpendicular (distance)]
						offset: [0, parseInt(spacing(1), 10)],
					},
				},
				...(modifiers || []),
			]}
			placement="bottom-start"
			// eslint-disable-next-line react/forbid-component-props
			style={{ zIndex, ...style }}
			{...rest}
		/>,
		<MUIPopper
			className={className}
			modifiers={modifiers}
			placement="bottom-start"
			{...rest}
			// eslint-disable-next-line react/forbid-component-props
			style={{ ...style, width: undefined }}
		/>
	);
};
