import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(theme => {
	const { palette, breakpoints, mixins } = theme;

	return {
		root: {
			backgroundColor: ifFeature('encore', 'transparent', palette.gray[100]),
			borderTop: mixins.border(1, ifFeature('encore', palette.gray[200], palette.gray[400])),
			position: 'relative',
			margin: ifFeature('encore', '0 20px'),
			padding: ifFeature('encore', '20px 0'),
			[breakpoints.down('md')]: {
				borderRadius: '0 0 2px, 2px',
			},
		},
	};
});
