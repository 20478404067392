import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ButtonProps, ButtonSize } from '../../types';

export const useStyles = makeStyles<ButtonProps>()(({ mixins, palette, spacing, constructs, borderRadiuses }, { size }) => {
	const getIconGap = (size?: ButtonSize) => {
		switch (size) {
			case 'large':
			case 'biggie':
				return spacing(1.5);
			default:
				return spacing(1);
		}
	};

	return ifFeature(
		'encore',
		{
			altStyling: {
				'&$inline': {
					'&&:hover': {
						borderRadius: borderRadiuses[100],
						outline: `2px solid ${mixins.alpha(constructs.surface.neutral.inverted, 0.3)}`,
						outlineOffset: 4,
						'&::after': {
							display: 'none',
						},
					},
				},
				'&&:focus-visible': {
					outline: `2px solid ${mixins.alpha(constructs.surface.neutral.inverted, 0.8)}`,
				},
			},
			content: {
				'&&&': {
					display: 'unset',
					whiteSpace: 'unset',
				},
			},
			endIcon: {
				marginLeft: getIconGap(size),
				marginRight: 'unset', // undo MUI negative margin since content is edge-to-edge
			},
			error: {},
			info: {},
			inline: {
				'&&': {
					display: 'unset',
					fontSize: 'inherit',
					fontWeight: 'inherit',
					height: 'auto',
					lineHeight: 'inherit',
					verticalAlign: 'unset',
				},
			},
			inlineIcon: {
				alignItems: 'center',
				display: 'inline-flex',
				height: '1lh',
				verticalAlign: 'top',
			},
			root: {},
			startIcon: {
				marginLeft: 'unset', // undo MUI negative margin since content is edge-to-edge
				marginRight: getIconGap(size),
			},
			warning: {},
		},
		{
			altStyling: {},
			content: {},
			inline: {
				fontSize: 'inherit',
				fontWeight: 'inherit',
				height: 'auto',
				lineHeight: 'inherit',
				verticalAlign: 'unset',
			},
			endIcon: {
				marginLeft: spacing(0.75),
			},
			inlineIcon: {},
			error: {
				color: palette.error.main,
				fill: palette.error.main,
				'&:hover': {
					color: palette.error.dark,
					fill: palette.error.dark,
				},
				'&$altStyling': {
					color: palette.error.main,
					fill: palette.error.main,
					'&:hover': {
						color: palette.error.dark,
						fill: palette.error.dark,
					},
				},
			},
			info: {
				color: palette.info.main,
				fill: palette.info.main,
				'&:hover': {
					color: palette.info.dark,
					fill: palette.info.dark,
				},
				'&$altStyling': {
					color: palette.info.main,
					fill: palette.info.main,
					'&:hover': {
						color: palette.info.dark,
						fill: palette.info.dark,
					},
				},
			},
			root: {},
			startIcon: {
				marginRight: spacing(0.75),
			},
			warning: {
				color: palette.warning.main,
				fill: palette.warning.main,
				'&:hover': {
					color: palette.warning.dark,
					fill: palette.warning.dark,
				},
				'&$altStyling': {
					color: palette.warning.main,
					fill: palette.warning.main,
					'&:hover': {
						color: palette.warning.dark,
						fill: palette.warning.dark,
					},
				},
			},
		}
	);
});
