import React, { ReactElement } from 'react';
import { useSvg } from '~utils';
import { SvgProps } from './svg.types';
import { useSvgStyles } from './svg.styles';

export function Svg(props: SvgProps): ReactElement {
	const { ariaLabel, image } = props;
	const { classes } = useSvgStyles(props);
	const internalSvgProps = useSvg(`https://staticfe.bamboohr.com/assets/images/${image}.svg`);

	return <svg aria-label={ariaLabel} className={classes.root} {...internalSvgProps} />;
}
