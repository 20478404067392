// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState, forwardRef, Ref, ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { DropdownCaret9x5 } from '@bamboohr/grim';
import {
	Autocomplete as MuiAutocomplete,
	AutocompleteChangeReason,
	AutocompleteCloseReason,
	AutocompleteHighlightChangeReason,
	AutocompleteInputChangeReason,
	type PopperProps,
} from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getPopperZIndex } from './autocomplete.domain';
import {
	/* @startCleanup encore */
	useJadeStyles,
	/* @endCleanup encore */
	useStyles,
} from './autocomplete.styles';
import { AutocompleteProps, AutocompleteStyleProps } from './types';
import { Popper } from './popper/popper';
import { IconV2 } from '~components/icon-v2';
import { widths } from '~definitions/json/sizes.json';

declare module 'react' {
	function forwardRef<T, P = Record<string, unknown>>(
		render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
	): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

function AutocompleteComponent<T>(props: AutocompleteProps<T>, ref: Ref<HTMLInputElement>): ReactElement {
	const {
		className,
		classes: parentClasses = {},
		clearIcon,
		closeOnSelect = true,
		getFilteredOptions,
		getTagsLimitLabel,
		hasClearButton = false,
		hasPopupToggle = false,
		inputValue: inputValueProp,
		isAnyInputValueAllowed = false,
		loading: loadingProp = false,
		loadingText,
		noOptionsText: noOptionsTextProp,
		onChange,
		onClose,
		onHighlightChange,
		onInputChange,
		onOpen,
		open: openProp,
		popupIcon,
		popupMinWidthFactor,
		renderOption,
		tagsLimit,
		variant,
		...restMuiAutocompleteProps
	} = props;
	const [internalInputValue, setInternalInputValue] = useState(inputValueProp || '');
	const [defaultOpen, setDefaultOpen] = useState(false);
	const [defaultNoOptionsText, setDefaultNoOptionsText] = useState('No matches for');
	const [rerenderedOnceForOpen, setRerenderedOnceForOpen] = useState(false);

	const open = openProp === undefined ? defaultOpen : openProp;
	const inputValue = inputValueProp === undefined ? internalInputValue : inputValueProp;
	const popperZIndex = useMemo(() => {
		return open ? getPopperZIndex() : 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, rerenderedOnceForOpen]);

	useEffect(() => {
		// If 'open' is true on first render, force rerender the component one time
		// so that the popperZIndex can account for z-indexes of any elements
		// that might have also been rendered for the first time along with this component
		// and would have otherwise been missed by the initial popperZIndex useMemo.
		if (open) {
			setRerenderedOnceForOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const styleProps: AutocompleteStyleProps = {
		hasClearButton,
		hasPopupToggle,
		variant,
		/* @startCleanup encore */
		hasRenderOption: !!renderOption,
		popupMinWidthFactor,
		popperZIndex,
		/* @endCleanup encore */
	};

	const { classes } = useStyles(styleProps, { props });

	const PopperComponent = useCallback(
		({ style, ...rest }: PopperProps) => (
			<Popper
				{...rest}
				// MUI provides style.width === anchor.width, which we use if no width or full width is specified
				// eslint-disable-next-line react/forbid-component-props
				style={{
					minWidth: popupMinWidthFactor && popupMinWidthFactor !== 100 ? widths[popupMinWidthFactor] : style?.width,
				}}
			/>
		),
		[popupMinWidthFactor]
	);

	/* @startCleanup encore */
	const styles = useJadeStyles(styleProps);
	const classesJade = {
		...parentClasses,
		groupLabel: classNames(styles.groupLabel, parentClasses.groupLabel),
		groupUl: classNames(styles.groupUl, parentClasses.groupUl),
		inputRoot: classNames(styles.inputRoot, parentClasses.inputRoot),
		listbox: classNames(styles.listbox, parentClasses.listbox),
		option: classNames(styles.option, parentClasses.option),
		paper: classNames(styles.paper, parentClasses.paper),
		popper: classNames(styles.popper, parentClasses.popper),
	};
	const PopperComponentJade = popupMinWidthFactor === 100 ? undefined : Popper;

	/* @endCleanup encore */

	const noOptionsText = noOptionsTextProp || defaultNoOptionsText;
	let loading = loadingProp;

	// MuiAutocomplete does not show the noOptionsText prop if the freeSolo prop is true,
	// but it still shows the loadingText prop if freeSolo is true and loading is true.
	// This overrides the loadingText prop with noOptionsTextProp if loading is false so that a defined noOptionsTextProp can still show up.
	let loadingTextUpdated = loadingText;
	if (isAnyInputValueAllowed && noOptionsTextProp && !loading) {
		loading = true;
		loadingTextUpdated = noOptionsTextProp;
	}

	return (
		<MuiAutocomplete
			{...restMuiAutocompleteProps}
			classes={ifFeature('encore', classes, classesJade)}
			/* @startCleanup encore */
			className={ifFeature('encore', undefined, classNames(styles.root, className))}
			/* @endCleanup encore */
			clearIcon={ifFeature('encore', <IconV2 name="circle-x-solid" size={16} />, clearIcon)}
			disableClearable={!hasClearButton}
			disableCloseOnSelect={!closeOnSelect}
			filterOptions={getFilteredOptions}
			forcePopupIcon={hasPopupToggle}
			freeSolo={isAnyInputValueAllowed}
			getLimitTagsText={getTagsLimitLabel}
			inputValue={inputValue}
			limitTags={tagsLimit}
			loading={loading}
			loadingText={loadingTextUpdated}
			noOptionsText={noOptionsText}
			onChange={handleChange}
			onClose={handleClose}
			onHighlightChange={handleHighlightChange}
			onInputChange={handleInputChange}
			onOpen={handleOpen}
			open={open}
			PopperComponent={ifFeature('encore', PopperComponent, PopperComponentJade)}
			popupIcon={ifFeature(
				'encore',
				<IconV2 name="caret-down-solid" size={16} />,
				popupIcon || (
					<div className={classNames(styles.defaultPopupIcon, classesJade.defaultPopupIcon)}>
						<DropdownCaret9x5 />
					</div>
				)
			)}
			ref={ref}
			renderOption={renderOption}
		/>
	);

	function handleChange(event: React.SyntheticEvent, value: T | T[], reason: AutocompleteChangeReason): void {
		onChange(
			{
				value,
				reason,
			},
			event
		);
	}

	function handleClose(event: React.SyntheticEvent, reason: AutocompleteCloseReason): void {
		if (event.type !== 'mousedown') {
			setDefaultOpen(false);
		}

		if (onClose) {
			onClose(
				{
					reason,
				},
				event
			);
		}
	}

	function handleHighlightChange(event: React.SyntheticEvent, option: T, reason: AutocompleteHighlightChangeReason): void {
		if (onHighlightChange) {
			onHighlightChange(
				{
					option,
					reason,
				},
				event
			);
		}
	}

	function handleInputChange(
		event: React.ChangeEvent<HTMLInputElement>,
		value: string,
		reason: AutocompleteInputChangeReason
	): void {
		if (inputValueProp === undefined) {
			// Trim the start of value if inputValueProp is uncontrolled (i.e. undefined).
			value = value.trimStart();
		}
		setInternalInputValue(value);

		if (!noOptionsTextProp) {
			const noOptionsTextValue = window.jQuery ? $.__('No matches for "%1$s"', value) : `No matches for "${value}"`;
			setDefaultNoOptionsText(noOptionsTextValue);
		}

		if (onInputChange) {
			onInputChange(
				{
					value,
					reason,
				},
				event
			);
		}
	}

	function handleOpen(event: React.SyntheticEvent) {
		if (event.type !== 'mousedown') {
			setDefaultOpen(true);
		}

		if (onOpen) {
			onOpen(event);
		}
	}
}

export const Autocomplete = forwardRef(AutocompleteComponent);
