import { DatePickerDate, DatePickerDatePredicate, DatePickerUtils } from '../types';
import { getNextEnabledDate } from './get-next-enabled-date';
import { getPreviousEnabledDate } from './get-previous-enabled-date';

/**
 * Returns the two closest dates that occur before and after the reference date. If there is no
 * closest enabled date then undefined will be returned as the property value.
 * @param utils The date utils used all over the Date Picker components
 * @param date The reference date.
 * @param constraints The constraints that determine if a date is enabled.
 * @returns An object containing the closest dates to the reference date.
 */
export function getClosestEnabledDates(
	utils: DatePickerUtils,
	date: DatePickerDate,
	constraints: { getDateDisabled?: DatePickerDatePredicate; maxDate: DatePickerDate; minDate: DatePickerDate }
) {
	return {
		next: getNextEnabledDate(utils, date, constraints),
		previous: getPreviousEnabledDate(utils, date, constraints),
	};
}
