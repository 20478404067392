import React, { Children, isValidElement, ReactElement, ReactNode } from 'react';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import type { TabsProps } from '../types';
import { last } from 'lodash';

/* @startCleanup encore */
export const computeOverflowIndex = (
	wrapperWidth: number,
	tabsWrapperScrollWidth: number,
	tabElementsScrollWidthList: number[],
	tabElementsGap: number
): number | undefined => {
	let currentWidth = 0;
	let overflowIndex;
	let shouldOverflow: boolean | undefined;
	const dropdownWidth = last(tabElementsScrollWidthList) || 0;

	if (wrapperWidth < tabsWrapperScrollWidth) {
		shouldOverflow = tabElementsScrollWidthList?.slice(0, -1).some((scrollWidth, i) => {
			currentWidth += scrollWidth + tabElementsGap;
			overflowIndex = i;
			const isNotLastItemAndAddingDropdownWouldCauseOverflow =
				i < tabElementsScrollWidthList.length - 2 && currentWidth + dropdownWidth > wrapperWidth;

			if (currentWidth > wrapperWidth || isNotLastItemAndAddingDropdownWouldCauseOverflow) {
				return true;
			}

			return false;
		});
	}
	return shouldOverflow ? overflowIndex : undefined;
};

export const extractTextFromChildren = (element: ReactNode): string => {
	if (typeof element === 'string') return element;

	if (isValidElement(element)) {
		const elementWithProps = element as ReactElement;
		const { children } = elementWithProps.props;

		if (children) {
			const childTextArray = Children.map(children as React.ReactNode, (child: ReactNode) => extractTextFromChildren(child));
			return childTextArray?.join(' ') ?? '';
		}
	}
	return '';
};
/* @endCleanup encore */

/**
 * Converts icon names into elements. If an element is provided, it will be returned as is.
 */
export function getIcon(icon: IconV2Name | ReactElement, size: TabsProps['size']) {
	const sizeNum = size === 'medium' ? 20 : 24;

	return typeof icon === 'string' ? (
		// Ensure there is no layout shift while the icon is loading. Probably better to do this in `IconV2` directly, but that needs some more discussion.
		<div style={{ height: sizeNum, width: sizeNum }}>
			<IconV2 name={icon} size={sizeNum} />
		</div>
	) : (
		icon
	);
}
