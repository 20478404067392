import React from 'react';
import { FabricIcon } from '@fabric/icon';

const Nub = ({ icon }) => (
	<span className="fab-TextInputNub">
		<span className="fab-TextInputNub__icon">{typeof icon === 'string' ? <FabricIcon name={icon} /> : icon}</span>
	</span>
);

export default Nub;
