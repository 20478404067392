import React, { FC, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

const useStyles = makeStyles()(({ constructs, borderRadiuses, gradients, palette }) => ({
	root: ifFeature(
		'encore',
		{
			backgroundColor: constructs.surface.neutral.weak,
			borderRadius: borderRadiuses[1000],
			height: '4px',
			width: '100%',
		},
		{
			backgroundColor: palette.gray[300],
			height: '3px',
			width: '100%',
		}
	),
	progress: {
		background: ifFeature('encore', palette.primary.main, gradients.lighterToLightestLinear(palette.primary)),
		borderRadius: ifFeature('encore', borderRadiuses[1000]),
		display: 'block',
		height: '100%',
		transition: 'width 100ms',
	},
}));

export const ProgressBar: FC<PropsWithChildren<{ progress: number }>> = props => {
	const { progress = 0 } = props;
	const { classes } = useStyles();

	return (
		<div
			aria-label="file-upload-progress-bar"
			aria-valuemax={100}
			aria-valuemin={0}
			aria-valuenow={progress}
			className={classes.root}
			role="progressbar"
		>
			<div className={classes.progress} style={{ width: `${progress}%` }}></div>
		</div>
	);
};
