import { hasKey } from '@fabric/utils';
import React, { useContext } from 'react';
import { useFormContext } from '../../hook-form';
import { getRenderer, RendererProps } from '../../render-form';
import { ControlledRenderOptionsContext } from './controlled-render-options-context';
import { getFieldDefinition } from './get-field-definition';

export function ControlledInputRenderer({ children, element, formConfig }: RendererProps) {
	const formController = useFormContext<Record<string, unknown>>();

	const { getErrorState, getRenderer: getRendererOption } = useContext(ControlledRenderOptionsContext) || {};

	const { name } = element;
	if (typeof name !== 'string' || !hasKey(formController.fields, name)) {
		throw new Error(
			`There is a problem with your configuration. An item with the name of "${
				name as string
			}" has not been registered with the provided form controller.`
		);
	}

	const fieldDefinition = getFieldDefinition(formController, getErrorState, name);

	return formController.controlled(props => {
		const Renderer = getRenderer(element, { getRenderer: getRendererOption });

		if (typeof Renderer !== 'function') {
			throw new Error(`Could not find a renderer for your form element with the name ${name} and type ${element.type}`);
		}

		return (
			<Renderer element={{ ...props, ...element }} formConfig={formConfig}>
				{children}
			</Renderer>
		);
	}, fieldDefinition);
}
