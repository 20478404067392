import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme } from '@mui/material';
import { makeStyles } from '~styles';
import { AccordionProps } from './accordion.types';

const getTextColor = (disabled, expanded, theme: Theme) => {
	if (disabled) {
		return ifFeature('encore', theme.constructs.text.neutral.weak, theme.palette.gray[600]);
	}
	if (expanded) {
		return theme.palette.primary.main;
	}

	return theme.palette.gray[900];
};

const getIconColor = (disabled, expanded, theme: Theme) => {
	if (disabled) {
		return ifFeature('encore', theme.constructs.icon.neutral.strong, theme.palette.gray[600]);
	}
	if (expanded) {
		return ifFeature('encore', theme.constructs.icon.primary.strong, theme.palette.primary.main);
	}

	return ifFeature('encore', theme.constructs.icon.neutral.xxStrong, theme.palette.gray[900]);
};

const getPointerEvents = (hideDescriptionOnCollapse, expanded) => {
	if (hideDescriptionOnCollapse && expanded) {
		return 'auto';
	}
	if (hideDescriptionOnCollapse && !expanded) {
		return 'none';
	}

	return 'inherit';
};

export const useAccordionHeaderContentStyles = makeStyles<Partial<AccordionProps>>()((theme, props) => {
	const { constructs, palette, spacing, transitions, typography } = theme;
	const { disabled, expanded, hideDescriptionOnCollapse } = props;

	return {
		title: {
			display: 'flex',
			gridColumn: 'center-column',
		},
		defaultTitle: {
			color: getTextColor(disabled, expanded, theme),
			fontFamily: ifFeature('encore', typography.h5.fontFamily, typography.h4.fontFamily),
			fontSize: ifFeature('encore', typography.h5.fontSize, typography.h4.fontSize),
			fontWeight: ifFeature('encore', typography.h5.fontWeight, typography.h4.fontWeight),
			lineHeight: ifFeature('encore', typography.h5.lineHeight, typography.h4.lineHeight),
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			transition: `color 150ms ${transitions.easing.easeInOut}`,
			whiteSpace: 'nowrap',
			'.Mui-focusVisible &, .MuiAccordionSummary-root:hover &': {
				color: ifFeature('encore', constructs.text.primary.strong),
			},
		},
		headerContent: {
			alignItems: 'center',
			display: 'grid',
			gridColumnGap: spacing(1.25),
			gridTemplateColumns: '[left-column] auto [center-column] minmax(auto, 100%) [right-column] auto',
			width: '100%',
		},
		headerIcon: {
			display: 'flex',
			fill: getIconColor(disabled, expanded, theme),
			/* @startCleanup encore */
			minWidth: ifFeature('encore', undefined, 20),
			/* @endCleanup encore */
			gridColumn: 'left-column',
			transition: `fill 150ms ${transitions.easing.easeInOut}`,
			'.Mui-focusVisible &, .MuiAccordionSummary-root:hover &': {
				fill: ifFeature('encore', constructs.icon.primary.strong),
			},
		},
		headerRight: {
			display: 'flex',
			gridColumn: 'right-column',
			gridRow: 1,
		},
		description: {
			background: 'none',
			border: 'none',
			color: palette.gray[700],
			cursor: expanded ? 'initial' : 'inherit',
			fontSize: typography.small.fontSize,
			gridColumn: ifFeature('encore', 'center-column', hideDescriptionOnCollapse ? '1 / span 3' : 'center-column'),
			lineHeight: typography.small.lineHeight,
			opacity: hideDescriptionOnCollapse && !expanded ? 0 : 1,
			/* @startCleanup encore */
			padding: ifFeature('encore', undefined, hideDescriptionOnCollapse ? spacing(0, 0, 2, 3.75) : 'inherit'),
			/* @endCleanup encore */
			pointerEvents: getPointerEvents(hideDescriptionOnCollapse, expanded),
			textAlign: 'inherit',
			transition: `opacity 150ms ${transitions.easing.easeInOut}`,
			userSelect: expanded ? 'text' : 'inherit',
		},
	};
});
