import { getExtFromFileName } from '@bamboohr/utils/lib/file';
import { uniq } from 'lodash';

export function getReason(failuresByFileId) {
	return fileId => {
		const failure = failuresByFileId[fileId] || {};
		const file = failure.nativeFile;
		let reason = '';

		switch (failure.errorType) {
			case 'network_failure':
				reason = window.jQuery
					? $.__('Something went wrong with the network connection. Please give it another try.')
					: 'Something went wrong with the network connection. Please give it another try.';
				break;
			case 'storage_space_exceeded':
				reason = window.jQuery
					? $.__(
							`You can't upload any more files because you are over your plan's storage limit. Get in touch your BambooHR administrator for help.`
						)
					: `You can't upload any more files because you are over your plan's storage limit. Get in touch your BambooHR administrator for help.`;
				break;
			case 'invalid_file_type':
				reason = window.jQuery
					? $.__(
							`We can’t accept the ".%s" file format. Make sure you’re selecting the right file(s) and try again.`,
							getExtFromFileName(file.name)
						)
					: `We can’t accept the ".${getExtFromFileName(
							file.name
						)}" file format. Make sure you’re selecting the right file(s) and try again.`;
				break;
			case 'invalid_file_size':
				reason = window.jQuery
					? $.__('The maximum file size you can upload is 20 MB.')
					: 'The maximum file size you can upload is 20 MB.';
				break;
			case 'only_previewable_file_type':
				reason = window.jQuery
					? $.__('Make sure you’re only trying to upload the file types specified.')
					: 'Make sure you’re only trying to upload the file types specified.';
				break;
			case 'unknown_error':
				reason = window.jQuery
					? $.__('Something on our side prevented your file(s) from uploading. Please give it another try.')
					: 'Something on our side prevented your file(s) from uploading. Please give it another try.';
				break;
			case 'backup_company':
				reason = window.jQuery ? $.__(`You can't upload files on a backup.`) : `You can't upload files on a backup.`;
				break;
			default:
				reason = failure.message;
		}
		return reason;
	};
}

export function getReasons(failedFileIds, failuresByFileId) {
	return uniq(failedFileIds.map(getReason(failuresByFileId)).filter(reason => !!reason));
}
