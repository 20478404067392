import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import React from 'react';

const useStyles = makeStyles()(({ mode, palette, typography }) => ({
	root: {
		color: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[700],
		fontSize: ifFeature('encore', typography.large.fontSize, typography.small.fontSize),
	},
}));

export function calculateRange({ pageSize, currentPage = 1, totalItemsCount, pageCount }) {
	let topRange = pageSize * currentPage;
	let lowerRange = topRange - pageSize + 1;
	const extras = totalItemsCount - Math.floor(totalItemsCount / pageSize) * pageSize;

	if (extras !== 0 && pageCount === currentPage) {
		topRange = totalItemsCount;
		lowerRange = totalItemsCount - extras + 1;
	}

	return {
		topRange,
		lowerRange,
	};
}

export default function Total(props) {
	const { totalItemsCount } = props;
	const { topRange, lowerRange } = calculateRange(props);

	const { classes } = useStyles();

	return (
		<span className={classes.root}>
			{`${lowerRange === totalItemsCount ? '' : `${lowerRange}-`}${topRange} of ${totalItemsCount}`}
		</span>
	);
}
