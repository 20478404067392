import { ReactNode } from 'react';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { InlineMessageStatusType, InlineMessageVariant } from './inline-message.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useInlineMessageStyles = makeStyles<{
	isFullSize: boolean;
	description: ReactNode;
	status: InlineMessageStatusType;
	variant?: InlineMessageVariant;
}>({ name: 'InlineMessage' })((
	{ palette, borderRadiuses, typography, spacing, mode },
	{ isFullSize, description, status, variant }
) => {
	return ifFeature(
		'encore',
		{
			action: {
				alignItems: 'center',
				display: 'flex',
				height: spacing(4),
				paddingLeft: spacing(2.5),
			},
			container: {
				backgroundColor: (() => {
					if (variant === InlineMessageVariant.light) {
						return palette.common.white;
					}
					if (status === InlineMessageStatusType.neutral) {
						return palette.gray[100];
					}
					return palette[status].light;
				})(),
				borderRadius: borderRadiuses['200'],
				boxSizing: 'border-box',
				display: 'flex',
				flexDirection: 'row',
				padding: spacing(1.5),
			},
			icon: {
				alignItems: 'flex-start',
				display: 'flex',
				flexShrink: 0,
				justifyContent: 'center',
				marginRight: spacing(1),
				minWidth: 16,
				paddingTop: spacing(0.25),
			},
			root: {
				display: 'inline-block',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			},
			content: {},
			description: {},
			title: {},
		},
		{
			action: {},
			container: {
				alignItems: 'start',
				boxSizing: 'border-box',
				display: 'flex',
				justifyContent: 'start',
				minHeight: isFullSize ? 60 : 35,
				padding: isFullSize ? '8px 14px' : '7px 12px',
			},
			content: {
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				lineHeight: typography.medium.lineHeight,
			},
			description: {
				color: palette.gray[variant === InlineMessageVariant.light ? 800 : 900],
				fontSize: typography.small.fontSize,
				fontWeight: typography.fontWeightRegular,
			},
			icon: {
				alignItems: 'center',
				display: 'flex',
				height: isFullSize ? 44 : 22,
				justifyContent: 'center',
				marginRight: description ? spacing(1.75) : spacing(1),
				maxWidth: isFullSize ? 36 : 18,
			},
			root: {
				display: 'inline-block',
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			},
			title: {
				color: palette.gray[1000],
				fontSize: typography.medium.fontSize,
				fontWeight: typography.fontWeightSemibold,
			},
		}
	);
});
