import React, { ReactElement, VoidFunctionComponent } from 'react';
import { proxyGroupedIndexes } from './etc/proxy-grouped-indexes';
import { MenuListProps } from './types';

export const GroupedList = (Component: VoidFunctionComponent<MenuListProps>): VoidFunctionComponent<MenuListProps> => {
	return (props: MenuListProps): ReactElement => {
		const adjustedProps = proxyGroupedIndexes(props);

		return <Component {...adjustedProps} />;
	};
};
