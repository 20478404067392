import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StandardHeadline as BaseModalStandardHeadline } from '~components/base-modal';
import { FullScreenModalStandardHeadlineProps } from '../full-screen-modal.types';
import { IconTile } from '~components/icon-tile';
import { useStandardHeadlineStyles } from './standard-headline.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const StandardHeadline: FunctionComponent<PropsWithChildren<FullScreenModalStandardHeadlineProps>> = props => {
	const { icon, ...rest } = props;
	const { classes } = useStandardHeadlineStyles();
	return (
		<div className={classes.root}>
			{ifFeature(
				'encore',
				<BaseModalStandardHeadline
					icon={icon && typeof icon === 'string' ? <IconTile icon={icon} size={56} variant="primary" /> : undefined}
					{...rest}
				/>,
				<BaseModalStandardHeadline {...props} />
			)}
		</div>
	);
};
