import React from 'react';
import { GlobalNavigationFooterItemProps } from '../global-navigation.types';
import { useStyles } from './footer-item.styles';
import { FooterItemButton } from '../footer-item-button';

export function FooterItem({ ariaLabel, biId, children, containerRef, href, onClick }: GlobalNavigationFooterItemProps) {
	const { classes } = useStyles();

	const innards = () => {
		if (onClick) {
			return (
				<FooterItemButton aria-label={ariaLabel} data-bi-id={biId} onClick={onClick}>
					{children}
				</FooterItemButton>
			);
		}
		if (href) {
			return (
				<a aria-label={ariaLabel} className={classes.link} data-bi-id={biId} href={href}>
					{children}
				</a>
			);
		}
		return children;
	};

	return (
		<li className={classes.root} ref={containerRef}>
			{innards()}
		</li>
	);
}
