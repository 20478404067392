import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';

export const StyledRoot = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { palette, mixins } = theme as Theme;
	return {
		alignItems: 'center',
		borderBottom: mixins.border(1, palette.gray[300]),
		display: 'flex',
		paddingBottom: 8,
	};
});

export const StyledIcon = styled('span')<{ theme: Theme }>(({ theme }) => {
	const { mode, palette } = theme as Theme;
	return {
		fill: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
		lineHeight: 0,
		marginRight: 6,
	};
});
