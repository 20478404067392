import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { Label } from '~components/label';
import { makeStyles } from '~styles';
import { FormFactoryGroupModel } from '../types/builtin-models.types';

export type GroupProps = FormFactoryGroupModel & {
	children?: ReactNode;
};

const useStyles = makeStyles()({
	grid: {
		'&:empty': {
			display: 'none',
		},
	},
});

const DEFAULT_ROW_GAP = 2;
const DEFAULT_COLUMN_GAP = 1;

export function Group({ children, gap, label, orientation = 'vertical' }: GroupProps) {
	const { classes } = useStyles();

	const grid = (
		<Box
			className={classes.grid}
			display="flex"
			flexDirection={orientation === 'vertical' ? 'column' : 'row'}
			{...(orientation === 'horizontal' ? { columnGap: gap || DEFAULT_ROW_GAP } : { rowGap: gap || DEFAULT_COLUMN_GAP })}
		>
			{children}
		</Box>
	);

	return label ? (
		<label>
			<Label>{label}</Label>
			{grid}
		</label>
	) : (
		grid
	);
}
