import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classnames from 'classnames';
import sizes from '@fabric/definitions/json/sizes.json';
import { getConditionModifier } from '@fabric/utils/inputs';
import { isFunction, isUndefined, last, startCase } from 'lodash';

import { DEFAULT_SIZE, DEFAULT_WIDTH } from '../../../constants';

const utilDevLogger = createLogger('@fabric/select/util | <Select />');

export function getCSSClasses(bemBlock, state = {}) {
	const {
		appliedPlacement = {},
		condition,
		isClearable,
		isDark,
		isDisabled,
		isFocused,
		isInvalid,
		isOpen,
		selectedItems,
		size,
		type,
		width,
	} = state;

	const { widths, heights } = sizes;

	let _size = size;
	let _width = width;

	if (!isUndefined(width) && !widths.hasOwnProperty(width)) {
		const keys = Object.keys(widths).map(key => `"${key}"`);

		_width = DEFAULT_WIDTH;

		utilDevLogger.error(`An invalid width was supplied. Expected one of ${keys}. Got "${width}".`);
	}

	if (!heights.hasOwnProperty(size)) {
		const keys = Object.keys(heights).map(key => `"${key}"`);

		_size = DEFAULT_SIZE;

		utilDevLogger.error(`An invalid size was supplied. Expected one of ${keys}. Got "${size}".`);
	}

	return classnames(
		{
			[bemBlock]: true,
			[`${bemBlock}--bottom`]: isOpen && appliedPlacement.side === 'bottom',
			[`${bemBlock}--dark`]: isDark,
			[`${bemBlock}--disabled`]: isDisabled,
			[`${bemBlock}--clearable`]: isClearable && type !== 'text' && selectedItems.length > 0,
			[`${bemBlock}--focused`]: !isDisabled && isFocused,
			[`${bemBlock}--opened`]: !isDisabled && isOpen,
			[`${bemBlock}--top`]: appliedPlacement.side === 'top' && isOpen,
			[`${bemBlock}--view`]: type === 'view' && !isDisabled && !isInvalid && !isOpen && ifFeature('encore', !condition, true),
			[`${bemBlock}--width${_width}`]: width,
		},
		`${bemBlock}--size${startCase(_size)}`
	);
}

export function getInnerFacadeCSSClasses(bemBlock = '', { condition = '' } = {}, { variant = '' } = {}) {
	return ifFeature(
		'encore',
		classnames(
			`${bemBlock}__innerFacade`,
			{
				[`${bemBlock}__innerFacade${getConditionModifier(condition)}`]: !!condition,
			},
			{
				[`${bemBlock}__innerFacade--${variant}`]: !!variant,
			}
		),
		classnames(`${bemBlock}__innerFacade`, {
			[`${bemBlock}__innerFacade${getConditionModifier(condition)}`]: !!condition,
		})
	);
}

export function getGutsCSSClasses(bemBlock = '', { variant = '' } = {}) {
	const baseClass = `${bemBlock}__guts`;
	return ifFeature(
		'encore',
		classnames(baseClass, {
			[`${baseClass}--${variant}`]: !!variant,
		}),
		classnames(baseClass)
	);
}

export function getToggleButtonCSSClasses(bemBlock = '', { condition = '' } = {}) {
	return classnames(`${bemBlock}__toggleButton`, {
		[`${bemBlock}__toggleButton${getConditionModifier(condition)}`]: !!condition,
	});
}

export function getToggleContent(selectedItems, renderToggleContent, canSelectMultiple) {
	if (isFunction(renderToggleContent)) {
		return renderToggleContent(selectedItems);
	}

	if (!canSelectMultiple || selectedItems.length === 1) {
		return last(selectedItems).text;
	}

	if (selectedItems.length > 1) {
		return window.jQuery ? $.__n('%s Selected', '%s Selected', selectedItems.length) : `${selectedItems.length} Selected`;
	}
}
