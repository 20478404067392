import { InputWidth } from '~components/text-field/types';

/**
 * Type guard for width prop
 * Returns undefined if a non-number is passed in or if value is not in valid values object
 * @param width
 */

export function getValidInputWidthValue(width: unknown): InputWidth | undefined {
	const isNumeric = (width: unknown): boolean => {
		return typeof width === 'number' || (typeof width === 'string' && typeof JSON.parse(width) === 'number');
	};

	if (!isNumeric(width)) {
		return undefined;
	}

	const validInputWidth: Record<number, InputWidth> = {
		1: 1,
		2: 2,
		3: 3,
		4: 4,
		5: 5,
		6: 6,
		7: 7,
		8: 8,
		9: 9,
		10: 10,
		100: 100,
	};

	return validInputWidth[width as number];
}
