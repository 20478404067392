import { ValidationRules } from './validation.types';

export async function validateSingle(name: string, values: Record<string, unknown>, rules: ValidationRules) {
	const errors: Array<string | undefined> = await Promise.all(
		Object.values(rules).reduce((memo, rule) => {
			const validatorPromise = Promise.resolve(rule.test(name, values));

			const messagePromise = validatorPromise.then(valid => {
				if (valid) {
					return;
				}
				const message = typeof rule.message === 'function' ? rule.message() : rule.message;
				return message;
			});

			return [...memo, messagePromise];
		}, [])
	);

	return errors.filter((error): error is string => typeof error === 'string');
}
