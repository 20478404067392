/* eslint-disable sort-keys */
import type { CSSProperties } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { getEncoreValue } from '~utils';
import { BaseHeadlineProps } from './base-headline.types';

interface Props extends Pick<BaseHeadlineProps, 'color' | 'justify' | 'size' | 'url'> {
	shouldFlex: boolean;
}

export const useBaseHeadlineStyles = makeStyles<Props, 'icon'>()((theme, props, classes) => {
	const { constructs, mode, palette, spacing, typography } = theme;
	const { color, justify, shouldFlex, size, url } = props;

	const fontFillColor = getEncoreValue(color, {
		primary: ifFeature('encore', constructs.text.primary.strong, palette.primary.main),
		'neutral-weak': ifFeature('encore', constructs.text.neutral.weak, palette.grey[700]),
		'neutral-medium': ifFeature('encore', constructs.text.neutral.strong, palette.grey[800]),
		'neutral-strong': ifFeature('encore', constructs.text.neutral.xStrong, palette.grey[1000]),
		'neutral-inverted': ifFeature('encore', constructs.text.neutral.inverted, palette.common.white),
	});

	const lineHeight = getEncoreValue(size, {
		'extra-small': typography.h5.lineHeight,
		small: typography.h4.lineHeight,
		medium: typography.h3.lineHeight,
		large: typography.h2.lineHeight,
		'extra-large': typography.h1.lineHeight,
	});

	const iconMargin = getEncoreValue(size, {
		'extra-small': spacing(1.5),
		small: spacing(1.5),
		medium: spacing(1.5),
		large: spacing(2),
		'extra-large': spacing(2.5),
	});

	const justifyToTextAlignMap: Record<NonNullable<BaseHeadlineProps['justify']>, CSSProperties['textAlign']> = {
		center: 'center',
		end: 'right',
		start: 'left',
	};

	return {
		icon: {
			alignItems: 'center',
			display: 'inline-flex',
			fill: ifFeature('encore', undefined, url ? palette.info.main : fontFillColor),
			flexShrink: 0,
			height: lineHeight,
			marginRight: ifFeature('encore', iconMargin, spacing(1)),
		},
		root: ifFeature(
			'encore',
			{
				...(shouldFlex
					? {
							alignItems: 'flex-start',
							display: 'flex',
							justifyContent: justify,
						}
					: {}),
				color: fontFillColor,
				fill: fontFillColor,
				fontSize: getEncoreValue(size, {
					'extra-small': typography.h5.fontSize,
					small: typography.h4.fontSize,
					medium: typography.h3.fontSize,
					large: typography.h2.fontSize,
					'extra-large': typography.h1.fontSize,
				}),
				fontWeight: getEncoreValue(size, {
					'extra-small': typography.h5.fontWeight,
					small: typography.h4.fontWeight,
					medium: typography.h3.fontWeight,
					large: typography.h2.fontWeight,
					'extra-large': typography.h1.fontWeight,
				}),
				fontFamily: getEncoreValue(size, {
					'extra-small': typography.h5.fontFamily,
					small: typography.h4.fontFamily,
					medium: typography.h3.fontFamily,
					large: typography.h2.fontFamily,
					'extra-large': typography.h1.fontFamily,
				}),
				lineHeight,
				margin: 0,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				overflowWrap: 'anywhere',
				padding: 0,
				textAlign: justify && justifyToTextAlignMap[justify],
				wordBreak: 'normal',
			},
			{
				alignItems: 'center',
				display: 'flex',
				color: fontFillColor,
				fill: fontFillColor,
				fontSize: getEncoreValue(size, {
					'extra-small': typography.h5.fontSize,
					small: typography.h4.fontSize,
					medium: typography.h3.fontSize,
					large: typography.h2.fontSize,
					'extra-large': typography.h1.fontSize,
				}),
				fontWeight: getEncoreValue(size, {
					'extra-small': typography.h5.fontWeight,
					small: typography.h4.fontWeight,
					medium: typography.h3.fontWeight,
					large: typography.h2.fontWeight,
					'extra-large': typography.h1.fontWeight,
				}),
				fontFamily: getEncoreValue(size, {
					'extra-small': typography.h5.fontFamily,
					small: typography.h4.fontFamily,
					medium: typography.h3.fontFamily,
					large: typography.h2.fontFamily,
					'extra-large': typography.h1.fontFamily,
				}),
				justifyContent: justify,
				lineHeight,
				margin: 0,
				outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
				overflowWrap: 'anywhere',
				padding: 0,
				wordBreak: 'normal',
			}
		),
		link: {
			alignItems: ifFeature('encore', 'flex-start', 'center'),
			color: ifFeature('encore', undefined, palette.info.main),
			display: 'inline-flex',
			fill: ifFeature('encore', undefined, palette.info.main),
			fontWeight: typography.fontWeightSemibold,
			textDecoration: ifFeature('encore', 'inherit'),
			...ifFeature(
				'encore',
				{},
				{
					[`&:hover .${classes.icon}, &:focus-visible .${classes.icon}`]: ifFeature('encore', undefined, {
						fill: palette.primary.main,
					}),
				}
			),
		},
	};
});
