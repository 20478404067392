import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(() => {
	return {
		root: {
			marginTop: ifFeature('encore', undefined, '20px'),
		},
	};
});
