import { ifFeature } from '@bamboohr/utils/lib/feature';
import classnames from 'classnames';

export function getCSSClasses(props) {
	const { isActive, anchor } = props;

	return classnames('fab-MenuAddOption', {
		'fab-MenuAddOption--active': isActive,
		'fab-MenuAddOption--anchorBottom': anchor === 'bottom',
		'fab-MenuAddOption--anchorTop': anchor === 'top',
	});
}

export function getAddItemText() {
	return ifFeature('encore', window.jQuery ? $.__('Add...') : 'Add...', window.jQuery ? $.__('+ Add Item') : '+ Add Item');
}
