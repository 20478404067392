import { Spacing } from '@mui/system';

const spacingProps = [
	'gap',
	'rowGap',
	'columnGap',
	'padding',
	'paddingTop',
	'paddingRight',
	'paddingBottom',
	'paddingLeft',
	'paddingX',
	'paddingY',
	'margin',
	'marginTop',
	'marginRight',
	'marginBottom',
	'marginLeft',
	'marginX',
	'marginY',
];

export function transformSpacingProps(inputProps: Record<string, any>, spacing: Spacing) {
	return Object.keys(inputProps).reduce(
		(acc, key) => {
			const value = inputProps[key];
			if (spacingProps.includes(key) && typeof value === 'number') {
				acc[key] = spacing(value);
			} else if (typeof value === 'object' && value !== null) {
				acc[key] = transformSpacingProps(value, spacing);
			} else {
				acc[key] = value;
			}
			return acc;
		},
		{} as Record<string, any>
	);
}
