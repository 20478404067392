// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button } from '@fabric/button';
import { ActionProps } from '../types/ActionProps';
import { useStyles } from './slidedown.styles';

const Action: FC<PropsWithChildren<ActionProps>> = ({ biId, children, text, url }) => {
	const { classes } = useStyles();

	if (!(children || (text && url))) {
		return null;
	}

	return (
		<span className={classes.action}>
			{children ||
				ifFeature(
					'encore',
					<Button dark={true} data-bi-id={biId} href={url} size="small" type="button" variant="outlined">
						{text}
					</Button>,
					<a className={classes.actionLink} data-bi-id={biId} href={url}>
						{text}
					</a>
				)}
		</span>
	);
};

export default Action;
