import { noop } from 'lodash';
import { Component } from 'react';

export class ClickOut extends Component {
	static defaultProps = {
		isEnabled: false,
	};

	_addClickEvent() {
		document.addEventListener('click', this._handleClick);
	}

	_removeClickEvent() {
		document.removeEventListener('click', this._handleClick);
	}

	_handleClick = e => {
		const { isDescendant, onClickOut = noop } = this.props;

		if (!isDescendant(e.target)) {
			onClickOut();
		}
	};

	componentDidMount() {
		const { isEnabled } = this.props;

		if (isEnabled) {
			this._addClickEvent();
		}
	}

	componentDidUpdate() {
		const { isEnabled } = this.props;

		if (isEnabled) {
			this._addClickEvent();
		} else {
			this._removeClickEvent();
		}
	}

	componentWillUnmount() {
		const { isEnabled } = this.props;

		if (isEnabled) {
			this._removeClickEvent();
		}
	}

	render() {
		const { children } = this.props;

		return children || null;
	}
}
