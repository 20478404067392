export function modifyAttachment(element, offsets, placement) {
	const { side } = placement;

	if (side === 'top' || side === 'bottom') {
		element.style.width = `${offsets.right - offsets.left}px`;
	} else if (side === 'left' || side === 'right') {
		element.style.height = `${offsets.bottom - offsets.top}px`;
	} else {
		throw new Error(`Could not modify attachment. Expected one of "top", "left", "bottom", or "right", got: "${side}".`);
	}
}
