import { Directions } from '../enums';
import { verifyOffsets } from '../offsets';

export function getContainerCollisions(containerOffsets, containedOffsets) {
	const collisions = [];

	verifyOffsets(containerOffsets);
	verifyOffsets(containedOffsets);

	if (containedOffsets[Directions.TOP] < containerOffsets[Directions.TOP]) {
		collisions.push(Directions.TOP);
	}

	if (containedOffsets[Directions.BOTTOM] > containerOffsets[Directions.BOTTOM]) {
		collisions.push(Directions.BOTTOM);
	}

	if (containedOffsets[Directions.LEFT] < containerOffsets[Directions.LEFT]) {
		collisions.push(Directions.LEFT);
	}

	if (containedOffsets[Directions.RIGHT] > containerOffsets[Directions.RIGHT]) {
		collisions.push(Directions.RIGHT);
	}

	return collisions;
}

export function hasCollisionWithAnchor(offsets) {
	return getContainerCollisions(offsets.container, offsets.anchor).length > 0;
}
