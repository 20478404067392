import { isFunction } from 'lodash';

import { getContainerCollisions } from './container';
import { constrain } from './constrain';
import { flip } from './flip';
import { slide } from './slide';
import { CollisionStrategies } from '../enums';

export function fixCollisions(offsets, locations, strategies = [], additionalVesselOffsets) {
	const result = {
		locations,
		offsets,
	};

	const containerCollisions = offsets.container ? getContainerCollisions(offsets.container, offsets.vessel) : [];

	if (!(offsets.container || strategies.length || containerCollisions.length)) {
		return result;
	}

	return strategies.reduce((memo, strategy) => {
		const collisions = getContainerCollisions(offsets.container, memo.offsets.vessel);
		const strategyArguments = { additionalVesselOffsets, collisions, locations, offsets };

		let strategyResult;

		if (!collisions.length) {
			return memo;
		}

		if (isFunction(strategy)) {
			strategyResult = strategy(strategyArguments);
		} else if (strategy === CollisionStrategies.CONSTRAIN) {
			strategyResult = constrain(strategyArguments);
		} else if (strategy === CollisionStrategies.FLIP) {
			strategyResult = flip(strategyArguments);
		} else if (strategy === CollisionStrategies.SLIDE) {
			strategyResult = slide(strategyArguments);
		} else {
			throw new Error(`Could not fix collisions. Unsupported strategy "${strategy}" encountered.`);
		}

		return strategyResult ? { locations, offsets, ...strategyResult } : memo;
	}, result);
}
