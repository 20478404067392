import { de, enUS, es, fr, frCA, ja, nl, pt, ptBR, zhCN, Locale } from '@bamboohr/utils/lib/datetime';
import { DatePickerLocale } from '~components/date-picker';

export const locales: Record<DatePickerLocale, Locale> = {
	de,
	en: enUS,
	'en-us': enUS,
	es,
	'es-ar': es,
	fr,
	'fr-ca': frCA,
	ja,
	nl,
	pt,
	'pt-br': ptBR,
	zh: zhCN,
	'zh-cn': zhCN,
};
