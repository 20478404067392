import { RangeErrorType } from './constants';
import { getBothDatesMissingError } from './get-both-dates-missing-error';
import { getDateMissingError } from './get-date-missing-error';
import { DatePickerDate, DatePickerRangeError } from '../../types';

export function getRangeMissingDateError(
	startDate: DatePickerDate,
	endDate: DatePickerDate,
	startInputValue: string,
	endInputValue: string,
	required: boolean,
	startRequired: boolean,
	endRequired: boolean
): DatePickerRangeError {
	if (!startDate && !endDate) {
		const isRequired = required || startRequired || endRequired;
		return getBothDatesMissingError(startInputValue, endInputValue, isRequired);
	}

	if (!startDate) {
		return getDateMissingError(startInputValue, RangeErrorType.start, required || startRequired);
	}

	return getDateMissingError(endInputValue, RangeErrorType.end, required || endRequired);
}
