import { GetRowIdParams } from 'ag-grid-community';
import { isRecord } from '~utils';

export function getRowId<TData>(row: GetRowIdParams<TData>) {
	if (!isRecord(row.data)) {
		throw new Error(`Row data should be an object but got: ${JSON.stringify(row.data)}.`);
	}
	if (typeof row.data.id !== 'string') {
		throw new Error(
			`The "id" property for your row data must be a string type: ${JSON.stringify(
				row.data
			)}. Update the "id" property to be a string or supply your own "getRowId" function to use a different property for your row ID.`
		);
	}

	return row.data.id;
}
