import { GetDisabledAtPath, GetTotalAtPath, MenuListPath, PathTraverser, PathTraverserConfig } from '../../types';

export class BasicPathTraverser implements PathTraverser {
	_path: MenuListPath = [];

	getDisabled: GetDisabledAtPath;

	getTotal: GetTotalAtPath;

	constructor({ getDisabled, getTotal, initialPath }: PathTraverserConfig) {
		this.getDisabled = getDisabled || (() => false);
		this.getTotal = getTotal;

		if (this.getDisabled(initialPath)) {
			throw new Error('You cannot pass in an initial path that is disabled.');
		}

		this._path = initialPath || [0];
	}

	get leafIndex(): number {
		return this.path.slice(-1)[0] || 0;
	}

	get path(): MenuListPath {
		return this._path;
	}

	get parentPath(): MenuListPath {
		return this.path.slice(0, -1);
	}

	next(): PathTraverser {
		const totalAtSameLevel = this.getTotal(this.parentPath);
		if (this.leafIndex === totalAtSameLevel - 1) {
			this.first();
			return this;
		}

		for (let i = this.leafIndex + 1; i < totalAtSameLevel; i++) {
			const path = [...this.parentPath, i];
			if (!this.getDisabled(path)) {
				this._path = path;
				break;
			}

			if (i === totalAtSameLevel - 1) {
				return this.first();
			}
		}

		return this;
	}

	prev(): PathTraverser {
		if (this.leafIndex === 0) {
			this.last();
			return this;
		}

		for (let i = this.leafIndex - 1; i > -1; i--) {
			const path = [...this.parentPath, i];

			if (!this.getDisabled(path)) {
				this._path = path;
				break;
			}

			if (i === 0) {
				return this.last();
			}
		}

		return this;
	}

	dive(): PathTraverser {
		const totalChildren = this.getTotal(this.path);

		for (let i = 0; i < totalChildren; i++) {
			const path = [...this.path, i];
			if (!this.getDisabled(path)) {
				this._path = path;
				break;
			}
		}

		return this;
	}

	surface(): PathTraverser {
		this._path = this.parentPath.length ? this.parentPath : this.path;

		return this;
	}

	first(): PathTraverser {
		const totalAtSameLevel = this.getTotal(this.parentPath);

		for (let i = 0; i < totalAtSameLevel; i++) {
			const path = [...this.parentPath, i];
			if (!this.getDisabled(path)) {
				this._path = path;
				break;
			}
		}

		return this;
	}

	last(): PathTraverser {
		const totalAtSameLevel = this.getTotal(this.parentPath);

		for (let i = totalAtSameLevel - 1; i > -1; i--) {
			const path = [...this.parentPath, i];
			if (!this.getDisabled(path)) {
				this._path = path;
				break;
			}
		}

		return this;
	}
}
