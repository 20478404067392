import { textSizes, textWeights } from '../json/text.json';
/**
 * TODO: Conditionally return values from the encore json
 * We need the ifFeature() function available first from FAB-2093
 */
// import { textSizes as encoreTextSizes, textWeights as encoreTextWeights } from '../json/encore-text.json';

export const fabricFontStack = "'Lato', sans-serif";

export function fabricFontSize(size: keyof typeof textSizes): string {
	const sizeValue = textSizes[size];
	if (!sizeValue) {
		console.error(`The requested font size definition (${size}) does not exist.`);
		return '';
	}
	return `${sizeValue.size}px`;
}

export function fabricLineHeight(size: keyof typeof textSizes): string {
	const sizeValue = textSizes[size];
	if (!sizeValue) {
		console.error(`The requested line height definition (${size}) does not exist.`);
		return '';
	}
	return `${sizeValue.line}px`;
}

export function fabricFontWeight(weight: keyof typeof textWeights): number {
	const weightVariant = textWeights[weight];

	if (!weightVariant) {
		console.error(`The requested font weight definition (${weight}) does not exist.`);
		return 400; // default
	}

	return weightVariant;
}
