import React, { Children, isValidElement, ReactElement } from 'react';
import { makeStyles, createStyles, Theme } from '~mui';
import classnames from 'classnames';
import { dyeColors, ModeOptions } from '~styles';
import { LatticeProps } from '../types';
import { Item } from './item';
import { ItemInterface } from './item-interface';
import {
	copyLayout,
	fillHolesInLayout,
	fixLayoutKeys,
	fixLayoutKey,
	layoutToGridTemplateAreas,
	flattenLayoutToSet,
	calculatePositionInLayout,
} from '../utilities';
import * as calculators from '../layout-calculators';
import * as exposedUtilities from '../utilities/exposed-utilities';

const useStyles = makeStyles(({ mode }: Theme) => {
	return createStyles({
		root: {
			display: 'grid',
			gap: 10,
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		},
	});
});

export function Lattice({ animate = true, children, layout, className }: LatticeProps): ReactElement {
	const classes = useStyles({ layout });

	if (layout.length === 0) {
		return <></>;
	}

	layout = copyLayout(layout);
	layout = fillHolesInLayout(layout);
	layout = fixLayoutKeys(layout);

	const keysInLayout = flattenLayoutToSet(layout);
	const columns = layout[0].length;

	const moduleChildren = Children.map(children, child => {
		/**
		 * Filter out anything (divs and other elements) that is not a ModuleInterface
		 * AND that is not keyed in the layout that was passed
		 */
		if (!isValidElement(child) || child.type !== ItemInterface) {
			return null;
		}

		const { children: currentChildren } = child.props;
		let { layoutKey } = child.props;
		layoutKey = fixLayoutKey(layoutKey);

		if (!keysInLayout.has(layoutKey)) {
			return null;
		}

		return (
			// Add child.props to pass-through any additional props... like className
			<Item {...{ ...child.props, ...calculatePositionInLayout(layout, layoutKey) }} animate={animate} layoutKey={layoutKey}>
				{currentChildren}
			</Item>
		);
	});

	return (
		<div
			className={classnames(classes.root, className)}
			style={{
				gridTemplateAreas: layoutToGridTemplateAreas(layout),
				gridTemplateColumns: '1fr '.repeat(columns).trim(),
			}}
		>
			{moduleChildren}
		</div>
	);
}

Lattice.Item = ItemInterface;
Lattice.LayoutCalculators = calculators;
Lattice.Utils = exposedUtilities;
