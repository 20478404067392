import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ palette, spacing, constructs }) => {
	return {
		clear: {
			position: 'absolute',
			right: spacing(3),
			top: spacing(2),
		},
		container: {
			height: `calc(100% - ${spacing(5)})`,
			paddingTop: spacing(5),
		},
		placeholder: {
			bottom: 0,
			color: ifFeature('encore', constructs.text.neutral.xStrong, palette.gray[700]),
			lineHeight: spacing(3),
			padding: `${spacing(5)} 55px`,
			pointerEvents: 'none', // Prevent element from being interactive
			position: 'absolute',
		},
		root: {
			height: '100%',
		},
	};
});
