import { Theme } from '@mui/material';
import composeRefs from '@seznam/compose-react-refs';
import React, { ElementType, forwardRef, Ref } from 'react';
import { Typography, TypographyProps } from '~components/typography';
import { sideTabPaddingX } from '../constants';
import { disableKeyboardFocus } from '../utils/disable-keyboard-focus';

function SideTabGroupHeaderComponent(props: TypographyProps<ElementType, { component?: ElementType }>, ref: Ref<HTMLSpanElement>) {
	return (
		<Typography
			alignItems="center"
			color="gray.800"
			display="flex"
			gap={1}
			mb={0.5}
			px={`var(${sideTabPaddingX})`}
			ref={composeRefs(ref, disableKeyboardFocus)}
			// set `fill` for default icon color
			sx={({ palette }: Theme) => ({ fill: palette.gray[700] })}
			variant="teenie"
			{...props}
		/>
	);
}

SideTabGroupHeaderComponent.displayName = 'SideTabGroupHeader';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The title at the top of a `SideTabGroup`.
 *
 * You likely won't need to use this directly as it's included in `SideTabGroup`.
 */
export const SideTabGroupHeader = forwardRef(SideTabGroupHeaderComponent);
