import React, { useContext, type ElementType } from 'react';
import { SideNavigationLinkProps } from './link.types';
import { useStyles } from './link.styles';
import { SideNavigationContext } from '../side-navigation-context';
import { IconV2, type IconV2Name } from '~components/icon-v2';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LinkUnstyled } from '~components/link/unstyled';

export function Link<C extends ElementType = 'a'>(props: SideNavigationLinkProps<C>) {
	const { active, activeIcon, children, component = 'a', disabled = false, icon, ...rest } = props;
	const { compact } = useContext(SideNavigationContext);
	const { classes } = useStyles({ active, compact, disabled });
	const currentIcon = active && activeIcon ? activeIcon : icon;
	// eslint-disable-next-line react/destructuring-assignment
	const href = 'href' in props && !disabled ? (props.href as string | undefined) : undefined;

	return (
		<LinkUnstyled className={classes.root} component={component} href={href} {...rest}>
			{ifFeature(
				'encore',
				typeof currentIcon === 'string' ? <IconV2 name={currentIcon as IconV2Name} size={compact ? 16 : 20} /> : null,
				<div className={classes.icon}>{currentIcon}</div>
			)}
			{children}
		</LinkUnstyled>
	);
}
