import { makeStyles } from '~styles';
import { getEncoreValue } from '~utils';
import type { IconV2Props } from './icon-v2.types';

export const useStyles = makeStyles<Pick<IconV2Props, 'size' | 'color'>>()((theme, props) => {
	const { size, color } = props;

	return {
		svg: {
			fill: getEncoreValue(color, {
				/* eslint-disable sort-keys */
				'bamboo-brand-medium': theme.constructs.icon.bambooBrand.medium,
				'discovery-weak': theme.palette.discovery.light,
				'discovery-medium': theme.palette.discovery.main,
				'discovery-strong': theme.palette.discovery.dark,
				'error-weak': theme.palette.error.light,
				'error-medium': theme.palette.error.main,
				'error-strong': theme.palette.error.dark,
				'info-weak': theme.palette.info.light,
				'info-medium': theme.palette.info.main,
				'info-strong': theme.palette.info.dark,
				link: theme.palette.link.main,
				'neutral-extra-weak': theme.palette.gray[300],
				'neutral-weak': theme.palette.gray[400],
				'neutral-medium': theme.palette.gray[500],
				'neutral-strong': theme.palette.gray[600],
				'neutral-extra-strong': theme.palette.gray[800],
				'neutral-extra-extra-strong': theme.palette.gray[900],
				'neutral-forced-white': theme.palette.common.white,
				'neutral-inverted': theme.palette.common.white,
				'notice-strong': theme.constructs.icon.notice.strong,
				'primary-medium': theme.palette.primary.light,
				'primary-strong': theme.palette.primary.main,
				'success-weak': theme.palette.success.light,
				'success-medium': theme.palette.success.main,
				'success-strong': theme.palette.success.dark,
				'warning-weak': theme.palette.warning.light,
				'warning-medium': theme.palette.warning.main,
				'warning-strong': theme.palette.warning.dark,
				/* eslint-enable sort-keys */
			}),
			flexShrink: 0,
			height: `${size}px`,
			width: `${size}px`,
		},
	};
});
