import { ValidationField, ValidationRules } from './validation.types';
import { getRulesFromField } from './get-rules-from-field';

export function getRulesFromFields(fields: Record<string, ValidationField>): Record<string, ValidationRules> {
	return Object.entries(fields).reduce((memo, [name, field]) => {
		const rules = getRulesFromField(field);

		if (rules.length) {
			memo[name] = rules;
		}

		return memo;
	}, {});
}
