/**
 * Focuses the first focusable element that is not a close button contained by a certain element
 * If there is no other focusable element, focuses the close button
 * @param {HTMLElement} element The element to search inside of
 */
export function focusFirstFocusableElement(element: HTMLElement): void {
	// TODO: Remove the .fab-Select selector once the Select component has been updated to redirect focus.
	const focusableElement = element
		? (element.querySelector(
				'input:not([type="hidden"]):not(:disabled), textarea:not(:disabled), .fab-Select [role="button"]:not(:disabled), select:not(:disabled), a:not(:disabled), button:not(:disabled):not([aria-label~="close" i])'
			) as HTMLElement)
		: null;

	const closeButton =
		element && !focusableElement
			? (element.querySelector('button:not(:disabled)[aria-label~="close" i]') as HTMLButtonElement)
			: null;

	if (focusableElement) {
		focusableElement.focus();
	} else if (closeButton) {
		closeButton.focus();
	}
}
