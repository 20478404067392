import { GetStylesObjectType, GetStylesType } from './layout-escape-hatch.types';

/**
 * This function is available to support typings as a developer builds the `getStyles` function.
 * It can take either a styles object or a function that takes the theme as a parameter and returns a styles object.
 *
 * @example
 * makeLayoutEscapeHatchStyles(theme => ({
 *  alignItems: 'center',
 * }));
 * @example
 * makeLayoutEscapeHatchStyles({
 *  alignItems: 'center',
 * });
 */
export const makeLayoutEscapeHatchStyles = (getStyles: GetStylesType): GetStylesType => getStyles;

/**
 * This function is available to use in conjunction with `makeLayoutEscapeHatchStyles` to better support typings on the return type of the `getStyles` function.
 * This is because of a shortcoming in TypeScript that does not correctly error on some return types.
 *
 * @example
 * makeLayoutEscapeHatchStyles(theme =>
 * 	createLayoutEscapeHatchStyles({
 * 		backgroundColor: 'green', // fails
 * 		alignItems: 'center', // passes
 * 	})
 * );
 */
export const createLayoutEscapeHatchStyles = (styles: GetStylesObjectType): GetStylesObjectType => styles;
