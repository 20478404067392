import { TabUnstyled, tabUnstyledClasses } from '@mui/base';
import TabUnstyledProps, { TabUnstyledTypeMap } from '@mui/base/TabUnstyled/TabUnstyledProps';
import { styled, Theme } from '@mui/material';
import { dyeColors, ModeOptions } from '~styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MUIStyledCommonProps } from '@mui/system';
import React, { ElementType, forwardRef, ReactNode, Ref } from 'react';
import { sideTabPaddingX } from '../constants';
import { SideTabText } from './side-tab-text';

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export interface SideTabOwnProps extends MUIStyledCommonProps<Theme> {
	/**
	 * @deprecated className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	className?: string;
	/** Only applicable if `children` is a simple `string` */
	note?: ReactNode;
	/**
	 * @deprecated sx should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 */
	sx?: MUIStyledCommonProps['sx'];

	// Make `value` required. It's optional in MUI because it will default the
	// value to the tab's index, but this is confusing behavior when you have
	// non-tab elements like dividers and headers mixed in, so we require it.
	/** The value that corresponds to a tab panel. */
	value: TabUnstyledProps['value'];
}

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 */
export type SideTabProps<Component extends ElementType = TabUnstyledTypeMap['defaultComponent']> = TabUnstyledProps<
	Component,
	SideTabOwnProps
>;

const Tab = styled(TabUnstyled)<TabUnstyledProps>(({ theme }) => ({
	background: 'none',
	border: 'none',
	borderRadius: '2px',
	display: 'inline-block',
	outline: theme.mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
	overflow: 'hidden',
	overflowWrap: 'anywhere',
	padding: `${theme.spacing(1)} var(${sideTabPaddingX})`,
	textAlign: 'left',
	textDecoration: 'none',
	whiteSpace: 'normal',
	width: '100%',
	':focus-visible': {
		outline: 0,
		boxShadow:
			theme.mode === ModeOptions.Dye
				? `inset 0 0 0 2px ${dyeColors.lightest}`
				: `inset 0 0 0 2px ${theme.palette.primary.lightest}`,
	},
	[`:not(.${tabUnstyledClasses.disabled})`]: {
		cursor: 'pointer',
		[`&.${tabUnstyledClasses.selected}, :hover`]: {
			backgroundColor: theme.mode === ModeOptions.Dye ? dyeColors.lightest : theme.palette.grey[100],
		},
	},
}));

/**
 * @deprecated `SideTabs` is deprecated. Use `SideSubNavigation` instead.
 *
 * The button that a user can hit to show the corresponding `SideTabPanel`.
 */
export const SideTab = forwardRef(function SideTab({ children, note, ...props }: SideTabProps<'button'>, ref: Ref<HTMLButtonElement>) {
	return (
		<Tab ref={ref} type="button" {...props}>
			<SideTabText note={note}>{children}</SideTabText>
		</Tab>
	);
	// Generics are lost with `forwardRef`
	// https://github.com/mui/material-ui/issues/32420#issuecomment-1146757121
}) as OverridableComponent<TabUnstyledTypeMap<SideTabOwnProps>>;
