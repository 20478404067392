import { heights } from '~definitions/json/sizes.json';
import { InputDateRangeStyleProps } from '../../types';
import { makeStyles } from '~styles';

export const useStyles = makeStyles<InputDateRangeStyleProps>()(({ palette }, { size, disabled }) => ({
	endDate: {},
	rangeSeparator: {
		alignItems: 'center',
		display: 'flex',
		// The default size is set to medium
		// outlineInputHeight is the height of the input field on both sides of the range separator
		// we set the height equal to those so the separator is vertically centered
		height: heights[size || 'medium'],
		margin: '0 8px',
	},
	root: {
		alignItems: 'flex-end',
		color: disabled ? palette.gray[600] : palette.gray[1000],
		display: 'flex',
		flexWrap: 'nowrap',
	},
	startDate: {},
}));
