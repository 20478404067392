import { makeStyles } from '~styles';

export default makeStyles()(() => {
	return {
		wrapper: {
			alignItems: 'center',
			display: 'flex',
			height: '100%',
			justifyContent: 'center',
			position: 'absolute',
			width: '100%',
		},
		step: {
			display: 'none',
			opacity: '0',
			top: '0',
			transition: 'all 400ms ease',
			width: '100%',
		},
		earlier: {
			transform: 'translateX(-20px)',
		},
		later: {
			transform: 'translateX(20px)',
		},
		current: {
			display: 'block',
			transform: 'translateX(0)',
			opacity: '1',
		},
		animating: {
			display: 'block',
			pointerEvents: 'none',
		},
	};
});
