import { Hourglass13x16 } from '@bamboohr/grim';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { STILL_NEEDED } from './constants';
import { EmptyFileCardRoot } from './empty-file-card-root';
import { EmptyFileCardProps } from '@fabric/file-card';
import { IconV2 } from '~components/icon-v2';

export const EmptyFileCard: FunctionComponent<PropsWithChildren<EmptyFileCardProps>> = props => {
	const { children, fileName = '' } = props;
	const displayName = children || fileName;
	return (
		// eslint-disable-next-line jsx-a11y/role-supports-aria-props
		<EmptyFileCardRoot aria-disabled={true} aria-label={`${fileName}`} className="EmptyFileCard" role="region">
			<div className="EmptyFileCard__detail">
				<div className="EmptyFileCard__title">{displayName}</div>
			</div>
			<div className="EmptyFileCard__info">
				<span className="EmptyFileCard__icon">
					{ifFeature(
						'encore',
						<IconV2
							ariaLabel={window.jQuery ? $.__('Hourglass icon') : 'Hourglass icon'}
							color="neutral-weak"
							name="hourglass-regular"
							size={20}
						/>,
						<Hourglass13x16 />
					)}
				</span>
				{STILL_NEEDED}
			</div>
		</EmptyFileCardRoot>
	);
};
