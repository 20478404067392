import React, { forwardRef } from 'react';
import { DropdownCellEditor } from '../dropdown-cell-editor';
import { TextCellEditor } from '../text-cell-editor';
import { DatePickerCellEditor } from '../date-picker-cell-editor';
import { InternalCellEditorParams, InternalDropdownCellEditorParams } from '../data-grid.types';

export const getCellEditor = forwardRef((props: InternalCellEditorParams, ref) => {
	const { colDef, getType } = props;
	const type = getType?.(props) ?? colDef?.type;

	switch (type) {
		case 'text':
			return <TextCellEditor {...props} ref={ref} />;
		case 'dropdown':
			return <DropdownCellEditor {...(props as InternalDropdownCellEditorParams)} ref={ref} />;
		case 'date':
			return <DatePickerCellEditor {...props} ref={ref} />;
		default:
			return <TextCellEditor {...props} ref={ref} />;
	}
});
