import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import { Helium, PlacementType } from '@fabric/helium';
import { isFunction, noop, uniqueId } from 'lodash';
import React, { Component, createRef } from 'react';

import { Anchor } from './components/anchor.react';
import { ClickOut } from './components/click-out.react';
import { Vessel } from './components/vessel.react';
import { ADD_ITEM, DEFAULT_SEARCH_THRESHOLD } from './constants';
import { isMenuDescendant } from './dom-util';
import {
	hasChildren,
	hasAddTypeItems,
	isActionOnly,
	isDisabled,
	isSelected,
	getFirstInteractiveItem,
	getParent,
	getSelected,
	searchItems,
} from './item';
import { getActiveItem, shouldShowSearch } from './util';
import { ItemType, MenuProps, MenuState } from '../types';

const menuDevLogger = createLogger('@fabric/menu | <Menu />');

export class Menu extends Component<
	MenuProps & { biId?: string; condition?: string; isFullWidth?: boolean; size?: string },
	MenuState
> {
	get _activeItem(): ItemType | null {
		const { activeItem = null, items, selectedItems } = this.state;
		const { onSearch } = this.props;

		return getActiveItem({
			activeItem,
			items,
			selectedItems,
			searchText: typeof onSearch === 'function' ? '' : this._searchText,
		}) as ItemType | null;
	}

	get _searchText(): string {
		const { searchText: searchTextProp } = this.props;
		const { searchText: searchTextState } = this.state;

		return typeof searchTextProp !== 'undefined' ? searchTextProp : searchTextState;
	}

	static defaultProps = {
		canQuickSelect: false,
		canSelectMultiple: false,
		isFullWidth: false,
		isOpen: false,
		items: [],
		onDeselect: noop,
		onClose: noop,
		onSelect: noop,
		onOpen: noop,
		renderOptionContent: noop,
		showSearch: 'auto',
		searchThreshold: DEFAULT_SEARCH_THRESHOLD,
	};

	static getDerivedStateFromProps(props: MenuProps): { items: ItemType[]; selectedItems: ItemType[] } {
		const { canSelectMultiple, items = [], onAdd, selectedItems } = props;

		const _items = isFunction(onAdd) && !hasAddTypeItems(items) ? [...items, ADD_ITEM as ItemType] : items;

		let _selectedItems = getSelected(_items, selectedItems) as ItemType[];

		if (_selectedItems.length > 1 && !canSelectMultiple) {
			_selectedItems = _selectedItems.slice(-1);

			menuDevLogger.error('Multiple items were selected but menu is not configured for multi-item selection.');
		}

		return {
			items: _items,
			selectedItems: _selectedItems,
		};
	}

	_activateItem(item: ItemType): void {
		if (hasChildren(item)) {
			this._focusSearch();
		}

		this.setState({
			activeItem: item,
		});
	}

	_close(): void {
		const { onClose } = this.props;

		this.setState({
			activeItem: null,
			searchText: '',
		});

		if (onClose) {
			onClose();
		}
	}

	_addIds(items: ItemType[]): ItemType[] {
		items.forEach(item => {
			if (!item.id) {
				item.id = uniqueId('menu-item-');
			}
			if (item.items) {
				this._addIds(item.items);
			}
		});
		return items;
	}

	_getListItems(): ItemType[] {
		const { items = [] } = this.state;
		const { onSearch } = this.props;
		const updatedItems = this._addIds(items);
		return typeof onSearch === 'function' ? items : (searchItems(updatedItems || [], this._searchText) as ItemType[]);
	}

	_focusAnchor(): void {
		if (this._anchorRef.current) {
			this._anchorRef.current.focus();
		}
	}

	_focusAndClose(): void {
		this._focusAnchor();
		this._close();
	}

	_focusSearch(): void {
		const { isOpen } = this.props;

		const search = this._searchRef.current;

		if (search && isOpen) {
			// setTimeout prevents the browser from scrolling down before Helium has resolved collisions
			setTimeout(() => {
				search.focus();
			}, 100);
		}
	}

	focus() {
		this._focusAnchor();
	}

	_handleActiveItemChange = (item: ItemType): void => {
		this._activateItem(item);
	};

	_handleAdd = async (): Promise<unknown> => {
		const { canSelectMultiple, onAdd } = this.props;

		this.setState({
			isAdding: true,
		});

		return onAdd?.().then(
			() => {
				this.setState({
					isAdding: false,
				});

				if (!canSelectMultiple) {
					this._focusAndClose();
				}
			},
			() => {
				this.setState({
					isAdding: false,
				});
			}
		);
	};

	_handleClose = (): void => {
		this._close();
	};

	_handleSearch = (searchText: string): void => {
		const { onSearch } = this.props;

		if (typeof onSearch === 'function') {
			onSearch(searchText);
		} else {
			this.setState({ searchText });
		}
	};

	_handleOpen = (): void => {
		const { isDisabled: isDisabledProp, isOpen, onOpen } = this.props;

		if (!isOpen && !isDisabledProp && onOpen) {
			onOpen();
		}
	};

	_handleSelect = (item: ItemType, isQuickSelect = false): void | Promise<unknown> => {
		const { canSelectMultiple, onDeselect = noop, onSelect = noop } = this.props;

		const { items, selectedItems } = this.state;

		const parentItem = getParent(items, item) as ItemType;

		if (!item || isDisabled(item, items) || hasChildren(item)) {
			if (item && !isDisabled(item, items)) {
				this._activateItem(getFirstInteractiveItem(item.items));
			}
			return;
		}

		if (item.type === 'add') {
			return this._handleAdd();
		}

		let result;

		if (!isQuickSelect || (isQuickSelect && !canSelectMultiple)) {
			if (isSelected(item, selectedItems) && canSelectMultiple) {
				result = onDeselect(item, parentItem, { isQuickSelect });
			} else {
				result = onSelect(item, parentItem, { isQuickSelect });
			}
		}

		this._activateItem(item);

		Promise.resolve(result).then(
			() => {
				const shouldFocusAndClose =
					!isQuickSelect && (!canSelectMultiple || (isActionOnly(item, items) as boolean) || item.exclusive);

				if (this._isMounted && shouldFocusAndClose) {
					this._focusAndClose();
				}
			},
			() => menuDevLogger.warn('An error occured when selecting an item. Not attempting to close Menu')
		);
	};

	_id = uniqueId('fab-menu');

	_anchorRef: React.RefObject<Anchor> = createRef();

	_isMounted: boolean;

	_searchRef: React.RefObject<HTMLInputElement> = createRef();

	state: MenuState = {
		activeItem: null,
		searchText: '',
	};

	componentDidMount(): void {
		this._isMounted = true;

		this._focusSearch();
	}

	componentDidUpdate(prevProps: MenuProps): void {
		const { isOpen } = this.props;

		if (isOpen !== prevProps.isOpen) {
			this._focusSearch();
		}
	}

	componentWillUnmount(): void {
		this._isMounted = false;
	}

	render(): JSX.Element {
		const {
			ariaLabel,
			biId,
			canQuickSelect,
			canSelectMultiple,
			condition,
			children,
			inline = true,
			isAttached,
			isDisabled: isDisabledProp,
			isFullWidth,
			isOpen,
			listRole,
			onAdd,
			placement,
			renderOptionContent,
			renderToggle,
			searchPlaceholder,
			searchThreshold,
			showSearch,
			size,
			width,
		} = this.props;

		const { isAdding, items, selectedItems } = this.state;

		const isVisible = !isDisabledProp && isOpen;

		const listItems = this._getListItems();
		const _shouldShowSearch = shouldShowSearch(showSearch, searchThreshold, items);

		return (
			<ClickOut isDescendant={isMenuDescendant(this._id)} isEnabled={isVisible && !isAdding} onClickOut={this._handleClose}>
				<Helium
					id={this._id}
					isAttached={isAttached}
					isFullWidth={isFullWidth}
					isInline={inline}
					isVisible={isVisible}
					placement={placement}
					renderAnchor={({ appliedPlacement }: { appliedPlacement: PlacementType }) => (
						<Anchor
							activeItem={this._activeItem}
							ariaLabel={ariaLabel}
							biId={biId}
							canQuickSelect={canQuickSelect}
							canSelectMultiple={canSelectMultiple}
							isAdding={isAdding}
							isDisabled={isDisabledProp}
							isFullWidth={isFullWidth}
							isOpen={isOpen}
							items={listItems}
							menuId={this._id}
							menuPlacement={appliedPlacement}
							onActiveItemChange={this._handleActiveItemChange}
							onClose={this._handleClose}
							onOpen={this._handleOpen}
							onSelect={this._handleSelect}
							ref={this._anchorRef}
							renderToggle={renderToggle}
							selectedItems={selectedItems}
							shouldShowSearch={_shouldShowSearch}
						/>
					)}
					renderVessel={({ appliedPlacement }: { appliedPlacement: PlacementType }) => (
						<Vessel
							activeItem={this._activeItem}
							biId={biId}
							canSelectMultiple={canSelectMultiple}
							condition={condition}
							isAttached={isAttached}
							isOpen={isOpen}
							items={listItems}
							listRole={listRole}
							menuId={this._id}
							menuPlacement={appliedPlacement}
							onActiveItemChange={this._handleActiveItemChange}
							onAdd={onAdd}
							onSearch={this._handleSearch}
							onSelect={this._handleSelect}
							renderOptionContent={renderOptionContent}
							searchPlaceholder={searchPlaceholder}
							searchRef={this._searchRef}
							searchText={this._searchText}
							selectedItems={selectedItems}
							shouldShowSearch={_shouldShowSearch}
							size={size}
							width={width}
						>
							{children}
						</Vessel>
					)}
				/>
			</ClickOut>
		);
	}
}
