import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { uniqueId } from 'lodash';
import { TextButton } from '@fabric/button';
import { signatureFont, useStyles } from './type.styles';
import { SignatureTypeProps } from './type.types';
import { MAX_FONT_SIZE, MIN_FONT_SIZE, fitText, textFits, trimAndConvertToImage } from '../utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox } from '~components/layout-box';

const clearText = window.jQuery ? $.__('Clear') : 'Clear';
const signaturePlaceholder = window.jQuery ? $.__('Type your name...') : 'Type your name...';
const initialsPlaceholder = window.jQuery ? $.__('Type your initials...') : 'Type your initials...';

export const SignatureType = (props: SignatureTypeProps): ReactElement => {
	const { biId, onChange, placeholder, variant } = props;
	const { classes } = useStyles();

	const [value, setValue] = useState('');
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));
	const contextRef = useRef<CanvasRenderingContext2D>(canvasRef.current.getContext('2d', { willReadFrequently: true }));
	const inputRef = useRef<HTMLInputElement>(null);

	const placeholderText = placeholder || (variant === 'initials' ? initialsPlaceholder : signaturePlaceholder);
	const placeholderId = `signature-placeholder-${uniqueId()}`;

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (!value && inputRef.current) {
			inputRef.current.style.fontSize = event.target.style.fontSize || `${MAX_FONT_SIZE}px`;
		}
		//if the font size is as low as we want it to go and no more text fits, don't allow any more character entry
		if (inputRef.current && !textFits(inputRef.current) && parseInt(inputRef.current.style.fontSize, 10) <= MIN_FONT_SIZE) {
			return;
		}
		setValue(event.target.value);

		if (inputRef.current && event.target.style.fontSize) {
			fitText(inputRef.current, {
				maxFontSize: MAX_FONT_SIZE,
				minFontSize: MIN_FONT_SIZE,
			});
		}
		updateCanvas(event.target.value);
	}

	function handleClick() {
		setValue('');
		updateCanvas('');
		inputRef.current?.focus();
	}

	function updateCanvas(value: string) {
		if (contextRef.current !== null && value !== '') {
			contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
			contextRef.current.fillText(value, 10, canvasRef.current.height / 2, canvasRef.current.width - 30);
			onChange(trimAndConvertToImage(canvasRef.current), value);
		} else {
			onChange('');
		}
	}

	useEffect(() => {
		if (containerRef.current !== null) {
			// Resize the canvas to its parent's dimensions so it can fit the signature
			canvasRef.current.height = containerRef.current.clientHeight;
			canvasRef.current.width = containerRef.current.clientWidth;
		}

		if (contextRef.current !== null) {
			contextRef.current.font = signatureFont;
		}
	}, []);

	return (
		<div className={classes.root}>
			<div className={classes.container} ref={containerRef}>
				<input
					aria-labelledby={placeholderId}
					className={classes.input}
					onChange={handleChange}
					ref={inputRef}
					type="text"
					value={value}
				/>
			</div>
			{value ? (
				<>
					{ifFeature(
						'encore',
						<LayoutBox margin="12px" position="absolute" right={0} top={0}>
							<TextButton
								color="inherit"
								data-bi-id={biId ? `${biId}-type-clear-button` : undefined}
								onClick={handleClick}
								size="small"
							>
								{clearText}
							</TextButton>
						</LayoutBox>,
						<div className={classes.clear}>
							<TextButton
								color="secondary"
								data-bi-id={biId ? `${biId}-type-clear-button` : undefined}
								onClick={handleClick}
								size="small"
							>
								{clearText}
							</TextButton>
						</div>
					)}
				</>
			) : (
				<span className={classes.placeholder} id={placeholderId}>
					{placeholderText}
				</span>
			)}
		</div>
	);
};
