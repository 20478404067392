import { SelectableBoxTruncatedOptions } from './selectable-box.types';

export function shouldTruncate(truncated: boolean | SelectableBoxTruncatedOptions, qualifier?: keyof SelectableBoxTruncatedOptions) {
	if (truncated === true) {
		return true;
	}
	if (qualifier) {
		return !!truncated?.[qualifier];
	}
	// Make sure to return `false` if `truncated` is an empty object!
	return truncated && (truncated.title || truncated.description);
}
