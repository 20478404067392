export const getRequired = (required?: string | boolean): string | undefined => {
	if (required === false) {
		return undefined;
	}
	if (required === true) {
		return 'Required';
	}

	return required;
};
