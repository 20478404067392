import { makeUid } from '../makeUid';
import { OverlayComponentTypes } from './enums';
import { createLogger } from '@bamboohr/utils/lib/dev-logger';

const overlaysDevLogger = createLogger('@fabric/overlays');

export interface OverlayStackItem {
	type: string;
	uid: string;
}

const OPEN_OVERLAYS_STACK: Array<OverlayStackItem> = [];

export function pushItemToStack(type = '', uid = makeUid()): OverlayStackItem {
	if (!OverlayComponentTypes.includes(type)) {
		overlaysDevLogger.warn(`Unknown overlay type pushed to stack. Type: "${type}"`);
	}
	const newlyOpenOverlay = { type, uid };
	OPEN_OVERLAYS_STACK.push(newlyOpenOverlay);
	return newlyOpenOverlay;
}

/**
 * Removes the item with the given ID from the global overlay state and returns it.
 *
 * Does nothing if the item cannot be found.
 */
export function removeItemFromStack(uid: string): OverlayStackItem | undefined {
	const i = OPEN_OVERLAYS_STACK.findIndex(item => item.uid === uid);
	if (i > -1) {
		const [removedItem] = OPEN_OVERLAYS_STACK.splice(i, 1);
		return removedItem;
	}
}

export function countItemsOfType(type: string): number {
	return OPEN_OVERLAYS_STACK.filter(overlayItem => overlayItem.type === type).length;
}

export function replaceItemOnStack(uid: string, type: string): void {
	const index = OPEN_OVERLAYS_STACK.findIndex(item => item.uid === uid);

	if (index > -1) {
		OPEN_OVERLAYS_STACK[index].type = type;
	}
}
