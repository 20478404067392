import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { FC, PropsWithChildren } from 'react';
import { TimelineItem, TimelineProps } from '../types';
import { capitalize } from 'lodash';
import { getIcon, getText } from '../utils';
import { useTimelineEventStyles } from '../event/event.styles';
import { useHorizontalStyles } from './horizontalEvent.styles';

export const HorizontalEvent: FC<
	PropsWithChildren<
		TimelineItem & {
			color: TimelineProps['color'];
			size: TimelineProps['size'];
		}
	>
> = ({
	classes = {},
	color = 'primary',
	icon,
	index,
	isActive = false,
	isDisabled = false,
	isInverted = false,
	primaryText,
	secondaryText,
	size = 'large',
}) => {
	const { classes: eventClasses } = useTimelineEventStyles({});
	const { classes: horizontalClasses, cx } = useHorizontalStyles();

	classes = {
		...classes,
		itemRoot: cx(
			classes?.itemRoot,
			horizontalClasses.itemRoot,
			horizontalClasses.unsetIndex,
			horizontalClasses[`size${capitalize(size)}`],
			horizontalClasses[`color${capitalize(color)}`]
		),
		wrapper: horizontalClasses.wrapper,
		icon: cx(classes?.icon, eventClasses.icon, horizontalClasses.icon),
		content: horizontalClasses.content,
		contentPrimary: eventClasses.contentPrimary,
		contentSecondary: eventClasses.contentSecondary,
	};

	return (
		<li
			className={cx(classes.itemRoot, {
				[eventClasses.invert]: isInverted,
				[horizontalClasses.disabled]: isDisabled,
				[horizontalClasses.activeInprogress]: isActive,
			})}
		>
			<div className={classes.wrapper}>
				<div className={cx(classes.icon, { [horizontalClasses.noIcon]: !icon })}>
					{getIcon(icon, size, isInverted, isActive)}
					{!icon && <div className={horizontalClasses.number}>{Number(index) + 1}</div>}
				</div>
				<div className={classes.content}>
					<div
						className={cx(
							classes.contentPrimary,
							ifFeature(
								'encore',
								{ [horizontalClasses.activePrimaryContent]: isActive },
								{ [horizontalClasses.inactiveColor]: !isActive }
							)
						)}
					>
						{primaryText && getText(primaryText)}
					</div>
					<div
						className={cx(
							classes.contentSecondary,
							/* @startCleanup encore */
							ifFeature('encore', {}, { [horizontalClasses.inactiveColor]: !isActive })
							/* @endCleanup encore */
						)}
					>
						{secondaryText && getText(secondaryText)}
					</div>
				</div>
			</div>
		</li>
	);
};
