// eslint-disable-next-line no-use-before-define
import { InputBase } from '@mui/material';
import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { OutlinedInputProps } from './types';
import { useStyles } from './outlined-input.styles';

export const OutlinedInput = forwardRef<HTMLInputElement, OutlinedInputProps>((props, ref) => {
	// Don't apply custom styling if Encore is enabled
	const { classname, className, sx, ...encoreProps } = props;
	const appliedProps = ifFeature('encore', encoreProps, props);

	const {
		ariaLabel,
		biId,
		denseAdornment,
		focusRing,
		inputProps = {},
		size = 'medium',
		status,
		variant = 'form',
		viewMode,
		...rest
	} = appliedProps;

	const { classes, cx } = useStyles(appliedProps, { props: { classes: appliedProps.classes } });
	const _inputProps = {
		'aria-label': ariaLabel,
		type: 'text',
		...inputProps,
		'data-bi-id': biId,
	};

	return (
		<InputBase
			{...rest}
			classes={{
				root: cx(classes.root, classes.size, { [classes.denseAdornment]: denseAdornment }),
				focused: classes.focused,
				input: classes.input,
			}}
			error={status === 'error'}
			inputProps={_inputProps}
			inputRef={ref}
		/>
	);
}) as FC<PropsWithChildren<OutlinedInputProps>>;

OutlinedInput.defaultProps = {
	size: 'medium',
};
