import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { SectionHeaderProps } from './types';
import { useStyles } from './section-header.styles';
import { AdornedText } from '~components/adorned-text';

/**
 * @deprecated Please use `Headline`, `BodyText`, or `BadgeV2` instead.
 */
export const SectionHeader = forwardRef<HTMLElement, SectionHeaderProps>(
	({ biId, classes: classesProp = {}, hasMargin = true, icon, label, size = 'medium', subtitle, ...rest }, ref) => {
		const { classes } = useStyles({ hasMargin }, { props: { classes: classesProp } });

		return (
			<AdornedText adornment={icon} alignItems="center" biId={biId} classes={classes.root} component="div" ref={ref} {...rest}>
				<div>
					<div className={classes.label}>{label}</div>
					{subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
				</div>
			</AdornedText>
		);
	}
) as FC<PropsWithChildren<SectionHeaderProps>>;
