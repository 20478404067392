import { Box } from '@mui/material';
import React, { isValidElement } from 'react';
import { RendererProps } from '../../types';
import { Section } from './section';

export function SectionRenderer({ children, element, formConfig }: RendererProps) {
	const { id } = element;
	const { layout } = formConfig;

	const childIds = layout[id]?.childIds || [];
	const icon = typeof element.icon === 'object' && isValidElement(element.icon) ? element.icon : undefined;
	const label = typeof element.label === 'string' ? element.label : undefined;
	const subLabel = typeof element.subLabel === 'string' ? element.subLabel : undefined;

	return (
		<Section icon={icon} label={label} subLabel={subLabel}>
			{childIds.length ? (
				<Box display="flex" flexDirection="column" rowGap={2}>
					{children}
				</Box>
			) : null}
		</Section>
	);
}
