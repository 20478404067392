import React, { Children, isValidElement, useContext, useState, type ReactElement } from 'react';
import { Menu, type ItemType } from '~components/menu';
import { makeStyles } from '~styles';
import type { TabDropdownProps } from './tab-dropdown.types';
import { TabBase } from '../tab-base';
import { useTabDropdown } from './use-tab-dropdown';
import { extractTextFromChildren, getIcon } from '../utils';
import type { TabProps } from '../types';
import { TabsContext } from '../tabs-context';

const useStyles = makeStyles<Pick<TabDropdownProps, 'fixedPosition' | 'hidden'>>()((theme, props) => {
	const { fixedPosition, hidden } = props;
	return {
		root: {
			'> div, > div > *': {
				height: '100%',
			},
			marginLeft: fixedPosition ? 'auto' : undefined,
			position: hidden ? 'absolute' : undefined,
			visibility: hidden ? 'hidden' : undefined,
		},
	};
});

export function TabDropdown(props: TabDropdownProps) {
	const { label = window.jQuery ? $.__('More') : 'More', fixedItems, fixedPosition, hidden, onSelect, size, tabs } = props;
	const [isOpen, setIsOpen] = useState(false);
	const { classes } = useStyles({ fixedPosition, hidden });

	const { overflowIndex, setDropdownRef } = useTabDropdown({ alwaysVisible: !!fixedItems?.length });
	const dropdownTabElements = overflowIndex === -1 ? [] : Children.toArray(tabs).slice(overflowIndex);
	const { value } = useContext(TabsContext);

	const selectedItems: ItemType[] = [];
	const items: ItemType[] = dropdownTabElements
		.map((tab: ReactElement<TabProps>, index) => {
			if (isValidElement(tab) && !!tab?.props) {
				const { disabled, href, label: tabLabel, icon, value: itemValue } = tab.props;
				const labelText = extractTextFromChildren(tabLabel);

				const item: ItemType = {
					text: labelText || '',
					...(icon ? { icon: getIcon(icon, size) } : {}),
					href,
					id: String(itemValue)?.replace(' ', '-') || index,
					key: itemValue || index,
					value: itemValue || index,
					isDisabled: disabled || false,
					...tab.props.overflowProps,
				};

				if (itemValue === value) {
					selectedItems.push(item);
				}

				return item;
			}
			return {};
		})
		.concat(fixedItems || []);

	return (
		<div className={classes.root} ref={setDropdownRef}>
			<Menu
				canQuickSelect
				isOpen={isOpen}
				items={items}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				onSelect={item => onSelect(item.value)}
				placement={{ align: 'end', side: 'bottom' }}
				renderToggle={(_, { onClick, ...toggleProps }) => (
					<TabBase
						{...toggleProps}
						isDropdown
						label={label}
						onClick={e => {
							// @ts-expect-error event is MouseEvent, expected BaseSyntheticEvent
							onClick?.(e);
							setIsOpen(open => !!open);
						}}
					/>
				)}
				selectedItems={selectedItems}
			/>
		</div>
	);
}
