import { Typography as MUITypography, TypographyTypeMap, TypographyVariant } from '@mui/material';
import React, { forwardRef, Ref } from 'react';
import { TypographyProps } from './types/fab-typography-props';
import { useStyles } from './typographyStyles';

function TypographyComponent<D extends React.ElementType = TypographyTypeMap['defaultComponent'], P = object>(
	props: TypographyProps<D, P>,
	ref: Ref<HTMLSpanElement>
) {
	const { classes, cx } = useStyles();
	const fabricVariants = ['biggie', 'large', 'medium', 'small', 'teenie'];

	const { variant, className, color, sx, ...leftovers } = props;

	const variantClass = variant ? classes[variant] : classes.medium;

	let _variant = variant;
	if (fabricVariants.includes(variant as string)) {
		_variant = undefined;
	}

	return (
		<MUITypography
			className={cx(className, variantClass, classes.root)}
			ref={ref}
			sx={{ color, ...sx }}
			variant={_variant as TypographyVariant}
			{...leftovers}
		/>
	);
}

TypographyComponent.displayName = 'Typography';

/**
 * @deprecated Typography should no longer be used. Instead, use BodyText or Headline.
 */
export const Typography = forwardRef(TypographyComponent);
