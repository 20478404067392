import React, { isValidElement, ReactElement } from 'react';
import { Check11x11, ExclamationCircle28x28, Lock10x12 } from '@bamboohr/grim';
import { Statuses } from '../constants';
import { IconV2, IconV2Color, IconV2Name } from '~components/icon-v2';

/* @startCleanup encore */
export const getJadeIcon = (icon?: ReactElement, status?: string, disabled?: boolean, hideIcon?: boolean): ReactElement | null => {
	if (hideIcon) {
		return null;
	}
	if (icon && isValidElement(icon)) {
		return icon;
	}
	if (status === Statuses.success) {
		return <Check11x11 />;
	}
	if (status === Statuses.error) {
		return <ExclamationCircle28x28 />;
	}
	if (disabled) {
		return <Lock10x12 />;
	}

	return null;
};
/* @endCleanup encore */

export const getEncoreIcon = (
	active: boolean,
	customIcon?: IconV2Name,
	status?: string,
	disabled?: boolean,
	hideIcon?: boolean
): ReactElement | null => {
	let color: IconV2Color = 'neutral-extra-strong';
	let iconName: IconV2Name = 'circle-regular';

	if (disabled) {
		color = 'neutral-medium';
	}
	if (active) {
		color = 'primary-strong';
	}
	if (status === Statuses.error) {
		color = 'error-strong';
		iconName = 'circle-exclamation-solid';
	}
	if (status === Statuses.success) {
		color = 'primary-strong';
		iconName = 'circle-check-solid';
	}
	if (customIcon) {
		if (typeof customIcon !== 'string') {
			return null;
		}
		iconName = customIcon;
	}
	if (hideIcon) {
		iconName = 'circle-regular';
	}

	return <IconV2 color={color} name={iconName} size={32} />;
};
