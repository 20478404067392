import { GetGroup } from '../types';

/**
 * Reorders indexes so that all items in the same group are contiguous.
 * The original index of an item is stored as the value of its new index.
 *
 * For example:
 *
 * Given indexes `0` and `2` are in the same group but index `1` is in a different group,
 * the expected output would look like this:
 * ```
 * 	[0, 2, 1]
 * ```
 *
 * @param total The total number of items
 * @param getGroup A function that returns a group name when provided an index
 * @returns Array of ordered indexes
 */
export function orderIndexesByGroup(total: number, getGroup?: GetGroup): Array<number> {
	let orderedIndexes = new Array(total).fill(true).map((v, i) => i);

	if (typeof getGroup === 'function') {
		const groupTuples: [number, string | undefined][] = orderedIndexes.map(index => [index, getGroup(index)]);

		const groupedTuples = groupTuples.filter((value): value is [number, string] => typeof value[1] === 'string');
		const ungrouped = groupTuples.filter((value): value is [number, undefined] => typeof value[1] === 'undefined');

		const groupedIndexes = groupedTuples.reduce((memo, tuple) => {
			const index = tuple[0];
			const group = tuple[1];
			const othersInGroup = memo.get(group) || [];

			memo.set(group, [...othersInGroup, index]);

			return memo;
		}, new Map<string, Array<number>>());

		orderedIndexes = [];

		groupedIndexes.forEach(value => {
			orderedIndexes = [...orderedIndexes, ...value];
		});

		orderedIndexes = [...orderedIndexes, ...ungrouped.map(tuple => tuple[0])];
	}

	return orderedIndexes;
}
