import { makeStyles } from '~styles';
import { SectionHeaderProps } from './types';

export const useStyles = makeStyles<Partial<SectionHeaderProps>>()(({ palette, typography }, { hasMargin }) => ({
	root: {
		color: palette.gray[1000],
		lineHeight: typography.medium.lineHeight,
		marginBottom: hasMargin ? 0 : undefined,
	},
	icon: {
		display: 'inline-flex',
		fill: palette.primary.main,
		flexDirection: 'column',
		justifyContent: 'center',
		marginRight: '8px',
		minHeight: '22px',
	},
	label: {
		alignItems: 'center',
		display: 'flex',
		fontSize: typography.large.fontSize,
		fontWeight: typography.fontWeightSemibold,
	},
	subtitle: {
		color: palette.gray[800],
		fontSize: typography.small.fontSize,
		fontWeight: typography.fontWeightRegular,
	},
}));
