import React, { ReactElement } from 'react';
import { NameValuePairProps } from './name-value-pair.types';
import { BodyText } from '~components/body-text';
import { Flex } from '~components/flex';

export function NameValuePair({ name, value, description, size }: NameValuePairProps): ReactElement {
	return (
		<Flex flexDirection="column" gap={0.25}>
			<Flex flexDirection="column" gap={0.5}>
				<BodyText color="neutral-weak" size="small">
					{name}
				</BodyText>
				<BodyText color="neutral-extra-strong" size={size} weight="medium">
					{value}
				</BodyText>
			</Flex>
			{description ? (
				<BodyText color="neutral-weak" size="small">
					{description}
				</BodyText>
			) : null}
		</Flex>
	);
}
