import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles()(({ spacing }) => ({
	transferListRoot: {
		alignItems: ifFeature('encore', 'center'),
		boxSizing: 'border-box',
		display: 'flex',
		gap: ifFeature('encore', spacing(3)),
	},
	transferListCenterColumn: {
		display: 'flex',
		flexDirection: 'column',
		height: 374,
		justifyContent: 'center',
		minWidth: 24,
	},
}));
