import { FormConfig } from '../../render-form';

export function getValuesByToken(formConfig: FormConfig, valuesById: Record<string, unknown>) {
	const tokensById = Object.entries(formConfig.idsByToken).reduce<Record<string, string>>((memo, [key, value]) => {
		memo[value] = key;
		return memo;
	}, {});

	return Object.entries(valuesById).reduce((memo, [id, value]) => {
		const token = tokensById[id];

		if (token) {
			memo[token] = value;
		}

		return memo;
	}, {});
}
