import React from 'react';
import classNames from 'classnames';

/**
 * @deprecated Use TextField instead.
 */
const Textarea = props => {
	const { children, formId, hasError, hasInfo, hasWarning, isDisabled, isReadOnly, value = '', width, ...other } = props;

	const widthVariant = width ? width.toString() : null;
	const classes = classNames({
		'fab-Textarea': true,
		'fab-Textarea--error': hasError,
		'fab-Textarea--info': hasInfo,
		'fab-Textarea--warning': hasWarning,
		[`fab-Textarea--width${widthVariant}`]: widthVariant,
	});

	const textAreaValue = children && typeof children === 'string' ? children : value;

	return (
		<textarea
			className={classes}
			defaultValue={textAreaValue}
			disabled={isDisabled}
			form={formId}
			readOnly={isReadOnly}
			{...other}
		/>
	);
};

export default Textarea;
