import { makeStyles } from '~styles';

export const useActionTileStyles = makeStyles()(theme => {
	const { constructs, mixins } = theme;

	return {
		root: {
			cursor: 'pointer',
			textAlign: 'unset', // browser defaults buttons to text-align: center
			'&:hover, &:focus-visible': {
				borderColor: constructs.border.primary.medium,
			},
			'&:focus-visible': {
				boxShadow: `0 0 0 4px ${mixins.alpha(constructs.border.primary.strong, 0.1)}`,
			},
		},
	};
});
