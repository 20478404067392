import { create } from 'zustand';

interface PromptState {
	prompt: string;
	updatePrompt: (newPrompt: string) => void;
	clearPrompt: () => void;
}

export const usePromptStore = create<PromptState>()((set) => ({
	prompt: '',
	updatePrompt: (newPrompt) => set({ prompt: newPrompt }),
	clearPrompt: () => set({ prompt: '' }),
}));
