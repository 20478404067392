export const styles = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		lineHeight: '24px',

		'& *': {
			boxSizing: 'border-box',
		},
	},
};
