import { renderFormItem } from './render-form-item';
import { FormConfig, RenderOptions } from './types';

export function renderChildFormItems(childIds: Array<string>, formConfig: FormConfig, renderOptions?: RenderOptions) {
	const { layout } = formConfig;

	return (
		childIds.map(childId => {
			const child = layout[childId];

			if (!child) {
				console.error(`Could not find a item on the layout with the "id" of "${childId}".`);
				return null;
			}

			return renderFormItem(childId, formConfig, renderOptions);
		}) || []
	);
}
