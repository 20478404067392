import React, { type ElementType } from 'react';
import { LinkUnstyledProps } from './link-unstyled.types';
import { enhanceAnchorProps, formatBaseProps } from '~utils';
import { useStyles } from './link-unstyled.styles';
import { Box } from '@mui/material';

/**
 * Use inside Fabric only!
 */
export const LinkUnstyled = <C extends ElementType = 'a'>(props: LinkUnstyledProps<C>) => {
	const { className, component = 'a', containerRef, ...rest } = props;
	const { classes, cx } = useStyles();

	const formattedProps = formatBaseProps(rest);
	const anchorProps = enhanceAnchorProps(formattedProps as LinkUnstyledProps<C>);

	return (
		// `children` is included in `rest`
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		<Box {...anchorProps} className={cx(classes.root, className, 'fab-LinkUnstyled')} component={component} ref={containerRef} />
	);
};
