import { Layout } from '../types';

/**
 * Rotates a layout counterclockwise
 * input = [
 *     ['a', 'b'],
 *     ['c', 'd'],
 * ]
 *
 * output = [
 *     ['b', 'd'],
 *     ['a', 'c'],
 * ]
 */
export function rotateLayoutCounterClockwise(layout: Layout): Layout {
	const values: Layout = Array.from({ length: layout[0].length }, () => []);

	layout.forEach(innerArray => {
		innerArray.forEach((element, i) => {
			values[i].push(element);
		});
	});

	return values.reverse();
}
