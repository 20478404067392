/* eslint-disable react/forbid-component-props */
import React, { useEffect, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { X12x12 } from '@bamboohr/grim';
import { FloatingIconButton, IconButton } from '~components/button';
import { IconV2 } from '~components/icon-v2';
import { BannerIcon } from './banner-icon';
import { useStyles } from './banner.styles';
import { BannerProps, BannerStyleProps } from './types';

export function Banner({
	children,
	classes = {},
	className,
	icon,
	onClose,
	open = true,
	role,
	type = 'info',
}: BannerProps): JSX.Element {
	const [closed, setClosed] = useState(!open);

	const styleProps: BannerStyleProps = {
		hasClose: !!onClose,
		type,
	};
	const { classes: styles } = useStyles(styleProps);

	let bannerRole = 'status';
	if (type === 'warning') {
		bannerRole = 'alert';
	}

	useEffect(() => {
		let timeout: number;
		if (!open && !closed) {
			// Wait for the closing animation before removing the banner from the DOM
			timeout = window.setTimeout(() => {
				setClosed(true);
			}, 400);
		} else if (open) {
			setClosed(false);
		}
		return () => {
			if (timeout) {
				window.clearTimeout(timeout);
			}
		};
	}, [closed, open]);

	return closed ? (
		<></>
	) : (
		<div className={classNames(styles.container, { [styles.closing]: !open && !closed })}>
			<div className={styles.innerContainer}>
				<div className={classNames(styles.root, className, classes.root)} role={role || bannerRole}>
					{icon && <BannerIcon className={classNames(styles.icon, classes.icon)} icon={icon} />}
					{children && <div className={classNames(styles.content, classes.content)}>{children}</div>}
					{onClose && (
						<div className={classNames(styles.closeIcon, classes.closeIcon)}>
							{ifFeature(
								'encore',
								<IconButton
									aria-label={window.jQuery ? $.__('Close Alert Message') : 'Close Alert Message'}
									color="secondary"
									dark={true}
									icon="xmark-solid"
									onClick={onClose}
									size="small"
								/>,
								<FloatingIconButton
									aria-label={window.jQuery ? $.__('Close Alert Message') : 'Close Alert Message'}
									clickAction={onClose}
									dark={true}
									hasBounding={false}
									icon={<X12x12 />}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
