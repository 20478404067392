// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { useTheme } from '@mui/styles';
import { BarGroupsProps } from '../types';

type ScaleType = string & { valueOf(): number };

export const BarGroupsVertical = <Datum = unknown,>({
	data,
	getSeriesValue,
	getBarGroupsCondition = () => true,
	getBarGroupsFormat,
	xScale,
	yMax,
}: BarGroupsProps<Datum>): ReactElement => {
	const { palette, typography } = useTheme();
	const lineColor = palette.gray[400];
	const strokeWidth = 1;
	return (
		<g>
			{data.map((d, index) => {
				const xScaleValue = xScale(getSeriesValue(d) as ScaleType) as number;
				const value = String(getSeriesValue(d));
				const x = xScaleValue - xScale.paddingInner() * xScale.step() * 0.5;
				const displayText = getBarGroupsFormat ? getBarGroupsFormat(value) : value;
				const shouldRender = getBarGroupsCondition && getBarGroupsCondition(value);

				return (
					<g key={`bar-group-${String(index)}-${value}`}>
						{shouldRender && index !== 0 && (
							<line
								shapeRendering="crispEdges"
								stroke={lineColor}
								strokeWidth={strokeWidth}
								x1={x}
								x2={x}
								y1={-40}
								y2={yMax + 25}
							/>
						)}
						{shouldRender && (
							<text fontFamily={typography.fontFamily} fontSize={typography.medium?.fontSize} x={xScaleValue} y={-25}>
								{displayText}
							</text>
						)}
					</g>
				);
			})}
		</g>
	);
};
