import React from 'react';
import type { BaseIconV2Props } from './base-icon-v2.types';
import { formatBaseProps, useSvg } from '~utils';

/**
 * Use in Fabric only!
 */
export const BaseIconV2 = (props: BaseIconV2Props) => {
	const { containerRef, name, ...externalSvgProps } = props;

	const internalSvgProps = useSvg(`https://staticfe.bamboohr.com/assets/icons/${name}.svg`);

	return <svg {...internalSvgProps} {...formatBaseProps(externalSvgProps)} ref={containerRef} />;
};
