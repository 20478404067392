import { ValidationRules } from './validation.types';
import { validateSingle } from './validate-single';

export async function validate(allValues: Record<string, unknown>, allRules: Record<string, ValidationRules>) {
	const promises = Object.keys(allRules).map(async name => {
		const errors = await validateSingle(name, allValues, allRules[name]);

		return { name, errors };
	});

	const results = await Promise.all(promises);

	return results.reduce(
		(memo, result) => ({
			...memo,
			[result.name]: result.errors,
		}),
		{}
	);
}
