import { FieldValues, FormProvider, UseFormProps } from '../../hook-form';
import React from 'react';
import { FormConfig, getRenderer, renderForm } from '../../render-form';
import { ControlledRenderOptions } from '../types';
import { ControlledInputRenderer } from './controlled-input-renderer';
import { ControlledRenderOptionsContext } from './controlled-render-options-context';
import { isFormInputElement } from './is-form-input-element';

export function renderControlledForm<T extends FieldValues>(
	formConfig: FormConfig,
	formController: UseFormProps<T>,
	renderOptions: ControlledRenderOptions = {}
) {
	return (
		<FormProvider {...formController}>
			<ControlledRenderOptionsContext.Provider value={renderOptions}>
				{renderForm(formConfig, {
					getRenderer: element => {
						return isFormInputElement(element) ? ControlledInputRenderer : getRenderer(element, renderOptions);
					},
				})}
			</ControlledRenderOptionsContext.Provider>
		</FormProvider>
	);
}
