/* eslint-disable sort-keys */
import { GetConstructsProps } from '../../types/constructs';
import { getEncorePrimitives } from '../palette/get-encore-primitives';
import { getConstructsLight, type ConstructMap } from './get-constructs-light';
import { getConstructsDark } from './get-constructs-dark';

export function getConstructs({ borderRadiuses, brandColors, paletteMode, spacing }: GetConstructsProps): ConstructMap {
	const primitives = getEncorePrimitives(brandColors);

	switch (paletteMode) {
		case 'light':
			return getConstructsLight({ borderRadiuses, primitives, spacing });
		case 'dark':
			return getConstructsDark({ borderRadiuses, primitives, spacing });
		default:
			return getConstructsLight({ borderRadiuses, primitives, spacing });
	}
}
