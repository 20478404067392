import React, { type ElementType } from 'react';
import { LinkProps } from './link.types';
import { LinkUnstyled, LinkUnstyledProps } from './unstyled';
import { useStyles } from './link.styles';
import { formatBaseProps } from '~utils';

export const Link = <C extends ElementType = 'a'>(props: LinkProps<C>) => {
	const { color, inheritFont, size, ...rest } = props;
	const { classes, cx } = useStyles({ color, inheritFont, size });

	return (
		<LinkUnstyled
			{...(formatBaseProps(rest) as LinkUnstyledProps)}
			className={cx(classes.root, { [classes.inverted]: color === 'inverted' })}
		/>
	);
};
