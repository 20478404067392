import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const useStyles = makeStyles()(({ palette }) => ({
	root: {
		alignSelf: ifFeature('encore', 'center'),
		background: 'none',
		borderColor: 'transparent',
		borderRadius: '2px',
		borderStyle: 'solid',
		borderWidth: '1px',
		color: palette.gray[700],
		fill: ifFeature('encore', palette.gray[300]),
		fontSize: '20px',
		letterSpacing: '1px',
		lineHeight: '12px',
		textAlign: 'center',
		width: '22px',

		'&:hover': {
			backgroundColor: ifFeature('encore', undefined, palette.primary.lighter),
		},
	},
}));
