import React from 'react';
import { BadgeV2Props } from './badge-v2.types';
import { Flex } from '~components/flex';
import { BodyText } from '~components/body-text';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconTile, IconTileSizes } from '~components/icon-tile';
import { Avatar } from './avatar';
import { BaseHeadline } from '~components/headline/base-headline';
import { BadgeV2Context } from './badge-v2-context';
import { useStyles } from './badge-v2.styles';

function getTitle(title: BadgeV2Props['title'], size: BadgeV2Props['size'], classes: ReturnType<typeof useStyles>['classes']) {
	if (size === 'large') {
		return (
			<BaseHeadline color="neutral-strong" component="div" size="small">
				{title}
			</BaseHeadline>
		);
	}

	if (size === 'small') {
		return <div className={classes.titleSmall}>{title}</div>;
	}

	return (
		// default is medium
		<BaseHeadline color="neutral-strong" component="div" size="extra-small">
			{title}
		</BaseHeadline>
	);
}

export function BadgeV2({ icon, subtitle, size = 'medium', title }: BadgeV2Props) {
	const { classes } = useStyles();
	const iconSizeMap: Record<typeof size, IconTileSizes> = {
		large: 56,
		medium: 48,
		small: 44,
	};
	return ifFeature(
		'encore',
		<BadgeV2Context.Provider value={{ size }}>
			<Flex gap={2}>
				{icon && typeof icon === 'string' ? <IconTile icon={icon} size={iconSizeMap[size]} /> : icon}
				<Flex flexDirection="column" justifyContent="center">
					{getTitle(title, size, classes)}
					{subtitle && (
						<BodyText color="neutral-weak" size={size} weight="regular">
							{subtitle}
						</BodyText>
					)}
				</Flex>
			</Flex>
		</BadgeV2Context.Provider>,

		// render nothing for Jade
		null
	);
}

BadgeV2.Avatar = Avatar;
