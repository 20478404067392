import React from 'react';

import { DatePickerContextValue, DatePickerFormatType, DatePickerLocale } from '../types';

export const DEFAULT_FORMAT_TYPE: DatePickerFormatType = 'MM/dd/yyyy';
export const DEFAULT_LOCALE: DatePickerLocale = 'en-us';

export const SUPPORTED_FORMAT_TYPES: Array<DatePickerFormatType> = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy-MM-dd', 'dd MMM yyyy'];

export const SUPPORTED_LOCALES: Array<DatePickerLocale> = [
	'de',
	'en',
	'en-us',
	'es',
	'es-ar',
	'fr',
	'fr-ca',
	'ja',
	'nl',
	'pt',
	'pt-br',
	'zh',
	'zh-cn',
];

export const DatePickerContext = React.createContext<DatePickerContextValue>({
	formatType: DEFAULT_FORMAT_TYPE,
	locale: DEFAULT_LOCALE,
	utils: undefined,
});
