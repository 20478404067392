import { Color } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { textSizes, textWeights } from '../../definitions/json/text.json';
import { fabricFontSize, fabricLineHeight } from '../../definitions/js/text';
import { px } from '../mixins/fabricMixins';

export const fabricTypography = (palette: PaletteOptions): TypographyOptions => {
	const gray = palette.gray as Color;

	return {
		fabricFontSize,
		fabricLineHeight,
		fontFamily: 'Lato, sans-serif',
		fontSize: 15,
		fontWeightLight: 300,
		fontWeightRegular: textWeights.regular,
		fontWeightMedium: textWeights.medium,
		fontWeightSemibold: textWeights.semibold,
		fontWeightBold: textWeights.bold,
		fontWeightHeavy: textWeights.heavy,
		h1: {
			fontSize: '2.266rem', // 34px
			fontWeight: textWeights.bold,
			lineHeight: '2.8rem', // 42px
			margin: '1.066rem 0', // 16px
		},
		h2: {
			fontSize: '1.866rem', // 28px
			fontWeight: textWeights.bold,
			lineHeight: '2.4rem', // 36px
			margin: '0.733rem 0', // 11px
		},
		h3: {
			fontSize: '1.466rem', // 22px
			fontWeight: textWeights.semibold,
			lineHeight: '2rem', // 30px
			margin: '0.866rem 0', // 13px
		},
		h4: {
			fontSize: '1.2rem', // 18px
			fontWeight: textWeights.semibold,
			lineHeight: '1.733rem', // 26px
			margin: '0.733rem 0', // 11px
		},
		h5: {
			fontSize: '1.066rem', // 16px
			fontWeight: textWeights.semibold,
			lineHeight: '1.467rem', // 22px
			margin: '0.6rem 0', // 9px
		},
		h6: {
			fontSize: '1.066rem', // 16px
			fontWeight: textWeights.semibold,
			lineHeight: '1.467rem', // 22px
			margin: '0.6rem 0', // 9px
		},
		body1: {
			fontSize: '1rem', // 15px
			lineHeight: '1.467rem', // 22px
		},
		body2: {
			fontSize: '0.933rem', // 14px: bodySmall
			lineHeight: '1.333rem', // 20px: bodySmall
		},
		caption: {
			color: gray[600],
		},
		biggie: {
			fontSize: textSizes.biggie.size,
			lineHeight: px(textSizes.biggie.line),
		},
		large: {
			fontSize: textSizes.large.size,
			lineHeight: px(textSizes.large.line),
		},
		medium: {
			fontSize: textSizes.medium.size,
			lineHeight: px(textSizes.medium.line),
		},
		small: {
			fontSize: textSizes.small.size,
			lineHeight: px(textSizes.small.line),
		},
		teenie: {
			fontSize: textSizes.teenie.size,
			lineHeight: px(textSizes.teenie.line),
		},
	};
};
