import React, { FC, PropsWithChildren, useCallback } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { HeaderContext } from './header-context';
import { Link } from './link';
import { Action } from './action';
import { SearchInput } from './search-input';
import { HeaderProps } from './types';
import { useStyles } from './header.styles';
import { Flex } from '~components/flex';

export const Header: FC<PropsWithChildren<HeaderProps>> & {
	Link: typeof Link;
	SearchInput: typeof SearchInput;
	Action: typeof Action;
} = props => {
	const {
		actions,
		borderBottom = false,
		children,
		className = '',
		classes: classesProp,
		dark,
		logo,
		position,
		nav,
		search,
		title,
		...rest
	} = props;

	const { classes, cx } = useStyles({ actions, children, dark, position, borderBottom }, { props: { classes: classesProp } });

	return (
		<HeaderContext.Provider value={{ dark }}>
			<header
				{...rest}
				className={cx(className, classes.root)}
				// set CSS var so consuming projects can use it for positioning
				ref={e => document.documentElement.style.setProperty('--fabric-global-header-height', `${e?.offsetHeight ?? 0}px`)}
			>
				<div className={cx(classes.guts, borderBottom ? classes.gutsBorder : '')}>
					<a aria-label={window.jQuery ? $.__('Logo') : 'Logo'} className={classes.logo} href="/">
						{logo}
					</a>
					{title}
					{ifFeature('encore', undefined, <nav className={classes.nav}>{nav}</nav>)}

					<div className={classes.search}>{search}</div>

					<>
						{children}
						{ifFeature(
							'encore',
							actions?.length ? (
								<Flex alignItems="center" gap={1} justifyContent="center">
									{actions}
								</Flex>
							) : null
						)}
					</>
				</div>

				{position === 'fixed' && <div className={classes.spacer}></div>}
			</header>
		</HeaderContext.Provider>
	);
};

Header.Link = Link;
Header.SearchInput = SearchInput;
Header.Action = Action;
