import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const usePhotoUploadStyles = makeStyles()(({ borderRadiuses, mode, palette, spacing, typography }) => ({
	wrapper: {
		color: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[800],
		display: 'flex',
		fill: mode === ModeOptions.Dye ? dyeColors.main : palette.gray[500],
		justifyContent: 'space-evenly',
		width: ifFeature('encore', '720px'),
	},
	cropperWrapper: {
		alignItems: 'center',
		maxWidth: '248px',
		minHeight: '248px',
	},
	previews: {
		display: 'flex',
		flexDirection: ifFeature('encore', 'row', 'column'),
		gap: ifFeature('encore', spacing(2.5), spacing(2)),
		/* @startCleanup encore */
		'& > svg': {
			alignSelf: ifFeature('encore', undefined, 'center'),
		},
		/* @endCleanup encore */
	},
	preview: {
		/* @startCleanup encore */
		alignSelf: ifFeature('encore', undefined, 'center'),
		/* @endCleanup encore */
		outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		overflow: 'hidden',
	},
	previewSmall: {
		borderRadius: ifFeature('encore', borderRadiuses[200], '50%'),
		height: ifFeature('encore', '32px', '24px'),
		width: ifFeature('encore', '32px', '24px'),
	},
	previewMedium: {
		borderRadius: ifFeature('encore', borderRadiuses[300], '50%'),
		height: ifFeature('encore', '56px', '40px'),
		width: ifFeature('encore', '56px', '40px'),
	},
	previewLarge: {
		borderRadius: ifFeature('encore', borderRadiuses[500], '50%'),
		height: ifFeature('encore', '96px', '100px'),
		width: ifFeature('encore', '96px', '100px'),
	},
	/* @startCleanup encore */
	previewTitle: {
		borderBottom: `1px solid ${palette.gray[600]}`,
		lineHeight: typography.medium.lineHeight,
		width: '120px',
	},
	/* @endCleanup encore */
	blankState: {
		alignItems: ifFeature('encore', 'center'),
		backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : palette.gray[100],
		borderRadius: ifFeature('encore', borderRadiuses[200]),
		boxSizing: 'border-box',
		display: ifFeature('encore', 'flex'),
		flexDirection: ifFeature('encore', 'column'),
		height: '248px',
		justifyContent: ifFeature('encore', 'center'),
		/* @startCleanup encore */
		padding: ifFeature('encore', '0px', '40px 8px 20px'),
		/* @endCleanup encore */
		rowGap: ifFeature('encore', spacing(3)),
		width: '248px',
		'& > svg': {
			display: 'block',
			margin: `0 auto ${spacing(3)}`,
		},
	},
	blankStateText: {
		fontSize: typography.small.fontSize,
		lineHeight: typography.small.lineHeight,
		padding: `0 ${spacing(2)}`,
		textAlign: 'center',
	},
	fileActions: {
		columnGap: ifFeature('encore', spacing(1)),
		display: ifFeature('encore', 'flex'),
		justifyContent: ifFeature('encore', 'center'),
		marginTop: spacing(2),
		textAlign: 'center',
	},
	hiddenFileInput: {
		display: 'none',
	},
}));
