const X_PADDING = 1.25; // used with theme spacing
const HEIGHTS = {
	// Pixel heights
	teenie: 28,
	small: 32,
	medium: 34,
	large: 36,
	biggie: 42,
};
const Y_PADDING = {
	// used with theme spacing
	teenie: 0.875,
	small: 0.9375,
	medium: 0.625,
	large: 0.625,
	biggie: 1.315,
};

export { HEIGHTS, Y_PADDING, X_PADDING };
