/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { uniqueId } from 'lodash';
import React, { forwardRef, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Popover } from '~components/popover';
import { DatePickerCalendarSingle, InputDate } from '../components';
import { useDatePickerUtils } from '../hooks';
import { DatePickerDate, DatePickerOnChangeParamDate, DatePickerProps, InputDateOnChangeParam } from '../types';
import { getDateError, getDateFromProp, getDateInfo, getDefaultMaxDate, getDefaultMinDate } from '../utils';
import {
	getDatePickerDetailedOnChangeParam,
	getDatePickerOnChangeParam,
	getDatePickerOnErrorParam,
	getDatePickerOnInputChangeParam,
} from './date-picker.domain';
import { useStyles } from './date-picker.styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const DatePickerComponent = forwardRef<HTMLInputElement, DatePickerProps>(function DatePickerComponent(
	{
		biId,
		classes = {},
		className,
		DatePickerCalendarProps = {},
		disabled,
		getDateDisabled,
		enableFlip = ifFeature('encore', true, false),
		id,
		InputDateProps = {},
		inputProps = {},
		label,
		maxDate: maxDateProp,
		minDate: minDateProp,
		note,
		onInputChange,
		onChange,
		onCalendarDateSelect,
		onClose,
		onError,
		onOpen,
		onPopupToggle,
		open,
		renderDate,
		required = false,
		size,
		status,
		value: valueProp,
		variant = 'form',
		viewMode,
		width,
	},
	ref
): ReactElement {
	const refInput = useRef<HTMLInputElement>(null);
	const refInputRoot = useRef<HTMLDivElement>(null);
	const [calendarButton, setCalendarButton] = useState<HTMLButtonElement | null>(null);
	const refPopoverRoot = useRef<HTMLDivElement>(null);
	const refLastChangedInputValue = useRef('');
	const { palette } = useTheme();
	const utils = useDatePickerUtils();
	const [isOpen, setIsOpen] = useState(false);
	const [menuControlsOpen, setMenuControlsOpen] = useState(false);
	const [fillColor, setFillColor] = useState(palette.gray[100]);

	const { classes: styles } = useStyles();

	const value = useMemo(() => getDateFromProp(utils, valueProp), [utils, valueProp]);
	const minDateValue = useMemo(() => getDateFromProp(utils, minDateProp), [utils, minDateProp]);
	const minDate = useMemo(() => minDateValue || getDefaultMinDate(utils), [utils, minDateValue]);
	const maxDateValue = useMemo(() => getDateFromProp(utils, maxDateProp), [utils, maxDateProp]);
	const maxDate = useMemo(() => maxDateValue || getDefaultMaxDate(utils), [utils, maxDateValue]);

	useEffect(() => {
		if (valueProp && !value) {
			console.warn(`The provided 'DatePicker' component's 'value' prop is being ignored because it is invalid.`);
		}
	}, [valueProp, value]);

	useEffect(() => {
		if (minDateProp && !minDateValue) {
			console.warn(`The provided 'DatePicker' component's 'minDate' prop is being ignored because it is invalid.`);
		}
	}, [minDateProp, minDateValue]);

	useEffect(() => {
		if (maxDateProp && !maxDateValue) {
			console.warn(`The provided 'DatePicker' component's 'maxDate' prop is being ignored because it is invalid.`);
		}
	}, [maxDateProp, maxDateValue]);

	const inputRef = inputProps.ref || refInput;

	useEffect(() => {
		window.addEventListener('click', tryClose);

		return () => {
			window.removeEventListener('click', tryClose);
		};

		function tryClose(e: MouseEvent) {
			const target = e.target as Element;

			if (
				(refPopoverRoot.current && refPopoverRoot.current.contains(target)) ||
				(refInputRoot.current && refInputRoot.current.contains(target))
			) {
				return;
			}

			tryHandleClose();
		}
	});

	useEffect(() => {
		handleError(value, refLastChangedInputValue.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [utils, value, required, minDate, maxDate, getDateDisabled, refLastChangedInputValue.current]);

	const _open = open || isOpen;

	const calendarId = useMemo(() => {
		return typeof id === 'string' ? id.replaceAll(' ', '-') : uniqueId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classNames(className, classes.root, styles.root)} ref={ref}>
			<InputDate
				{...InputDateProps}
				biId={biId ? `${biId}_date-picker_input-date` : biId}
				calendarButtonRef={setCalendarButton}
				calendarId={calendarId}
				className={classNames('fabInternal-DatePickerInput', InputDateProps.className)}
				disabled={disabled}
				id={id}
				inputProps={{
					...inputProps,
					ref: inputRef,
				}}
				isPopoverOpen={_open}
				label={label}
				note={note}
				onChange={handleInputDateChange}
				onInputChange={handleInputChange}
				onPopupToggle={handlePopupToggle}
				ref={refInputRoot}
				required={required}
				size={size}
				status={status}
				value={value}
				variant={variant}
				viewMode={viewMode}
				width={width}
			/>
			<Popover
				anchorEl={calendarButton}
				arrowFill={fillColor}
				autofocus={false}
				disableOuterClickOnClose={true}
				hasCloseButton={false}
				hasMaxWidth={false}
				id={`date-picker-control-${calendarId}`}
				isRounded={true}
				modifiers={[
					{
						name: 'flip',
						enabled: enableFlip, // enable to flip the placement of the popper if it collides with the viewport
					},
				]}
				noPadding={true}
				onFlip={placement => handleFlip(placement)}
				open={open === undefined ? isOpen : open}
				placement="bottom-start"
				ref={refPopoverRoot}
				variant="bordered"
			>
				<FocusTrap
					active={open}
					focusTrapOptions={{
						clickOutsideDeactivates: true,
						delayInitialFocus: true,
						escapeDeactivates: false,
						initialFocus: false,
						tabbableOptions: { displayCheck: 'none' },
					}}
					paused={menuControlsOpen}
				>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
					<div onKeyDown={handleEscape}>
						<DatePickerCalendarSingle
							{...DatePickerCalendarProps}
							autofocus={_open}
							disabled={disabled}
							getDateDisabled={handleGetDateDisabled}
							maxDate={maxDate}
							minDate={minDate}
							onChange={handleCalendarChange}
							onMenuClose={handleMenuClose}
							onMenuOpen={handleMenuOpen}
							renderDate={renderDate}
							value={value}
						/>
					</div>
				</FocusTrap>
			</Popover>
		</div>
	);

	function handleInputChange(param: InputDateOnChangeParam) {
		if (typeof onInputChange === 'function') {
			onInputChange(getDatePickerOnInputChangeParam(utils, param));
		}
	}

	function handleCalendarChange(param: DatePickerOnChangeParamDate): void {
		refLastChangedInputValue.current = '';
		tryHandleClose();
		if (typeof onCalendarDateSelect === 'function') {
			onCalendarDateSelect(getDatePickerDetailedOnChangeParam(utils, param.value));
		}
		onChange(getDatePickerOnChangeParam(utils, param.value));
		handleError(param.value, refLastChangedInputValue.current);
	}

	function handleClose() {
		setIsOpen(false);
		if (onClose) {
			onClose();
		}
	}

	function handleError(date: DatePickerDate, inputValue: string): void {
		if (onError) {
			const error = getDateError(utils, date, inputValue, required, minDate, maxDate, handleGetDateDisabled);
			onError(getDatePickerOnErrorParam(error));
		}
	}

	function handleEscape(event: React.KeyboardEvent) {
		if (event.key === 'Escape' && !menuControlsOpen) {
			event.preventDefault();

			tryHandleClose();
		}
	}

	function handleGetDateDisabled(date: DatePickerDate) {
		return getDateDisabled ? getDateDisabled(getDateInfo(utils, date)) : false;
	}

	function handleInputDateChange(param: InputDateOnChangeParam): void {
		refLastChangedInputValue.current = param.inputValue;
		onChange(getDatePickerOnChangeParam(utils, param.value));
		handleError(param.value, refLastChangedInputValue.current);
	}

	function handleMenuClose() {
		setMenuControlsOpen(false);
	}

	function handleMenuOpen() {
		setMenuControlsOpen(true);
	}

	function handleOpen() {
		setIsOpen(true);
		if (onOpen) {
			onOpen();
		}
	}

	function handlePopupToggle(): void {
		if (isOpen) {
			tryHandleClose();
		} else {
			tryHandleOpen();
		}
		if (onPopupToggle) {
			onPopupToggle();
		}
	}

	function handleFlip(placement: string): void {
		setFillColor(placement.includes('bottom') ? palette.gray[100] : palette.common.white);
	}

	function tryHandleClose() {
		if (open !== undefined) {
			return;
		}

		if (!isOpen) {
			return;
		}

		handleClose();
	}

	function tryHandleOpen() {
		if (open !== undefined) {
			return;
		}

		if (isOpen) {
			return;
		}

		handleOpen();
	}
});
