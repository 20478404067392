import { styled, Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { widths } from '~definitions/json/encore-sizes.json';

export const DetailStyled = styled('div')({
	alignItems: 'flex-start',
	display: 'flex',
	flexDirection: 'row',
	flexGrow: 1,
	justifyContent: 'space-between',
	maxWidth: '100%',
});

export const DisplayStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { borderRadiuses, constructs, mixins, spacing, palette, transitions, typography } = theme as Theme;
	return {
		backgroundColor: ifFeature('encore', constructs.surface.neutral.xWeak, palette.gray[100]),
		border: `1px solid ${ifFeature('encore', constructs.border.neutral.weak, palette.gray[400])}`,
		borderRadius: ifFeature('encore', borderRadiuses[400], '2px'),
		boxShadow: ifFeature('encore', mixins.boxShadow('300', constructs.surface.neutral.xxStrong), 'none'),
		boxSizing: 'border-box',
		color: palette.gray[1000],
		display: 'flex',
		flexDirection: 'row',
		fontSize: typography.teenie.fontSize,
		height: ifFeature('encore', '128px', '112px'),
		lineHeight: typography.teenie.lineHeight,
		padding: ifFeature('encore', spacing(2), '9px 12px 12px'),
		position: 'relative',
		width: ifFeature('encore', widths[7], '176px'),
		'&.fab-FileCard--action:not(.fab-FileCard--disabled)': {
			cursor: 'pointer',
		},
		'&:hover:not(.fab-FileCard--disabled)': {
			// @startCleanup encore
			backgroundColor: ifFeature('encore', undefined, palette.gray[200]),
			// @endCeanup encore
			border: 'solid 1px',
			borderColor: ifFeature('encore', constructs.border.primary.strong, palette.gray[400]),
			boxShadow: ifFeature('encore', mixins.boxShadow('300', constructs.surface.neutral.xxStrong), 'none'),
			transition: transitions.create(['border', 'fill']),
		},
		'.fab-FloatingIconButton--secondary, .fab-FloatingIconButton--secondary:hover': {
			backgroundColor: 'transparent',
		},
	};
});

export const FileStyled = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
});

export const IconStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { spacing } = theme as Theme;
	return {
		display: ifFeature('encore', 'flex'),
		marginRight: ifFeature('encore', spacing(1), '6px'),
	};
});

export const InfoStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { palette, typography } = theme as Theme;
	return {
		color: palette.gray[700],
		display: 'inline-block',
		fontSize: typography.teenie.fontSize,
		height: 'auto',
		lineHeight: 1,
		'.fab-FileCard--disabled &': {
			opacity: 0.5,
		},
	};
});

export const LinkStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { borderRadiuses, constructs, spacing, palette, typography } = theme as Theme;
	return {
		borderRadius: ifFeature('encore', borderRadiuses[100], '2px'),
		fontSize: ifFeature('encore', typography.small.fontSize, '12px'),
		fontWeight: ifFeature('encore', typography.fontWeightRegular, typography.fontWeightSemibold),
		lineHeight: ifFeature('encore', typography.small.lineHeight, '15px'),
		margin: 0,
		// @startCleanup encore
		padding: ifFeature('encore', undefined, '2px 0'),
		// @endCeanup encore
		textOverflow: 'ellipsis',
		'&.required': {
			backgroundColor: ifFeature('encore', constructs.surface.warning.strong, palette.action?.highlight),
			color: ifFeature('encore', constructs.text.neutral.forcedWhite, palette.gray[1000]),
			fontSize: ifFeature('encore', typography.teenie.fontSize),
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightBold),
			lineHeight: ifFeature('encore', typography.teenie.lineHeight),
			padding: ifFeature('encore', spacing(0.5, 1), '3px 6px'),
		},
		'&.signed': {
			backgroundColor: ifFeature('encore', constructs.surface.success.strong, palette.success.main),
			color: ifFeature('encore', constructs.text.neutral.forcedWhite, 'white'),
			fontSize: ifFeature('encore', typography.teenie.fontSize),
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightBold),
			lineHeight: ifFeature('encore', typography.teenie.lineHeight),
			padding: ifFeature('encore', spacing(0.5, 1), '3px 6px'),
		},
	};
});

export const RemoveButtonStyled = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginTop: '3px',
});

export const TitleStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { constructs, palette, transitions, typography } = theme as Theme;
	return {
		display: 'inline-block',
		fontSize: ifFeature('encore', typography.medium.fontSize, typography.small.fontSize),
		fontWeight: ifFeature('encore', typography.fontWeightMedium),
		lineHeight: ifFeature('encore', typography.medium.lineHeight, typography.teenie.lineHeight),
		maxHeight: ifFeature('encore', '42px', '38px'),
		maxWidth: '100%',
		minHeight: '34px',
		overflow: 'hidden',
		wordBreak: 'break-all',
		'.fab-FileCard--action &': {
			color: ifFeature('encore', constructs.text.link.idle, palette.info.main),
			fontSize: ifFeature('encore', typography.medium.fontSize, typography.small.fontSize),
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightBold),
		},
		'.fab-FileCard--action:not(.fab-FileCard--disabled):hover &': {
			color: ifFeature('encore', constructs.text.link.hover),
			textDecoration: 'underline',
			transition: ifFeature('encore', transitions.create(['color', 'fill'])),
		},
	};
});

export const TypeStyled = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { constructs, palette, typography } = theme as Theme;
	return {
		alignItems: 'center',
		color: ifFeature('encore', constructs.text.neutral.medium, palette.gray[700]),
		display: 'inline-flex',
		flexDirection: 'row',
		fontSize: ifFeature('encore', typography.small.fontSize, typography.teenie.fontSize),
		height: 'auto',
		lineHeight: ifFeature('encore', typography.small.lineHeight, 1),
	};
});
