import { useCallback, useEffect, useState } from 'react';
import { CurrencyInputProps } from '../types';
import { CurrencyItem } from '~components/currency-picker';

export const useSelectedCurrency = (currencyItem: CurrencyItem, onSelectCurrency: CurrencyInputProps['onSelectCurrency']) => {
	const [selectedCurrencyItem, setSelectedCurrencyItem] = useState<CurrencyItem>(currencyItem);

	useEffect(() => {
		setSelectedCurrencyItem(currencyItem);
	}, [JSON.stringify(currencyItem)]);

	const handleCurrencyCodeSelected = useCallback(
		(selectedItem: { value: string; data: CurrencyItem }) => {
			setSelectedCurrencyItem(selectedItem.data);
			if (onSelectCurrency) {
				onSelectCurrency(selectedItem.data);
			}
		},
		[onSelectCurrency]
	);

	return {
		selectedCurrencyItem,
		handleCurrencyCodeSelected,
	};
};
