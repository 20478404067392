import { SetCropBoxCoords, SetImageSrc } from './utils.types';

export const handleFileSelection = (files: File[], setCropBoxCoords: SetCropBoxCoords, setImageSrc: SetImageSrc) => {
	const file = files[0];
	const reader = new FileReader();
	// convert image file to base64 string
	reader.addEventListener(
		'load',
		() => {
			if (reader.result) {
				setCropBoxCoords(undefined);
				setImageSrc(reader.result as string);
			}
		},
		false
	);
	if (file) {
		reader.readAsDataURL(file);
	}
};
