import { CSSProperties } from 'react';

export const styles: { [key: string]: CSSProperties } = {
	botMessageMainContainer: {
		display: 'flex',
	},
	chatPromptWrapper: {
		columnGap: '25px',
		display: 'flex',
		flexDirection: 'column',
	},
	chatPrompt: {
		flex: 1,
		backgroundColor: '#F6F6F4',
		borderRadius: '16px 16px 0px 16px',
		maxWidth: '100%',
	},
	botMessage: {
		margin: 0,
		marginLeft: '40px',
		padding: 0,
		textIndent: 0,
		textAlign: 'left' as const,
	},
	messageHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		gap: 12,
	},
	chatHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-start',
		gap: 12,
	},
	nameContainer: {
		flexGrow: 2,
		textAlign: 'left' as const,
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row' as const,
		gap: 6,
	},
	iconButton: {
		backgroundColor: 'transparent',
		borderWidth: 0,
		cursor: 'pointer',
	},
	copiedMessage: {
		marginRight: 4,
	},
	avatarImage: {
		height: 24,
		width: 24,
		position: 'relative' as const,
	},
	popoverContainer: {
		position: 'relative',
		display: 'inline-block',
	},
};
