import { ifFeature } from '@bamboohr/utils/lib/feature';
import { SliderSelectProps } from '@fabric/slider-select/types';
import { dyeColors, makeStyles, ModeOptions } from '~styles';

export const useStyles = makeStyles<Partial<SliderSelectProps>>()((
	{ borderRadiuses, constructs, mixins, mode, palette, typography },
	{ staticLabels }
) => {
	const fontSize = ifFeature('encore', typography.medium?.fontSize, typography.small?.fontSize);
	const railHeight = 8;
	const thumbHeight = ifFeature('encore', 20, 22);
	const thumbWidth = ifFeature('encore', 24, 22);

	return {
		valueLabel: {
			fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: '22px',
			top: 20,
			backgroundColor: 'unset',
			'&:before': {
				display: 'none',
			},
			'& *': {
				background: 'transparent',
				color:
					mode === ModeOptions.Dye
						? dyeColors.main
						: ifFeature('encore', constructs.text.primary.strong, palette.primary.main),
			},
		},
		mark: {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
			height: ifFeature('encore', 4, 22),
			width: ifFeature('encore', 2, 22),
			borderRadius: ifFeature('encore', borderRadiuses[100], borderRadiuses[800]),
			margin: ifFeature('encore', 0, '0 0 0 -10px'),
			'&.MuiSlider-markActive': {
				opacity: 1,
				backgroundColor: ifFeature('encore', constructs.surface.primary.strong, palette.primary.main),
			},
		},
		markDisabled: {
			'&.MuiSlider-markActive': {
				opacity: 0,
			},
		},
		markLabel: {
			color: ifFeature('encore', constructs.text.neutral.xWeak, palette.grey[500]),
			display: ifFeature('encore', staticLabels ? 'none' : undefined),
			fontSize,
			fontWeight: ifFeature('encore', undefined, typography.fontWeightSemibold),
			top: 29,
			'&.MuiSlider-markLabelActive': {
				color: 'transparent',
			},
		},
		rail: {
			opacity: 1,
			height: railHeight,
			backgroundColor: ifFeature('encore', constructs.surface.neutral.weak, palette.grey[400]),
		},
		root: {
			color: ifFeature('encore', constructs.surface.primary.medium, palette.primary.lightest),
			marginBottom: ifFeature('encore', staticLabels ? 0 : undefined),
			height: 3,
			padding: '15px 0',
		},
		thumb: {
			height: thumbHeight,
			width: thumbWidth,
			backgroundColor:
				mode === ModeOptions.Dye
					? dyeColors.main
					: ifFeature('encore', constructs.surface.primary.strong, palette.primary.main),
			border: ifFeature('encore', mixins.border(2, constructs.border.neutral.forcedWhite)),
			borderRadius: borderRadiuses[800],
			boxSizing: ifFeature('encore', 'content-box'),
			'& .MuiSlider-valueLabel': {
				top: ifFeature('encore', 50, 52),
			},
			'&.Mui-disabled': {
				height: thumbHeight,
				width: thumbWidth,
				backgroundColor:
					mode === ModeOptions.Dye
						? dyeColors.light
						: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
				color: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
				'& .MuiSlider-valueLabel span': {
					color: ifFeature('encore', constructs.surface.neutral.medium, palette.grey[400]),
					'& *': {},
				},
			},
			'&.Mui-focusVisible': {
				boxShadow: `0px 0px 0px 4px ${ifFeature(
					'encore',
					mixins.alpha(constructs.surface.primary.medium, 0.1),
					mixins.alpha(palette.primary.lightest, 0.35)
				)}`,
			},
			'&:before': {
				content: ifFeature('encore', 'none'),
			},
			'&:hover': {
				boxShadow: `0px 0px 0px 4px ${ifFeature(
					'encore',
					mixins.alpha(constructs.surface.primary.medium, 0.1),
					mixins.alpha(palette.primary.lightest, 0.35)
				)}`,
			},
		},
		track: {
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : undefined,
			border: 'none',
			height: ifFeature('encore', railHeight, 12),
		},
		customLabelsWrapper: {
			position: 'relative',
			width: '100%',
			top: '-16px',
		},
		customLabel: {
			position: 'absolute',
			fontSize,
			fontWeight: typography.fontWeightSemibold,
			color:
				mode === ModeOptions.Dye ? dyeColors.main : ifFeature('encore', constructs.text.primary.strong, palette.primary.main),
		},
		leftLabel: {
			left: -4,
			top: 5,
		},
		rightLabel: {
			right: -4,
			top: 5,
		},
	};
});
