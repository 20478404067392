import { debounce } from 'lodash';
import { Component } from 'react';

export class CollisionLocker extends Component {
	static defaultProps = {
		isEnabled: false,
	};

	_addEventListeners() {
		window.addEventListener('resize', this._handleResize);
		window.addEventListener('scroll', this._handleScroll);
	}

	_lock() {
		const { onLock } = this.props;

		onLock();
	}

	_removeEventListeners() {
		window.removeEventListener('resize', this._handleResize);
		window.removeEventListener('scroll', this._handleScroll);
	}

	_handleResize = debounce(
		() => {
			this._lock();
		},
		20,
		{ leading: true }
	);

	_handleScroll = debounce(
		() => {
			this._lock();
		},
		20,
		{ leading: true }
	);

	componentDidMount() {
		const { isEnabled } = this.props;

		if (isEnabled) {
			this._addEventListeners();
		}
	}

	componentDidUpdate() {
		const { isEnabled } = this.props;

		if (isEnabled) {
			this._addEventListeners();
		} else {
			this._removeEventListeners();
		}
	}

	componentWillUnmount() {
		this._removeEventListeners();
	}

	render() {
		const { children } = this.props;

		return children || null;
	}
}
