// eslint-disable-next-line no-restricted-imports
import createCache from '@emotion/cache';
import React, { ReactNode, useMemo } from 'react';
import { TssCacheProvider } from 'tss-react';

const insertionPoint = document.createElement('meta');
insertionPoint.setAttribute('name', 'fabric-insertion-point');
insertionPoint.setAttribute('content', '');

const getMuiJSSInsertionPoint = () => [...document.head.childNodes].find(childNode => childNode.nodeValue === 'mui-inject-first');

const appendInsertionPoint = () => {
	const muiInsertionPoint = getMuiJSSInsertionPoint();

	if (muiInsertionPoint && muiInsertionPoint.parentElement) {
		muiInsertionPoint.parentElement.insertBefore(insertionPoint, muiInsertionPoint);
		return;
	}

	document.head.insertBefore(insertionPoint, document.head.firstChild);
};

export function TssProvider({ children }: { children: ReactNode }) {
	const cache = useMemo(() => {
		appendInsertionPoint();

		return createCache({
			key: 'fabric',
			insertionPoint,
		});
	}, []);

	return <TssCacheProvider value={cache}>{children}</TssCacheProvider>;
}
