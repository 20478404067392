/* eslint-disable @typescript-eslint/no-use-before-define */
import { CSSInterpolation, CSSObject } from 'tss-react';
import { getMuiTheme } from '../../styles';
import { GetStylesType } from './layout-escape-hatch.types';
import { stylesBlacklist } from './styles-blacklist';
import { Theme } from '@mui/material';

let testTheme: Theme;

const invalidKeyEncountered = (key: string, message: string) => {
	const detail = message ? `. ${message}` : '';

	if ((window as typeof window & { ENV: string }).ENV !== 'prod') {
		throw new Error(`Invalid key: ${key}${detail}`);
	}
};

const checkPseudoSelectorValid = (key: string, value: CSSInterpolation) => {
	if (!key.includes('@media') && !key.includes('@container')) {
		invalidKeyEncountered(key, 'Nested selectors dissallowed. Only @media and @container pseudo-selectors are valid.');
	}
	if (key.includes('@media') && (key.includes('all') || key.includes('screen'))) {
		invalidKeyEncountered(key, `Due to the potential for abuse, 'all' and 'screen' are disallowed on @media queries.`);
	}
	if (key === '@media print') {
		return;
	}
	checkRootLevelEntries(value as CSSObject);
};

const checkStyleKeyValid = (key: string) => {
	const unionOfBlacklist = stylesBlacklist.join('|');
	const pattern = `^(${unionOfBlacklist}).*$`;

	if (key.match(pattern)) {
		invalidKeyEncountered(key, 'Only layout CSS properties are allowed.');
	}
};

const checkEntry = ([key, value]: [string, CSSInterpolation]) => {
	if (typeof value === 'object') {
		checkPseudoSelectorValid(key, value);
		return;
	}
	checkStyleKeyValid(key);
};

const checkRootLevelEntries = (value: CSSObject) => Object.entries(value).forEach(checkEntry);

export const validateStyles = (getStyles: GetStylesType) => {
	testTheme = testTheme || getMuiTheme('lime4');
	const rawJSS = typeof getStyles === 'function' ? getStyles(testTheme) : getStyles;

	checkRootLevelEntries(rawJSS as CSSObject); // coerce to CSSObject for validation
};
