import { Select } from '@fabric/select';
import React from 'react';

import { getSelectYearItems, getSelectYearOnChangeParam } from './select-year.domain';
import { SelectYearProps } from '../../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function SelectYear({
	disabled = false,
	getYearDisabled,
	onChange,
	onOpen,
	onClose,
	value,
	years,
}: SelectYearProps): JSX.Element {
	const items = getSelectYearItems(years, getYearDisabled);

	return (
		<Select
			aria-label={window.jQuery ? $.__('Select Year') : 'Select Year'}
			isClearable={false}
			isDisabled={disabled}
			items={items}
			onChange={handleChange}
			onClose={onClose}
			onOpen={onOpen}
			selectedValues={value ? [value] : undefined}
			showSearch="never"
			size="small"
			variant={ifFeature('encore', 'single')}
			width={1}
		/>
	);

	function handleChange(values: Array<number>): void {
		const param = getSelectYearOnChangeParam(values);
		onChange(param);
	}
}
