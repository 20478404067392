import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ProgressBarProps } from './progress-bar.types';

export const useStyles = makeStyles<ProgressBarProps>()((theme, props: ProgressBarProps) => {
	const { borderRadiuses, constructs, mode, palette } = theme;
	const { current, height, total, width } = props;
	return {
		innerBar: {
			background:
				mode === ModeOptions.Dye
					? dyeColors.main
					: ifFeature(
							'encore',
							constructs.surface.primary.strong,
							`linear-gradient(to right, ${palette.primary.lightest}, ${palette.primary.lighter})`
						),
			borderRadius: ifFeature('encore', borderRadiuses[1000], 6),
			height: `${height}px`,
			maxWidth: '100%',
			width: `${(100 * current) / total}%`,
		},
		outerBar: {
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.lightest : palette.gray['200'],
			borderRadius: ifFeature('encore', borderRadiuses[1000], 6),
			height: `${height}px`,
			width: width,
		},
	};
});
