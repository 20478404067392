import useId from '@mui/utils/useId';
import { interpolatePath } from 'd3-interpolate-path';
import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import { LineChartAreaProps } from '../../types/line-chart-area-props';
import { useAnimatedLinePath } from '../use-animated-line-path';

export const SolidArea: FC<PropsWithChildren<LineChartAreaProps>> = ({ path, animate, yMax, color, transition }) => {
	const animatedPath = useAnimatedLinePath(path, yMax, interpolatePath, transition);
	const id = useId() as string;
	// The id generated by useId is guaranteed to be unique across all components
	const areaId = `areaSolid-${id}`;
	if (yMax === 0) {
		return null;
	}

	return (
		<motion.path
			animate={animate ? 'idle' : false}
			d={animatedPath}
			fill={color}
			id={areaId}
			initial={animate ? 'initial' : 'idle'}
		/>
	);
};
